import React, { useState, useEffect, useContext, useRef } from 'react';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import Layout from '../../Layout';
import Dropzone from 'react-dropzone';
import {
  Card,
  TextField,
  Grid,
  Button,
  Typography,
  CardContent,
  Divider,
  Paper,
  MenuItem,
} from '@material-ui/core';
import CommonBreadcrumbs from '../../../components/common-breadcrumbs/breadcrumbs';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { DropzoneArea } from 'material-ui-dropzone';
import './createIchapter.scss';
import { useDispatch } from 'react-redux';
import { Add, Remove } from '@material-ui/icons';
import axiosInstance from '../../../config/axios';
import endpoints from '../../../config/endpoints';
import Loading from '../../../components/loader/loader';

function CreateIbookChapter() {
  const [loading, setLoading] = useState(false);
  const [bookList, setBookList] = useState([]);
  const [selectedBook, setSelectedBook] = useState('');
  const imageRef = useRef();
  const chapterRef = useRef();
  const [inputList, setInputList] = useState([
    {
      chapter_name: '',
      chapter_image: '',
      chapter_header: '',
      book_id: '',
      chapter_editor_id: '',
    },
  ]);
  const dispatch = useDispatch();
  const [openImage, setOpenImage] = useState(false);
  const [error, setErrorValue] = useState({
    option: true,
    editor: true,
    title: true,
    image: true,
  });

  const handleInputChange = (e, index) => {
    let name = e.target.name;
    let value;
    if (name === 'chapter_image') value = e.target.files[0];
    else value = e.target.value;
    const list = [...inputList];
    list[index][name] = value;
    setInputList(list);
  };
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([
      ...inputList,
      {
        chapter_name: '',
        chapter_image: '',
        chapter_header: '',
        book_id: '',
        chapter_editor_id: '',
      },
    ]);
  };

  const handleSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    let promises = inputList.map((obj) => {
      const formData = new FormData();
      Object.keys(obj).forEach((key) => {
        formData.append(key, obj[key]);
      });
      //https://dev.mgmt.letseduvate.com/
      //${window.location.origin}

      let endpoint = `${window.location.origin}`
      if (endpoint=='http://localhost:3000' || endpoint=='http://qa.mgmt.letseduvate.com') {
        endpoint='https://dev.mgmt.letseduvate.com'
      }
      console.log('endpoint',endpoint)

      return fetch(`${endpoint}/qbox${endpoints.ibook.addChapter}`, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization:
            'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxMSwidXNlcm5hbWUiOiI3Nzc3Nzc3Nzc3IiwiZXhwIjo2NjI0MzQ4NTM4LCJlbWFpbCI6InNhZGF0aEBvcmNoaWRzLmVkdS5pbiJ9.zCWvBVZgvLiq1hXAK0_8ScSah3Lmlry83GXW_8LcK3A',
          'X-API-KEY': 'vikash@12345#1231',
        },
      });
    });
    Promise.all(promises)
      .then((resp) => {
        console.log('Added successfully', resp);
      })
      .then((res) => {
        console.log('inputList', res);
        setLoading(false);
        inputList.length === 1
          ? setAlert('success', 'Chapter Added Successfully!')
          : setAlert('success', `${inputList.length} Chapters Added Successfully!`);
        setInputList([
          {
            chapter_name: '',
            chapter_image: '',
            chapter_header: '',
            book_id: '',
            chapter_editor_id: '',
          },
        ]);
        window.location = '/iChapter/view';
      });

  };
  useEffect(() => {
    if (inputList.book_id === '' && inputList.chapter_image === '') {
      imageRef.current.value = '';
    }
  }, [inputList]);

  const handleChangeFile = (files) => {
    // setDropFiles(files);
    console.log(files, '************files************');
  };

  const handleCloseImage = () => {
    setOpenImage(false);
  };

  const handlGetApi = () => {
    setLoading(true);
    axiosInstance
      .get(`${endpoints.ibook.bookList}`)
      .then((result) => {
        setLoading(false);
        if (result.status === 200) {
          setBookList(result.data.result.result);
        } else {
          // setAlert('error', result.data.message);
          console.log(result, 'console data');
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.message);
      });
  };

  useEffect(() => {
    handlGetApi();
  }, []);

  const { setAlert } = useContext(AlertNotificationContext);
  const [files, setFiles] = useState([]);

  const isPDF = (files) => {
    if (files[0].name.match(/.(pdf)$/i)) {
      return true;
    }
    return false;
  };
  const onDrop = (files = []) => {
    if (!isPDF(files)) {
      setAlert('error', 'Please select only PDF format');
      return;
    }
    if (files.length > 1) {
      setAlert('error', 'You can select only a single PDF at once');
      return;
    }
    const sampleFile = files;
    setFiles(sampleFile);
  };
  const getFileNameAndSize = (files) => {
    if (files.length) {
      const fileName =
        files &&
        files.map((file) => (
          <li key={file.name}>{`${file.name} -${file.size} bytes`}</li>
        ));
      return fileName;
    }
    return null;
  };
  const handleClearall = () => {};

  return (
    <>
      {loading ? <Loading message='Loading...' /> : null}
      <Layout>
        <div style={{ width: '100%', padding: '10px 20px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <CommonBreadcrumbs
                componentName='Intelligent Book'
                childComponentName='Create IBook-Chapter'
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12}>
            <form
              class='form-inline text-center mt-2 rounded p-5 bg-light'
              onSubmit={handleSubmit}
            >
              {inputList.map((x, i) => {
                return (
                  <Paper style={{ margin: '15px 0', padding: '10px' }}>
                    <Grid container alignItems='flex-end' justify='flex-end'>
                      {inputList.length !== 1 && (
                        <Button
                          onClick={() => handleRemoveClick(i)}
                          variant='contained'
                          color='primary'
                          size='medium'
                          style={{ margin: '0 5px 10px' }}
                        >
                          <Remove />
                        </Button>
                      )}

                      {inputList.length - 1 === i && (
                        <Button
                          onClick={handleAddClick}
                          variant='contained'
                          color='Secondary'
                          size='medium'
                          style={{ margin: '0 5px 10px' }}
                        >
                          <Add />
                        </Button>
                      )}
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item md={12} xs={12} sm={12}>
                        <Divider />
                      </Grid>

                      <Grid item md={3} xs={12} sm={3}>
                        <TextField
                          id='book_id'
                          name='book_id'
                          select
                          label='Select Book'
                          value={inputList.book_id}
                          onChange={(e) => handleInputChange(e, i)}
                          variant='outlined'
                          style={{ width: '100%' }}
                          size='small'
                          required
                        >
                          {bookList?.map((option) => (
                            <MenuItem key={option?.id} value={option?.id}>
                              {option?.book_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>

                      <Grid item md={3} xs={12} sm={3}>
                        <TextField
                          id='outlined-helperText'
                          label='Chapter Name'
                          name='chapter_name'
                          defaultValue=''
                          value={x.chapter_name}
                          className='dropdownIcon'
                          variant='outlined'
                          required
                          style={{ width: '100%' }}
                          inputProps={{ maxLength: 100 }}
                          onChange={(e) => handleInputChange(e, i)}
                          color='secondary'
                          size='small'
                        />
                      </Grid>
                      <Grid item md={3} xs={12} sm={3}>
                        <TextField
                          id='outlined-helperText'
                          label='Chapter Header'
                          name='chapter_header'
                          defaultValue=''
                          value={x.chapter_header}
                          className='dropdownIcon'
                          variant='outlined'
                          required
                          style={{ width: '100%' }}
                          inputProps={{ maxLength: 100 }}
                          onChange={(e) => handleInputChange(e, i)}
                          color='secondary'
                          size='small'
                        />
                      </Grid>
                      <Grid item md={3} xs={12} sm={3}>
                        <TextField
                          type='number'
                          id='outlined-helperText'
                          label='Chapter Editor Id'
                          name='chapter_editor_id'
                          defaultValue=''
                          value={x.chapter_editor_id}
                          className='dropdownIcon'
                          variant='outlined'
                          required
                          style={{ width: '100%' }}
                          inputProps={{ maxLength: 100 }}
                          onChange={(e) => handleInputChange(e, i)}
                          color='secondary'
                          size='small'
                        />
                      </Grid>

                      <Grid item md={3} xs={12} sm={3}>
                        <TextField
                          id='img'
                          type='file'
                          required
                          variant='outlined'
                          ref={imageRef}
                          name='chapter_image'
                          accept='image/*'
                          onChange={(e) => handleInputChange(e, i)}
                          size='small'
                        />
                      </Grid>
                      <Grid item md={3} xs={12} sm={3}>
                        <Typography color='secondary'>
                          Accepted jpg,jpeg,png Files
                        </Typography>
                      </Grid>
                    </Grid>
                  </Paper>
                );
              })}

              <Grid container spacing={2}>
                <Grid item xs={6} sm={2}>
                  <Button
                    onClick={() => window.history.back()}
                    variant='contained'
                    style={{ color: 'white' }}
                    className='custom_button_master'
                    size='medium'
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item xs={6} sm={2}>
                  <button className='btn btn-submit'>Submit</button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </div>
      </Layout>
    </>
  );
}

export default CreateIbookChapter;
