import React, { useState, useEffect, useCallback, useContext } from 'react';
import Layout from '../../Layout';
import CommonBreadcrumbs from '../../../components/common-breadcrumbs/breadcrumbs';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import './styles.scss';
import {
  Typography,
  Grid,
  FormControl,
  TextField,
  FormHelperText,
  Divider,
  Tabs,
  Tab,
  Paper,
  Button,
  InputLabel,
  OutlinedInput,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from '@material-ui/core';
import { Input } from 'antd';
import { useTheme } from '@material-ui/core';
import axiosInstance from '../../../config/axios';
import endpoints from '../../../config/endpoints';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DeleteIcon from '@material-ui/icons/Delete';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DNDFileUpload from '../../../components/dnd-file-upload';
import TabPanel from './tab-panel';
import PeriodList from './period-list';
import ContentTypeList from './content-type-list';
import AttachmentList from './attachment-list';
import { useHistory } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loading from '../../../components/loader/loader';
import {
  fetchVolumes,
  fetchAcademicYears,
  fetchGrades,
  fetchSubjects,
  fetchBoard,
  fetchModule,
  fetchTopics,
  fetchChapters,
  uploadLessonPlanFile,
  createLessonPlan,
  createLessonOverview,
  uploadLessonPlanHomework,
  deleteLessonPlanFile,
  getAttachments,
  createPeriods,
  periodDetails,
  uploadLessonPlanFileFromDrive,
} from './apis';

import useDrivePicker from 'react-google-drive-picker';
import GOOGLEDRIVE from './../../../assets/images/google-drive.svg';

import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import { fetchModules } from '../../../redux/actions';
import { InputNumber, message, Modal } from 'antd';
const validationSchema = Yup.object({
  academic_year: Yup.object('').required('Required').nullable(),
  volume: Yup.object('').required('Required').nullable(),
  grade: Yup.object('').required('Required').nullable(),
  subject: Yup.object('').required('Required').nullable(),
  chapter: Yup.object('').required('Required').nullable(),
  board: Yup.object('').required('Required').nullable(),
  modules: Yup.object('').required('Required').nullable(),
  // periods: Yup.number()
  // .required('Required')
  // .test(
  // 'Is positive?',
  // 'ERROR: The number must be greater than 0!',
  // (value) => value > 0
  // ),
});
const contentTypes = [
  'Lesson Plan',
  'Student Reading Material',
  'PPT',
  'Video',
  'Audio',
  'Textbook Answer Scheme',
  'Teacher Reading Material',
  'Activity Sheet',
  'Homework',
  'Question Paper',
];

const contentTypesInitialState = contentTypes.map((type) => ({
  currentUploadedFile: null,
  name: type,
  attachments: [],
}));

const validateSynopsisOverviewUpload = (valuesObj) => {
  let errors = [];
  Object.keys(valuesObj).forEach((key) => {
    if (!valuesObj[key]) {
      errors.push(key);
    }
  });
  return errors;
};

const initialState = {
  period: 'Period-1',
  activeTab: 'lesson-plan',
  contentTypeState: contentTypesInitialState,
};

const CreateLessonPlan = () => {
  const history = useHistory();
  const filterData = history?.location?.state;
  const { setAlert } = useContext(AlertNotificationContext);
  const [loading, setLoading] = useState(false);
  const [periodsState, setPeriodsState] = useState([]);
  const [yearlyCurrFile, setYearlyCurrFile] = useState();
  const [portionDocumentFile, setPortionDocumentFile] = useState();
  const [activeTab, setActiveTab] = useState('lesson-plan');
  const [selectedPeriod, setselectedPeriod] = useState(null);
  const [selectedContentType, setSelectedContentType] = useState([]);
  const [totalPeriods, setTotalPeriods] = useState(20);
  const [expandFilter, setExpandFilter] = useState(true);
  const [volumes, setVolumes] = useState([]);
  const [academicYears, setAcademicYears] = useState([]);
  const themeContext = useTheme();
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [board, setBoard] = useState([]);
  const [modules, setModule] = useState([]);
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const [chapters, setChapters] = useState([]);
  const [showSecondView, setShowSecondView] = useState(false);
  const [contentTypesState, setContentTypesState] = useState([]);
  const [currentFile, setCurrentFile] = useState(null);
  const [activeAttachments, setActiveAttachments] = useState([]);
  const [overviewFile, setOverviewFile] = useState(null);
  const [synopsisFile, setSynopsisFile] = useState(null);
  const [chaptersState, setChaptersState] = useState(null);
  const [uploadingAttachment, setUploadingAttachment] = useState(false);
  const [topics, setTopics] = useState([]);
  const [periods, setPeriods] = useState(0);
  const [periodCount, setPeriodCount] = useState(0);
  const [periodName, setPeriodName] = useState('Period');
  const [questionPaperIds, setQuestionPaperIds] = useState([]);
  const [qpname, setqpname] = useState();
  const [istrue, setIstrue] = useState(true);
  const [periodId, setPeriodId] = useState('');
  const [periodStates, setPeriodStates] = useState([]);
  const [AddmoduleButton, setAddmoduleButton] = useState(true);
  const [AddChapterButton, setAddChapterButton] = useState(true);

  const [AddKeyconceptButton, setAddKeyconceptButton] = useState(true);
  const [module_name, setModule_name] = useState('');
  const [openModule, setOpenModule] = useState(false);
  const [moduleSequenceNumber, setModuleSequenceNumber] = useState('');
  const [chapter_name, setChapter_name] = useState('');
  const [openChapter, setOpenChapter] = useState(false);
  const [chapterSequence, setChapterSequence] = useState('');

  const [keyconcept_name, setKeyconcept_name] = useState('');
  const [openKeyconcept, setOpenKeyconcept] = useState(false);
  const [keyConceptSequence, setKeyConceptSequence] = useState('');
  const [isQuiz, setIsQuiz] = useState(false);
  const [overviewClicked, setOverviewClicked] = useState(false);
  const [synClicked, setSynClicked] = useState(false);
  const [fileSubmitYear, setFileSubmitYear] = useState(false);
  const [fileSubmitPortion, setFileSubmitPortion] = useState(false);
  const [wholeQuestion, setWholeQuestion] = useState();

  const [gdriveresult, setgdriveresult] = useState(null);
  const [openPicker, authResponse] = useDrivePicker();
  const [homeworkText, setHomeworkText] = useState();
  const clientid =
    '841262037974-me0t2cr0mj4bb4hnu54a8ou6ph0j15a1.apps.googleusercontent.com';
  const apikey = 'AIzaSyC9kPCpcCVxgZ3eiWwdLWKJBmgfieikspI';
  let token = localStorage.getItem('drivetoken');
  // console.log(token ,'token');
  authResponse && localStorage.setItem('drivetoken', authResponse?.access_token);
  // console.log('authResponse?.access_token', authResponse?.access_token);

  const warning = () => {
    Modal.warning({
      title: 'Please Submit Files',
      content: 'You Have Attached Files But Not Submitted',
    });
  };
  const formik = useFormik({
    initialValues: {
      academic_year: '',
      volume: '',
      grade: '',
      subject: '',
      chapter: '',
      board: '',
      modules: '',
      topic: '',
      period: '',
    },
    validationSchema,
    onSubmit: (values, extra) => {
      // setExpandFilter(false);
      handleShowSecondView();
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const resetToInitialState = () => {
    setShowSecondView(false);
    setselectedPeriod(null);
    setHomeworkText('');
    setActiveTab(initialState.activeTab);
    // setContentTypesState(initialState.contentTypeState);
    formik.resetForm();
  };
  // const discardChanges = () => {
  // setShowSecondView(false);
  // setselectedPeriod(null);
  // setActiveTab(initialState.activeTab);
  // }
  const handleClickOpen = () => {
    setOpenModule(true);
  };

  const handleClose = () => {
    setOpenModule(false);
  };
  const handleClickOpenChapter = () => {
    setOpenChapter(true);
  };

  const handleCloseKeyconcept = () => {
    setOpenKeyconcept(false);
    setKeyconcept_name('');
  };
  const handleClickOpenKeyconcept = () => {
    setOpenKeyconcept(true);
    setKeyconcept_name('');
  };

  const handleCloseChapter = () => {
    setOpenChapter(false);
    setChapter_name('');
  };
  const getVolumes = async () => {
    try {
      const volumes = await fetchVolumes();
      setVolumes(volumes);
    } catch (e) {
      setVolumes([]);
    }
  };

  const getAcademicYears = async () => {
    try {
      const years = await fetchAcademicYears();
      setAcademicYears(years);
    } catch (e) {
      setAcademicYears([]);
    }
  };

  const getGrades = async () => {
    try {
      const years = await fetchGrades();
      setGrades(years);
    } catch (e) {
      setGrades([]);
    }
  };

  const getSubjects = async (gradeId) => {
    try {
      const subjects = await fetchSubjects(gradeId);
      setSubjects(subjects);
    } catch (e) {
      setSubjects([]);
    }
  };

  const getBoard = async () => {
    try {
      const board = await fetchBoard();
      setAddmoduleButton(false);
      setBoard(board);
    } catch (e) {
      setBoard([]);
    }
  };

  const getModule = async (yearId, volumeId, gradeId, boardId) => {
    try {
      const modules = await fetchModule(yearId, volumeId, gradeId, boardId);
      setAddChapterButton(false);
      setModule(modules);
    } catch (e) {
      setModule([]);
    }
  };
  const getTopic = async (Id) => {
    try {
      const topics = await fetchTopics(Id);
      setTopics(topics);
    } catch (e) {
      setTopics([]);
    }
  };

  const getChapters = async (
    gradeSubjectMappingId,
    volumeId,
    academicYearId,
    board,
    modules
  ) => {
    try {
      const chapters = await fetchChapters(
        gradeSubjectMappingId,
        volumeId,
        academicYearId,
        board,
        modules
      );
      setAddKeyconceptButton(false);
      setChapters(chapters);
      // let chaptersInitialState = chapters.length? chapters.map((chapter) => ({
      // name: chapter.chapter_name,
      // periods: Array.from({length: chapter.no_of_periods}, (_, index) =>({name: `Period-${index}`, contentTypes: contentTypesInitialState }) ).filter((_,index) => index!==0);
      // })): []
      // setChaptersState(chaptersInitialState)
    } catch (e) {
      setChapters([]);
    }
  };

  const getPeriodDetails = async (topicId, chapterId) => {
    try {
      if (topicId && chapterId) {
        const topicDetails = await periodDetails(topicId, chapterId);
        if (topicDetails?.total_count > 0) {
          setPeriods(topicDetails?.total_count);
          setPeriodCount(topicDetails?.total_count);
          const periodsState = Array.from(
            { length: topicDetails?.total_count },
            (_, index) => ({
              id: `${topicDetails?.periods[index]?.id}`,
              period: `${topicDetails?.periods[index]?.period}`,
              contentTypes: contentTypes?.map((type) => {
                return {
                  currentUploadedFile: null,
                  name: type,
                  attachments: [],
                };
              }),
              topic_id: formik.values.topic.id,
              chapter: formik.values.chapter.id,
              sequence: topicDetails?.periods[index]?.sequence,
            })
          );
          setPeriodsState(periodsState);
          setselectedPeriod(periodsState[0]);
        } else {
          const periodsCreate = Array.from({ length: 1 }, (_, index) => ({
            period: `${periodName}-${index + 1}`,
            contentTypes: contentTypes.map((type) => {
              return {
                currentUploadedFile: null,
                name: type,
                attachments: [],
              };
            }),
            topic_id: formik.values.topic.id,
            chapter: formik.values.chapter.id,
            sequence: index + 1,
          }));
          handleCreatePeriods(periodsCreate);
        }
      }
    } catch (e) {
      setAlert('error', e);
    }
  };
  useEffect(() => {
    if (formik.values.topic) {
      try {
        getPeriodDetails(formik.values.topic.id, formik.values.chapter.id);
      } catch (e) {
        setAlert('error', e);
      }
    }
  }, [formik.values.topic]);

  const handleAddPeriods = () => {
    if (periodsState.length > 0) {
      const periodsCreate = Array.from({ length: 1 }, (_, index) => ({
        period: `${periodName}-${
          Math.max(...periodsState.map((item) => Number(item.sequence))) + 1
        }`,
        contentTypes: contentTypes.map((type) => ({
          currentUploadedFile: null,
          name: type,
          attachments: [],
        })),
        topic_id: formik.values.topic.id,
        chapter: formik.values.chapter.id,
        // sequence: periodsState?.length + 1,
        sequence: Math.max(...periodsState.map((item) => Number(item.sequence))) + 1,
      }));
      setPeriodStates(periodsCreate);
      handleCreatePeriods(periodsCreate);
    } else {
      setAlert('warning', 'Please Update Period-1 name');
    }
  };

  const handleNext = () => {
    if (periodCount === periods) {
      getPeriodDetails(formik.values.topic.id, formik.values.chapter.id);
      formik.handleSubmit();
    } else if (periodCount < periods) {
      // setPeriods(parseInt(periods - periodCount));
      // const periodsCreate = Array.from(
      // { length: parseInt(periods - periodCount) },
      // (_, index) => ({
      // period: `${periodName}-${index + 1}`,
      // contentTypes: contentTypes.map((type) => ({
      // currentUploadedFile: null,
      // name: type,
      // attachments: [],
      // })),
      // topic_id: formik.values.topic.id,
      // chapter: formik.values.chapter.id,
      // })
      // );
      // setPeriodStates(periodsCreate);
      handleCreatePeriods();
    }
  };

  const handleShowSecondView = () => {
    setShowSecondView(true);
  };

  const gdriveAfterPicker = (attachmentData) => {
    var tempState = periodsState;
    var index = tempState.findIndex((obj) => obj.period === selectedPeriod?.period);
    var tempObj = tempState[index];
    var contentTypeIndex = tempObj.contentTypes.findIndex((contentType) => {
      // contentType.name === selectedPeriod?.period
    });

    let findAttachment = selectedPeriod?.contentTypes.filter((e) => {
      if (e?.name == selectedContentType?.name) {
        if (attachmentData != null) {
          let calldata = e?.attachments.push(attachmentData);
        }
      }
    });
    setUploadingAttachment(false);
    setLoading(false);
  };

  const getAcceptedFileTypes = (contentType) => {
    switch (contentType) {
      case 'Lesson Plan':
        return { fileTypes: 'application/pdf', types: 'pdf' };
      case 'Student Reading Material':
        return { fileTypes: 'application/pdf', types: 'pdf' };
      case 'PPT':
        return {
          fileTypes:
            'application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf, application/vnd.ms-powerpoint',
          types: 'ppt, pptx, pdf',
        };
      case 'Video':
        return { fileTypes: 'video/mp4', types: 'mp4' };
      case 'Audio':
        return { fileTypes: 'audio/*', types: 'audio' };
      case 'Textbook Answer Scheme':
        return {
          fileTypes: 'image/png, image/jpg, image/jpeg,  application/pdf',
          types: 'png, jpg, jpeg,  pdf',
        };
      case 'Teacher Reading Material':
        return { fileTypes: 'application/pdf , video/mp4', types: 'pdf, mp4' };
      case 'Activity Sheet':
        return {
          fileTypes: 'image/png, image/jpg, image/jpeg,  application/pdf',
          types: 'png, jpg, jpeg,  pdf',
        };
      case 'Homework':
        return {
          fileTypes:
            'image/png, image/jpg, image/jpeg, application/pdf,audio/*,video/mp4',
          types: 'png, jpg, jpeg, pdf,audio,mp4',
        };
      case 'Question Paper':
        return { fileTypes: 'text', types: 'id' };
      default:
        return { fileTypes: 'image/*', types: 'images' };
    }
  };
  const acceptedFileTypes = getAcceptedFileTypes(selectedContentType?.name).types;
  let gdriveDocumentType;
  if (selectedContentType?.name === 'Lesson Plan') {
    gdriveDocumentType = 'PDFS';
  } else if (selectedContentType?.name === 'Student Reading Material') {
    gdriveDocumentType = 'PDFS';
  } else if (selectedContentType?.name === 'PPT') {
    gdriveDocumentType = 'DOCS';
  } else if (selectedContentType?.name === 'Video') {
    gdriveDocumentType = 'DOCS_IMAGES_AND_VIDEOS';
  } else if (selectedContentType?.name === 'Audio') {
    gdriveDocumentType = 'DOCS';
  } else if (selectedContentType?.name === 'Textbook Answer Scheme') {
    gdriveDocumentType = 'DOCS';
  } else if (selectedContentType?.name === 'Teacher Reading Material') {
    gdriveDocumentType = 'DOCS';
  } else if (selectedContentType?.name === 'Activity Sheet') {
    gdriveDocumentType = 'DOCS';
  }

  const startTimerGdrive = () => {
    const currentTime = new Date();
    const timestamp = currentTime.getTime();
    let oldtimestamp = localStorage.getItem('drivetokentime');
    if (oldtimestamp !== null) {
      let newTime = new Date();
      let newtimestamp = newTime.getTime();
      if (newtimestamp - oldtimestamp > 30 * 60 * 1000) {
        localStorage.setItem('drivetokentime', newtimestamp);
        localStorage.removeItem('drivetoken');
      }
    } else {
      localStorage.setItem('drivetokentime', timestamp);
    }
  };

  const uploadFromGoogleDrive = async () => {
    startTimerGdrive();
    let attachmentData;
    const {
      academic_year,
      volume,
      grade,
      subject,
      chapter,
      period,
      board,
    } = formik.values;
    const fd = new FormData();
    fd.set('academic_year_id', academic_year.id);
    fd.set('volume_id', volume.id);
    fd.set('chapter_id', chapter.id);
    fd.set('gs_mapping_id', subject.id);
    fd.set('file_type', selectedContentType.name.toLowerCase().replace(/\s/g, '_'));
    openPicker({
      clientId: clientid,
      developerKey: apikey,
      viewId: gdriveDocumentType,
      token: localStorage.getItem('drivetoken'), // pass oauth token in case you already have one
      supportDrives: true,
      multiselect: false,
      setIncludeFolders: true,
      showUploadFolders: true,
      callbackFunction: async (data) => {
        if (data.action === 'picked') {
          setUploadingAttachment(true);
          setLoading(true);
          fd.set('file_id', data.docs[0]?.id);
          const result = await uploadLessonPlanFileFromDrive(fd);
          if (result?.result !== undefined) {
            setAlert('success', 'File added successfully');
            gdriveAfterPicker(result?.result);
          } else {
            setUploadingAttachment(false);
            setLoading(false);
            setAlert(
              'error',
              result?.description
              // 'Google Spreadsheets, Slides, and Docs cannot be added'
            );
          }

          setgdriveresult(result?.result);
          attachmentData = result?.result;
        }
      },
    });
  };

  const handleSaveAttachments = async () => {
    const {
      academic_year,
      volume,
      grade,
      subject,
      chapter,
      period,
      board,
    } = formik.values;
    if (
      selectedContentType.currentUploadedFile !== null &&
      selectedContentType.currentUploadedFile !== undefined
    ) {
      try {
        // const temp = contentTypesState;
        // const index = temp.findIndex((obj) => obj.name === selectedContentType.name);
        const tempState = periodsState;
        const index = tempState.findIndex((obj) => obj.period === selectedPeriod?.period);
        const tempObj = tempState[index];
        const contentTypeIndex = tempObj.contentTypes.findIndex(
          (contentType) => contentType.name === selectedContentType.name
        );
        const tempContentType = tempObj.contentTypes[contentTypeIndex];
        const fd = new FormData();
        fd.set('file', tempContentType.currentUploadedFile);
        // fd.set('academic_year', academic_year.session_year);
        // fd.set('volume', volume.volume_name);
        // fd.set('grade', grade.grade_name);
        // fd.set('subject', subject.subject.subject_name);
        // fd.set('chapter', chapter.chapter_name);
        // fd.set('period', selectedPeriod?.period);

        fd.set('academic_year_id', academic_year.id);
        fd.set('volume_id', volume.id);
        fd.set('chapter_id', chapter.id);
        fd.set('gs_mapping_id', subject.id);
        // fd.set('file_type', selectedContentType.name);
        fd.set('file_type', selectedContentType.name.toLowerCase().replace(/\s/g, '_'));
        setUploadingAttachment(true);
        setLoading(true);
        let result;
        if (selectedContentType.name.toLowerCase().replace(/\s/g, '_') == 'homework') {
          if (!homeworkText?.trim().length) {
            message.error('Homework Text cannot be empty');
            setLoading(false);
            setUploadingAttachment(false);
            return;
          } else if (homeworkText?.length > 2000) {
            message.error('Homework Text cannot be more than 2000 characters long');
            setLoading(false);
            setUploadingAttachment(false);
            return;
          } else {
            fd.set('homework', homeworkText);
            fd.set('period_name', selectedPeriod?.period);
            fd.set('key_concept', selectedPeriod?.topic_id);
            result = await uploadLessonPlanHomework(fd);
          }
        } else {
          result = await uploadLessonPlanFile(fd);
        }
        if (result?.status_code == 409) {
          message.error('Homework Text cannot be more than 2000 characters long');
          setUploadingAttachment(false);
          setLoading(false);
        } else {
          const tempAttachments = tempContentType.attachments;
          tempAttachments.push(result);
          const resultObject = {
            name: tempContentType.name,
            attachments: tempAttachments,
          };
          tempObj.contentTypes = [
            ...tempObj.contentTypes.slice(0, contentTypeIndex),
            resultObject,
            ...tempObj.contentTypes.slice(contentTypeIndex + 1),
          ];

          const updatedPeriodsState = [
            ...tempState.slice(0, index),
            tempObj,
            ...tempState.slice(index + 1),
          ];
          setPeriodsState(updatedPeriodsState);

          setSelectedContentType({ ...resultObject, canSubmit: true });
          setUploadingAttachment(false);
          setLoading(false);
        }
      } catch (e) {
        setUploadingAttachment(false);
        setLoading(false);
      }
    }
  };
  const handleCreateLessonOverviewSynopsis = async (type) => {
    let reqObj = {
      volume: formik.values.volume.id,
      grade_subject_mapping_id: formik.values.subject.id,
      type: type === 'overview' ? 2 : 1,
      attachment: type === 'overview' ? [overviewFile] : [synopsisFile],
      academic_year_id: formik.values.academic_year.id,
    };

    try {
      const response = await createLessonOverview(reqObj);
      // if (type === 'synopsis') setAlert('success', 'Synopsis Uploaded');
      // else setAlert('success', 'Overview Uploaded');
      if (type === 'synopsis') {
        setAlert('success', 'Portion document Uploaded');
        setFileSubmitPortion(true);
        setLoading(false);
      } else {
        setAlert('success', 'Yearly Curriculum Uploaded');
        setFileSubmitYear(true);
        setLoading(false);
      }
    } catch (e) {
      setAlert('error', 'Failed to upload synopsis');
      if (type === 'synopsis') {
        setFileSubmitPortion(false);
        setLoading(false);
      } else {
        setFileSubmitYear(false);
        setLoading(false);
      }
    }
  };

  const handleSynopsisOverviewFileUpload = async (file, type) => {
    const { academic_year, volume, grade, subject, chapter, period } = formik.values;
    if (chapter == '') {
      setAlert('error', 'Please Select Filters First');
    } else {
      const errors = validateSynopsisOverviewUpload(formik.values);

      if (type === 'overview' || type === 'synopsis') {
        if (errors.includes['chapter']) {
          if (errors.length > 0) {
            setAlert('error', `Please provide ${errors.join(', ')} from Periods tab`);
            return;
          }
        }
      } else if (errors.length > 0) {
        setAlert('error', `Please provide ${errors.join(', ')}`);
        return;
      }
      setLoading(true);
      const fd = new FormData();
      fd.set('file', file);

      fd.set('academic_year_id', academic_year.id);
      fd.set('volume_id', volume.id);
      fd.set('gs_mapping_id', subject.id);

      if (type === 'overview') {
        fd.set('overview', 1);
        fd.set('file_type', 'pdf');
      } else if (type === 'synopsis') {
        fd.set('synopsis', 1);
        fd.set('file_type', 'pdf');
      } else {
        fd.set('period', period);
        // fd.set('chapter', chapter?.chapter_name);
        fd.set('chapter_id', chapter.id);
        fd.set('file_type', selectedContentType);
      }
      try {
        const result = await uploadLessonPlanFile(fd);

        if (type === 'overview') {
          setOverviewFile(result);
          setFileSubmitYear(true);
          setLoading(false);
        } else {
          setSynopsisFile(result);
          setFileSubmitPortion(true);
          setLoading(false);
        }
      } catch (e) {
        setAlert('error', 'File upload failed');
        if (type === 'overview') {
          setFileSubmitYear(false);
          setLoading(false);
        } else {
          setFileSubmitPortion(false);
          setLoading(false);
        }
      }
    }
  };

  const handletabChange = (value) => {
    if (overviewFile != null && overviewClicked == false) {
      warning();
    } else if (synopsisFile != null && synClicked == false) {
      warning();
    } else {
      setActiveTab(value);
    }
  };
  const handleCreateLessonPlan = async () => {
    setUploadingAttachment(true);
    const arr = selectedPeriod?.contentTypes;
    const isFileRemoved = selectedPeriod?.isFileRemoved;
    const atleaseOneAttachmentPresent = arr.some((obj) => obj.attachments.length > 0);

    var attachments = arr.reduce(function (map, obj) {
      if (obj?.name == 'Homework') {
        let homeworkAttachments = obj.attachments?.map((item) =>
          item?.url ? item.url[0] : item
        );
        map[obj.name] = homeworkAttachments;
      } else {
        map[obj?.name] = obj?.attachments;
      }
      return map;
    }, {});

    const selectedId = wholeQuestion != undefined ? wholeQuestion?.id : questionPaperIds;
    const reqObj = {
      chapter: formik.values.chapter.id,
      period: selectedPeriod?.period,
      lesson_plan: attachments['Lesson Plan'],
      ppt: attachments['PPT'],
      video_file: attachments['Video'],
      audio_file: attachments['Audio'],
      student_reading_material: attachments['Student Reading Material'],
      textbook_Answer_Scheme: attachments['Textbook Answer Scheme'],
      teacher_material: attachments['Teacher Reading Material'],
      activity_sheet: attachments['Activity Sheet'],
      homework_text: homeworkText,
      hw_url: attachments['Homework'],
      question_paper_id: selectedId,
      is_quiz: istrue,
      topic_id: formik.values.topic.id,
    };
    try {
      const response = await createLessonPlan(reqObj);
      console.log('Attachment 2', response);
      if (response?.description?.includes('value too long')) {
        message.error('Homework Text cannot be more than 2000 characters long');
        setUploadingAttachment(false);
      } else {
        setqpname(wholeQuestion?.paper_name);
        setQuestionPaperIds(wholeQuestion?.id);
        // resetToInitialState();
        setAlert('success', 'Periods added');
        setUploadingAttachment(false);
        // history.goBack();
        // window.location.reload();
      }
    } catch (e) {
      setAlert('error', 'Periods creation failed');
      setUploadingAttachment(false);
    }
  };

  const handleCreateLessonPlanqp = async () => {
    setUploadingAttachment(true);
    const arr = selectedPeriod?.contentTypes;
    const isFileRemoved = selectedPeriod?.isFileRemoved;
    const atleaseOneAttachmentPresent = arr.some((obj) => obj.attachments.length > 0);

    var attachments = arr.reduce(function (map, obj) {
      map[obj?.name] = obj?.attachments;
      return map;
    }, {});
    const selectedId = wholeQuestion != undefined ? wholeQuestion?.id : questionPaperIds;
    const reqObj = {
      chapter: formik.values.chapter.id,
      period: selectedPeriod?.period,
      lesson_plan: attachments['Lesson Plan'],
      ppt: attachments['PPT'],
      video_file: attachments['Video'],
      audio_file: attachments['Audio'],
      student_reading_material: attachments['Student Reading Material'],
      textbook_Answer_Scheme: attachments['Textbook Answer Scheme'],
      teacher_material: attachments['Teacher Reading Material'],
      activity_sheet: attachments['Activity Sheet'],
      homework_text: homeworkText,
      hw_url: attachments['Homework'],
      question_paper_id: null,
      is_quiz: istrue,
      topic_id: formik.values.topic.id,
    };
    try {
      const response = await createLessonPlan(reqObj);
      setqpname();
      setQuestionPaperIds();
      // resetToInitialState();
      setAlert('success', 'Periods added');
      setUploadingAttachment(false);
      // history.goBack();
      // window.location.reload();
    } catch (e) {
      setAlert('error', 'Periods addition failed');
      setUploadingAttachment(false);
    }
  };

  const createChapterhandleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axiosInstance
      .post(endpoints.masterManagement.createUpdatemodule, {
        lt_module_name: module_name,
        grade_subject_mapping: formik.values.subject.id,
        volume: formik.values.volume.id,
        academic_year: formik.values.academic_year.id,
        board: formik.values.board.id,
        sequence: Number(moduleSequenceNumber),
      })
      .then((result) => {
        if (result.status === 201) {
          {
            getModule(
              formik.values.academic_year.id,
              formik.values.volume.id,
              formik.values.subject.id,
              formik.values.board.id
            );
            setModuleSequenceNumber();
            handleClose();
            setLoading(false);
            setAlert('success', result.statusText);
          }
        } else {
          setLoading(false);
          setAlert('error', result.statusText);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data.message == 'Module existed') {
          setAlert('error', 'Module Name Already Exists');
        }
        if (error.response.data.message == 'Module Name Already Exists') {
          setAlert('error', 'Module Name Already Exists');
        }
        if (error.response.data.message == 'duplicate sequence not allowed.') {
          setAlert('error', 'Module Already Exists');
        }
      });
  };
  const createKeyconcepthandleSubmit = (e) => {
    if (!formik.values.modules) {
      return setAlert('error', 'Please Select Module !');
    } else {
      e.preventDefault();
      setLoading(true);
      axiosInstance
        .post(endpoints.masterManagement.createChapters, {
          grade_subject_mapping: formik.values.subject.id,
          volume: formik.values.volume.id,
          chapter_name: chapter_name.trim(),
          academic_year: formik.values.academic_year.id,
          board: formik.values.board.id,
          lt_module: formik.values.modules.id,
          sequence: Number(chapterSequence),
        })
        .then((result) => {
          if (result.data.status_code === 200) {
            {
              handleCloseChapter();
              const {
                grade,
                subject,
                volume,
                academic_year,
                board,
                modules,
              } = formik.values;
              getChapters(subject.id, volume.id, academic_year.id, board.id, modules.id);
              setChapterSequence();
              setLoading(false);
              setAlert('success', result.data.message);
            }
          } else if (result.data.status_code === 404) {
            setLoading(false);
            setAlert('error', 'Please Enter Valid Name and Sequence Number');
          } else {
            setLoading(false);
            setAlert('error', result.statusText);
          }
        })
        .catch((error) => {
          setLoading(false);
          // setAlert('error', error.statusText);
          if (error.response.data.message == 'Chapter is existed') {
            setAlert('error', 'Chapter Name Already Exists');
          }
          if (error.response.data.message == 'Duplicate sequence not allowed.') {
            setAlert('error', 'Chapter Already Exists');
          }
        });
    }
  };
  const createKeyPeriodhandleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const params = {
      chapter: formik.values.chapter.id,
      topic_name: keyconcept_name,
      board: formik.values.board.id,
      sequence: Number(keyConceptSequence),
    };
    axiosInstance
      .post(`${endpoints.masterManagement.createTopic}`, params)
      .then((result) => {
        if (result.data.status_code === 200) {
          handleCloseKeyconcept();
          setKeyConceptSequence();
          setLoading(false);
          setAlert('success', result.data.message);
          getTopic(formik.values.chapter?.id);
        }
        if (result.data.status_code === 404) {
          setLoading(false);
          if (
            result.data.description == 'Key Concept name already exists for this chapter'
          ) {
            setAlert('error', 'Key Concept Name Already Exists');
          } else {
            setAlert('error', 'Please Enter Valid Name and Sequence Number');
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data.message == 'Duplicate sequence not allowed.') {
          setAlert('error', 'Key Concept Already Exists');
        } else {
          setAlert('error', error.message);
        }
      });
  };
  useEffect(() => {
    if (!Array.isArray(selectedContentType)) {
      const { attachments, isFileRemoved, canSubmit } = selectedContentType || {};
      // if((Array.isArray(attachments)&& attachments.length) || isFileRemoved){
      if (canSubmit || isFileRemoved) {
        handleCreateLessonPlan();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContentType]);

  const handleChangeUploadedFile = (file, type, period) => {
    // const type = selectedContentType?.name;
    const tempState = periodsState;
    const index = tempState.findIndex((obj) => obj.period === period);
    const tempObj = tempState[index];
    const contentTypeIndex = tempObj.contentTypes.findIndex(
      (contentType) => contentType.name === type
    );

    const tempContentType = tempObj.contentTypes[contentTypeIndex];
    tempContentType.currentUploadedFile = file;

    tempObj.contentTypes = [
      ...tempObj?.contentTypes.slice(0, contentTypeIndex),
      tempContentType,
      ...tempObj?.contentTypes.slice(contentTypeIndex + 1),
    ];
    setSelectedContentType(tempContentType);
    setPeriodsState([
      ...tempState?.slice(0, index),
      tempObj,
      ...tempState?.slice(index + 1),
    ]);
    // setCurrentFile(file);
  };

  useEffect(() => {
    formik.setFieldValue('academic_year', filterData?.year);
    formik.setFieldValue('volume', filterData?.volume);
    formik.setFieldValue('grade', filterData?.grade);
    formik.setFieldValue('subject', filterData?.subject?.subject);
    formik.setFieldValue('board', '');
    formik.setFieldValue('modules', '');
    formik.setFieldValue('chapter', '');
    formik.setFieldValue('topic', '');
  }, []);
  useEffect(() => {
    if (typeof overviewFile == 'string') {
      setOverviewClicked(true);
      handleCreateLessonOverviewSynopsis('overview');
    }
  }, [overviewFile]);
  useEffect(() => {
    if (typeof synopsisFile == 'string') {
      setSynClicked(true);
      handleCreateLessonOverviewSynopsis('synopsis');
    }
  }, [synopsisFile]);

  useEffect(() => {
    const { grade, subject, volume, academic_year, board, modules } = formik.values;
    formik.setFieldValue('chapter', '');
    if (subject && volume && academic_year && board && modules) {
      getChapters(subject.id, volume.id, academic_year.id, board.id, modules.id);
    } else {
      setChapters([]);
    }
  }, [
    formik.values.grade,
    formik.values.subject,
    formik.values.volume,
    formik.values.academic_year,
    formik.values.board,
    formik.values.modules,
  ]);

  useEffect(() => {
    formik.setFieldValue('subject', '');
    if (formik.values.grade) {
      getSubjects(formik.values.grade.id);
    } else {
      setSubjects([]);
    }
  }, [formik.values.grade]);

  useEffect(() => {
    formik.setFieldValue('board', '');
    if (formik.values.subject) {
      getBoard(formik.values.board.id);
    } else {
      setBoard([]);
    }
  }, [formik.values.subject]);

  useEffect(() => {
    formik.setFieldValue('modules', '');
    if (formik.values.board) {
      getModule(
        formik.values.academic_year.id,
        formik.values.volume.id,
        formik.values.subject.id,
        formik.values.board.id
      );
    } else {
      setBoard([]);
    }
  }, [formik.values.board]);

  useEffect(() => {
    getVolumes();
    getAcademicYears();
    getGrades();
    getBoard();
    const contentTypesInitialState = contentTypes?.map((type) => ({
      currentUploadedFile: null,
      name: type,
      attachments: [],
    }));
    setContentTypesState(contentTypesInitialState);
    setSelectedContentType(contentTypesInitialState[0]);
  }, []);

  useEffect(() => {
    if (formik.values.chapter) {
      getTopic(formik.values.chapter?.id);
    }
  }, [formik.values.chapter]);

  // useEffect(() => {
  // if (formik.values.topic) {
  // const periodsState = Array.from(
  // { length: periods },
  // (_, index) => ({
  // period: `${periodName}-${index + 1}`,
  // contentTypes: contentTypes.map((type) => ({
  // currentUploadedFile: null,
  // name: type,
  // attachments: [],
  // })),
  // })
  // );
  // setselectedPeriod(periodsState[0]);
  // }
  // }, [periods])

  const handleCreatePeriods = async (periodObj) => {
    try {
      const reqObj = {
        peroid_creation: true,
        periods: JSON.stringify(periodObj),
      };
      const periodsCreate = await createPeriods(reqObj);
      // setPeriods(periods + 1);
      getPeriodDetails(formik.values.topic.id, formik.values.chapter.id);
    } catch (e) {
      setAlert('error', e);
    }
  };

  const fetchAttachments = async () => {
    try {
      const attachments = await getAttachments(
        formik.values.chapter.id,
        selectedPeriod?.period,
        selectedPeriod?.topic_id
      );
      const tempState = periodsState;
      const index = tempState.findIndex((obj) => obj.period === selectedPeriod?.period);
      const tempObj = tempState[index];
      setQuestionPaperIds([]);
      setqpname();
      setWholeQuestion();
      setIsQuiz(false);
      setHomeworkText(
        attachments?.filter((item) => item.document_type == 'Homework')[0]?.homework_text
      );
      attachments.forEach((attachment) => {
        if (
          attachment.document_type === '' ||
          attachment.document_type === 'QuestionPaper' ||
          attachment.document_type === 'Question Paper'
        ) {
          const index = tempObj.contentTypes.findIndex(
            (obj) =>
              obj.name.toLowerCase() ===
              `${'QuestionPaper'}`.split('_').join(' ').toLowerCase()
          );
          setQuestionPaperIds(attachment?.quesion_paper_ids[0]?.id);
          setqpname(attachment?.quesion_paper_ids[0]?.paper_name);
          setIsQuiz(attachment?.is_quiz);
        } else {
          const index = tempObj.contentTypes.findIndex(
            (obj) =>
              obj.name.toLowerCase() ===
              attachment.document_type.split('_').join(' ').toLowerCase()
          );
          const tempContentType = tempObj.contentTypes[index];
          {
          }
          attachment.media_file.forEach((file) => {
            if (!tempContentType.attachments.includes(file)) {
              tempContentType.attachments.push(file);
            }
          });
          tempObj.contentTypes = [
            ...tempObj.contentTypes.slice(0, index),
            tempContentType,
            ...tempObj.contentTypes.slice(index + 1),
          ];
        }
      });
      setPeriodsState([
        ...tempState.slice(0, index),
        tempObj,
        ...tempState.slice(index + 1),
      ]);
    } catch (e) {
      setAlert('error', 'Failed to fetch attachments');
    }
  };

  useEffect(() => {
    if (formik.values.chapter) {
      fetchAttachments();
    }
  }, [selectedPeriod]);

  const currentUploadedFile = selectedPeriod?.contentTypes.find(
    (contentType) => contentType?.name === selectedContentType?.name
  )?.currentUploadedFile;

  const currentAttachments = selectedPeriod?.contentTypes.find(
    (contentType) => contentType?.name === selectedContentType?.name
  )?.attachments;

  const clearOverView = () => {
    setYearlyCurrFile(null);
    setOverviewFile(null);
    setOverviewClicked(false);
  };

  const clearSyn = () => {
    setPortionDocumentFile(null);
    setSynopsisFile(null);
    setSynClicked(false);
  };

  return (
    <>
      <Layout>
        {loading ? <Loading message='Loading...' /> : null}
        <div className='create-lesson-plan-container'>
          <Dialog open={openKeyconcept} onClose={handleCloseKeyconcept}>
            <form onSubmit={createKeyPeriodhandleSubmit}>
              <DialogTitle>Add KeyConcept</DialogTitle>
              <DialogContent>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                    <FormControl fullWidth variant='outlined'>
                      <Autocomplete
                        id='academic_year'
                        name='academic_year'
                        onChange={(e, value) => {
                          formik.setFieldValue('volume', '');
                          formik.setFieldValue('grade', '');
                          formik.setFieldValue('subject', '');
                          formik.setFieldValue('board', '');
                          formik.setFieldValue('modules', '');
                          formik.setFieldValue('chapter', '');
                          formik.setFieldValue('topic', '');
                          setShowSecondView(false);
                          if (value) {
                            formik.setFieldValue('academic_year', value);
                          }
                        }}
                        value={formik.values.academic_year}
                        options={academicYears}
                        getOptionLabel={(option) => option.session_year || ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            label='Academic year'
                            placeholder='Academic year'
                          />
                        )}
                        size='small'
                      />
                      <FormHelperText style={{ color: 'red' }}>
                        {formik.errors.academic_year ? formik.errors.academic_year : ''}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                    <FormControl fullWidth variant='outlined'>
                      <Autocomplete
                        id='volume'
                        name='volume'
                        onChange={(e, value) => {
                          formik.setFieldValue('grade', '');
                          formik.setFieldValue('subject', '');
                          formik.setFieldValue('board', '');
                          formik.setFieldValue('modules', '');
                          formik.setFieldValue('chapter', '');
                          formik.setFieldValue('topic', '');
                          setShowSecondView(false);
                          if (value) {
                            formik.setFieldValue('volume', value);
                          }
                        }}
                        value={formik.values.volume}
                        options={volumes}
                        getOptionLabel={(option) => option.volume_name || ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            label='Volume'
                            placeholder='Volume'
                          />
                        )}
                        size='small'
                      />
                      <FormHelperText style={{ color: 'red' }}>
                        {formik.errors.academic_year ? formik.errors.academic_year : ''}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                    <FormControl fullWidth variant='outlined'>
                      <Autocomplete
                        id='grade'
                        name='grade'
                        onChange={
                          (e, value) => {
                            formik.setFieldValue('subject', '');
                            formik.setFieldValue('board', '');
                            formik.setFieldValue('modules', '');
                            formik.setFieldValue('chapter', '');
                            formik.setFieldValue('topic', '');
                            setShowSecondView(false);
                            if (value) {
                              formik.setFieldValue('grade', value);
                            }
                          }
                          // formik.setFieldValue('branch', value);
                          // formik.setFieldValue('grade', []);
                          // formik.setFieldValue('section', []);
                          // formik.setFieldValue('subjects', []);
                          // handleChangeBranch(value ? [value] : null);
                        }
                        value={formik.values.grade}
                        options={grades}
                        getOptionLabel={(option) => option.grade_name || ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            label='Grade'
                            placeholder='Grade'
                          />
                        )}
                        size='small'
                      />
                      <FormHelperText style={{ color: 'red' }}>
                        {formik.errors.grade ? formik.errors.grade : ''}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                    <FormControl fullWidth variant='outlined'>
                      <Autocomplete
                        id='subject'
                        name='subject'
                        onChange={(e, value) => {
                          formik.setFieldValue('board', '');
                          formik.setFieldValue('modules', '');
                          formik.setFieldValue('chapter', '');
                          formik.setFieldValue('topic', '');
                          setShowSecondView(false);
                          if (value) {
                            formik.setFieldValue('subject', value);
                          }
                        }}
                        value={formik.values.subject}
                        options={subjects}
                        getOptionLabel={(option) => option.subject?.subject_name || ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            label='Subject'
                            placeholder='Subject'
                          />
                        )}
                        size='small'
                      />
                      <FormHelperText style={{ color: 'red' }}>
                        {formik.errors.subject ? formik.errors.subject : ''}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                    <FormControl fullWidth variant='outlined'>
                      <Autocomplete
                        id='board'
                        name='board'
                        onChange={(e, value) => {
                          formik.setFieldValue('modules', '');
                          formik.setFieldValue('chapter', '');
                          formik.setFieldValue('topic', '');
                          setShowSecondView(false);
                          if (value) {
                            formik.setFieldValue('board', value);
                          }
                        }}
                        value={formik.values.board}
                        options={board}
                        getOptionLabel={(option) => option?.board_name || ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            label='Board'
                            placeholder='Board'
                          />
                        )}
                        size='small'
                      />
                      {/* <FormHelperText style={{ color: 'red' }}>
{formik.errors.subject ? formik.errors.subject : ''}
</FormHelperText> */}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                    <FormControl fullWidth variant='outlined'>
                      <Autocomplete
                        id='modules'
                        name='modules'
                        onChange={(e, value) => {
                          formik.setFieldValue('chapter', '');
                          formik.setFieldValue('topic', '');
                          setShowSecondView(false);
                          if (value) {
                            formik.setFieldValue('modules', value);
                          }
                        }}
                        value={formik.values.modules}
                        options={modules}
                        getOptionLabel={(option) => option?.lt_module_name || ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            label='Module'
                            placeholder='Module'
                          />
                        )}
                        size='small'
                      />
                      {/* <FormHelperText style={{ color: 'red' }}>
{formik.errors.subject ? formik.errors.subject : ''}
</FormHelperText> */}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                    <FormControl fullWidth variant='outlined'>
                      <Autocomplete
                        id='chapter'
                        name='chapter'
                        onChange={(e, value) => {
                          setShowSecondView(false);
                          formik.setFieldValue('topic', '');
                          if (value) {
                            formik.setFieldValue('chapter', value);
                          }
                        }}
                        value={formik.values.chapter}
                        options={chapters}
                        getOptionLabel={(option) => option.chapter_name || ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            label='Chapter'
                            placeholder='Chapter'
                          />
                        )}
                        size='small'
                      />
                      <FormHelperText style={{ color: 'red' }}>
                        {formik.errors.chapter ? formik.errors.chapter : ''}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                    <TextField
                      id='Key concept id'
                      label='KeyConcept Name'
                      style={{ width: '100%' }}
                      variant='outlined'
                      size='small'
                      placeholder='Enter KeyConceptName'
                      value={keyconcept_name}
                      // inputProps={{maxLength:7,pattern:'^[0-9]{4}-[0-9]{2}'}}
                      name='Keyconcept Name'
                      onChange={(e) => setKeyconcept_name(e.target.value)}
                      required
                    />
                    {/* </Grid> */}
                  </Grid>
                  <Grid item xs={12} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                    <TextField
                      id='keyconceptsequencenumber'
                      type='number'
                      value={keyConceptSequence}
                      label='Key Concept Sequence'
                      placeholder='Enter Key Concept Sequence Number'
                      onChange={(e) => setKeyConceptSequence(e.target.value)}
                      style={{ width: '100%' }}
                      variant='outlined'
                      size='small'
                      SelectProps={{
                        native: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Grid
                  container
                  spacing={isMobile ? 1 : 5}
                  style={{ width: '95%', margin: '10px' }}
                >
                  <Grid
                    item
                    xs={6}
                    sm={2}
                    className={isMobile ? '' : 'addEditButtonsPadding'}
                  >
                    <Button
                      variant='contained'
                      className='custom_button_master labelColor'
                      size='medium'
                      onClick={handleCloseKeyconcept}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={2}
                    className={isMobile ? '' : 'addEditButtonsPadding'}
                  >
                    <Button
                      variant='contained'
                      style={{ color: 'white' }}
                      color='primary'
                      className='custom_button_master'
                      size='medium'
                      type='submit'
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </form>
          </Dialog>
          <Dialog open={openChapter} onClose={handleCloseChapter}>
            {/* <form onSubmit={createKeyconcepthandleSubmit}> */}
            <DialogTitle>Add Chapter</DialogTitle>
            <DialogContent>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                  <FormControl fullWidth variant='outlined'>
                    <Autocomplete
                      id='academic_year'
                      name='academic_year'
                      onChange={(e, value) => {
                        formik.setFieldValue('volume', '');
                        formik.setFieldValue('grade', '');
                        formik.setFieldValue('subject', '');
                        formik.setFieldValue('board', '');
                        formik.setFieldValue('modules', '');
                        formik.setFieldValue('chapter', '');
                        formik.setFieldValue('topic', '');
                        setShowSecondView(false);
                        if (value) {
                          formik.setFieldValue('academic_year', value);
                        }
                      }}
                      value={formik.values.academic_year}
                      options={academicYears}
                      getOptionLabel={(option) => option.session_year || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='outlined'
                          label='Academic year'
                          placeholder='Academic year'
                        />
                      )}
                      size='small'
                    />
                    <FormHelperText style={{ color: 'red' }}>
                      {formik.errors.academic_year ? formik.errors.academic_year : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                  <FormControl fullWidth variant='outlined'>
                    <Autocomplete
                      id='volume'
                      name='volume'
                      onChange={(e, value) => {
                        formik.setFieldValue('grade', '');
                        formik.setFieldValue('subject', '');
                        formik.setFieldValue('board', '');
                        formik.setFieldValue('modules', '');
                        formik.setFieldValue('chapter', '');
                        formik.setFieldValue('topic', '');
                        setShowSecondView(false);
                        if (value) {
                          formik.setFieldValue('volume', value);
                        }
                      }}
                      value={formik.values.volume}
                      options={volumes}
                      getOptionLabel={(option) => option.volume_name || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='outlined'
                          label='Volume'
                          placeholder='Volume'
                        />
                      )}
                      size='small'
                    />
                    <FormHelperText style={{ color: 'red' }}>
                      {formik.errors.academic_year ? formik.errors.academic_year : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                  <FormControl fullWidth variant='outlined'>
                    <Autocomplete
                      id='grade'
                      name='grade'
                      onChange={
                        (e, value) => {
                          formik.setFieldValue('subject', '');
                          formik.setFieldValue('board', '');
                          formik.setFieldValue('modules', '');
                          formik.setFieldValue('chapter', '');
                          formik.setFieldValue('topic', '');
                          setShowSecondView(false);
                          if (value) {
                            formik.setFieldValue('grade', value);
                          }
                        }
                        // formik.setFieldValue('branch', value);
                        // formik.setFieldValue('grade', []);
                        // formik.setFieldValue('section', []);
                        // formik.setFieldValue('subjects', []);
                        // handleChangeBranch(value ? [value] : null);
                      }
                      value={formik.values.grade}
                      options={grades}
                      getOptionLabel={(option) => option.grade_name || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='outlined'
                          label='Grade'
                          placeholder='Grade'
                        />
                      )}
                      size='small'
                    />
                    <FormHelperText style={{ color: 'red' }}>
                      {formik.errors.grade ? formik.errors.grade : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                  <FormControl fullWidth variant='outlined'>
                    <Autocomplete
                      id='subject'
                      name='subject'
                      onChange={(e, value) => {
                        formik.setFieldValue('board', '');
                        formik.setFieldValue('modules', '');
                        formik.setFieldValue('chapter', '');
                        formik.setFieldValue('topic', '');
                        setShowSecondView(false);
                        if (value) {
                          formik.setFieldValue('subject', value);
                        }
                      }}
                      value={formik.values.subject}
                      options={subjects}
                      getOptionLabel={(option) => option.subject?.subject_name || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='outlined'
                          label='Subject'
                          placeholder='Subject'
                        />
                      )}
                      size='small'
                    />
                    <FormHelperText style={{ color: 'red' }}>
                      {formik.errors.subject ? formik.errors.subject : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                  <FormControl fullWidth variant='outlined'>
                    <Autocomplete
                      id='board'
                      name='board'
                      onChange={(e, value) => {
                        formik.setFieldValue('modules', '');
                        formik.setFieldValue('chapter', '');
                        formik.setFieldValue('topic', '');
                        setShowSecondView(false);
                        if (value) {
                          formik.setFieldValue('board', value);
                        }
                      }}
                      value={formik.values.board}
                      options={board}
                      getOptionLabel={(option) => option?.board_name || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='outlined'
                          label='Board'
                          placeholder='Board'
                        />
                      )}
                      size='small'
                    />
                    {/* <FormHelperText style={{ color: 'red' }}>
{formik.errors.subject ? formik.errors.subject : ''}
</FormHelperText> */}
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                  <FormControl fullWidth variant='outlined'>
                    <Autocomplete
                      id='modules'
                      name='modules'
                      onChange={(e, value) => {
                        formik.setFieldValue('chapter', '');
                        formik.setFieldValue('topic', '');
                        setShowSecondView(false);
                        if (value) {
                          formik.setFieldValue('modules', value);
                        }
                      }}
                      value={formik.values.modules}
                      options={modules}
                      getOptionLabel={(option) => option?.lt_module_name || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='outlined'
                          label='Module'
                          placeholder='Module'
                        />
                      )}
                      size='small'
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                  <TextField
                    id='chapterid'
                    label='Chapter Name'
                    style={{ width: '100%' }}
                    variant='outlined'
                    size='small'
                    placeholder='Enter Chapter name'
                    value={chapter_name}
                    // inputProps={{maxLength:7,pattern:'^[0-9]{4}-[0-9]{2}'}}
                    name='Module_Name'
                    onChange={(e) => setChapter_name(e.target.value)}
                    required
                  />
                  {/* </Grid> */}
                </Grid>
                <Grid item xs={12} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                  <TextField
                    id='chaptersequencenumber'
                    type='number'
                    label='Chapter Sequence Number'
                    placeholder='Enter Chapter Sequence Number'
                    onChange={(e) => setChapterSequence(e.target.value)}
                    style={{ width: '100%' }}
                    variant='outlined'
                    size='small'
                    SelectProps={{
                      native: true,
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Grid
                container
                spacing={isMobile ? 1 : 5}
                style={{ width: '95%', margin: '10px' }}
              >
                <Grid
                  item
                  xs={6}
                  sm={2}
                  className={isMobile ? '' : 'addEditButtonsPadding'}
                >
                  <Button
                    variant='contained'
                    className='custom_button_master labelColor'
                    size='medium'
                    onClick={handleCloseChapter}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={2}
                  className={isMobile ? '' : 'addEditButtonsPadding'}
                >
                  <Button
                    variant='contained'
                    style={{ color: 'white' }}
                    color='primary'
                    className='custom_button_master'
                    size='medium'
                    type='submit'
                    onClick={createKeyconcepthandleSubmit}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
            {/* </form> */}
          </Dialog>
          {/* <Dialog open={openModule} onClose={handleClose}>
            <form onSubmit={createChapterhandleSubmit}>
              <DialogTitle>Add Module</DialogTitle>
              <DialogContent>
                <Grid container spacing={5}>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                    <FormControl fullWidth variant='outlined'>
                      <Autocomplete
                        id='academic_year'
                        name='academic_year'
                        onChange={(e, value) => {
                          formik.setFieldValue('volume', '');
                          formik.setFieldValue('grade', '');
                          formik.setFieldValue('subject', '');
                          formik.setFieldValue('board', '');
                          formik.setFieldValue('modules', '');
                          formik.setFieldValue('chapter', '');
                          formik.setFieldValue('topic', '');
                          setShowSecondView(false);
                          if (value) {
                            formik.setFieldValue('academic_year', value);
                          }
                        }}
                        value={formik.values.academic_year}
                        options={academicYears}
                        getOptionLabel={(option) => option.session_year || ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            label='Academic year'
                            placeholder='Academic year'
                          />
                        )}
                        size='small'
                      />
                      <FormHelperText style={{ color: 'red' }}>
                        {formik.errors.academic_year ? formik.errors.academic_year : ''}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                    <FormControl fullWidth variant='outlined'>
                      <Autocomplete
                        id='volume'
                        name='volume'
                        onChange={(e, value) => {
                          formik.setFieldValue('grade', '');
                          formik.setFieldValue('subject', '');
                          formik.setFieldValue('board', '');
                          formik.setFieldValue('modules', '');
                          formik.setFieldValue('chapter', '');
                          formik.setFieldValue('topic', '');
                          setShowSecondView(false);
                          if (value) {
                            formik.setFieldValue('volume', value);
                          }
                        }}
                        value={formik.values.volume}
                        options={volumes}
                        getOptionLabel={(option) => option.volume_name || ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            label='Volume'
                            placeholder='Volume'
                          />
                        )}
                        size='small'
                      />
                      <FormHelperText style={{ color: 'red' }}>
                        {formik.errors.academic_year ? formik.errors.academic_year : ''}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                    <FormControl fullWidth variant='outlined'>
                      <Autocomplete
                        id='grade'
                        name='grade'
                        onChange={
                          (e, value) => {
                            formik.setFieldValue('subject', '');
                            formik.setFieldValue('board', '');
                            formik.setFieldValue('modules', '');
                            formik.setFieldValue('chapter', '');
                            formik.setFieldValue('topic', '');
                            setShowSecondView(false);
                            if (value) {
                              formik.setFieldValue('grade', value);
                            } else {
                              formik.setFieldValue('grade', '');
                            }
                          }
                          // formik.setFieldValue('branch', value);
                          // formik.setFieldValue('grade', []);
                          // formik.setFieldValue('section', []);
                          // formik.setFieldValue('subjects', []);
                          // handleChangeBranch(value ? [value] : null);
                        }
                        value={formik.values.grade}
                        options={grades}
                        getOptionLabel={(option) => option.grade_name || ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            label='Grade'
                            placeholder='Grade'
                          />
                        )}
                        size='small'
                      />
                      <FormHelperText style={{ color: 'red' }}>
                        {formik.errors.grade ? formik.errors.grade : ''}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                    <FormControl fullWidth variant='outlined'>
                      <Autocomplete
                        id='subject'
                        name='subject'
                        onChange={(e, value) => {
                          formik.setFieldValue('board', '');
                          formik.setFieldValue('modules', '');
                          formik.setFieldValue('chapter', '');
                          formik.setFieldValue('topic', '');
                          setShowSecondView(false);
                          if (value) {
                            formik.setFieldValue('subject', value);
                          } else {
                            formik.setFieldValue('subject', '');
                          }
                        }}
                        value={formik.values.subject}
                        options={subjects}
                        getOptionLabel={(option) => option.subject?.subject_name || ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            label='Subject'
                            placeholder='Subject'
                          />
                        )}
                        size='small'
                      />
                      <FormHelperText style={{ color: 'red' }}>
                        {formik.errors.subject ? formik.errors.subject : ''}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                    <FormControl fullWidth variant='outlined'>
                      <Autocomplete
                        id='board'
                        name='board'
                        onChange={(e, value) => {
                          formik.setFieldValue('modules', '');
                          formik.setFieldValue('chapter', '');
                          formik.setFieldValue('topic', '');
                          setShowSecondView(false);
                          if (value) {
                            formik.setFieldValue('board', value);
                          } else {
                            formik.setFieldValue('board', '');
                          }
                        }}
                        value={formik.values.board}
                        options={board}
                        getOptionLabel={(option) => option?.board_name || ''}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            label='Board'
                            placeholder='Board'
                          />
                        )}
                        size='small'
                      />                     
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                    <TextField
                      id='sessionyear'
                      label='Module Name'
                      style={{ width: '100%' }}
                      variant='outlined'
                      size='small'
                      placeholder='Enter Module name'
                      value={module_name}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      // inputProps={{maxLength:7,pattern:'^[0-9]{4}-[0-9]{2}'}}
                      name='Module_Name'
                      onChange={(e) => setModule_name(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                    <TextField
                      id='modulesequencenumber'
                      type='number'
                      label='Module Sequence Number'
                      placeholder='Enter Sequence Number'
                      onChange={(e) => setModuleSequenceNumber(e.target.value)}
                      style={{ width: '100%' }}
                      variant='outlined'
                      size='small'
                      SelectProps={{
                        native: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Grid
                  container
                  spacing={isMobile ? 1 : 5}
                  style={{ width: '95%', margin: '10px' }}
                >
                  <Grid
                    item
                    xs={6}
                    sm={2}
                    className={isMobile ? '' : 'addEditButtonsPadding'}
                  >
                    <Button
                      variant='contained'
                      className='custom_button_master labelColor'
                      size='medium'
                      onClick={handleClose}
                    >
                      Back
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    sm={2}
                    className={isMobile ? '' : 'addEditButtonsPadding'}
                  >
                    <Button
                      variant='contained'
                      style={{ color: 'white' }}
                      color='primary'
                      className='custom_button_master'
                      size='medium'
                      type='submit'
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </form>
          </Dialog> */}

          <div className='lesson-plan-breadcrumb-wrapper'>
            <CommonBreadcrumbs componentName='Periods' childComponentName='Create New' />
          </div>
          <div className='content-container'>
            <Accordion
              className='collapsible-section'
              square
              expanded={expandFilter}
              onChange={() => {}}
            >
              <AccordionDetails>
                <div className='form-grid-container mv-20'>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth variant='outlined'>
                        <Autocomplete
                          id='academic_year'
                          name='academic_year'
                          onChange={(e, value) => {
                            formik.setFieldValue('volume', '');
                            formik.setFieldValue('grade', '');
                            formik.setFieldValue('subject', '');
                            formik.setFieldValue('board', '');
                            formik.setFieldValue('modules', '');
                            formik.setFieldValue('chapter', '');
                            formik.setFieldValue('topic', '');
                            setShowSecondView(false);
                            if (value) {
                              formik.setFieldValue('academic_year', value);
                            } else {
                              formik.setFieldValue('academic_year', '');
                            }
                          }}
                          value={formik.values.academic_year}
                          options={academicYears}
                          getOptionLabel={(option) => option.session_year || ''}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              label='Academic year'
                              placeholder='Academic year'
                            />
                          )}
                          size='small'
                        />
                        <FormHelperText style={{ color: 'red' }}>
                          {formik.errors.academic_year ? formik.errors.academic_year : ''}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth variant='outlined'>
                        <Autocomplete
                          id='volume'
                          name='volume'
                          onChange={(e, value) => {
                            formik.setFieldValue('grade', '');
                            formik.setFieldValue('subject', '');
                            formik.setFieldValue('board', '');
                            formik.setFieldValue('modules', '');
                            formik.setFieldValue('chapter', '');
                            formik.setFieldValue('topic', '');
                            setShowSecondView(false);
                            if (value) {
                              formik.setFieldValue('volume', value);
                            } else {
                              formik.setFieldValue('volume', '');
                            }
                          }}
                          value={formik.values.volume}
                          options={volumes}
                          getOptionLabel={(option) => option.volume_name || ''}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              label='Volume'
                              placeholder='Volume'
                            />
                          )}
                          size='small'
                        />
                        <FormHelperText style={{ color: 'red' }}>
                          {formik.errors.academic_year ? formik.errors.academic_year : ''}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth variant='outlined'>
                        <Autocomplete
                          id='grade'
                          name='grade'
                          onChange={(e, value) => {
                            formik.setFieldValue('subject', '');
                            formik.setFieldValue('board', '');
                            formik.setFieldValue('modules', '');
                            formik.setFieldValue('chapter', '');
                            formik.setFieldValue('topic', '');
                            setShowSecondView(false);
                            if (value) {
                              formik.setFieldValue('grade', value);
                            } else {
                              formik.setFieldValue('grade', '');
                            }
                          }}
                          value={formik.values.grade}
                          options={grades}
                          getOptionLabel={(option) => option.grade_name || ''}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              label='Grade'
                              placeholder='Grade'
                            />
                          )}
                          size='small'
                        />
                        <FormHelperText style={{ color: 'red' }}>
                          {formik.errors.grade ? formik.errors.grade : ''}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth variant='outlined'>
                        <Autocomplete
                          id='subject'
                          name='subject'
                          onChange={(e, value) => {
                            formik.setFieldValue('board', '');
                            formik.setFieldValue('modules', '');
                            formik.setFieldValue('chapter', '');
                            formik.setFieldValue('topic', '');
                            setShowSecondView(false);
                            if (value) {
                              formik.setFieldValue('subject', value);
                            } else {
                              formik.setFieldValue('subject', '');
                            }
                          }}
                          value={formik.values.subject}
                          options={subjects}
                          getOptionLabel={(option) => option.subject?.subject_name || ''}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              label='Subject'
                              placeholder='Subject'
                            />
                          )}
                          size='small'
                        />
                        <FormHelperText style={{ color: 'red' }}>
                          {formik.errors.subject ? formik.errors.subject : ''}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth variant='outlined'>
                        <Autocomplete
                          id='board'
                          name='board'
                          onChange={(e, value) => {
                            setModule([]);
                            setChapters([]);
                            setTopics([]);
                            formik.setFieldValue('modules', '');
                            formik.setFieldValue('chapter', '');
                            formik.setFieldValue('topic', '');
                            setShowSecondView(false);
                            if (value) {
                              formik.setFieldValue('board', value);
                            } else {
                              formik.setFieldValue('board', '');
                            }
                          }}
                          value={formik.values.board}
                          options={board}
                          getOptionLabel={(option) => option?.board_name || ''}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              label='Board'
                              placeholder='Board'
                            />
                          )}
                          size='small'
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth variant='outlined'>
                        <Autocomplete
                          id='modules'
                          name='modules'
                          onChange={(e, value) => {
                            formik.setFieldValue('chapter', '');
                            formik.setFieldValue('topic', '');
                            setShowSecondView(false);
                            if (value) {
                              formik.setFieldValue('modules', value);
                            }
                          }}
                          value={formik.values.modules}
                          options={modules}
                          getOptionLabel={(option) => option?.lt_module_name || ''}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              label='Module'
                              placeholder='Module'
                            />
                          )}
                          size='small'
                        />
                      </FormControl>
                    </Grid>
                    {/* <Grid item xs={1} sm={1} className={isMobile ? '' : 'addEditPadding'}>
                      {!AddmoduleButton && (
                        <Button
                          startIcon={<AddOutlinedIcon style={{ fontSize: '30px' }} />}
                          variant='contained'
                          color='primary'
                          size='small'
                          style={{ color: 'white' }}
                          title='Add Chapter'
                          onClick={handleClickOpen}
                        ></Button>
                      )}
                    </Grid> */}
                    <Grid item md={3} xs={12}>
                      <FormControl fullWidth variant='outlined'>
                        <Autocomplete
                          id='chapter'
                          name='chapter'
                          onChange={(e, value) => {
                            setShowSecondView(false);
                            formik.setFieldValue('topic', '');
                            if (value) {
                              formik.setFieldValue('chapter', value);
                            }
                          }}
                          value={formik.values.chapter}
                          options={chapters}
                          getOptionLabel={(option) => option.chapter_name || ''}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              label='Chapter'
                              placeholder='Chapter'
                            />
                          )}
                          size='small'
                        />
                        <FormHelperText style={{ color: 'red' }}>
                          {formik.errors.chapter ? formik.errors.chapter : ''}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={1} sm={1} className={isMobile ? '' : 'addEditPadding'}>
                      {!AddChapterButton && (
                        <Button
                          startIcon={<AddOutlinedIcon style={{ fontSize: '30px' }} />}
                          variant='contained'
                          color='primary'
                          size='small'
                          style={{ color: 'white' }}
                          title='Add KeyConsept'
                          onClick={handleClickOpenChapter}
                        ></Button>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </AccordionDetails>
            </Accordion>
            <div className='divider-container'>
              <Divider />
            </div>
            <div className='tabs-container'>
              <Paper square style={{ boxShadow: 'none' }}>
                <Tabs
                  indicatorColor='primary'
                  textColor='primary'
                  onChange={(event, value) => {
                    // setActiveTab(value);
                    handletabChange(value);
                  }}
                  aria-label='disabled tabs example'
                  style={{ backgroundColor: '#fafafa' }}
                  value={activeTab}
                >
                  {/* <Tab label='Overview' value='overview' />
<Tab label='Synopsis' value='synopsis' /> */}

                  <Tab label='Yearly Curriculum' value='overview' />
                  <Tab label='Portion Document' value='synopsis' />

                  <Tab label='Periods' value='lesson-plan' />
                </Tabs>
                <TabPanel name='overview' value={activeTab}>
                  <DNDFileUpload
                    value={overviewFile}
                    handleChange={(files) => {
                      setYearlyCurrFile(files);
                      setOverviewFile(files);
                      setFileSubmitYear(false);
                    }}
                    fileType='application/pdf'
                    typeNames='pdf'
                  />
                  <div className='row my-3'>
                    <Button
                      onClick={clearOverView}
                      style={{ background: '#dddddd', color: '#00000042' }}
                      className='mx-2'
                      disabled={fileSubmitYear}
                    >
                      Clear
                    </Button>
                    <Button
                      style={{
                        backgroundColor: overviewFile == null ? '#d3d3d3' : '#ff6b6b',
                      }}
                      onClick={() => {
                        handleSynopsisOverviewFileUpload(yearlyCurrFile, 'overview');
                      }}
                      disabled={overviewFile == null ? true : false}
                    >
                      Submit file
                    </Button>
                  </div>
                </TabPanel>
                <TabPanel name='synopsis' value={activeTab}>
                  <DNDFileUpload
                    value={synopsisFile}
                    handleChange={(files) => {
                      // handleSynopsisOverviewFileUpload(files, 'synopsis');
                      setPortionDocumentFile(files);
                      setSynopsisFile(files);
                      setFileSubmitPortion(false);
                    }}
                    fileType='application/pdf'
                    typeNames='pdf'
                  />
                  <div className='row my-3'>
                    <Button
                      onClick={clearSyn}
                      style={{ background: '#dddddd', color: '#00000042' }}
                      className='mx-2'
                      disabled={fileSubmitPortion}
                    >
                      Clear
                    </Button>
                    <Button
                      style={{
                        backgroundColor: synopsisFile == null ? '#d3d3d3' : '#ff6b6b',
                      }}
                      onClick={() => {
                        handleSynopsisOverviewFileUpload(portionDocumentFile, 'synopsis');
                      }}
                      disabled={synopsisFile == null ? true : false}
                    >
                      Submit File
                    </Button>
                  </div>
                </TabPanel>
                <TabPanel name='lesson-plan' value={activeTab}>
                  <div className='lesson-plan-tab-content'>
                    <div className='lesson-plan-first-view'>
                      <div className='form-grid-container mb-20 '>
                        <Grid container spacing={2}>
                          <Grid item md={3} xs={12}>
                            <FormControl fullWidth variant='outlined'>
                              <Autocomplete
                                id='topic'
                                name='topic'
                                onChange={(e, value) => {
                                  setShowSecondView(false);
                                  if (value) {
                                    formik.setFieldValue('topic', value);
                                  }
                                }}
                                value={formik.values.topic}
                                options={topics}
                                getOptionLabel={(option) => option.topic_name || ''}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant='outlined'
                                    label='Key Concept'
                                    placeholder='Select Key Concept'
                                  />
                                )}
                                size='small'
                              />
                              <FormHelperText style={{ color: 'red' }}>
                                {formik.errors.chapter ? formik.errors.chapter : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={1}
                            sm={1}
                            className={isMobile ? '' : 'addEditPadding'}
                          >
                            {!AddKeyconceptButton && (
                              <Button
                                startIcon={
                                  <AddOutlinedIcon style={{ fontSize: '30px' }} />
                                }
                                variant='contained'
                                color='primary'
                                size='small'
                                style={{ color: 'white' }}
                                title='Add KeyConsept'
                                onClick={handleClickOpenKeyconcept}
                              ></Button>
                            )}
                          </Grid>
                          <Grid item md={2} xs={12}>
                            <FormControl variant='outlined' fullWidth size='small'>
                              <OutlinedInput
                                id='periods'
                                name='periods'
                                onChange={(e) => {
                                  setPeriods(e.target.value);
                                }}
                                value={periods}
                                inputProps={{ maxLength: 150 }}
                                type='number'
                                placeholder='Total period'
                                disabled
                              />
                              <FormHelperText style={{ color: 'red' }}>
                                {/* {formik.errors.periods ? formik.errors.periods : ''} */}
                              </FormHelperText>
                            </FormControl>
                          </Grid>

                          <Grid item md={2} xs={12}>
                            <Button
                              color='secondary'
                              variant='contained'
                              onClick={handleAddPeriods}
                              startIcon={<AddOutlinedIcon style={{ fontSize: '30px' }} />}
                            />
                          </Grid>
                          <Grid item md={4} xs={12}>
                            {!showSecondView && periods !== 0 && (
                              <div className='btn-container'>
                                <Button
                                  onClick={handleNext}
                                  disabled={periods !== 0 ? false : true}
                                  // style={{ backgroundColor: '#ff6b6b' }}
                                  color='primary'
                                  variant='contained'
                                  className={
                                    periods !== 0 ? 'next-button-second' : 'next-button'
                                  }
                                >
                                  Next
                                </Button>
                              </div>
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                    {showSecondView && (
                      <div className='lesson-plan-second-view'>
                        <Grid container>
                          <Grid xs={12} md={4} item>
                            <PeriodList
                              value={selectedPeriod}
                              totalPeriods={formik?.values?.chapter?.no_of_periods}
                              periods={periodsState}
                              chapterInfo={formik?.values?.chapter?.id}
                              topicInfo={formik?.values?.topic?.id}
                              setPeriodState={setPeriodsState}
                              setPeriodNames={setPeriodName}
                              getPeriodDetails={getPeriodDetails}
                              periodId={selectedPeriod?.id}
                              onClick={(period) => {
                                setselectedPeriod([]);
                                if (period) {
                                  setselectedPeriod(period);
                                }
                              }}
                            />
                          </Grid>

                          {selectedPeriod ? (
                            <>
                              <Grid xs={12} md={3} item>
                                <ContentTypeList
                                  value={selectedContentType}
                                  contentTypes={selectedPeriod?.contentTypes}
                                  onClick={(contentType) => {
                                    setSelectedContentType([]);
                                    if (contentType) {
                                      setSelectedContentType(contentType);
                                    }
                                    // setSelectedContentType(contentType);
                                  }}
                                />
                              </Grid>
                              <Grid xs={12} md={4} item>
                                {selectedContentType?.name == 'Homework' && (
                                  <Input.TextArea
                                    placeholder='Enter Homework Question'
                                    className='my-2'
                                    rows={3}
                                    value={homeworkText}
                                    onChange={(e) => setHomeworkText(e.target.value)}
                                  />
                                )}
                                {!['Question Paper', 'Homework'].includes(
                                  selectedContentType?.name
                                ) ? (
                                  <>
                                    <Button
                                      style={{
                                        backgroundColor: '#fef8cc',
                                        color: '#222',
                                        textTransform: 'none',
                                      }}
                                      className='btn btn-block btn-sm'
                                      onClick={() => uploadFromGoogleDrive()}
                                    >
                                      <img
                                        src={GOOGLEDRIVE}
                                        className='mr-3'
                                        style={{ width: 25 }}
                                      />
                                      Upload from google drive
                                      <br />
                                      Select File : {acceptedFileTypes}
                                    </Button>
                                    <p className='text-center mt-3'>OR</p>
                                  </>
                                ) : null}

                                <AttachmentList
                                  loading={uploadingAttachment}
                                  value={currentAttachments}
                                  selectedContentType={selectedContentType?.name}
                                  subjectInfo={formik.values?.subject}
                                  gradeInfo={formik.values?.grade}
                                  chapterInfo={formik.values?.chapter}
                                  academic_year={formik.values?.academic_year}
                                  setQuestionPaperIds={setQuestionPaperIds}
                                  questionPaperIds={questionPaperIds}
                                  qpname={qpname}
                                  setqpname={setqpname}
                                  setIsQuiz={setIsQuiz}
                                  isQuiz={isQuiz}
                                  setIstrue={setIstrue}
                                  istrue={istrue}
                                  onChange={(file) => {
                                    if (file) {
                                      handleChangeUploadedFile(
                                        file,
                                        selectedContentType?.name,
                                        selectedPeriod?.period
                                      );
                                    }
                                  }}
                                  currentFile={currentUploadedFile}
                                  onDelete={(file) => {
                                    const type = selectedContentType?.name;
                                    const tempState = periodsState;
                                    const index = tempState.findIndex(
                                      (obj) => obj.period === selectedPeriod?.period
                                    );
                                    const tempObj = tempState[index];
                                    const contentTypeIndex = tempObj.contentTypes.findIndex(
                                      (contentType) => contentType.name === type
                                    );

                                    const tempContentType =
                                      tempObj.contentTypes[contentTypeIndex];

                                    tempContentType.attachments = tempContentType.attachments.filter(
                                      (attachment) => attachment !== file
                                    );

                                    tempObj.contentTypes = [
                                      ...tempObj.contentTypes.slice(0, contentTypeIndex),
                                      tempContentType,
                                      ...tempObj.contentTypes.slice(contentTypeIndex + 1),
                                    ];

                                    setPeriodsState([
                                      ...tempState.slice(0, index),
                                      tempObj,
                                      ...tempState.slice(index + 1),
                                    ]);
                                    setSelectedContentType({
                                      ...tempContentType,
                                      isFileRemoved: true,
                                    });
                                  }}
                                  onSave={handleSaveAttachments}
                                  onSubmit={handleCreateLessonPlan}
                                  onDiscard={resetToInitialState}
                                  wholeQuestion={wholeQuestion}
                                  setWholeQuestion={setWholeQuestion}
                                  handleCreateLessonPlan={handleCreateLessonPlanqp}
                                />
                              </Grid>
                            </>
                          ) : (
                            ''
                          )}
                        </Grid>
                      </div>
                    )}
                  </div>
                </TabPanel>
              </Paper>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CreateLessonPlan;
