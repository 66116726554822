import React, { useContext, useState, useEffect } from 'react';
import { Grid, TextField, Button, TableRow, TableCell, TableBody, Table, TableHead, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import endpoints from '../../config/endpoints';
import axiosInstance from '../../config/axios';
import { AlertNotificationContext } from '../../context-api/alert-context/alert-state';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/AddCircleOutline';
// import './styles.scss';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,

    },
  },
};

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


const CreateEditSchool = ({ editData, setLoading, handleGoBack }) => {
  console.log('editdata', editData)
  let boardlists = [];
  // boardlists=editData?.editData.boards;
  const { setAlert } = useContext(AlertNotificationContext);
  const [noOfSections, setNoOfSections] = useState(1);
  const [schoolName, setSchoolName] = useState('' || editData?.name)
  const [schoolEmail, setSchoolEmail] = useState('' || editData?.email)
  const [schoolLogo, setSchoolLogo] = useState(null)
  const [schoolLogoDisp, setSchoolLogoDisp] = useState(null || editData?.logo)
  const [contact, setContact] = useState('' || editData?.contact)
  const [address, setAddress] = useState('' || editData?.address)
  const [city, setCity] = useState('' || editData?.city)
  const [schoolCode, setSchoolCode] = useState('' || editData?.schoolCode)
  const [domainName, setDomainName] = useState('')
  const [domainField, setDomainField] = useState('' || editData?.domain)
  const [domainFlag, setDomainFlag] = useState(false)
  const [affiliation_number, setAffiliation_number] = useState('' || editData?.affiliation_number)
  const [board, setBoard] = useState([] || editData?.boards);
  const [boardName, setBoardName] = useState('')
  const [boardList, setBoardList] = useState([]);
  const [roles, setRoles] = useState([]);
  const themeContext = useTheme();
  const [selectedMultipleRoles, setSelectedMultipleRoles] = useState('' || editData?.boards);
  const [editMultipleBoard, setEditMultipleBoard] = useState('' || editData?.boards)
  const [boarddata, setBoardData] = useState('')
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const [inputList, setInputList] = useState([]);
  const [boardIdList, setBoardIdList] = useState([] || editData?.boards);
  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    const resultOptions = [];
    axiosInstance
      .get(
        `${endpoints.masterManagement.board}`
      )
      .then((result) => {
        if (result.status === 200) {
          {
            result.data.result.map((items) => resultOptions.push(items.board_name));
            // setRoles(result.data.result);
            setBoardList(result.data.result);
          }
        } else {
          setAlert('error', result.error_message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });
  }, []);
  let boardids = [];
  // boardids = board?.map((b) => {
  //   return b.id;
  boardids = board?.id

  const handleSubmit = (e) => {
    e.preventDefault()
    if (schoolLogo === null)
      setAlert('warning', 'Provide School Logo')
    else {
      if (!domainFlag) {
        setLoading(true);
        const formData = new FormData()
        formData.append('school_name', schoolName)
        formData.append('school_email', schoolEmail)
        formData.append('school_logo', schoolLogo)
        formData.append('school_contact', contact)
        formData.append('address', address)
        formData.append('city', city)
        formData.append('school_code', schoolCode)
        formData.append('school_sub_domain_name', domainName)
        // formData.append('affiliation_number', affiliation_number);
        // formData.append('board_ids', boardids);
        // formData.append('board_ids',JSON.stringify(inputList))
        formData.append('board_ids', JSON.stringify(boardIdList))
        //   let data = {"school_name": schoolName,
        // "school_email": schoolEmail,
        // "school_logo": base64,
        // "school_contact": contact,
        // "address": address,
        // "city": city,
        // "school_code": schoolCode,
        // "school_sub_domain_name":domainName,
        // "board_ids":inputList
        // }

        axiosInstance.post(endpoints.school.addSchool, formData)
          .then(result => {
            if (result.data.status_code === 200) {
              setSchoolName('')
              setSchoolEmail('')
              setSchoolLogo('')
              setSchoolLogoDisp(null)
              setContact('')
              setCity('')
              setSchoolCode('')
              setAddress('')
              setDomainName('')
              setDomainField('')
              setLoading(false);
              setAffiliation_number('');
              // setBoard_ids('');
              setAlert('success', result.data.message);
              handleGoBack()
            } else {
              setLoading(false);
              setAlert('error', result.data.message);
            }
          }).catch((error) => {
            setLoading(false);
            setAlert('error', error.response.data.description);
          })
      } else
        setAlert('error', "Domain name not verified")
    }
  }

  const handleUpdate = (e) => {
    e.preventDefault()

    setLoading(true);
    const formData = new FormData()

    if ((schoolName !== editData.name && schoolName !== "") ||
      (schoolEmail !== editData.email && schoolEmail !== "") ||
      (contact !== editData.contact && contact !== "") ||
      (address !== editData.address && address !== "") ||
      (city !== editData.city && city !== "") ||
      // (affiliation_number !== editData.affiliation_number && affiliation_number !== "") ||
      (board !== editData?.boards && board !== "") ||
      (schoolCode !== editData.schoolCode && schoolCode !== "") ||
      (!domainFlag && domainName !== editData.domain && domainName !== "") ||
      (schoolLogoDisp !== editData.logo && schoolLogo !== null)) {
      formData.append('id', editData.id)
      if (schoolName !== editData.name && schoolName !== "")
        formData.append('school_name', schoolName)
      if (schoolEmail !== editData.email && schoolEmail !== "")
        formData.append('school_email', schoolEmail)
      if (schoolLogoDisp !== editData.logo && schoolLogo !== null)
        formData.append('school_logo', schoolLogo)
      if (contact !== editData.contact && contact !== "")
        formData.append('school_contact', contact)
      if (address !== editData.address && address !== "")
        formData.append('address', address)
      if (city !== editData.city && city !== "")
        formData.append('city', city)
      if (schoolCode !== editData.schoolCode && schoolCode !== "")
        formData.append('school_code', schoolCode)

      // if (affiliation_number !== editData.affiliation_number && affiliation_number !== "")
      //   formData.append('affiliation_number', affiliation_number)

      if (!domainFlag && domainName !== editData.domain && domainName !== "")
        formData.append('school_sub_domain_name', domainName)
      // formData.append('board_ids', JSON.stringify(selectedMultipleRoles));
      formData.append('board_ids', JSON.stringify(editMultipleBoard));
      axiosInstance.put(endpoints.school.updateSchool, formData)
        .then(result => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setAlert('success', result.data.message);
            handleGoBack()
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
          }
        }).catch((error) => {
          setLoading(false);
          setAlert('error', error.response.data.description);
        })
    } else {
      setAlert('error', "Nothing to Update")
      setLoading(false);
    }
  }

  // console.log(board, 'mtestinf')
  const handleMultipleRoles = (event, value) => {
    // console.log(value,'shivaa')
    if (value) {
      // console.log(board, 'mtesting2')
      // console.log(value,'eyes')
      // const ids = value.map((el) => el);
      // console.log(ids)
      // setBoard(ids);
      setBoard(value)
    }
    else {
      setBoard([]);
    }
  };
  // console.log(bo)
  const onDomainChange = (event) => {

    let val = event.target.value
    setDomainField(val)
    axiosInstance.get(`${endpoints.school.verifyDomain}?school_sub_domain_name=${event.target.value}`)
      .then(result => {
        if (result.data.status_code === 200) {
          if (!(result.data.result.status)) {
            setDomainFlag(result.data.result.status)
            setDomainName(val)
          }
          else
            setDomainFlag(result.data.result.status)
        } else {
          setAlert('error', result.data.message)
        }
      }).catch(error => {
        setAlert('error', error.response.data.description)
      })
  }
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSchoolLogoDisp(URL.createObjectURL(event.target.files[0]));
      setSchoolLogo(event.target.files[0]);
    }
  };

  const handleCancel = (e) => {
    e.preventDefault()
    setAddress(editData.address)
    setCity(editData.city)
    setDomainField(editData.domain)
    setDomainName('')
    setContact(editData.contact)
    setSchoolName(editData.name)
    setSchoolEmail(editData.email)
    setSchoolLogoDisp(editData.logo)
    setAffiliation_number(editData.affiliation_number)
    // setBoard_ids(editData.boards);

  }

  // const handleAddNewSection = () => {
  //   setNoOfSections(noOfSections => noOfSections + 1);
  //   handleAddSection(noOfSections);
  // };

  // const addMoreRole = () => {
  //   const newRole = [...roles];
  //   newRole.push({ organization_id: null, role_id: null });
  //   setRoles(newRole);
  // };

  const handleSchoolCode = (event, value) => {
    if (event.target.value.match(/^[A-Z]*$/)) {
      let val = event.target.value
      if (val.length <= 3) {
        setSchoolCode(event.target.value)
      }
    } else setAlert('warning', 'Invalid Format')
  }

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    console.log({ name, value }, 'checking')
    const list = [...inputList];
    console.log(list, 'checking22')
    list[index][name] = value;
    setInputList(list);
  };

  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    // console.log({ board, affiliation_number }, 'mtesting33==>')
    if (affiliation_number === undefined) {
      setAlert('warning', 'Please Add Affiliation Number')
      return
    }
    // setInputList([...inputList, { firstName: "", lastName: "" }]);
    if (editData) {
      let editData1 = selectedMultipleRoles
      let editData2 = editMultipleBoard

      // editData1.push(
      //   {
      //     'board_id': board?.id,
      //     'affliated_number': affiliation_number
      //   }
      // )
      editData2.push(
        {
          'board_id': board?.id,
          // 'board_name': board?.board_name,
          // 'affiliation_number':board?.affiliation_number
          'board_name': board?.board_name,
          'affliation_number': affiliation_number

        }
      )

    } else {
      let data = inputList
      let data1 = boardIdList
      data.push(
        {
          'board_id': board?.id,
          // 'board': board?.board_name,
          'affliation_number': affiliation_number
        }
      )
      data1.push(
        {
          'board_id': board?.id,
          'board': board?.board_name,
          // 'affiliation_number': affiliation_number
          'affliation_number': affiliation_number
        }
      )
    }

    setBoard([])
    setAffiliation_number('')
  };

  function removeDetail(index) {
    // setInputList((previousData) => {
    //   previousData.splice(index, 1);
    //   return [...previousData];
    // })
    setBoardIdList((previousData) => {
      previousData.splice(index, 1);
      return [...previousData];
    });
  }

  function removeDetailEdit(index) {
    setEditMultipleBoard((previousData) => {
      previousData.splice(index, 1);
      return [...previousData];
    })
  }

  function openEditBord(value) {
    setIsEdit(true);
  }

  function handleEditCancel() {
    setIsEdit(false);
  }
  function handleEditConfirm(value) {
    console.log('value', value)
  }

  return (

    <form autoComplete='off' onSubmit={editData ? handleUpdate : handleSubmit}>
      <div style={{ width: '95%', margin: '20px auto' }}>
        <Grid container spacing={isMobile ? 2 : 5}>
          <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <img
              src={schoolLogoDisp || require('../../assets/images/schoollogo.jpg')}
              alt='Not found'
              className='profile_avator_dash'
            />
          </Grid>
          <Grid item xs={0} sm={8} className={isMobile ? 'hide' : ''} />
          <Grid item xs={12} sm={12} >
            <Button
              variant='contained'
              color='primary'
              className='profile_upload_image_button_dash'
              component='label'
            >
              Add Logo
              <input
                type='file'
                required={editData ? false : null}
                style={{ display: 'none' }}
                id='raised-button-file'
                accept="image/*"
                title='Upload school logo'
                onChange={onImageChange}
              />
            </Button>
          </Grid>
        </Grid>
        <hr style={{ margin: '20px auto' }} />
        <Grid container spacing={isMobile ? 2 : 5} className="marginContainer">
          <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <TextField
              id='schoolname'
              label='School Name'
              variant='outlined'
              size="small"
              style={isMobile ? { marginLeft: '3px', width: '98%' } : { width: '100%' }}
              inputProps={{ maxLength: 100 }}
              value={schoolName}
              name='schoolname'
              title='Orchids International School'
              onChange={e => setSchoolName(e.target.value)}
              required={editData ? false : true}
            />
          </Grid>
          <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <TextField
              id='schoolemail'
              label='Email'
              variant='outlined'
              size="small"
              autoComplete='off'
              style={isMobile ? { marginLeft: '3px', width: '98%' } : { width: '100%' }}
              value={schoolEmail}
              inputProps={{ type: "email", pattern: "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$", maxLength: 50 }}
              title='Ex: john.doe@gmail.com'
              name='schoolemail'
              onChange={e => setSchoolEmail(e.target.value)}
              required={editData ? false : true}
            />
          </Grid>
          <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <TextField
              id='number'
              label='Contact No.'
              variant='outlined'
              style={isMobile ? { marginLeft: '3px', width: '98%' } : { width: '100%' }}
              size="small"
              value={contact}
              inputProps={{ pattern: '^[0-9]{10}$', maxLength: 10 }}
              title="Contact number should contain 10 digits Ex: 985498xxxx"
              name='number'
              onChange={e => setContact(e.target.value)}
              required={editData ? false : true}
            />
          </Grid>
          <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <TextField
              id='address'
              label='Address'
              variant='outlined'
              size="small"
              multiline
              rows={4}
              rowsMax={6}
              style={isMobile ? { marginLeft: '3px', width: '98%' } : { width: '100%' }}
              inputProps={{ maxLength: 150 }}
              title="Address should contain maximum 150 characters"
              value={address}
              name='address'
              onChange={e => setAddress(e.target.value)}
              required={editData ? false : true}
            />
          </Grid>
          <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <TextField
              id='city'
              label='City'
              variant='outlined'
              size="small"
              style={isMobile ? { marginLeft: '3px', width: '98%' } : { width: '100%' }}
              inputProps={{ pattern: '^[a-zA-Z]+', maxLength: 25 }}
              title="City should contain only letters Ex: Bangalore"
              value={city}
              name='city'
              onChange={e => setCity(e.target.value)}
              required={editData ? false : true}
            />
          </Grid>
          <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <TextField
              id='school_code'
              label='School Code'
              variant='outlined'
              size="small"
              style={isMobile ? { marginLeft: '3px', width: '98%' } : { width: '100%' }}
              title="School Code Contain Alpha Numeric"
              value={schoolCode}
              name='school_code'
              onChange={handleSchoolCode}
              // inputProps={{pattern: "[A-Z]" }}
              required={editData ? false : true}
            />
          </Grid>

          <Grid item xs={12} sm={4}
            style={{ marginTop: '20px' }}
            className={isMobile ? '' : 'addEditPadding'}>
            <TextField
              id=''
              label='Domain Name'
              variant='outlined'
              size="small"
              style={isMobile ? { marginLeft: '3px', width: '98%' } : { width: '100%' }}
              value={domainField}
              inputProps={{ pattern: '^[a-zA-Z0-9]+$', maxLength: 120 }}
              title='Ex: orchids'
              name='domainname'
              onChange={onDomainChange}
              required={editData ? false : true}
            />
          </Grid>
          <Grid item xs={12} sm={4}
            // style={{marginTop:'20px',marginLeft:'10px'}} 
            className={isMobile ? '' : 'addEditPadding'}
          >
            <h3 className='domainName'>
              .letseduvate.com
              {(!domainFlag && domainName !== "")
                ? <CheckCircleIcon style={{ color: 'green', marginLeft: '15px' }} />
                : domainName === "" ? null : <CancelIcon style={{ color: 'red', marginLeft: '15px' }} />}
            </h3>
          </Grid>
        </Grid>
        <Grid container spacing={isMobile ? 2 : 5} className="marginContainer">
          {/* <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <TextField
              id='address'
              label='Address'
              variant='outlined'
              size="small"
              multiline
              rows={4}
              rowsMax={6}
              style={isMobile ? { marginLeft: '3px', width: '98%' } : { width: '100%' }}
              inputProps={{ maxLength: 150 }}
              title="Address should contain maximum 150 characters"
              value={address}
              name='address'
              onChange={e => setAddress(e.target.value)}
              required={editData ? false : true}
            />
          </Grid> */}
          {/* <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <TextField
              id='city'
              label='City'
              variant='outlined'
              size="small"
              style={isMobile ? { marginLeft: '3px', width: '98%' } : { width: '100%' }}
              inputProps={{ pattern: '^[a-zA-Z]+', maxLength: 25 }}
              title="City should contain only letters Ex: Bangalore"
              value={city}
              name='city'
              onChange={e => setCity(e.target.value)}
              required={editData ? false : true}
            />
          </Grid> */}
          {/* <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <TextField
              id='school_code'
              label='School Code'
              variant='outlined'
              size="small"
              style={isMobile ? { marginLeft: '3px', width: '98%' } : { width: '100%' }}
              title="School Code Contain Alpha Numeric"
              value={schoolCode}
              name='school_code'
              onChange={handleSchoolCode}
              // inputProps={{pattern: "[A-Z]" }}
              required={editData ? false : true}
            />
          </Grid> */}
{/* 
          <Grid container spacing={isMobile ? 2 : 5}>
            <Grid item xs={12} sm={4}
              style={{ marginTop: '20px', marginLeft: '10px' }}
              className={isMobile ? '' : 'addEditPadding'}>
              <TextField
                id=''
                label='Domain Name'
                variant='outlined'
                size="small"
                style={isMobile ? { marginLeft: '3px', width: '98%' } : { width: '100%' }}
                value={domainField}
                inputProps={{ pattern: '^[a-zA-Z0-9]+$', maxLength: 20 }}
                title='Ex: orchids'
                name='domainname'
                onChange={onDomainChange}
                required={editData ? false : true}
              />
            </Grid>
            <Grid item xs={12} sm={4}
              // style={{marginTop:'20px',marginLeft:'10px'}} 
              className={isMobile ? '' : 'addEditPadding'}
            >
              <h3 className='domainName'>
                .letseduvate.com
                {(!domainFlag && domainName !== "")
                  ? <CheckCircleIcon style={{ color: 'green', marginLeft: '15px' }} />
                  : domainName === "" ? null : <CancelIcon style={{ color: 'red', marginLeft: '15px' }} />}
              </h3>
            </Grid>
          </Grid> */}

          {/* <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <TextField
              id='affiliation_number'
              label='affiliation_number'
              variant='outlined'
              size="small"
              style={isMobile ? { marginLeft: '3px', width: '98%' } : { width: '100%' }}
              value={affiliation_number}
              inputProps={{ pattern: '^[a-zA-Z0-9]+$', maxLength: 20 }}
              title='Ex: orchids'
              name='affiliation_number'
              onChange={e => setAffiliation_number(e.target.value)}
              required={editData ? false : true}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Autocomplete
              multiple
              size='small'
              onChange={handleMultipleRoles}
              value={board}
              id='message_log-smsType'
              options={boardList}
              getOptionLabel={(option) => option?.board_name}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  className='message_log-textfield'
                  {...params}
                  variant='outlined'
                  label='Board'
                  placeholder='Board'
                />
              )}
            />
          </Grid> */}
          {/* 
            <div style={{ margin: "1%" }}>
                <Button
                  size="small"
                  // startIcon={<AddIcon />}
                  onClick={() => addMoreRole()}
                >
                  Add
                </Button>
              </div> */}
          <Grid md={12} xs={3}
           style={{ marginTop: '10px', marginLeft: '10px' }}
           >
            <div style={{ margin: '1%' }}>
              {/* {inputList.map((x, i) => { */}
              {/* return ( */}
              {/* <div> */}
                {/* <input
                    name="firstName"
                    placeholder="Enter First Name"
                    value={x.firstName}
                    onChange={e => handleInputChange(e, i)}
                  /> */}

                <Grid container spacing={isMobile ? 2 : 5} className="marginContainer">
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      // multiple
                      size='small'
                      onChange={handleMultipleRoles}
                      value={board}
                      id='board'
                      options={boardList}
                      getOptionLabel={(option) => option?.board_name}
                      filterSelectedOptions
                      required={editData ? false : true}
                      renderInput={(params) => (
                        <TextField
                          // className='message_log-textfield'
                          {...params}
                          variant='outlined'
                          label='Board'
                          placeholder='Board'
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}
                  // className={isMobile ? '' : 'addEditPadding'}
                  >
                    <TextField
                      id='affiliation_number'
                      label='affiliation_number'
                      variant='outlined'
                      size="small"
                      // style={isMobile ? { marginLeft: '3px', width: '98%' } : { width: '100%' }}
                      value={affiliation_number}
                      inputProps={{ pattern: '^[a-zA-Z0-9]+$', maxLength: 20 }}
                      title='Ex: orchids'
                      name='affiliation_number'
                      onChange={e => setAffiliation_number(e.target.value)}
                    // required={editData ? false : true}
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={3}> */}
                  <Button
                    variant='contained'
                    color='primary'
                    className='add_customAdd'
                    component='label'
                    size='small'
                    style={{ color: 'white' }}
                    startIcon={<AddIcon style={{ fontSize: '30px' }} />}
                    disabled={board.length !== 0 ? false : true}
                    onClick={handleAddClick}>
                    {/* <AddIcon style={{color:'blue'}}/>  */}
                    Add
                  </Button>
                  {/* </Grid> */}

                </Grid>

                {/* <input
                      className="ml10"
                      name="lastName"
                      placeholder="Enter Last Name"
                      value={x.lastName}
                      onChange={e => handleInputChange(e, i)}
                    /> */}
                <div >
                  {/* {inputList.length !== 1 &&

                    <Button
                      variant='contained'
                      color='primary'
                      className='add_custom'
                      component='label'
                      size='small'
                      style={{ marginTop: '10px' }}
                    // className="mr10"
                    // onClick={() => handleRemoveClick(i)}
                    >
                      Remove

                    </Button>} */}
                  {/* {inputList.length - 1 === i && */}
                  {/* <Button
                    variant='contained'
                    color='primary'
                    className='add_custom'
                    component='label'
                    size='small'
                    disabled={board.length !== 0 ? false : true}
                    onClick={handleAddClick}>
                    Add
                  </Button> */}
                  {/* } */}
                </div>
              {/* </div> */}
              {/* ); */}
              {/* })} */}
            </div>

          </Grid>
          <Grid md={12} xs={12}>
            {/* <div style={{border:'1px solid blue'}}>
            </div> */}

            {boardIdList && boardIdList?.length !== 0 || editMultipleBoard && editMultipleBoard?.length !== 0 ? (
              <>
                <Grid md={6} xs={6}>
                  <div className='tableDesign' 
                  // style={{ border: '1px solid #ef676a', borderRadius: '10px', padding: '10px', marginLeft: '22px' }}
                  >
                    <Grid container spacing={2}>
                    </Grid>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            Board
                          </TableCell>
                          <TableCell align="right">
                            Affiliation Number
                          </TableCell>
                          <TableCell align="right">
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {/* <TableRow> */}
                      {/* <TableCell> Board</TableCell>
                        <TableCell> Affiliation Number</TableCell> */}
                      <TableBody>
                        {editMultipleBoard ? (
                          <>

                            {editMultipleBoard.map((e, index) =>
                              <TableRow key={index}>
                                <TableCell >
                                  {e.board_name}
                                </TableCell>
                                <TableCell align="right">
                                  {e.affliation_number}
                                  {/* {e.affiliation_number} */}
                                </TableCell>
                                <TableCell align="right">
                                  <IconButton
                                    // variant="contained" 
                                    // color="primary"
                                    title='Delete'
                                    onClick={() => removeDetailEdit(index)}>
                                    <DeleteOutlinedIcon style={{ color: '#fe6b6b' }} />

                                  </IconButton>
                                  {/* <IconButton
                                // onClick={(e) => handleEditSubject(topic)}
                                onClick={() => { openEditBord(index) }}
                                title='Edit Key Concept'
                              >
                                <EditOutlinedIcon style={{ color: '#fe6b6b' }} />
                              </IconButton> */}
                                </TableCell>
                              </TableRow>
                            )}
                          </>

                        ) :
                          <>
                            {/* {console.log(boardList, 'boardList====>')} */}
                            {boardIdList.map((e, index) =>
                              <TableRow key={index}>
                                <TableCell >
                                  {e.board}
                                </TableCell>
                                <TableCell >
                                  {e.affliation_number}
                                </TableCell>
                                <TableCell >
                                  <IconButton
                                    // variant="contained" 
                                    // color="primary"
                                    title='Delete'
                                    onClick={() => removeDetail(index)}>
                                    <DeleteOutlinedIcon style={{ color: '#fe6b6b' }} />

                                  </IconButton>
                                  {/* <IconButton
                            // onClick={(e) => handleEditSubject(topic)}
                            onClick={() => { openEditBord(index) }}
                            title='Edit Key Concept'
                          >
                            <EditOutlinedIcon style={{ color: '#fe6b6b' }} />
                          </IconButton> */}
                                </TableCell>
                              </TableRow>
                            )}
                          </>

                        }

                      </TableBody>
                      {/* </TableRow> */}
                    </Table>
                  </div>

                </Grid>

              </>

            )

              : ''}

          </Grid>


          <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>

            {/* <Autocomplete
                multiple
                size='small'
                onChange={handleMultipleRoles}
                value={board_ids}
                id='message_log-smsType'
                options={boardList}
                getOptionLabel={(option) => option?.board_name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    className='message_log-textfield'
                    {...params}
                    variant='outlined'
                    label='Role'
                    placeholder='Role'
                  />
                )}
              /> */}
            {/* <TextField
              id='board_ids'
              label='board_ids Name'
              variant='outlined'
              size="small"
              style={isMobile ? { marginLeft: '3px', width: '98%' } : { width: '100%' }}
              value={board_ids}
              // inputProps={{ pattern: '^[a-zA-Z0-9]+$', maxLength: 20 }}
              title='Ex: orchids'
              name='board_ids'
              onChange={e => setBoard_ids(e.target.value)}
              required={editData ? false : true}
            /> */}

          </Grid>
        </Grid>
        {/* <Grid container spacing={isMobile ? 2 : 5}>
          <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <TextField
              id=''
              label='Domain Name'
              variant='outlined'
              size="small"
              style={isMobile ? { marginLeft: '3px', width: '98%' } : { width: '100%' }}
              value={domainField}
              inputProps={{ pattern: '^[a-zA-Z0-9]+$', maxLength: 20 }}
              title='Ex: orchids'
              name='domainname'
              onChange={onDomainChange}
              required={editData ? false : true}
            />
          </Grid>


          <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <h3 className='domainName'>
              .letseduvate.com
              {(!domainFlag && domainName !== "")
                ? <CheckCircleIcon style={{ color: 'green', marginLeft: '15px' }} />
                : domainName === "" ? null : <CancelIcon style={{ color: 'red', marginLeft: '15px' }} />}
            </h3>
          </Grid>
        </Grid> */}
      </div>
      <Dialog open={isEdit} onClose={handleEditCancel}>
        <DialogTitle id='draggable-dialog-title'>Edit Affiliation Number </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Are you sure you want to remove this section ?
          </DialogContentText> */}
          <Grid container spacing={2}>
            {/* <Grid item xs={12} sm={12} className={isMobile ? '' : 'addEditPadding'}>
              <TextField
                id='period_name'
                label='Period Name'
                fullwidth
                variant='outlined'
                size='small'
                placeholder='Enter Period name'
                value={periodName}
                name='period_name'
                onChange={(event, value) => { handleChange(value) }}
                required
              />
            </Grid> */}
            {/* <Grid item xs={12} md={8}>
              <Autocomplete
                // multiple
                size='small'
                onChange={handleMultipleRoles}
                value={board}
                id='board'
                options={boardList}
                getOptionLabel={(option) => option?.board_name}
                filterSelectedOptions
                required={editData ? false : true}
                renderInput={(params) => (
                  <TextField
                    // className='message_log-textfield'
                    {...params}
                    variant='outlined'
                    label='Board'
                    placeholder='Board'
                  />
                )}
              />
            </Grid> */}


            <Grid item xs={12} sm={12}
            // className={isMobile ? '' : 'addEditPadding'}
            >
              <TextField
                id='affiliation_number'
                label='affiliation_number'
                variant='outlined'
                size="small"
                // style={isMobile ? { marginLeft: '3px', width: '98%' } : { width: '100%' }}
                value={affiliation_number}
                inputProps={{ pattern: '^[a-zA-Z0-9]+$', maxLength: 20 }}
                title='Ex: orchids'
                name='affiliation_number'
                onChange={e => setAffiliation_number(e.target.value)}
                required={editData ? false : true}
              />
            </Grid>


          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditCancel} className='labelColor cancelButton'>
            Cancel
          </Button>
          <Button
            color='primary'
            variant='contained'
            style={{ color: 'white' }}
            onClick={handleEditConfirm}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Grid container spacing={isMobile ? 1 : 5} style={{ width: '95%', margin: '10px' }} >

        <Grid item xs={6} sm={2} className={isMobile ? '' : 'addEditButtonsPadding'}>
          <Button variant='contained' type="button" className="labelColor cancelButton custom_button_master" onClick={handleGoBack} size='medium'>
            BACK
          </Button>
        </Grid>

        {editData ?
          <Grid item xs={6} sm={2} className={isMobile ? '' : 'addEditButtonsPadding'}>
            <Button variant='contained' type="button" className="labelColor cancelButton custom_button_master" onClick={handleCancel} size='medium'>
              CANCEL
            </Button>
          </Grid>
          : null}

        <Grid item xs={editData ? 12 : 6} sm={2} className={isMobile ? '' : 'addEditButtonsPadding'}>
          <Button variant='contained' type="submit" className="custom_button_master" color="primary" style={{ color: 'white' }} size='medium'>
            {editData ? 'UPDATE' : 'SUBMIT'}
          </Button>
        </Grid>

      </Grid>

    </form>
  );
};

export default CreateEditSchool
