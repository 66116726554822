import React , { useContext, useState } from 'react';
import { Grid, TextField, Button, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import endpoints from '../../config/endpoints';
import axiosInstance from '../../config/axios';
import { AlertNotificationContext } from '../../context-api/alert-context/alert-state';

const EditAssignLicense = ({editData,handleGoBack,setLoading}) => {

  const {name,domain,number}=editData
  const { setAlert } = useContext(AlertNotificationContext);
  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const [licenseCount,setLicenseCount]=useState(number||0)
  
  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    let request={}
    request["school_sub_domain_name"]=domain
    request["school_name"]=name
    request["no_of_licence"]=Number(licenseCount)

    if(licenseCount!==number && licenseCount!==0)
    {
      axiosInstance.post(endpoints.zoom.editAssignLicense,request)
      .then(result=>{
      if (result.status === 200) {
        {
          handleGoBack()
          setLoading(false)
          setAlert('success', result.data.message);
        }
      } else {
        setLoading(false);
        setAlert('error', result.data.description);
      }
    })
      .catch((error)=>{
        setLoading(false);
        setAlert('error', error.response.data.description);
      })
    }
    else
    {
      setLoading(false);
      setAlert('error','No Fields to Update')
    }
    };


  return (
    <form autoComplete='off' onSubmit={handleSubmit}>
        <div style={{ width: '95%', margin: '20px auto' }}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4} className={isMobile?'':'addEditPadding'}>
            <TextField
              id='schoolname'
              label='School Name'
              variant='outlined'
              style={{ width: '100%' }}
              size='small'
              value={name}
              name='schoolname'
              disabled
            />
          </Grid>
          </Grid>
          <Grid container spacing={5}>
          <Grid item xs={12} sm={4} className={isMobile?'':'addEditPadding'}>
            <TextField
              id='domainname'
              label='Domain Name'
              variant='outlined'
              style={{ width: '100%' }}
              size='small'
              value={domain}
              name='domainname'
              disabled
            />
          </Grid>
          </Grid>

          <Grid container spacing={5}>
          <Grid item xs={12} sm={4} className={isMobile?'':'addEditPadding'}>
            <TextField
              id='licensecount'
              label='License Count'
              variant='outlined'
              type='number'
              size='small'
              style={{ width: '100%' }}
              InputProps={{ inputProps: { min: 0}}}
              inputProps={{maxLength:3}}
              value={licenseCount}
              name='licensecount'
              onChange={e=>setLicenseCount(e.target.value)}
            />
          </Grid>
        </Grid>
        </div>
        <Grid container spacing={isMobile?1:5} style={{ width: '95%', margin: '10px'}} >
          <Grid item xs={6} sm={2} className={isMobile?'':'addEditButtonsPadding'}>
            <Button variant='contained' className="custom_button_master labelColor" size='medium' onClick={handleGoBack}>
              Back
            </Button>
          </Grid>
          <Grid item xs={6} sm={2} className={isMobile?'':'addEditButtonsPadding'}>
            <Button variant='contained' style={{color:'white'}} color ="primary" className="custom_button_master" size='medium' type='submit'>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
  );
};

export default EditAssignLicense;
