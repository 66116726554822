import React, { useState, useRef, useEffect } from 'react';
import { difficultyLevels, bloomLevels } from '../../../generalQuestionConstants';
import { Form, Select, message, Input, Button, Spin } from 'antd';
import axiosInstance from '../../../config/axios';
import endpoints from '../../../config/endpoints';
import { InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import QuestionTypeFinder from '../CreateQuestion/QuestionTypeFinder';
import QuestionTypehandler from '../QuestionTypesHandler/QuestionTypehandler';
import _ from 'lodash';
import ComprehensionNew from '../QuestionTypesHandler/ComprehensionNew';
import TypeRestricter from '../TypeRestrictChecker';
const { Option } = Select;

const EditQuestionCard = ({
  questionTypeList,
  chapterId,
  keyConceptId,
  getQuestionList,
  isSubQuestion,
  subQuestionIndex,
  handleCloseViewQuestion,
  key,
  handleCloseEnableMode,
  question,
}) => {
  const questionFormRef = useRef();
  const [updateLoading, setUpdateLoading] = useState(false);
  const [subQuestionTypeList, setSubQuestionTypeList] = useState([]);
  const [questionTypeId, setQuestionTypeId] = useState(
    isSubQuestion
      ? question?.sub_questions[subQuestionIndex].question_type?.type?.id
      : question?.question_type?.type?.id
  );
  const [subQuestionType, setSubQuestionType] = useState(
    isSubQuestion
      ? {
          children: question?.sub_questions?.[subQuestionIndex]?.question_type?.type_name,
          value: question?.sub_questions?.[subQuestionIndex]?.question_type?.id,
        }
      : {
          children: question?.question_type?.type_name,
          value: question?.question_type?.id,
        }
  );
  const QuestionTypeChecker = isSubQuestion
    ? typeof question?.sub_questions?.[subQuestionIndex]?.question_type == 'object'
      ? question?.sub_questions?.[subQuestionIndex]?.question_type?.type_name
      : question?.sub_questions?.[subQuestionIndex]?.question_type
    : typeof question?.question_type == 'object'
    ? question?.question_type?.type_name
    : question?.question_type;
  const [marks, setMarks] = useState(
    isSubQuestion
      ? question?.sub_questions[subQuestionIndex]?.question_mark
      : question?.question_mark
  );
  const [bloomLevel, setBloomLevel] = useState();
  const [difficultyLevel, setDifficultyLevel] = useState();
  const [currentQuestion, setCurrentQuestion] = useState({
    ...question,
  });

  const [deletedSubQuestions, setDeletedSubQuestions] = useState([]);
  const handleDeleteSubQuestion = (deleteIndex) => {
    let list = currentQuestion?.sub_questions?.filter(
      (el, index) => index !== deleteIndex
    );
    setCurrentQuestion({ ...currentQuestion, sub_questions: list });
    if (currentQuestion?.sub_questions[deleteIndex]?.id) {
      setDeletedSubQuestions([
        ...deletedSubQuestions,
        currentQuestion?.sub_questions[deleteIndex]?.id,
      ]);
    }
  };
  const handleAddSubQuestion = () => {
    let list = currentQuestion?.sub_questions;
    list.push({
      is_tagged: true,
      chapter: currentQuestion?.chapter,
      topic: currentQuestion?.topic,
      question_status: currentQuestion?.question_status,
      question_type: '',
      question_mark: '',
      question_answer: [],
    });
    setCurrentQuestion({ ...currentQuestion, sub_questions: list });
  };

  const handleQuestionChanges = (updatedValue) => {
    if (isSubQuestion) {
      let subQuestions = currentQuestion.sub_questions;
      subQuestions[subQuestionIndex]['question_answer'] = [updatedValue];
      subQuestions[subQuestionIndex]['question_mark'] = marks;
      setCurrentQuestion({ ...currentQuestion, sub_questions: subQuestions });
    } else {
      let updatedQuestion = _.cloneDeep(currentQuestion);
      if (updatedQuestion.question_answer) {
        updatedQuestion['question_answer'] = [updatedValue];
      }
      setCurrentQuestion({ ...updatedQuestion, question_mark: marks });
    }
  };

  const handleUpdateQuestion = () => {
    if (!currentQuestion?.question_categories) {
      message.error('Please select Bloom Level');
      return;
    }
    if (!currentQuestion?.question_level) {
      message.error('Please select Difficulty Level');
      return;
    }
    if (!currentQuestion?.question_type?.type?.type_name) {
      message.error('Please select Question Type');
      return;
    }
    if (!currentQuestion?.question_type?.type_name) {
      message.error('Please select Sub-Question Type');
      return;
    }
    if (!currentQuestion?.question_mark) {
      message.error('Please enter Marks');
      return;
    }
    if (currentQuestion?.question_mark <= 0) {
      message.error('Marks must be greater than 0');
      return;
    }
    if (currentQuestion.question_answer[0].question == '') {
      message.error('Question cannot be empty');
      return;
    }
    if (
      currentQuestion.question_answer[0].answer == '' ||
      currentQuestion.question_answer[0].answer == []
    ) {
      message.error('Answer cannot be empty');
      return;
    }

    if (currentQuestion?.sub_questions?.length > 0) {
      const isBloomLevelNull = currentQuestion?.sub_questions.filter((el) => {
        return el.question_categories == '';
      });
      const isDifficultyLevelNull = currentQuestion?.sub_questions.filter((el) => {
        return el.question_level == '';
      });
      const isMarksNull = currentQuestion?.sub_questions.filter((el) => {
        return el.question_mark == '';
      });
      const isSubQuestionsEmpty = currentQuestion?.sub_questions.filter((el) => {
        return el?.question_answer[0]?.question == '';
      });
      const isSubAnswersEmpty = currentQuestion?.sub_questions.filter((el) => {
        return (
          el?.question_answer[0]?.answer == '' || el?.question_answer[0]?.answer == []
        );
      });
      if (isBloomLevelNull.length > 0) {
        message.error('Please select bloom level for all sub questions');
        return;
      }
      if (isDifficultyLevelNull.length > 0) {
        message.error('Please select difficulty level for all sub questions');
        return;
      }
      if (isMarksNull.length > 0) {
        message.error('Please enter marks for all sub questions');
        return;
      }
      if (isSubQuestionsEmpty.length > 0) {
        message.error('Sub questions cannot be empty');
        return;
      }
      if (isSubAnswersEmpty.length > 0) {
        message.error('Answers of sub-questions cannot be empty');
        return;
      }
      let subQuestionMarks = currentQuestion?.sub_questions.reduce(
        (acc, el) => acc + Number(el?.question_mark),
        0
      );
      if (Number(subQuestionMarks) !== Number(currentQuestion.question_mark)) {
        message.error(
          'Sum of marks of sub questions must be equal to the marks of main question'
        );
        return;
      }
    }
    let updatedQuestion = {};
    setUpdateLoading(true);
    if (currentQuestion?.question_type?.type_name == 'Comprehension') {
      let subQuestions = currentQuestion?.sub_questions.map((item) => ({
        ...item,
        question_type: item?.question_type?.id,
        chapter: currentQuestion?.chapter?.id,
        topic: currentQuestion?.topic?.id,
      }));
      updatedQuestion = {
        sub_questions: subQuestions,
        question_answer: currentQuestion?.question_answer,
        question_level: currentQuestion?.question_level,
        question_categories: currentQuestion?.question_categories,
        question_type: currentQuestion?.question_type?.id,
        question_mark: currentQuestion?.question_mark,
        chapter: currentQuestion?.chapter?.id,
        topic: currentQuestion?.topic?.id,
        question_status: currentQuestion?.question_status,
        id: currentQuestion?.id,
        delete_questions: [...new Set(deletedSubQuestions)],
      };
    } else {
      updatedQuestion = {
        id: currentQuestion?.id,
        question_answer: currentQuestion?.question_answer,
        question_status: currentQuestion?.question_status,
        question_level: currentQuestion?.question_level,
        question_categories: currentQuestion?.question_categories,
        question_type: currentQuestion?.question_type?.id,
        question_mark: currentQuestion?.question_mark,
      };
    }
    axiosInstance
      .put(`v2/assessment/${currentQuestion?.id}/v3/questions/`, updatedQuestion)
      .then((response) => {
        if (response?.data?.status_code === 200) {
          message.success(response?.data?.message);
          handleCloseViewQuestion();
          getQuestionList();
        } else {
          message.error(response?.data?.message);
        }
      })
      .catch((error) => {
        message.error(error?.message);
      })
      .finally(() => {
        setUpdateLoading(false);
      });
  };
  const handleQuestiontypeChange = (e) => {
    questionFormRef.current.setFieldsValue({
      sub_question_type: null,
    });
    setSubQuestionTypeList([]);
    setSubQuestionType();
    if (e) {
      setQuestionTypeId(e);
      fetchSubQuestionTypesList({
        question_type: e,
      });
    } else {
      setQuestionTypeId();
    }
  };
  const handleSubQuestionTypeChange = (each) => {
    setSubQuestionType();
    if (each) {
      let currentQuestionType = QuestionTypeFinder(each?.children);
      if (currentQuestionType == 'Fill in the Blanks') {
        if (isSubQuestion) {
          let list = currentQuestion?.sub_questions;
          list[subQuestionIndex]['question_type'] = subQuestionTypeList.filter(
            (el) => el?.id == each?.value
          )[0];
          // list[subQuestionIndex]['question_answer'] = [
          //   {
          //     question: '',
          //     answer: [],
          //     options: [{ option1: { optionValue: '', images: [] } }],
          //   },
          // ];
          setCurrentQuestion({
            ...currentQuestion,
            sub_questions: list,
          });
        } else {
          setCurrentQuestion({
            ...currentQuestion,
            // question_status: 1,
            question_type: subQuestionTypeList.filter((el) => el?.id == each?.value)[0],
            sub_questions: [],
            // question_answer: [
            //   {
            //     question: '',
            //     answer: [],
            //     options: [{ option1: { optionValue: '', images: [] } }],
            //   },
            // ],
          });
        }
      } else if (currentQuestionType == 'True or False') {
        if (isSubQuestion) {
          let list = currentQuestion?.sub_questions;
          list[subQuestionIndex]['question_type'] = subQuestionTypeList.filter(
            (el) => el?.id == each?.value
          )[0];
          // list[subQuestionIndex]['question_answer'] = [
          //   {
          //     question: '',
          //     answer: [],
          //     options: [
          //       { option1: { isChecked: false, optionValue: true, title: 'True' } },
          //       { option2: { isChecked: false, optionValue: false, title: 'False' } },
          //     ],
          //   },
          // ];

          setCurrentQuestion({
            ...currentQuestion,
            sub_questions: list,
          });
        } else {
          setCurrentQuestion({
            ...currentQuestion,
            // question_status: 1,
            question_type: subQuestionTypeList.filter((el) => el?.id == each?.value)[0],
            sub_questions: [],
            // question_answer: [
            //   {
            //     question: '',
            //     answer: [],
            //     options: [
            //       { option1: { isChecked: false, optionValue: true, title: 'True' } },
            //       { option2: { isChecked: false, optionValue: false, title: 'False' } },
            //     ],
            //   },
            // ],
          });
        }
      } else if (
        currentQuestionType == 'MCQSingle' ||
        currentQuestionType == 'MCQMultiple'
      ) {
        if (isSubQuestion) {
          let list = currentQuestion?.sub_questions;
          list[subQuestionIndex]['question_type'] = subQuestionTypeList.filter(
            (el) => el?.id == each?.value
          )[0];
          // list[subQuestionIndex]['question_answer'] = [
          //   {
          //     question: '',
          //     answer: [],
          //     options: [
          //       { option1: { isChecked: false, optionValue: '', images: [] } },
          //       { option2: { isChecked: false, optionValue: '', images: [] } },
          //       { option3: { isChecked: false, optionValue: '', images: [] } },
          //     ],
          //   },
          // ];

          setCurrentQuestion({
            ...currentQuestion,
            sub_questions: list,
          });
        } else {
          setCurrentQuestion({
            ...currentQuestion,
            // question_status: 1,
            question_type: subQuestionTypeList.filter((el) => el?.id == each?.value)[0],
            sub_questions: [],
            // question_answer: [
            //   {
            //     question: '',
            //     answer: [],
            //     options: [
            //       { option1: { isChecked: false, optionValue: '', images: [] } },
            //       { option2: { isChecked: false, optionValue: '', images: [] } },
            //       { option3: { isChecked: false, optionValue: '', images: [] } },
            //     ],
            //   },
            // ],
          });
        }
      } else if (currentQuestionType == 'Descriptive') {
        if (isSubQuestion) {
          let list = currentQuestion?.sub_questions;
          list[subQuestionIndex]['question_type'] = subQuestionTypeList.filter(
            (el) => el?.id == each?.value
          )[0];
          // list[subQuestionIndex]['question_answer'] = [
          //   {
          //     question: '',
          //     answer: '',
          //   },
          // ];

          setCurrentQuestion({
            ...currentQuestion,
            sub_questions: list,
          });
        } else {
          setCurrentQuestion({
            ...currentQuestion,
            // question_status: 1,
            question_type: subQuestionTypeList.filter((el) => el?.id == each?.value)[0],
            sub_questions: [],
            // question_answer: [
            //   {
            //     question: '',
            //     answer: '',
            //   },
            // ],
          });
        }
      } else if (currentQuestionType == 'Comprehension') {
        setCurrentQuestion({
          ...currentQuestion,
          // question_categories: bloomLevel,
          // question_level: difficultyLevel,
          // question_status: 1,
          // question_type: subQuestionTypeList.filter((el) => el?.id == each?.value)[0],
          // question_answer: [
          //   {
          //     question: '',
          //   },
          // ],
          // sub_questions: [
          //   {
          //     is_tagged: true,
          //     chapter: chapterId,
          //     topic: keyConceptId,
          //     question_status: 1,
          //     question_type: '',
          //     question_answer: [],
          //   },
          // ],
        });
      }
      setSubQuestionType(each);
    } else {
      setSubQuestionType();
      setCurrentQuestion({
        ...currentQuestion,
        question_answer: [],
        sub_questions: [],
      });
    }
  };
  const fetchSubQuestionTypesList = (params = {}) => {
    axiosInstance
      .get(`v2/assessment/v3/questiontype/`, {
        params: { ...params },
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          if (isSubQuestion) {
            let checker =
              typeof question?.sub_questions?.[subQuestionIndex]?.question_type ==
              'object'
                ? question?.sub_questions?.[subQuestionIndex]?.question_type?.type_name
                : question?.sub_questions?.[subQuestionIndex]?.question_type;
            if (checker) {
              let allowedList = result?.data?.result?.filter(
                (el) => QuestionTypeFinder(el?.type_name) == QuestionTypeFinder(checker)
              );
              setSubQuestionTypeList(allowedList);
            } else {
              setSubQuestionTypeList(
                result?.data?.result.filter((item) => item?.type_name !== 'Comprehension')
              );
            }
          } else {
            let checker =
              typeof question?.question_type == 'object'
                ? question.question_type?.type_name
                : question?.question_type;
            if (checker) {
              let allowedList = result?.data?.result?.filter(
                (el) => QuestionTypeFinder(el?.type_name) == QuestionTypeFinder(checker)
              );
              setSubQuestionTypeList(allowedList);
            } else {
              setSubQuestionTypeList(result?.data?.result);
            }
          }
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  useEffect(() => {
    if (isSubQuestion) {
      questionFormRef.current.setFieldsValue({
        blooms_level: bloomLevels.filter(
          (el) => el?.id == question?.sub_questions[subQuestionIndex]?.question_categories
        )[0]?.level,
        difficulty_level: difficultyLevels.filter(
          (el) => el?.id == question?.sub_questions[subQuestionIndex]?.question_level
        )[0]?.level,
        question_type: question?.sub_questions[subQuestionIndex]?.question_categories,
        question_type:
          question?.sub_questions[subQuestionIndex]?.question_type?.type?.type_name,
        sub_question_type:
          question?.sub_questions[subQuestionIndex]?.question_type?.type_name,
        marks: question?.sub_questions[subQuestionIndex]?.question_mark,
      });

      // fetchSubQuestionTypesList({
      //   question_type: question?.sub_questions[subQuestionIndex]?.question_type?.type?.id,
      // });
    } else {
      questionFormRef.current.setFieldsValue({
        blooms_level: bloomLevels.filter(
          (el) => el?.id == question?.question_categories
        )[0]?.level,
        difficulty_level: difficultyLevels.filter(
          (el) => el?.id == question?.question_level
        )[0]?.level,
        question_type: question?.question_categories,
        question_type: question?.question_type?.type?.type_name,
        sub_question_type: question?.question_type?.type_name,
        marks: question?.question_mark,
      });
      fetchSubQuestionTypesList({
        question_type: question?.question_type?.type?.id,
      });
    }
  }, [question]);

  // Select Options
  const bloomLevelOptions = bloomLevels?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.level}
      </Option>
    );
  });
  const difficultyLevelOptions = difficultyLevels?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.level}
      </Option>
    );
  });
  const questionTypeOptions = TypeRestricter(QuestionTypeChecker)?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.type_name}
      </Option>
    );
  });
  const subQuestionTypeOptions = subQuestionTypeList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.type_name}
      </Option>
    );
  });
  console.log('editquestionCard2', currentQuestion);
  return (
    <div className='th-bg-grey th-br-4' id={key} style={{ border: '1px solid #d9d9d9' }}>
      <Spin tip='Creating question...' size='large' spinning={updateLoading}>
        <Form ref={questionFormRef} layout='vertical'>
          <div className='row align-items-center'>
            <div className='col-sm-3 col-6'>
              <Form.Item name='blooms_level' label='Blooms Level'>
                <Select
                  placeholder='Blooms Level'
                  optionFilterProp='children'
                  className=''
                  listHeight={180}
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownMatchSelectWidth={false}
                  filterOption={(input, options) => {
                    return (
                      options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  onChange={(e) => {
                    if (e) {
                      setBloomLevel(e);
                      if (isSubQuestion) {
                        let subQuestions = currentQuestion.sub_questions;
                        subQuestions[subQuestionIndex]['question_categories'] = e;
                        setCurrentQuestion({
                          ...currentQuestion,
                          sub_questions: subQuestions,
                        });
                      } else {
                        let updatedQuestion = _.cloneDeep(currentQuestion);
                        updatedQuestion['question_categories'] = e;
                        setCurrentQuestion(updatedQuestion);
                      }
                    } else {
                      setBloomLevel();
                    }
                  }}
                >
                  {bloomLevelOptions}
                </Select>
              </Form.Item>
            </div>
            <div className='col-sm-2 col-6'>
              <Form.Item name='difficulty_level' label='Difficulty Level'>
                <Select
                  placeholder='Difficulty Level'
                  optionFilterProp='children'
                  className=''
                  allowClear
                  listHeight={180}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownMatchSelectWidth={false}
                  filterOption={(input, options) => {
                    return (
                      options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  onChange={(e) => {
                    if (e) {
                      setDifficultyLevel(e);
                      if (isSubQuestion) {
                        let subQuestions = currentQuestion.sub_questions;
                        subQuestions[subQuestionIndex]['question_level'] = e;
                        setCurrentQuestion({
                          ...currentQuestion,
                          sub_questions: subQuestions,
                        });
                      } else {
                        let updatedQuestion = _.cloneDeep(currentQuestion);
                        updatedQuestion['question_level'] = e;
                        setCurrentQuestion(updatedQuestion);
                      }
                    } else {
                      setDifficultyLevel();
                    }
                  }}
                >
                  {difficultyLevelOptions}
                </Select>
              </Form.Item>
            </div>
            <div className='col-sm-2 col-6'>
              <Form.Item name='question_type' label='Question Type'>
                <Select
                  placeholder='Question Type'
                  optionFilterProp='children'
                  className=''
                  listHeight={180}
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentNode}
                  onChange={(e) => {
                    handleQuestiontypeChange(e);
                  }}
                  filterOption={(input, options) => {
                    return (
                      options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {questionTypeOptions}
                </Select>
              </Form.Item>
            </div>
            <div className='col-sm-3 col-6 pl-0'>
              <Form.Item name='sub_question_type' label='Sub Question Type'>
                <Select
                  placeholder='Sub Question*'
                  showSearch
                  optionFilterProp='children'
                  className=''
                  getPopupContainer={(trigger) => trigger.parentNode}
                  listHeight={180}
                  onChange={(e, value) => {
                    handleSubQuestionTypeChange(value);
                  }}
                  onFocus={() => {
                    fetchSubQuestionTypesList({ question_type: questionTypeId });
                  }}
                  filterOption={(input, options) => {
                    return (
                      options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {subQuestionTypeOptions}
                </Select>
              </Form.Item>
            </div>
            <div className='col-sm-2 col-6 pl-0'>
              <Form.Item name='marks' label='Marks'>
                <Input
                  type='number'
                  placeholder='Marks*'
                  className='text-center'
                  maxLength={2}
                  // value={marks}
                  onChange={(e) => {
                    if (e.target.value > 99) {
                      message.warning('Marks must be 2 digit number');
                      questionFormRef.current.setFieldsValue({
                        marks: e.target.value.substring(0, 2),
                      });
                    } else {
                      setMarks(e.target.value);
                      if (isSubQuestion) {
                        let subQuestions = currentQuestion.sub_questions;
                        subQuestions[subQuestionIndex]['question_mark'] = e.target.value;
                        setCurrentQuestion({
                          ...currentQuestion,
                          sub_questions: subQuestions,
                        });
                      } else {
                        let updatedQuestion = _.cloneDeep(currentQuestion);
                        updatedQuestion['question_mark'] = e.target.value;
                        setCurrentQuestion(updatedQuestion);
                      }
                    }
                  }}
                  pattern='\d+'
                  min={1}
                />
              </Form.Item>
            </div>
          </div>
        </Form>
        <div className='row'>
          {currentQuestion?.question_answer?.length > 0 ? (
            <div className='col-12 px-0'>
              {currentQuestion?.question_type?.type_name == 'Comprehension' &&
              !isSubQuestion ? (
                <>
                  <ComprehensionNew
                    currentQuestion={currentQuestion}
                    questionTypeList={questionTypeList}
                    handleQuestionChanges={handleQuestionChanges}
                    handleDeleteSubQuestion={handleDeleteSubQuestion}
                  />

                  <div className='py-2 text-center px-1'>
                    {' '}
                    <Button
                      icon={<PlusCircleOutlined />}
                      onClick={() => {
                        handleAddSubQuestion();
                      }}
                      className='th-bg-primary th-white'
                    >
                      Add new sub question{' '}
                    </Button>
                  </div>
                </>
              ) : (
                <QuestionTypehandler
                  currentQuestion={
                    isSubQuestion
                      ? currentQuestion?.sub_questions[subQuestionIndex]
                      : currentQuestion
                  }
                  subQuestionType={
                    isSubQuestion
                      ? currentQuestion?.sub_questions[subQuestionIndex]?.question_type
                          ?.type_name
                      : currentQuestion?.question_type?.type_name
                  }
                  handleQuestionChanges={handleQuestionChanges}
                  isSubQuestion={isSubQuestion}
                  subQuestionIndex={subQuestionIndex}
                />
              )}
            </div>
          ) : (
            <div className='col-12 text-center py-2'>
              {' '}
              <InfoCircleOutlined className='pr-2' /> Please select the Question Sub type
              first
            </div>
          )}
        </div>
        {currentQuestion?.question_answer?.length > 0 && !isSubQuestion && (
          <div
            className='row py-2'
            style={{ position: 'sticky', bottom: '0%', background: 'white', zIndex: 6 }}
          >
            <div className='col-6 text-left'>
              <Button type='default' className='w-50' onClick={handleCloseEnableMode}>
                Cancel
              </Button>
            </div>
            <div className='col-6 text-right'>
              <Button
                type='primary'
                className='w-25'
                loading={updateLoading}
                onClick={() => {
                  handleUpdateQuestion();
                }}
              >
                Update
              </Button>
            </div>
          </div>
        )}
      </Spin>
    </div>
  );
};

export default EditQuestionCard;
