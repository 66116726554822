import React from 'react';
import './assess-attemption.css';

const QuestionHeader = () => {
  return (
    <>
      <div className='question-numbers'>
        <div>1</div>
        <div>Progress - 1/1</div>
      </div>
    </>
  );
};
export default QuestionHeader;
