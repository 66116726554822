import React, { useContext, useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Button,
  useTheme,
  Divider,
  Switch,
  FormControlLabel,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Autocomplete from '@material-ui/lab/Autocomplete';
import endpoints from '../../../config/endpoints';
import axiosInstance from '../../../config/axios';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';

import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 auto',
    boxShadow: 'none',
  },
  container: {
    maxHeight: '70vh',
    width: '100%',
  },
  columnHeader: {
    color: `${theme.palette.secondary.main} !important`,
    fontWeight: 600,
    fontSize: '1rem',
    backgroundColor: `#ffffff !important`,
  },
  clrBtn: {
    margin: 3,
    color: 'rgb(140, 140, 140) !important',
    //  !important
  },
  tableCell: {
    color: theme.palette.secondary.main,
  },
  buttonContainer: {
    width: '95%',
    margin: '0 auto',
    background: theme.palette.background.secondary,
    paddingBottom: theme.spacing(2),
  },
}));
const columns = [
  {
    id: 'board_id',
    label: 'Board Name',
    minWidth: 100,
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'module_sequence',
    label: 'Module Sequence',
    minWidth: 100,
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'module_id',
    label: 'Module Name',
    minWidth: 100,
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'chapter-sequence',
    label: 'Chapter Sequence',
    minWidth: 100,
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'subject_name',
    label: 'Chapter',
    minWidth: 100,
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'chapter_reference',
    label: 'Chapter Reference ',
    minWidth: 100,
    align: 'center',
    labelAlign: 'center',
  },
  // {
  //   id: 'created_by',
  //   label: 'No. Of Periods',
  //   minWidth: 100,
  //   align: 'center',
  //   labelAlign: 'center',
  // },
  // {
  //   id: 'desc',
  //   label: 'Description',
  //   minWidth: 100 ,
  //   align: 'center',
  //   labelAlign: 'center',
  // },
  // {
  //   id: 'optional',
  //   label: 'Optional',
  //   minWidth: 50,
  //   align: 'center',
  //   labelAlign: 'center',
  // },
  // {
  //   id: 'actions',
  //   label: 'Actions',
  //   minWidth: 170,
  //   align: 'center',
  //   labelAlign: 'center',
  // },
];

const CreateChapter = ({ chapterData, setLoading, handleGoBack }) => {
  const classes = useStyles();
  const { setAlert } = useContext(AlertNotificationContext);
  const [subjectName, setSubjectName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedGrade, setSelectedGrade] = useState([]);
  const [selectedSection, setSelectedSection] = useState([]);
  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const [sections, setSections] = useState([]);
  const [optional, setOptional] = useState(false);
  const [academicYearDropdown, setAcademicYearDropdown] = useState([]);
  const [volumeDropdown, setVolumeDropdown] = useState([]);
  const [gradeDropdown, setGradeDropdown] = useState([]);
  const [subjectDropdown, setSubjectDropdown] = useState([]);
  const [boardDropdown, setBoardDropdown] = useState([]);
  const [moduleDropDown, setModuleDropdown] = useState([]);
  const [chapterName, setChapterName] = useState([]);
  const [chapterRef, setChapterRef] = useState([]);
  const [chapterSequence, setChapterSequence] = useState('');
  const [gradeSubjectMapping, setGradeSubjectMapping] = useState('');
  const [openModule, setOpenModule] = useState(false);
  const [module_name, setModule_name] = useState('');
  const [AddmoduleButton, setAddmoduleButton] = useState(false);
  const [moduleSequenceNumber, setModuleSequenceNumber] = useState('');

  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [subjects, setSubjects] = useState([]);
  const [tableFlag, setTableFlag] = useState(true);
  const { role_details } = JSON.parse(localStorage.getItem('userDetails'));
  const limit = 15;
  console.log('DataChapter', chapterData);
  const [filterData, setFilterData] = useState({
    year: chapterData?.year,
    volume: chapterData?.volume,
    grade: chapterData?.grade,
    subject: chapterData?.subject,
    chapter: '',
    board: chapterData?.board,
    module: chapterData?.module,
  });
  const handleClickOpen = () => {
    setOpenModule(true);
  };

  const handleClose = () => {
    setOpenModule(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };
  useEffect(() => {
    // setLoading(true);
    axiosInstance
      .get(`${endpoints.masterManagement.academicYear}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setLoading(false);
          setAcademicYearDropdown(result.data.result.results);
          handleAcademicYear('', result.data.current_acad_session_data[0]);
        } else {
          // setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        // setAlert('error', error.message);
      });

    axiosInstance
      .get(`${endpoints.masterManagement.volumes}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setLoading(false);
          setVolumeDropdown(result.data.result.results);
        } else {
          // setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        // setAlert('error', error.message);
      });

    axiosInstance
      .get(`${endpoints.masterManagement.grades}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setLoading(false);
          setGradeDropdown(result.data.result.results);
        } else {
          // setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        // setAlert('error', error.message);
      });
    axiosInstance
      .get(`${endpoints.masterManagement.boardMappingList}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setLoading(false);
          setBoardDropdown(result.data.result);
          // console.log(result.data.result,'hargsy77777')
        } else {
          // setAlert('error', result.data.message)
          setLoading(false);
          setBoardDropdown([]);
        }
      })
      .catch((error) => {
        // setAlert('error', error.message);
        setLoading(false);
        setBoardDropdown([]);
      });
    if (chapterData?.grade) {
      axiosInstance
        .get(
          `${endpoints.masterManagement.gradeSubjectMappingList}?grade=${chapterData?.grade?.id}`
        )
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setSubjectDropdown(result.data.result.results);
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
            setSubjectDropdown([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
          setSubjectDropdown([]);
        });
    }
    if (chapterData?.grade) {
      axiosInstance
        .get(
          `${endpoints.masterManagement.gradeSubjectMappingList}?grade=${chapterData.grade?.id}&subject=${chapterData?.subject?.subject?.subject_name}`
        )
        .then((response) => {
          if (response.data.status_code === 200) {
            setLoading(false);
            let selectedSubject = response.data.result?.results?.filter(
              (item) => item.subject.id === chapterData?.subject?.subject?.id
            );
            setGradeSubjectMapping(selectedSubject[0]?.id);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
        });
    }
  }, []);
  useEffect(() => {
    handleTabel();
  }, [page, filterData]);
  const handleTabel = () => {
    if (
      filterData.subject?.id &&
      filterData.board?.length !== 0 &&
      filterData.module?.length !== 0
    ) {
      setLoading(true);
      axiosInstance
        .get(
          `${endpoints.masterManagement.chapters}?grade_subject=${filterData.subject.id}&board=${filterData.board.id}&lt_module=${filterData?.module?.id}&page=${page}&page_size=15`
        )
        .then((result) => {
          if (result.data.status_code === 200) {
            setTableFlag(false);
            setLoading(false);
            let count = result.data.result ? result.data.result.length : 0;
            setTotalCount(result.data.count ? result.data.count : count);
            setSubjects(result.data.result);
            setAlert('success', result.data.message);
          } else {
            setTableFlag(true);
            // setAlert('error', result.data.error_message);
          }
        })
        .catch((error) => {
          setTableFlag(true);
          // setAlert('error', error.message);
        });
    } else {
      // console.log('no data')
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axiosInstance
      .post(endpoints.masterManagement.createChapters, {
        grade_subject_mapping: gradeSubjectMapping,
        volume: filterData.volume.id,
        chapter_name: chapterName.trim(),
        rfrnc: chapterRef.length !== 0 ? chapterRef?.trim() : null,
        academic_year: filterData.year.id,
        // "no_of_periods":noOfChapter,
        board: filterData.board.id,
        lt_module: filterData.module.id,
        sequence: Number(chapterSequence),
      })
      .then((result) => {
        if (result.data.status_code === 200) {
          handleTabel();
          // setFilterData({
          //   year: '',
          //   volume: '',
          //   grade: '',
          //   subject: '',
          //   chapter: '',
          //   board: '',
          //   module: '',

          // });
          setChapterName('');
          setChapterRef('');
          setChapterSequence('');
          // setNoOfChapter('')
          setLoading(false);
          setAlert('success', result.data.message);
        } else {
          setLoading(false);
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        // setAlert('error', error.message)
        if (error.response.data.message == 'Chapter is existed') {
          setAlert('error', 'Chapter Name Already Exists');
        } else {
          setAlert('error', error.response.data.message);
        }
      });
  };
  const createChapterhandleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axiosInstance
      .post(endpoints.masterManagement.createUpdatemodule, {
        lt_module_name: module_name.trim(),
        grade_subject_mapping: gradeSubjectMapping,
        volume: filterData.volume.id,
        academic_year: filterData.year.id,
        board: filterData.board.id,
        sequence: Number(moduleSequenceNumber),
      })
      .then((result) => {
        if (result.status === 201) {
          {
            console.log('valueBoardData', filterData.board);
            handleBoards(filterData.board);

            handleClose();
            setLoading(false);
            setAlert('success', result.data.message);
          }
        } else {
          setLoading(false);
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data.message == 'Module existed') {
          setAlert('error', 'Module Name Already Exists');
        } else {
          setAlert('error', error.response.data.message);
        }
      });
  };

  const handleAcademicYear = (event, value) => {
    setFilterData({ ...filterData, year: '' });
    if (value) {
      setFilterData({ ...filterData, year: value });
    } else {
      setFilterData({
        year: '',
        volume: '',
        grade: '',
        subject: '',
        board: '',
        chapter: '',
      });
    }
  };
  const handleVolume = (event, value) => {
    setFilterData({ ...filterData, volume: '' });
    if (value) {
      setFilterData({ ...filterData, volume: value });
    } else {
      setFilterData({
        year: filterData?.year,
        volume: '',
        grade: '',
        subject: '',
        board: '',
        chapter: '',
      });
    }
  };

  const handleGrade = (event, value) => {
    setFilterData({ ...filterData, grade: '' });
    if (value) {
      setLoading(true);
      setFilterData({ ...filterData, grade: value });
      axiosInstance
        .get(`${endpoints.masterManagement.gradeSubjectMappingList}?grade=${value.id}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setSubjectDropdown(result.data.result.results);
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
            setSubjectDropdown([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
          setSubjectDropdown([]);
        });
    } else {
      setLoading(false);
      setFilterData({
        year: filterData?.year,
        volume: filterData?.volume,
        grade: '',
        subject: '',
        board: '',
        chapter: '',
      });
      setSubjectDropdown([]);
    }
  };
  const handleSubject = (event, value) => {
    setFilterData({ ...filterData, subject: '' });
    if (value) {
      setLoading(true);
      setFilterData({ ...filterData, subject: value });
      axiosInstance
        .get(
          `${endpoints.masterManagement.gradeSubjectMappingList}?grade=${filterData.grade?.id}&subject=${value?.subject?.subject_name}`
        )
        .then((response) => {
          if (response.data.status_code === 200) {
            setLoading(false);
            let selectedSubject = response.data.result?.results?.filter(
              (item) => item.subject.id === value?.subject?.id
            );
            setGradeSubjectMapping(selectedSubject[0]?.id);
          }
        });
      axiosInstance
        .get(`${endpoints.masterManagement.boardMappingList}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setBoardDropdown(result.data.result);
            // console.log(result.data.result,'hargsy77777')
          } else {
            // setAlert('error', result.data.message)
            setLoading(false);
            setBoardDropdown([]);
          }
        })
        .catch((error) => {
          // setAlert('error', error.message);
          setLoading(false);
          setBoardDropdown([]);
        });
    } else {
      setLoading(false);
      setBoardDropdown([]);
      setFilterData({
        year: filterData?.year,
        volume: filterData?.volume,
        grade: filterData?.grade,
        subject: '',
        board: '',
        chapter: '',
      });
    }
  };

  const handleBoards = (value) => {
    if (value) {
      setFilterData({ ...filterData, board: value });
      setAddmoduleButton(true);

      axiosInstance
        .get(
          `${endpoints.masterManagement.module}?academic_year=${filterData?.year?.id}&volume=${filterData?.volume?.id}&grade_subject=${filterData?.subject?.id}&board=${value?.id}`
        )
        .then((result) => {
          if (result.data.status_code === 200) {
            console.log(result.data.result);
            setModuleDropdown(result.data.result);
          } else {
            // setAlert('error', result.data.message);
            setModuleDropdown([]);
          }
        })
        .catch((error) => {
          // setAlert('error', error.message);
          setModuleDropdown([]);
        });
    } else {
      console.log('valueBoard2', value);

      setAddmoduleButton(false);
      setModuleDropdown([]);
      setFilterData({
        year: filterData?.year,
        volume: filterData?.volume,
        grade: filterData?.grade,
        subject: filterData?.subject,
        board: '',
        chapter: '',
      });
    }
  };
  const handleBoard = (event, value) => {
    if (value) {
      setLoading(true);
      setFilterData({ ...filterData, board: value });
      setAddmoduleButton(true);

      axiosInstance
        .get(
          `${endpoints.masterManagement.module}?academic_year=${filterData?.year?.id}&volume=${filterData?.volume?.id}&grade_subject=${filterData?.subject?.id}&board=${value?.id}`
        )
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setModuleDropdown(result.data.result);
          } else {
            // setAlert('error', result.data.message);
            setLoading(false);
            setModuleDropdown([]);
          }
        })
        .catch((error) => {
          // console.log(error,'ERROR Check==>')
          // setAlert('error', error.message);
          setLoading(false);
          setModuleDropdown([]);
        });
    } else {
      console.log('valueBoard2', value);

      setAddmoduleButton(false);
      setModuleDropdown([]);
      setFilterData({
        year: filterData?.year,
        volume: filterData?.volume,
        grade: filterData?.grade,
        subject: filterData?.subject,
        board: '',
        chapter: '',
      });
    }
  };

  const handleModule = (event, value) => {
    setFilterData({ ...filterData, module: '' });
    if (value) {
      setFilterData({ ...filterData, module: value });
    } else {
      setFilterData({
        year: filterData?.year,
        volume: filterData?.volume,
        grade: filterData?.grade,
        subject: filterData?.subject,
        board: filterData?.board,
        module: '',
        chapter: '',
      });
      setAddmoduleButton(true);
    }
  };
  return (
    <div>
      {/* <Dialog open={openModule} onClose={handleClose}>
        <form onSubmit={createChapterhandleSubmit}>
          <DialogTitle>Add Module</DialogTitle>
          <DialogContent>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  size='small'
                  onChange={handleAcademicYear}
                  style={{ width: '100%' }}
                  id='grade'
                  options={academicYearDropdown}
                  value={filterData.year}
                  getOptionLabel={(option) => option?.session_year}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Academic Year'
                      placeholder='Academic Year'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  size='small'
                  onChange={handleVolume}
                  style={{ width: '100%' }}
                  id='grade'
                  options={volumeDropdown}
                  value={filterData.volume}
                  getOptionLabel={(option) => option?.volume_name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Volume'
                      placeholder='Volume'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  size='small'
                  onChange={handleGrade}
                  style={{ width: '100%' }}
                  id='grade'
                  options={gradeDropdown}
                  value={filterData.grade}
                  getOptionLabel={(option) => option?.grade_name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Grades'
                      placeholder='Grades'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  size='small'
                  onChange={handleSubject}
                  style={{ width: '100%' }}
                  id='grade'
                  options={subjectDropdown}
                  value={filterData.subject}
                  getOptionLabel={(option) => option?.subject?.subject_name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Subject'
                      placeholder='Subject'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  size='small'
                  onChange={handleBoard}
                  style={{ width: '100%' }}
                  id='board'
                  options={boardDropdown}
                  value={filterData.board}
                  getOptionLabel={(option) => option?.board_name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Board'
                      placeholder='Board'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                <TextField
                  id='sessionyear'
                  label='Module Name'
                  style={{ width: '100%' }}
                  variant='outlined'
                  size='small'
                  placeholder='Enter Module name'
                  value={module_name}
                  // inputProps={{maxLength:7,pattern:'^[0-9]{4}-[0-9]{2}'}}
                  name='Module_Name'
                  onChange={(e) => setModule_name(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                <TextField
                  id='modulesequencenumber'
                  type='number'
                  label='Module Sequence Number'
                  placeholder='Enter Module Sequence Number'
                  onChange={(e) => setModuleSequenceNumber(e.target.value)}
                  style={{ width: '100%' }}
                  variant='outlined'
                  size='small'
                  SelectProps={{
                    native: true,
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              spacing={isMobile ? 1 : 5}
              style={{ width: '95%', margin: '10px' }}
            >
              <Grid
                item
                xs={6}
                sm={2}
                className={isMobile ? '' : 'addEditButtonsPadding'}
              >
                <Button
                  variant='contained'
                  className='custom_button_master labelColor'
                  size='medium'
                  onClick={handleClose}
                >
                  Back
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2}
                className={isMobile ? '' : 'addEditButtonsPadding'}
              >
                <Button
                  variant='contained'
                  style={{ color: 'white' }}
                  color='primary'
                  className='custom_button_master'
                  size='medium'
                  type='submit'
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog> */}

      <form autoComplete='off' onSubmit={handleSubmit}>
        <div style={{ width: '95%', margin: '20px auto' }}>
          <Grid container spacing={5}>
            <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
              <Autocomplete
                size='small'
                onChange={handleAcademicYear}
                style={{ width: '100%' }}
                id='grade'
                options={academicYearDropdown}
                value={filterData.year}
                getOptionLabel={(option) => option?.session_year}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Academic Year'
                    placeholder='Academic Year'
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
              <Autocomplete
                size='small'
                onChange={handleVolume}
                style={{ width: '100%' }}
                id='grade'
                options={volumeDropdown}
                value={filterData.volume}
                getOptionLabel={(option) => option?.volume_name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Volume'
                    placeholder='Volume'
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
              <Autocomplete
                size='small'
                onChange={handleGrade}
                style={{ width: '100%' }}
                id='grade'
                options={gradeDropdown}
                value={filterData.grade}
                getOptionLabel={(option) => option?.grade_name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Grades'
                    placeholder='Grades'
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
              <Autocomplete
                size='small'
                onChange={handleSubject}
                style={{ width: '100%' }}
                id='grade'
                options={subjectDropdown}
                value={filterData.subject}
                getOptionLabel={(option) => option?.subject?.subject_name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Subject'
                    placeholder='Subject'
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
              <Autocomplete
                size='small'
                onChange={handleBoard}
                style={{ width: '100%' }}
                id='board'
                options={boardDropdown}
                value={filterData.board}
                getOptionLabel={(option) => option?.board_name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Board'
                    placeholder='Board'
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
              <Autocomplete
                size='small'
                onChange={handleModule}
                style={{ width: '100%' }}
                id='module'
                options={moduleDropDown}
                value={filterData.module}
                getOptionLabel={(option) => option?.lt_module_name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Module'
                    placeholder='Module'
                    required
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={1} sm={1} className={isMobile ? '' : 'addEditPadding'}>
              {AddmoduleButton && (
                <Button
                  startIcon={<AddOutlinedIcon style={{ fontSize: '30px' }} />}
                  variant='contained'
                  color='primary'
                  size='small'
                  style={{ color: 'white' }}
                  title='Add Chapter'
                  onClick={handleClickOpen}
                ></Button>
              )}
            </Grid> */}
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>

          {/* <Grid container spacing={5}>
<Grid item xs={12} sm={4} className={isMobile?'':'addEditPadding'}>
<Autocomplete
size='small'
onChange={handleSection}
style={{ width: '100%' }}
id='section'
options={sections}
value={selectedSection}
getOptionLabel={(option) => option?.section__section_name}
filterSelectedOptions
renderInput={(params) => (
<TextField
{...params}
variant='outlined'
label='Section'
placeholder='Sections'
required
/>
)}
/>
</Grid>
</Grid> */}
          <Grid container spacing={5}>
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
              <TextField
                id='chname'
                style={{ width: '100%' }}
                label='Chapter Name'
                variant='outlined'
                size='small'
                value={chapterName}
                inputProps={{ accept: '^[a-zA-Z0-9 +_-]+', maxLength: 100 }}
                //inputProps={{pattern:'^[a-zA-Z0-9]+',maxLength:100}}
                name='chname'
                onChange={(e) => setChapterName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
              <TextField
                id='ref'
                style={{ width: '100%' }}
                label='Chapter Reference'
                variant='outlined'
                size='small'
                value={chapterRef}
                inputProps={{ accept: '^[a-zA-Z0-9 +_-]+', maxLength: 100 }}
                //inputProps={{pattern:'^[a-zA-Z0-9]+',maxLength:100}}
                name='ref'
                onChange={(e) => setChapterRef(e.target.value)}
                // required
              />
            </Grid>
            {/* <Grid item xs={12} sm={4} className={isMobile?'':'addEditPadding'}>
<TextField
id='subname'
type="number"
style={{ width: '100%' }}
label='Total No. Of Periods'
variant='outlined'
size='small'
value={noOfChapter}
inputProps={{pattern:"[0-9]*",min:0,maxLength:20}}
name='subname'
onChange={e=>setNoOfChapter(e.target.value)}
required
/>
</Grid> */}
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
              <TextField
                id='chaptersequencenumber'
                type='number'
                value={chapterSequence || ''}
                label='Chapter Sequence Number'
                placeholder='Enter Chapter Sequence Number'
                onChange={(e) => setChapterSequence(e.target.value)}
                style={{ width: '100%' }}
                variant='outlined'
                size='small'
                SelectProps={{
                  native: true,
                }}
              />
            </Grid>
          </Grid>
        </div>
        <Grid
          container
          spacing={isMobile ? 1 : 5}
          style={{ width: '95%', margin: '10px' }}
        >
          <Grid item xs={6} sm={2} className={isMobile ? '' : 'addEditButtonsPadding'}>
            <Button
              variant='contained'
              className='custom_button_master labelColor'
              size='medium'
              onClick={handleGoBack}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={6} sm={2} className={isMobile ? '' : 'addEditButtonsPadding'}>
            <Button
              variant='contained'
              style={{ color: 'white' }}
              color='primary'
              className='custom_button_master'
              size='medium'
              type='submit'
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>

      {!isMobile ? (
        <>
          {!tableFlag && (
            <Paper className={`${classes.root} common-table`}>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label='sticky table'>
                  <TableHead className='table-header-row'>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                          className={classes.columnHeader}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {subjects.map((subject, index) => {
                      return (
                        <TableRow hover subject='checkbox' tabIndex={-1} key={index}>
                          <TableCell className={classes.tableCell}>
                            {subject?.board === null ? (
                              <b style={{ color: 'red' }}> NA </b>
                            ) : (
                              subject?.board?.board_name
                            )}
                            {/* {subject?.board?.board_name} */}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {subject?.lt_module?.sequence}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {subject?.lt_module === null ? (
                              <b style={{ color: 'red' }}> NA </b>
                            ) : (
                              subject?.lt_module?.lt_module_name
                            )}
                            {/* {subject?.board?.board_name} */}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {subject?.sequence}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {subject?.chapter_name}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {subject?.rfrnc === null ? (
                              <b style={{ color: 'red' }}> NA</b>
                            ) : (
                              subject?.rfrnc
                            )}
                          </TableCell>
                          {/* <TableCell className={classes.tableCell}>
                                {subject?.no_of_periods}
                              </TableCell> */}
                          {/* <TableCell className={classes.tableCell}>
                      {subject.subject_description}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {(subject.is_optional)?'Yes':'No'}
                    </TableCell> */}
                          {/* <TableCell className={classes.tableCell}>
                                <IconButton
                                  onClick={(e) => {
                                    // handleDelete(subject);
                                  }}
                                  title='Delete Chapter'
                                >
                                  <DeleteOutlinedIcon style={{ color: '#fe6b6b' }} />
                                </IconButton>
                                <IconButton
                                  // onClick={(e) => handleEditSubject(subject)}
                                  title='Edit Chapter'
                                >
                                  <EditOutlinedIcon style={{ color: '#fe6b6b' }} />
                                </IconButton>
                              </TableCell> */}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className='paginateData'>
                <TablePagination
                  component='div'
                  count={totalCount}
                  className='customPagination'
                  rowsPerPage={limit}
                  page={page - 1}
                  onChangePage={handleChangePage}
                  rowsPerPageOptions={false}
                />
              </div>
            </Paper>
          )}
        </>
      ) : (
        <>
          <>
            {!tableFlag && (
              <>
                {/* {subjects.map((subject) => (
                      <SubjectCard
                        data={subject}
                        handleDelete={handleDelete}
                        handleEditSubject={handleEditSubject}
                      />
                    ))} */}
                <div className='paginateData paginateMobileMargin'>
                  <TablePagination
                    component='div'
                    count={totalCount}
                    rowsPerPage={limit}
                    page={page - 1}
                    onChangePage={handleChangePage}
                    rowsPerPageOptions={false}
                    className='table-pagination'
                  />
                </div>
              </>
            )}
          </>
        </>
      )}
    </div>
  );
};

export default CreateChapter;
