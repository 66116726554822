import React, { useState, useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Button, useTheme, IconButton, SvgIcon } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import useStyles from './useStyles';
import endpoints from '../../../../config/endpoints';
import axiosInstance from '../../../../config/axios';
import { AlertNotificationContext } from '../../../../context-api/alert-context/alert-state';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ReactHtmlParser from 'react-html-parser';

const levels = [
  { id: '1', level: 'Easy' },
  { id: '2', level: 'Average' },
  { id: '3', level: 'Difficult' },
];

const QuestionBankCard = ({
  period,
  setPeriodDataForView,
  setViewMoreData,
  setViewMore,
  setCallFlag,
  setLoading,
  index,
  periodColor,
  setSelectedIndex,
  onClick,
  showAddToQuestionPaper,
  handleMultiplePublish,
  redFlag,
  periodData,
  toggleCompleteQuestion,
}) => {
  const themeContext = useTheme();
  const { setAlert } = useContext(AlertNotificationContext);
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const classes = useStyles();
  const [showMenu, setShowMenu] = useState(false);
  const [showPeriodIndex, setShowPeriodIndex] = useState();
  const getInitialTag = (questionLevel) =>
    levels.reduce((tag, { id, level }) => {
      if (id === questionLevel) {
        tag = level;
      }
      return tag;
    }, '');

  const questionName = period?.question_answer;

  const handlePeriodMenuOpen = (index, id) => {
    setShowMenu(true);
    setShowPeriodIndex(index);
  };

  const handlePeriodMenuClose = (index) => {
    setShowMenu(false);
    setShowPeriodIndex();
  };

  const handleViewMore = () => {
    setLoading(true);
    axiosInstance
      .get(`${endpoints.questionBank.viewMoreData}?question=${period?.id}`)
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setLoading(false);
          setViewMore(true);
          setViewMoreData(result?.data?.result);
          setPeriodDataForView(period);
          setSelectedIndex(index);
        } else {
          setLoading(false);
          setViewMore(false);
          setViewMoreData({});
          setPeriodDataForView([]);
          setAlert('error', result?.data?.message);
          setSelectedIndex(-1);
        }
      })
      .catch((error) => {
        setLoading(false);
        setViewMore(false);
        setViewMoreData({});
        setPeriodDataForView([]);
        setAlert('error', error?.message);
        setSelectedIndex(-1);
      });
  };

  const questionType = (type) => {
    switch (type) {
      case 1:
        return 'MCQ SINGLE CHOICE';
      case 2:
        return 'MCQ_MULTIPLE_CHOICE';
      case 3:
        return 'Match the Following';
      case 4:
        return 'Video Question';
      case 5:
        return 'PPT Question';
      case 6:
        return 'Matrix Questions';
      case 7:
        return 'Comprehension Questions';
      case 8:
        return 'True False';
      case 9:
        return 'Fill In The Blanks';
      case 10:
        return 'Descriptive';
      default:
        return '--';
    }
  };

  // function extractContent(s) {
  //   const span = document.createElement('span');
  //   span.innerHTML = s;
  //   return span.textContent || span.innerText;
  // }
  const handleDelete = (obj) => {
    axiosInstance
      .put(`${endpoints.questionBank.deleteQuestion}`, {
        question: obj?.id,
        is_delete: true,
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setCallFlag((prev) => !prev);
          window.location.reload();
          setAlert('success', 'Question Deleted Successfully');
        } else {
          setAlert('error', 'ERROR!');
        }
      })
      .catch((error) => {
        setAlert('error', error?.message);
      });
  };

  const extractContentOption = (s) => {
    if (s?.length > 0 && s.indexOf('<') > -1) {
      let newarr = s.replace(/</g, '&lt;');
      newarr = newarr.replace('&lt;p>', '');
      newarr = newarr.replace('&lt;/p>', '');
      newarr = newarr.replace('&lt;strong>', '');
      newarr = newarr.replace('&lt;/strong>', '');
      // newarr = newarr.replaceAll('&lt;br />',' ');
      newarr = newarr.split('&lt;br />').join(' ');
      const span = document.createElement('span');
      span.innerHTML = newarr;
      return span.textContent || span.innerText;
    } else {
      const span = document.createElement('span');
      span.innerHTML = s;
      return span.textContent || span.innerText;
    }
  };

  const checkName = (node) => {
    if (node?.split('"').filter((str) => str.startsWith('https')).length > 0) {
      return ReactHtmlParser(node);
    } else {
      return extractContentOption(node);
    }
  };
  return (
    <Paper
      className={periodColor ? classes.selectedRoot : classes.root}
      style={
        isMobile
          ? { margin: '0rem auto' }
          : {
              margin: '0rem auto -1.1rem auto',
              background: period?.question_status === '2' ? '#FCEEEE ' : '',
              border: period?.question_status === '2' ? '1px solid red ' : '',
              height: '300px',
            }
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            {period?.question_status === '3' && window.location.search?.length == 0 ? (
              <Checkbox
                id={period?.id}
                checked={period?.checked}
                onChange={(e) => handleMultiplePublish(e, period, index)}
                // disabled={redFlag === true ? true : false}
                name={period?.id}
                size='small'
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            ) : (
              ''
            )}
            {showAddToQuestionPaper && period?.question_status === '2' ? (
              <Checkbox
                id={period?.id}
                checked={period?.checked}
                onChange={(e) => toggleCompleteQuestion(e, period, index)}
                name={period?.id}
                size='small'
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            ) : (
              ''
            )}
            <Typography
              className={classes.title}
              variant='p'
              component='p'
              color='primary'
            >
              {questionType(period?.question_type)}
            </Typography>
            <Typography
              variant='p'
              component='p'
              color='secondary'
              style={{ marginLeft: '10px', marginTop: '1px' }}
            >
              ({getInitialTag(period?.question_level)})
            </Typography>
          </Box>
          <Box>
            <Typography
              className={classes.content}
              variant='p'
              component='p'
              color='secondary'
              noWrap
            >
              {period?.question_status === '1' ? 'Draft' : null}
              {period?.question_status === '2' ? 'Published' : null}
              {period?.question_status === '3' ? 'Review' : null}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={4} className={classes.textRight}>
          <Box>
            <span
              className='period_card_menu'
              onClick={() => handlePeriodMenuOpen(index)}
              onMouseLeave={handlePeriodMenuClose}
            >
              <IconButton className='moreHorizIcon' color='primary'>
                <MoreHorizIcon />
              </IconButton>
              {showPeriodIndex === index && showMenu ? (
                <div className='tooltipContainer'>
                  <span className='tooltiptext'>
                    <div onClick={(e) => handleDelete(period)}>Delete</div>
                  </span>
                </div>
              ) : null}
            </span>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12}>
          {period.question_type === 7 ? (
            <Box>
              <Typography
                className={classes.content}
                variant='p'
                component='p'
                color='secondary'
                noWrap
              >
                {extractContentOption(questionName[0]?.question)}
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography
                className={classes.content}
                variant='p'
                component='p'
                color='secondary'
                noWrap
              >
                Question:{' '}
                <div className='card-img' style={{ height: '100px', overflowY: 'auto' }}>
                  {ReactHtmlParser(questionName[0]?.question)}
                </div>
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid item xs={6}>
          <Box>
            <Typography
              className={classes.title}
              variant='p'
              component='p'
              color='secondary'
            >
              Created By: {period?.created_by?.first_name}
            </Typography>
          </Box>
          <Box>
            <Typography
              className={classes.content}
              variant='p'
              component='p'
              color='secondary'
            >
              {/* {period.updated_at.substring(0, 10)}s */}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} className={classes.textRight}>
          {!periodColor && (
            <Button
              variant='contained'
              style={{ color: 'white', borderRadius: '10px' }}
              color='primary'
              className='custom_button_master'
              size='small'
              onClick={handleViewMore}
            >
              VIEW MORE
            </Button>
          )}
          {showAddToQuestionPaper && period?.question_status === '2' ? (
            <Button
              variant='contained'
              color='primary'
              className='custom_button_master'
              size='small'
              onClick={() => onClick(period)}
              style={{ margin: '15px 0', borderRadius: '10px', color: 'white' }}
            >
              ADD TO QUESTION PAPER
            </Button>
          ) : (
            ''
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default QuestionBankCard;
