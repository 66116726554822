import { Button, Card, Checkbox, Input, message, Select, Switch } from 'antd';
import React, { useContext, useEffect, useState } from 'react';

import './options.css';
import { marks, marksoption } from '../const';
import {
  CloseCircleOutlined,
  DeleteOutlined,
  FileImageOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import './options.css';
import cuid from 'cuid';
import axiosInstance from '../../../../../config/axios';
import endpoints from '../../../../../config/endpoints';
import { AlertNotificationContext } from '../../../../../context-api/alert-context/alert-state';
import Loader from '../../../../../components/loader/loader';
const Mcq = ({ questionData, questionList, setQuestions, parentIndex, comptype, childIndex }) => {
  const { setAlert } = useContext(AlertNotificationContext);
  const [answers, setAnswers] = useState([]);
  const [loading, setLoading] = useState(false);

  const [optionsList, setOptionsList] = useState([
    { isChecked: false, optionValue: '', images: [] },
    { isChecked: false, optionValue: '', images: [] },
    { isChecked: false, optionValue: '', images: [] },
  ]);

  const [isSingleChoice, setIsSingleChoice] = useState(false);

  const handleAddOption = () => {
    setOptionsList([...optionsList, { isChecked: false, optionValue: '', images: [] }]);
  };

  const handleFileUpload = (list, file, name, index) => {
    setLoading(true)
    if (
      file &&
      file[0] &&
      (file[0]?.name.lastIndexOf('.jpg') > 0 ||
        file[0]?.name.lastIndexOf('.jpeg') > 0 ||
        file[0]?.name.lastIndexOf('.png') > 0)
    ) {
      const formData = new FormData();
      formData.append('file', file[0]);
      formData.append('grade_name', questionData?.grade?.grade_name);
      formData.append('subject_name', questionData?.subject.children);
      formData.append('question_categories', questionData?.question_categories?.value);
      formData.append('question_type', questionData?.question_type?.children);
      axiosInstance
        .post(`${endpoints.questionBank.uploadFile}`, formData)
        .then((result) => {
          if (result?.data?.status_code === 200) {
            list[index][name].push(result?.data?.result);
            setOptionsList(list);
            setLoading(false);
            setAlert('success', result?.data?.message);
          } else {
            setLoading(false);
            setAlert('error', result?.data?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
      // const formData = new FormData();
      // formData.append('file', file[0]);
    } else {
      setLoading(false);
      setAlert('error', 'Only .jpg, .jpeg, .png format is acceptable');
    }
  };

  const handleDeleteOption = (ind) => {
    let filteredOption = optionsList?.filter((item, index) => index != ind);
    setOptionsList(filteredOption);
    const answerList = [...answers];
    if (answerList?.length > 0) {
      if (answerList?.length === optionsList?.length) {
        answerList.splice(answerList.length - 1, 1);
      } else {
        for (let k = 0; k < answerList?.length; k++) {
          if (answerList[k] === `option${ind + 1}`) {
            answerList.splice(k, 1);
            break;
          }
        }
      }
      setAnswers(answerList);
    }
  };
  const handleOptionUpdate = (oplist, answerList) => {
    // let qa = questionList[parentIndex]?.question_answer;
    let list = [...questionList];
    // qa.push(oplist)
    let newoplist = oplist.map((item) => item);
    let arr = [];
    newoplist.forEach((item, index) => {
      let obj = {};
      obj[`option${index + 1}`] = item;
      arr.push(obj);
    });

    if(comptype == 'comp'){
      let subQuestions = list[parentIndex].subquestions[childIndex]
      subQuestions.question_answer[0]['options'] = arr;
      // let answerlist = oplist.map((item) => item.optionValue);
      if (answerList) {
        subQuestions.question_answer[0]['answer'] = answerList
      }
      // let subQuestions = [...list[parentIndex].subquestions]
      // subQuestions.push(questionTypeProps?.QuestionObj)
      list[parentIndex].subquestions[childIndex] = subQuestions
    } else{
      list[parentIndex].question_answer[0]['options'] = arr;
      // let answerlist = oplist.map((item) => item.optionValue);
      if (answerList) {
        list[parentIndex].question_answer[0]['answer'] = answerList;
      }
    }
    setQuestions(list);
  };

  const handleDeleteFile = (image, rowIndex, imageIndex) => {
    setLoading(true)
    const list = [...optionsList];
    axiosInstance
      .post(`${endpoints.questionBank.removeFile}`, {
        file_name: list[rowIndex]['images'][imageIndex],
      })
      .then((result) => {
        if (result?.data?.status_code === 204) {
          list[rowIndex]['images'].splice(imageIndex, 1);
          setOptionsList(list);
          setAlert('success', result?.data?.message);
          setLoading(false);
        } else {
          setAlert('error', result?.data?.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setAlert('error', error?.message);
        setLoading(false);
      });

  };

  const handleOptionData = (e, index) => {
    let name = e.target?.name;
    let value;
    value = e.target?.checked;
    const list = [...optionsList];
    if (name === 'isChecked') {
      list[index][name] = value;
      let answerList = [...answers];
      if(isSingleChoice){
     for (let i = 0; i < list?.length; i++) {
          if (i != index) {
            list[i][name] = false;
          }
        }
        // let answerList = [];
        let answers = []
        if (value) {
          answers.push(`option${index + 1}`);
        } else {
          answers.splice(answerList.indexOf(`option${index + 1}`), 1);
        }
        answerList = answers
        // setAnswers(answerList);
      }else{
        // let answerList = [...answers];
        if (value) {
          // if(answerList?.length < 2){
            answerList.push(`option${index + 1}`);
          // } else {
          //   setAlert('error', 'Only 2 answers can be correct.')
          //   list[index][name] = false;
          // }
        } else {
          answerList.splice(answerList.indexOf(`option${index + 1}`), 1);
        }
      }
      setAnswers([...new Set(answerList)]);
      handleOptionUpdate(list,[...new Set(answerList)])
  
    } else if (name === 'optionValue') {
      value = e.target?.value;
      list[index][name] = value;
    } else if (name === 'images') {
      if (list[index][name]?.length < 2) {
        const file = e.target?.files;
        handleFileUpload([...optionsList], file, name, index);
      } else {
        setAlert('error', "Can't upload more than 2 images for one option");
      }
    }
    handleOptionUpdate(list);
    setOptionsList(list);
  };
  const handleSwitch = (checked) => {
    if(comptype !== 'comp'){
      if (checked) {
        setIsSingleChoice(true);
        let list = [...questionList];
        list[parentIndex].question_type = { key: '1', value: 1, children: 'MCQ' };
        setQuestions(list);
      } else {
        setIsSingleChoice(false);
        let list = [...questionList];
        list[parentIndex].question_type = { key: '2', value: 2, children: 'MCQ' };
        setQuestions(list);
      }
    }else{
      if (checked) {
        setIsSingleChoice(true);
        let list = [...questionList];
        list[parentIndex].subquestions[childIndex].question_type = { key: '1', value: 1, children: 'MCQ' };
        setQuestions(list);
      } else {
        setIsSingleChoice(false);
        let list = [...questionList];
        list[parentIndex].subquestions[childIndex].question_type = { key: '2', value: 2, children: 'MCQ' };
        setQuestions(list);
      }
    }
  };

  return (
    <>
      <div className='row'>
      {loading ? <Loader message='Loading...' /> : null}
        <Card bordered={true} className='options-card' style={{ width: '100%' }}>
          <div className='row'>
            <div className='col-md-8 col-12'></div>
            {/* <div className='col-md-4 col-12 text-right'>
              <label htmlFor='marks' className='mb-0 small'>
                Marks for this Question
              </label>
              <Select
                allowClear
                placeholder='Marks'
                showSearch
                optionFilterProp='children'
                options={marksoption}
                getPopupContainer={(trigger) => trigger.parentNode}
                className='th-black-1 th-bg-grey th-br-4'
                style={{ alignItems: 'right' }}
              ></Select>
            </div> */}
          </div>
          <hr className='mb-2' />
          <div className='row'>
            <div className='col-md-4 col-12'>
              <label
                htmlFor='answer'
                className='text-success'
                style={{ fontWeight: 500 }}
              >
                Select Answer
              </label>
            </div>
            <div className='col-md-8 col-12 text-right'>
              <p>
                <label htmlFor='multiple' className='mx-2'>
                  Multiple Choice
                </label>
                <Switch
                  size='small'
                  checked={isSingleChoice}
                  onChange={(e) => handleSwitch(e)}
                />
                <label htmlFor='single' className='mx-2'>
                  Single Choice
                </label>
              </p>
            </div>
          </div>
          {Array.isArray(optionsList) &&
            optionsList.length > 0 &&
            optionsList.map((item, index) => (
              <div key={index}>
                <div
                  className='row'
                  style={{ alignItems: 'center', alignContent: 'center' }}
                >
                  <div className='col-1' id={`check-${index}`} >
                    <Checkbox
                      name='isChecked'
                      checked={item?.isChecked}
                      onChange={(e) => handleOptionData(e, index)}
                    />
                  </div>
                  <div className='col-10'>
                    <div style={{ display: 'inline-flex', width: '100%' }}>
                      {`${String.fromCharCode(index + 65).toUpperCase()}. `}
                      <Input
                        placeholder='Type the option here'
                        className='ml-2'
                        onChange={(e) => handleOptionData(e, index)}
                        name='optionValue'
                        value={item.optionValue}
                      />
                    </div>
                  </div>
                  <div className='col-1'>
                    {optionsList.length > 3 && (
                      <DeleteOutlined
                        style={{ fontSize: 18, cursor: 'pointer' }}
                        onClick={() => handleDeleteOption(index)}
                      />
                    )}
                  </div>
                </div>
                <div className='row mt-1'>
                  <div className='col-1'></div>
                  <div className='col-7'>
                    <p className='ml-3' style={{ fontSize: 14, fontWeight: 500 }}>
                      {Array.isArray(item.images) &&
                        item.images.length > 0 &&
                        item.images?.map((imgitem, i) => (
                          <span key={i}>
                            {/* <FileImageOutlined /> {imgitem?.name} */}
                            <img
                              src={`${endpoints.s3}${imgitem}`}
                              alt='Not found'
                              className='optionImageAttachment'
                              style={{
                                marginLeft: '15px',
                                marginTop: '10px',
                                marginRight: '0',
                              }}
                            />
                            <CloseCircleOutlined
                              className='ml-2'
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleDeleteFile(imgitem, index, i)}
                            />
                          </span>
                        ))}
                    </p>
                  </div>
                  <div className='col-3 pl-0 text-right'>
                    <label
                      style={{
                        fontWeight: 500,
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                      // htmlFor='file-upload'
                    >
                      <input
                        type='file'
                        onChange={(e) => {
                          handleOptionData(e, index);
                        }}
                        value=''
                        name='images'
                        id='file-upload'
                        style={{ display: 'none' }}
                      />
                      <FileImageOutlined /> Attach Image
                    </label>
                  </div>
                </div>
              </div>
            ))}
          <div className='row my-2 justify-content-center text-center'>
            <div className='col-12'>
              {optionsList.length < 6 && (
                <Button
                  className='btn add-another'
                  style={{ boxShadow: 'none' }}
                  onClick={handleAddOption}
                >
                  <PlusOutlined /> Add another option
                </Button>
              )}
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Mcq;
