/* eslint-disable react/jsx-no-duplicate-props */
import React, { useEffect, useState } from 'react';
import AssessmentOld from './assessmentOld';
import { message, Spin } from 'antd';
import { useSelector } from 'react-redux';
import endpoints from '../../../config/endpoints';
import AssessmentNew from '../assessment-new/assessment-view/assessmentNew';
import axiosInstance from '../../../config/axios';

const AssessmentView = () => {
  const [loading, setLoading] = useState(false);
  const [showNewAsses, setShowNewAsses] = useState(true);
  const fetchquesPaperStatus = () => {
    setLoading(true);
    axiosInstance
      .get(`/assessment/check-sys-config/?config_key=central_asmt_enhancement`)
      .then((response) => {
        if (response?.data?.result) {
          if (response?.data?.result[0] == 'True' || response?.data?.result == 'True') {
            setShowNewAsses(true);
          } else {
            setShowNewAsses(false);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error('error', error?.message);
      });
  };
  useEffect(() => {
    fetchquesPaperStatus();
  }, []);
  return (
    <>
      {!loading ? (
        showNewAsses ? 
            <AssessmentNew />
         : <AssessmentOld/>
      ) : (
        <div className='th-width-100 text-center mt-5 h-100'>
          <Spin tip='Loading...'></Spin>
        </div>
      )}
    </>
  );
};

export default AssessmentView;
