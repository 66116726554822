/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-debugger */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { withRouter } from 'react-router-dom';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import './breadcrumbs.css';

const DisableDashboardBreadcrumbs = withRouter(({ history, ...props }) => {
  const { componentName } = props || {};
  return (
    <div className='page_title'>
      {componentName?.length !== 0
        ? componentName.map((item, index) => (
            <>
            <ArrowForwardIosIcon
                className='page_heading_arrow'
                style={{
                  display: index === 0 ? 'none' : '',
                }}
              />
            {item}
          </>
          ))
        : ''}
      {/* {componentName}
      {childComponentName ? (
        <>
          <ArrowForwardIosIcon className='page_heading_arrow' />
          {childComponentName}
        </>
      ) : null}
      {childComponentNameNext ? (
        <>
          <ArrowForwardIosIcon className='page_heading_arrow_mob' />
          {childComponentNameNext}
        </>
      ) : null} */}
    </div>
  );
});

export default DisableDashboardBreadcrumbs;
