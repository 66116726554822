import React , { useContext, useState,useEffect } from 'react';
import { Grid, TextField, Button, useTheme, Switch, FormControlLabel } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Autocomplete from '@material-ui/lab/Autocomplete';
import endpoints from '../../../config/endpoints';
import axiosInstance from '../../../config/axios';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';

const CreateGradeSubjectMapping = ({grades,setLoading,handleGoBack}) => {

  const { setAlert } = useContext(AlertNotificationContext);
  const [subjectName,setSubjectName]=useState('')
  const [description,setDescription]=useState('')
  const [selectedGrade,setSelectedGrade]=useState([])
  const [selectedSection,setSelectedSection]=useState([])
  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const [sections,setSections]=useState([])
  const [optional,setOptional] = useState(false)

  const [gradeDropdown,setGradeDropdown]=useState([])
  const [subjectDropdown,setSubjectDropdown]=useState([])


  const {role_details}=JSON.parse(localStorage.getItem('userDetails'))

  const [filterData, setFilterData] = useState({
    gradeId:'',
    subjectId:''
});


  useEffect(() => {
    axiosInstance.get(`${endpoints.masterManagement.grades}`)
        .then(result => {
            if (result.data.status_code === 200) {
                setGradeDropdown(result.data.result.results);
            } else {
                setAlert('error', result.data.message);
            }
        }).catch(error => {
            setAlert('error', error.message);
        })

    axiosInstance.get(`${endpoints.masterManagement.subjects}`)
        .then(result => {
            if (result.data.status_code === 200) {
                setSubjectDropdown(result.data.result.results);
            } else {
                setAlert('error', result.data.message);
            }
        }).catch(error => {
            setAlert('error', error.message);
        })
}, [])


  const handleGrade = (event, value) => {
    // console.log(value.id,'hi')
    setFilterData({ ...filterData, gradeId: '' });
    if (value) {
        setFilterData({ ...filterData, gradeId: value});
        
    }
  };
  const handleSubject = (event, value) => {
    // console.log(value.id,'hi')
    setFilterData({ ...filterData, subjectId: '' });
    if (value) {
        setFilterData({ ...filterData, subjectId: value});
        
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true);
    console.log(filterData.gradeId.id,'hi',filterData.subjectId.id)
      axiosInstance.post(endpoints.masterManagement.createGradeSubjectMapping,{
        "subject_id":filterData.subjectId.id,
        "grade_id":filterData.gradeId.id
      }).then(result=>{
      if (result.data.status_code === 201) {
        setFilterData({
          gradeId: '',
          subjectId: '',
      });
        setLoading(false)
        setAlert('success', result.data.message)
      } else {
        setLoading(false);
        setAlert('error',result.data.message)
      }
      }).catch((error)=>{
        setLoading(false);
        setAlert('error', error.message)
      })
    };

  return (
      <form autoComplete='off' onSubmit={handleSubmit}>
      <div style={{ width: '95%', margin: '20px auto'}}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4} className={isMobile?'':'addEditPadding'}>
            <Autocomplete
              size='small'
              onChange={handleGrade}
              style={{ width: '100%' }}
              id='grade'
              options={gradeDropdown}
              value={filterData.gradeId}
              getOptionLabel={(option) => option?.grade_name}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Grades'
                  placeholder='Grades'
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} className={isMobile?'':'addEditPadding'}>
            <Autocomplete
              size='small'
              onChange={handleSubject}
              style={{ width: '100%' }}
              id='grade'
              options={subjectDropdown}
              value={filterData.subjectId}
              getOptionLabel={(option) => option?.subject_name}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Subjects'
                  placeholder='Subjects'
                  required
                />
              )}
            />
          </Grid>
        </Grid>
      </div>
        <Grid container spacing={isMobile?1:5} style={{ width: '95%', margin: '10px'}} >
        <Grid item xs={6} sm={2} className={isMobile?'':'addEditButtonsPadding'}>
            <Button variant='contained' className="custom_button_master labelColor" size='medium' onClick={handleGoBack}>
              Back
            </Button>
          </Grid>
          <Grid item xs={6} sm={2} className={isMobile?'':'addEditButtonsPadding'}> 
            <Button variant='contained' style={{color:'white'}} color ="primary" className="custom_button_master" size='medium' type='submit'>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
  );
};

export default CreateGradeSubjectMapping;
