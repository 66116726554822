import { Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import SubQuestionTaggingCard from './SubQuestionTaggingCard';
import axiosInstance from '../../../../config/axios';

const SubQuestionTagging = ({
  showSubQuestionTaggingModal,
  handleCloseSubQuestionTagging,
  gradeList,
  volumeList,
  boardList,
  questionTypeList,
  currentPage,
  mainQuestion,
}) => {
  const [unTaggedSubQuestionsList, setUnTaggedSubQuestionsList] = useState([]);

  const fetchUnTaggedSubQuestionsList = (id) => {
    let params = {
      question_id: id,
    };
    axiosInstance
      .get(`v2/assessment/v3/question/`, {
        params: { ...params },
      })
      .then((res) => {
        if (res?.data?.status_code == 200) {
          let unTaggedSubQuestions = res?.data?.result?.sub_questions.filter(
            (el) => el?.is_tagged == false
          );
          if (unTaggedSubQuestions.length > 0) {
            setUnTaggedSubQuestionsList(unTaggedSubQuestions);
            message.info('Please tag these sub-questions');
          } else {
            handleCloseSubQuestionTagging();
          }
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  useEffect(() => {
    fetchUnTaggedSubQuestionsList(mainQuestion?.id);
  }, []);
  return (
    <div>
      <Modal
        open={showSubQuestionTaggingModal}
        destroyOnClose={true}
        centered
        zIndex={1500}
        footer={null}
        onCancel={handleCloseSubQuestionTagging}
        title={'Sub Questions Tagging'}
        className='th-upload-modal'
        width={'75vw'}
      >
        <div style={{ maxHeight: '72vh', overflowY: 'scroll' }}>
          {unTaggedSubQuestionsList?.map((item, index) => {
            return (
              <SubQuestionTaggingCard
                currentQuestion={item}
                index={index}
                gradeList={gradeList}
                volumeList={volumeList}
                boardList={boardList}
                currentPage={currentPage}
                questionTypeList={questionTypeList}
                isQuestionTagged={item?.is_tagged}
                fetchUnTaggedSubQuestionsList={fetchUnTaggedSubQuestionsList}
                mainQuestion={mainQuestion}
              />
            );
          })}
        </div>
      </Modal>
    </div>
  );
};

export default SubQuestionTagging;
