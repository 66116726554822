const levels = [
    { id: '1', value: 'Easy' },
    { id: '2', value: 'Average' },
    { id: '3', value: 'Difficult' },
  ];
  
  const categories = [
    { id: '1', value: 'Knowledge' },
    { id: '2', value: 'Understanding' },
    { id: '3', value: 'Application' },
    { id: '4', value: 'Analysis' },
    { id: '5', value: 'Remembering' },
    { id: '6', value: 'Evaluation' },
    { id: '7', value: 'Creating' },
  ];
  
  const questiontype = [
    { key: '2', value: 2, children:'MCQ' },
    // {
    //   id: 2,
    //   value: 'Multiple Choice Multiple Select',
    //   MultipleChoiceMultipleSelect: true,
    // },
    // { id: 3, value: 'Match The Following', MatchTheFollowing: true },
    // { id: 6, value: 'Matrix Question', MatrixQuestion: true },
    { key: '7', value: 7, children: 'Comprehensive' },
    { key: '8', value: 8, children :  'True False'},
    { key: '9', value: 9, children: 'Fill In The Blanks' },
    { key: '10', value: 10, children: 'Descriptive' },
  ];

  const marksoption = [
    {id: 1, value: "1"},
    {id: 2, value: "2"},
    {id: 3, value: "3"},
    {id: 5, value: "5"},
  ]

  module.exports = {levels, categories, questiontype, marksoption}