import React,{useContext} from 'react';
import Layout from '../Layout';
import axiosInstance from '../../config/axios';
import endpoints from '../../config/endpoints';
import {
    Grid,
    makeStyles,
    withStyles,
    Switch,
    TextField,
    Typography,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Dialog,
    DialogTitle ,
    DialogContent,
    Button,
    DialogActions
  } from '@material-ui/core';
import BluetoothIcon from '@material-ui/icons/Bluetooth';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { School } from '@material-ui/icons';
import { AlertNotificationContext } from '../../context-api/alert-context/alert-state';
import ModuleDataList from './moduleDataList';

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      maxWidth: 760,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    gridItem: {
        padding: '35px 50px',
        height: '100px',
    },
    moduleListGrid: {
        height: 'calc(100% - 100px)',
        backgroundColor: '#FFFFFF',
        padding: '25px 50px',
    },
    columnHeader: {
        color: `${theme.palette.secondary.main} !important`,
        fontWeight: 600,
        fontSize: '1rem',
    },
    tableCell: {
        color: theme.palette.secondary.main,
    },
}));

const StyledButton = withStyles({
    root: {
        height: '40px',
        color: '#FFFFFF',
        backgroundColor: '#FF6B6B',
        //borderRadius: '10px',
        '&:hover': {
            backgroundColor: '#FF6B6B',
        },
    }
})(Button);

const StyledCancelButton = withStyles({
    root: {
        backgroundColor: '#E2E2E2',
        color: '#FF6B6B',
        height: '40px',
        marginRight: 'auto',
    },
})(Button);

const CentralManagement = () => {
    const classes = useStyles();
    const [open,setOpen] = React.useState(false);
    const [update, setUpdate] = React.useState(0);
    const [checked, setChecked] = React.useState(false);
    const [schoolList, setSchoolList] = React.useState([]);
    const [selectedSchool, setSelectedSchool] = React.useState('');
    const [moduleList, setModuleList] = React.useState([]);
    const [moduleData, setModuleData] = React.useState({
        status: '',
        module_name: '',
        mapping_id: '',
        module_id: '',
        school_domain: '',
    });
    const schoolDomain = selectedSchool && selectedSchool.school_sub_domain_name ? selectedSchool.school_sub_domain_name : '';
    const {setAlert} = useContext(AlertNotificationContext);
/*
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };
*/
    const handleOpenDialog = (data) => { 
        setModuleData({
            status: data.is_delete ? data.is_delete : false,
            module_name: data.module_name,
            mapping_id: data.id,
            module_id: data.module_id,
            school_domain: selectedSchool.school_sub_domain_name? selectedSchool.school_sub_domain_name : '',
        });
        setOpen(true);
    }
    const handleCloseDialog = () => {
        setOpen(false);
    }

    const handleChange = (data) => {
        handleOpenDialog(data);
        //setModuleList({[event.target.name]: event.target.checked });
        setChecked(true);
    };

    const handleComform = () => {
        //alert('Conform');
        const params = {
            mapping_id: moduleData.mapping_id,
            module_id: moduleData.module_id.toString(),
            school_domain: moduleData.school_domain,
        };

        if(moduleData.status){
            //console.log(params);
            axiosInstance.put(endpoints.centralManagement.activateModule,params)
            .then((res) => {
                console.log(res.data);
                setAlert('success', res.data.message);
                setUpdate(update + 1);
            })
            .catch((error) => console.log(error))
            handleCloseDialog();
        }
        if(!moduleData.status){
            axiosInstance.put(endpoints.centralManagement.removeModule,params)
            .then((res) => {
                console.log(res.data);
                setAlert('success', res.data.message);
                setUpdate(update + 1);
            })
            .catch((error) => console.log(error))
            handleCloseDialog();
        }
    }

    React.useEffect(() => {
        if(selectedSchool !== undefined && selectedSchool !== null && schoolList.length > 0){
            //console.log(schoolList, +"======"+selectedSchool);
            setModuleList([]);
            axiosInstance.get(`${endpoints.centralManagement.schoolModule}?school_id=${selectedSchool?.id}`)
            .then((res) => {
                console.log(res.data);
                setModuleList(res.data.result);
            })
            .catch((error) => console.log(error))
        }
        else {
            console.log("emty");
            setModuleList([]);
        }
    }, [selectedSchool, update]);

    React.useEffect(() => {
        axiosInstance.get(endpoints.centralManagement.schoolList)
        .then((res) => {
            console.log(res.data);
            setSchoolList(res.data.result);
        })
        .catch((error) => console.log(error))
    },[]);

    return (
        <Layout>
            <Grid container>
                <Grid item xs={12} className={classes.gridItem}>
                    <Autocomplete
                        style={{ width: '35%' }}
                        size='small'
                        onChange={(event, value) => {
                            setSelectedSchool(value);
                        }}
                        id='school_id'
                        className='dropdownIcon'
                        value={selectedSchool}
                        options={schoolList}
                        getOptionLabel={(option) => option?.school_name}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant='outlined'
                                label='School'
                                placeholder='Select School'
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} className={classes.moduleListGrid}>
                    <TableContainer>
                        <Table stickyHeader aria-label='sticky table'>
                            <TableHead className='view_groups_header'>
                                <TableRow>
                                    <TableCell
                                        key='school_name'
                                        align='center'
                                        style={{ width: '100px' }}
                                        className={classes.columnHeader}
                                    >
                                        Sr. No.
                                    </TableCell>
                                    <TableCell
                                        key='school_name'
                                        align='center'
                                        style={{ minWidth: '150' }}
                                        className={classes.columnHeader}
                                    >
                                        Module Name
                                    </TableCell>
                                    <TableCell
                                        key='school_name'
                                        align='left'
                                        style={{ minWidth: '100' }}
                                        className={classes.columnHeader}
                                    >
                                        Status
                                    </TableCell>
                                    <TableCell
                                        key='school_name'
                                        align='center'
                                        style={{ minWidth: '100' }}
                                        className={classes.columnHeader}
                                    >
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* moduleList && moduleList.length > 0 && moduleList.map((data, id) => (
                                    <TableRow keys={data.id}>
                                        <TableCell style={{ width: '70px' }}>
                                            {id + 1}
                                        </TableCell>
                                        <TableCell>
                                            {data.module_name}
                                        </TableCell>
                                        <TableCell >    
                                            {data.is_delete !== true
                                                ? <div style={{ color: 'green',fontWeight:'500'}}>Activated</div>
                                                : <div style={{ color: 'red',fontWeight:'500' }}>Deactivate</div>
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                <Switch
                                                    edge="end"
                                                    onChange={handleToggle(data.id)}
                                                    checked={checked.indexOf(data.id) !== -1}
                                                    inputProps={{ 'aria-labelledby': 'switch-list-label-bluetooth' }}
                                                />
                                            }
                                            <Switch
                                                checked={data.is_delete? false : true}
                                                onChange={(e) => handleChange(data)}
                                                name={data.module_name}
                                                color="primary"
                                            />
                                        </TableCell>
                                    </TableRow>
                                )) */}
                                {moduleList && moduleList.length > 0 && moduleList.map((moduleData, idx) => (
                                    <ModuleDataList
                                        data={moduleData}
                                        id={idx}
                                        schoolDomain={schoolDomain}/>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Dialog
                        open={open}
                        onClose={handleCloseDialog}
                    >
                        <DialogContent>
                            <Typography>{`Are you sure to ${moduleData.status !== false? 'Active' : 'Diactive'} ${moduleData.module_name} ?`}</Typography>
                        </DialogContent>
                        <DialogActions>
                            <StyledCancelButton
                                variant='contained'
                                onClick={handleCloseDialog}
                            >
                                Cancel
                            </StyledCancelButton>
                            <StyledButton
                                variant='contained'
                                onClick={handleComform}
                            >
                                Conform
                            </StyledButton >
                        </DialogActions>
                    </Dialog>
                </Grid>
            </Grid>
        </Layout>
    )
}

export default CentralManagement;