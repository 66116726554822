import React, { useContext, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { Grid, useTheme, SvgIcon, IconButton } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Layout from '../../Layout';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import CommonBreadcrumbs from '../../../components/common-breadcrumbs/breadcrumbs';
import endpoints from '../../../config/endpoints';
import axiosInstance from '../../../config/axios';
// import './lesson.css';
import Loading from '../../../components/loader/loader';
import AssessmentCard from './assesment-card-new';
import AssessmentFilters from './filterdata';
import ViewMoreCard from './view-more-card';
import TabPanel from './tab-panel';
import unfiltered from '../../../assets/images/unfiltered.svg';
import selectfilter from '../../../assets/images/selectfilter.svg';
import hidefilter from '../../../assets/images/hidefilter.svg';
import showfilter from '../../../assets/images/showfilter.svg';

import CreateEditSchool from './../assesment-view/assesment-card-new/QuestionPaperpreview';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '-10px auto',
    boxShadow: 'none',
  },
  container: {
    maxHeight: '70vh',
    width: '100%',
  },
}));

function AssessmentOld() {
    const classes = useStyles();
    const { setAlert } = useContext(AlertNotificationContext);
    const [page, setPage] = useState(1);
    const [periodData, setPeriodData] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [viewMore, setViewMore] = useState(false);
    const [viewMoreData, setViewMoreData] = useState([]);
    const [periodDataForView, setPeriodDataForView] = useState({});
    const [filterDataDown, setFilterDataDown] = useState({});
    const limit = 9;
    const [chapterSearch, setChapterSearch] = useState();
    const themeContext = useTheme();
    const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
    const [isFilter, setIsFilter] = useState(false);
    const [periodColor, setPeriodColor] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [tableFlag, setTableFlag] = useState(true);
    const [tabValue, setTabValue] = useState(0);
    const [tabGradeId, setTabGradeId] = useState('');
    const [tabSubjectId, setTabSubjectId] = useState('');
    const [tabQpValue, setTabQpValue] = useState('');
    const [publishFlag, setPublishFlag] = useState(false);
    const [periodId, setperiodId] = useState('');
    const [gradeSubjectMap, setGradeSubjectMap] = useState('');
    const handlePagination = (event, page) => {
      setPage(page);
    };
  
    const handleSubjectData = (grade, subject) => {
      axiosInstance
        .get(
          `${endpoints.assementQP.assementSubjectMap}?grade=${grade?.id}&subject_id=${subject?.subject?.id}`
        )
        .then((result) => {
          if (result.data.status_code === 200) {
            setGradeSubjectMap(result?.data?.result?.results[0]?.id);
          } else {
            setAlert('error', result.data.description);
          }
        })
        .catch((error) => {
          setAlert('error', error.message);
        });
    };
  
    const handlePeriodList = (grade, subject, qpValue, newValue) => {
      if (!grade) {
        setAlert('error', 'Select all the fields!');
        return;
      }
  
      setTabValue(0);
      setLoading(true);
      setPeriodData([]);
      setTabGradeId(grade);
      setTabSubjectId(subject);
      setTabQpValue(qpValue);
  
  
      if (newValue == 0 || newValue == undefined) {
        const tabVal = '';
        setTabValue(0);
        // if (tabValue == 1) {
        //   tabVal = '&is_draft=True';
        // } else if (tabValue == 2) {
        //   tabVal = '&is_review=True';
        // } else if (tabValue == 3) {
        //   tabVal = '&is_verified=True';
        // }
        let url = `${endpoints.assementQP.assementFilterData}?page=${page}&page_size=${limit}&gs_mapping=${gradeSubjectMap}&is_delete=False`
        if(qpValue){
        url +=   `&paper_level=${qpValue.id}${tabVal}`
        }
        axiosInstance
          .get(url)
          // axiosInstance.get(`${endpoints.assementQP.assementFilter}?grade=${2}&paper_level=${1}`)
          .then((result) => {
            if (result.data.status_code === 200) {
              setTotalCount(result?.data?.result?.count);
              setLoading(false);
              setPeriodData(result?.data?.result?.results);
              setViewMore(false);
              setViewMoreData([]);
            } else {
              setLoading(false);
              setAlert('error', result?.data?.description);
            }
          })
          .catch((error) => {
            setLoading(false);
            setAlert('error', error?.message);
          });
      } else if (newValue == 1) {
        let url = `${endpoints.assementQP.assementFilter}?gs_mapping=${gradeSubjectMap}&is_draft=True&page=${page}&page_size=${limit}&is_delete=False` //grade=${grade.id}&
  
        if(qpValue){
        url +=   `&paper_level=${qpValue.id}`
        }
        setTabValue(1);
        axiosInstance
          .get(url)
          // axiosInstance.get(`${endpoints.assementQP.assementFilter}?grade=${2}&paper_level=${1}&is_draft=True`)
          .then((result) => {
            if (result.data.status_code === 200) {
              setTotalCount(result.data.result.count);
              setLoading(false);
              setPeriodData(result.data.result.results);
              setViewMore(false);
              setViewMoreData([]);
            } else {
              setLoading(false);
              setAlert('error', result.data.description);
            }
          })
          .catch((error) => {
            setLoading(false);
            setAlert('error', error.message);
          });
      } else if (newValue == 2) {
        setTabValue(2);
        let url = `${endpoints.assementQP.assementFilter}?gs_mapping=${gradeSubjectMap}&is_review=True&page=${page}&page_size=${limit}&is_delete=False` //grade=${grade.id}&
        if(qpValue){
          url +=   `&paper_level=${qpValue.id}`
          }
        axiosInstance
          .get(url)
          // axiosInstance.get(`${endpoints.assementQP.assementFilter}?grade=${2}&paper_level=${1}&is_review=True`)
          .then((result) => {
            if (result.data.status_code === 200) {
              setTotalCount(result.data.result.count);
              setLoading(false);
              setPeriodData(result.data.result.results);
              setViewMore(false);
              setViewMoreData([]);
            } else {
              setLoading(false);
              setAlert('error', result.data.description);
            }
          })
          .catch((error) => {
            setLoading(false);
            setAlert('error', error.message);
          });
      } else if (newValue == 3) {
        setTabValue(3);
        let url =  `${endpoints.assementQP.assementFilter}?gs_mapping=${gradeSubjectMap}&is_verified=True&page=${page}&page_size=${limit}&is_delete=False` //grade=${grade.id}&
        if(qpValue){
          url +=   `&paper_level=${qpValue.id}`
          }
        axiosInstance
          .get(url)
          // axiosInstance.get(`${endpoints.assementQP.assementFilter}?grade=${2}&paper_level=${1}&is_verified=True`)
          .then((result) => {
            if (result.data.status_code === 200) {
              setTotalCount(result.data.result.count);
              setLoading(false);
              setPeriodData(result.data.result.results);
              setViewMore(false);
              setViewMoreData([]);
            } else {
              setLoading(false);
              setAlert('error', result.data.description);
            }
          })
          .catch((error) => {
            setLoading(false);
            setAlert('error', error.message);
          });
      } else if (newValue == 4) {
        setTabValue(4);
        let url = `${endpoints.assementQP.assementFilter}?gs_mapping=${gradeSubjectMap}&page=${page}&page_size=${limit}&is_delete=True` //grade=${grade.id}&
        if(qpValue){
          url +=   `&paper_level=${qpValue.id}`
          }
        axiosInstance
          .get(url)
          // axiosInstance.get(`${endpoints.assementQP.assementFilter}?grade=${2}&paper_level=${1}&is_verified=True`)
          .then((result) => {
            if (result.data.status_code === 200) {
              setTotalCount(result.data.result.count);
              setLoading(false);
              setPeriodData(result.data.result.results);
              setViewMore(false);
              setViewMoreData([]);
            } else {
              setLoading(false);
              setAlert('error', result.data.description);
            }
          })
          .catch((error) => {
            setLoading(false);
            setAlert('error', error.message);
          });
      }
    };
    const handleGoBack = () => {
      setTableFlag(true)
      console.log('clicked')
  
    };
  
    useEffect(() => {
      // if (page && chapterSearch || publishFlag)
      if (publishFlag) handlePeriodList(tabGradeId, tabSubjectId, tabQpValue);
      if (tabGradeId && tabSubjectId)
        handlePeriodList(tabGradeId, tabSubjectId, tabQpValue, tabValue);
    }, [publishFlag, page]);
  
    return (
      <>
  
        {loading ? <Loading message='Loading...' /> : null}
  
  
  
        <Layout>
  
  
  
  
          <div
            className={isMobile ? 'breadCrumbFilterRow' : null}
            style={{ display: 'flex', marginLeft: '2.25rem' }}
          >
            <div style={{ width: '95%', margin: '20px auto' }}>
              <CommonBreadcrumbs
                componentName='Assessment'
                childComponentName='Question Paper'
              />
            </div>
  
  
            <div className='hideShowFilterIcon'>
              <IconButton onClick={() => setIsFilter(!isFilter)}>
                <SvgIcon
                  component={() => (
                    <img
                      style={{ height: '20px', width: '25px' }}
                      src={isFilter ? hidefilter : showfilter}
                    />
                  )}
                />
              </IconButton>
            </div>
          </div>
          {!tableFlag &&
            <CreateEditSchool periodId={periodId} handleGoBack={handleGoBack} />}
          {tableFlag &&
            <div>
              <div className={!isFilter ? 'showFilters' : 'hideFilters'}>
                <AssessmentFilters
                  handlePeriodList={handlePeriodList}
                  setPeriodData={setPeriodData}
                  setViewMore={setViewMore}
                  setViewMoreData={setViewMoreData}
                  setFilterDataDown={setFilterDataDown}
                  setSelectedIndex={setSelectedIndex}
                  handleData={handleSubjectData}
                  tabValue = {tabValue}
                  setTabValue = {setTabValue}
                />
              </div>
              <div>
                <TabPanel
                  handlePeriodList={handlePeriodList}
                  tabGradeId={tabGradeId}
                  tabSubjectId={tabSubjectId}
                  tabQpValue={tabQpValue}
                  setTabValue={setTabValue}
                  page={page}
                  setPage={setPage}
                  setSelectedIndex={setSelectedIndex}
                />
              </div>
  
              <Paper className={classes.root}>
                {periodData?.length > 0 ? (
                  <Grid
                    container
                    style={
                      isMobile
                        ? { width: '95%', margin: '20px auto' }
                        : { width: '100%', margin: '20px auto' }
                    }
                    spacing={5}
                  >
                    <Grid item xs={12} sm={viewMore ? 7 : 12}>
                      <Grid container spacing={isMobile ? 3 : 5}>
                        {periodData.map((period, i) => (
  
                          <Grid
                            item
                            xs={12}
                            style={isMobile ? { marginLeft: '-8px' } : null}
                            sm={viewMore ? 12 : 8}
                          >
                            <AssessmentCard
                              index={i}
                              filterDataDown={filterDataDown}
                              period={period}
                              setSelectedIndex={setSelectedIndex}
                              periodColor={selectedIndex === i}
                              setPeriodColor={setPeriodColor}
                              viewMore={viewMore}
                              setLoading={setLoading}
                              setViewMore={setViewMore}
                              setViewMoreData={setViewMoreData}
                              setPeriodDataForView={setPeriodDataForView}
                              setPublishFlag={setPublishFlag}
                              setTableFlag={setTableFlag}
                              setperiodId={setperiodId}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                    {viewMore && (
                      <Grid item xs={12} sm={5} style={{ width: '100%' }}>
                        <ViewMoreCard
                          setSelectedIndex={setSelectedIndex}
                          viewMoreData={viewMoreData}
                          setViewMore={setViewMore}
                          filterDataDown={filterDataDown}
                          periodDataForView={periodDataForView}
                        />
                      </Grid>
                    )}
                  </Grid>
                ) : (
                  <div className='periodDataUnavailable'>
                    <SvgIcon
                      component={() => (
                        <img
                          style={
                            isMobile
                              ? { height: '100px', width: '200px' }
                              : { height: '160px', width: '290px' }
                          }
                          src={unfiltered}
                        />
                      )}
                    />
                    <SvgIcon
                      component={() => (
                        <img
                          style={
                            isMobile
                              ? { height: '20px', width: '250px' }
                              : { height: '50px', width: '400px', marginLeft: '5%' }
                          }
                          src={selectfilter}
                        />
                      )}
                    />
                  </div>
                )}
  
                {periodData?.length > 0 && (
                  <div className='paginateData paginateMobileMargin'>
                    <Pagination
                      onChange={handlePagination}
                      style={{ marginTop: 25 }}
                      count={Math.ceil(totalCount / limit)}
                      color='primary'
                      page={page}
                    />
                  </div>
                )}
  
              </Paper>
            </div>
          }
  
        </Layout>
  
      </>
  
    );
  
}

export default AssessmentOld