import { PlusOutlined } from '@ant-design/icons';
import { Button, Drawer, message, Tabs } from 'antd'
import React, { useState, useContext } from 'react'
import CreateQuestion from './createQuestion';
import QuestionBankDrawer from './questionBankDrawer';
import '../../assesment.scss'
import { AlertNotificationContext } from '../../../../context-api/alert-context/alert-state';

const { TabPane } = Tabs;

function QuestionBank({
  drawerOpen,
  onClose,
  section,
  questionId,
  grade,
  erpCategory,
  questionPaperWise,
  activeTab
}) {
  const { setAlert } = useContext(AlertNotificationContext);
    const [showTab, setShowTab] = useState(activeTab)
    const [isquestionCreation , setIsQuestionCreation] = useState(false)
    const [isquestionnAdd , setIsQuestionAdd] = useState(false)
    const [ getCount , setCount ] = useState(0)
    const [createCount, setCreateCount] = useState(0)

    const onChange = (key) => {
        setShowTab(key);
        // if (key === '2') {
            
        // } else {
        // }
      }; 

    const checkQuestion = () => {
      if(getCount > 0){
        setIsQuestionAdd(true)
      }else{
        setAlert('error', 'Please select atleast one question to add')
      }
    }

    const checkCreateQuestion = () => {
      if(createCount > 0){
        setIsQuestionCreation(true)
      } else{
        setAlert('error', 'Please create atleast one question to add')
      }
    }

  return (
    <Drawer
    headerStyle={{padding: 12}}
      title={<div className='col-12'>
      <div className='th-tabs th-bg-white create-question'>
        <Tabs type='card' onChange={onChange} defaultActiveKey={showTab} className='tabCreateQP' >
          <TabPane tab='Create Question' key='1' >
            {/* <PeriodView /> */}
            <CreateQuestion 
             section = {section}
             grade = {grade}
             questionPaperWise = {questionPaperWise}
             questionId = {questionId}
             isCreation = {isquestionCreation}
             setIsQuestionCreation = {setIsQuestionCreation}
             onClose={onClose}
             setCreateCount={setCreateCount}
            />
          </TabPane>
          <TabPane tab='Question From Question Bank' key='2'>
            <QuestionBankDrawer
            //  drawerOpen = {drawerOpen}
             onClose = {onClose}
             section = {section}
             questionId = {questionId}
             grade = {grade}
             erpCategory = {erpCategory}
             questionPaperWise = {questionPaperWise}
             isCreation = {isquestionnAdd}
             setIsQuestionAdd={setIsQuestionAdd}
             setCount={setCount}
            />
          </TabPane>
        </Tabs>
      </div>
    </div>}
      placement='right'
      onClose={onClose}
      closable={false}
      visible={drawerOpen}
      zIndex = {1500}
      width={'680px'}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          {showTab == 2 && <Button
            form='incomeForm'
            type='primary'
            htmlType='submit'
            // onClick={() => setIsQuestionAdd(true)}
            onClick={checkQuestion}
            // disabled={selectedQuestion?.length === 0}
          >
            <PlusOutlined size='small' />
            Add to Section
          </Button>}
          {showTab == 1 && <Button
            form='incomeForm'
            type='primary'
            htmlType='submit'
            // onClick={() => setIsQuestionCreation(true)}
            onClick={checkCreateQuestion}
            // disabled={selectedQuestion?.length === 0}
          >
            <PlusOutlined size='small' />
           Create and Add to Section
          </Button>}
        </div>
      }
    >
    <div className='row'>
          </div>
          </Drawer>
  )
}

export default QuestionBank