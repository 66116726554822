import React, { useState, useEffect, useContext } from 'react';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import Layout from '../../Layout';
import Dropzone from 'react-dropzone';
import {
  Card,
  TextField,
  Grid,
  useTheme,
  Button,
  Typography,
  CardContent,
  IconButton,
} from '@material-ui/core';
import CommonBreadcrumbs from '../../../components/common-breadcrumbs/breadcrumbs';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { DropzoneArea } from 'material-ui-dropzone';
import './createibook.scss';
import endpoints from '../../../config/endpoints';
import Loading from '../../../components/loader/loader';
import axiosInstance from '../../../config/axios';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ClearIcon from '@material-ui/icons/Clear';
import { useHistory } from 'react-router-dom';

function CreateIbook() {
  const { setAlert } = useContext(AlertNotificationContext);
  const [title, setTitle] = useState('');
  const [localStorage, setLocalStorage] = useState('');
  const [description, setDescription] = useState('');
  const [author, setAuthor] = useState('');
  const [files, setFiles] = useState([]);
  const [dropfiles, setDropFiles] = useState([]);
  const [openImage, setOpenImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedGrades, setSelectedGrades] = useState('');
  const [selectedAcad, setSelectedAcad] = useState('');
  const [acadList, setAcadList] = useState([]);
  const [selectedVolume, setSelectedVolume] = useState('');
  const [volumeList, setVolumeList] = useState([]);
  const [boardDropdown, setBoardDropdown] = useState('');
  const [gradeList, setGradeList] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [subjectList, setSubjectList] = useState([]);
  const [academicYearDropdown, setAcademicYearDropdown] = useState([]);
  const [volumeDropdown, setVolumeDropdown] = useState([]);
  const [gradeDropdown, setGradeDropdown] = useState([]);
  const [subjectDropdown, setSubjectDropdown] = useState([]);
  const [chapterDropdown, setChapterDropdown] = useState([]);
  const [selectedBoardId, setSelectedBoardId] = useState([]);
  const [selectedModuleId, setSelectedModuleId] = useState([]);
  const [moduleDropdown, setModuleDropdown] = useState([]);
  const [selectedKeyConceptId, setSelectedKeyConceptId] = useState([]);
  const [keyConceptDropdown, setKeyConceptDropdown] = useState([]);
  const themeContext = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const wider = isMobile ? '-10px 0px' : '-10px 0px 20px 8px';
  const widerWidth = isMobile ? '98%' : '95%';

  const [filterData, setFilterData] = useState({
    year: '',
    volume: '',
    grade: '',
    subject: '',
    chapter: '',
    board: '',
    module: '',
    keyconcept: '',
  });
  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`${endpoints.lessonPlan.academicYearList}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setLoading(false);
          setAcademicYearDropdown(result.data.result.results);
        } else {
          setLoading(false);
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.message);
      });

    axiosInstance
      .get(`${endpoints.lessonPlan.volumeList}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setLoading(false);
          setVolumeDropdown(result.data.result.results);
        } else {
          setLoading(false);
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.message);
      });

    axiosInstance
      .get(`${endpoints.lessonPlan.gradeList}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setLoading(false);
          setGradeDropdown(result.data.result.results);
        } else {
          setLoading(false);
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.message);
      });
  }, []);
  const handlGetApi = (api, key) => {
    setLoading(true);
    axiosInstance
      .get(api)
      .then((result) => {
        setLoading(false);
        if (result.data.status_code === 200) {
          if (key === 'acad') {
            setAcadList(result.data.result.results);
          } else if (key === 'volume') {
            setVolumeList(result.data.result.results);
          } else if (key === 'grade') {
            setGradeList(result.data.result.results);
          } else if (key === 'subject') {
            setSubjectList(result.data.result.results);
          }
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.message);
      });
  };

  useEffect(() => {
    handlGetApi(`${endpoints.ebook.academicYearList}`, 'acad');
    handlGetApi(endpoints.ebook.volumes, 'volume');
  }, []);
  const handleAcademicYear = (event, value) => {
    setFilterData({ ...filterData, year: '' });
    if (value) {
      setFilterData({ ...filterData, year: value });
    }
  };

  const handleVolume = (event, value) => {
    setFilterData({
      ...filterData,
      volume: '',
      grade: '',
      subject: '',
      board: '',
      module: '',
      chapter: '',
    });
    if (value) {
      setFilterData({ ...filterData, volume: value });
    }
  };

  const handleGrade = (event, value) => {
    setLoading(true);
    setFilterData({
      ...filterData,
      grade: '',
      subject: '',
      chapter: '',
      board: '',
      module: '',
      chapter: '',
    });
    if (value) {
      console.log(value, "ji")
      setLoading(true);
      setFilterData({ ...filterData, grade: value, subject: '', chapter: '' });
      axiosInstance
        .get(`${endpoints.lessonPlan.gradeSubjectMappingList}?grade=${value.id}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setSubjectDropdown(result.data.result.results);
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
            setSubjectDropdown([]);
            setChapterDropdown([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
          setSubjectDropdown([]);
          setChapterDropdown([]);
        });
    } else {
      setLoading(false);
      setSubjectDropdown([]);
      setChapterDropdown([]);
    }
  };

  const handleSubject = (event, value) => {
    setLoading(true);
    setFilterData({ ...filterData, subject: '', board: '', module: '', chapter: '' });
    if (value) {
      setLoading(true);
      setFilterData({ ...filterData, subject: value });
      axiosInstance
        .get(`${endpoints.masterManagement.boardMappingList}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setBoardDropdown(result.data.result);
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
            setBoardDropdown([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
          setBoardDropdown([]);
        });
    } else {
      setLoading(false);
      setBoardDropdown([]);
    }
  };

  const handleBoard = (event = {}, values = []) => {
    setLoading(true);
    setFilterData({ ...filterData, board: '', module: '', chapter: '' });
    if (values) {
      setLoading(true);
      const ids = values.map((el) => el);
      const selectedId = values.map((el) => el?.id);
      setSelectedBoardId(selectedId);
      setFilterData({ ...filterData, board: ids, chapter: '' });
      axiosInstance
        .get(
          `${endpoints.masterManagement.module}?academic_year=${filterData?.year?.id
          }&volume=${filterData?.volume?.id}&grade_subject=${filterData?.subject?.id
          }&board=${selectedId}&page_size=${50}`
        )
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setModuleDropdown(result.data.result);
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
            setSelectedBoardId([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
          setSelectedBoardId([]);
        });
    } else {
      setLoading(false);
      setSelectedBoardId([]);
    }
  };

  const handleModule = (event = {}, values = []) => {
    setLoading(true);
    setFilterData({ ...filterData, module: '', chapter: '' });
    if (values) {
      setLoading(true);
      // const ids = values.map((el) => el);
      // const selectedId = values.map((el) => el?.id)
      setSelectedModuleId(values);
      setFilterData({ ...filterData, module: values, chapter: '' });
      if (filterData.year && filterData.volume && values.length !== 0) {
        axiosInstance
          .get(
            `${endpoints.lessonPlan.chapterList}?grade_subject=${filterData?.subject?.id}&volume=${filterData?.volume?.id}&academic_year=${filterData?.year?.id}&board=${selectedBoardId}&lt_module=${values?.id}`
          )
          .then((result) => {
            if (result.data.status_code === 200) {
              setLoading(false);
              setChapterDropdown(result.data.result);
            } else {
              setLoading(false);
              setAlert('error', result.data.message);
              setChapterDropdown([]);
            }
          })
          .catch((error) => {
            setLoading(false);
            setAlert('error', error.message);
            setChapterDropdown([]);
          });
      }
    } else {
      setLoading(false);
      setChapterDropdown([]);
    }
  };

  const handleChapter = (event = {}, values = []) => {
    setLoading(true);
    setFilterData({ ...filterData, chapter: '', keyconcept: '' });

    if (values) {
      setLoading(true);
      setSelectedKeyConceptId(values);
      setFilterData({ ...filterData, chapter: values, keyconcept: '' });
      if (filterData.year && filterData.volume && values.length !== 0) {
        axiosInstance
          .get(
            `${endpoints.masterManagement.createTopic}?chapter=${values?.id}&page_size=50`
          )
          .then((result) => {
            if (result.data.status_code === 200) {
              setLoading(false);
              setKeyConceptDropdown(result.data.result);
            } else {
              setLoading(false);
              setAlert('error', result.data.message);
              setKeyConceptDropdown([]);
            }
          })
          .catch((error) => {
            setLoading(false);
            setAlert('error', error.message);
            setKeyConceptDropdown([]);
          });
      }
    } else {
      setLoading(false);
      setChapterDropdown([]);
    }
  };
  const handleKeyConcept = (event, value) => {
    console.log(filterData, 'ggg');
    setFilterData({ ...filterData, keyconcept: '' });

    if (value) {
      setFilterData({ ...filterData, keyconcept: value });
    }
  };
  const [error, setErrorValue] = useState({
    option: true,
    editor: true,
    title: true,
    image: true,
  });

  const isZIP = (files) => {
    if (files[0].name.match(/.(zip)$/i)) {
      return true;
    }
    return false;
  };

  const onDrop = (files = []) => {
    if (!isZIP(files)) {
      setAlert('error', 'Please select only zip format');
      return;
    }
    if (files.length > 1) {
      setAlert('error', 'You can select only a single PDF at once');
      return;
    }
    const sampleFile = files;
    setFiles(sampleFile);
  };

  const onDropImage = (files = []) => {
    if (files.length > 1) {
      setAlert('error', 'You can select only a single fine at once');
      return;
    }
    let fileType = ['image/jpeg', 'image/jpg', 'image/png'];
    let selectedFileType = files[0].type;
    if (fileType.includes(selectedFileType)) {
      const sampleFile = files;
      setDropFiles(sampleFile);
      return;
    } else {
      setAlert('error', 'Please select valid Image format i.e. JPEG, JPG, PNG');
      return;
    }

  };

  const handleChangeFile = (files) => {
    setDropFiles(files[0]);
  };

  const handleCloseImage = () => {
    setOpenImage(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!dropfiles || dropfiles?.length == 0 ) {
      return setAlert('error', 'Please Add Image');
    }
    if (title == '') {
      return setAlert('error', 'Please Add Book Title')
    }
    if (localStorage == '') {
      return setAlert('error', 'Please Add LocalStorage ID')
    }
    setLoading(true);
    const formData = new FormData();
    for (let i = 0; i < files.length; i += 1) {
      formData.append('book_url', files[i]);
    }
    formData.append('book_image', dropfiles[0]);
    formData.set('academic_year', filterData.year.id);
    formData.set('volume', filterData.volume.id);
    formData.set('local_storage_id', localStorage);
    formData.set('grade', filterData?.grade?.id);
    formData.set('subject', filterData?.subject?.subject?.id);
    formData.set('board_id', filterData?.board[0]?.id);
    formData.set('lt_module', filterData?.module?.id);
    formData.set('chapter_id', filterData?.chapter?.id);
    formData.set('key_concept_id', filterData?.keyconcept?.id);
    formData.set('book_name', title);
    formData.set('author', author);
    formData.set('created_by', '');
    formData.set('book_status', '1');

    axiosInstance
      .post(`${endpoints.ibook.addbook}`, formData)
      .then((result) => {
        if (result.status === 200) {
          setLoading(false);
          setAlert('success', 'SUCCESSFULLY UPLOADED IBOOK');
          history.push('/ibook/view')
        } else {
          setLoading(false);
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.message);
      });
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const handleLocalStorageChange = (e) => {
    setLocalStorage(e.target.value);
  };

  const handleAuthorChange = (e) => {
    setAuthor(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const getFileNameAndSize = (files) => {
    if (files.length) {
      const fileName =
        files &&
        files.map((file) => (
          <li key={file.name}>{`${file.name} -${file.size} bytes`}</li>
        ));


        const wholeArea = (
          <div style={{display: 'flex' , justifyContent: 'space-between'}} >
            <div style={{margin: 'auto 0'}} >
              {fileName}
            </div>
            <IconButton onClick={handleFileZip}  >
              <ClearIcon />
            </IconButton>
          </div>
        )
      return wholeArea;
    }
    return null;
  };

  const handleFile = (e) => {
    e.stopPropagation()
    setDropFiles([])
  }

  const handleFileZip = (e) => {
    e.stopPropagation()
    setFiles([])
  }

  

  const getImageNameAndSize = (files) => {
    if (files.length) {
      const fileName =
        files &&
        files.map((file) => (
          <li key={file.name}>{`${file.name} -${file.size} bytes`}</li>
        ));

      const wholeArea = (
        <div style={{display: 'flex' , justifyContent: 'space-between'}} >
          <div style={{margin: 'auto 0'}}>
            {fileName}
          </div>
          <IconButton onClick={handleFile}  >
            <ClearIcon />
          </IconButton>
        </div>
      )

      return wholeArea;
    }
    return null;
  };

  return (
    <>
      {loading ? <Loading message='Loading...' /> : null}
      <Layout>
        <div style={{ width: '100%', padding: '10px 20px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <CommonBreadcrumbs
                componentName='Intelligent Book'
                childComponentName='Create IBook'
              />
            </Grid>
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
              <Autocomplete
                style={{ width: '100%' }}
                size='small'
                onChange={handleAcademicYear}
                id='academic-year'
                className='dropdownIcon'
                value={filterData.year}
                options={academicYearDropdown}
                getOptionLabel={(option) => option?.session_year}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Academic Year'
                    placeholder='Academic Year'
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
              <Autocomplete
                style={{ width: '100%' }}
                size='small'
                onChange={handleVolume}
                id='volume'
                className='dropdownIcon'
                value={filterData.volume}
                options={volumeDropdown}
                getOptionLabel={(option) => option?.volume_name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Volume'
                    placeholder='Volume'
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
              <Autocomplete
                style={{ width: '100%' }}
                size='small'
                onChange={handleGrade}
                id='grade'
                className='dropdownIcon'
                value={filterData.grade}
                options={gradeDropdown}
                getOptionLabel={(option) => option?.grade_name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Grade'
                    placeholder='Grade'
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
              <Autocomplete
                style={{ width: '100%' }}
                size='small'
                onChange={handleSubject}
                id='subject'
                className='dropdownIcon'
                value={filterData.subject}
                options={subjectDropdown}
                getOptionLabel={(option) => option?.subject?.subject_name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Subject'
                    placeholder='Subject'
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
              <Autocomplete
                multiple
                style={{ width: '100%' }}
                size='small'
                onChange={handleBoard}
                id='board'
                className='dropdownIcon'
                value={filterData.board || []}
                options={boardDropdown || []}
                getOptionLabel={(option) => option?.board_name || ''}
                // filterSelectedOptions
                getOptionSelected={(option, value) => option?.id == value?.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Board'
                    placeholder='Board'
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
              <Autocomplete
                // multiple
                style={{ width: '100%' }}
                size='small'
                onChange={handleModule}
                id='module'
                className='dropdownIcon'
                value={filterData.module || []}
                options={moduleDropdown || []}
                getOptionLabel={(option) => option?.lt_module_name || ''}
                filterSelectedOptions
                // getOptionSelected={(option, value) =>
                //     option?.id == value?.id
                //   }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Module'
                    placeholder='Module'
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
              <Autocomplete
                style={{ width: '100%' }}
                size='small'
                onChange={handleChapter}
                id='chapter'
                className='dropdownIcon'
                value={filterData.chapter}
                options={chapterDropdown}
                getOptionLabel={(option) => option?.chapter_name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Chapter'
                    placeholder='Chapter'
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
              <Autocomplete
                style={{ width: '100%' }}
                size='small'
                onChange={handleKeyConcept}
                id='keyConcept'
                className='dropdownIcon'
                value={filterData.keyconcept}
                options={keyConceptDropdown}
                getOptionLabel={(option) => option?.topic_name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='KeyConcept'
                    placeholder='KeyConcept'
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
              <TextField
                id='outlined-helperText'
                label='Book LocalStorage ID'
                defaultValue=''
                required
                className='dropdownIcon'
                variant='outlined'
                type='number'
                style={{ width: '100%' }}
                inputProps={{ maxLength: 100 }}
                onChange={(event) => {
                  handleLocalStorageChange(event);
                }}
                color='secondary'
                size='small'
              />
            </Grid>

            <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
              <TextField
                id='outlined-helperText'
                label='Book title'
                defaultValue=''
                required
                className='dropdownIcon'
                variant='outlined'
                style={{ width: '100%' }}
                inputProps={{ maxLength: 100 }}
                onChange={(event) => {
                  handleTitleChange(event);
                }}
                color='secondary'
                size='small'
              />
            </Grid>
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
              <TextField
                id='outlined-helperText'
                label='Author name'
                defaultValue=''
                className='dropdownIcon'
                variant='outlined'
                style={{ width: '100%' }}
                inputProps={{ maxLength: 100 }}
                onChange={(event) => {
                  handleAuthorChange(event);
                }}
                color='secondary'
                size='small'
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                id='outlined-helperText'
                label='Description'
                className='dropdownIcon'
                multiline
                placeholder='Note==> Word should be less than 100'
                rows={3}
                variant='outlined'
                value={description}
                style={{ width: '100%' }}
                inputProps={{ maxLength: 100 }}
                onChange={(event) => {
                  handleDescriptionChange(event);
                }}
                color='secondary'
                size='small'
              />
            </Grid>
          </Grid>
          <br />
          <Grid container direction='row'>
            <Grid item md={6} xs={12}>
              <Typography variant='h6' color='secondary'>
                Upload I-book in ZIP format
              </Typography>

              <Typography color='textPrimary' variant='caption' />
              <Dropzone onDrop={onDrop}>
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragAccept,
                  isDragReject,
                }) => (
                  <Card
                    elevation={0}
                    style={{
                      width: '320px',
                      height: '150px',
                      border: '1px solid #ff6b6b',
                      borderRadius: '10px',
                    }}
                    {...getRootProps()}
                    className='dropzone'
                  >
                    <CardContent>
                      <input {...getInputProps()} />
                      <div style={{ textAlign: 'center', marginTop: '40px' }}>
                        {isDragAccept && 'All files will be accepted'}
                        {isDragReject && 'Some files will be rejected'}
                        {!isDragActive && (
                          <Button
                            variant='text'
                            style={{ background: 'none', color: '#014b7e' }}
                          >
                            <CloudUploadIcon />
                            &nbsp;Drop file
                          </Button>
                        )}
                      </div>
                      {getFileNameAndSize(files)}
                    </CardContent>
                  </Card>
                )}
              </Dropzone>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography variant='h6' color='secondary'>
                Upload I-book Image
              </Typography>

              <Typography color='textPrimary' variant='caption' />
              <Dropzone onDrop={onDropImage}>
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragAccept,
                  isDragReject,
                }) => (
                  <Card
                    elevation={0}
                    style={{
                      width: '320px',
                      height: '150px',
                      border: '1px solid #ff6b6b',
                      borderRadius: '10px',
                    }}
                    {...getRootProps()}
                    className='dropzone'
                  >
                    <CardContent>
                      <input {...getInputProps()} />
                      <div style={{ textAlign: 'center', marginTop: '40px' }}>
                        {isDragAccept && 'All files will be accepted'}
                        {isDragReject && 'Some files will be rejected'}
                        {!isDragActive && (
                          <Button
                            variant='text'
                            style={{ background: 'none', color: '#014b7e' }}
                          >
                            <CloudUploadIcon />
                            &nbsp;Drop Image
                          </Button>
                        )}
                      </div>
                      {getImageNameAndSize(dropfiles)}
                    </CardContent>
                  </Card>
                )}
              </Dropzone>
            </Grid>

            {/* <Grid item md={4} xs={12}>
              <Typography variant='h6' color='secondary'>
                Upload I-book Image
              </Typography>

              <Typography color='textPrimary' variant='caption' />
              <Grid className='drag-drop'>
                <DropzoneArea
                  open={openImage}
                  error={error.image}
                  acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                  maxFileSize={5000000}
                  onChange={handleChangeFile.bind()}
                  onClose={handleCloseImage.bind()}
                  alertSnackbarProps={{
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                  }}
                />
              </Grid>
            </Grid> */}

          </Grid>

          <br />
          <Grid container spacing={2}>
            <Grid item xs={6} sm={2}>
              <Button
                onClick={() => history.push('/ibook/view')}
                variant='contained'
                style={{ color: 'white' }}
                className='custom_button_master'
                size='medium'
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={6} sm={2}>
              <Button
                variant='contained'
                style={{ color: 'white' }}
                color='primary'
                className='custom_button_master'
                size='medium'
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
      </Layout>
    </>
  );
}

export default CreateIbook;
