import React, { useContext, useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Button,
  useTheme,
  Switch,
  FormControlLabel,
  Divider,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Autocomplete from '@material-ui/lab/Autocomplete';
import endpoints from '../../../config/endpoints';
import axiosInstance from '../../../config/axios';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';

// {id,name,desc,handleGoBack,setLoading,opt}

const EditChapter = ({ chapter, setLoading, handleGoBack }) => {
  console.log('datac', chapter);
  // const subName=name.split("_").pop()
  const { setAlert } = useContext(AlertNotificationContext);

  const [chapterName, setChapterName] = useState(chapter.chapter_name || '');
  const [chapterRef, setChapterRef] = useState(chapter.rfrnc || '');
  const [academicYear, setSetAcademicYear] = useState(chapter.academic_year || '');
  const [gradeSubjectMapingId, setGradeMapingId] = useState(
    chapter.grade_subject_mapping || ''
  );
  const [chapterId, setChapterId] = useState(chapter.id);
  const [chapterSequence, setChapterSequence] = useState(chapter?.sequence);
  const [noOfPeriods, setNoOfPeriods] = useState(chapter.no_of_periods || '');
  const [boardDropdown, setBoardDropdown] = useState([]);
  const [moduleDropDown, setModuleDropdown] = useState([]);
  // const [noOfChapter,setNoOfChapter]=useState(chapter.no_of_periods);

  const [academicYearDropdown, setAcademicYearDropdown] = useState([]);
  const [volumeDropdown, setVolumeDropdown] = useState([]);
  const [gradeDropdown, setGradeDropdown] = useState([]);
  const [subjectDropdown, setSubjectDropdown] = useState([]);
  const [gradeSubjectMapping, setGradeSubjectMapping] = useState('');

  const chapterUpdateApi = '/update-chapter/';

  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));

  const [filterData, setFilterData] = useState({
    year: chapter?.academic_year,
    volume: chapter?.lt_module?.volume,
    grade: chapter?.grade_subject_mapping?.grade,
    subject: chapter?.grade_subject_mapping?.subject,
    chapter: '',
    board: chapter?.board,
    module: chapter?.lt_module,
  });

  useEffect(() => {
    axiosInstance
      .get(`${endpoints.masterManagement.academicYear}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setAcademicYearDropdown(result.data.result.results);
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });

    axiosInstance
      .get(`${endpoints.masterManagement.volumes}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setVolumeDropdown(result.data.result.results);
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });

    axiosInstance
      .get(`${endpoints.masterManagement.grades}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setGradeDropdown(result.data.result.results);
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });
    if (chapter?.grade_subject_mapping) {
      setGradeSubjectMapping(chapter?.grade_subject_mapping?.id);
    }
  }, []);
  //-------------------------------------------------------
  const handleAcademicYear = (event, value) => {
    setFilterData({ ...filterData, year: '' });
    if (value) {
      setFilterData({ ...filterData, year: value });
    }
  };
  const handleVolume = (event, value) => {
    setFilterData({ ...filterData, volume: '' });
    if (value) {
      setFilterData({ ...filterData, volume: value });
    }
  };
  const handleSubject = (event, value) => {
    setFilterData({ ...filterData, subject: '' });
    if (value) {
      setFilterData({ ...filterData, subject: value });
      axiosInstance
        .get(
          `${endpoints.masterManagement.gradeSubjectMappingList}?grade=${filterData.grade?.id}&subject=${value?.subject?.subject_name}`
        )
        .then((response) => {
          if (response.data.status_code === 200) {
            console.log('response', response, filterData);
            setGradeSubjectMapping(response?.data?.result?.results[0]?.id);
          }
        });
      axiosInstance
        .get(`${endpoints.masterManagement.boardMappingList}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            setBoardDropdown(result.data.result);
            // console.log(result.data.result,'hargsy77777')
          } else {
            setAlert('error', result.data.message);
            setBoardDropdown([]);
          }
        })
        .catch((error) => {
          setAlert('error', error.message);
          setBoardDropdown([]);
        });
    } else {
      setBoardDropdown([]);
    }
  };
  const handleGrade = (event, value) => {
    setFilterData({ ...filterData, grade: '' });
    if (value) {
      setFilterData({ ...filterData, grade: value });
      axiosInstance
        .get(`${endpoints.masterManagement.gradeSubjectMappingList}?grade=${value.id}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            setSubjectDropdown(result.data.result.results);
          } else {
            setAlert('error', result.data.message);
            setSubjectDropdown([]);
          }
        })
        .catch((error) => {
          setAlert('error', error.message);
          setSubjectDropdown([]);
        });
    } else {
      setSubjectDropdown([]);
    }
  };
  //------------------------------------------------
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let request = {};
    if (
      (chapterName !== chapter.chapter_name && chapterName !== '') ||
      (chapterRef !== chapter.rfrnc && chapterRef !== '') ||
      chapterSequence !== chapter?.sequence
    ) {
      request['chapter_name'] = chapterName.trim();
      request['rfrnc'] = chapterRef ? chapterRef.trim() : null;
      // request["academic_year"]= 1
      // request["no_of_periods"]=noOfPeriods
      request['academic_year'] = filterData?.year?.id;
      request['volume'] = filterData?.volume?.id;
      request['grade_subject_mapping'] = gradeSubjectMapping;
      request['board'] = filterData?.board?.id;
      request['lt_module'] = filterData?.module?.id;
      request['sequence'] = Number(chapterSequence);
      axiosInstance
        .put(
          `${endpoints.masterManagement.updateChapters}${chapterId}${chapterUpdateApi}`,
          request
        )
        .then((result) => {
          if (result.data.status_code === 200) {
            setChapterName('');
            setChapterRef('');
            setNoOfPeriods('');
            setLoading(false);
            setAlert('success', result.data.message);
            handleGoBack();
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.response.data.message);
        });
    } else {
      setAlert('error', 'No Fields to Update');
      setLoading(false);
    }
  };

  const handleBoard = (event, value) => {
    setFilterData({ ...filterData, board: '' });
    if (value) {
      setFilterData({ ...filterData, board: value });
      axiosInstance
        .get(
          `${endpoints.masterManagement.module}?academic_year=${filterData?.year?.id}&volume=${filterData?.volume?.id}&grade_subject=${filterData?.subject?.id}&board=${value?.id}`
        )
        .then((result) => {
          if (result.data.status_code === 200) {
            console.log(result.data.result);
            setModuleDropdown(result.data.result);
          } else {
            setAlert('error', result.data.message);
            setModuleDropdown([]);
          }
        })
        .catch((error) => {
          setAlert('error', error.message);
          setModuleDropdown([]);
        });
    } else {
      setModuleDropdown([]);
    }
  };

  const handleModule = (event, value) => {
    setFilterData({ ...filterData, module: '' });
    if (value) {
      setFilterData({ ...filterData, module: value });
    }
  };

  return (
    <form autoComplete='off' onSubmit={handleSubmit}>
      <div style={{ width: '95%', margin: '20px auto' }}>
        <Grid container spacing={5}>
          <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <Autocomplete
              size='small'
              onChange={handleAcademicYear}
              style={{ width: '100%' }}
              id='grade'
              options={academicYearDropdown}
              value={filterData.year}
              getOptionLabel={(option) => option?.session_year}
              filterSelectedOptions
              disabled
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Academic Year'
                  placeholder='Academic Year'
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <Autocomplete
              size='small'
              onChange={handleVolume}
              style={{ width: '100%' }}
              id='grade'
              options={volumeDropdown}
              value={filterData.volume}
              getOptionLabel={(option) => option?.volume_name}
              filterSelectedOptions
              disabled
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Volume'
                  placeholder='Volume'
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <Autocomplete
              size='small'
              onChange={handleGrade}
              style={{ width: '100%' }}
              id='grade'
              options={gradeDropdown}
              value={filterData.grade}
              getOptionLabel={(option) => option?.grade_name}
              filterSelectedOptions
              disabled
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Grades'
                  placeholder='Grades'
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <Autocomplete
              size='small'
              onChange={handleSubject}
              style={{ width: '100%' }}
              id='grade'
              options={subjectDropdown}
              value={filterData.subject}
              disabled
              getOptionLabel={(option) => option?.subject_name}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Subject'
                  placeholder='Subject'
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <Autocomplete
              size='small'
              onChange={handleBoard}
              style={{ width: '100%' }}
              id='board'
              options={boardDropdown}
              disabled
              value={filterData.board}
              getOptionLabel={(option) => option?.board_name}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Board'
                  placeholder='Board'
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <Autocomplete
              size='small'
              onChange={handleModule}
              style={{ width: '100%' }}
              id='module'
              options={moduleDropDown}
              value={filterData.module}
              disabled
              getOptionLabel={(option) => option?.lt_module_name}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Module'
                  placeholder='Module'
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>

        {/* <Grid container spacing={5}>
          <Grid item xs={12} sm={4} className={isMobile?'':'addEditPadding'}>
            <Autocomplete
              size='small'
              onChange={handleSection}
              style={{ width: '100%' }}
              id='section'
              options={sections}
              value={selectedSection}
              getOptionLabel={(option) => option?.section__section_name}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Section'
                  placeholder='Sections'
                  required
                />
              )}
            />
          </Grid>
        </Grid> */}
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <TextField
              id='chname'
              style={{ width: '100%' }}
              label='Chapter Name'
              variant='outlined'
              size='small'
              value={chapterName}
              inputProps={{ accept: '^[a-zA-Z0-9 +_-]+', maxLength: 100 }}
              //inputProps={{pattern:'^[a-zA-Z0-9]+',maxLength:100}}
              name='chname'
              onChange={(e) => setChapterName(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <TextField
              id='chname'
              style={{ width: '100%' }}
              label='Chapter Reference'
              variant='outlined'
              size='small'
              value={chapterRef}
              inputProps={{ accept: '^[a-zA-Z0-9 +_-]+', maxLength: 100 }}
              //inputProps={{pattern:'^[a-zA-Z0-9]+',maxLength:100}}
              name='chname'
              onChange={(e) => setChapterRef(e.target.value)}
              // required
            />
          </Grid>
          <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <TextField
              id='chaptersequencenumber'
              type='number'
              label='Chapter Sequence Number'
              placeholder='Enter Chapter Sequence Number'
              value={chapterSequence}
              onChange={(e) => setChapterSequence(e.target.value)}
              style={{ width: '100%' }}
              variant='outlined'
              size='small'
              SelectProps={{
                native: true,
              }}
            />
          </Grid>
        </Grid>
      </div>

      <Grid container spacing={isMobile ? 1 : 5} style={{ width: '95%', margin: '10px' }}>
        <Grid item xs={6} sm={2} className={isMobile ? '' : 'addEditButtonsPadding'}>
          <Button
            variant='contained'
            className='custom_button_master labelColor'
            size='medium'
            onClick={handleGoBack}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={6} sm={2} className={isMobile ? '' : 'addEditButtonsPadding'}>
          <Button
            variant='contained'
            style={{ color: 'white' }}
            color='primary'
            className='custom_button_master'
            size='medium'
            type='submit'
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditChapter;
