import React, { useContext, useState, useEffect } from 'react';
import {
  Grid,
  TextField,
  Button,
  useTheme,
  Switch,
  FormControlLabel,
  Divider,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Autocomplete from '@material-ui/lab/Autocomplete';
import endpoints from '../../../config/endpoints';
import axiosInstance from '../../../config/axios';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import { event } from 'jquery';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';

import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 auto',
    boxShadow: 'none',
  },
  container: {
    maxHeight: '70vh',
    width: '100%',
  },
  columnHeader: {
    color: `${theme.palette.secondary.main} !important`,
    fontWeight: 600,
    fontSize: '1rem',
    backgroundColor: `#ffffff !important`,
  },
  tableCell: {
    color: theme.palette.secondary.main,
  },
  clrBtn: {
    margin: 3,
    color: 'rgb(140, 140, 140) !important',
  },
  buttonContainer: {
    width: '95%',
    margin: '0 auto',
    background: theme.palette.background.secondary,
    paddingBottom: theme.spacing(2),
  },
}));

const columns = [
  {
    id: 'subject_name',
    label: 'Key Concept Name',
    minWidth: 100,
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'created_by',
    label: 'Chapter Name',
    minWidth: 100,
    align: 'center',
    labelAlign: 'center',
  },
  // {
  //   id: 'desc',
  //   label: 'Description',
  //   minWidth: 100 ,
  //   align: 'center',
  //   labelAlign: 'center',
  // },
  // {
  //   id: 'optional',
  //   label: 'Optional',
  //   minWidth: 50,
  //   align: 'center',
  //   labelAlign: 'center',
  // },
  // {
  //   id: 'actions',
  //   label: 'Actions',
  //   minWidth: 170,
  //   align: 'center',
  //   labelAlign: 'center',
  // },
];
const CreateTopic = ({ topicData, setLoading, handleGoBack }) => {
  const classes = useStyles();
  console.log('DataTopics', topicData);
  const { setAlert } = useContext(AlertNotificationContext);
  const [subjectName, setSubjectName] = useState('');
  const [description, setDescription] = useState('');
  const [selectedGrade, setSelectedGrade] = useState([]);
  const [selectedSection, setSelectedSection] = useState([]);
  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const [sections, setSections] = useState([]);
  const [optional, setOptional] = useState(false);
  const [academicYearDropdown, setAcademicYearDropdown] = useState([]);
  const [volumeDropdown, setVolumeDropdown] = useState([]);
  const [gradeDropdown, setGradeDropdown] = useState([]);
  const [subjectDropdown, setSubjectDropdown] = useState([]);
  const [boardDropdown, setBoardDropdown] = useState([]);
  const [moduleDropdown, setModuleDropdown] = useState([]);
  const [chapterDropdown, setChapterDropdown] = useState([]);
  const [selectedChapter, setSelectedChapter] = useState('');
  const [topicList, setTopicList] = useState([]);
  const [topicName, setTopicName] = useState([]);
  const [openModule, setOpenModule] = useState(false);
  const [module_name, setModule_name] = useState('');
  const [moduleSequenceNumber, setModuleSequenceNumber] = useState('');
  const [openChapter, setOpenChapter] = useState(false);
  const [chapter_name, setChapter_name] = useState('');
  const [chapterSequence, setChapterSequence] = useState('');
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [tableFlag, setTableFlag] = useState(true);
  const [AddmoduleButton, setAddmoduleButton] = useState(true);

  const [AddChapterButton, setAddChapterButton] = useState(true);
  const [gradeSubjectMapping, setGradeSubjectMapping] = useState('');
  const [keyConceptSequence, setKeyConceptSequence] = useState('');

  const { role_details } = JSON.parse(localStorage.getItem('userDetails'));

  const [filterData, setFilterData] = useState({
    year: topicData?.year,
    volume: topicData?.volume,
    grade: topicData?.grade,
    subject: topicData?.subject,
    chapter: '',
    board: topicData?.board,
    module: '',
  });

  const limit = 10;
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };
  const handleClickOpen = () => {
    setOpenModule(true);
  };

  const handleClose = () => {
    setOpenModule(false);
  };
  const handleClickOpenChapter = () => {
    setOpenChapter(true);
  };

  const handleCloseChapter = () => {
    setOpenChapter(false);
  };
  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`${endpoints.masterManagement.academicYear}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          const defaultYear = result?.data?.current_acad_session_data?.[0];
          setLoading(false);
          setFilterData({ ...filterData, year: defaultYear });
          setAcademicYearDropdown(result.data.result.results);
        } else {
          setLoading(false);
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.message);
      });

    axiosInstance
      .get(`${endpoints.masterManagement.volumes}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setLoading(false);
          setVolumeDropdown(result.data.result.results);
        } else {
          setLoading(false);
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.message);
      });

    axiosInstance
      .get(`${endpoints.masterManagement.grades}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setLoading(false);
          setGradeDropdown(result.data.result.results);
        } else {
          setLoading(false);
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.message);
      });
    axiosInstance
      .get(`${endpoints.masterManagement.boardMappingList}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setLoading(false);
          setBoardDropdown(result.data.result);
        } else {
          setLoading(false);
          setAlert('error', result.data.message);
          setBoardDropdown([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.message);
        setBoardDropdown([]);
      });
    if (topicData?.subject) {
      axiosInstance
        .get(
          `${endpoints.masterManagement.gradeSubjectMappingList}?grade=${topicData?.grade?.id}`
        )
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setSubjectDropdown(result.data.result.results);
            let selectedSubject = result.data.result?.results?.filter(
              (item) => item.subject.id === topicData?.subject?.id
            );
            setGradeSubjectMapping(selectedSubject[0]?.id);
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
            setSubjectDropdown([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
          setSubjectDropdown([]);
        });
    }
  }, []);

  useEffect(() => {
    if (
      filterData.year?.id &&
      filterData.volume?.id &&
      filterData.subject?.id &&
      filterData.board?.id &&
      filterData.module?.id
    ) {
      setLoading(true);
      axiosInstance
        .get(
          `${endpoints.masterManagement.chapters}?academic_year=${filterData.year.id}&grade_subject=${filterData.subject.id}&board=${filterData.board.id}&lt_module=${filterData.module.id}`
        )
        .then((res) => {
          setLoading(false);
          setChapterDropdown(res.data.result);
        })
        .catch((error) => console.log(error));
    }
  }, [filterData]);
  const handleFilter = () => {
    // setLoading(true);
    if (!filterData?.grade) {
      setAlert('error', 'Select Grade!');
      return;
    }
    if (!filterData?.subject) {
      setAlert('error', 'Select Subject!');
      return;
    }
    if (!filterData?.board) {
      setAlert('error', 'Select Board !');
      return;
    }
    if (!filterData?.module) {
      setAlert('error', 'Select Module !');
      return;
    }
    if (!filterData?.chapter) {
      // setAlert('error', 'Select Module !');
      return;
    } else {
      axiosInstance
        .get(
          //topic_name chapter
          `${endpoints.masterManagement.createTopic}?chapter=${filterData.chapter.id}&page_size=${limit}`
        )
        .then((res) => {
          //console.log(res.data,'topic');
          if (res.data.status_code === 200) {
            setTableFlag(false);
            // setLoading(true);
            setTotalCount(res.data.count);
            setTopicList(res.data.result);
            setAlert('success', res.data.message);
          } else {
            setTableFlag(true);
            // setLoading(false);
            setAlert('error', res.data.message);
          }
        })
        .catch((error) => {
          setTableFlag(false);
          //console.log(error);
          // setLoading(false);
          setAlert('error', error.message);
        });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log('hi', filterData.chapter.id, topicName, filterData.year.id, filterData.board)

    setLoading(true);
    const params = {
      chapter: filterData.chapter.id,
      topic_name: topicName.trim(),
      board: filterData.board.id,
      sequence: Number(keyConceptSequence),
    };
    axiosInstance
      .post(`${endpoints.masterManagement.createTopic}`, params)
      .then((result) => {
        if (result.data.status_code === 200) {
          // setFilterData({
          //     year: '',
          //     volume: '',
          //     grade: '',
          //     subject: '',
          //     chapter: '',
          //     board: '',
          //     module: ''

          // });
          setTopicName('');
          //setNoOfChapter('')
          setLoading(false);
          setAlert('success', result.data.message);
          setKeyConceptSequence('');
        } else {
          if (result.data.status_code === 404) {
            setAlert('error', result.data.description);
            setLoading(false);
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.response.data.message);
      });
  };
  const createChapterhandleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axiosInstance
      .post(endpoints.masterManagement.createUpdatemodule, {
        lt_module_name: module_name,
        grade_subject_mapping: gradeSubjectMapping,
        volume: filterData.volume.id,
        academic_year: filterData.year.id,
        board: filterData.board.id,
        sequence: Number(moduleSequenceNumber),
      })
      .then((result) => {
        if (result.status === 201) {
          {
            console.log('valueBoardData', filterData.board);
            handleBoards(filterData.board);
            handleFilter();
            handleClose();
            setLoading(false);
            setModuleSequenceNumber();
            setAlert('success', result.data.message);
          }
        } else {
          setLoading(false);
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data.message == 'Module existed') {
          setAlert('error', 'Module Name Already Exists');
        } else {
          setAlert('error', error.response.data.message);
        }
      });
  };

  const createKeyconcepthandleSubmit = (e) => {
    console.log(
      'hi',
      filterData.subject.id,
      filterData.volume.id,
      filterData.chapter,
      filterData.year.id
    );

    e.preventDefault();
    setLoading(true);
    axiosInstance
      .post(endpoints.masterManagement.createChapters, {
        grade_subject_mapping: gradeSubjectMapping,
        volume: filterData.volume.id,
        chapter_name: chapter_name.trim(),
        academic_year: filterData.year.id,
        // "no_of_periods":noOfChapter,
        board: filterData.board.id,
        lt_module: filterData.module.id,
        sequence: Number(chapterSequence),
      })
      .then((result) => {
        console.log(result);
        if (result.data.status_code === 200) {
          handleFilter();
          handleCloseChapter();
          handleBoards(filterData.board);
          handleModules(filterData.module);
          setChapterSequence();
          setLoading(false);
          setAlert('success', result.statusText);
          setAlert('success', result.data.message);
        } else {
          setLoading(false);
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        // setAlert('error', error.message)
        if (error.response.data.message == 'Chapter is existed') {
          setAlert('error', 'Chapter Name Already Exists');
        } else {
          setAlert('error', error.response.data.message);
        }
      });
  };

  const handleAcademicYear = (event, value) => {
    setFilterData({ ...filterData, year: '' });
    if (value) {
      setFilterData({ ...filterData, year: value });
    } else {
      setFilterData({
        year: '',
        volume: '',
        grade: '',
        subject: '',
        board: '',
        chapter: '',
        module: '',
      });
    }
  };
  const handleVolume = (event, value) => {
    setFilterData({ ...filterData, volume: '' });
    if (value) {
      setFilterData({ ...filterData, volume: value });
    } else {
      setFilterData({
        year: filterData?.year,
        volume: '',
        grade: '',
        subject: '',
        board: '',
        chapter: '',
        module: '',
      });
    }
  };

  const handleGrade = (event, value) => {
    setFilterData({ ...filterData, grade: '' });
    if (value) {
      setLoading(true);
      setFilterData({ ...filterData, grade: value });
      axiosInstance
        .get(`${endpoints.masterManagement.gradeSubjectMappingList}?grade=${value.id}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setSubjectDropdown(result.data.result.results);
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
            setSubjectDropdown([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
          setSubjectDropdown([]);
        });
    } else {
      setLoading(false);
      setFilterData({
        year: filterData?.year,
        volume: filterData?.volume,
        grade: '',
        subject: '',
        board: '',
        chapter: '',
        module: '',
      });
      setSubjectDropdown([]);
    }
  };
  const handleSubject = (event, value) => {
    setFilterData({ ...filterData, subject: '' });
    if (value) {
      setLoading(true);
      setFilterData({ ...filterData, subject: value });
      axiosInstance
        .get(
          `${endpoints.masterManagement.gradeSubjectMappingList}?grade=${filterData.grade?.id}&subject=${value?.subject?.subject_name}`
        )
        .then((response) => {
          if (response.data.status_code === 200) {
            setLoading(false);
            let selectedSubject = response.data.result?.results?.filter(
              (item) => item.subject.id === value?.subject?.id
            );
            setGradeSubjectMapping(selectedSubject[0]?.id);
          }
        });
      axiosInstance
        .get(`${endpoints.masterManagement.boardMappingList}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setBoardDropdown(result.data.result);
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
            setBoardDropdown([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
          setBoardDropdown([]);
        });
    } else {
      setLoading(false);
      setBoardDropdown([]);
      setFilterData({
        year: filterData?.year,
        volume: filterData?.volume,
        grade: filterData?.grade,
        subject: '',
        board: '',
        chapter: '',
        module: '',
      });
    }
  };

  // const handleSubject = (event, value) => {
  //     setFilterData({ ...filterData, subject: '' });
  //     if (value) {
  //         setFilterData({ ...filterData, subject: value });
  //     }
  // };

  const handleBoard = (event, value) => {
    setFilterData({ ...filterData, board: '' });
    if (value) {
      setLoading(true);
      setFilterData({ ...filterData, board: value });
      setAddmoduleButton(false);
      axiosInstance
        .get(
          `${endpoints.masterManagement.module}?academic_year=${filterData?.year?.id}&volume=${filterData?.volume?.id}&grade_subject=${filterData?.subject?.id}&board=${value?.id}&page_size=100`
        )
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setModuleDropdown(result.data.result);
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
            setModuleDropdown([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
          setModuleDropdown([]);
        });
    } else {
      setLoading(false);
      setAddmoduleButton(true);
      setModuleDropdown([]);
      setFilterData({
        year: filterData?.year,
        volume: filterData?.volume,
        grade: filterData?.grade,
        subject: filterData?.subject,
        board: '',
        chapter: '',
        module: '',
      });
    }
  };
  const handleBoards = (value) => {
    setAddmoduleButton(false);
    // setFilterData({ ...filterData, board: '' });
    console.log('valueBoard', value);

    if (value) {
      console.log('valueBoard1', value);

      setFilterData({ ...filterData, board: value });
      setAddmoduleButton(true);

      axiosInstance
        .get(
          `${endpoints.masterManagement.module}?academic_year=${filterData?.year?.id}&volume=${filterData?.volume?.id}&grade_subject=${filterData?.subject?.id}&board=${value?.id}`
        )
        .then((result) => {
          if (result.data.status_code === 200) {
            console.log(result.data.result);
            setModuleDropdown(result.data.result);
          } else {
            setAlert('error', result.data.message);
            setModuleDropdown([]);
          }
        })
        .catch((error) => {
          setAlert('error', error.message);
          setModuleDropdown([]);
        });
    }
  };
  const handleModule = (event, value) => {
    setFilterData({ ...filterData, module: '' });

    if (value) {
      setAddChapterButton(false);

      setFilterData({ ...filterData, module: value });
    } else {
      setFilterData({
        year: filterData?.year,
        volume: filterData?.volume,
        grade: filterData?.grade,
        subject: filterData?.subject,
        board: filterData?.board,
        module: '',
        chapter: '',
      });
      setAddmoduleButton(true);
    }
  };
  const handleModules = (value) => {
    // setFilterData({ ...filterData, module: '' })

    if (value) {
      setAddChapterButton(false);
      setFilterData({ ...filterData, module: value });
    } else {
      setFilterData({
        year: filterData?.year,
        volume: filterData?.volume,
        grade: filterData?.grade,
        subject: filterData?.subject,
        board: filterData?.board,
        module: '',
        chapter: '',
      });
      setAddmoduleButton(true);
    }
  };
  const handleChapter = (event, value) => {
    setFilterData({ ...filterData, chapter: '' });
    if (value) {
      setFilterData({ ...filterData, chapter: value });
      handleFilter();
    } else {
      setFilterData({
        year: filterData?.year,
        volume: filterData?.volume,
        grade: filterData?.grade,
        subject: filterData?.subject,
        board: filterData?.board,
        module: filterData?.module,
        chapter: '',
      });
      setAddChapterButton(true);
    }
  };

  return (
    <div>
      <Dialog open={openChapter} onClose={handleCloseChapter}>
        <form onSubmit={createKeyconcepthandleSubmit}>
          <DialogTitle>Add </DialogTitle>
          <DialogContent>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  size='small'
                  onChange={handleAcademicYear}
                  style={{ width: '100%' }}
                  id='grade'
                  options={academicYearDropdown}
                  value={filterData.year}
                  getOptionLabel={(option) => option?.session_year}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Academic Year'
                      placeholder='Academic Year'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  size='small'
                  onChange={handleVolume}
                  style={{ width: '100%' }}
                  id='grade'
                  options={volumeDropdown}
                  value={filterData.volume}
                  getOptionLabel={(option) => option?.volume_name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Volume'
                      placeholder='Volume'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  size='small'
                  onChange={handleGrade}
                  style={{ width: '100%' }}
                  id='grade'
                  options={gradeDropdown}
                  value={filterData.grade}
                  getOptionLabel={(option) => option?.grade_name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Grades'
                      placeholder='Grades'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  size='small'
                  onChange={handleSubject}
                  style={{ width: '100%' }}
                  id='grade'
                  options={subjectDropdown}
                  value={filterData.subject}
                  getOptionLabel={(option) => option?.subject?.subject_name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Subject'
                      placeholder='Subject'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  size='small'
                  onChange={handleBoard}
                  style={{ width: '100%' }}
                  id='board'
                  options={boardDropdown}
                  value={filterData.board}
                  getOptionLabel={(option) => option?.board_name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Board'
                      placeholder='Board'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={3} sm={3} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  size='small'
                  onChange={handleModule}
                  style={{ width: '100%' }}
                  id='module'
                  options={moduleDropdown}
                  value={filterData.module}
                  getOptionLabel={(option) => option?.lt_module_name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Module'
                      placeholder='Module'
                      required
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>

            <Grid container spacing={5}>
              <Grid item xs={12} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                <TextField
                  id='chname'
                  style={{ width: '100%' }}
                  label='Chapter Name'
                  variant='outlined'
                  size='small'
                  value={chapter_name}
                  inputProps={{ accept: '^[a-zA-Z0-9 +_-]+', maxLength: 100 }}
                  //inputProps={{pattern:'^[a-zA-Z0-9]+',maxLength:100}}
                  name='chname'
                  onChange={(e) => setChapter_name(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                <TextField
                  id='chaptersequencenumber'
                  type='number'
                  label='Chapter Sequence Number'
                  placeholder='Enter Chapter Sequence Number'
                  onChange={(e) => setChapterSequence(e.target.value)}
                  style={{ width: '100%' }}
                  variant='outlined'
                  size='small'
                  SelectProps={{
                    native: true,
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              spacing={isMobile ? 1 : 5}
              style={{ width: '95%', margin: '10px' }}
            >
              <Grid
                item
                xs={6}
                sm={2}
                className={isMobile ? '' : 'addEditButtonsPadding'}
              >
                <Button
                  variant='contained'
                  className='custom_button_master labelColor'
                  size='medium'
                  onClick={handleCloseChapter}
                >
                  Back
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2}
                className={isMobile ? '' : 'addEditButtonsPadding'}
              >
                <Button
                  variant='contained'
                  style={{ color: 'white' }}
                  color='primary'
                  className='custom_button_master'
                  size='medium'
                  type='submit'
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog>
      {/* <Dialog open={openModule} onClose={handleClose}>
        <form onSubmit={createChapterhandleSubmit}>
          <DialogTitle>Add Module</DialogTitle>
          <DialogContent>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  size='small'
                  onChange={handleAcademicYear}
                  style={{ width: '100%' }}
                  id='grade'
                  options={academicYearDropdown}
                  value={filterData.year}
                  getOptionLabel={(option) => option?.session_year}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Academic Year'
                      placeholder='Academic Year'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  size='small'
                  onChange={handleVolume}
                  style={{ width: '100%' }}
                  id='grade'
                  options={volumeDropdown}
                  value={filterData.volume}
                  getOptionLabel={(option) => option?.volume_name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Volume'
                      placeholder='Volume'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  size='small'
                  onChange={handleGrade}
                  style={{ width: '100%' }}
                  id='grade'
                  options={gradeDropdown}
                  value={filterData.grade}
                  getOptionLabel={(option) => option?.grade_name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Grades'
                      placeholder='Grades'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  size='small'
                  onChange={handleSubject}
                  style={{ width: '100%' }}
                  id='grade'
                  options={subjectDropdown}
                  value={filterData.subject}
                  getOptionLabel={(option) => option?.subject?.subject_name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Subject'
                      placeholder='Subject'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} sm={6} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  size='small'
                  onChange={handleBoard}
                  style={{ width: '100%' }}
                  id='board'
                  options={boardDropdown}
                  value={filterData.board}
                  getOptionLabel={(option) => option?.board_name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Board'
                      placeholder='Board'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                <TextField
                  id='sessionyear'
                  label='Module Name'
                  style={{ width: '100%' }}
                  variant='outlined'
                  size='small'
                  placeholder='Enter Module name'
                  value={module_name}
                  // inputProps={{maxLength:7,pattern:'^[0-9]{4}-[0-9]{2}'}}
                  name='Module_Name'
                  onChange={(e) => setModule_name(e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={8} className={isMobile ? '' : 'addEditPadding'}>
                <TextField
                  id='modulesequencenumber'
                  type='number'
                  label='Module Sequence Number'
                  placeholder='Enter Module Sequence Number'
                  onChange={(e) => setModuleSequenceNumber(e.target.value)}
                  style={{ width: '100%' }}
                  variant='outlined'
                  size='small'
                  SelectProps={{
                    native: true,
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid
              container
              spacing={isMobile ? 1 : 5}
              style={{ width: '95%', margin: '10px' }}
            >
              <Grid
                item
                xs={6}
                sm={2}
                className={isMobile ? '' : 'addEditButtonsPadding'}
              >
                <Button
                  variant='contained'
                  className='custom_button_master labelColor'
                  size='medium'
                  onClick={handleClose}
                >
                  Back
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                sm={2}
                className={isMobile ? '' : 'addEditButtonsPadding'}
              >
                <Button
                  variant='contained'
                  style={{ color: 'white' }}
                  color='primary'
                  className='custom_button_master'
                  size='medium'
                  type='submit'
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </form>
      </Dialog> */}

      <form autoComplete='off' onSubmit={handleSubmit}>
        <div style={{ width: '95%', margin: '20px auto' }}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
              <Autocomplete
                size='small'
                onChange={handleAcademicYear}
                style={{ width: '100%' }}
                id='grade'
                options={academicYearDropdown}
                value={filterData.year}
                getOptionLabel={(option) => option?.session_year}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Academic Year'
                    placeholder='Academic Year'
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
              <Autocomplete
                size='small'
                onChange={handleVolume}
                style={{ width: '100%' }}
                id='grade'
                options={volumeDropdown}
                value={filterData.volume}
                getOptionLabel={(option) => option?.volume_name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Volume'
                    placeholder='Volume'
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
              <Autocomplete
                size='small'
                onChange={handleGrade}
                style={{ width: '100%' }}
                id='grade'
                options={gradeDropdown}
                value={filterData.grade}
                getOptionLabel={(option) => option?.grade_name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Grades'
                    placeholder='Grades'
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
              <Autocomplete
                size='small'
                onChange={handleSubject}
                style={{ width: '100%' }}
                id='grade'
                options={subjectDropdown}
                value={filterData.subject}
                getOptionLabel={(option) => option?.subject?.subject_name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Subject'
                    placeholder='Subject'
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
              <Autocomplete
                size='small'
                onChange={handleBoard}
                style={{ width: '100%' }}
                id='board'
                options={boardDropdown}
                value={filterData.board}
                getOptionLabel={(option) => option?.board_name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Board'
                    placeholder='Board'
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
              <Autocomplete
                size='small'
                onChange={handleModule}
                style={{ width: '100%' }}
                id='module'
                options={moduleDropdown}
                value={filterData.module}
                getOptionLabel={(option) => option?.lt_module_name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Module'
                    placeholder='Module'
                    required
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={1} sm={1} className={isMobile ? '' : 'addEditPadding'}>
              {!AddmoduleButton && (
                <Button
                  startIcon={<AddOutlinedIcon style={{ fontSize: '30px' }} />}
                  variant='contained'
                  color='primary'
                  size='small'
                  style={{ color: 'white' }}
                  title='Add Chapter'
                  onClick={handleClickOpen}
                ></Button>
              )}
            </Grid> */}
            <Grid item xs={12} sm={3} className={isMobile ? '' : 'addEditPadding'}>
              <Autocomplete
                size='small'
                onChange={handleChapter}
                style={{ width: '100%' }}
                id='grade'
                options={chapterDropdown}
                value={filterData.chapter}
                getOptionLabel={(option) => option?.chapter_name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Chapter'
                    placeholder='Chapter'
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={1} sm={1} className={isMobile ? '' : 'addEditPadding'}>
              {!AddChapterButton && (
                <Button
                  startIcon={<AddOutlinedIcon style={{ fontSize: '30px' }} />}
                  variant='contained'
                  color='primary'
                  size='small'
                  style={{ color: 'white' }}
                  title='Add KeyConsept'
                  onClick={handleClickOpenChapter}
                ></Button>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
              <TextField
                id='topic-name'
                style={{ width: '100%' }}
                label='KeyConcept Name'
                variant='outlined'
                size='small'
                value={topicName}
                placeholder='Enter KeyConcept Name'
                inputProps={{ accept: '^[a-zA-Z0-9 +_-]+', maxLength: 100 }}
                //inputProps={{pattern:'^[a-zA-Z0-9]+',maxLength:100}}
                name='topic-name'
                onChange={(e) => setTopicName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
              <TextField
                id='keyconceptsequencenumber'
                type='number'
                value={keyConceptSequence || ''}
                label='Key Concept Sequence Number'
                placeholder='Enter Key Concept Sequence Number'
                onChange={(e) => setKeyConceptSequence(e.target.value)}
                style={{ width: '100%' }}
                variant='outlined'
                size='small'
                SelectProps={{
                  native: true,
                }}
              />
            </Grid>
          </Grid>
        </div>
        <Grid
          container
          spacing={isMobile ? 1 : 5}
          style={{ width: '95%', margin: '10px' }}
        >
          <Grid item xs={6} sm={2} className={isMobile ? '' : 'addEditButtonsPadding'}>
            <Button
              variant='contained'
              className='custom_button_master labelColor'
              size='medium'
              onClick={handleGoBack}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={6} sm={2} className={isMobile ? '' : 'addEditButtonsPadding'}>
            <Button
              variant='contained'
              style={{ color: 'white' }}
              color='primary'
              className='custom_button_master'
              size='medium'
              type='submit'
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
      {!isMobile ? (
        <>
          {!tableFlag && (
            <Paper className={`${classes.root} common-table`}>
              <TableContainer className={classes.container}>
                <Table stickyHeader aria-label='sticky table'>
                  <TableHead className='table-header-row'>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                          className={classes.columnHeader}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {topicList.map((topic, index) => {
                      return (
                        <TableRow hover subject='checkbox' tabIndex={-1} key={index}>
                          <TableCell className={classes.tableCell}>
                            {topic.topic_name}
                          </TableCell>
                          <TableCell className={classes.tableCell}>
                            {topic.chapter?.chapter_name}
                          </TableCell>
                          {/* <TableCell className={classes.tableCell}>
                                <IconButton
                                  onClick={(e) => {
                                    // handleDelete(topic);
                                  }}
                                  title='Delete Key Concept'
                                >
                                  <DeleteOutlinedIcon style={{ color: '#fe6b6b' }} />
                                </IconButton>
                                <IconButton
                                //   onClick={(e) => handleEditSubject(topic)}
                                  title='Edit Key Concept'
                                >
                                  <EditOutlinedIcon style={{ color: '#fe6b6b' }} />
                                </IconButton>
                              </TableCell> */}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className='paginateData'>
                <TablePagination
                  component='div'
                  count={totalCount}
                  className='customPagination'
                  rowsPerPage={limit}
                  page={page - 1}
                  onChangePage={handleChangePage}
                  rowsPerPageOptions={false}
                />
              </div>
            </Paper>
          )}
        </>
      ) : (
        <>
          <>
            {!tableFlag && (
              <>
                {/* {topicList.map((topic) => (
                      <SubjectCard
                        data={topic}
                        handleDelete={handleDelete}
                        handleEditSubject={handleEditSubject}
                      /> */}
                {/* ))} */}
                <div className='paginateData paginateMobileMargin'>
                  <TablePagination
                    component='div'
                    count={totalCount}
                    rowsPerPage={limit}
                    page={page - 1}
                    onChangePage={handleChangePage}
                    rowsPerPageOptions={false}
                    className='table-pagination'
                  />
                </div>
              </>
            )}
          </>
        </>
      )}
    </div>
  );
};

export default CreateTopic;
