import React, { useContext, useEffect, useState } from 'react';
import Divider from '@material-ui/core/Divider';
import { Grid, TextField, Button, useTheme, SvgIcon } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AlertNotificationContext } from '../../../../context-api/alert-context/alert-state';
import endpoints from '../../../../config/endpoints';
import axiosInstance from '../../../../config/axios';
import './top-filters.css';

const TopFilters = ({ setFilterDataDisplay, setIsFilter, setIsTopFilterOpen }) => {
  const { setAlert } = useContext(AlertNotificationContext);
  const themeContext = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const wider = isMobile ? '-10px 0px' : '-10px 0px 20px 8px';
  const widerWidth = isMobile ? '98%' : '95%';
  const [moduleDropdown, setModuleDropdown] = useState([]);


  const [filterData, setFilterData] = useState({
    grade: '',
    subject: '',
    chapter: '',
    topic: '',
    module: '',
  });

  const [dropdownData, setDropdownData] = useState({
    grades: [],
    subjects: [],
    chapters: [],
    topics: [],
  });

  useEffect(() => {
    axiosInstance
      .get(`${endpoints.lessonPlan.gradeList}`)
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setDropdownData({
            ...dropdownData,
            grades: result?.data?.result?.results,
            subjects: [],
            chapters: [],
            topics: [],
          });
        } else {
          setAlert('error', result?.data?.message);
        }
      })
      .catch((error) => {
        setAlert('error', error?.message);
      });
      // getModules()
  }, []);

  const getModules = (gradeSubjectId) => {
    axiosInstance
        .get(`${endpoints.masterManagement.module}?grade_subject=${gradeSubjectId || filterData?.subject?.id}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            // setLoading(false)
            setModuleDropdown(result.data.result);
          } else {
            // setLoading(false)
            setAlert('error', result.data.message)
          }
        })
        .catch((error) => {
          // setLoading(false)
          setAlert('error', error.message);
        })
  }

  const handleGrade = (event, value) => {
    setFilterData({ ...filterData, grade: '', subject: '', chapter: '', topic: '' });
    setDropdownData({ ...dropdownData, subjects: [], chapters: [], topics: [] });
    if (value) {
      setFilterData({ ...filterData, grade: value, subject: '', chapter: '', topic: '' });
      axiosInstance
        .get(`${endpoints.lessonPlan.gradeSubjectMappingList}?grade=${value?.id}`)
        .then((result) => {
          if (result?.data?.status_code === 200) {
            setDropdownData({
              ...dropdownData,
              subjects: result?.data?.result?.results,
              chapters: [],
              topics: [],
            });
          } else {
            setAlert('error', result?.data?.message);
            setDropdownData({ ...dropdownData, subjects: [], chapters: [], topics: [] });
          }
        })
        .catch((error) => {
          setAlert('error', error?.message);
          setDropdownData({ ...dropdownData, subjects: [], chapters: [], topics: [] });
        });
    }
  };

  const getChapters = (moduleId) => {
    axiosInstance
    .get(`${endpoints.lessonPlan.chapterList}?lt_module=${moduleId}`)
    .then((result) => {
      if (result?.data?.status_code === 200) {
        setDropdownData({
          ...dropdownData,
          chapters: result?.data?.result,
          topics: [],
        });
      } else {
        setAlert('error', result?.data?.message);
        setDropdownData({ ...dropdownData, chapters: [], topics: [] });
      }
    })
    .catch((error) => {
      setAlert('error', error?.message);
      setDropdownData({ ...dropdownData, chapters: [], topics: [] });
    });
  }

  const handleSubject = (event, value) => {
    setFilterData({ ...filterData, subject: '', module : '', chapter: '', topic: '' });
    setDropdownData({ ...dropdownData, chapters: [], topics: [] });
    setModuleDropdown([])
    if (value) {
      setFilterData({ ...filterData, subject: value, module : '', chapter: '', topic: '' });
      getModules(value?.id)
    }
  };

  const handleModule = (event,value) => {
    
    setFilterData({ ...filterData , module : '' , chapter: '', topic: '' });
    setDropdownData({ ...dropdownData, chapters: [], topics: [] });
    if(value){
      setFilterData({ ...filterData, module : value, chapter: '', topic: '' });
      getChapters(value?.id)
    }
  }

  const handleChapter = (event, value) => {
    setFilterData({ ...filterData, chapter: '', topic: '' });
    setDropdownData({ ...dropdownData, topics: [] });
    if (value) {
      setFilterData({ ...filterData, chapter: value, topic: '' });
      if (value) {
        axiosInstance
          .get(`${endpoints.createQuestionApis.topicList}?chapter=${value?.id}&page_size=${50}`)
          .then((result) => {
            if (result?.data?.status_code === 200) {
              setDropdownData({ ...dropdownData, topics: result?.data?.result });
            } else {
              setAlert('error', result?.data?.message);
              setDropdownData({ ...dropdownData, topics: [] });
            }
          })
          .catch((error) => {
            setAlert('error', error?.message);
            setDropdownData({ ...dropdownData, topics: [] });
          });
      }
    }
  };

  const handleTopic = (event, value) => {
    setFilterData({ ...filterData, topic: '' });
    if (value) {
      setFilterData({ ...filterData, topic: value });
    }
  };

  const handleClear = () => {
    setFilterData({
      grade: '',
      subject: '',
      chapter: '',
      topic: '',
    });
    setFilterDataDisplay({
      grade: '',
      subject: '',
      chapter: '',
      topic: '',
    });
    setDropdownData({
      ...dropdownData,
      subjects: [],
      chapters: [],
      topics: [],
    });
    setIsFilter(false);
  };

  const handleFilter = () => {
    if (!filterData?.grade) {
      setAlert('warning', 'Please select grade!');
      return;
    }
    if (!filterData?.subject) {
      setAlert('warning', 'Please select subject!');
      return;
    }
    if (!filterData?.chapter) {
      setAlert('warning', 'Please select chapter!');
      return;
    }

    setIsFilter(true);
    setFilterDataDisplay(filterData);
    setIsTopFilterOpen(false);
  };

  return (
    <Grid
      container
      spacing={isMobile ? 3 : 5}
      style={{ width: widerWidth, margin: wider }}
    >
      <Grid item xs={12} sm={3} className={isMobile ? '' : 'filterPadding'}>
        <Autocomplete
          style={{ width: '100%' }}
          size='small'
          onChange={handleGrade}
          id='grade'
          className='dropdownIcon'
          value={filterData?.grade || {}}
          options={dropdownData?.grades || []}
          getOptionLabel={(option) => option?.grade_name || ''}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField {...params} variant='outlined' label='Grade' placeholder='Grade' />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3} className={isMobile ? '' : 'filterPadding'}>
        <Autocomplete
          style={{ width: '100%' }}
          size='small'
          onChange={handleSubject}
          id='subject'
          className='dropdownIcon'
          value={filterData?.subject || {}}
          options={dropdownData?.subjects || []}
          getOptionLabel={(option) => option?.subject?.subject_name || ''}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              label='Subject'
              placeholder='Subject'
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3} className={isMobile ? '' : 'filterPadding'}>
        <Autocomplete
          // multiple
          style={{ width: '100%' }}
          size='small'
          onChange={handleModule}
          id='module'
          className="dropdownIcon"
          value={filterData.module || []}
          options={moduleDropdown || []}
          getOptionLabel={(option) => option?.lt_module_name || ''}
          filterSelectedOptions
          getOptionSelected={(option, value) =>
            option?.id == value?.id
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              label='Module'
              placeholder='Module'
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3} className={isMobile ? '' : 'filterPadding'}>
        <Autocomplete
          style={{ width: '100%' }}
          size='small'
          onChange={handleChapter}
          id='chapter'
          className='dropdownIcon'
          value={filterData?.chapter || {}}
          options={dropdownData?.chapters || []}
          getOptionLabel={(option) => option?.chapter_name || ''}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              label='Chapter'
              placeholder='Chapter'
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3} className={isMobile ? '' : 'filterPadding'}>
        <Autocomplete
          style={{ width: '100%' }}
          size='small'
          onChange={handleTopic}
          id='topic'
          className='dropdownIcon'
          value={filterData?.topic || {}}
          options={dropdownData?.topics || []}
          getOptionLabel={(option) => option?.topic_name || ''}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField {...params} variant='outlined' label='Key Concept' placeholder='Key Concept' />
          )}
        />
      </Grid>
      {!isMobile && (
        <Grid item xs={12} sm={12}>
          <Divider />
        </Grid>
      )}
      {isMobile && <Grid item xs={3} sm={0} />}
      <Grid item xs={6} sm={2} className={isMobile ? '' : 'addButtonPadding'}>
        <Button
          variant='contained'
          className='custom_button_master labelColor modifyDesign'
          size='medium'
          onClick={handleClear}
        >
          CLEAR ALL
        </Button>
      </Grid>
      {isMobile && <Grid item xs={3} sm={0} />}
      {isMobile && <Grid item xs={3} sm={0} />}
      <Grid item xs={6} sm={2} className={isMobile ? '' : 'addButtonPadding'}>
        <Button
          variant='contained'
          style={{ color: 'white' }}
          color='primary'
          className='custom_button_master modifyDesign'
          size='medium'
          onClick={handleFilter}
        >
          NEXT
        </Button>
      </Grid>
      {isMobile && <Grid item xs={3} sm={0} />}
    </Grid>
  );
};

export default TopFilters;
