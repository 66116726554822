import { Button, Card, Form, Modal, Select, Input } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AlertNotificationContext } from '../../../../context-api/alert-context/alert-state';
import axiosInstance from '../../../../config/axios';
import endpoints from '../../../../config/endpoints';
import Question from './questiontype/question';
import { categories, levels, questiontype } from './const';
import Comprehensive from './questiontype/comprehensive';
import Mcq from './questiontype/mcq';
import Descriptive from './questiontype/descriptive';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import ConfirmPopOver from '../assessment-view/assesment-card/ConfirmPopOver';

const { Option } = Select;

const QuestionFilters = ({ parentIndex, question, grade, questionList, setQuestions, questionPaperWise, filterArr, setFilterArr }) => {
  const { setAlert } = useContext(AlertNotificationContext);
  const formRef = useRef();
  const [subjectDropdown, setSubjectDropdown] = useState([]);
  const [modulesDropdown, setModuleDropDown] = useState([]);
  const [chapterDropdown, setChapterDropdown] = useState([]);
  const [topicDropDown, setTopicDropDown] = useState([]);
  const [categoryDropDown, setCategoryDropdown] = useState(categories)
  const [levelDropDown, setLevelDropdown] = useState(levels)
  const [queTypeDropdown, setQueTypeDropdown] = useState([]);
  const [showQuestionType, setShowQuestionType] = useState("")
  const [comprehensivetype, setComprehensivetype] = useState([])
  //eslint-disable-next-line
  const [falseState, setFalseState] = useState("")
  // console.log(question, `question ${parentIndex}`);
  const [openModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [deleteIndex, setDeleteIndex] = useState('');
  const [confirmMessage, setConfirmMessage] = useState();
  useEffect(() => {
    fetchSubjectData();
    // getQuestionTypes();
    filterArr?.subject?.value && fetchModule(filterArr?.subject?.value)
    filterArr?.module?.value && fetchChapter(filterArr?.module?.value)
    filterArr?.chapter?.value && fetchTopic(filterArr?.chapter?.value)
  }, []);

  useEffect(() => {
    setShowQuestionType(question?.question_type)
  },[questionList])


  const fetchSubjectData = () => {
    axiosInstance
      .get(
        `${endpoints.lessonPlan.gradeSubjectMappingList}?grade=${grade?.id}` //?session_year=${acadSessionIds}
      )
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setSubjectDropdown(result.data.result.results);
        } else {
          setAlert('error', result?.data?.message);
        }
      })
      .catch((error) => {
        setAlert('error', error?.message);
      });
  };
  const fetchModule = (subjectid) => {
    axiosInstance
      .get(`${endpoints.masterManagement.module}?grade_subject=${subjectid}`) //?grade_subject=${filterData?.subject?.id}&board=${selectedId}&page_size=${50}
      .then((result) => {
        if (result.data.status_code === 200) {
          // setLoading(false)
          setModuleDropDown(result.data.result);
        } else {
          // setLoading(false)
          setAlert('error', result.data.message);
          // setSelectedBoardId([])
        }
      })
      .catch((error) => {
        // setLoading(false)
        setAlert('error', error.message);
        // setSelectedBoardId([]);
      });
  };

  const fetchChapter = (moduleId) => {
    axiosInstance
      .get(`${endpoints.lessonPlan.chapterList}?lt_module=${moduleId}`) //grade_subject=${filterData.subject.id}&academic_year=${filterData.year.id}
      .then((result) => {
        if (result.data.status_code === 200) {
          // setLoading(false)
          setChapterDropdown(result.data.result);
        } else {
          // setLoading(false)
          setAlert('error', result.data.message);
          setChapterDropdown([]);
        }
      })
      .catch(error => {
        // setLoading(false)
        setAlert('error', error.message);
        setChapterDropdown([]);
      })
    }

    const fetchTopic = (chapterId) => {
      // setLoading(true);
      axiosInstance
      .get(`${endpoints.questionBank.topics}?chapter=${chapterId}&page_size=50`)
        .then((result) => {
          if (result?.data?.status_code === 200) {
            setTopicDropDown(result?.data?.result);
            //   setLoadingInner(false);
          } else {
            //   setLoadingInner(false);/
            setTopicDropDown([])
          }
        })
        .catch((error) => {
          setAlert('error',error.message);
          // setLoadingInner(false);
        });
    };

    // const getQuestionTypes = () => {
    //   axiosInstance
    //     .get(`${endpoints.createQuestionApis.questionType}`)
    //     .then((result) => {
    //       if (result?.data?.status_code === 200) {
    //         setQueTypeDropdown(result?.data?.result?.filter((obj) => obj?.id !== 5));
    //         // setLoading(false);
    //       } else {
    //         setAlert('error', result?.data?.message);
    //       }
    //     })
    //     .catch((error) => {
    //       setAlert('error', error?.message);
    //     });
    // };

    const handleSubject = (value,queId) => {
      if(value){
        let subject = subjectDropdown?.filter((item) => item?.id == value?.value)
        let questions = questionList
       let index = questions.findIndex((item) => item?.id == queId)
       questions[index].subject = value
       questions[index].chapter = ''
       questions[index].module = ''
       questions[index].topic = ''
       setChapterDropdown([])
       setModuleDropDown([]);
       setTopicDropDown([])
       setQuestions(questions)
      //  setSelectedSubject(value)
       fetchModule(subject[0]?.id)
        filterArr.subject = value

        filterArr.module = ''
        filterArr.chapter = ''
        filterArr.topic = ''
      //  formik.setFieldValue('subject', value)
      }else{
        let questions = questionList
        let index = questions.findIndex((item) => item?.id == queId)
        questions[index].chapter = ''
        questions[index].module = ''
        questions[index].topic = ''
        setChapterDropdown([])
        setModuleDropDown([]);
        setTopicDropDown([])
        questions[index].subject = ''

        filterArr.module = ''
        filterArr.chapter = ''
        filterArr.topic = ''

       setQuestions(questions)
      //  formik.setFieldValue('subject', '')

      }
}

const handleModule = (value,queId) => {
  if(value){
    let module = modulesDropdown?.filter((item) => item?.id == value?.value)
    let questions = questionList
    let index = questions.findIndex((item) => item?.id == queId)
    questions[index].module = value
    questions[index].chapter = ''
    questions[index].topic = ''
    setChapterDropdown([])
    setTopicDropDown([])
    setQuestions(questions)
    fetchChapter(module[0]?.id)
    filterArr.module = value

    filterArr.chapter = ''
    filterArr.topic = ''
  }else{
    let questions = questionList
    let index = questions.findIndex((item) => item?.id == queId)
    questions[index].chapter = ''
    questions[index].topic = ''

    filterArr.chapter = ''
    filterArr.topic = ''
    setQuestions(questions)
  }
}

const handleChapter = (value,queId) => {
  if(value){
    let chapter = chapterDropdown?.filter((item) => item?.id == value?.value)
    let questions = questionList
    let index = questions.findIndex((item) => item?.id == queId)
    questions[index].chapter = value
    questions[index].topic = ''
    setTopicDropDown([])
    setQuestions(questions)
    fetchTopic(chapter[0]?.id)
    filterArr.chapter = value

    filterArr.topic = ''
  }else{
    let questions = questionList
    let index = questions.findIndex((item) => item?.id == queId)
    questions[index].topic = ''
    setTopicDropDown([])
    setQuestions(questions)
    filterArr.topic = ''

  }
}

const handleTopic = (value,queId) => {
  if(value){
    let topic = topicDropDown?.filter((item) => item?.id == value?.value)
    let questions = questionList
   let index = questions.findIndex((item) => item?.id == queId)
   questions[index].topic = value
   setQuestions(questions)
   filterArr.topic = value
   setFalseState(value)
  }else{
    let questions = questionList
   let index = questions.findIndex((item) => item?.id == queId)
   questions[index].topic = ''
   setQuestions(questions)
  }
}
const handleCategory = (value, queId) => {
  if (value) {
    let ctegory = categoryDropDown?.filter((item) => item?.id == value?.value);
    let questions = questionList;
    let index = questions.findIndex((item) => item?.id == queId);
    questions[index].question_categories = value;
    setQuestions(questions);
    filterArr.category = value
  } else {
    let questions = questionList;
    let index = questions.findIndex((item) => item?.id == queId);
    questions[index].question_categories = '';
    setQuestions(questions);
  }
};

const handleLevel = (value, queId) => {
  if (value) {
    let level = levelDropDown?.filter((item) => item?.id == value?.value);
    let questions = questionList;
    let index = questions.findIndex((item) => item?.id == queId);
    questions[index].question_level = value;
    setQuestions(questions);
    filterArr.level = value
  } else {
    let questions = questionList;
    let index = questions.findIndex((item) => item?.id == queId);
    questions[index].question_level = '';
    setQuestions(questions);
  }
};

const handleQustionType = (value, queId) => {
  if (value) {
    let quetype = queTypeDropdown?.filter((item) => item?.id == value?.value);
    let questions = questionList;
    let index = questions.findIndex((item) => item?.id == queId);
    questions[index].question_type = value;
    questions[index].question_answer = [{}];
    setQuestions(questions);
    setShowQuestionType(value)
  } else {
    let questions = questionList;
    let index = questions.findIndex((item) => item?.id == queId);
    questions[index].question_type = '';
    questions[index].question_answer = [{}];
    setQuestions(questions);
  }
};

// console.log(questionList, '!@#' , question.subject)

const handleMarks = (value, queId) => {
  let questions = [...questionList];
  let index = questions.findIndex((item) => item?.id == queId);
  questions[index].marks = value;
  setQuestions(questions);
}

const handleDeleteCompQuestion = (key, index) => {
  let filteredQuestion = comprehensivetype?.filter((item, i) => i!= index);
  let list = [...questionList]
  let subQuestions = list[parentIndex].subquestions;
  let filteredSubquestion = subQuestions?.filter((item, ind) => ind !== index);
  list[parentIndex].subquestions = filteredSubquestion
  setComprehensivetype(filteredQuestion);
  setQuestions(list);
  setAlert('success', 'Question Deleted successfully')
};

const handleComprehensivetype = (value) => {
  const QuestionObj = {
    question_type: value.value,
    question_answer: [{}],
    // marks : '',
  }
  let data = value
  let list = [...questionList]
  let subQuestions = list[parentIndex].subquestions
  subQuestions.push(QuestionObj)
  setQuestions(list)
  // data['QuestionObj'] = QuestionObj
  setComprehensivetype([...comprehensivetype, data]);
}


const handleClearSubject = (queId) => {
  let questions = questionList
  let index = questions.findIndex((item) => item?.id == queId)
  questions[index].subject = ''
  questions[index].chapter = ''
  questions[index].module = ''
  questions[index].topic = ''
  setChapterDropdown([])
  setModuleDropDown([]);
  setTopicDropDown([])
  setQuestions(questions)

  filterArr.subject = ''
  filterArr.module = ''
  filterArr.chapter = ''
  filterArr.topic = ''
}

const handleClearModule = (queId) => {
  let questions = questionList
  let index = questions.findIndex((item) => item?.id == queId)
  questions[index].module = ''
  questions[index].chapter = ''
  questions[index].topic = ''
  setChapterDropdown([])
  setTopicDropDown([])
  setQuestions(questions)

  filterArr.module = ''
  filterArr.chapter = ''
  filterArr.topic = ''
}


const handleClearChapter = (queId) => {
  let questions = questionList
  let index = questions.findIndex((item) => item?.id == queId)
  questions[index].chapter = ''
  questions[index].topic = ''
  setTopicDropDown([])
  setQuestions(questions)

  filterArr.chapter = ''
  filterArr.topic = ''
}

const handleClearTopic = (queId) => {
  let questions = questionList
  let index = questions.findIndex((item) => item?.id == queId)
  questions[index].topic = ''
  setQuestions(questions)
  filterArr.topic = ''
  setFalseState(queId)
}


    const subjectOptions = subjectDropdown?.map((each) => {
      return (
        <Option key={each?.subject?.id} value={each?.id}>
          {each?.subject?.subject_name}
        </Option>
      );
    });

    const moduleOptions = modulesDropdown?.map((each) => {
      return (
        <Option key={each?.id} value={each.id}>
          {each?.lt_module_name}
        </Option>
      );
    })
    const chapterOption = chapterDropdown?.map((each) => {
      return (
        <Option key={each?.id} value={each.id}>
          {each?.chapter_name}
        </Option>
      );
    });

    const topicOption = topicDropDown?.map((each) => {
      return (
        <Option key={each?.id} value={each.id}>
          {each?.topic_name}
        </Option>
      );
    });

    const questionTypes = questiontype?.map((each) => {
      return (
        <Option key={each?.key} value={each.value}>
          {each?.children}
        </Option>
      );
    });

    const qtypeforcomp = questiontype?.map((each) => {
      return (
        <>
          {each.children !== 'Comprehensive' && (
          <Option key={each?.key} value={each.value}>
            {each?.children}
          </Option>
          )}
        </>
      );
    });
    
  return (
   <>
           <div className='filters col-12 mt-2 px-0 py-2'>
           {/* <Form id='filterForm'  layout={'horizontal'}> */}
            <div className='row align-items-center'>
                <div className='col-md-4 col-6 mb-3 pl-1'>
                  {/* <div className='mb-2 text-left'>Topic</div> */}
                  {/* <Form.Item name='subject'> */}
                    <Select
                      allowClear={true}
                      placeholder='Subject'
                      showSearch
                      value={question?.subject?.children ? question?.subject?.children : filterArr?.subject?.children }
                      optionFilterProp='children'
                      getPopupContainer={(trigger) => trigger.parentNode}
                      filterOption={(input, options) => {
                        return (
                          options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                      onChange={(e, value) => {
                        handleSubject(value,question?.id);
                      }}
                      onClear={() => handleClearSubject(question?.id)}
                      className='w-100 text-left th-black-1 th-bg-grey th-br-4'
                      bordered={true}
                    >
                      {subjectOptions}
                    </Select>
                  {/* </Form.Item> */}
                </div>
                <div className='col-md-4  mb-3 col-6 pl-1'>
                  {/* <div className='mb-2 text-left'>Topic</div> */}
                  {/* <Form.Item name='module'> */}
                    <Select
                      allowClear={true}
                      placeholder='Module'
                      showSearch
                      value={question?.module?.children ? question?.module?.children : filterArr?.module?.children}
                      // disabled={user_level == 13}
                      optionFilterProp='children'
                      getPopupContainer={(trigger) => trigger.parentNode}
                      filterOption={(input, options) => {
                        return (
                          options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                      onChange={(e, value) => {
                        handleModule(value,question?.id);
                      }}
                      onClear={() => handleClearModule(question?.id)}
                      className='w-100 text-left th-black-1 th-bg-grey th-br-4'
                      bordered={true}
                    >
                      {moduleOptions}
                    </Select>
                  {/* </Form.Item> */}
                </div>
              {/* {boardFilterArr.includes(window.location.host) && ( */}
                <div className='col-md-4  mb-3 col-6 pl-1'>
                  {/* <Form.Item name='chapter'> */}
                    <Select
                      allowClear={true}
                      placeholder= {'Chapter'}
                      showSearch
                      value={question?.chapter?.children ? question?.chapter?.children : filterArr?.chapter?.children}
                      optionFilterProp='children'
                      getPopupContainer={(trigger) => trigger.parentNode}
                      filterOption={(input, options) => {
                        return (
                          options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                      onChange={(e, value) => {
                        handleChapter(value,question?.id);
                      }}
                      onClear={() => handleClearChapter(question?.id)}
                      className='w-100 text-left th-black-1 th-bg-grey th-br-4'
                      bordered={true}
                    >
                      {chapterOption}
                    </Select>
                  {/* </Form.Item> */}
                </div>
              {/* )} */}
              <div className='col-md-4  mb-3 col-6 pl-1'>
                  {/* <div className='mb-2 text-left'>Topic</div> */}
                  {/* <Form.Item name='topic'> */}
                  <Select
                      allowClear={true}
                      placeholder= {'Topic'}
                      showSearch
                      value={question?.topic?.children ? question?.topic?.children : filterArr?.topic?.children}
                      optionFilterProp='children'
                      getPopupContainer={(trigger) => trigger.parentNode}
                      filterOption={(input, options) => {
                        return (
                          options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                      onChange={(e, value) => {
                        handleTopic(value,question?.id);
                      }}
                      onClear={() => handleClearTopic(question?.id)}
                      className='w-100 text-left th-black-1 th-bg-grey th-br-4'
                      bordered={true}
                    >
                      {topicOption}
                    </Select>
                    
                  {/* </Form.Item> */}
                </div>
                <div className='col-md-4  mb-3 col-6 pl-1 '>
                {/* <div className='mb-2 text-left'>Category</div> */}
                {/* <Form.Item name='category'> */}
                  <Select
                    allowClear={false}
                    placeholder='Category'
                    showSearch
                    value={question?.question_categories.value ? question?.question_categories.value : filterArr?.category?.value}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    optionFilterProp='children'
                    filterOption={(input, options) => {
                      return (
                        options?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    onChange={(e, value) => {
                      handleCategory(value,question?.id);
                    }}
                    // onClear={handleClearSubject}
                    className='w-100 text-left th-black-1 th-bg-grey th-br-4'
                    bordered={true}
                    options={categories}
                  >
                    {/* {questioncategoryoptions} */}
                  </Select>
                {/* </Form.Item> */}
              </div>
              <div className='col-md-4  mb-3 col-6 pl-1'>
                {/* <div className='mb-2 text-left'>Level</div> */}
                {/* <Form.Item name='level'> */}
                  <Select
                    allowClear={false}
                    placeholder='Level'
                    showSearch
                    // disabled={user_level == 13}
                    value={question?.question_level.value ? question?.question_level.value : filterArr?.level?.value}
                    optionFilterProp='children'
                    getPopupContainer={(trigger) => trigger.parentNode}
                    filterOption={(input, options) => {
                      return (
                        options?.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    onChange={(e, value) => {
                      handleLevel(value, question?.id);
                    }}
                    // onClear={handleClearGrade}
                    className='w-100 text-left th-black-1 th-bg-grey th-br-4'
                    bordered={true}
                    options={levels}
                  >
                    {/* {questionLeveloptions} */}
                  </Select>
                {/* </Form.Item> */}
              </div>
              <div className='col-md-4  mb-3 col-6 pl-1'>
                {/* <div className='mb-2 text-left'>Level</div> */}
                {/* <Form.Item name='questype'> */}
                  <Select
                    allowClear={false}
                    placeholder='Question Type'
                    showSearch
                    // disabled={user_level == 13}
                    value={question?.question_type.children}
                    optionFilterProp='children'
                    getPopupContainer={(trigger) => trigger.parentNode}
                    filterOption={(input, options) => {
                      return (
                        options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      );
                    }}
                    onChange={(e, value) => {
                      handleQustionType(value,question?.id);
                    }}
                    // onClear={handleClearGrade}
                    className='w-100 text-left th-black-1 th-bg-grey th-br-4'
                    bordered={true}
                  >
                    {questionTypes}
                  </Select>
                {/* </Form.Item> */}
              </div>
              <div className='col-md-4  mb-3 col-6 pl-1'></div>
              {!questionPaperWise && <div className='col-md-4 d-flex justify-content-end mb-3 col-6 pl-1'>
              <div className='mr-2' style={{ color: '#00c040' }}>
                  Assign Marks
                </div>
              <Input
                  type='text'
                  maxLength={4}
                  // className='w-20 mx-2 text-center'
                  onChange={(e) => {
                    if(e?.target?.value && (e?.target?.value.match('[0-9]+') || e?.target?.value.match('[0-9][/.][0-9]') )){
                      // setMarks(e?.target?.value)
                      handleMarks(e.target.value, question?.id);
                      // return setAlert('error','Please Enter Valid Marks !')
                    }else{
                      // setMarks('')
                      return setAlert('error','Please Enter Valid Marks !')
                    }
                  }}
                  // value={marks}
                  pattern='\d*'
                  style={{
                    width: '52px',
                    height: '24px',
                    // background: enableMarks ? 'white' : '',
                  }}
                />
              </div>}
            </div>
            {/* <div className='row'>
            
            </div> */}
          {/* </Form> */}
          </div>
          <hr />
          <div className='row'>
            {showQuestionType?.children === "Comprehensive" ? (
              <>
                <Comprehensive parentIndex={parentIndex} comprehensivetype={comprehensivetype} setComprehensivetype={setComprehensivetype}  questionList={questionList} setQuestions={setQuestions} />         

                {Array.isArray(comprehensivetype) && comprehensivetype.length > 0 && (
                  comprehensivetype.map((item, index) => (
                    <Card bordered={true} className='options-card' style={{ width: '100%', backgroundColor: '#F1F4FB', borderRadius: 8 }}>
                    
                    <Question questionTypeProps = {item} childIndex={index} questionData = {question} parentIndex = {parentIndex} questionList={questionList} setQuestions={setQuestions} comptype='comp'  />

                    <Button className='mt-3' title='Delete this question' style={{ color: 'blue', cursor: 'pointer', float: 'right' }} 
                    // onClick={() => handleDeleteCompQuestion(item.key, index)}
                    onClick={() => {
                      // handleDeleteCompQuestion(item.key, index)}
                      setDeleteId(item.key)
                      setDeleteIndex(index)
                      setConfirmMessage('delete');
                      setOpenModal(true)
                    }}
                    >Delete Question</Button>

                    {openModal && (
                      <ConfirmPopOver
                        submit={() => handleDeleteCompQuestion(deleteId, deleteIndex)}
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                        operation={confirmMessage}
                        opendelete={true}
                      />
                    )}
                  </Card>
                  ))
                )}

                

                <div className='my-2' style={{width: '100%'}}>
                      <Card  bordered={true} className='options-card' style={{ width: '100%', backgroundColor: '#F1F4FB', borderRadius: 8 }}>
                        <div>
                          <span style={{fontWeight: 500}}>Add Question for this comprehension</span>
                          <span style={{float: 'right'}}>
                          <Select
                            allowClear={false}
                            placeholder='Question Type'
                            showSearch
                            // disabled={user_level == 13}
                            value='Select Question Type'
                            optionFilterProp='children'
                            getPopupContainer={(trigger) => trigger.parentNode}
                            filterOption={(input, options) => {
                              return (
                                options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                            onChange={(e, value) => {
                              handleComprehensivetype(value);
                            }}
                            // onClear={handleClearGrade}
                            className='w-100 text-left th-black-1 th-bg-grey th-br-4'
                            bordered={true}
                          >
                          {qtypeforcomp}
                          </Select>
                          </span>
                        </div>
                      </Card>
                </div>
              </>
            ): (
              <>
              {showQuestionType && <Question questionTypeProps = {showQuestionType} questionData = {question} parentIndex = {parentIndex} questionList={questionList} setQuestions={setQuestions} />}
              </>
            )}
          </div>
    </>
  );
};

export default QuestionFilters;
