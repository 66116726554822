import React, { useState, useEffect } from 'react';
import Layout from '../../Layout';
import { Breadcrumb, Radio, message, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import QuestionList from './questionList';
import axiosInstance from '../../../config/axios';

const AutomatedAssessment = () => {
  const history = useHistory();
  const [status, setStatus] = useState('untagged');
  const [checkConfig, setCheckConfig] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const fetchquesPaperStatus = () => {
    setLoading(true);
    axiosInstance
      .get(`/assessment/check-sys-config/?config_key=eble-qstn-tagng`)
      .then((response) => {
        if (response?.data?.result) {
          if (response?.data?.result[0] == 'True' || response?.data?.result == 'True') {
            setCheckConfig(true);
          } else {
            setCheckConfig(false);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error('error', error?.message);
      });
  };
  useEffect(() => {
    fetchquesPaperStatus();
  }, []);

  const options = [
    {
      label: 'Un-Tagged Questions',
      value: 'untagged',
    },
    {
      label: 'Tagged Questions',
      value: 'tagged',
    },
  ];

  return (
    <React.Fragment>
      <Layout>
        <div className='row py-3 px-2'>
          <div className='col-md-6' style={{ zIndex: 2 }}>
            <Breadcrumb separator='>'>
              <Breadcrumb.Item className='th-grey th-16'>Auto Assessment</Breadcrumb.Item>
              <Breadcrumb.Item className='th-black-1 th-16'>
                Question Tagging
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          {!loading ? (
            <>
              {checkConfig ? (
                <>
                  <div className='col-md-6 text-right' style={{ zIndex: 2 }}>
                    <Radio.Group
                      options={options}
                      onChange={handleStatusChange}
                      value={status}
                    />
                  </div>
                  <div className='row py-2'>
                    <div className='col-12'>
                      <div className='th-bg-white th-br-4 py-2'>
                        <QuestionList tagStatus={status} />
                      </div>
                    </div>
                  </div>{' '}
                </>
              ) : (
                <div
                  className='row py-2 d-flex justify-content-center align-items-center w-100 font-weight-bolder font-italic'
                  style={{ fontSize: '30px', marginTop: '10%' }}
                >
                  {' '}
                  Feature Will Be Live Soon{' '}
                </div>
              )}
            </>
          ) : (
            <div className='th-width-100 text-center mt-5 h-100'>
              <Spin tip='Loading...'></Spin>
            </div>
          )}
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default AutomatedAssessment;
