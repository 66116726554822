/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Card,
  TextField,
  Grid,
  Button,
  Typography,
  CardContent,
} from '@material-ui/core';
import Dropzone from 'react-dropzone';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Loading from '../../components/loader/loader';
import axiosInstance from '../../config/axios';
import endpoints from '../../config/endpoints';
import CommonBreadcrumbs from '../../components/common-breadcrumbs/breadcrumbs';
import { AlertNotificationContext } from '../../context-api/alert-context/alert-state';
import Layout from '../Layout';

const CreateEbook = () => {
  const bookTypeChoices = [
    { label: 'General', value: '1' },
    { label: 'Curriculum', value: '2' },
  ];
  const [bookTypeChoicesValue, setBookTypeChoicesValue] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [author, setAuthor] = useState('');
  const { setAlert } = useContext(AlertNotificationContext);
  const [loading, setLoading] = useState(false);
  const [selectedGrades, setSelectedGrades] = useState('');
  const [selectedAcad, setSelectedAcad] = useState('');
  const [acadList, setAcadList] = useState([]);
  const [selectedVolume, setSelectedVolume] = useState('');
  const [volumeList, setVolumeList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [files, setFiles] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [subjectList, setSubjectList] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    for (let i = 0; i < files.length; i += 1) {
      formData.append('files', files[i]);
    }
    formData.set('volume_id', selectedVolume.id);
    formData.set('academic_year', selectedAcad.id);
    formData.set('ebook_name', title);
    formData.set('ebook_description', description);
    formData.set('ebook_type', bookTypeChoicesValue);
    formData.set('subject_id', selectedSubject?.subject?.id);
    formData.set('grade_id', selectedGrades?.id);
    formData.set('ebook_author', author);
    axiosInstance
      .post(`${endpoints.ebook.ebook}`, formData)
      .then((result) => {
        if (result.status === 200) {
          setLoading(false);
          setAlert('success', 'SUCCESSFULLY UPLOADED EBOOK');
          // window.location = '/ebook/view';
        } else {
          setLoading(false);
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.message);
      });
  };

  const handleBookType = (event, value) => {
    if (value && value.value) {
      setBookTypeChoicesValue(value.value);
    } else {
      setBookTypeChoicesValue('');
    }
  };

  const handlGetApi = (api, key) => {
    setLoading(true);
    axiosInstance
      .get(api)
      .then((result) => {
        setLoading(false);
        if (result.data.status_code === 200) {
          if (key === 'acad') {
            setAcadList(result.data.result.results);
          } else if (key === 'volume') {
            setVolumeList(result.data.result.results);
          } else if (key === 'grade') {
            setGradeList(result.data.result.results);
          } else if (key === 'subject') {
            setSubjectList(result.data.result.results);
          }
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.message);
      });
  };

  useEffect(() => {
    handlGetApi(`${endpoints.ebook.academicYearList}`, 'acad');
    handlGetApi(endpoints.ebook.volumes, 'volume');
  }, []);

  const isPDF = (files) => {
    if (files[0].name.match(/.(pdf)$/i)) {
      return true;
    }
    return false;
  };

  const validateFileSize = (size) => {
    return size / 1024 / 1024 > 40 ? false : true;
  };
  const onDrop = (files = []) => {
    if (!isPDF(files)) {
      setAlert('error', 'Please select only PDF format');
      return;
    }
    if (files.length > 1) {
      setAlert('error', 'You can select only a single PDF at once');
      return;
    }
    const fileSize = files[0]?.size;

    if (!validateFileSize(fileSize)) {
      setAlert('error', 'File size must be less than 40MB');
      files= "";
      return;
    }


    const sampleFile = files;
    setFiles(sampleFile);
  };

  const getFileNameAndSize = (files) => {
    if (files.length) {
      const fileName =
        files &&
        files.map((file) => (
          <li key={file.name}>{`${file.name} -${file.size} bytes`}</li>
        ));
      return fileName;
    }
    return null;
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const handleAuthorChange = (e) => {
    setAuthor(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  return (
    <>
      {loading ? <Loading message='Loading...' /> : null}
      <Layout>
        <div style={{ width: '100%', padding: '10px 20px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <CommonBreadcrumbs
                componentName='Ebook'
                childComponentName='Create Ebook'
              />
            </Grid>
            <Grid item md={3} xs={12} sm={3}>
              <Autocomplete
                style={{ width: '100%' }}
                size='small'
                className='dropdownIcon'
                onChange={(event, value) => {
                  handlGetApi(`${endpoints.ebook.gradeList}`, 'grade');
                  setSelectedAcad(value);
                  setSelectedVolume('');
                  setSelectedGrades('');
                  setGradeList([]);
                  setSelectedSubject('');
                  setSubjectList([]);
                }}
                id='Acad_id'
                options={acadList}
                value={selectedAcad}
                getOptionLabel={(option) => option?.session_year}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Academic Year'
                    placeholder='Academic Year'
                  />
                )}
              />
            </Grid>
            <Grid item md={3} xs={12} sm={3}>
              <Autocomplete
                style={{ width: '100%' }}
                size='small'
                onChange={(event, value) => {
                  if (value) {
                    handlGetApi(
                      `${endpoints.ebook.gradeSubjectMappingList}?grade=${value.id}`,
                      'subject'
                    );
                  }
                  setSelectedGrades(value);
                  setSelectedSubject('');
                  setSubjectList([]);
                }}
                id='grade_id'
                value={selectedGrades}
                className='dropdownIcon'
                options={gradeList}
                filterSelectedOptions
                getOptionLabel={(option) => option?.grade_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Grade'
                    placeholder='Grade'
                  />
                )}
              />
            </Grid>
            <Grid item md={3} xs={12} sm={3}>
              <Autocomplete
                style={{ width: '100%' }}
                size='small'
                onChange={(event, value) => setSelectedSubject(value)}
                id='subject_id'
                value={selectedSubject}
                className='dropdownIcon'
                options={subjectList}
                getOptionLabel={(option) => option?.subject?.subject_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Subject'
                    placeholder='Subject'
                  />
                )}
              />
            </Grid>
            <Grid item md={3} xs={12} sm={3}>
              <Autocomplete
                style={{ width: '100%' }}
                size='small'
                className='dropdownIcon'
                onChange={(event, value) => {
                  setSelectedVolume(value);
                }}
                id='volume_id'
                options={volumeList}
                value={selectedVolume}
                getOptionLabel={(option) => option.volume_name || ''}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Volume'
                    placeholder='Volume'
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Autocomplete
                style={{ width: '100%' }}
                size='small'
                onChange={handleBookType}
                id='category'
                required
                className='dropdownIcon'
                options={bookTypeChoices}
                getOptionLabel={(option) => option?.label}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Book type'
                    placeholder='Book type'
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6} sm={3}>
              <TextField
                id='outlined-helperText'
                label='Book title'
                defaultValue=''
                required
                variant='outlined'
                style={{ width: '100%' }}
                inputProps={{ maxLength: 100 }}
                onChange={(event) => {
                  handleTitleChange(event);
                }}
                color='secondary'
                size='small'
              />
            </Grid>

            <Grid item md={6} xs={6} sm={3}>
              <TextField
                id='outlined-helperText'
                label='Author name'
                defaultValue=''
                variant='outlined'
                required
                style={{ width: '100%' }}
                inputProps={{ maxLength: 100 }}
                onChange={(event) => {
                  handleAuthorChange(event);
                }}
                color='secondary'
                size='small'
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                id='outlined-helperText'
                label='Description'
                multiline
                rows={3}
                required
                variant='outlined'
                value={description}
                style={{ width: '100%' }}
                inputProps={{ maxLength: 100 }}
                onChange={(event) => {
                  handleDescriptionChange(event);
                }}
                color='secondary'
                size='small'
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <Typography variant='h6' color='secondary'>
                Upload book in pdf format
              </Typography>
              <Typography color='textPrimary' variant='caption' />
              <Dropzone onDrop={onDrop}>
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragAccept,
                  isDragReject,
                }) => (
                  <Card
                    elevation={0}
                    style={{
                      width: '320px',
                      height: '150px',
                      border: '1px solid #ff6b6b',
                    }}
                    {...getRootProps()}
                    className='dropzone'
                  >
                    <CardContent>
                      <input {...getInputProps()} />
                      <div style={{ textAlign: 'center', marginTop: '40px' }}>
                        {isDragAccept && 'All files will be accepted'}
                        {isDragReject && 'Some files will be rejected'}
                        {!isDragActive && (
                          <Button
                            variant='text'
                            style={{ background: 'none', color: '#014b7e' }}
                          >
                            <CloudUploadIcon />
                            &nbsp;Upload file
                          </Button>
                        )}
                      </div>
                      {getFileNameAndSize(files)}
                    </CardContent>
                  </Card>
                )}
              </Dropzone>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6} sm={2}>
              <Button
                onClick={() => window.history.back()}
                variant='contained'
                style={{ color: 'white' }}
                color='secondary'
                className='custom_button_master'
                size='medium'
              >
                Back
              </Button>
            </Grid>
            <Grid item xs={6} sm={2}>
              <Button
                variant='contained'
                style={{ color: 'white' }}
                color='primary'
                className='custom_button_master'
                size='medium'
                disabled={
                  !selectedAcad ||
                  !selectedVolume ||
                  !selectedGrades ||
                  !selectedSubject ||
                  !title ||
                  !description ||
                  !author ||
                  !files.length > 0
                }
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
      </Layout>
    </>
  );
};

export default CreateEbook;
