import React, { useContext, useState, useEffect } from 'react';
import Paper from '@material-ui/core/Paper';
import { AttachmentPreviewerContext } from '../../../../components/attachment-previewer/attachment-previewer-contexts';
import { IconButton, SvgIcon, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import './view-more.css';
import endpoints from '../../../../config/endpoints';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { AlertNotificationContext } from '../../../../context-api/alert-context/alert-state';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import axiosInstance from '../../../../config/axios';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ReactHtmlParser from 'react-html-parser';
import { Drawer, Space, Spin, message } from 'antd';
import QuestionPaperView from '../../create-lesson-plan/attachment-list/questionPaperView';
import { CloseOutlined } from '@material-ui/icons';
import { FileTextOutlined } from '@ant-design/icons';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const ViewMoreCard = ({
  viewMoreData,
  setViewMore,
  filterDataDown,
  periodDataForView,
  setSelectedIndex,
}) => {
  const [openQuestion, setOpenQuestion] = useState(false);
  const [questionId, setQuestionId] = useState('');
  const [questionData, setQuestionData] = useState([]);
  const { openPreview } = React.useContext(AttachmentPreviewerContext) || {};
  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));

  const { setAlert } = useContext(AlertNotificationContext);
  const handleClickOpen = () => {
    setOpenQuestion(true);
  };

  const [questionPaperData, setQuestionPaperData] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [loadingDrawer, setLoadingDrawer] = useState(false);
  useEffect(() => {
    if (questionId?.id) {
      axiosInstance
        .get(`${endpoints.questionBank.questionPaper}${questionId?.id}/`)
        .then((result) => {
          if (result.status === 200) {
            {
              setQuestionData(result?.data);
            }
          } else {
            setAlert('error', result.error_message);
          }
        })
        .catch((error) => {
          setAlert('error', error.message);
        });
    }
  }, [questionId]);

  const handleClose = () => {
    setOpenQuestion(false);
  };
  const getS3DomainURL = (fileSrc, p) => {
    return `${endpoints.erps3}${fileSrc}`;
  };
  const handleDisplayQuestionId = (question) => {
    setQuestionId(question);
    handleClickOpen();
  };

  const fetchQuestionData = (paperid) => {
    setLoadingDrawer(true);
    const url = endpoints.questionBank.questionPaperPreview.replace(
      '<question-paper-id>',
      paperid
    );
    axiosInstance
      .get(url)
      .then((result) => {
        if (result.data.status_code === 200) {
          const { sections, questions } = result.data.result;
          const parsedResponse = [];
          sections.forEach((sec) => {
            const sectionObject = { name: '', questions: [] };
            const sectionName = Object.keys(sec)[0];
            sectionObject.name = sectionName;
            sec[sectionName].forEach((qId) => {
              const questionFound = questions.find((q) => q?.id === qId);
              if (questionFound) {
                sectionObject.questions.push(questionFound);
              }
            });
            parsedResponse.push(sectionObject);
          });
          setLoadingDrawer(false);
          setQuestionPaperData(parsedResponse);
        } else {
          setLoadingDrawer(false);
          message.error(result.data.message);
        }
      })
      .catch((error) => {
        setLoadingDrawer(false);
        message.error(error.message);
      })
      .finally(() => {
        setDrawerVisible(true);
        setLoadingDrawer(false);
      });
  };

  const openQpDrawer = (id) => {
    fetchQuestionData(id);
  };

  const closeQpDrawer = () => {
    setDrawerVisible(false);
  };

  return (
    <Paper className='rootViewMore'>
      <div className='viewMoreHeader'>
        <div className='leftHeader'>
          <div className='headerTitle'>{periodDataForView?.period_name}</div>
          <div
            className='headerContent headerContentCustom'
            title={filterDataDown?.chapter?.chapter_name}
          >
            {filterDataDown?.chapter?.chapter_name}
          </div>
        </div>
        <div className='rightHeader'>
          <div className='headerTitle closeIcon'>
            <IconButton
              onClick={() => {
                setViewMore(false);
                setSelectedIndex(-1);
              }}
            >
              <CloseIcon color='primary' />
            </IconButton>
          </div>
          <div className='headerContent'>
            <div>Last updated on</div>
            <div className='viewUpdatedDate'>
              {new Date(periodDataForView.updated_at).toLocaleDateString('in')}
            </div>
          </div>
        </div>
      </div>
      <div className='resourceBulkDownload pl-0'>
        <div>Resources</div>
      </div>

      {viewMoreData &&
        viewMoreData
          ?.filter((item) => item.document_type !== 'Homework')
          .map((p) => (
            <div className='viewMoreBody'>
              <div className='bodyTitle d-flex justify-content-between'>
                <span>
                  {p?.document_type === ''
                    ? 'Question Paper'
                    : p?.document_type === 'QuestionPaper'
                    ? 'Question Paper'
                    : p?.document_type}
                </span>
              </div>
              {p?.quesion_paper_ids?.map((qn) => (
                <div className='bodyContent'>
                  {qn?.paper_name}
                  <a
                    onClick={() => {
                      // handleDisplayQuestionId(qn);
                      openQpDrawer(qn?.id);
                    }}
                  >
                    <SvgIcon component={() => <VisibilityIcon />} />
                  </a>
                </div>
              ))}

              <div className='scrollableContent'>
                {p.media_file.map((file) => {
                  return (
                    <div className='bodyContent'>
                      <div title={file}>
                        {(file || '-/No file').split('/').splice(-1)[0]}
                      </div>
                      <div>
                        <a
                          onClick={() => {
                            openPreview({
                              currentAttachmentIndex: 0,
                              attachmentsArray: [
                                {
                                  src: getS3DomainURL(file, p),
                                  name: `${p.document_type}`,
                                  extension:
                                    '.' + file.split('.')[file.split('.').length - 1],
                                },
                              ],
                            });
                          }}
                        >
                          <SvgIcon component={() => <VisibilityIcon />} />
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
      {viewMoreData &&
        viewMoreData
          ?.filter((item) => item.document_type === 'Homework')
          .map((p) => (
            <div className='viewMoreBody'>
              <div className='bodyTitle d-flex justify-content-between'>
                <span>
                  {p?.document_type === ''
                    ? 'Question Paper'
                    : p?.document_type === 'QuestionPaper'
                    ? 'Question Paper'
                    : p?.document_type}
                </span>
                {p?.document_type === 'Homework' && p.media_file.length > 0 && (
                  <span>
                    {' '}
                    <FileTextOutlined className='mr-3 th-20' />
                    No. of attachments: {p.media_file.length}
                  </span>
                )}
              </div>
              {p?.document_type === 'Homework' && p?.homework_text !== '' && (
                <div className='mt-2 th-black-1 text-justify'>
                  <span className='th-18 th-fw-600'>Homework Question : </span>
                  <span className='th-16 th-primary'>{p?.homework_text}</span>
                </div>
              )}
              {p?.quesion_paper_ids?.map((qn) => (
                <div className='bodyContent'>
                  {qn?.paper_name}
                  <a
                    onClick={() => {
                      // handleDisplayQuestionId(qn);
                      openQpDrawer(qn?.id);
                    }}
                  >
                    <SvgIcon component={() => <VisibilityIcon />} />
                  </a>
                </div>
              ))}

              <div className='scrollableContent'>
                {p.media_file.map((file) => {
                  return (
                    <div className='bodyContent'>
                      <div title={file}>
                        {(file || '-/No file').split('/').splice(-1)[0]}
                      </div>
                      <div>
                        <a
                          onClick={() => {
                            openPreview({
                              currentAttachmentIndex: 0,
                              attachmentsArray: [
                                {
                                  src: getS3DomainURL(file, p),
                                  name: `${p.document_type}`,
                                  extension:
                                    '.' + file.split('.')[file.split('.').length - 1],
                                },
                              ],
                            });
                          }}
                        >
                          <SvgIcon component={() => <VisibilityIcon />} />
                        </a>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}

      <Drawer
        title={<span className='th-fw-500'>{'Question Paper'}</span>}
        placement='right'
        onClose={closeQpDrawer}
        zIndex={1400}
        open={drawerVisible}
        width={'450px'}
        closable={false}
        className='th-resources-drawer'
        extra={
          <Space>
            <CloseOutlined onClick={closeQpDrawer} />
          </Space>
        }
      >
        {loadingDrawer ? (
          <div className='text-center mt-5'>
            <Spin tip='Loading...' />
            loading
          </div>
        ) : (
          <QuestionPaperView questionData={questionPaperData} />
        )}
      </Drawer>

      <Dialog
        fullScreen
        open={openQuestion}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: 'relative' }}
          style={{ marginTop: '80px', background: '#ff6b6b' }}
        >
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              {questionId?.paper_name}
            </Typography>
            <Button autoFocus color='inherit' onClick={handleClose}>
              Back
            </Button>
          </Toolbar>
        </AppBar>
        <List>
          <div style={{ width: '95%', margin: '20px auto' }}>
            {/* <Button onClick={handleClose}>back</Button> */}
            {questionData?.question_details?.map((q) =>
              q.question_answer?.map((qd) => (
                <div style={{ padding: '10px' }}>
                  <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color='text.secondary'
                        gutterBottom
                      >
                        <div
                          style={{ color: 'black', padding: '10px', fontSize: '15px' }}
                          dangerouslySetInnerHTML={{ __html: qd.question }}
                        />
                      </Typography>
                    </CardContent>
                    <CardContent>
                      <Typography
                        sx={{ fontSize: 14 }}
                        color='text.secondary'
                        gutterBottom
                      >
                        {qd?.options ? (
                          <Grid item xs={12} sm={12}>
                            <Grid container spacing={isMobile ? 3 : 5}>
                              {Array.isArray(qd?.options) &&
                                qd?.options.length > 0 &&
                                qd?.options.map((opitem, opindex) => (
                                  <Grid
                                    item
                                    xs={3}
                                    style={isMobile ? { marginLeft: '-8px' } : null}
                                    sm={3}
                                  >
                                    Option {opindex + 1} .
                                    {/* {qd?.options[0]?.option1?.optionValue} */}
                                    {ReactHtmlParser(
                                      opitem[`option${opindex + 1}`].optionValue
                                    )}
                                    {Array.isArray(
                                      opitem[`option${opindex + 1}`].images
                                    ) &&
                                      opitem[`option${opindex + 1}`].images.map(
                                        (imgitem, imgindex) => (
                                          <img
                                            className='m-2'
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                              openPreview({
                                                currentAttachmentIndex: imgindex,
                                                attachmentsArray: (() => {
                                                  const images =
                                                    `${
                                                      opitem[`option${opindex + 1}`]
                                                        .images
                                                    }`.split(',') || {};
                                                  const attachmentsArray = [];
                                                  images.forEach((image) => {
                                                    const attachmentObj = {
                                                      src: getS3DomainURL(image),
                                                      name: `${image}`
                                                        .split('.')
                                                        .slice(0, -1)
                                                        .join('.'),
                                                      extension: `.${
                                                        `${image}`.split('.').slice(-1)[0]
                                                      }`,
                                                    };
                                                    attachmentsArray.push(attachmentObj);
                                                  });

                                                  return attachmentsArray;
                                                })(),
                                              });
                                            }}
                                            // src={getS3DomainURL(
                                            //   `${opitem[`option${opindex + 1}`].images}`.split(
                                            //     ','
                                            //   )
                                            // )}
                                            src={getS3DomainURL(imgitem)}
                                            alt={getS3DomainURL(
                                              `${opitem[`option${opindex + 1}`].images}`
                                            )}
                                            height='50px'
                                            width='75px'
                                          />
                                        )
                                      )}
                                    <br />
                                  </Grid>
                                ))}

                              {/* <Grid
                                item
                                xs={3}
                                style={isMobile ? { marginLeft: '-8px' } : null}
                                sm={3}
                              >
                                Option 1: {qd?.options[0]?.option1?.optionValue}
                                <br />
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                style={isMobile ? { marginLeft: '-8px' } : null}
                                sm={3}
                              >
                                Option 2: {qd?.options[1]?.option2?.optionValue}
                                <br />
                                <br />
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                style={isMobile ? { marginLeft: '-8px' } : null}
                                sm={3}
                              >
                                Option 3:{qd?.options[2]?.option3?.optionValue}
                                <br />
                                <br />
                              </Grid>
                              <Grid
                                item
                                xs={3}
                                style={isMobile ? { marginLeft: '-8px' } : null}
                                sm={3}
                              >
                                Option 4:{qd?.options[3]?.option1?.optionValue}
                                <br />
                                <br />
                              </Grid> */}
                            </Grid>
                          </Grid>
                        ) : (
                          ''
                        )}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size='small'>Answer :{qd.answer}</Button>
                    </CardActions>
                  </Card>

                  {/* {qd.question} */}
                </div>
              ))
            )}
          </div>
        </List>
      </Dialog>
    </Paper>
  );
};

export default ViewMoreCard;
