import React, { useState, useContext } from 'react';
import Paper from '@material-ui/core/Paper';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Button, useTheme, IconButton, SvgIcon } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { connect } from 'react-redux';
import useStyles from './useStyles';
import endpoints from '../../../../config/endpoints';
import axiosInstance from '../../../../config/axios';
import { addQuestionPaperToTest } from '../../../../redux/actions';
import CreateEditSchool from './../../../dashboard/create-edit-school';
// import '../../lesson-plan-view/lesson.css';
// import downloadAll from '../../../../assets/images/downloadAll.svg';
import { AlertNotificationContext } from '../../../../context-api/alert-context/alert-state';
import { PinDropSharp } from '@material-ui/icons';
import ConfirmPopOver from './ConfirmPopOver';
import RestoreModal from './restore-model'

const AssessmentCard = ({
  period,
  setPeriodDataForView,
  setViewMoreData,
  setViewMore,
  viewMore,
  filterDataDown,
  setLoading,
  index,
  periodColor,
  setPeriodColor,
  setSelectedIndex,
  initAddQuestionPaperToTest,
  setPublishFlag, setTableFlag, setperiodId,
}) => {
  const themeContext = useTheme();
  const { setAlert } = useContext(AlertNotificationContext);
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const classes = useStyles();
  const [showMenu, setShowMenu] = useState(false);
  const [showPeriodIndex, setShowPeriodIndex] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState();
  const history = useHistory();
  const [restoreModel , setOpenRestoreModal] = useState(false)


  const handlePeriodMenuOpen = (index, id) => {
    setShowMenu(true);
    setShowPeriodIndex(index);
  };

  const handlePeriodMenuClose = (index) => {
    setShowMenu(false);
    setShowPeriodIndex();
  };

  const handleAssign = () => {
    initAddQuestionPaperToTest(period);
    history.push('/create-assesment');
  };

  const handleDelete = () => {
    setPublishFlag(false);
    const url = endpoints.assementQP?.publishQuestionPaper.replace(
      '<question-paper-id>',
      period?.id
    );
    axiosInstance
      .put(url, {
        is_delete: true,
      })
      .then((result) => {
        if (result?.data?.status_code > 199 && result?.data?.status_code < 300) {
          setAlert('success', 'Question Deleted Successfully');
          setPublishFlag(true);
          setSelectedIndex(-1);
        } else {
          setAlert('error', result?.data?.message);
        }
      })
      .catch((error) => {
        setAlert('error', error?.message);
      });
  };
  const handlePublish = () => {
    setPublishFlag(false);
    axiosInstance
      .put(`/assessment/${period?.id}/update-status-question-paper/`, {
        is_verified: true,
        is_draft: false,
        is_review: false
      })
      .then((result) => {
        if (result?.data?.status_code > 199 && result?.data?.status_code < 300) {
          setAlert('success', "Question Published Successfully");
          setPublishFlag(true);
        } else {
          setAlert('error', result?.data?.message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });
  };
  const handlePaper = () => {
    setTableFlag(false);
    setperiodId(period?.id);
    console.log('published', period?.id)
    setPublishFlag(false);
    axiosInstance
      .put(`/assessment/${period?.id}/update-status-question-paper/`, {
        is_verified: true,
        is_draft: false,
        is_review: false
      })
      .then((result) => {
        if (result?.data?.status_code > 199 && result?.data?.status_code < 300) {
          setAlert('success', "Question Published Successfully");
          setPublishFlag(true);
        } else {
          setAlert('error', result?.data?.message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });
  };

  const handleRestore = () => {
    setPublishFlag(false);
    axiosInstance
      .put(`/assessment/${period?.id}/update-status-question-paper/`, {
        is_delete: false,
      })
      .then((result) => {
        if (result?.data?.status_code > 199 && result?.data?.status_code < 300) {
          setAlert('success', "Question Restored Successfully");
          setPublishFlag(true);
        } else {
          setAlert('error', result?.data?.message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });
  }

  const handleViewMore = () => {
    setLoading(true);
    setPeriodDataForView({})
    axiosInstance
      .get(`${endpoints.assementQP.assementViewmore.replace('<question-paper-id>', period?.id)}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          const { sections, questions } = result.data.result;
          const parsedResponse = [];
          sections.forEach((sec) => {
            const sectionObject = { name: '', questions: [] };
            const sectionName = Object.keys(sec)[0];
            sectionObject.name = sectionName;
            sec[sectionName].forEach((qId) => {
              const questionFound = questions.find((q) => q.id === qId);
              console.log('questionsFound', questionFound);
              if (questionFound) {
                sectionObject.questions.push(questionFound);
              }
            });
            parsedResponse.push(sectionObject);
          });

          setLoading(false);
          setViewMore(true);
          // setViewMoreData(result.data.result);
          setViewMoreData(parsedResponse);
          setPeriodDataForView(period);
          setSelectedIndex(index);
          setPeriodColor(true);
        } else {
          setLoading(false);
          setViewMore(false);
          setViewMoreData([]);
          setPeriodDataForView();
          setAlert('error', result.data.message);
          setSelectedIndex(-1);
          setPeriodColor(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        setViewMore(false);
        setViewMoreData([]);
        setPeriodDataForView();
        setAlert('error', error.message);
        setSelectedIndex(-1);
        setPeriodColor(true);
      });
  };

  return (
    <div>


      <Paper
        className={periodColor ? classes.selectedRoot : classes.root}
        style={isMobile ? { margin: '0rem auto' } : { margin: '0rem auto -1.1rem auto' }}
      >

        <Grid container spacing={2} style={{ background: '#ffefef' }}>
          <Grid item xs={9} >
            <Box>
              <Typography
                className={classes.title}
                variant='p'
                component='p'
                style={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
                title={period?.paper_name || ''}
                color='primary'
              >
                <span onClick={handlePaper}> {period?.paper_name}</span>


              </Typography>
            </Box>
            <Box>
              <Typography
                className={classes.content}
                variant='p'
                component='p'
                color='secondary'
                noWrap
              >
                {period.is_draft ? 'Draft' : null}
                {period.is_review ? 'Review' : null}
                {period.is_verified ? 'Published' : null}
              </Typography>
            </Box>
          </Grid>
          {/* {period.is_verified && ( */}
          <Grid item xs={3} className={classes.textRight}>
            <Box>
              <span
                className='period_card_menu'
                onClick={() => handlePeriodMenuOpen(index)}
                onMouseLeave={handlePeriodMenuClose}
              >
                <IconButton className='moreHorizIcon' color='primary'>
                  <MoreHorizIcon />
                </IconButton>
                {showPeriodIndex === index && showMenu ? (
                  <div className='tooltipContainer'>
                    {period.is_verified && !period?.is_delete && (
                      <span className='tooltiptext'>
                        {/* <span onClick={handleAssign}>Assign To Test</span> */}
                        <span onClick={() => {
                          setConfirmMessage('delete');
                          setOpenModal(true);
                        }}>Delete</span>
                      </span>
                    )}
                    {!period.is_verified && !period?.is_delete && (
                      <span className='tooltiptext'>
                        <span onClick={handlePublish}>Publish Paper</span>
                        <span onClick={() => {
                          setConfirmMessage('delete');
                          setOpenModal(true);
                        }}>Delete</span>
                      </span>)}
                      {period?.is_delete && (
                      <span className='tooltiptext'>
                        <span onClick={(e) => setOpenRestoreModal(true)}>Restore</span>
                      </span>)}
                    {
                      openModal && (
                        <ConfirmPopOver
                          submit={() => handleDelete()}
                          openModal={openModal}
                          setOpenModal={setOpenModal}
                          operation={confirmMessage}
                          opendelete={true}
                        />
                      )
                    }
                    {restoreModel && (
                <RestoreModal
                  submit={e => handleRestore()}
                  openModal={restoreModel}
                  setOpenModal={setOpenRestoreModal}
                />
              )}
                  </div>
                ) : null}
              </span>
            </Box>
          </Grid>
          {/* )} */}
          {/* <Grid item xs={12} sm={12} /> */}
          <Grid item xs={8}>
            <Box>
              <Typography
                className={classes.title}
                variant='p'
                component='p'
                color='secondary'
              >
                Created On
              </Typography>
            </Box>
            <Box>
              <Typography
                className={classes.content}
                variant='p'
                component='p'
                color='secondary'
              >
                {period.created_at.substring(0, 10)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4} className={classes.textRight}>
            {!periodColor && (
              <Button
                variant='contained'
                style={{ color: 'white' }}
                color='primary'
                className='custom_button_master modifyDesign'
                size='small'
                onClick={handleViewMore}
              >
                VIEW MORE
              </Button>
            )}
          </Grid>
        </Grid>
      </Paper >
    </div >
  );
};

const mapDispatchToProps = (dispatch) => ({
  initAddQuestionPaperToTest: (data) => dispatch(addQuestionPaperToTest(data)),
});

export default connect(null, mapDispatchToProps)(AssessmentCard);
