import React, { useContext, useState, useEffect } from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Autocomplete from '@material-ui/lab/Autocomplete';
import endpoints from '../../config/endpoints';
import axiosInstance from '../../config/axios';
import FilterFilledIcon from '../../components/icon/FilterFilledIcon';
import { AlertNotificationContext } from '../../context-api/alert-context/alert-state';
import Loading from '../../components/loader/loader';

const Filter = ({ handleFilter, clearFilter }) => {
  const { setAlert } = useContext(AlertNotificationContext);
  const [acadList, setAcadList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [selectedAcad, setSelectedAcad] = useState('');
  const [selectedGrade, setSelectedGrade] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState('');
  const [schoolList, setSchoolList] = useState([]);

  useEffect(() => {
    setSelectedAcad('');
    setSubjectList([]);
    setSelectedGrade('');
    setSelectedSubject('');
    setSelectedSchool('');
  }, [clearFilter]);

  function getApiCalls(url, key) {
    setLoading(true);
    axiosInstance
      .get(url)
      .then((result) => {
        setLoading(false);
        if (result.data.status_code === 200) {
          if (key === 'acad') {
            setAcadList(result.data.result.results);
          } else if (key === 'school') {
            setSchoolList(result.data.result.results);
          } else if (key === 'grade') {
            setGradeList(result.data.result.results);
          } else if (key === 'subject') {
            setSubjectList(result.data.result.results);
          }
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.message);
      });
  }

  useEffect(() => {
    getApiCalls(`${endpoints.ebook.academicYearList}`, 'acad');
    getApiCalls(endpoints.ebook.volumes, 'school');
    getApiCalls(`${endpoints.ebook.gradeList}`, 'grade');
  }, []);

  function handleClear() {
    handleFilter();
    setSelectedAcad('');
    setSelectedSchool('');
    setSubjectList([]);
    setSelectedGrade('');
    setSelectedSubject('');
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          <Autocomplete
            style={{ width: '100%' }}
            size='small'
            className='dropdownIcon'
            onChange={(event, value) => {
              setSelectedAcad(value);
              setSelectedSchool('');
              setSelectedGrade('');
              setSelectedSubject('');
            }}
            id='Acad_id'
            options={acadList}
            value={selectedAcad}
            getOptionLabel={(option) => option?.session_year}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                label='Acadmic Year'
                placeholder='Acadmic Year'
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Autocomplete
            size='small'
            onChange={(event, value) => {
              setSelectedGrade(value);
              setSelectedSubject('');
              setSelectedSchool('');
              if (value) {
                getApiCalls(
                  `${endpoints.ebook.gradeSubjectMappingList}?grade=${value.id}`,
                  'subject'
                );
              }
            }}
            className='dropdownIcon'
            style={{ width: '100%' }}
            id='grade'
            options={gradeList}
            value={selectedGrade}
            getOptionLabel={(option) => option?.grade_name}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                label='Grades'
                placeholder='Grades'
                required
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Autocomplete
            size='small'
            onChange={(event, value) => {
              setSelectedSubject(value);
              setSelectedSchool('');
            }}
            className='dropdownIcon'
            style={{ width: '100%' }}
            id='subject'
            options={subjectList}
            value={selectedSubject}
            getOptionLabel={(option) => option?.subject?.subject_name}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                label='Subject'
                placeholder='Subject'
                required
              />
            )}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <Autocomplete
            style={{ width: '100%' }}
            size='small'
            className='dropdownIcon'
            onChange={(event, value) => {
              setSelectedSchool(value);
            }}
            id='school_id'
            options={schoolList}
            value={selectedSchool}
            getOptionLabel={(option) => option.volume_name || ''}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                label='Volume'
                placeholder='Volume'
              />
            )}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={4} xs={6}>
              <Button
                className='custom_button_master labelColor'
                size='small'
                fullWidth
                onClick={() => handleClear()}
                variant='contained'
              >
                Clear All
              </Button>
            </Grid>
            <Grid item md={4} xs={6}>
              <Button
                startIcon={<FilterFilledIcon />}
                style={{ color: 'white' }}
                size='small'
                variant='contained'
                color='primary'
                fullWidth
                onClick={() =>
                  handleFilter(
                    selectedAcad,
                    selectedGrade,
                    selectedSubject,
                    selectedSchool
                  )}
              >
                Filter
              </Button>
            </Grid>
            <Grid item md={4} xs={12}>
              <Button
                size='small'
                color='primary'
                variant='contained'
                href='/ebook/create'
                style={{ color: 'white' }}
              >
                <AddCircleOutlineIcon />
                &nbsp;Create Ebook
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {loading && <Loading />}
    </>
  );
};

export default Filter;
