import React from 'react';
import { Grid, Button, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const ConfirmDialog = ({ open, cancel, confirm, title }) => {
  return (
    <Grid container spacing={2}>
      <Grid item md={12} xs={12}>
        <Dialog
          open={open}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            <Typography variant='h6' color='primary'>
              <Grid container spacing={2}>
                Confirm Alert&nbsp;
                <ErrorOutlineIcon style={{ marginTop: '5px' }} />
              </Grid>
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              <Typography color='secondary'>{title}</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              size='small'
              onClick={() => cancel(false)}
              color='secondary'
              variant='contained'
            >
              Cancel
            </Button>
            <Button size='small' onClick={confirm} color='primary' variant='contained'>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </Grid>
  );
};

export default ConfirmDialog;
