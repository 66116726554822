import React from 'react';
import TrueFalse from './TrueFalse';
import QuestionTypeFinder from '../CreateQuestion/QuestionTypeFinder';
import Descriptive from './Descriptive';
import MCQSingleChoice from './MCQSingleChoice';
import MCQMultipleChoice from './MCQMultipleChoice';
import FillInBlanksQuestion from './FillInBlanksQuestion';
import ComprehensionNew from './ComprehensionNew';

const QuestionTypehandler = ({
  currentQuestion,
  handleQuestionChanges,
  isSubQuestion,
  subQuestionType,
  subQuestionIndex,
  isEditQuestion,
}) => {
  const questionSubType = QuestionTypeFinder(
    isSubQuestion ? subQuestionType : currentQuestion?.question_type?.type_name
  );

  console.log('rohan12', questionSubType);

  return (
    <div>
      {questionSubType == 'Descriptive' && (
        <div className={`${isSubQuestion ? 'th-sub_question-box' : ''}`}>
          <Descriptive
            currentQuestion={currentQuestion}
            isSubQuestion={isSubQuestion}
            handleQuestionChanges={handleQuestionChanges}
            subQuestionIndex={subQuestionIndex}
            isEditQuestion={isEditQuestion}
          />
        </div>
      )}
      {questionSubType == 'True or False' && (
        <div className={`${isSubQuestion ? 'th-sub_question-box' : ''}`}>
          <TrueFalse
            currentQuestion={currentQuestion}
            isSubQuestion={isSubQuestion}
            handleQuestionChanges={handleQuestionChanges}
            subQuestionIndex={subQuestionIndex}
            isEditQuestion={isEditQuestion}
          />
        </div>
      )}
      {questionSubType == 'MCQMultiple' && (
        <div className={`${isSubQuestion ? 'th-sub_question-box' : ''}`}>
          <MCQMultipleChoice
            currentQuestion={currentQuestion}
            isSubQuestion={isSubQuestion}
            handleQuestionChanges={handleQuestionChanges}
            subQuestionIndex={subQuestionIndex}
            isEditQuestion={isEditQuestion}
          />
        </div>
      )}
      {questionSubType == 'MCQSingle' && (
        <div className={`${isSubQuestion ? 'th-sub_question-box' : ''}`}>
          <MCQSingleChoice
            currentQuestion={currentQuestion}
            isSubQuestion={isSubQuestion}
            handleQuestionChanges={handleQuestionChanges}
            subQuestionIndex={subQuestionIndex}
            isEditQuestion={isEditQuestion}
          />
        </div>
      )}
      {questionSubType == 'Fill in the Blanks' && (
        <div className={`${isSubQuestion ? 'th-sub_question-box' : ''}`}>
          <FillInBlanksQuestion
            currentQuestion={currentQuestion}
            isSubQuestion={isSubQuestion}
            handleQuestionChanges={handleQuestionChanges}
            subQuestionIndex={subQuestionIndex}
            isEditQuestion={isEditQuestion}
          />
        </div>
      )}
      {(currentQuestion?.sub_questions?.length > 0 ||
        questionSubType == 'Comprehension') && (
        <div>
          <ComprehensionNew
            currentQuestion={currentQuestion}
            isSubQuestion={false}
            handleQuestionChanges={handleQuestionChanges}
            isEditQuestion={isEditQuestion}
          />
        </div>
      )}
    </div>
  );
};

export default QuestionTypehandler;
