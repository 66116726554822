import React, { useState, useRef, useEffect } from 'react';
import { Modal, Select, Form, message, Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import QuestionCard from './QuestionCard';
import axiosInstance from '../../../config/axios';
import endpoints from '../../../config/endpoints';
import cuid from 'cuid';

const { Option } = Select;
const CreateQuestion = ({
  handleCloseCreateQuestionModal,
  academicYearList,
  gradeList,
  volumeList,
  boardList,
  questionTypeList,
  selectedAcademicYearId,
  selectedGradeId,
  selectedSubjectId,
}) => {
  const formRef = useRef();
  const [boardId, setBoardId] = useState();
  const [volumeId, setVolumeId] = useState();
  const [academicYearId, setAcademicYearId] = useState(selectedAcademicYearId);
  const [gradeId, setGradeId] = useState(selectedGradeId);
  const [subjectList, setSubjectList] = useState([]);
  const [subjectId, setSubjectId] = useState(selectedSubjectId);
  const [moduleList, setModuleList] = useState([]);
  const [moduleId, setModuleId] = useState();
  const [chapterList, setChapterList] = useState([]);
  const [chapterId, setChapterId] = useState();
  const [keyConceptList, setKeyConceptList] = useState([]);
  const [keyConceptId, setKeyConceptId] = useState();
  const [currentQuestion, setCurrentQuestion] = useState({
    chapter: chapterId,
    grade_subject_mapping: subjectId,
    question_answer: [],
    question_categories: '',
    question_level: '',
    question_status: 1,
    question_type: '',
    question_mark: '',
    is_tagged: true,
    topic: keyConceptId,
  });

  //  API Calls
  const fetchSubjectList = (params = {}) => {
    axiosInstance
      .get(`${endpoints.masterManagement.gradeSubjectMappingList}`, {
        params: { ...params },
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setSubjectList(result?.data?.result?.results);
          if (selectedSubjectId) {
            formRef.current.setFieldsValue({
              subject: result?.data?.result?.results?.filter(
                (el) => el?.id == selectedSubjectId
              )[0]?.subject?.subject_name,
            });
          }
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  const fetchModuleList = (params = {}) => {
    axiosInstance
      .get(`${endpoints.masterManagement.module}`, {
        params: { ...params },
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setModuleList(result?.data?.result);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  const fetchChapterList = (params = {}) => {
    axiosInstance
      .get(`${endpoints.masterManagement.chapters}`, {
        params: { ...params },
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setChapterList(result?.data?.result);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  const fetchKeyConceptList = (params = {}) => {
    axiosInstance
      .get(`${endpoints.masterManagement.keyConceptTableData}`, {
        params: { ...params },
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setKeyConceptList(result?.data?.result);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  // Handle Functions
  const handleBoardChange = (e) => {
    if (e) {
      setBoardId(e);
    } else {
      setBoardId();
    }
  };
  const handleVolumeChange = (e) => {
    setModuleList([]);
    setModuleId();
    setChapterList([]);
    setChapterId();
    setKeyConceptList([]);
    setKeyConceptId();
    formRef.current.setFieldsValue({
      module: null,
      chapter: null,
      key_concept: null,
    });
    if (e) {
      setVolumeId(e);
      fetchModuleList({
        academic_year: academicYearId,
        volume: e,
        grade_subject: subjectId,
        board: boardId,
      });
    } else {
      setVolumeId();
    }
  };
  const handleAcademicYearChange = (e) => {
    setGradeId();
    setSubjectId();
    setSubjectList([]);
    setModuleList([]);
    setModuleId();
    setChapterList([]);
    setChapterId();
    setKeyConceptList([]);
    setKeyConceptId();
    formRef.current.setFieldsValue({
      grade: null,
      subject: null,
      module: null,
      chapter: null,
      key_concept: null,
    });
    if (e) {
      setAcademicYearId(e);
      if (volumeId && boardId && subjectId) {
        fetchModuleList({
          academic_year: e,
          volume: volumeId,
          grade_subject: subjectId,
          board: boardId,
        });
      }
    } else {
      setAcademicYearId();
    }
  };
  const handleGradeChange = (e) => {
    setSubjectList([]);
    setSubjectId();
    setVolumeId();
    setModuleList([]);
    setModuleId();
    setChapterList([]);
    setChapterId();
    setKeyConceptList([]);
    setKeyConceptId();
    formRef.current.setFieldsValue({
      subject: null,
      volume: null,
      module: null,
      chapter: null,
      key_concept: null,
    });
    if (e) {
      setGradeId(e);
      fetchSubjectList({ grade: e });
    } else {
      setGradeId();
    }
  };
  const handleSubjectChange = (e) => {
    setVolumeId();
    setModuleList([]);
    setModuleId();
    setChapterList([]);
    setChapterId();
    setKeyConceptList([]);
    setKeyConceptId();
    formRef.current.setFieldsValue({
      volume: null,
      module: null,
      chapter: null,
      key_concept: null,
    });
    if (e) {
      setSubjectId(e);
    } else {
      setSubjectId();
    }
  };
  const handleModuleChange = (e) => {
    setChapterList([]);
    setChapterId();
    setKeyConceptList([]);
    setKeyConceptId();
    formRef.current.setFieldsValue({
      chapter: null,
      key_concept: null,
    });
    if (e) {
      setModuleId(e);
      fetchChapterList({
        grade_subject: subjectId,
        volume: volumeId,
        board: boardId,
        lt_module: e,
      });
    } else {
      setModuleId();
    }
  };
  const handleChapterChange = (e) => {
    setKeyConceptList([]);
    setKeyConceptId();
    formRef.current.setFieldsValue({
      key_concept: null,
    });
    if (e) {
      setChapterId(e);
      fetchKeyConceptList({ chapter: e });
    } else {
      setChapterId();
    }
  };
  const handleKeyConceptChange = (e) => {
    if (e) {
      setKeyConceptId(e);
    } else {
      setKeyConceptId();
    }
  };

  // Select Options
  const boardOptions = boardList?.map((el) => {
    return (
      <Option id={el?.id} value={el.id}>
        {el?.board_name}
      </Option>
    );
  });
  const volumeOptions = volumeList?.map((el) => {
    return (
      <Option id={el?.id} value={el.id}>
        {el?.volume_name}
      </Option>
    );
  });
  const academicYearOptions = academicYearList?.map((el) => {
    return (
      <Option id={el?.id} value={el.id}>
        {el?.session_year}
      </Option>
    );
  });
  const gradeOptions = gradeList?.map((el) => {
    return (
      <Option id={el?.id} value={el.id}>
        {el?.grade_name}
      </Option>
    );
  });
  const subjectOptions = subjectList?.map((el) => {
    return (
      <Option id={el?.id} value={el.id}>
        {el?.subject?.subject_name}
      </Option>
    );
  });
  const moduleOptions = moduleList?.map((el) => {
    return (
      <Option id={el?.id} value={el.id}>
        {el?.lt_module_name}
      </Option>
    );
  });
  const chapterOptions = chapterList?.map((el) => {
    return (
      <Option id={el?.id} value={el.id}>
        {el?.chapter_name}
      </Option>
    );
  });
  const keyConceptOptions = keyConceptList?.map((el) => {
    return (
      <Option id={el?.id} value={el.id}>
        {el?.topic_name}
      </Option>
    );
  });

  useEffect(() => {
    if (selectedAcademicYearId) {
      formRef.current.setFieldsValue({
        academic_year: academicYearList?.filter(
          (el) => el?.id == selectedAcademicYearId
        )[0]?.session_year,
      });
    }
    if (selectedGradeId) {
      formRef.current.setFieldsValue({
        grade: gradeList?.filter((el) => el?.id == selectedGradeId)[0]?.grade_name,
      });
    }
    if (selectedGradeId) {
      fetchSubjectList({ grade: selectedGradeId });
    }
  }, []);

  return (
    <div className='row'>
      <div className='col-12'>
        <Form ref={formRef} layout='vertical'>
          <div className='row align-items-center'>
            <div className='col-sm-3 col-6'>
              <Form.Item name='academic_year' label='Academic Year*'>
                <Select
                  showSearch
                  placeholder='Select Academic Year*'
                  optionFilterProp='children'
                  className=''
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownMatchSelectWidth={false}
                  filterOption={(input, options) => {
                    return (
                      options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  onChange={(e) => {
                    handleAcademicYearChange(e);
                  }}
                >
                  {academicYearOptions}
                </Select>
              </Form.Item>
            </div>
            <div className='col-sm-3 col-6'>
              <Form.Item name='grade' label='Grade*'>
                <Select
                  showSearch
                  placeholder='Select Grade*'
                  optionFilterProp='children'
                  className=''
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownMatchSelectWidth={false}
                  filterOption={(input, options) => {
                    return (
                      options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  onChange={(e) => {
                    handleGradeChange(e);
                  }}
                >
                  {gradeOptions}
                </Select>
              </Form.Item>
            </div>
            <div className='col-sm-3 col-6 pr-1'>
              <Form.Item name='subject' label='Subject*'>
                <Select
                  showSearch
                  placeholder='Select Subject*'
                  optionFilterProp='children'
                  className=''
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownMatchSelectWidth={false}
                  filterOption={(input, options) => {
                    return (
                      options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  onChange={(e) => {
                    handleSubjectChange(e);
                  }}
                >
                  {subjectOptions}
                </Select>
              </Form.Item>
            </div>
            <div className='col-sm-3 col-6'>
              <Form.Item name='baord' label='Board*'>
                <Select
                  showSearch
                  allowClear
                  placeholder='Select Board*'
                  optionFilterProp='children'
                  className=''
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownMatchSelectWidth={false}
                  filterOption={(input, options) => {
                    return (
                      options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  onChange={(e) => {
                    handleBoardChange(e);
                  }}
                >
                  {boardOptions}
                </Select>
              </Form.Item>
            </div>
            <div className='col-sm-3 col-6'>
              <Form.Item name='volume' label='Volume*'>
                <Select
                  showSearch
                  placeholder='Select Volume*'
                  optionFilterProp='children'
                  className=''
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownMatchSelectWidth={false}
                  filterOption={(input, options) => {
                    return (
                      options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  onChange={(e) => {
                    handleVolumeChange(e);
                  }}
                >
                  {volumeOptions}
                </Select>
              </Form.Item>
            </div>
            <div className='col-sm-3 col-6'>
              <Form.Item name='module' label='Module*'>
                <Select
                  showSearch
                  allowClear
                  placeholder='Select Module*'
                  optionFilterProp='children'
                  className=''
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownMatchSelectWidth={false}
                  filterOption={(input, options) => {
                    return (
                      options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  onChange={(e) => {
                    handleModuleChange(e);
                  }}
                >
                  {moduleOptions}
                </Select>
              </Form.Item>
            </div>
            <div className='col-sm-3 col-6'>
              <Form.Item name='chapter' label='Chapter*'>
                <Select
                  showSearch
                  placeholder='Select Chapter*'
                  optionFilterProp='children'
                  className=''
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownMatchSelectWidth={false}
                  filterOption={(input, options) => {
                    return (
                      options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  onChange={(e) => {
                    handleChapterChange(e);
                  }}
                >
                  {chapterOptions}
                </Select>
              </Form.Item>
            </div>
            <div className='col-sm-3 col-6'>
              <Form.Item name='key_concept' label='Key Concept*'>
                <Select
                  showSearch
                  placeholder='Select Key Concept*'
                  optionFilterProp='children'
                  className=''
                  allowClear
                  getPopupContainer={(trigger) => trigger.parentNode}
                  dropdownMatchSelectWidth={false}
                  filterOption={(input, options) => {
                    return (
                      options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  onChange={(e) => {
                    handleKeyConceptChange(e);
                  }}
                >
                  {keyConceptOptions}
                </Select>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
      <div className='col-12'>
        {keyConceptId ? (
          <>
            <QuestionCard
              questionTypeList={questionTypeList}
              question={currentQuestion}
              chapterId={chapterId}
              keyConceptId={keyConceptId}
              subjectId={subjectId}
              key={cuid()}
              handleCloseCreateQuestionModal={handleCloseCreateQuestionModal}
            />
          </>
        ) : (
          <div className='text-center pt-2 th-fw-600'>
            <InfoCircleOutlined className='mr-2' /> Please select all the required filters
            for this question
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateQuestion;
