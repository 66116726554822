import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../Layout';
import {
  Breadcrumb,
  Button,
  Drawer,
  Form,
  Input,
  Pagination,
  Result,
  Select,
  Table,
  message,
} from 'antd';
import endpoints from '../../../config/endpoints';
import axiosInstance from '../../../config/axios';
import UploadVideo from './uploadVideo';
import { FileCopy } from '@material-ui/icons';

const QrcodeUpload = () => {
  const formRef = useRef();
  const { Option } = Select;
  const [gradeList, setGradeList] = useState([]);
  const [gradeId, setGradeId] = useState(null);
  const [subjectList, setSubjectList] = useState([]);
  const [subjectId, setSubjectId] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const [videosList, setVideosList] = useState('');
  const [showFilter, setShowFilter] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  //eslint-disable-next-line
  const pageLimit = 15;
  const [loading, setLoading] = useState(false);

  const [showVideoDrawer, setShowVideoDrawer] = useState(false);
  const [uploadFlag, setUploadFlag] = useState(false);
  const [resetDrawer, setResetDrawer] = useState(false);

  useEffect(() => {
    fetchGradeList();
  }, []);

  useEffect(() => {
    if (resetDrawer) {
      closeVideoDrawer();
      setResetDrawer(false);
      fetchVideoList(gradeId, subjectId, searchValue ?? null, 1, pageLimit);
      setPageNo(1);
    }
  }, [resetDrawer]);

  // API Calls

  const fetchGradeList = () => {
    axiosInstance
      .get(`${endpoints.masterManagement.grades}`)
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setGradeList(result?.data?.result?.results);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const fetchSubjectList = (params = {}) => {
    axiosInstance
      .get(`${endpoints.masterManagement.gradeSubjectMappingList}`, {
        params: { ...params },
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setSubjectList(result?.data?.result?.results);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  const fetchVideoList = (grade, subject, file_name, pageNo) => {
    if (!grade && !subject && !file_name) {
      setShowFilter(true);
      setVideosList([]);
      return;
    }
    setLoading(true);
    const params = {
      grade,
      subject,
      file_name,
      page: pageNo,
      page_size: pageLimit,
    };
    axiosInstance
      .get(`${endpoints.lessonPlan.plannerVideos}`, { params })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setVideosList(result?.data?.result?.result);
          setTotalPage(result?.data?.result?.count);
        }
      })
      .catch((error) => {
        message.error(error.message);
      })
      .finally(() => {
        setLoading(false);
        setShowFilter(false);
      });
  };

  const columns = [
    {
      title: <span className='th-white th-fw-700'>Grade</span>,
      dataIndex: 'grade__grade_name',
      width: '20%',
      render: (data) => <span className='th-black-1 th-14'>{data}</span>,
    },
    {
      title: <span className='th-white th-fw-700'>Subject</span>,
      dataIndex: 'subject__subject_name',
      width: '20%',
      render: (data) => <span className='th-black-1 th-14'>{data}</span>,
    },
    {
      title: <span className='th-white th-fw-700'>File Name</span>,
      dataIndex: 'file_name',
      width: '25%',
      align: 'center',
      render: (data) => <span className='th-black-1 th-14'>{data}</span>,
    },
    {
      title: <span className='th-white th-fw-700'>File URL</span>,
      dataIndex: 'file',
      width: '35%',
      align: 'center',
      // render: (data) => <span className='th-black-1 th-14'>{data}</span>,
      render: (data) => (
        <div
          className='d-flex justiy-content-center'
          style={{ wordBreak: 'break-all', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => handleCopyUrl(data)}
        >
          <span className='th-fw-500 th-18 th-black-1'>{data}</span>
          <span className='pl-2'>
            <FileCopy />
          </span>
        </div>
      ),
    },
  ];

  //Functions

  const handleGradeChange = (e) => {
    setSubjectList([]);
    setSubjectId();
    setPageNo(1);
    formRef.current.setFieldsValue({
      subject: null,
    });
    if (e) {
      setGradeId(e);
      fetchSubjectList({ grade: e });
      fetchVideoList(e, subjectId, searchValue ?? null, 1);
    } else {
      setGradeId(null);
      fetchVideoList(null, null, searchValue ?? null, 1);
    }
  };

  const handleSubjectChange = (e) => {
    setPageNo(1);
    if (e) {
      setSubjectId(e);
      fetchVideoList(gradeId, e, searchValue ?? null, 1);
    } else {
      setSubjectId();
      fetchVideoList(gradeId, null, searchValue ?? null, 1);
    }
  };

  // Select Options

  const gradeOptions = gradeList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.grade_name}
      </Option>
    );
  });

  const subjectOptions = subjectList?.map((el) => {
    return (
      <Option key={el?.subject?.id} id={el?.subject?.id} value={el?.subject?.id}>
        {el?.subject?.subject_name}
      </Option>
    );
  });

  const closeVideoDrawer = () => {
    setShowVideoDrawer(false);
    setResetDrawer(true);
  };

  const openVideoDrawer = () => {
    setShowVideoDrawer(true);
  };

  const handleCopyUrl = (data) => {
    const copiedText = navigator.clipboard.writeText(`${endpoints.s3}media/${data}`);
    message.success('URL copied');
  };

  return (
    <React.Fragment>
      <Layout>
        <div className='row py-3 px-2'>
          <div className='col-md-6' style={{ zIndex: 2 }}>
            <Breadcrumb separator='>'>
              <Breadcrumb.Item className='th-grey th-16'>Dashboard</Breadcrumb.Item>
              <Breadcrumb.Item className='th-black-1 th-16'>QR Code</Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>

        <div className='row py-2'>
          <div className='col-12'>
            <div className='th-bg-white th-br-4 py-2'>
              <Form ref={formRef} layout='vertical'>
                <div className='row align-items-center'>
                  <div className='col-sm-3 col-6'>
                    <Form.Item name='grade' label='Grade'>
                      <Select
                        showSearch
                        placeholder='Select Grade'
                        optionFilterProp='children'
                        className=''
                        allowClear
                        getPopupContainer={(trigger) => trigger.parentNode}
                        dropdownMatchSelectWidth={false}
                        filterOption={(input, options) => {
                          return (
                            options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                            0
                          );
                        }}
                        onChange={(e) => {
                          handleGradeChange(e);
                        }}
                      >
                        {gradeOptions}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className='col-sm-3 col-6 '>
                    <Form.Item name='subject' label='Subject'>
                      <Select
                        showSearch
                        placeholder='Select Subject'
                        optionFilterProp='children'
                        className=''
                        allowClear
                        getPopupContainer={(trigger) => trigger.parentNode}
                        dropdownMatchSelectWidth={false}
                        filterOption={(input, options) => {
                          return (
                            options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                            0
                          );
                        }}
                        onChange={(e) => {
                          handleSubjectChange(e);
                        }}
                      >
                        {subjectOptions}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className='col-sm-3 col-6 '>
                    <Form.Item name='searchvalue' label='Search File'>
                      <Input
                        type='text'
                        value={searchValue}
                        placeholder='Search by file name'
                        onChange={(e) => {
                          setSearchValue(e.target.value);
                          setPageNo(1);
                          fetchVideoList(gradeId, subjectId, e.target.value);
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div className='col-sm-3 col-6 mt-3'>
                    <Button
                      type='primary'
                      className='th-br-4 w-100'
                      onClick={openVideoDrawer}
                    >
                      Upload File
                    </Button>
                  </div>
                </div>
              </Form>
              {showFilter ? (
                <Result
                  status='warning'
                  title='Please select the filters to show data'
                  className='mt-3'
                />
              ) : (
                <div className='col-md-12 mt-3'>
                  <Table
                    className='th-table'
                    rowClassName={(record, index) =>
                      index % 2 === 0 ? 'th-bg-grey' : 'th-bg-white'
                    }
                    loading={loading}
                    columns={columns}
                    rowKey={(record) => record?.user_id}
                    dataSource={videosList}
                    pagination={false}
                    scroll={{ y: '400px' }}
                  />

                  {videosList?.length > 0 && (
                    <div className='pt-3 '>
                      <Pagination
                        current={pageNo}
                        total={totalPage}
                        showSizeChanger={false}
                        pageSize={pageLimit}
                        onChange={(current) => {
                          setPageNo(current);
                          fetchVideoList(
                            gradeId,
                            subjectId,
                            searchValue ?? null,
                            current
                          );
                        }}
                        className='text-center'
                      />
                    </div>
                  )}
                </div>
              )}
            </div>

            <Drawer
              title='Upload File'
              placement='right'
              onClose={closeVideoDrawer}
              closable={false}
              open={showVideoDrawer}
              width={500}
              zIndex={1500}
              footer={
                <div
                  style={{
                    textAlign: 'right',
                  }}
                >
                  <Button
                    form='incomeForm'
                    type='primary'
                    htmlType='submit'
                    onClick={() => {
                      setUploadFlag(true);
                    }}
                    disabled={uploadFlag}
                    loading={uploadFlag}
                  >
                    Upload File
                  </Button>
                </div>
              }
            >
              <UploadVideo
                uploadFlag={uploadFlag}
                setUploadFlag={setUploadFlag}
                resetDrawer={resetDrawer}
                setResetDrawer={setResetDrawer}
              />
            </Drawer>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default QrcodeUpload;
