/*global chrome*/
import { Breadcrumb, Upload, Button, Modal, message, Select } from 'antd';
import React, { useState, useEffect } from 'react';
import Layout from '../Layout';
import { CheckCircleTwoTone, FileExcelTwoTone } from '@ant-design/icons';
import axiosInstance from '../../config/axios';
import endpoints from '../../config/endpoints';
import { useHistory } from 'react-router-dom';
import SampleExcel from '../../../src/assets/resources/Sample_Excel_for_Upload.xlsx';
import FileSaver from 'file-saver';

const CreateIndices = () => {
  const history = useHistory();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileTypeError, setFileTypeError] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [boardListData, setBoardListData] = useState([]);
  const [selectedBoard, setSelectedBoard] = useState();
  const [uploading, setUploading] = useState(false);
  const [uploadResults, setUploadResults] = useState();
  const [reportDownloaded, setReportDownloaded] = useState(false);

  const allowedFiles = ['.xls', '.xlsx'];
  const { Dragger } = Upload;
  const { Option } = Select;
  const draggerProps = {
    showUploadList: false,
    disabled: false,
    accept: allowedFiles.join(),
    // '.xls,.xlsx',
    multiple: false,
    onRemove: () => {
      setSelectedFile(null);
    },
    onDrop: (e) => {
      const file = e.dataTransfer.files;
      setSelectedFile(null);
      const type = '.' + file[0]?.name.split('.')[file[0]?.name.split('.').length - 1];
      console.log({ type }, { file });
      if (allowedFiles.includes(type)) {
        setSelectedFile(...file);
        setFileTypeError(false);
      } else {
        setFileTypeError(true);
      }

      return false;
    },
    beforeUpload: (...file) => {
      setSelectedFile(null);
      const type = '.' + file[0]?.name.split('.')[file[0]?.name.split('.').length - 1];
      console.log({ type }, { file });
      if (allowedFiles.includes(type)) {
        setSelectedFile(...file[1]);
        setFileTypeError(false);
      } else {
        setFileTypeError(true);
      }

      return false;
    },
    selectedFile,
  };

  const boardOptions = boardListData?.map((each) => {
    return (
      <Option key={each?.id} value={each.board_name}>
        {each?.board_name}
      </Option>
    );
  });
  const fetchBoardListData = () => {
    axiosInstance
      .get(`${endpoints.masterManagement.boardMappingList}`)
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setBoardListData(result?.data?.result);
          let data = result?.data?.result?.filter(
            (item) => item?.board_name === 'CBSE'
          )[0];
          setSelectedBoard(data?.board_name);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  const hanldleUpload = () => {
    if (!selectedFile) {
      message.error('Please select file !!');
      return;
    }
    if (!selectedBoard) {
      message.error('Please select Board first !!');
      return;
    } else {
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('board', selectedBoard);
      setUploading(true);

      axiosInstance
        .post('/lesson_plan/create-indices-v2/', formData)
        .then((response) => {
          if (response?.data?.status_code == 200) {
            setUploadResults(response?.data?.data);
            setShowDetailsModal(true);
            setReportDownloaded(false);
          } else {
            message.error(response?.data?.developer_msg ?? 'Something went wrong');
            setUploading(false);
          }
        })
        .catch((error) => {
          message.error('Something went wrong!!');
          setUploading(false);
        });
    }
  };

  const handleDownloadReport = () => {
    let sliceSize = 1024;
    let byteCharacters = window.atob(uploadResults?.file);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);
      let bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    FileSaver.saveAs(
      new Blob(byteArrays, { type: 'application/vnd.ms-excel' }),
      'Upload Report.xlsx'
    );
    setReportDownloaded(true);
  };

  useEffect(() => {
    fetchBoardListData();
  }, []);

  return (
    <Layout>
      <div className='row'>
        <div className='col-12'>
          <div className='row '>
            <div className='col-md-6 '>
              <Breadcrumb separator='>'>
                <Breadcrumb.Item
                  onClick={() => history.push('/lesson-plan/view')}
                  className='th-grey th-fw-500 th-16 th-pointer'
                >
                  Lesson Plan
                </Breadcrumb.Item>
                <Breadcrumb.Item className='th-black-1 th-fw-500 th-16'>
                  Create Indices
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
          <div className='row  justify-content-center th-width-100 p-3'>
            <div className='col-12 th-bg-white py-2 th-br-5'>
              <div className='th-16 th-fw-500 py-2 th-black-1 d-flex justify-content-between align-items-center'>
                <div>Upload the Excel with Modules, Chapters & KeyConcepts</div>
                <div>
                  <span className='px-2 th-fw-500'>Board </span>
                  <span>
                    <Select
                      placeholder='Select Board'
                      showSearch
                      value={selectedBoard}
                      optionFilterProp='children'
                      filterOption={(input, options) => {
                        return (
                          options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                      onChange={(e) => {
                        setSelectedBoard(e);
                      }}
                      className=' text-left th-black-1 th-bg-grey th-br-4'
                    >
                      {boardOptions}
                    </Select>
                  </span>
                </div>
              </div>
              <div className='d-flex justify-content-center pt-2'>
                <Dragger
                  {...draggerProps}
                  className={`th-br-4 th-bg-white d-flex justify-content-center ${
                    window.innerWidth < 600 ? 'th-width-100' : 'th-width-50'
                  }`}
                  style={{
                    background: '#fff6f6',
                  }}
                >
                  {selectedFile ? (
                    <div className='row align-items-center' style={{ height: '100px' }}>
                      {/* <div className='col-2 pr-0'>Selected File :</div> */}
                      <div className='col-12 text-wrap'>
                        <span className='th-fw-500 th-16'>
                          <FileExcelTwoTone className='pr-2' />
                          {selectedFile?.name}
                        </span>
                      </div>
                    </div>
                  ) : (
                    <>
                      {' '}
                      <p className='pt-2'>
                        {' '}
                        Drag And Drop Files Here <br /> or
                      </p>
                      <Button
                        className='th-primary pb-2 mt-0 th-bg-white th-br-4'
                        style={{ border: '1px solid #1b4ccb' }}
                      >
                        Browse Files
                      </Button>
                    </>
                  )}
                </Dragger>
              </div>
              {fileTypeError && (
                <div className='py-3'>
                  <div className='row pt-3 justify-content-center  th-red'>
                    <span className='th-red th-fw-500 th-16'>
                      Please select Excel file only
                    </span>
                  </div>
                </div>
              )}
              <div className='d-flex justify-content-between flex-column flex-sm-row align-items-center p-2 mt-2'>
                <div>
                  <a href={SampleExcel} download>
                    <Button type='primary' className='th-bg-pink th-primary'>
                      Download Sample File
                    </Button>
                  </a>
                  {!reportDownloaded && uploadResults && !showDetailsModal && (
                    <Button
                      type='primary'
                      className='ml-4'
                      onClick={handleDownloadReport}
                    >
                      Last Upload Result
                    </Button>
                  )}
                </div>
                <div className='d-flex justify-content-around py-2 py-sm-0'>
                  <Button
                    type='default'
                    className='mx-md-4 mx-2'
                    onClick={() => setSelectedFile(null)}
                  >
                    Clear
                  </Button>
                  <Button
                    type='primary'
                    className='mx-md-0 mx-2'
                    onClick={hanldleUpload}
                    disabled={uploading || !selectedFile}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showDetailsModal && (
          <Modal
            visible={showDetailsModal}
            centered
            footer={null}
            width={window.innerWidth < 60 ? '90vw' : '50vw'}
            title={
              <div>
                <span>Upload Status</span>
              </div>
            }
            onCancel={() => {
              setSelectedFile(null);
              setShowDetailsModal(false);
              setUploading(false);
            }}
            className='th-upload-modal'
          >
            <div>
              <div className='row'>
                <div className='col-4 th-black-2'>
                  Academic Year :{' '}
                  <span className='th-primary th-fw-500'>
                    {uploadResults?.academic_year}
                  </span>
                </div>
                <div className='col-4 th-black-2'>
                  Volume :{' '}
                  <span className='th-primary th-fw-500'>{uploadResults?.volume}</span>
                </div>
                <div className='col-4 th-black-2 '>
                  Board :{' '}
                  <span className='th-primary th-fw-500'>{uploadResults?.board}</span>
                </div>
              </div>
              <div className='row py-2'>
                <div className='col-4 th-black-2'>
                  Grade :{' '}
                  <span className='th-primary th-fw-500'> {uploadResults?.grade}</span>
                </div>
                <div className='col-4 th-black-2 '>
                  Subject :{' '}
                  <span className='th-primary th-fw-500'> {uploadResults?.subject}</span>
                </div>
              </div>
              <div className='row pt-3'>
                <div className='col-3'></div>
                <div className='col-9 '>
                  {uploadResults?.created_modules > 0 && (
                    <div>
                      <CheckCircleTwoTone twoToneColor='#52c41a' className='mr-2' />{' '}
                      <span className='th-fw-500'>{uploadResults?.created_modules} </span>
                      {uploadResults?.created_modules > 1 ? 'Modules' : 'Module'} Created
                      Successfully
                    </div>
                  )}
                  {uploadResults?.created_chapters > 0 && (
                    <div>
                      <CheckCircleTwoTone twoToneColor='#52c41a' className='mr-2' />{' '}
                      <span className='th-fw-500'>
                        {uploadResults?.created_chapters}{' '}
                      </span>
                      {uploadResults?.created_chapters > 1 ? 'Chapters' : 'Chapter'}{' '}
                      Created Successfully
                    </div>
                  )}
                  {uploadResults?.created_key_concepts > 0 && (
                    <div>
                      <CheckCircleTwoTone twoToneColor='#52c41a' className='mr-2' />{' '}
                      <span className='th-fw-500'>
                        {uploadResults?.created_key_concepts}{' '}
                      </span>{' '}
                      Key{' '}
                      {uploadResults?.created_key_concepts > 1 ? 'Concepts' : 'Concept'}{' '}
                      Created Successfully
                    </div>
                  )}
                  <div className='pt-4 pl-4'>
                    {/* <a href={uploadResults?.file} download> */}
                    <Button type='primary' onClick={handleDownloadReport}>
                      Download Complete Result
                    </Button>
                    {/* </a> */}
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </Layout>
  );
};

export default CreateIndices;
