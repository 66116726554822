/* eslint-disable react/jsx-no-duplicate-props */
import React, { createRef, useContext, useEffect, useState } from 'react';
import Layout from '../../../Layout';
import Paper from '@material-ui/core/Paper';
import { Grid, useTheme, SvgIcon, IconButton } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AlertNotificationContext } from '../../../../context-api/alert-context/alert-state';
import endpoints from '../../../../config/endpoints';
import axiosInstance from '../../../../config/axios';
import { Breadcrumb, Button } from 'antd';
import unfiltered from '../../../../assets/images/unfiltered.svg';
import selectfilter from '../../../../assets/images/selectfilter.svg';
import AssessmentCard from './assesment-card'



// import './lesson.css';
import AssessmentFiltersNew from './assessmentFiltersNew';
import { useHistory } from 'react-router-dom';
import ViewMoreCard from './view-more-card';
import Loader from '../../../../components/loader/loader';
import { Spin } from 'antd/es';




const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '-10px auto',
    boxShadow: 'none',
  },
  container: {
    maxHeight: '70vh',
    width: '100%',
  },
  loader : {
  //   margin: '20px 0';
  // margin-bottom: 20px;
  // padding: 30px 50px;
  textAlign: 'center',
  marginTop:'12%',
  // background: '#1140b9',
  // opacity:'0.2',
  borderRadius: '4px'
  }
}));
const AssessmentNew = () => {

  const classes = useStyles();
  const { setAlert } = useContext(AlertNotificationContext);
  const [page, setPage] = useState(1);
  const history = useHistory();
  const formRef = createRef();
  const [periodData, setPeriodData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [viewMore, setViewMore] = useState(false);
  const [viewMoreData, setViewMoreData] = useState([]);
  const [periodDataForView, setPeriodDataForView] = useState({});
  const [filterDataDown, setFilterDataDown] = useState({});
  const limit = 9;
  const [chapterSearch, setChapterSearch] = useState();
  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const [isFilter, setIsFilter] = useState(false);
  const [periodColor, setPeriodColor] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [tableFlag, setTableFlag] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [tabGradeId, setTabGradeId] = useState('');
  const [tabSubjectId, setTabSubjectId] = useState('');
  const [tabQpValue, setTabQpValue] = useState('');
  const [publishFlag, setPublishFlag] = useState(false);
  const [periodId, setperiodId] = useState('');
  const [gradeSubjectMap, setGradeSubjectMap] = useState('');
  const handlePagination = (event, page) => {
    setPage(page);
  };

  const handlePeriodList = (grade, subject, qpValue,gradeSubjectMap) => {
    if (!grade) {
      setAlert('error', 'Select all the fields!');
      return;
    }
    setLoading(true);
    setPeriodData([]);
    setTabGradeId(grade);
    setTabSubjectId(subject);
    setTabQpValue(qpValue);
    setGradeSubjectMap(gradeSubjectMap)
    let requestURL = `${endpoints.assementQP.assementFilterData}?page=${page}&page_size=${limit}&gs_mapping=${gradeSubjectMap}`
    if(qpValue){
      requestURL +=   `&paper_level=${qpValue.value}`
      }
      // setTabValue(tabValue);
    if (tabValue == 0) {
      requestURL += `&is_delete=False`;
    }
    if (tabValue == 1) {
      requestURL += `&is_draft=True&is_delete=False`;
    }
    if (tabValue == 3) {
      requestURL += `&is_review=True&is_delete=False`;
    }
    if (tabValue == 2) {
      requestURL += `&is_verified=True&is_delete=False`;
    }
    if (tabValue == 4) {
      requestURL += `&is_delete=True`;
    }
      axiosInstance
        .get(requestURL)
        .then((result) => {
          if (result.data.status_code === 200) {
            setTotalCount(result?.data?.result?.count);
            setLoading(false);
            setPeriodData(result?.data?.result?.results);
            setViewMore(false);
            setViewMoreData([]);
          } else {
            setLoading(false);
            setAlert('error', result?.data?.description);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error?.message);
        });

    // if (newValue == 0 || newValue == undefined) {
    //   const tabVal = '';
    //   setTabValue(0);
    //   // if (tabValue == 1) {
    //   //   tabVal = '&is_draft=True';
    //   // } else if (tabValue == 2) {
    //   //   tabVal = '&is_review=True';
    //   // } else if (tabValue == 3) {
    //   //   tabVal = '&is_verified=True';
    //   // }
    //   if(qpValue){
    //   url +=   `&paper_level=${qpValue.id}${tabVal}`
    //   }
    //   axiosInstance
    //     .get(url)
    //     .then((result) => {
    //       if (result.data.status_code === 200) {
    //         setTotalCount(result?.data?.result?.count);
    //         setLoading(false);
    //         setPeriodData(result?.data?.result?.results);
    //         setViewMore(false);
    //         setViewMoreData([]);
    //       } else {
    //         setLoading(false);
    //         setAlert('error', result?.data?.description);
    //       }
    //     })
    //     .catch((error) => {
    //       setLoading(false);
    //       setAlert('error', error?.message);
    //     });
    // } else if (newValue == 1) {
    //   let url = `${endpoints.assementQP.assementFilter}?gs_mapping=${gradeSubjectMap}&is_draft=True&page=${page}&page_size=${limit}&is_delete=False` //grade=${grade.id}&

    //   if(qpValue){
    //   url +=   `&paper_level=${qpValue.id}`
    //   }
    //   setTabValue(1);
    //   axiosInstance
    //     .get(url)
    //     // axiosInstance.get(`${endpoints.assementQP.assementFilter}?grade=${2}&paper_level=${1}&is_draft=True`)
    //     .then((result) => {
    //       if (result.data.status_code === 200) {
    //         setTotalCount(result.data.result.count);
    //         setLoading(false);
    //         setPeriodData(result.data.result.results);
    //         setViewMore(false);
    //         setViewMoreData([]);
    //       } else {
    //         setLoading(false);
    //         setAlert('error', result.data.description);
    //       }
    //     })
    //     .catch((error) => {
    //       setLoading(false);
    //       setAlert('error', error.message);
    //     });
    // } else if (newValue == 2) {
    //   setTabValue(2);
    //   let url = `${endpoints.assementQP.assementFilter}?gs_mapping=${gradeSubjectMap}&is_review=True&page=${page}&page_size=${limit}&is_delete=False` //grade=${grade.id}&
    //   if(qpValue){
    //     url +=   `&paper_level=${qpValue.id}`
    //     }
    //   axiosInstance
    //     .get(url)
    //     // axiosInstance.get(`${endpoints.assementQP.assementFilter}?grade=${2}&paper_level=${1}&is_review=True`)
    //     .then((result) => {
    //       if (result.data.status_code === 200) {
    //         setTotalCount(result.data.result.count);
    //         setLoading(false);
    //         setPeriodData(result.data.result.results);
    //         setViewMore(false);
    //         setViewMoreData([]);
    //       } else {
    //         setLoading(false);
    //         setAlert('error', result.data.description);
    //       }
    //     })
    //     .catch((error) => {
    //       setLoading(false);
    //       setAlert('error', error.message);
    //     });
    // } else if (newValue == 3) {
    //   setTabValue(3);
    //   let url =  `${endpoints.assementQP.assementFilter}?gs_mapping=${gradeSubjectMap}&is_verified=True&page=${page}&page_size=${limit}&is_delete=False` //grade=${grade.id}&
    //   if(qpValue){
    //     url +=   `&paper_level=${qpValue.id}`
    //     }
    //   axiosInstance
    //     .get(url)
    //     // axiosInstance.get(`${endpoints.assementQP.assementFilter}?grade=${2}&paper_level=${1}&is_verified=True`)
    //     .then((result) => {
    //       if (result.data.status_code === 200) {
    //         setTotalCount(result.data.result.count);
    //         setLoading(false);
    //         setPeriodData(result.data.result.results);
    //         setViewMore(false);
    //         setViewMoreData([]);
    //       } else {
    //         setLoading(false);
    //         setAlert('error', result.data.description);
    //       }
    //     })
    //     .catch((error) => {
    //       setLoading(false);
    //       setAlert('error', error.message);
    //     });
    // } else if (newValue == 4) {
    //   setTabValue(4);
    //   let url = `${endpoints.assementQP.assementFilter}?gs_mapping=${gradeSubjectMap}&page=${page}&page_size=${limit}&is_delete=True` //grade=${grade.id}&
    //   if(qpValue){
    //     url +=   `&paper_level=${qpValue.id}`
    //     }
    //   axiosInstance
    //     .get(url)
    //     // axiosInstance.get(`${endpoints.assementQP.assementFilter}?grade=${2}&paper_level=${1}&is_verified=True`)
    //     .then((result) => {
    //       if (result.data.status_code === 200) {
    //         setTotalCount(result.data.result.count);
    //         setLoading(false);
    //         setPeriodData(result.data.result.results);
    //         setViewMore(false);
    //         setViewMoreData([]);
    //       } else {
    //         setLoading(false);
    //         setAlert('error', result.data.description);
    //       }
    //     })
    //     .catch((error) => {
    //       setLoading(false);
    //       setAlert('error', error.message);
    //     });
    // }
  };
  const handleGoBack = () => {
    setTableFlag(true)
    console.log('clicked')

  };


  useEffect(() => {
    // if (page && chapterSearch || publishFlag)
    if (publishFlag) handlePeriodList(tabGradeId, tabSubjectId, tabQpValue ,gradeSubjectMap );
    if (tabGradeId && tabSubjectId)
      handlePeriodList(tabGradeId, tabSubjectId, tabQpValue,gradeSubjectMap);
  }, [publishFlag, page, tabValue]);
  return (
    <Layout>
      <div className='py-3 px-2'>
        <div className='col-md-8 th-bg-grey' style={{ zIndex: 2 }}>
          <Breadcrumb separator='>'>
            <Breadcrumb.Item className='th-black-1 th-18'>Assessment</Breadcrumb.Item>
            <Breadcrumb.Item className='th-black-1 th-18'>Question Paper</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className='th-bg-white py-2 mx-3'>
              <AssessmentFiltersNew
                  handlePeriodList={handlePeriodList}
                  setPeriodData={setPeriodData}
                  setViewMore={setViewMore}
                  setViewMoreData={setViewMoreData}
                  setFilterDataDown={setFilterDataDown}
                  setSelectedIndex={setSelectedIndex}
                  // handleData={handleSubjectData}s
                  setPage={setPage}
                  setTabGradeId={setTabGradeId}
                  setTabSubjectId={setTabSubjectId}
              />
            <div className='row ml-2'>
              
                <div className='col-md-1 col-6'>
                  <Button
                    className={`${
                      tabValue == 0 ? 'th-button-active' : 'th-button'
                    } th-width-100 th-br-6 mt-2`}
                    onClick={() => {
                      setTabValue(0);
                      setPage(1);
                    }}
                  >
                    All
                  </Button>
                </div>
              <div className='col-md-5 d-flex pl-0'>
                <div className='col-md-4 col-6'>
                  <Button
                    className={`${
                      tabValue == 2  ? 'th-button-active' : 'th-button'
                    } th-width-100 th-br-6 mt-2`}
                    onClick={() => {
                      setTabValue(2);
                      setPage(1);
                    }}
                  >
                    Published
                  </Button>
                </div>
                  <div className='col-md-4 col-6'>
                    <Button
                      className={`${
                        tabValue == 3 ? 'th-button-active' : 'th-button'
                      } th-width-100 th-br-6 mt-2`}
                      onClick={() => {
                        setTabValue(3);
                        setPage(1);
                      }}
                    >
                      For Review
                    </Button>
                  </div>
                  <div className='col-md-4 col-6'>
                    <Button
                      className={`${
                        tabValue == 1 ? 'th-button-active' : 'th-button'
                      } th-width-100 th-br-6 mt-2`}
                      onClick={() => {
                        setTabValue(1);
                        setPage(1);
                      }}
                    >
                      Draft
                    </Button>
                  </div>
              </div>
              {/* {tabIsErpCentral && <div className='col-md-1 col-6'></div>} */}
              <div className='col-md-2 d-flex' style={{ marginLeft: '-2%' }}>
                  <div className='col-md-10 col-6'>
                    <Button
                      className={`${
                        tabValue == 4 ? 'th-button-active' : 'th-button'
                      } th-width-100 th-br-6 mt-2`}
                      onClick={() => {
                        setTabValue(4);
                        setPage(1);
                      }}
                    >
                      Deleted
                    </Button>
                  </div>
                <div className='col-md-2 col-6'></div>
              </div>
            </div>
            <hr />

            <Paper className={classes.root}>
              {loading ? <div className={classes.loader}><Spin tip="Loading" size="large"/></div> : (
                <>
              { periodData?.length > 0 ? (
                <Grid
                  container
                  style={
                    isMobile
                      ? { width: '95%', margin: '20px auto' }
                      : { width: '100%', margin: '20px auto' }
                  }
                  spacing={5}
                >
                  <Grid item xs={12} sm={viewMore ? 7 : 12}>
                    <Grid container spacing={isMobile ? 3 : 5}>
                      {periodData.map((period, i) => (
                        <Grid
                          item
                          xs={12}
                          style={isMobile ? { marginLeft: '-8px' } : null}
                          sm={12}
                        >
                          <AssessmentCard
                            index={i}
                            filterDataDown={filterDataDown}
                            period={period}
                            setSelectedIndex={setSelectedIndex}
                            periodColor={selectedIndex === i ? true : false}
                            setPeriodColor={setPeriodColor}
                            viewMore={viewMore}
                            setLoading={setLoading}
                            setViewMore={setViewMore}
                            setViewMoreData={setViewMoreData}
                            setPeriodDataForView={setPeriodDataForView}
                            setPublishFlag={setPublishFlag}
                            tabValue={tabValue}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                  {viewMore && (
                    <Grid item xs={12} sm={5} style={{ width: '100%' }}>
                      <ViewMoreCard
                        setSelectedIndex={setSelectedIndex}
                        viewMoreData={viewMoreData}
                        setViewMore={setViewMore}
                        filterDataDown={filterDataDown}
                        periodDataForView={periodDataForView}
                        setPublishFlag={setPublishFlag}
                        tabValue={tabValue}
                      />
                    </Grid>
                  )}
                </Grid>
              ) : (
                <div className='periodDataUnavailable mt-4'>
                  <SvgIcon
                    component={() => (
                      <img
                        style={
                          isMobile
                            ? { height: '100px', width: '200px' }
                            : { height: '160px', width: '290px' }
                        }
                        src={unfiltered}
                      />
                    )}
                  />
                  <SvgIcon
                    component={() => (
                      <img
                        style={
                          isMobile
                            ? { height: '20px', width: '250px' }
                            : { height: '50px', width: '400px', marginLeft: '5%' }
                        }
                        src={selectfilter}
                      />
                    )}
                  />
                </div>
              )}

              {periodData?.length > 0 && (
                <div className='paginateData paginateMobileMargin py-2'>
                  <Pagination
                    onChange={handlePagination}
                    style={{ marginTop: 25 }}
                    count={Math.ceil(totalCount / limit)}
                    color='primary'
                    page={page}
                  />
                </div>
              )}
              </>
              )}
            </Paper>
          </div>

    </Layout>
  );
};

export default AssessmentNew;
