/* eslint-disable react/jsx-no-duplicate-props */
import React, { useContext, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { Grid, useTheme, SvgIcon, IconButton, Typography, Button } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Layout from '../../Layout';
import unfiltered from '../../../assets/images/unfiltered.svg';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import CommonBreadcrumbs from '../../../components/common-breadcrumbs/breadcrumbs';
import endpoints from '../../../config/endpoints';
import axiosInstance from '../../../config/axios';
import Loading from '../../../components/loader/loader';
import FilterFilledIcon from '../../../components/icon/FilterFilledIcon';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        margin: '-10px auto',
        boxShadow: 'none'
    },
    container: {
        maxHeight: '70vh',
        width: '100%'
    },
}));

const LessonPlanFeedback = () => {
    const classes = useStyles();
    const { setAlert } = useContext(AlertNotificationContext);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const limit = 9;
    const themeContext = useTheme();
    const [subjectList, setSubjectList] = useState([])
    const [gradeList, setGradeList] = useState([])
    const [chapterList, setChapterList] = useState([])

    const [selectedSub, setSelectedSub] = useState([])
    const [selectedGrade, setSelectedGrade] = useState([])
    const [selectedChapter, setSelectedChapter] = useState([])
    const [feedbackCount, setFeedbackCount] = useState([])
    const [ schoolList , setSchoolList ] = useState([])
    const [ selectedSchool , setSelectedSchool ] = useState([])
    const [selectedBoard,setSelectedBoard] = useState([])
    const [boardList, setBoardList] = useState([]);
    const [moduleList,setModuleList] = useState([]);
    const [selectedModuleId, setSelectedModuleId] = useState([]);
    const [selectedModule,setSelectedModule] = useState([]); 
    const [selectedBoardId,setSelectedBoardId] = useState([]);

    useEffect(() => {
        axiosInstance
            .get(`${endpoints.mapping.gradelist}`)
            .then((result) => {
                console.log(result);
                setGradeList(result.data.result.results)
            })
            .catch((error) => {

            });
            getSchool()
    }, [])

    const getSchool = () => {
        axiosInstance
            .get(`${endpoints.centralManagement.schoolList}`)
            .then((result) => {
                console.log(result);
                setSchoolList(result.data.result)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const handleGradeChange = (values) => {
        setSelectedSub([])
        setSelectedChapter([])
        if (values && values.id !== null) {
            setSelectedGrade(values);
            axiosInstance
                .get(`${endpoints.mapping.subjectList}?grade=${values.id}`)
                .then((res) => {
                    console.log(res);
                    const { results } = res.data.result;
                    let subj = [];
                    for (const i of results) {
                        subj.push({
                            subject: i.subject.subject_name,
                            id: i.id,
                        });
                    }
                    setSubjectList(subj);
                })
                .catch((err) => {
                    console.log(err, 'err');
                });
        } else {
            setSelectedGrade('');
        }
    };

  

    const handleSubjectChange = (values) => {
        setSelectedBoard([])
        if(values && values.id !== null) {
            setSelectedSub(values)
            axiosInstance
            .get(`${endpoints.masterManagement.boardMappingList}`)
            .then((result) => {
                setBoardList(result.data.result)
            })
            .catch((error) => {
                console.log(error)
            });

        } else{
            setSelectedBoard('')
        }
        
    }

      // const handleSchool = (event = {}, value = []) => {
    //     console.log(value);
    //     if (value?.length) {
    //         const ids = value.map((el) => el);
    //         const selectedId = value.map((el) => el?.id);
    //         setSelectedSchool(ids);
    //     }
    // }


    const handleBoardChange = (event={}, values=[]) => {
        setSelectedModule([])
        if (values && values.id !== null) {
            console.log(values);
            // setSelectedSub(values);
            const ids = values.map((el) => el);
            const selectedId = values.map((el) => el?.id)
            setSelectedBoard(ids);
            setSelectedBoardId(selectedId)
            axiosInstance
                .get(
                    `${endpoints.masterManagement.module}?&grade_subject=${selectedGrade?.id}&board=${selectedId}`
                )
                .then((result) => {
                    if (result.data.status_code === 200) {
                        setModuleList(result.data.result);
                    } else {
                        setAlert('error', result.data.error_message);
                    }
                })
                .catch((error) => {
                    setAlert('error', error.message);
                });
        } else {
            setSelectedModule('');
        }


    }

    const handleModuleChange = (event ={} ,values = []) => {
        setSelectedChapter([])
        // console.log(values);
        if (values && values.id !== null) {
            // setSelectedSub(values);
            // const ids = values.map((el) => el);
            // const selectedId = values.map((el) => el?.id)
            setSelectedModule(values);
            setSelectedModuleId(values?.id)
            axiosInstance
                .get(
                    `${endpoints.masterManagement.chapters}?grade_subject=${selectedSub.id}&board=${selectedBoardId}&lt_module=${values?.id}`
                )
                .then((result) => {
                    if (result.data.status_code === 200) {
                        setChapterList(result.data.result);
                    } else {
                        // setAlert('error', result.data.error_message);
                    }
                })
                .catch((error) => {
                    setAlert('error', error.message);
                });
        } else {
            setSelectedChapter('');
        }
    };

    const chapterChange = (value) => {
        setSelectedChapter(value)
    }

    const handlePagination = (event, page) => {
        setPage(page);
    };

    const handleSchool = (event = {}, value = []) => {
        console.log(value);
        if (value?.length) {
            const ids = value.map((el) => el);
            const selectedId = value.map((el) => el?.id);
            setSelectedSchool(ids);
        }
      }

    const getFeedback = () => {
        // console.log(selectedSchool);
        // console.log(selectedChapter);
        if (!selectedChapter) {
            setAlert('warning', "Please select Chapter")
        } 
        if(selectedSchool.length === 0){
            setAlert('warning', "Please select School")

        }
        if(selectedChapter.length === 0){
            setAlert('warning', "Please select Chapter!")
            return;
        }
        if(selectedModule.length === 0) {
            setAlert('warning', "Please select Module!")
            return;
        }
        if(selectedSchool.length >= 1 && selectedChapter) {
            axiosInstance
                .get(
                    `${endpoints.lessonPlan.lessonFeedback}?school_id=${selectedSchool.map((el) => el?.id)}&chapter_id=${selectedChapter.id}`
                )
                .then((result) => {
                    if (result.data.data.length == 1) {
                        console.log(result);
                        setAlert('error', 'No Ratings Present for this Period')
                        setFeedbackCount([])
                    }
                    if (result.data.data.length > 1) {
                        console.log(result);
                        setFeedbackCount(result.data.data)
                    }
                })
                .catch((error) => {
                    setAlert('error', error.message);
                });
        }
    }
    return (
        <>
            {loading ? <Loading message='Loading...' /> : null}
            <Layout>
                <div style={{ width: '95%', margin: '20px auto' }}>
                    <CommonBreadcrumbs
                        componentName='Periods'
                        childComponentName='Feedback'
                    />
                </div>
                <div style={{ display: 'flex' }} >
                <Grid item md={3} xs={12} style={{ margin: '0 10px' }} >
                <Autocomplete
                        multiple
                        style={{ width: '100%' }}
                        size='small'
                        onChange={handleSchool}
                        id='branch_id'
                        className='dropdownIcon'
                        value={selectedSchool || []}
                        options={schoolList || []}
                        getOptionLabel={(option) => option?.school_name || ''}
                        getOptionSelected={(option, value) =>
                          option?.id == value?.id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            label='School'
                            placeholder='School'
                          />
                        )}
                      />
                </Grid>
                    <Grid item md={3} xs={12} style={{ margin: '0 10px' }} >
                        <Autocomplete
                            style={{ width: '100%' }}
                            size='small'
                            className='dropdownIcon'
                            onChange={(e, value) => {
                                handleGradeChange(value);
                            }}
                            id='Book_id'
                            options={gradeList}
                            value={selectedGrade}
                            getOptionLabel={(option) => option.grade_name}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField {...params} variant='outlined' label='Grade' placeholder='Grade' />
                            )}
                        />
                    </Grid>
                    <Grid item md={3} xs={12} style={{ margin: '0 10px' }}>
                        <Autocomplete
                            style={{ width: '100%' }}
                            size='small'
                            className='dropdownIcon'
                            onChange={(e, value) => {
                                handleSubjectChange(value);
                            }}
                            id='Book_id'
                            options={subjectList}
                            value={selectedSub}
                            getOptionLabel={(option) => option.subject}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField {...params} variant='outlined' label='Subject' placeholder='Subject' />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3} style={{margin: '0 10px'}}>
                        <Autocomplete
                        multiple
                        style={{ width: '100%' }}
                        size='small'
                        onChange={handleBoardChange}
                        id='board_id'
                        className='dropdownIcon'
                        value={selectedBoard || []}
                        options={boardList || []}
                        getOptionLabel={(option) => option?.board_name || ''}
                        getOptionSelected={(option, value) =>
                          option?.id == value?.id
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            label='Board'
                            placeholder='Board'
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Autocomplete
                        // multiple
                        style={{ width: '100%' }}
                        size='small'
                        onChange={handleModuleChange}
                        id='module_id'
                        className='dropdownIcon'
                        value={selectedModule || []}
                        options={moduleList || []}
                        getOptionLabel={(option) => option?.lt_module_name || ''}
                        filterSelectedOptions
                        // getOptionSelected={(option, value) =>
                        //   option?.id == value?.id
                        // }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant='outlined'
                            label='Module'
                            placeholder='Module'
                          />
                        )}
                      />
                    </Grid>

                    <Grid item md={3} xs={12} style={{ margin: '0 10px' }}>
                        <Autocomplete
                            style={{ width: '100%' }}
                            size='small'
                            className='dropdownIcon'
                            onChange={(e, value) => {
                                chapterChange(value);
                            }}
                            id='Book_id'
                            options={chapterList}
                            value={selectedChapter}
                            getOptionLabel={(option) => option.chapter_name}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField {...params} variant='outlined' label='Chapter' placeholder='Chapter' />
                            )}
                        />
                    </Grid>
                </div>
                <div>
                    <Grid item md={2} xs={6} style={{ margin: '30px 10px' }} >
                        <Button
                            startIcon={<FilterFilledIcon />}
                            style={{ color: 'white' }}
                            size='small'
                            variant='contained'
                            color='primary'
                            fullWidth
                            onClick={() => getFeedback()}
                        >
                            Filter
                        </Button>
                    </Grid>
                </div>


                <Paper className={`${classes.root} view_user_table_wrapper`}>

                    {feedbackCount.length >= 1 ?

                        <>
                            <TableContainer
                                className={`table view_user_table table-shadow ${classes.container}`}
                            >
                                <Table stickyHeader aria-label='sticky table'>
                                    <TableHead className='view_groups_header'>
                                        <TableRow>
                                            <TableCell>Period</TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                            >
                                                Outstanding
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                            >
                                                Excellent
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                            >
                                                Very Good
                                            </TableCell>
                                            <TableCell>Good</TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                            >
                                                Above Average
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                            >
                                                Average
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                            >
                                                Below Average
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                            >
                                                Weak
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                            >
                                                Very Weak
                                            </TableCell>
                                            <TableCell
                                                className={classes.tableCell}
                                            >
                                                Average Rating
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className='view_groups_body'>
                                        {feedbackCount.map((items, i) => (
                                            <TableRow
                                                hover
                                                role='checkbox'
                                                tabIndex={-1}
                                                key={`group_table_index${i}`}
                                            >
                                                <TableCell>{items.period_name}</TableCell>
                                                <TableCell
                                                    className={classes.tableCell}
                                                >
                                                    {items.outstanding_count}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell}
                                                >
                                                    {items.excellent_count}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell}
                                                >
                                                    {items.verygood_count}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell}
                                                >
                                                    {items.good_count}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell}
                                                >
                                                    {items.aboveaverage_count}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell}
                                                >
                                                    {items.average_count}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell}
                                                >
                                                    {items.belowaverage_count}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell}
                                                >
                                                    {items.weak_count}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell}
                                                >
                                                    {items.veryweak_count}
                                                </TableCell>
                                                <TableCell
                                                    className={classes.tableCell}
                                                >
                                                    {items.average_rating}
                                                </TableCell>


                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                        :
                        <div  >
                            <Grid item md={12} xs={12} style={{display: 'flex' , justifyContent: 'center'}} >
                                <div className={classes.periodDataUnavailable}>
                                    <SvgIcon component={() => <img src={unfiltered} alt='crash' />} />
                                    <Typography variant='h6' color='secondary' style={{textAlign: 'center'}} >
                                        NO DATA FOUND
                                    </Typography>
                                </div>
                            </Grid>
                        </div>
                    }

                </Paper>
            </Layout >
        </>
    );
};

export default LessonPlanFeedback;