import React, { useState, useEffect } from 'react';
import Question from './Question';
import Filters from './filters';
import { Spin, Result, message, Empty, Pagination, Checkbox, Button } from 'antd';
import axiosInstance from '../../../config/axios';
import { SendOutlined, DownSquareTwoTone, UpSquareTwoTone } from '@ant-design/icons';
import endpoints from '../../../config/endpoints';

const QuestionList = ({ tagStatus }) => {
  const [loading, setLoading] = useState(false);
  const [publishLoading, setPublishLoading] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  const [boardList, setBoardList] = useState([]);
  const [volumeList, setVolumeList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [academicYearList, setAcademicYearList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [listCount, setListCount] = useState('');
  const [currentFilterData, setCurrentFilterData] = useState();
  const [questionTypeList, setQuestionTypeList] = useState([]);
  const [questionList, setQuestionList] = useState([]);
  const [questionStatus, setQuestionStatus] = useState(0);
  const [selectedQuestionList, setSelectedQuestionList] = useState([]);
  const [sortBy, setSortBy] = useState(2);

  const questionStatusOptionList = [
    { value: 0, label: 'All' },
    { value: 1, label: 'Draft' },
    { value: 3, label: 'For Review' },
    { value: 2, label: 'Published' },
  ];
  // API Calls
  const fetchQuestionList = (filterData) => {
    // setQuestionList([]);
    setListCount([]);
    let params;

    if (filterData?.searchValue) {
      params = {
        question_id: filterData?.searchValue,
      };
      setRequestSent(true);
      setLoading(true);
      setCurrentFilterData(filterData);
      axiosInstance
        .get(`v2/assessment/v3/question/`, {
          params: { ...params },
        })
        .then((res) => {
          if (res?.data?.status_code == 200) {
            setPageNumber(filterData?.pageNumber);
            setQuestionList(new Array(res?.data?.result));
          } else {
            setQuestionList([]);
          }
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      if (!filterData?.academicYearId) {
        message.error('Please select academic year');
        return;
      }

      if (!filterData?.gradeId) {
        message.error('Please select grade');
        return;
      }
      if (!filterData?.subjectId) {
        message.error('Please select subject');
        return;
      }
      setRequestSent(true);
      setLoading(true);
      setCurrentFilterData(filterData);
      params = {
        academic_year: filterData?.academicYearId,
        gs_id: filterData?.subjectId,
        page: filterData?.pageNumber,
        is_tagged: tagStatus == 'tagged' ? 'True' : 'False',
        sort_key: sortBy,
      };
      axiosInstance
        .get(`v2/assessment/v3/questions/`, {
          params: {
            ...params,
            ...(filterData?.chapterId ? { chapter_id: filterData?.chapterId } : {}),
            ...(filterData?.keyConceptId ? { topic_id: filterData?.keyConceptId } : {}),
            ...(filterData?.questionTypeId
              ? { question_type: filterData?.questionTypeId }
              : {}),
            ...(filterData?.difficultyLevel
              ? { question_level: filterData?.difficultyLevel }
              : {}),
            ...(filterData?.bloomLevel
              ? { question_categories: filterData?.bloomLevel }
              : {}),
            ...(questionStatus !== 0 ? { question_status: questionStatus } : {}),
          },
        })
        .then((res) => {
          if (res?.data?.status_code == 200) {
            setPageNumber(filterData?.pageNumber);
            setQuestionList(res?.data?.result?.results);
            setListCount(res?.data?.result?.count);
          } else {
            message.warning(res?.data?.message);
          }
        })
        .catch((err) => {
          message.error(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const handleBulkPublish = () => {
    setPublishLoading(true);
    let payload = {
      question: selectedQuestionList.toString(),
      question_status: 2,
    };
    axiosInstance
      .put(`v2/assessment/publish/`, payload)
      .then((res) => {
        if (res?.data?.status_code == 200) {
          message.success(res.data?.message);
          setQuestionStatus(2);
          setSelectedQuestionList([]);
        } else {
          message.error(res.data?.message);
        }
      })
      .catch((err) => {
        message.error(err.message);
      })
      .finally(() => {
        setPublishLoading(false);
      });
  };
  const fetchBoardList = () => {
    axiosInstance
      .get(`${endpoints.masterManagement.board}`)
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setBoardList(result?.data?.result);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  const fetchVolumeList = () => {
    axiosInstance
      .get(`${endpoints.masterManagement.volumes}`)
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setVolumeList(result?.data?.result?.results);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  const fetchAcademicYearList = () => {
    axiosInstance
      .get(`${endpoints.masterManagement.academicYear}`)
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setAcademicYearList(result?.data?.result?.results);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  const fetchGradeList = () => {
    axiosInstance
      .get(`${endpoints.masterManagement.grades}`)
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setGradeList(result?.data?.result?.results);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  const fetchQuestionTypeList = () => {
    axiosInstance
      .get(`/v2/assessment/v3/questiontype/`)
      .then((res) => {
        if (res?.data?.status_code == 200) {
          setQuestionTypeList(res?.data?.result);
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  const getQuestionList = () => {
    fetchQuestionList(currentFilterData);
  };

  const handleSelectQuestion = (value) => {
    if (value) {
      setSelectedQuestionList(
        questionList.filter((item) => item?.question_status !== 2).map((el) => el?.id)
      );
    } else {
      setSelectedQuestionList([]);
    }
  };
  const handleSingleQuestionSelect = (id) => {
    if (selectedQuestionList.includes(id)) {
      let list = selectedQuestionList.filter((el) => el != id);
      setSelectedQuestionList(list);
    } else {
      let list = [...selectedQuestionList];
      list.push(id);
      setSelectedQuestionList(list);
    }
  };
  useEffect(() => {
    fetchVolumeList();
    fetchBoardList();
    fetchAcademicYearList();
    fetchGradeList();
    fetchQuestionTypeList();
  }, []);
  useEffect(() => {
    if (currentFilterData?.subjectId) {
      fetchQuestionList({ ...currentFilterData, pageNumber: 1 });
    }
  }, [tagStatus, questionStatus, sortBy]);

  return (
    <div className='row pb-3'>
      <div className='col-12 pl-0'>
        <Filters
          fetchQuestionList={fetchQuestionList}
          loading={loading}
          gradeList={gradeList}
          volumeList={volumeList}
          boardList={boardList}
          academicYearList={academicYearList}
          questionTypeList={questionTypeList}
          singleQuestion={currentFilterData?.searchValue ? true : false}
          tagStatus={tagStatus == 'tagged' ? true : false}
        />
      </div>
      <div className='row py-3'>
        {!requestSent ? (
          <div className='col-12 text-center'>
            <Result status='warning' title='Please select the filters to show data' />
          </div>
        ) : (
          <div className='col-12 text-center'>
            {/* {!currentFilterData?.searchValue && ( */}
            {!currentFilterData?.searchValue ? (
              <div className='row py-2 justify-content-between align-items-center'>
                <div className='col-sm-4 pl-2'>
                  {tagStatus == 'tagged' && questionStatus !== 2 && (
                    <div className='d-flex th-checkbox align-items-center'>
                      <Checkbox
                        style={{ fontSize: '16px' }}
                        checked={
                          !loading &&
                          questionList.length > 0 &&
                          selectedQuestionList.length ==
                            questionList.filter((item) => item.question_status !== 2)
                              .length
                        }
                        onChange={(e) => {
                          handleSelectQuestion(e.target.checked);
                        }}
                      >
                        Select All
                      </Checkbox>
                      <div className='ml-4 w-50'>
                        <Button
                          type='primary'
                          icon={<SendOutlined />}
                          loading={publishLoading}
                          disabled={selectedQuestionList?.length == 0}
                          className='w-100'
                          onClick={() => {
                            handleBulkPublish();
                          }}
                        >
                          Bulk Publish
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                <>
                  <div className='col-8 '>
                    <div className='d-flex justify-content-end align-items-center'>
                      <div className='d-flex align-items-center justify-content-end'>
                        {questionStatusOptionList?.map((el) => {
                          return (
                            <div className='px-2' style={{ width: 140 }}>
                              <div
                                className={`th-bg-grey th-pointer th-14 p-2 ${
                                  questionStatus == el?.value
                                    ? 'th-custom-selected-tab'
                                    : 'th-custom-tab'
                                }`}
                                onClick={() => {
                                  setQuestionStatus(el?.value);
                                  setSelectedQuestionList([]);
                                }}
                              >
                                {el?.label}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className=''>
                        <span className='th-14'>Sort By: </span>

                        <DownSquareTwoTone
                          twoToneColor={sortBy == 2 ? '#1b4ccb' : '#d9d9d9'}
                          title='Newest to Oldest'
                          onClick={() => {
                            setSortBy(2);
                          }}
                          className=' th-pointer'
                        />
                        <UpSquareTwoTone
                          twoToneColor={sortBy == 1 ? '#1b4ccb' : '#d9d9d9'}
                          title='Oldest to Newest'
                          onClick={() => {
                            setSortBy(1);
                          }}
                          className='mr-1 th-pointer'
                        />
                      </div>
                    </div>
                  </div>
                </>
              </div>
            ) : (
              currentFilterData?.searchValue &&
              questionList[0]?.question_status !== 2 &&
              questionList.length > 0 && (
                <div className='row py-2 justify-content-between align-items-center'>
                  <div className='col-sm-4 pl-1'>
                    <div className='w-50'>
                      <Button
                        type='primary'
                        icon={<SendOutlined />}
                        loading={publishLoading}
                        disabled={selectedQuestionList?.length == 0}
                        className='w-100'
                        onClick={() => {
                          handleBulkPublish();
                        }}
                      >
                        Publish
                      </Button>
                    </div>
                  </div>
                </div>
              )
            )}
            <div className={`${loading ? 'py-5' : ''}`}>
              <Spin tip='Loading...' size='large' spinning={loading}>
                {questionList?.length > 0 ? (
                  <>
                    {questionList?.map((item, index) => {
                      return (
                        <Question
                          currentQuestion={item}
                          index={index}
                          gradeList={gradeList}
                          volumeList={volumeList}
                          boardList={boardList}
                          currentPage={pageNumber}
                          questionTypeList={questionTypeList}
                          getQuestionList={getQuestionList}
                          isQuestionTagged={item?.is_tagged}
                          selectedQuestionList={selectedQuestionList}
                          handleSingleQuestionSelect={handleSingleQuestionSelect}
                        />
                      );
                    })}

                    <div className='text-center'>
                      <Pagination
                        current={pageNumber}
                        hideOnSinglePage={false}
                        showSizeChanger={false}
                        pageSize={15}
                        onChange={(page) => {
                          setPageNumber(page);
                          fetchQuestionList({ ...currentFilterData, pageNumber: page });
                        }}
                        total={listCount}
                      />
                    </div>
                  </>
                ) : (
                  <div className='py-5'>
                    {loading ? null : (
                      <Empty
                        description={
                          !currentFilterData?.searchValue
                            ? 'No Questions created for this Grade and Subject'
                            : 'No question found'
                        }
                      />
                    )}
                  </div>
                )}
              </Spin>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default QuestionList;
