import React from 'react';
import { TextField } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';
import '../assess-attemption.css';

const FillUpsQuestion = (props) => {
  const { questionObj: currentQuestionObj } = props || {};
  const { question_answer } = currentQuestionObj || {};
  const [{ options, question }] = question_answer;

  return (
    <div>
      <div className='mcq-question-wrapper'>
        <div style={{ fontSize: '30px' }}>{ReactHtmlParser(question)}</div>

        {options.length &&
          options.map((option, index) => {
            return (
              <TextField
                id='outlined-basic'
                value={
                  currentQuestionObj?.user_response?.answer
                    ? currentQuestionObj?.user_response?.answer[index]
                    : ''
                }
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  autoComplete: 'off',
                }}
                label={`Option ${index + 1}`}
                variant='outlined'
              />
            );
          })}
      </div>
    </div>
  );
};

export default FillUpsQuestion;
