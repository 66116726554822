import React, { useEffect, useState, useContext } from 'react';
import {
  Divider,
  Grid,
  withStyles,
  TextField,
  Typography,
  IconButton,
  Button,
  Card,
  CardContent,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Dropzone from 'react-dropzone';
import endpoints from '../../config/endpoints';
import axiosInstance from '../../config/axios';
import Loading from '../../components/loader/loader';
import { AlertNotificationContext } from '../../context-api/alert-context/alert-state';
import DisableDashboardBreadcrumbs from '../../components/common-breadcrumbs/disableDesktop';
import styles from './ebookStyle';
import ConfirmDialog from '../../components/confirm-dialog';

const EditEbook = ({ classes, open, fullDate, handleClose }) => {
  const { setAlert } = useContext(AlertNotificationContext);
  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [description, setDescription] = useState('');
  const [files, setFile] = useState('');
  const [loading, setLoading] = useState(false);
  const [conFirmDelete, setConFirmDelete] = useState(false);
  const bookTypeChoices = [
    { label: 'General', value: '1' },
    { label: 'Curriculum', value: '2' },
  ];

  const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant='h6'>{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  const getFileNameAndSize = (files) => {
    if (files?.length) {
      const fileName =
        files &&
        files?.map((file) => (
          <li key={file.name}>{`${file.name} -${file.size} bytes`}</li>
        ));
      return fileName;
    }
    return null;
  };

  useEffect(() => {
    if (open && fullDate) {
      console.log(fullDate, 'full dtatatat');
      setTitle(fullDate?.ebook_name);
      setAuthor(fullDate?.ebook_author);
      setDescription(fullDate?.ebook_description);
      setFile(fullDate?.ebook_file_type);
    }
  }, [open, fullDate]);

  const isPDF = (files) => {
    if (files[0].name.match(/.(pdf)$/i)) {
      return true;
    }
    return false;
  };

  const onDrop = (files = []) => {
    if (!isPDF(files)) {
      setAlert('error', 'Please select only PDF format');
      return;
    }
    if (files.length > 1) {
      setAlert('error', 'You can select only a single PDF at once');
      return;
    }
    const sampleFile = files;
    setFile(sampleFile);
  };

  function handleSubmit() {
    if (!title) {
      setAlert('error', 'Please Enter Book Title');
      return;
    }
    if (!author) {
      setAlert('error', 'Please Enter Author Name');
      return;
    }
    if (!description) {
      setAlert('error', 'Please Enter Description');
      return;
    }
    if (!files) {
      setAlert('error', 'Please upload pdf file');
      return;
    }
    const formData = new FormData();
    if (typeof files !== 'string') {
      for (let i = 0; i < files.length; i += 1) {
        formData.append('files', files[i]);
      }
    }
    formData.set('ebook_id', fullDate.id);
    formData.set('ebook_name', title);
    formData.set('ebook_description', description);
    formData.set('ebook_author', author);
    axiosInstance
      .put(`${endpoints.ebook.ebook}`, formData)
      .then((result) => {
        if (result.status === 200) {
          setLoading(false);
          setAlert('success', 'SUCCESSFULLY UPDATED EBOOK');
          handleClose('success');
        } else {
          setLoading(false);
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.message);
      });
  }

  function handleDelete() {
    setAlert('success', 'SUCCESSFULLY DELETED FILE');
    setFile('');
    setTimeout(() => {
      setConFirmDelete(false);
    }, 1000);
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Dialog
          maxWidth='xl'
          open={open}
          disableEnforceFocus
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          closeAfterTransition
          style={{ zIndex: conFirmDelete ? '' : '10000' }}
        >
          <DialogTitle id='alert-dialog-title' onClose={handleClose}>
            Update Ebook
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={2} style={{ textAlign: 'center' }}>
              <Grid item md={12} xs={12} style={{ textAlign: 'left' }}>
                <DisableDashboardBreadcrumbs
                  componentName={[
                    fullDate?.academic_year?.session_year,
                    fullDate?.volume?.volume_name,
                    fullDate?.grade?.grade_name,
                    fullDate?.subject?.subject_name,
                    bookTypeChoices[parseInt(fullDate?.ebook_type, 10) - 1]?.label || '',
                  ]}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  value={title}
                  variant='outlined'
                  margin='dense'
                  label='Book Title'
                  placeholder='Book Title'
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  value={author}
                  variant='outlined'
                  margin='dense'
                  label='Author Name'
                  placeholder='Author Name'
                  onChange={(e) => setAuthor(e.target.value)}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  value={description}
                  variant='outlined'
                  margin='dense'
                  label='Description'
                  placeholder='Description'
                  multiline
                  rows={3}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>
              <Grid item md={12} xs={12} style={{ textAlign: 'left' }}>
                {files && typeof files === 'string' ? (
                  <Typography variant='h6' color='secondary'>
                    View Uploaded file&nbsp;
                    <a href={files} target='_blank' without rel='noreferrer'>
                      View File
                    </a>
                    &nbsp;
                    <IconButton
                      size='small'
                      color='primary'
                      onClick={() => setConFirmDelete(true)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Typography>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                      <Typography variant='h6' color='secondary'>
                        Upload book in pdf format
                      </Typography>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Dropzone onDrop={onDrop}>
                        {({
                          getRootProps,
                          getInputProps,
                          isDragActive,
                          isDragAccept,
                          isDragReject,
                        }) => (
                          <Card
                            elevation={0}
                            style={{
                              width: '320px',
                              height: '150px',
                              border: '1px solid #ff6b6b',
                            }}
                            {...getRootProps()}
                            className='dropzone'
                          >
                            <CardContent>
                              <input {...getInputProps()} />
                              <div style={{ textAlign: 'center', marginTop: '40px' }}>
                                {isDragAccept && 'All files will be accepted'}
                                {isDragReject && 'Some files will be rejected'}
                                {!isDragActive && (
                                  <Button
                                    variant='text'
                                    style={{ background: 'none', color: '#014b7e' }}
                                  >
                                    <CloudUploadIcon />
                                    &nbsp;Drop file
                                  </Button>
                                )}
                              </div>
                              {getFileNameAndSize(files)}
                            </CardContent>
                          </Card>
                        )}
                      </Dropzone>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item md={12} xs={12}>
                <Button
                  disabled={!title || !author || !description || !files}
                  variant='contained'
                  color='primary'
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
      {conFirmDelete ? (
        <ConfirmDialog
          open={conFirmDelete}
          cancel={setConFirmDelete}
          confirm={handleDelete}
          title='Are You Sure To Delete this File'
        />
      ) : (
        ''
      )}
    </>
  );
};

export default withStyles(styles)(EditEbook);
