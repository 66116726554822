import React, { useState, useRef } from 'react';
import { IconButton, Divider, TextField, Button, SvgIcon } from '@material-ui/core';
import minimizeIcon from '../../../../assets/images/minimize.svg';
import maximizeIcon from '../../../../assets/images/maximize.svg';
import Question from '../question';
import './styles.scss';
import { useLocation } from 'react-router-dom';
const QuestionPaper = ({
  grade,
  subject,
  level,
  sections,
  handleAddSection,
  onCreateQuestionPaper,
  onChangePaperName,
  questionPaperName,
  onDeleteSection,
  onDeleteQuestion,
  // updateQuestionPaper
}) => {
  const [minimize, setMinimize] = useState(false);
  const location = useLocation();
  const [noOfSections, setNoOfSections] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const addNewContainerRef = useRef(null);
  const updateQuestionPaper = Number(location.pathname.slice(23));
  const handleAddNewSection = () => {
    setNoOfSections(noOfSections => noOfSections + 1);
    handleAddSection(noOfSections);
  };
  return (
    <div className='create-container'>
      <div className='header'>
        <div className='applied-filters'>
          <div className='filter'>{grade}</div>
          {subject && <span className='dot'>.</span>}
          <div className='filter'>{subject}</div>
          {subject && <span className='dot'>.</span>}
          <div className='filter'>{level}</div>
        </div>
        {/* <div className='icon'>
          <img src={productIcon} alt='product' />
        </div> */}
      </div>
      <div className='questions-paper-container'>
        <div className='minimize-container'>
          <span className='info'>{!minimize ? 'Minimize' : 'Maximize'}</span>
          {!minimize ? (
            <IconButton
              onClick={() => {
                setMinimize(true);
              }}
            >
              <SvgIcon component={() => <img src={minimizeIcon} alt='minimize' />} />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                setMinimize(false);
              }}
            >
              <SvgIcon component={() => <img src={maximizeIcon} alt='maximize' />} />
            </IconButton>
          )}
        </div>

        {!minimize && (
          <>
            <div className='form-field'>
              <TextField
                id='outlined-search'
                label='Question paper name'
                variant='outlined'
                size='small'
                className='dropdownIcon'
                fullWidth
                onChange={onChangePaperName}
                value={questionPaperName || ''}
                inputProps={{
                  autoComplete: 'off',
                  maxLength: 100
                }}
              />
            </div>
            <div className='questions-container'>
              <div className='questions-header'>Questions</div>
              <div className='divider-container'>
                <Divider color='secondary' />
              </div>
              <div className='questions-content'>
                {sections?.map((question) => (
                  <Question question={question} onDeleteSection={onDeleteSection} onDeleteQuestion={onDeleteQuestion} />
                ))}

                <div className='add-new' ref={addNewContainerRef}>
                  <div className='info'>Add sections for this question paper</div>
                  <div
                    className='add-new-btn-container'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >

                    <div
                      className='action'
                      style={{ cursor: 'pointer' }}
                      onClick={handleAddNewSection}
                    >
                      ADD NEW
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className='submit-btn-conntainer mv-20'>
          <Button
            style={{ borderRadius: '10px' }}
            className='outlined-btn'
            onClick={() => {
              onCreateQuestionPaper(true);
            }}
          >
            Save as draft
          </Button>

          <Button
            variant='contained'
            style={{ borderRadius: '10px' }}
            color='primary'
            onClick={() => {
              onCreateQuestionPaper(false);
            }}
          >
            {updateQuestionPaper ? 'Update' : 'Submit'}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default QuestionPaper;
