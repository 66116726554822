/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-debugger */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
import React, { useContext, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, TextField, Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import BlockIcon from '@material-ui/icons/Block';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TableRow from '@material-ui/core/TableRow';
import CommonBreadcrumbs from '../../../components/common-breadcrumbs/breadcrumbs';
import axiosInstance from '../../../config/axios';
import endpoints from '../../../config/endpoints';
import Layout from '../../Layout';
import AddEditUser from '../create-user/create-user';
import Loading from '../../../components/loader/loader';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import './view-user.css';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
    width: '95%',
    margin: 'auto',
    boxShadow: 'none',
    [theme.breakpoints.down('xs')]: {
      width: '90vw',
      marginLeft: '5px',
      marginTop: '5px',
      marginBottom: '15px',
      maxHeight: '80vh',
    },
  },
  container: {
    maxHeight: 440,
  },
}));

// eslint-disable-next-line no-unused-vars
const ViewUsers = withRouter(({ history, ...props }) => {
  const classes = useStyles();
  const { setAlert } = useContext(AlertNotificationContext);
  const { token } = JSON.parse(localStorage.getItem('userDetails')) || {};
  const [isHidden, setIsHidden] = useState(window.innerWidth < 600);
  const [groupsData, setGroupsData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [editGroupId, setEditGroupId] = useState(0);
  const [page,setPage]=useState(1)
  const [deleteId, setDeleteId] = useState(null);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [editUser, setEditUser] = useState({});
  const [roleList, setRoleList] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState();
  const activeList = ['active', 'inactive'];
  const [selectedActive, setSelectedActive] = useState();
  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false)
  const [limit,setLimit]=useState(15)

  const getRoles = async () => {
    try {
      const result = await axiosInstance.get(endpoints.user.getRoles, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (result.status === 200) {
        setRoleList(result.data.result);
      } else {
        setAlert('error', result.data.message);
      }
    } catch (error) {
      setAlert('error', error.message);
    }
  };

  const getUsersData = async () => {
    let getUserDataUrl = `${endpoints.user.viewUsers}?page=${page}&page_size=${limit}`;
    if (selectedRoles) {
      getUserDataUrl += `&role_type=${selectedRoles.id}`;
    }
    if (selectedActive) {
      getUserDataUrl += `&is_active=${selectedActive === 'active' ? 'true' : 'false'}`;
    }
    try {
      setLoading(true);
      const result = await axiosInstance.get(getUserDataUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const resultGroups = [];
      if (result.status === 200) {
        setLoading(false);
        result.data.result.result.map((items) =>
          resultGroups.push({
            groupId: items.id,
            userName: items.user.first_name,
            phoneNo: items.phone_number,
            roleType: items.role_type.role_name,
            email: items.user.email,
            roleId: items.role_type.id,
            active: items.is_active,
            profile: items.profile,
          })
        );
        setGroupsData(resultGroups);
        setTotalPages(result.data.result.count);
      } else {
        setAlert('error', result.data.message);
        setLoading(false);
      }
    } catch (error) {
      setAlert('error', error.message);
      setLoading(false);
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage+1);
  };
  const toggleHide = () => {
    setIsHidden(!isHidden);
  };
  const handleStatusChange = async (id, index) => {
    try {
      setLoading(true);
      const statusChange = await axiosInstance.put(
        `${endpoints.user.centralAdminCommon}${id}/change-user_status/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (statusChange.status === 200) {
        setLoading(false);
        setAlert('success', statusChange.data.message);
        const tempGroupData = groupsData.slice();
        tempGroupData[index].active = groupsData[index].active
          ? !groupsData[index].active
          : true;
        setGroupsData(tempGroupData);
      } else {
        setAlert('error', statusChange.data.message);
        setLoading(false);
      }
    } catch (error) {
      setAlert('error', error.message);
      setLoading(false);
    }
  };
  const handleDelete = async (id, index) => {
    setDeleteId(id);
    setDeleteIndex(index);
    setDeleteAlert(true);
  };

  const handleRoleChange = (event, value) => {
    setPage(1);
    if (value) {
      setSelectedRoles(value);
    } else {
      setSelectedRoles();
    }
  };

  const handleActiveChange = (event, value) => {
    setPage(1);
    if (value) {
      setSelectedActive(value);
    } else {
      setSelectedActive();
    }
  };

  const handleDeleteConfirm = async () => {
    try {
      setLoading(true);
      const statusChange = await axiosInstance.delete(
        `${endpoints.user.centralAdminCommon}${deleteId}/delete-user/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (statusChange.status === 200) {
        setLoading(false);
        setAlert('success', statusChange.data.message);
        const tempGroupData = groupsData.slice();
        tempGroupData.splice(deleteIndex, 1);
        setGroupsData(tempGroupData);
        setDeleteId(null);
        setDeleteIndex(null);
        setDeleteAlert(false);
      } else {
        setAlert('error', statusChange.data.message);
        setLoading(false);
      }
    } catch (error) {
      setAlert('error', error.message);
      setLoading(false);
    }
  };
  const handleDeleteCancel = () => {
    setDeleteId(null);
    setDeleteIndex(null);
    setDeleteAlert(false);
  };
  const handleEdit = (id, index) => {
    setEditGroupId(id);
    setEditUser({
      id: groupsData[index].groupId,
      name: groupsData[index].userName,
      email: groupsData[index].email,
      contact: groupsData[index].phoneNo,
      role: { id: groupsData[index].roleId, role_name: groupsData[index].roleType },
      profile: groupsData[index].profile,
    });
    setEditing(true);
  };
  useEffect(() => {
    getUsersData();
  }, [page, selectedRoles, selectedActive]);
  useEffect(() => {
    getRoles();
  }, []);
  useEffect(() => {
    if (!editing && editGroupId) {
      setEditGroupId(0);
      setEditUser({});
      getUsersData();
    }
  }, [editing]);
  return (
    <>
      {loading ? <Loading message='Loading...' /> : null}
      {editing ? (
        <AddEditUser editClose={setEditing} userDetails={editUser} />
      ) : (
        <Layout>
          <div className='creategroup__page'>
            <div className='view_users_breadcrumb_wrapper'>
              <CommonBreadcrumbs componentName='View users' />
            </div>
            <div className='filter_wrapper'>
              <Grid container className='view_users_container' spacing={5}>
                <Grid item xs={12} lg={6}>
                  <Autocomplete
                    size='small'
                    onChange={handleRoleChange}
                    value={selectedRoles}
                    id='add_edit_user-role'
                    className='add_edit_user_role'
                    options={roleList}
                    getOptionLabel={(option) => option?.role_name}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        className='add_edit_user-textfield'
                        {...params}
                        variant='outlined'
                        label='Role'
                        placeholder='Role'
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    size='small'
                    onChange={handleActiveChange}
                    value={selectedActive}
                    id='message_log-smsType'
                    className='add_edit_user_role'
                    options={activeList}
                    getOptionLabel={(option) => option}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField
                        className='message_log-textfield'
                        {...params}
                        variant='outlined'
                        label='Status'
                        placeholder='Status'
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </div>
            {deleteAlert ? (
              <Dialog
                open={deleteAlert}
                onClose={handleDeleteCancel}
                className='view_group_delete_modal'
              >
                <DialogTitle
                  className='view_group_delete_modal_title'
                  style={{ cursor: 'move' }}
                  id='draggable-dialog-title'
                >
                  Delete Group
                </DialogTitle>
                <DialogContent>
                  <DialogContentText className='view_group_delete_alert_tag'>
                    Do you want to Delete the User
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    autoFocus
                    onClick={handleDeleteCancel}
                    className='view_group_delete_alert_button_cancel'
                    color='secondary'
                  >
                    Cancel
                  </Button>
                  <Button
                    className='view_group_delete_alert_button'
                    onClick={handleDeleteConfirm}
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            ) : null}
            <div className='view_group_white_space_wrapper'>
              {isHidden ? (
                <span className='message_log_expand_manage' onClick={toggleHide}>
                  view more
                </span>
              ) : (
                <span className='message_log_expand_manage' onClick={toggleHide}>
                  view less
                </span>
              )}
              <Paper className={`${classes.root} view_user_table_wrapper`}>
                <TableContainer
                  className={`table view_user_table table-shadow ${classes.container}`}
                >
                  <Table stickyHeader aria-label='sticky table'>
                    <TableHead className='view_groups_header'>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell
                          className={`${classes.tableCell} ${isHidden ? 'hide' : 'show'}`}
                        >
                          Phone No
                        </TableCell>
                        <TableCell
                          className={`${classes.tableCell} ${isHidden ? 'hide' : 'show'}`}
                        >
                          Role Type
                        </TableCell>
                        <TableCell
                          className={`${classes.tableCell} ${isHidden ? 'hide' : 'show'}`}
                        >
                          Email
                        </TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell
                          className={`${classes.tableCell} ${isHidden ? 'hide' : 'show'}`}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className='view_groups_body'>
                      {groupsData.map((items, i) => (
                        <TableRow
                          hover
                          role='checkbox'
                          tabIndex={-1}
                          key={`group_table_index${i}`}
                        >
                          <TableCell>{items.userName}</TableCell>
                          <TableCell
                            className={`${classes.tableCell} ${
                              isHidden ? 'hide' : 'show'
                            }`}
                          >
                            {items.phoneNo}
                          </TableCell>
                          <TableCell
                            className={`${classes.tableCell} ${
                              isHidden ? 'hide' : 'show'
                            }`}
                          >
                            {items.roleType}
                          </TableCell>
                          <TableCell
                            className={`${classes.tableCell} ${
                              isHidden ? 'hide' : 'show'
                            }`}
                          >
                            {items.email}
                          </TableCell>
                          <TableCell>
                            {items.active ? (
                              <div style={{ color: 'green' }}>Activated</div>
                            ) : (
                              <div style={{ color: 'red' }}>Deactivated</div>
                            )}
                          </TableCell>
                          <TableCell
                            className={`${classes.tableCell} ${
                              isHidden ? 'hide' : 'show'
                            }`}
                          >
                            {items.active ? (
                              <IconButton
                                aria-label='deactivate'
                                onClick={() => handleStatusChange(items.groupId, i)}
                                title='Deactivate'
                              >
                                <BlockIcon style={{ color: '#ff6b6b' }} />
                              </IconButton>
                            ) : (
                              <button
                                type='submit'
                                title='Activate'
                                onClick={() => handleStatusChange(items.groupId, i)}
                                style={{
                                  borderRadius: '50%',
                                  backgroundColor: 'green',
                                  border: 0,
                                  width: '30px',
                                  height: '30px',
                                  color: '#ffffff',
                                  cursor: 'pointer',
                                }}
                              >
                                A
                              </button>
                            )}
                            <IconButton
                              title='Delete'
                              onClick={() => handleDelete(items.groupId, i)}
                            >
                              <DeleteOutlinedIcon style={{ color: '#ff6b6b' }} />
                            </IconButton>
                            <IconButton
                              title='Edit'
                              onClick={() => handleEdit(items.groupId, i)}
                            >
                              <EditOutlinedIcon style={{ color: '#ff6b6b' }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className={`${classes.root} pagenation_view_groups`}>
                  <TablePagination
                  component='div'
                  count={totalPages}
                  rowsPerPage={limit}
                  page={page-1}
                  onChangePage={handleChangePage}
                  rowsPerPageOptions={false}
                  className='table-pagination-view-group'
                  />
                </div>
              </Paper>
            </div>
          </div>
        </Layout>
      )}
    </>
  );
});

export default ViewUsers;
