import React, { useContext, useState, useEffect } from 'react';
import { Grid, TextField, Button, useTheme, Divider } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import endpoints from '../../../config/endpoints';
import axiosInstance from '../../../config/axios';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import { useHistory } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';

const EditTopic = ({ topicData, setLoading, handleGoBack }) => {
  // console.log({ topicData, setLoading, handleGoBack }, 'watch77')
  // console.log(topicData,'dummy')
  // console.log(topicData)
  const history = useHistory();
  const topicUpdateApi = '/update-topic/';
  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const { setAlert } = useContext(AlertNotificationContext);
  // const[board_name, setBoard_name] = useState(moduleData?.topic_name || '')
  const [moduleDropdown, setModuleDropdown] = useState([]);
  const [topicName, setTopicName] = useState(topicData.topic_name || '');
  const [subjectName, setSubjectName] = useState(
    topicData?.grade_subject_mapping?.subject?.subject_name
  );
  const [academicYearDropdown, setAcademicYearDropdown] = useState([]);
  const [volumeDropdown, setVolumeDropdown] = useState([]);
  const [gradeDropdown, setGradeDropdown] = useState([]);
  const [subjectDropdown, setSubjectDropdown] = useState([]);
  const [boardDropdown, setBoardDropdown] = useState([]);
  const [selectedBoardId, setSelectedBoardId] = useState([]);
  const [chapterDropdown, setChapterDropdown] = useState([]);
  const [gradeSubjectMapping, setGradeSubjectMapping] = useState('');
  const [chapterId, setChapterId] = useState(topicData?.chapter?.id);
  const [topicId, setTopicId] = useState(topicData.id);
  const [filterData, setFilterData] = useState({
    year: topicData?.academic_year,
    volume: topicData?.volume,
    grade: topicData?.grade_subject_mapping?.grade,
    subject: topicData?.grade_subject_mapping?.subject,
    board: topicData?.board,
    module: topicData?.lt_module,
    chapter: topicData?.chapter,
  });
  const [keyConceptSequence, setKeyConceptSequence] = useState(topicData?.sequence);

  useEffect(() => {
    axiosInstance
      .get(`${endpoints.masterManagement.academicYear}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setAcademicYearDropdown(result.data.result.results);
          // handleAcademicYear('', result.data.current_acad_session_data[0])
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });

    axiosInstance
      .get(`${endpoints.masterManagement.volumes}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setVolumeDropdown(result.data.result.results);
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });

    axiosInstance
      .get(`${endpoints.masterManagement.grades}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setGradeDropdown(result.data.result.results);
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });
  }, []);

  const handleAcademicYear = (event, value) => {
    setFilterData({ ...filterData, year: '' });
    if (value) {
      setFilterData({ ...filterData, year: value });
    }
  };

  const handleVolume = (event, value) => {
    setFilterData({ ...filterData, volume: '' });
    if (value) {
      setFilterData({ ...filterData, volume: value });
    }
  };

  const handleGrade = (event, value) => {
    setFilterData({ ...filterData, grade: '' });
    if (value) {
      setFilterData({ ...filterData, grade: value });
      axiosInstance
        .get(`${endpoints.masterManagement.gradeSubjectMappingList}?grade=${value.id}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            setSubjectDropdown(result.data.result.results);
          } else {
            setAlert('error', result.data.message);
            setSubjectDropdown([]);
          }
        })
        .catch((error) => {
          setAlert('error', error.message);
          setSubjectDropdown([]);
        });
    } else {
      setSubjectDropdown([]);
    }
  };

  const handleBoard = (event, value) => {
    setFilterData({ ...filterData, board: '' });
    if (value) {
      setFilterData({ ...filterData, board: value });
      axiosInstance
        .get(
          `${endpoints.masterManagement.module}?academic_year=${filterData?.year?.id}&volume=${filterData?.volume?.id}&grade_subject=${filterData?.subject?.id}&board=${value?.id}`
        )
        .then((result) => {
          if (result.data.status_code === 200) {
            console.log(result.data.result);
            setModuleDropdown(result.data.result);
          } else {
            setAlert('error', result.data.message);
            setModuleDropdown([]);
          }
        })
        .catch((error) => {
          setAlert('error', error.message);
          setModuleDropdown([]);
        });
    } else {
      setModuleDropdown([]);
    }
  };

  const handleSubject = (event, value) => {
    setFilterData({ ...filterData, subject: '' });
    if (value) {
      setFilterData({ ...filterData, subject: value });
      axiosInstance
        .get(
          `${endpoints.masterManagement.gradeSubjectMappingList}?grade=${filterData.grade?.id}&subject=${value?.subject?.subject_name}`
        )
        .then((response) => {
          if (response.data.status_code === 200) {
            console.log('response', response, filterData);
            setGradeSubjectMapping(response?.data?.result?.results[0]?.id);
          }
        });
      axiosInstance
        .get(`${endpoints.masterManagement.boardMappingList}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            setBoardDropdown(result.data.result);
            // console.log(result.data.result,'hargsy77777')
          } else {
            setAlert('error', result.data.message);
            setBoardDropdown([]);
          }
        })
        .catch((error) => {
          setAlert('error', error.message);
          setBoardDropdown([]);
        });
    } else {
      setBoardDropdown([]);
    }
  };

  const handleModule = (event, value) => {
    setFilterData({ ...filterData, module: '' });
    if (value) {
      setFilterData({ ...filterData, module: value });
    }
  };

  const handleChapter = (event, value) => {
    setFilterData({ ...filterData, chapter: '' });
    if (value) {
      setFilterData({ ...filterData, chapter: value });
    }
  };

  useEffect(() => {
    if (
      filterData?.subject?.id &&
      filterData?.board?.length !== 0 &&
      filterData?.module?.length !== 0
    ) {
      axiosInstance
        .get(
          `${endpoints.masterManagement.chapters}?academic_year=${filterData?.year?.id}&grade_subject=${filterData.subject.id}&board=${filterData?.board?.id}&lt_module=${filterData?.module?.id}`
        )
        .then((res) => {
          if (res.data.status_code === 200) {
            setChapterDropdown(res.data.result);
          } else {
            // setAlert('error', res.data.message);
          }
        })
        .catch((error) => setAlert('error', error.message));
    } else if (filterData?.subject?.id && filterData?.board?.length !== 0) {
      axiosInstance
        .get(
          `${endpoints.masterManagement.chapters}?academic_year=${filterData?.year?.id}&grade_subject=${filterData.subject.id}&board=${filterData?.board?.id}`
        )
        .then((res) => {
          if (res.data.status_code === 200) {
            setChapterDropdown(res.data.result);
          } else {
            // setAlert('error', res.data.message);
          }
        })
        .catch((error) => setAlert('error', error.message));
    }
  }, [filterData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let request = {};
    request['id'] = topicData?.id;
    if (
      (topicName !== topicData.topic_name && topicName !== '') ||
      keyConceptSequence !== ''
    ) {
      request['topic_name'] = topicName.trim();
      // request["chapter"] = chapterId
      request['chapter'] = filterData?.chapter?.id;
      request['board'] = filterData?.board?.id;
      request['grade_subject_mapping'] = filterData?.grade.id;
      request['academic_year'] = filterData?.year?.id;
      request['volume'] = filterData?.volume?.id;
      request['subject'] = filterData?.subject?.id;
      request['lt_module_name'] = filterData?.module?.id;
      request['sequence'] = Number(keyConceptSequence);

      axiosInstance
        .put(
          `${endpoints.masterManagement.updateTopic}${topicId}${topicUpdateApi}`,
          request
        )
        .then((result) => {
          if (result.data.status_code === 200) {
            setTopicName('');
            setLoading(false);
            setAlert('success', result.data.message);
            handleGoBack();
          } else {
            if (result.data.status_code === 404) {
              setAlert('error', result.data.description);
              setLoading(false);
            } else {
              setLoading(false);
              setAlert('error', result.data.message);
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.response.data.message);
        });
    } else {
      setAlert('error', 'No Fields to Update!');

      setLoading(false);
    }
  };

  return (
    <form autoComplete='off' onSubmit={handleSubmit}>
      <div style={{ width: '95%', margin: '20px auto' }}>
        <Grid container spacing={5}>
          <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <Autocomplete
              size='small'
              onChange={handleAcademicYear}
              style={{ width: '100%' }}
              id='year'
              options={academicYearDropdown}
              value={filterData.year}
              getOptionLabel={(option) => option?.session_year}
              filterSelectedOptions
              disabled
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Academic Year'
                  placeholder='Academic Year'
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <Autocomplete
              size='small'
              onChange={handleVolume}
              style={{ width: '100%' }}
              id='volume'
              options={volumeDropdown}
              value={filterData.volume}
              disabled
              getOptionLabel={(option) => option?.volume_name}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Volume'
                  placeholder='Volume'
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <Autocomplete
              size='small'
              onChange={handleGrade}
              style={{ width: '100%' }}
              id='grade'
              options={gradeDropdown}
              disabled
              value={filterData.grade}
              getOptionLabel={(option) => option?.grade_name}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Grades'
                  placeholder='Grades'
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <Autocomplete
              size='small'
              onChange={handleSubject}
              style={{ width: '100%' }}
              id='subject'
              options={subjectDropdown}
              value={filterData.subject}
              disabled
              getOptionLabel={(option) => option?.subject_name}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Subject'
                  placeholder='Subject'
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <Autocomplete
              size='small'
              onChange={handleBoard}
              style={{ width: '100%' }}
              id='board'
              options={boardDropdown}
              value={filterData.board}
              disabled
              getOptionLabel={(option) => option?.board_name}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Board'
                  placeholder='Board'
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <Autocomplete
              size='small'
              onChange={handleModule}
              style={{ width: '100%' }}
              id='module'
              options={moduleDropdown}
              disabled
              value={filterData.module}
              getOptionLabel={(option) => option?.lt_module_name}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Module'
                  placeholder='Module'
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <Autocomplete
              size='small'
              onChange={handleChapter}
              style={{ width: '100%' }}
              id='chapter'
              options={chapterDropdown}
              value={filterData.chapter}
              disabled
              getOptionLabel={(option) => option?.chapter_name}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Chapter'
                  placeholder='Chapter'
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <TextField
              id='chname'
              style={{ width: '100%' }}
              label='KeyConcept Name'
              variant='outlined'
              size='small'
              value={topicName}
              inputProps={{ accept: '^[a-zA-Z0-9 +_-]+', maxLength: 100 }}
              //inputProps={{pattern:'^[a-zA-Z0-9]+',maxLength:100}}
              name='chname'
              onChange={(e) => setTopicName(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <TextField
              id='keyconceptsequencenumber'
              type='number'
              label='Key Concept Sequence Number'
              placeholder='Enter Key Concept Sequence Number'
              value={keyConceptSequence}
              onChange={(e) => setKeyConceptSequence(e.target.value)}
              style={{ width: '100%' }}
              variant='outlined'
              size='small'
              SelectProps={{
                native: true,
              }}
            />
          </Grid>
        </Grid>
      </div>

      <Grid container spacing={isMobile ? 1 : 5} style={{ width: '95%', margin: '10px' }}>
        <Grid item xs={6} sm={2} className={isMobile ? '' : 'addEditButtonsPadding'}>
          <Button
            variant='contained'
            className='custom_button_master labelColor'
            size='medium'
            onClick={handleGoBack}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={6} sm={2} className={isMobile ? '' : 'addEditButtonsPadding'}>
          <Button
            variant='contained'
            style={{ color: 'white' }}
            color='primary'
            className='custom_button_master'
            size='medium'
            type='submit'
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditTopic;
