import React, { useContext, useState, useEffect } from 'react';
import { Grid, TextField, Button, useTheme } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Autocomplete from '@material-ui/lab/Autocomplete';
import endpoints from '../../config/endpoints';
import axiosInstance from '../../config/axios';
import { useHistory } from 'react-router-dom';
import FilterFilledIcon from '../../components/icon/FilterFilledIcon';
import { AlertNotificationContext } from '../../context-api/alert-context/alert-state';
import Loading from '../../components/loader/loader';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const Filter = ({ checkDt, handleFilter, clearFilter }) => {
  const { setAlert } = useContext(AlertNotificationContext);
  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const history = useHistory();
  const wider = isMobile ? '-10px 0px' : '-10px 0px 20px 8px';
  const widerWidth = isMobile ? '98%' : '95%';
  const [loading, setLoading] = useState(false);
  const [selectedBoardId, setSelectedBoardId] = useState([]);
  const [yearCall, SetYearCall] = useState(false);
  const [selectedKeyConceptId, setSelectedKeyConceptId] = useState([]);
  const [filterData, setFilterData] = useState({
    year: '',
    volume: '',
    grade: '',
    subject: '',
    board: '',
    module: '',
    chapter: '',
    keyconcept: '',
  });
  const [dropdownData, setDropdownData] = useState({
    years: [],
    volumes: [],
    grades: [],
    subjects: [],
    boards: [],
    modules: [],
    chapters: [],
    keyconcepts: [],
  });
  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`${endpoints.lessonPlan.academicYearList}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setLoading(false);
          setDropdownData({
            ...dropdownData,
            years: result.data.result.results,
            volumes: '',
            grades: '',
            subjects: '',
            boards: '',
            modules: '',
            chapters: '',
            keyconcepts: '',
          })
        } else {
          setLoading(false);
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.message);
      });
  }, []);
  useEffect(() => {
    if (yearCall) {
      setLoading(true);
      axiosInstance
        .get(`${endpoints.lessonPlan.academicYearList}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setDropdownData({
              ...dropdownData,
              years: result.data.result.results,
              volumes: '',
              grades: '',
              subjects: '',
              boards: '',
              modules: '',
              chapters: '',
              keyconcepts: '',
            })
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
        });
    }
  }, [yearCall])
  const handleClear = () => {
    SetYearCall(true)
    setFilterData({
      year: '',
      volume: '',
      grade: '',
      subject: '',
      chapter: '',
      board: '',
      module: '',
      keyconcept: '',
    });
    setDropdownData({
      years: [],
      volumes: [],
      grades: [],
      subjects: [],
      boards: [],
      modules: [],
      chapters: [],
      keyconcepts: [],
    })
  };

  const handleAcademicYear = (event, value) => {
    setFilterData({
      ...filterData,
      year: '',
      volume: '',
      grade: '',
      subject: '',
      chapter: '',
      board: '',
      module: '',
      keyconcept: ''
    });
    setDropdownData({
      ...dropdownData,
      year: value,
      volumes: [],
      grades: [],
      subjects: [],
      boards: [],
      modules: [],
      chapters: [],
      keyconcepts: [],
    })
    if (value) {
      setFilterData({
        ...filterData,
        year: value,
        volume: '',
        grade: '',
        subject: '',
        chapter: '',
        board: '',
        module: '',
        keyconcept: ''
      });
      axiosInstance
        .get(`${endpoints.lessonPlan.volumeList}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setDropdownData({
              ...dropdownData,
              volumes: result.data.result.results,
              grades: [],
              subjects: [],
              boards: [],
              modules: [],
              chapters: [],
              keyconcepts: [],
            })
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
            setDropdownData({
              ...dropdownData,
              volumes: [],
              grades: [],
              subjects: [],
              boards: [],
              modules: [],
              chapters: [],
              keyconcepts: [],
            })
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
          setDropdownData({
            ...dropdownData,
            volumes: [],
            grades: [],
            subjects: [],
            boards: [],
            modules: [],
            chapters: [],
            keyconcepts: [],
          })
        });
    }
  };

  const handleVolume = (event, value) => {
    setFilterData({
      ...filterData,
      volume: '',
      grade: '',
      subject: '',
      board: '',
      module: '',
      chapter: '',
      keyconcept: ''
    });
    setDropdownData({
      ...dropdownData,
      grades: [],
      subjects: [],
      boards: [],
      modules: [],
      chapters: [],
      keyconcepts: [],
    })
    if (value) {
      setFilterData({
        ...filterData,
        volume: value,
        grade: '',
        subject: '',
        board: '',
        module: '',
        chapter: '',
        keyconcept: ''
      });
      axiosInstance
        .get(`${endpoints.lessonPlan.gradeList}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setDropdownData({
              ...dropdownData,
              grades: result.data.result.results,
              subjects: [],
              boards: [],
              modules: [],
              chapters: [],
              keyconcepts: [],
            })
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
            setDropdownData({
              ...dropdownData,
              grades: [],
              subjects: [],
              boards: [],
              modules: [],
              chapters: [],
              keyconcepts: [],
            })
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error?.message);
          setDropdownData({
            ...dropdownData,
            grades: [],
            subjects: [],
            boards: [],
            modules: [],
            chapters: [],
            keyconcepts: [],
          })
        });
    }
  };

  const handleGrade = (event, value) => {
    setLoading(true);
    setFilterData({
      ...filterData,
      grade: '',
      subject: '',
      chapter: '',
      board: '',
      module: '',
      chapter: '',
      keyconcept: ''
    });
    setDropdownData({
      ...dropdownData,
      grades: [],
      subjects: [],
      boards: [],
      modules: [],
      chapters: [],
      keyconcepts: [],
    });
    if (value) {
      setLoading(true);
      setFilterData({ ...filterData, grade: value, subject: '', chapter: '', keyconcept: '' });
      axiosInstance
        .get(`${endpoints.lessonPlan.gradeSubjectMappingList}?grade=${value.id}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setDropdownData({
              ...dropdownData,
              subjects: result.data.result.results,
              boards: [],
              modules: [],
              chapters: [],
              keyconcepts: [],
            });
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
            setDropdownData({
              ...dropdownData,
              subjects: [],
              boards: [],
              modules: [],
              chapters: [],
              keyconcepts: [],
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
          setDropdownData({
            ...dropdownData,
            subjects: [],
            boards: [],
            modules: [],
            chapters: [],
            keyconcepts: [],
          });
        });
    } else {
      setLoading(false);
      setDropdownData({
        ...dropdownData,
        subjects: [],
        boards: [],
        modules: [],
        chapters: [],
        keyconcepts: [],
      });
    }
  };

  const handleSubject = (event, value) => {
    setLoading(true);
    setFilterData({ ...filterData, subject: '', board: '', module: '', chapter: '', keyconcept: '' });
    setDropdownData({
      ...dropdownData,
      boards: [],
      modules: [],
      chapters: [],
      keyconcepts: [],
    });
    if (value) {
      setLoading(true);
      setFilterData({ ...filterData, subject: value, board: '', module: '', chapter: '', keyconcept: '' });
      axiosInstance
        .get(`${endpoints.masterManagement.boardMappingList}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setDropdownData({
              ...dropdownData,
              boards: result.data.result,
              modules: [],
              chapters: [],
              keyconcepts: [],
            });
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
            setDropdownData({
              ...dropdownData,
              boards: [],
              modules: [],
              chapters: [],
              keyconcepts: [],
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
          setDropdownData({
            ...dropdownData,
            boards: [],
            modules: [],
            chapters: [],
            keyconcepts: [],
          });
        });
    } else {
      setLoading(false);
      setDropdownData({
        ...dropdownData,
        boards: [],
        modules: [],
        chapters: [],
        keyconcepts: [],
      });
    }
  };

  const handleBoard = (event = {}, values = []) => {
    setLoading(true);
    setFilterData({ ...filterData, board: '', module: '', chapter: '', keyconcept: '' });
    setDropdownData({
      ...dropdownData,
      modules: [],
      chapters: [],
      keyconcepts: [],
    });
    if (values.length !== 0) {
      setLoading(true);
      const ids = values.map((el) => el);
      const selectedId = values.map((el) => el?.id);
      setSelectedBoardId(selectedId);
      setFilterData({ ...filterData, board: ids, module: '', chapter: '', keyconcept: '' });
      axiosInstance
        .get(
          `${endpoints.masterManagement.module}?academic_year=${filterData?.year?.id
          }&grade=${filterData?.grade?.id}&volume=${filterData?.volume?.id}&grade_subject=${filterData?.subject?.id
          }&board=${selectedId}&page_size=${50}`
        )
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setDropdownData({
              ...dropdownData,
              modules: result.data.result,
              chapters: [],
              keyconcepts: [],
            });
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
            setDropdownData({
              ...dropdownData,
              modules: [],
              chapters: [],
              keyconcepts: [],
            });
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
          setDropdownData({
            ...dropdownData,
            modules: [],
            chapters: [],
            keyconcepts: [],
          });
        });
    } else {
      setLoading(false);
      setDropdownData({
        ...dropdownData,
        modules: [],
        chapters: [],
        keyconcepts: [],
      });
    }
  };

  const handleModule = (event = {}, values = []) => {
    setLoading(true);
    setFilterData({ ...filterData, module: '', chapter: '', keyconcept: '' });
    setDropdownData({
      ...dropdownData,
      chapters: [],
      keyconcepts: [],
    });
    if (values) {
      setLoading(true);
      setFilterData({ ...filterData, module: values, chapter: '', keyconcept: '' });
      if (filterData.year && filterData.volume && values.length !== 0) {
        axiosInstance
          .get(
            `${endpoints.lessonPlan.chapterList}?grade_subject=${filterData?.subject?.id}&volume=${filterData?.volume?.id}&academic_year=${filterData?.year?.id}&board=${selectedBoardId}&lt_module=${values?.id}`
          )
          .then((result) => {
            if (result.data.status_code === 200) {
              setLoading(false);
              setDropdownData({
                ...dropdownData,
                chapters: result.data.result,
                keyconcepts: [],
              });
            } else {
              setLoading(false);
              setAlert('error', result.data.message);
              setDropdownData({
                ...dropdownData,
                chapters: [],
                keyconcepts: [],
              });
            }
          })
          .catch((error) => {
            setLoading(false);
            setAlert('error', error.message);
            setDropdownData({
              ...dropdownData,
              chapters: [],
              keyconcepts: [],
            });
          });
      }
    } else {
      setLoading(false);
      setDropdownData({
        ...dropdownData,
        chapters: [],
        keyconcepts: [],
      });
    }
  };

  const handleChapter = (event = {}, values = []) => {
    setLoading(true);
    setFilterData({ ...filterData, chapter: '', keyconcept: '' });
    setDropdownData({
      ...dropdownData,
      keyconcepts: [],
    });
    if (values) {
      setLoading(true);
      // setSelectedKeyConceptId(values);
      setFilterData({ ...filterData, chapter: values, keyconcept: '' });
      if (filterData.year && filterData.volume && values.length !== 0) {
        axiosInstance
          .get(
            `${endpoints.masterManagement.createTopic}?chapter=${values?.id}&page_size=50`
          )
          .then((result) => {
            if (result.data.status_code === 200) {
              setLoading(false);
              setDropdownData({
                ...dropdownData,
                keyconcepts: result.data.result,
              });
            } else {
              setLoading(false);
              setAlert('error', result.data.message);
              setDropdownData({
                ...dropdownData,
                keyconcepts: [],
              });
            }
          })
          .catch((error) => {
            setLoading(false);
            setAlert('error', error.message);
            setDropdownData({
              ...dropdownData,
              keyconcepts: [],
            });
          });
      }
    } else {
      setLoading(false);
      setDropdownData({
        ...dropdownData,
        keyconcepts: [],
      });
    }
  };
  const handleKeyConcept = (event, value) => {
    setFilterData({ ...filterData, keyconcept: '' });
    if (value) {
      setFilterData({ ...filterData, keyconcept: value });
    }
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
          <Autocomplete
            style={{ width: '100%' }}
            size='small'
            onChange={handleAcademicYear}
            id='academic-year'
            className='dropdownIcon'
            value={filterData?.year}
            options={dropdownData?.years || []}
            getOptionLabel={(option) => option?.session_year}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                label='Academic Year'
                placeholder='Academic Year'
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
          <Autocomplete
            style={{ width: '100%' }}
            size='small'
            onChange={handleVolume}
            id='volume'
            className='dropdownIcon'
            value={filterData.volume}
            options={dropdownData?.volumes || []}
            getOptionLabel={(option) => option?.volume_name}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                label='Volume'
                placeholder='Volume'
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
          <Autocomplete
            style={{ width: '100%' }}
            size='small'
            onChange={handleGrade}
            id='grade'
            className='dropdownIcon'
            value={filterData.grade}
            options={dropdownData?.grades || []}
            getOptionLabel={(option) => option?.grade_name}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                label='Grade'
                placeholder='Grade'
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
          <Autocomplete
            style={{ width: '100%' }}
            size='small'
            onChange={handleSubject}
            id='subject'
            className='dropdownIcon'
            value={filterData.subject}
            options={dropdownData?.subjects || []}
            getOptionLabel={(option) => option?.subject?.subject_name}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                label='Subject'
                placeholder='Subject'
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
          <Autocomplete
            multiple
            style={{ width: '100%' }}
            size='small'
            onChange={handleBoard}
            id='board'
            className='dropdownIcon'
            value={filterData.board || []}
            options={dropdownData?.boards || []}
            getOptionLabel={(option) => option?.board_name || ''}
            // filterSelectedOptions
            getOptionSelected={(option, value) => option?.id == value?.id}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                label='Board'
                placeholder='Board'
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
          <Autocomplete
            // multiple
            style={{ width: '100%' }}
            size='small'
            onChange={handleModule}
            id='module'
            className='dropdownIcon'
            value={filterData.module || []}
            options={dropdownData?.modules || []}
            getOptionLabel={(option) => option?.lt_module_name || ''}
            filterSelectedOptions
            // getOptionSelected={(option, value) =>
            //     option?.id == value?.id
            //   }
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                label='Module'
                placeholder='Module'
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
          <Autocomplete
            style={{ width: '100%' }}
            size='small'
            onChange={handleChapter}
            id='chapter'
            className='dropdownIcon'
            value={filterData.chapter}
            options={dropdownData?.chapters || []}
            getOptionLabel={(option) => option?.chapter_name}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                label='Chapter'
                placeholder='Chapter'
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
          <Autocomplete
            style={{ width: '100%' }}
            size='small'
            onChange={handleKeyConcept}
            id='keyConcept'
            className='dropdownIcon'
            value={filterData.keyconcept}
            options={dropdownData?.keyconcepts || []}
            getOptionLabel={(option) => option?.topic_name}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                label='KeyConcept'
                placeholder='KeyConcept'
              />
            )}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={2} xs={6}>
              <Button
                style={{ color: 'white', background: "darkgrey" }}
                size='small'
                fullWidth
                onClick={handleClear}
                variant='contained'
              >
                Clear All
              </Button>
            </Grid>
            <Grid item md={2} xs={6}>
              <Button
                startIcon={<FilterFilledIcon />}
                style={{ color: 'white' }}
                size='small'
                variant='contained'
                color='primary'
                fullWidth
                onClick={() =>
                  handleFilter(
                    filterData.year,
                    filterData.grade,
                    filterData.volume,
                    filterData.subject,
                    filterData.board,
                    filterData.module,
                    filterData.chapter,
                    filterData.keyconcept
                  )
                }
              >
                Filter
              </Button>
            </Grid>
            <Grid item md={2} xs={6}>
              <Button
                size='small'
                color='primary'
                variant='contained'
                onClick={() => {
                  history.push('/ibook/create');
                }}
                style={{ color: 'white' }}
                fullWidth
              >
                <AddCircleOutlineIcon />
                &nbsp;Create ibook
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {loading && <Loading />}
    </>
  );
};

export default Filter;
