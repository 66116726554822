import React, { useContext } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import ReactHtmlParser from 'react-html-parser';
import { makeStyles } from '@material-ui/styles';
import '../assess-attemption.css';

const useStyles = makeStyles((theme) => ({
  mcqOptions: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
    background: theme.palette.primary.primarylightest,
    padding: '10px',
    width: '100%',
    textAlign: 'center',
    // justifyContent: 'space-between',
    /* max-width: 400px, */
    cursor: 'pointer',
    marginBottom: '10px',
  },
}));
const TrueFalseQuestion = (props) => {
  const classes = useStyles();

  const { questionObj: currentQuestionObj } = props || {};

  const {
    id: qId,
    question_answer: questionAnswer,
    user_response: { answer: existingAnswerArray } = {},
  } = currentQuestionObj || {};

  const [existingAnswer] = existingAnswerArray || [];

  const [{ question }] = questionAnswer || [];

  return (
    <div>
      <div className='mcq-question-wrapper'>
        <div style={{ fontSize: '30px' }}>{ReactHtmlParser(question)}</div>
        <FormControl component='fieldset'>
          <RadioGroup aria-label='gender' name='options' value={existingAnswer}>
            <FormControlLabel
              className={classes.mcqOptions}
              value='option1'
              control={<Radio checked={existingAnswer === 'option1'} />}
              label={'True'}
            />
            <FormControlLabel
              className={classes.mcqOptions}
              value='option2'
              control={<Radio checked={existingAnswer === 'option2'} />}
              label={'False'}
            />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};

export default TrueFalseQuestion;
