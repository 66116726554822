import React, { useState, useRef, useEffect } from 'react';
import { Button, Checkbox, Form, Input, Popconfirm, Select, message } from 'antd';
import {
  PlusOutlined,
  MinusOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import ReactHtmlParser from 'react-html-parser';
import { difficultyLevels, bloomLevels } from '../../../generalQuestionConstants';
import QuestionDetailsModal from './questionDetailsModal';
import axiosInstance from '../../../config/axios';
import endpoints from '../../../config/endpoints';
import CreateQuestion from '../CreateQuestion/CreateQuestion';
import SubQuestionTagging from './SubQuestionTagging/SubQuestionTagging';
import QuestionTypeFinder from '../CreateQuestion/QuestionTypeFinder';
import TypeRestricter from '../TypeRestrictChecker';

const { Option } = Select;

const Question = ({
  currentQuestion,
  index,
  gradeList,
  volumeList,
  boardList,
  questionTypeList,
  getQuestionList,
  isQuestionTagged,
  currentPage,
  selectedQuestionList,
  handleSingleQuestionSelect,
}) => {
  const formRef = useRef();
  const [questionViewModal, setQuestionViewModal] = useState(false);
  const [showExtraFilters, setShowExtraFilters] = useState(null);
  const [boardId, setBoardId] = useState();
  const [volumeId, setVolumeId] = useState();
  const [gradeId, setGradeId] = useState();
  const [academicYearId, setAcademicYearId] = useState();
  const [subjectList, setSubjectList] = useState([]);
  const [subjectId, setSubjectId] = useState();
  const [moduleList, setModuleList] = useState([]);
  const [moduleId, setModuleId] = useState();
  const [chapterList, setChapterList] = useState([]);
  const [chapterId, setChapterId] = useState();
  const [keyConceptList, setKeyConceptList] = useState([]);
  const [keyConceptId, setKeyConceptId] = useState();
  const [bloomLevel, setBloomLevel] = useState();
  const [difficultyLevel, setDifficultyLevel] = useState();
  const [questionTypeId, setQuestionTypeId] = useState();
  const [subQuestionTypeList, setSubQuestionTypeList] = useState([]);
  const [subQuestionTypeId, setSubQuestionTypeId] = useState();
  const [marks, setMarks] = useState();
  const [editMode, setEditMode] = useState(false);
  const [viewEditQuestion, setViewEditQuestion] = useState(false);
  const [questionTaggingDone, setQuestionTaggingDone] = useState(false);
  const [showSubQuestionTaggingModal, setShowSubQuestionTaggingModal] = useState(false);
  const QuestionTypeChecker =
    typeof currentQuestion?.question_type == 'object'
      ? currentQuestion?.question_type?.type_name
      : currentQuestion?.question_type;
  // API Calls
  const fetchSubjectList = (params = {}) => {
    axiosInstance
      .get(`${endpoints.masterManagement.gradeSubjectMappingList}`, {
        params: { ...params },
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setSubjectList(result?.data?.result?.results);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  const fetchModuleList = (params = {}) => {
    axiosInstance
      .get(`${endpoints.masterManagement.module}`, {
        params: { ...params },
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setModuleList(result?.data?.result);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  const fetchChapterList = (params = {}) => {
    axiosInstance
      .get(`${endpoints.masterManagement.chapters}`, {
        params: { ...params },
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setChapterList(result?.data?.result);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  const fetchKeyConceptList = (params = {}) => {
    axiosInstance
      .get(`${endpoints.masterManagement.keyConceptTableData}`, {
        params: { ...params },
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setKeyConceptList(result?.data?.result);
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };
  const fetchSubQuestionTypesList = (params = {}) => {
    axiosInstance
      .get(`v2/assessment/v3/questiontype/`, {
        params: { ...params },
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          let checker =
            typeof currentQuestion?.question_type == 'object'
              ? currentQuestion.question_type?.type_name
              : currentQuestion?.question_type;

          if (checker) {
            let allowedList = result?.data?.result?.filter(
              (el) => QuestionTypeFinder(el?.type_name) == QuestionTypeFinder(checker)
            );
            console.log('checker2', QuestionTypeFinder(checker), checker);
            setSubQuestionTypeList(allowedList);
          } else {
            setSubQuestionTypeList(result?.data?.result);
          }
        }
      })
      .catch((error) => {
        message.error(error.message);
      });
  };

  // Functions
  const handleBoardChange = (e) => {
    if (e) {
      setBoardId(e);
    } else {
      setBoardId();
    }
  };
  const handleGradeChange = (e) => {
    setSubjectList([]);
    setSubjectId();
    setVolumeId();
    setModuleList([]);
    setModuleId();
    setChapterList([]);
    setChapterId();
    setKeyConceptList([]);
    setKeyConceptId();
    formRef.current.setFieldsValue({
      subject: null,
      volume: null,
      module: null,
      chapter: null,
      key_concept: null,
    });
    if (e) {
      setGradeId(e);
      fetchSubjectList({ grade: e });
    } else {
      setGradeId();
    }
  };
  const handleSubjectChange = (e) => {
    setVolumeId();
    setModuleList([]);
    setModuleId();
    setChapterList([]);
    setChapterId();
    setKeyConceptList([]);
    setKeyConceptId();
    formRef.current.setFieldsValue({
      volume: null,
      module: null,
      chapter: null,
      key_concept: null,
    });
    if (e) {
      setSubjectId(e);
    } else {
      setSubjectId();
    }
  };
  const handleVolumeChange = (e) => {
    setModuleList([]);
    setModuleId();
    setChapterList([]);
    setChapterId();
    setKeyConceptList([]);
    setKeyConceptId();
    formRef.current.setFieldsValue({
      chapter: null,
      key_concept: null,
      module: null,
    });
    if (e) {
      setVolumeId(e);
      fetchModuleList({
        grade_subject: subjectId,
        volume: e,
        academic_year: academicYearId,
      });
    } else {
      setVolumeId();
    }
  };
  const handleModuleChange = (e) => {
    setChapterList([]);
    setChapterId();
    setKeyConceptList([]);
    setKeyConceptId();
    formRef.current.setFieldsValue({
      chapter: null,
      key_concept: null,
    });
    if (e) {
      setModuleId(e);
      fetchChapterList({
        grade_subject: subjectId,
        volume: volumeId,
        lt_module: e,
        academic_year: academicYearId,
      });
    } else {
      setModuleId();
    }
  };
  const handleChapterChange = (e) => {
    setKeyConceptList([]);
    setKeyConceptId();
    formRef.current.setFieldsValue({
      key_concept: null,
    });
    if (e) {
      setChapterId(e);
      fetchKeyConceptList({ chapter: e });
    } else {
      setChapterId();
    }
  };
  const handleKeyConceptChange = (e) => {
    if (e) {
      setKeyConceptId(e);
    } else {
      setKeyConceptId();
    }
  };

  const handleViewQuestion = () => {
    setQuestionViewModal(true);
  };
  const handleCloseViewQuestion = () => {
    setQuestionViewModal(false);
    getQuestionList();
  };
  const handleViewEditQuestion = () => {
    setViewEditQuestion(true);
  };
  const handleCloseEditQuestion = () => {
    setViewEditQuestion(false);
  };

  const handleCloseSubQuestionTagging = () => {
    setShowSubQuestionTaggingModal(false);
  };

  const handleQuestiontypeChange = (e) => {
    formRef.current.setFieldsValue({
      sub_question_type: null,
    });
    setSubQuestionTypeList([]);
    if (e) {
      setQuestionTypeId(e);
      fetchSubQuestionTypesList({
        question_type: e,
      });
    } else {
      setQuestionTypeId();
    }
  };

  const handleQuestionTagging = (id) => {
    if (!bloomLevel) {
      message.error('Please select Bloom Level');
      return;
    }
    if (!difficultyLevel) {
      message.error('Please select Difficulty Level');
      return;
    }
    if (!questionTypeId) {
      message.error('Please select Question Type');
      return;
    }
    if (!subQuestionTypeId) {
      message.error('Please select Sub-Question Type');
      return;
    }
    if (!marks) {
      message.error('Please enter Marks');
      return;
    }
    if (marks <= 0) {
      message.error('Marks must be greater than 0');
      return;
    }
    if (showExtraFilters && !keyConceptId) {
      message.error('Please update Key Concept');
      return;
    }
    let payload = {
      ...currentQuestion,
      id: currentQuestion?.id,
      question_level: difficultyLevel,
      question_type: subQuestionTypeId,
      question_categories: bloomLevel,
      question_mark: marks,
      question_status: 3,
      is_tagged: true,
      chapter: currentQuestion?.chapter?.id,
      topic: currentQuestion?.topic?.id,
    };
    if (keyConceptId && showExtraFilters) {
      payload.grade_subject_mapping = subjectId;
      payload.chapter = chapterId;
      payload.topic = keyConceptId;
    }
    // handleCloseSubQuestionTagging();
    axiosInstance
      .put(`/v2/assessment/${id}/v3/questions/`, payload)
      .then((res) => {
        if (res?.data?.status_code === 200) {
          message.success(
            'Question has been successfully updated and has been moved to For review'
          );
          if (currentQuestion?.sub_questions?.length > 0) {
            setShowSubQuestionTaggingModal(true);
          } else {
            setQuestionTaggingDone(true);
            // getQuestionList();
          }
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };
  const handleQuestionDelete = () => {
    axiosInstance
      .delete(`/v2/assessment/${currentQuestion?.id}/v3/questions/`)
      .then((res) => {
        if (res?.data?.status_code === 200) {
          message.success(res?.data?.message);
          getQuestionList();
        } else {
          message.error(res?.data?.message);
        }
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  // Select Options
  const boardOptions = boardList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.board_name}
      </Option>
    );
  });
  const volumeOptions = volumeList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.volume_name}
      </Option>
    );
  });
  const gradeOptions = gradeList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.grade_name}
      </Option>
    );
  });
  const subjectOptions = subjectList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.subject?.subject_name}
      </Option>
    );
  });
  const moduleOptions = moduleList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.lt_module_name}
      </Option>
    );
  });
  const chapterOptions = chapterList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.chapter_name}
      </Option>
    );
  });
  const keyConceptOptions = keyConceptList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.topic_name}
      </Option>
    );
  });
  const bloomLevelOptions = bloomLevels?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.level}
      </Option>
    );
  });
  const difficultyLevelOptions = difficultyLevels?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.level}
      </Option>
    );
  });
  const questionTypeOptions = TypeRestricter(QuestionTypeChecker)?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.type_name}
      </Option>
    );
  });
  const subQuestionTypeOptions = subQuestionTypeList?.map((el) => {
    return (
      <Option key={el?.id} id={el?.id} value={el.id}>
        {el?.type_name}
      </Option>
    );
  });

  useEffect(() => {
    // if (isQuestionTagged) {
    if (currentQuestion?.question_mark > 0) {
      setMarks(currentQuestion?.question_mark);
    }
    setBloomLevel(currentQuestion?.question_categories);
    setDifficultyLevel(currentQuestion?.question_level);
    setQuestionTypeId(currentQuestion?.question_type?.type?.id);
    setSubQuestionTypeId(currentQuestion?.question_type?.id);
    setAcademicYearId(currentQuestion?.topic?.academic_year?.id);
    setGradeId(currentQuestion?.topic?.grade_subject_mapping?.grade?.id);
    setSubjectId(currentQuestion?.topic?.grade_subject_mapping?.id);
    setVolumeId(currentQuestion?.topic?.volume?.id);
    setModuleId(currentQuestion?.chapter?.module?.id);
    setChapterId(currentQuestion?.chapter?.id);
    setKeyConceptId(currentQuestion?.topic?.id);

    formRef.current.setFieldsValue({
      blooms_level: bloomLevels?.filter(
        (el) => el?.id == currentQuestion?.question_categories
      )[0]?.level,
      difficulty_level: difficultyLevels?.filter(
        (el) => el?.id == currentQuestion?.question_level
      )[0]?.level,
      question_type: currentQuestion?.question_type?.type?.type_name,
      sub_question_type: currentQuestion?.question_type?.type_name,
      grade: currentQuestion?.topic?.grade_subject_mapping?.grade?.grade_name,
      subject: currentQuestion?.topic?.grade_subject_mapping?.subject?.subject_name,
      volume: currentQuestion?.topic?.volume?.volume_name,
      module: currentQuestion?.chapter?.module?.lt_module_name,
      chapter: currentQuestion?.chapter?.chapter_name,
      key_concept: currentQuestion?.topic?.topic_name,
    });
    // }
  }, [currentQuestion]);

  useEffect(() => {
    if (editMode) {
      fetchSubQuestionTypesList({
        question_type: currentQuestion?.question_type?.type?.id,
      });
    }
  }, [editMode]);

  useEffect(() => {
    if (showExtraFilters) {
      if (!subjectList.length > 0) {
        fetchSubjectList({ grade: gradeId });
      }
      if (!moduleList.length > 0) {
        fetchModuleList({
          grade_subject: subjectId,
          academic_year: academicYearId,
          volume: volumeId,
        });
      }
      if (!chapterList.length > 0) {
        fetchChapterList({
          grade_subject: subjectId,
          volume: volumeId,
          lt_module: moduleId,
          academic_year: academicYearId,
        });
      }
      if (!keyConceptList.length > 0) {
        fetchKeyConceptList({ chapter: chapterId });
      }
    }
  }, [showExtraFilters]);

  useEffect(() => {
    if (questionTaggingDone) {
      getQuestionList();
      formRef.current.resetFields();
      setMarks();
    }
  }, [questionTaggingDone]);

  return (
    <div className='row'>
      <div className='col-12 mb-2 p-2 pl-0'>
        <div className='d-flex align-items-center justify-content-between'>
          {isQuestionTagged && (
            <div>
              {currentQuestion?.question_status !== 2 && (
                <Checkbox
                  onChange={() => handleSingleQuestionSelect(currentQuestion?.id)}
                  checked={
                    selectedQuestionList?.includes(currentQuestion?.id) ? true : false
                  }
                />
              )}
            </div>
          )}
          <div
            className='p-2 th-br-4 th-width-98'
            style={{ border: '2px solid #d9d9d9' }}
          >
            <div
              className='th-bg-blue-1 row p-2 th-br-4 th-question-card'
              style={{ maxHeight: '300px', overflowY: 'auto', minHeight: 75 }}
            >
              <div
                className='col-sm-11 pl-0 pr-1 text-left th-pointer'
                onClick={handleViewQuestion}
              >
                <div className='th-question-box d-flex '>
                  <div className='pr-2'>Q {(currentPage - 1) * 15 + index + 1}.</div>
                  <div style={{ maxWidth: '95%' }}>
                    {ReactHtmlParser(currentQuestion?.question_answer?.[0]?.question)}
                  </div>
                </div>
              </div>
              <div className='col-sm-1 px-1 text-right'>
                <div className='pb-1 th-black-1'>
                  ID : <span className=''>{currentQuestion?.id}</span>
                </div>
                <div>{/* <Button onClick={handleViewQuestion}>View </Button> */}</div>
              </div>
            </div>

            <hr />
            <div className='th-automated-question'>
              <Form ref={formRef} id='filterForm' layout={'horizontal'}>
                <div className='row align-items-center'>
                  <div className='col-sm-10 px-0 text-left'>
                    <div className='row align-items-center'>
                      <div className='col-sm-2 col-6 pl-0'>
                        <Form.Item name='blooms_level'>
                          <Select
                            placeholder='Blooms Level*'
                            disabled={isQuestionTagged && !editMode}
                            optionFilterProp='children'
                            showSearch
                            className=''
                            getPopupContainer={(trigger) => trigger.parentNode}
                            dropdownMatchSelectWidth={false}
                            onChange={(e) => {
                              setBloomLevel(e);
                            }}
                            filterOption={(input, options) => {
                              return (
                                options.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                          >
                            {bloomLevelOptions}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className='col-sm-2 col-6 pl-0'>
                        <Form.Item name='difficulty_level'>
                          <Select
                            placeholder='Difficulty Level*'
                            disabled={isQuestionTagged && !editMode}
                            optionFilterProp='children'
                            className=''
                            getPopupContainer={(trigger) => trigger.parentNode}
                            dropdownMatchSelectWidth={false}
                            onChange={(e) => {
                              setDifficultyLevel(e);
                            }}
                            filterOption={(input, options) => {
                              return (
                                options.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                          >
                            {difficultyLevelOptions}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className='col-sm-2 col-6 pl-0'>
                        <Form.Item name='question_type'>
                          <Select
                            placeholder='Question Type*'
                            disabled={isQuestionTagged && !editMode}
                            optionFilterProp='children'
                            showSearch
                            className=''
                            getPopupContainer={(trigger) => trigger.parentNode}
                            onChange={handleQuestiontypeChange}
                            filterOption={(input, options) => {
                              return (
                                options.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                          >
                            {questionTypeOptions}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className='col-sm-3 col-6 pl-0'>
                        <Form.Item name='sub_question_type'>
                          <Select
                            placeholder='Sub Question*'
                            disabled={isQuestionTagged && !editMode}
                            showSearch
                            optionFilterProp='children'
                            className=''
                            listHeight={150}
                            getPopupContainer={(trigger) => trigger.parentNode}
                            onChange={(e) => {
                              setSubQuestionTypeId(e);
                            }}
                            onFocus={() => {
                              if (questionTypeId) {
                                fetchSubQuestionTypesList({
                                  question_type: questionTypeId,
                                });
                              }
                            }}
                            filterOption={(input, options) => {
                              return (
                                options.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              );
                            }}
                          >
                            {subQuestionTypeOptions}
                          </Select>
                        </Form.Item>
                      </div>
                      <div className='col-sm-2 col-6 pl-0'>
                        <Input
                          type='number'
                          placeholder='Marks*'
                          className='text-center'
                          disabled={isQuestionTagged && !editMode}
                          maxLength={2}
                          value={marks}
                          onChange={(e) => {
                            if (e.target.value > 99) {
                              message.warning('Marks must be 2 digit number');
                            } else {
                              setMarks(e.target.value);
                            }
                          }}
                          pattern='\d+'
                          min={1}
                        />
                      </div>
                      <div className='col-1 pl-0'>
                        <Button
                          type='link'
                          size='small'
                          icon={showExtraFilters ? <MinusOutlined /> : <PlusOutlined />}
                          className='px-0'
                          onClick={() => {
                            setShowExtraFilters(!showExtraFilters);
                          }}
                        >
                          View {showExtraFilters ? 'Less' : 'More'}
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className='col-sm-2 col-12 pr-0'>
                    <div className='d-flex justify-content-end pr-1'>
                      {isQuestionTagged && !editMode ? (
                        <>
                          <Popconfirm
                            title='Are you sure to delete this question?'
                            onConfirm={handleQuestionDelete}
                            okText='Yes'
                            cancelText='No'
                            placement='topRight'
                          >
                            <Button
                              type='default'
                              className='mr-2'
                              title='Delete question'
                            >
                              {<DeleteOutlined className='th-red' />}
                            </Button>
                          </Popconfirm>
                          <Button
                            title='Edit'
                            type='default'
                            onClick={() => {
                              setEditMode(true);
                            }}
                          >
                            {<EditOutlined className='th-primary' />}
                          </Button>
                        </>
                      ) : (
                        <>
                          {editMode ? (
                            <Button
                              type='default'
                              className='mr-2'
                              onClick={() => {
                                setEditMode(false);
                              }}
                            >
                              Cancel
                            </Button>
                          ) : (
                            <Popconfirm
                              title='Are you sure to delete this question?'
                              onConfirm={handleQuestionDelete}
                              okText='Yes'
                              cancelText='No'
                              placement='topRight'
                            >
                              <Button
                                type='default'
                                className='mr-2'
                                title='Delete question'
                              >
                                {<DeleteOutlined className='th-red' />}
                              </Button>
                            </Popconfirm>
                          )}
                          <Button
                            type='primary'
                            onClick={() => {
                              handleQuestionTagging(currentQuestion?.id);
                            }}
                          >
                            Update
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                  {showExtraFilters && (
                    <div className='row pt-2'>
                      <div className='col-10 px-0 text-left'>
                        <div className='row align-items-center'>
                          <div className='col-sm-2 d-none col-6 pl-0'>
                            <Form.Item name='board'>
                              <Select
                                showSearch
                                allowClear
                                placeholder='Select Board'
                                disabled={isQuestionTagged && !editMode}
                                optionFilterProp='children'
                                className=''
                                getPopupContainer={(trigger) => trigger.parentNode}
                                dropdownMatchSelectWidth={false}
                                filterOption={(input, options) => {
                                  return (
                                    options.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  );
                                }}
                                onChange={(e) => {
                                  handleBoardChange(e);
                                }}
                              >
                                {boardOptions}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className='col-sm-2 col-6 pl-0'>
                            <Form.Item name='grade'>
                              <Select
                                showSearch
                                placeholder='Select Grade'
                                disabled={isQuestionTagged && !editMode}
                                optionFilterProp='children'
                                className=''
                                allowClear
                                getPopupContainer={(trigger) => trigger.parentNode}
                                dropdownMatchSelectWidth={false}
                                filterOption={(input, options) => {
                                  return (
                                    options.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  );
                                }}
                                onChange={(e) => {
                                  handleGradeChange(e);
                                }}
                              >
                                {gradeOptions}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className='col-sm-2 col-6 pl-0'>
                            <Form.Item name='subject'>
                              <Select
                                showSearch
                                placeholder='Select Subject'
                                disabled={isQuestionTagged && !editMode}
                                optionFilterProp='children'
                                className=''
                                allowClear
                                getPopupContainer={(trigger) => trigger.parentNode}
                                dropdownMatchSelectWidth={false}
                                filterOption={(input, options) => {
                                  return (
                                    options.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  );
                                }}
                                onChange={(e) => {
                                  handleSubjectChange(e);
                                }}
                              >
                                {subjectOptions}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className='col-sm-2 col-6 pl-0'>
                            <Form.Item name='volume'>
                              <Select
                                showSearch
                                allowClear
                                disabled={isQuestionTagged && !editMode}
                                placeholder='Select Volume'
                                optionFilterProp='children'
                                className=''
                                getPopupContainer={(trigger) => trigger.parentNode}
                                dropdownMatchSelectWidth={false}
                                filterOption={(input, options) => {
                                  return (
                                    options.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  );
                                }}
                                onChange={(e) => {
                                  handleVolumeChange(e);
                                }}
                              >
                                {volumeOptions}
                              </Select>
                            </Form.Item>
                          </div>

                          <div className='col-sm-2 col-6 pl-0'>
                            <Form.Item name='module'>
                              <Select
                                showSearch
                                allowClear
                                disabled={isQuestionTagged && !editMode}
                                placeholder='Select Module'
                                optionFilterProp='children'
                                className=''
                                getPopupContainer={(trigger) => trigger.parentNode}
                                dropdownMatchSelectWidth={false}
                                filterOption={(input, options) => {
                                  return (
                                    options.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  );
                                }}
                                onChange={(e) => {
                                  handleModuleChange(e);
                                }}
                              >
                                {moduleOptions}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className='col-sm-2 col-6 pl-0'>
                            <Form.Item name='chapter'>
                              <Select
                                showSearch
                                disabled={isQuestionTagged && !editMode}
                                placeholder='Select Chapter'
                                optionFilterProp='children'
                                className=''
                                allowClear
                                getPopupContainer={(trigger) => trigger.parentNode}
                                dropdownMatchSelectWidth={false}
                                filterOption={(input, options) => {
                                  return (
                                    options.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  );
                                }}
                                onChange={(e) => {
                                  handleChapterChange(e);
                                }}
                              >
                                {chapterOptions}
                              </Select>
                            </Form.Item>
                          </div>
                          <div className='col-sm-2 col-6 px-0'>
                            <Form.Item name='key_concept'>
                              <Select
                                showSearch
                                disabled={isQuestionTagged && !editMode}
                                placeholder='Select Key Concept'
                                optionFilterProp='children'
                                className=''
                                allowClear
                                getPopupContainer={(trigger) => trigger.parentNode}
                                dropdownMatchSelectWidth={false}
                                filterOption={(input, options) => {
                                  return (
                                    options.children
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  );
                                }}
                                onChange={(e) => {
                                  handleKeyConceptChange(e);
                                }}
                              >
                                {keyConceptOptions}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      {questionViewModal && (
        <QuestionDetailsModal
          questionViewModal={questionViewModal}
          handleCloseViewQuestion={handleCloseViewQuestion}
          handleViewEditQuestion={handleViewEditQuestion}
          currentQuestion={currentQuestion}
          index={index}
          isQuestionTagged={isQuestionTagged}
          getQuestionList={getQuestionList}
          questionTypeList={questionTypeList}
        />
      )}
      {viewEditQuestion && <CreateQuestion currentQuestion={currentQuestion} />}
      {showSubQuestionTaggingModal && (
        <SubQuestionTagging
          gradeList={gradeList}
          volumeList={volumeList}
          boardList={boardList}
          currentPage={currentPage}
          questionTypeList={questionTypeList}
          mainQuestion={currentQuestion}
          showSubQuestionTaggingModal={showSubQuestionTaggingModal}
          handleCloseSubQuestionTagging={handleCloseSubQuestionTagging}
        />
      )}
    </div>
  );
};

export default Question;
