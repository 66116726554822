import React, { useState, useEffect } from 'react';
import {
  useTheme,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { AttachmentPreviewerContext } from '../../../../components/attachment-previewer/attachment-previewer-contexts';
import { DeleteFilled, InfoCircleOutlined,PlusOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Checkbox, Drawer, Input, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import QuestionCard from './questionCard';
import QuestionBankDrawer from './questionBankDrawer';
import { addInstructionToSection, addOptionalQuestion } from '../../../../redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmPopOver from '../assessment-view/assesment-card/ConfirmPopOver';
import QuestionBank from './questionBank';
import Tooltip from 'antd/lib/tooltip';



const { Option } = Select;

const Sections = ({
  question,
  section,
  questionId,
  onDelete,
  onDeleteQuestion,
  grade,
  erpCategory,
  questionPaperWise,
  deleteOneSection,
  isEdit,
  setViewMoreData,
  setPeriodDataForView,
}) => {

  const themeContext = useTheme();
  const [Diaopen, setdiaOpen] = React.useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('')
  const dispatch = useDispatch();
  const [openModal , setOpenModal] = useState(false)
  const [isOptionalQues, setisOptionalQues] = useState(false);
  const sections = useSelector((state) => state.createQuestionPaper.questions);

  // const sectionMarks = section?.test_marks?.forEach((item) => {
  //       marks += parseInt(item?.question_mark[0])
  // } )

  console.log('sections',sections)

  function round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
  return (Math.round(value * multiplier) / multiplier)
}

  const getMarks = () => {
    let marks = 0;
    for (let i = 0; i < section?.mandatory_questions; i++) {
      marks += parseFloat(section?.test_marks[i]?.question_mark[0]);
    }
        // console.log('marks', marks)
    if(section?.questions?.length > 0){
      // if(marks.toFixed(2)%1 == 0){
      //   return marks
      // }else{
        return round(marks , 2);
      // }
      
    }else return 0;
    
  };
  useEffect(() => {
    if(!isEdit && section){
      handleOptionalQuestion(section?.questions?.length, '');
    }else if(isEdit && section){
        if(section?.questions?.length < section?.mandatory_questions){
          handleOptionalQuestion(section?.questions?.length, '');
        }
    }
  }, [section?.questions?.length]);

  const handleAddQuestion = (tab) => {
    if(tab === '1'){
      setActiveTab('1')
      setDrawerOpen(true);
    } else{
      setActiveTab('2')
      setDrawerOpen(true);
    }
  };

  const onClose = () => {
    setDrawerOpen(false);
  };

  const handleOptionalQuestion = (e, value) => {
    dispatch(addOptionalQuestion(e, questionId, section?.name));
  };

  const mandtorydropdown = () => {
    let arr = [];
    for (let i = 1; i <= section?.questions?.length; i++) {
      arr.push(i);
    }
    return arr;
  };
  const Mandetary_question = mandtorydropdown();

  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const { openPreview, closePreview } =
    React.useContext(AttachmentPreviewerContext) || {};

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const DiaClickOpen = () => {
    setdiaOpen(true);
  };

  const DiaClose = () => {
    setdiaOpen(false);
  };

  const handleDelete = () => {
    setDeleteAlert(true);
  };
  const handleDeleteConfirm = () => {
    setDeleteAlert(false);
    onDelete(questionId, section.id);
  };
  const handleDeleteQuestion = (q, v) => {
    handleMenuClose();
    onDeleteQuestion(q?.id, section);
  };
  const handleDeleteCancel = () => {
    setDeleteAlert(false);
  };

  function extractContent(s) {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  // console.log(section, '@@');

  const isoptionalQues = (e) => {
    setisOptionalQues(e.target.checked);
  };

  return (
    <>
      <div className='row col-md-12 mt-3'>
        <div className='th-fw-900 col-md-7'>Section {section?.name}</div>
        <div className='d-flex align-items-center col-md-5'>
          <div className='d-flex col-md-7'>
            <div>Total Questions Added</div>
            <div className='col-md-4'>
              {/* <Input style={{ width: '3rem', height: '1.5rem' }} /> */}
              {section?.questions?.length}
            </div>
          </div>
          {!questionPaperWise && (
            <div className='d-flex col-md-5'>
              Total Marks Added
              <div className='col-md-4'>
                {/* <Input style={{ width: '3rem', height: '1.5rem' }} /> */}
                {getMarks()}
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className='row mx-3 mt-4'
        style={{ border: '4px solid #e8e8e9', borderRadius: '6px', width: '100%' }}
      >
        <div className='row mx-4 mt-2' style={{ width: '100%' }}>
          <span className='th-fw-600'>Instructions</span>
          <TextArea
            placeholder='Enter Instruction'
            rows={4}
            maxLength={250}
            className='mt-2'
            value={section?.instruction}
            style={{ height: '60px', background: '#f0f4fb' }}
            onChange={(e) =>
              dispatch(addInstructionToSection(questionId, section?.name, e.target.value))
            }
          />
        </div>
        <hr />
        {/* {section?.questions.map((ques) => ( */}
        {section?.questions?.map((ques, i) => (
          <QuestionCard
            ques={ques}
            index={i}
            handleDeleteQuestion={handleDeleteQuestion}
            testMark={section?.test_marks}
            questionPaperWise={questionPaperWise}
            setPeriodDataForView={setPeriodDataForView}
            setViewMoreData={setViewMoreData}
          />
        ))}
        {/* <div>
          <hr />
        </div> */}
        <div className='row mx-4 justify-content-end my-3' style={{ width: '100%' }}>
          <div className='col-md-2 pl-0 mr-2'>
            <Button className='w-100 th-button' onClick={() => handleAddQuestion('1')}>
              Create Question
            </Button>
          </div>
          <div className='col-md-3 pl-0 mr-2'>
            <Button className='w-100 th-button' onClick={() => handleAddQuestion('2')}>
              Add Question from 'Question Bank'
            </Button>
          </div>
        </div>
      </div>
      <hr />
      <div className='mt-2 row justify-content-end'>
          <div className='d-flex align-items-center mr-2'>
            <Tooltip color={'#1b4ccb9c'} className="text-center" title={`${!isOptionalQues ? section?.questions?.length : section?.mandatory_questions} Question is mandatory out of ${section?.questions?.length} Questions`}><InfoCircleOutlined style={{ color: 'blue', fontSize: 'large', cursor: 'pointer' }} /></Tooltip>
            {/* <InfoCircleOutlined title='Information'  /> */}
          </div>
        <div
          className='mr-3 d-flex my-2 align-items-center p-1'
          style={{ border: '1px solid #e8e8e9' }}
        >
          
          <Checkbox
            className='ml-2'
            style={{ color: '#2ecf87' }}
            onChange={isoptionalQues}
          >
            Set for Mandatory Questions
          </Checkbox>
          <div className='d-flex mr-1'>{section?.questions?.length}/</div>
          <Select
            allowClear
            placeholder='Topic'
            showSearch
            disabled={!isOptionalQues}
            value={
              section?.mandatory_questions < section?.questions?.length
                ? section?.mandatory_questions
                : section?.questions?.length
            }
            // disabled={user_level == 13}
            filterOption={(input, options) => {
              return options.children;
            }}
            optionFilterProp='children'
            getPopupContainer={(trigger) => trigger.parentNode}
            onChange={(e, value) => {
              handleOptionalQuestion(e, value);
            }}
          >
            {Mandetary_question?.map((item) => (
              <Option key={item} value={item}>
                {item}
              </Option>
            ))}
          </Select>
        </div>
        {sections?.length > 1 && (
          <div style={{ display: 'flex', marginRight: '1%', alignItems: 'center' }}>
            <DeleteFilled
              style={{ color: 'blue', fontSize: 'large' }}
              onClick={() => setOpenModal(true)}
            />
          </div>
        )}
        {openModal && (
          <ConfirmPopOver
            submit={() => deleteOneSection(questionId, section?.id)}
            openModal={openModal}
            setOpenModal={setOpenModal}
            operation={'delete'}
            opendelete={true}
          />
        )}
      </div>

      {drawerOpen && (
        <QuestionBank //QuestionBankDrawer
          grade={grade}
          erpCategory={erpCategory}
          onClose={onClose}
          drawerOpen={drawerOpen}
          section={section}
          questionId={questionId}
          questionPaperWise={questionPaperWise}
          activeTab={activeTab}
        />
      )}
    </>
  );
};

export default Sections;
