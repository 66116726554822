/* eslint-disable react/jsx-no-duplicate-props */
import React , { useContext, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import IconButton from '@material-ui/core/IconButton';
import { Grid, TextField, Button, useTheme } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Layout from '../Layout';
import { AlertNotificationContext } from '../../context-api/alert-context/alert-state';
import endpoints from '../../config/endpoints';
import CommonBreadcrumbs from '../../components/common-breadcrumbs/breadcrumbs';
import axiosInstance from '../../config/axios';
import CreateLicense from './create-license'
// import EditSubject from './edit-subject'
import Loading from '../../components/loader/loader';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '90vw',
    width:'95%',
    margin:'20px auto',
    marginTop: theme.spacing(2),
    boxShadow: 'none',
  },
  container: {
    maxHeight: '70vh',
    maxWidth: '90vw',
  },
  columnHeader: {
    color: `${theme.palette.secondary.main} !important`,
    fontWeight: 600,
    fontSize: '1rem',
  },
  tableCell: {
    color: theme.palette.secondary.main,
  },
}));

const columns = [
  { 
    id: 'email',
    label: 'Email',
    minWidth: 100 ,
    align: 'left',
    labelAlign: 'left',
  },
  { 
    id: 'username',
    label: 'username',
    minWidth: 100 ,
    align: 'center',
    labelAlign: 'center',
  },
  { 
    id: 'password',
    label: 'Password',
    minWidth: 100 ,
    align: 'center',
    labelAlign: 'center',
  },
  { 
    id: 'id',
    label: 'id',
    minWidth: 100 ,
    align: 'center',
    labelAlign: 'center',
  },
  { 
    id: 'is_active',
    label: 'is_active',
    minWidth: 100 ,
    align: 'center',
    labelAlign: 'center',
  },
  { 
    id: 'is_delete',
    label: 'is_delete',
    minWidth: 100 ,
    align: 'center',
    labelAlign: 'center',
  },
  { 
    id: 'is_occupied',
    label: 'is_occupied',
    minWidth: 100 ,
    align: 'center',
    labelAlign: 'center',
  },
  { 
    id: 'limit',
    label: 'limit',
    minWidth: 100 ,
    align: 'center',
    labelAlign: 'center',
  },
  { 
    id: 'webinar_enabled',
    label: 'webinar_enabled',
    minWidth: 100 ,
    align: 'center',
    labelAlign: 'center',
  },
  { 
    id: 'Zoom_User_id',
    label: 'Zoom_User_id',
    minWidth: 100 ,
    align: 'center',
    labelAlign: 'center',
  },
];


const LicenseTable = () => {
  const classes = useStyles();
  const { setAlert } = useContext(AlertNotificationContext);
  const [page, setPage] = useState(1)
  const [openDeleteModal,setOpenDeleteModal]=useState(false)
  const [addFlag,setAddFlag]=useState(false)
  const [editFlag,setEditFlag]=useState(false)
  const [tableFlag,setTableFlag]=useState(true)
  const [delFlag,setDelFlag]=useState(false)
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false)
  const [opt, setOpt] = useState(false)
  const [limit, setLimit] = useState(15);
  const [goBackFlag,setGoBackFlag]=useState(false)
  const [licenseList,setLicenseList]=useState([])
  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'))
  const [showPassword, setShowPassword] = useState(false)

  const wider= isMobile?'-10px 0px':'-10px 0px 20px 8px'
  const widerWidth=isMobile?'98%':'95%'


  const handleChangePage = (event, newPage) => {
    setPage(newPage+1)
  }

  const handleAddLicense=()=>{
    setTableFlag(false)
    setAddFlag(true)
    setEditFlag(false)
  }

  const handleEditSubject=(id,name,desc,optional)=>{
    // setTableFlag(false)
    // setAddFlag(false)
    // setEditFlag(true)
    // setSubjectId(id)
    // setSubjectName(name)
    // setDesc(desc)
    // setOpt(optional)    
  }

  const handleGoBack=()=>{
    setPage(1)
    setTableFlag(true)
    setAddFlag(false)
    setEditFlag(false)
    setGoBackFlag(!goBackFlag)
  }

  const handleDeleteSubject = (e) => {
    //   e.preventDefault()
    //   setLoading(true);
    //   axiosInstance.put(endpoints.masterManagement.updateSubject,{
    //     'is_delete': true,
    //     'subject_id': subjectId
    //   }).then(result=>{
    //   if (result.status === 200) {
    //     setDelFlag(!delFlag)
    //     setLoading(false);
    //     setAlert('success', "Subject deleted successfully!");
    //   } else {
    //     setLoading(false);
    //     setAlert('error', "Network Error!");
    //   }
    //   }).catch((error)=>{
    //     setLoading(false);
    //     setAlert('error', "Subject couldn't be deleted!");
    //   })
    // setOpenDeleteModal(false)
  };

  const handleOpenDeleteModal = (id) => {
    // setSubjectId(id)
    // setOpenDeleteModal(true)
  };

  const handleCloseDeleteModal = () => {
    // setOpenDeleteModal(false)
  };

  useEffect(()=>{
    setLoading(true)
    setTimeout(()=> {setLoading(false)},450); 
  },[goBackFlag,page,delFlag])

  useEffect(()=>{
      axiosInstance.get(`${endpoints.zoom.listLicense}?page=${page}&page_size=${limit}`)
      .then(result=>{
        if (result.status === 200) {
          setTotalCount(result.data.count)
          setLicenseList(result.data.result)
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error)=>{
        setAlert('error', error.response.data.description);
      })
  },[goBackFlag,delFlag,page])
      
  const handleDelete = (subj) => {
    // setSubjectName(subj.subject.subject_name);
    // handleOpenDeleteModal(subj.subject.id)
  }
 
  return (
    <>
    {loading ? <Loading message='Loading...' /> : null}
   <Layout>
    <div>
      <div style={{ width: '95%', margin: '20px auto' }}>
        <CommonBreadcrumbs
          componentName='License List'
          childComponentName={(addFlag&&!tableFlag)?'Add License':(editFlag&&!tableFlag)?'Edit License':null}
        />
      </div>
    </div>
   
    {!tableFlag && addFlag && !editFlag && <CreateLicense setLoading={setLoading} handleGoBack={handleGoBack}/> }
    {/* {!tableFlag && !addFlag && editFlag && <EditSubject id={subjectId} desc={desc} name={subjectName} setLoading={setLoading} handleGoBack={handleGoBack} opt={opt}/> } */}

    {tableFlag && !addFlag && !editFlag &&   
      <Grid container spacing={isMobile?3:5} style={{ width: widerWidth, margin: wider}}>
      <Grid item xs={12} sm={3} className={isMobile?'':'addButtonPadding'}>
          <Button 
          startIcon={<AddOutlinedIcon style={{fontSize:'30px'}}/>} 
          variant='contained' 
          color='primary' 
          size="small" 
          style={{color:'white'}} 
          title="Add License" 
          onClick={handleAddLicense}>
            Add License
        </Button>
      </Grid>
      </Grid>
    }

    {/* <>
    {!isMobile?
     <> */}
    {tableFlag && !addFlag && !editFlag && 
    <Paper className={`${classes.root} view_user_table_wrapper`}>
      <TableContainer className={`table view_user_table table-shadow ${classes.container}`}>
       <Table stickyHeader aria-label='sticky table'>
         <TableHead className='view_groups_header'>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={classes.columnHeader}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {licenseList.map((license, index) => {
              return (
                <TableRow hover license='checkbox' tabIndex={-1} key={index}>
                   
                    <TableCell className={classes.tableCell}>
                      {license.email}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {license.username}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {license.password}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {license.id}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {license.is_active?"true":"false"}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {license.is_delete?"true":"false"}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {license.is_occupied?"true":"false"}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {license.limit}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {license.webinar_enabled?"true":"false"}
                    </TableCell>
                     <TableCell className={classes.tableCell}>
                       {license.zoom_user_id}
                       </TableCell>
                      {/*<IconButton
                        onClick={e=>{ handleDelete(subject) }}
                        title='Delete Subject'
                      >
                        <DeleteOutlinedIcon style={{color:'#fe6b6b'}} />
                      </IconButton>

                      <IconButton
                        onClick={e=>handleEditSubject(subject.subject.id,subject.subject.subject_name,subject.subject.subject_description,subject.subject.is_optional)}
                        title='Edit Subject'
                      >
                        <EditOutlinedIcon style={{color:'#fe6b6b'}} />
                      </IconButton>
                      
                    </TableCell> */}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer> 
      <div className="paginate paginateMobileMargin">
      <TablePagination
        component='div'
        count={totalCount}
        className='customPagination'
        rowsPerPage={limit}
        page={page-1}
        onChangePage={handleChangePage}
        rowsPerPageOptions={false}
      />
      </div>
    </Paper>
    }
      {/* </>
      : <>
      <>
      {tableFlag && !addFlag && !editFlag && 
      <>
      {
        subjects.map(subject => (
          <SubjectCard 
          data={subject} 
          handleDelete={handleDelete} 
          handleEditSubject={handleEditSubject} />
        ))
      }
      <div className="paginateData paginateMobileMargin">
      <TablePagination
        component='div'
        count={totalCount}
        rowsPerPage={limit}
        page={page-1}
        onChangePage={handleChangePage}
        rowsPerPageOptions={false}
        className='table-pagination'
      />
      </div>
      </>
      }
      </>
      </>
    }
    </> */}

    {/* <Dialog
      open={openDeleteModal}
      onClose={handleCloseDeleteModal}
      aria-labelledby='draggable-dialog-title'
    >
      <DialogTitle style={{ cursor: 'move',color: '#014b7e' }} id='draggable-dialog-title'>
        Delete Subject
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
        {`Confirm Delete Subject ${subjectName}`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDeleteModal} className="labelColor cancelButton">
          Cancel
        </Button>
        <Button color="primary" onClick={handleDeleteSubject}>Confirm</Button>
      </DialogActions>
    </Dialog> */}
    
    </Layout>
    </>
  );
};

export default LicenseTable;
