/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-state */
import React, { Component, useContext, useState } from 'react';
import { AlertNotificationContext } from '../../context-api/alert-context/alert-state';

import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid, Divider } from '@material-ui/core';
// import TextField from '@material-ui/core/TextField';
// import moment from 'moment';
// import Autocomplete from '@material-ui/lab/Autocomplete';
// import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import { withRouter } from 'react-router-dom';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Pagination } from '@material-ui/lab';
import CommonBreadcrumbs from '../../components/common-breadcrumbs/breadcrumbs';
import Layout from '../Layout';
// import Filter from './Filter';
import Filter from './filter';
import GridList from './gridList';
import axios from '../../config/axios';
import endpoints from '../../config/endpoints';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const styles = (theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  tabRoot: {
    width: '100%',
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
    // margin: '20px',
  },
});

class ViewiBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      newData: [],
      tabValue: 0,
      pageNo: 1,
      pageSize: 6,
      moduleId: 112,
      totalEbooks: 0,
      clearFilter: '',
      acadmicYear: '',
      selectedGrade: '',
      selectedSubject: '',
      selectedSchool: '',
      selectedVolume: '',
      selectedChapter: '',
      selectedModule: '',
      selectedKeyConcept: '',
      selectedBoard: '',
      loading: false,
      totalPages: 0,
    };
  }
  static contextType = AlertNotificationContext;
  componentDidMount() {
    this.getEbook();
  }

  getDaysAfter = (date, amount) => {
    return date ? date.add(amount, 'days').format('YYYY-MM-DD') : undefined;
  };

  getDaysBefore = (date, amount) => {
    return date ? date.subtract(amount, 'days').format('YYYY-MM-DD') : undefined;
  };

  handleStartDateChange = (date) => {
    const endDate = this.getDaysAfter(date.clone(), 6);
    this.setState({ endDate });
    this.setState({ startDate: date.format('YYYY-MM-DD') });
  };

  handlePagination = (event, page) => {
    const {
      tabValue,
      status,
      acadmicYear,
      selectedGrade,
      selectedSubject,
      selectedSchool,
      selectedVolume,
      selectedChapter,
      selectedModule,
      selectedKeyConcept,
      selectedBoard,
    } = this.state;
    console.log(selectedVolume , 'vol');
    if (tabValue === 0) {
      this.setState({ pageNo: page }, () => {
        console.log(selectedModule, 'finding pagination');
        this.getEbook(
          acadmicYear,selectedGrade,  selectedVolume, selectedSubject,
           selectedBoard,
          selectedModule,selectedChapter,
          selectedKeyConcept
         );
      });
    } else if (tabValue === 1) {
      this.setState({ pageNo: page }, () => {
        this.getEbook(     acadmicYear,selectedGrade,  selectedVolume, selectedSubject,
          selectedBoard,
         selectedModule,selectedChapter,
         selectedKeyConcept);
      });
    } else if (tabValue === 2) {
      this.setState({ pageNo: page }, () => {
        this.getEbook(     acadmicYear,selectedGrade,  selectedVolume, selectedSubject,
          selectedBoard,
         selectedModule,selectedChapter,
         selectedKeyConcept);
      });
    } else if (tabValue === 3) {
      this.setState({ pageNo: page }, () => {
        this.getEbook(     acadmicYear,selectedGrade,  selectedVolume, selectedSubject,
          selectedBoard,
         selectedModule,selectedChapter,
         selectedKeyConcept);
      });
    }
  };

  handleEndDateChange = (date) => {
    const startDate = this.getDaysBefore(date.clone(), 6);
    this.setState({ startDate });
    this.setState({ endDate: date.format('YYYY-MM-DD') });
  };

  handleTabChange = (event, newValue) => {
    const { acadmicYear, selectedGrade, selectedSubject, selectedVolume,
      selectedChapter,
      selectedModule,
      selectedKeyConcept,
      selectedBoard } = this.state;
    const { board, } = this.state
    this.setState({ tabValue: newValue, pageNo: 1, pageSize: 8 });
    if (newValue === 0) {
      this.setState({ tabValue: newValue, data: [] }, () => {
        this.getEbook(     acadmicYear,selectedGrade,  selectedVolume, selectedSubject,
          selectedBoard,
         selectedModule,selectedChapter,
         selectedKeyConcept);
        this.state.clearFilter = 1;
        this.handleClearFilter();
      });
    } else if (newValue === 1) {
      this.setState({ tabValue: newValue, data: [] }, () => {
        this.getEbook(     acadmicYear,selectedGrade,  selectedVolume, selectedSubject,
          selectedBoard,
         selectedModule,selectedChapter,
         selectedKeyConcept);
        this.state.clearFilter = 2;
        this.handleClearFilter();
      });
    } else if (newValue === 2) {
      this.setState({ tabValue: newValue, data: [] }, () => {
        this.getEbook(     acadmicYear,selectedGrade,  selectedVolume, selectedSubject,
          selectedBoard,
         selectedModule,selectedChapter,
         selectedKeyConcept);
        this.state.clearFilter = 3;
        this.handleClearFilter();
      });
    }
  };

  getEbook = (acad, grade,  volume,subject,  board, module, chapter, keyConcept) => {
    console.log(grade , 'gr' , subject ,'sub' , volume , 'vol' , board , "board" , module , "mod" , chapter , 'chap' , keyConcept , 'key');
    const filterAcad = `${acad ? `&academic_year=${acad?.id}` : ''}`;
    const filterGrade = `${grade ? `&grade=${grade?.id}` : ''}`;
    const filterSubject = `${subject ? `&subject=${subject?.subject?.id}` : ''}`;
    const filterVolume = `${volume ? `&volume=${volume?.id}` : ''}`;
    const filterBoard = `${board ? `&board_id=${board[0]?.id}` : ''}`;
    const filteredModule = `${module ? `&lt_module=${module.id}` : ''}`;
    const filteredChapter = `${chapter ? `&chapter_id=${chapter?.id}` : ''}`;
    const filterKeyConcept = `${keyConcept ? `&key_concept_id=${keyConcept.id}` : ''}`;
    const { pageNo, pageSize, tabValue, moduleId, totalPages } = this.state;
    let urlPath = '';
    if (tabValue === 0) {
      urlPath = `${endpoints.ibook.ibook
        }?page=${pageNo}&page_size=${pageSize}&book_status=${1}${filterAcad}${filterGrade}${filterSubject}${filterVolume}${filterBoard}${filteredModule}${filteredChapter}${filterKeyConcept}`;
    } else if (tabValue === 1) {
      urlPath = `${endpoints.ibook.ibook
        }?page=${pageNo}&page_size=${pageSize}&book_status=${0}${filterAcad}${filterGrade}${filterSubject}${filterVolume}${filterBoard}${filteredModule}${filteredChapter}${filterKeyConcept}`;
    } else if (tabValue === 2) {
      urlPath = `${endpoints.ibook.ibook
        }?page=${pageNo}&page_size=${pageSize}&book_status=${2}${filterAcad}${filterGrade}${filterSubject}${filterVolume}${filterBoard}${filteredModule}${filteredChapter}${filterKeyConcept}`;
    }
    axios
      .get(urlPath)
      .then((result) => {
        this.setState({ loading: false });
        if (result.status === 200) {
          this.setState({
            totalPages: Math.ceil(result?.data?.result?.count / pageSize),
          });
          this.setState({
            data: result.data && result.data.result.result,
            // totalEbooks: result.data.result.total_ebooks,
          });
        } else {
          this.setState({ loading: false });
          console.log(result.data.message);
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };
  abc = (datas) => {
    this.setState({ newData: datas.result })
    this.setState({ totalPages: Math.ceil(datas.count / this.state.pageSize) })
  }
  handleFilter = (acad,  grade,volume, subject,  board, module,chapter, keyConcept) => {
    console.log(chapter , 'chap');
    if (!acad) {
      this.context.setAlert('error', 'Please select Year');
      return;
    }
    // if (!volume) {
    //   this.context.setAlert('error', 'Please select Volume');
    //   return;
    // }
    if (!grade) {
      this.context.setAlert('error', 'Please select Grade');
      return;
    }
    if (!subject) {
      this.context.setAlert('error', 'Please select Subject');
      return;
    }
    if (!board) {
      this.context.setAlert('error', 'Please Select Board');
      return;
    }
    // setLoading(true);
    this.setState({ loading: true });
    this.state.pageNo = 1;
    this.state.acadmicYear = acad;
    this.state.selectedGrade = grade;
    this.state.selectedVolume = volume;
    this.state.selectedModule = module;
    this.state.selectedBoard = board;
    this.state.selectedChapter = chapter;
    this.state.selectedKeyConcept = keyConcept;
    this.state.selectedSubject = subject;
    this.getEbook(acad,  grade,volume, subject, board, module, chapter, keyConcept);
  };

  handleClearFilter = () => {
    this.state.acadmicYear = '';
    this.state.selectedGrade = '';
    this.state.selectedSubject = '';
    this.state.selectedSchool = '';
  };

  render() {
    const { classes } = this.props;
    const {
      tabValue,
      data,
      newData,
      totalEbooks,
      pageNo,
      pageSize,
      startDate,
      endDate,
      clearFilter,
    } = this.state;

    return (
      <div className='layout-container-div'>
        <Layout className='layout-container'>
          <div
            className='message_log_wrapper'
            style={{ backgroundColor: '#F9F9F9', padding: '10px' }}
          >
            <div
              className='message_log_breadcrumb_wrapper'
              style={{ backgroundColor: '#F9F9F9' }}
            >
              <CommonBreadcrumbs
                componentName='Intelligent Book'
                childComponentName='View IBook'
              />
              <div className='create_group_filter_container'>
                <Grid container>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{ padding: '10px', marginTop: '20px' }}
                  >
                    <Filter checkDt={this.abc} handleFilter={this.handleFilter} clearFilter={clearFilter} />
                  </Grid>
                </Grid>
                <div style={{ margin: '20px' }}>
                  <Divider variant='middle' />
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
                      <div className={classes.tabRoot}>
                        <Tabs
                          indicatorColor='primary'
                          textColor='primary'
                          value={tabValue}
                          onChange={this.handleTabChange}
                          aria-label='simple tabs example'
                        >
                          <Tab label='Activated' {...a11yProps(0)} />
                          <Tab label='Deactivated' {...a11yProps(1)} />
                          <Tab label='Deleted' {...a11yProps(2)} />
                        </Tabs>
                        <TabPanel value={tabValue} index={0}>
                          {data && (
                            <GridList
                              data={data}
                              tabValue={tabValue}
                              totalEbooks={totalEbooks}
                              callBack={this.getEbook}
                            />
                          )}
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                          {data && (
                            <GridList
                              data={data}
                              tabValue={tabValue}
                              totalEbooks={totalEbooks}
                              callBack={this.getEbook}
                            />
                          )}
                        </TabPanel>
                        <TabPanel value={tabValue} index={2}>
                          {data && (
                            <GridList
                              data={data}
                              tabValue={tabValue}
                              totalEbooks={totalEbooks}
                              callBack={this.getEbook}
                            />
                          )}
                        </TabPanel>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
                      <Pagination
                        onChange={this.handlePagination}
                        count={this.state.totalPages}
                        color='primary'
                        page={pageNo}
                        style={{ paddingLeft: '35%' }}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(ViewiBook));
