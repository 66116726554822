import { DeleteFilled, DeleteOutlined } from '@ant-design/icons';
import { Button, Drawer } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import QuestionDetailCard from '../../assesment-view/question-details-card';
import ConfirmPopOver from '../assessment-view/assesment-card/ConfirmPopOver';
import ViewMoreCard from './viewMoreCard';
import ReactHtmlParser from 'react-html-parser';
import './preview.scss';
import axiosInstance from '../../../../config/axios';
import endpoints from '../../../../config/endpoints';
const QuestionCard = ({
  ques,
  index,
  handleDeleteQuestion,
  testMark,
  questionPaperWise,
  setSelectedIndex,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [questionData, setQuestionData] = useState('');
  const [qindex, setQindex] = useState('');
  const [viewMoreData, setViewMoreData] = useState([]);
  const [periodDataForView, setPeriodDataForView] = useState([]);
  const marks = testMark.filter((item) => item?.question_id === ques?.id);
  const getquestionLevel = (type) => {
    switch (type) {
      case 1:
        return 'Easy';
      case 2:
        return 'Average';
      case 3:
        return 'Difficult';
      default:
        return '--';
    }
  };

  const questionType = (type) => {
    switch (type) {
      case 1:
        return 'MCQ Single Choice';
      case 2:
        return 'MCQ Multiple Choice';
      case 3:
        return 'Match the Following';
      case 4:
        return 'Video Question';
      case 5:
        return 'PPT Question';
      case 6:
        return 'Matrix Questions';
      case 7:
        return 'Comprehension';
      case 8:
        return 'True False';
      case 9:
        return 'Fill In The Blanks';
      case 10:
        return 'Descriptive';
      default:
        return '--';
    }
  };
  function extractContent(s) {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }

  const extractContentOption = (s) => {
    if (s?.length > 0 && s.indexOf('<') > -1) {
      let newarr = s.replace(/</g, '&lt;');
      newarr = newarr.replace('&lt;p>', '');
      newarr = newarr.replace('&lt;/p>', '');
      // newarr = newarr.replaceAll('&lt;br />',' ');
      newarr = newarr.split('&lt;br />').join(' ');
      const span = document.createElement('span');
      span.innerHTML = newarr;
      return span.textContent || span.innerText;
    } else {
      const span = document.createElement('span');
      span.innerHTML = s;
      return span.textContent || span.innerText;
    }
  };

  const checkName = (node) => {
    if (node?.split('"').filter((str) => str.startsWith('https')).length > 0) {
      return ReactHtmlParser(node);
    } else {
      return extractContentOption(node);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const viewQuestion = (index) => {
    setOpen(true);
    setQindex(index);
    // const qd = ques?.filter((item => item === index));
    setQuestionData(ques);
  };

  const handleViewMore = (index) => {
    // axiosInstance
    //   .get(`${endpoints.questionBank.viewMoreData}?question=${period.id}`)
    axiosInstance
      .get(`${endpoints.questionBank.viewMoreData}?question=${ques?.id}`)
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setViewMoreData(result?.data?.result);
          setPeriodDataForView(ques);
          setSelectedIndex(index);

          setOpen(true);
          setQindex(index);
          // const qd = ques?.filter((item => item === index));
          setQuestionData(result?.data?.result);
        } else {
          setPeriodDataForView([]);
          // setAlert('error', result?.data?.message);
          setSelectedIndex(-1);
          setOpen(true);
          setQindex(index);
          // const qd = ques?.filter((item => item === index));
          setQuestionData([]);
        }
      })
      .catch((error) => {
        // setPeriodDataForView([]);
        // setAlert('error', error?.message);
        // setSelectedIndex(-1);

        setOpen(true);
        setQindex(index);
        // const qd = ques?.filter((item => item === index));
        setQuestionData([]);
      });

    // console.log('qd', questionData)
    // if (!question.is_central) {
    //   axiosInstance
    //     .get(`${endpoints.questionBank.erpViewMoreData}?question=${question?.id}`)
    //     .then((result) => {
    //       if (result?.data?.status_code === 200) {
    //         setLoading(false);
    //         setViewMore(true);
    //         setViewMoreData(result?.data?.result);
    //         // setState({editData:result.data.result})
    //         setPeriodDataForView(question);
    //         setSelectedIndex(index);
    //       } else {
    //         setLoading(false);
    //         setViewMore(false);
    //         setViewMoreData({});
    //         setPeriodDataForView([]);
    //         setAlert('error', result?.data?.message);
    //         setSelectedIndex(-1);
    //       }
    //     })
    //     .catch((error) => {
    //       setLoading(false);
    //       setViewMore(false);
    //       setViewMoreData({});
    //       setPeriodDataForView([]);
    //       setAlert('error', error?.message);
    //       setSelectedIndex(-1);
    //     });
    // }
  };

  return (
    <div className='row col-md-12 mx-4 my-3'>
      Q{index + 1}.
      <div
        className='col-md-11 ml-2'
        style={{ border: '4px solid #e8e8e9', borderRadius: '6px' }}
      >
        <div
          className='row mx-1 align-items-center mt-2'
          style={{ background: '#f0f4fb' }}
        >
          <div
            className='ml-2 question-tiny text-truncate parsedPara'
            style={{ display: 'grid' }}
          >
            Question: {ReactHtmlParser(ques?.question_answer[0]?.question)}
            {/* {extractContent(ques?.question_answer[0]?.question).length > 100
              ? ReactHtmlParser(ques?.question_answer[0]?.question).substring(0, 100) +
                '...'
              : ReactHtmlParser(ques?.question_answer[0]?.question)} */}
          </div>
        </div>
        <hr />
        <div className='row mt-3 mb-2'>
          <div
            className='col-md-1 d-flex align-items-center justify-content-center'
            style={{
              fontSize: '13px',
              background: '#00be91',
              color: 'white',
              borderRadius: '6px',
              height: '20px',
            }}
          >
            {getquestionLevel(parseInt(ques?.question_level))}
          </div>
          <div
            className='col-md-2 d-flex align-items-center justify-content-center ml-2'
            style={{
              fontSize: '13px',
              background: '#01b8d8',
              color: 'white',
              borderRadius: '6px',
              height: '20px',
            }}
            title={questionType(ques?.question_type)}
          >
            {questionType(ques?.question_type)}
          </div>
          {marks && !questionPaperWise ? (
            <div
              className='col-md-2 d-flex align-items-center'
              style={{ fontSize: '13px', height: '20px' }}
            >
              Marks : {marks[0]?.question_mark[0]}
            </div>
          ) : (
            <div className='col-md-2 d-flex align-items-center'></div>
          )}
          {!marks && questionPaperWise && (
            <div
              className='col-md-2 d-flex align-items-center'
              style={{ fontSize: '13px', height: '20px' }}
            ></div>
          )}
          <div className='col-md-1'></div>
          <div className='d-flex col-md-4 justify-content-end'>
            {/* <div className='col-md-9 d-flex align-items-center' style={{fontSize : '13px', height:'20px'}}> */}
            Created On: {moment(ques?.created_at).format('L')}
            {/* </div> */}
            {/* <div className='col-md-3'>
    <Button className='th-button-active' style={{borderRadius : '6px'}}>
        View
    </Button>
    </div> */}
          </div>
          <div className='d-flex'>
            <DeleteFilled
              className='ml-3'
              onClick={() => {
                setOpenModal(true);
              }}
              style={{ color: 'blue', fontSize: 'large' }}
            />
            <Button
              form='incomeForm'
              type='primary'
              htmlType='submit'
              className='ml-3'
              style={{
                fontSize: '13px',
                color: 'white',
                borderRadius: '6px',
                height: '20px',
                paddingTop: 0,
              }}
              onClick={() => handleViewMore()}
            >
              View
            </Button>
            {open && (
              <Drawer
                title={'Question'}
                zIndex={10000}
                width={'500px'}
                placement='right'
                onClose={onClose}
                open={open}
              >
                {/* <ViewMoreCard
              setSelectedIndex={qindex}
              viewMoreData={questionData}
              setViewMore={setQindex}
              //   filterDataDown={filterDataDown}
              periodDataForView={questionData}
              // setCallFlag={setCallFlag}
            /> */}
                <ViewMoreCard
                  setSelectedIndex={setSelectedIndex}
                  viewMoreData={viewMoreData}
                  setViewMore={setQindex}
                  periodDataForView={periodDataForView}
                  setViewMoreData={setViewMoreData}
                />

                {/* <QuestionView 
            setSelectedIndex={qindex}
            viewMoreData={questionData}
            setViewMore={setQindex}
            //   filterDataDown={filterDataDown}
            periodDataForView={questionData}
            // setCallFlag={setCallFlag}
            /> */}
              </Drawer>
            )}
          </div>

          {openModal && (
            <ConfirmPopOver
              submit={() => handleDeleteQuestion(ques, index)}
              openModal={openModal}
              setOpenModal={setOpenModal}
              operation={'delete'}
              opendelete={true}
            />
          )}
          {/* <div className='col-md-2'>
    
    </div> */}
        </div>
      </div>
    </div>
  );
};

export default QuestionCard;
