/* eslint-disable react/jsx-no-duplicate-props */
import React, { useContext, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import IconButton from '@material-ui/core/IconButton';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { Grid, TextField, Button, useTheme, Divider } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Layout from '../../Layout';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import CommonBreadcrumbs from '../../../components/common-breadcrumbs/breadcrumbs';
import endpoints from '../../../config/endpoints';
import axiosInstance from '../../../config/axios';
import CreateChapter from './create-chapter';
import EditChapter from './edit-chapter';
import Loading from '../../../components/loader/loader';
import '../master-management.css';
import SubjectCard from './subjects-card';
import ClearAllIcon from '@material-ui/icons/ClearAll';
import { event } from 'jquery';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 auto',
    boxShadow: 'none',
  },
  container: {
    maxHeight: '70vh',
    width: '100%',
  },
  columnHeader: {
    color: `${theme.palette.secondary.main} !important`,
    fontWeight: 600,
    fontSize: '1rem',
    backgroundColor: `#ffffff !important`,
  },
  clrBtn: {
    margin: 3,
    color: 'rgb(140, 140, 140) !important',
    //  !important
  },
  tableCell: {
    color: theme.palette.secondary.main,
  },
  buttonContainer: {
    width: '95%',
    margin: '0 auto',
    background: theme.palette.background.secondary,
    paddingBottom: theme.spacing(2),
  },
}));

const columns = [
  {
    id: 'board_id',
    label: 'Board Name',
    minWidth: 100,
    align: 'center',
    labelAlign: 'center',
  },
  // {
  //   id: 'module_sequence',
  //   label: 'Module Sequence',
  //   minWidth: 100,
  //   align: 'center',
  //   labelAlign: 'center',
  // },
  {
    id: 'module_id',
    label: 'Module Name',
    minWidth: 100,
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'chapter_sequence',
    label: 'Chapter Sequence',
    minWidth: 100,
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'subject_name',
    label: 'Chapter',
    minWidth: 100,
    align: 'center',
    labelAlign: 'center',
  },

  {
    id: 'chapter_ref',
    label: 'Chapter Reference',
    minWidth: 100,
    align: 'center ref',
    labelAlign: 'center ref',
  },
  // {
  //   id: 'created_by',
  //   label: 'No. Of Periods',
  //   minWidth: 100,
  //   align: 'center',
  //   labelAlign: 'center',
  // },
  // {
  //   id: 'desc',
  //   label: 'Description',
  //   minWidth: 100 ,
  //   align: 'center',
  //   labelAlign: 'center',
  // },
  // {
  //   id: 'optional',
  //   label: 'Optional',
  //   minWidth: 50,
  //   align: 'center',
  //   labelAlign: 'center',
  // },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 170,
    align: 'center',
    labelAlign: 'center',
  },
];

const ChapterTable = () => {
  const classes = useStyles();
  const { setAlert } = useContext(AlertNotificationContext);
  const [page, setPage] = useState(1);
  const [subjects, setSubjects] = useState([]);
  const [grades, setGrades] = useState([]);
  const [sections, setSections] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [subjectId, setSubjectId] = useState();
  const [subjectName, setSubjectName] = useState('');
  const [addFlag, setAddFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [tableFlag, setTableFlag] = useState(true);
  const [desc, setDesc] = useState('');
  const [delFlag, setDelFlag] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [searchGrade, setSearchGrade] = useState('');
  const [searchSection, setSearchSection] = useState('');
  const [sectionDisplay, setSectionDisplay] = useState([]);
  const [searchSubject, setSearchSubject] = useState('');
  const [loading, setLoading] = useState(false);
  const [opt, setOpt] = useState(false);
  const [academicYearDropdown, setAcademicYearDropdown] = useState([]);
  const [volumeDropdown, setVolumeDropdown] = useState([]);
  const [gradeDropdown, setGradeDropdown] = useState([]);
  const [subjectDropdown, setSubjectDropdown] = useState([]);
  const [boardDropdown, setBoardDropdown] = useState([]);
  const [selectedModuleId, setSelectedModuleId] = useState([]);
  const [moduleDropdown, setModuleDropdown] = useState([]);
  const [chapterName, setChapterName] = useState([]);
  const [selectedBoardId, setSelectedBoardId] = useState([]);
  const [noOfChapter, setNoOfChapter] = useState([]);
  const [openModule, setOpenModule] = useState(false);
  const [board_name, setBoard_name] = useState('');
  const [filter, setFilter] = useState(false);

  const [filterData, setFilterData] = useState({
    year: '',
    volume: '',
    grade: '',
    subject: '',
    chapter: '',
    board: '',
    module: '',
  });

  const [chapterEditDetails, setChapterEditDetails] = useState([]);
  // const [chapterName,setChapterName]=useState('')
  // const [chapterId,setChapterId]=useState('')
  // const [gradeSubjectMapingId,setGradeSubjectMapingId]=useState('')

  const [chapterId, setChapterId] = useState('');
  const chapterUpdateApi = '/update-chapter/';

  const limit = 15;

  const [goBackFlag, setGoBackFlag] = useState(false);
  const { role_details } = JSON.parse(localStorage.getItem('userDetails'));
  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));

  const wider = isMobile ? '-10px 0px' : '-10px 0px 20px 8px';
  const widerWidth = isMobile ? '98%' : '95%';

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  // const handleGrade = (event, value) => {
  //   if(value)
  //     {
  //       setSearchGrade(value.id)
  //       setPage(1)
  //       axiosInstance.get(`${endpoints.masterManagement.sections}?grade_id=${value.id}`)
  //       .then(result=>{
  //         if(result.data.status_code===200)
  //         {
  //           setSections(result.data.data)
  //         }
  //         else
  //         {
  //           setAlert('error',result.data.message)
  //           setSections([])
  //           setSectionDisplay([])
  //         }
  //       })
  //       .catch(error=>{
  //         setAlert('error', error.message);
  //         setSections([])
  //         setSectionDisplay([])
  //       })
  //     }
  //   else
  //     {
  //       setSearchGrade('')
  //       setSearchSection('')
  //       setSections([])
  //       setSectionDisplay([])
  //     }
  // };

  const handleGrade = (event, value) => {
    setFilterData({ ...filterData, grade: '', subject: '', board: '', module: '' });
    if (value) {
      setLoading(true);
      setFilterData({ ...filterData, grade: value });
      axiosInstance
        .get(`${endpoints.masterManagement.gradeSubjectMappingList}?grade=${value.id}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setSubjectDropdown(result.data.result.results);
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
            setSubjectDropdown([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
          setSubjectDropdown([]);
        });
    } else {
      setLoading(false);
      setSubjectDropdown([]);
    }
  };

  useEffect(() => {
    axiosInstance
      .get(`${endpoints.masterManagement.academicYear}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setAcademicYearDropdown(result.data.result.results);
          handleAcademicYear('', result.data.current_acad_session_data[0]);
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });

    axiosInstance
      .get(`${endpoints.masterManagement.volumes}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setVolumeDropdown(result.data.result.results);
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });

    axiosInstance
      .get(`${endpoints.masterManagement.grades}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setGradeDropdown(result.data.result.results);
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });
  }, []);

  const handleSection = (event, value) => {
    setSearchSection('');
    setSectionDisplay(value);
    if (value) {
      setPage(1);
      setSearchSection(value.section_id);
    }
  };

  const handleClearFilter = () => {
    setFilter(false);
    setPage(1);
    setSelectedBoardId([]);
    setFilterData({
      year: '',
      grade: '',
      subject: '',
      chapter: '',
      board: '',
      module: '',
      volume: '',
    });
  };

  const handleAddSubject = () => {
    setTableFlag(false);
    setAddFlag(true);
    setEditFlag(false);
  };

  const handleEditSubject = (subject) => {
    setTableFlag(false);
    setAddFlag(false);
    setEditFlag(true);
    setChapterEditDetails(subject);
    // console.log('hi',subject,subject.id)

    // setSubjectId(id)
    // setSubjectName(name)
    // setDesc(desc)
    // setOpt(optional)
  };
  // console.log(chapterEditDetails, 'hi');
  const handleGoBack = () => {
    setPage(1);
    setTableFlag(true);
    setAddFlag(false);
    setEditFlag(false);
    setSearchGrade('');
    setSearchSubject('');
    setSearchSection('');
    setSectionDisplay([]);
    setGoBackFlag(true);
  };

  const handleAcademicYear = (event, value) => {
    setFilterData({ ...filterData, year: '' });
    if (value) {
      setFilterData({ ...filterData, year: value });
    } else {
      setFilterData({
        year: '',
        grade: '',
        subject: '',
        chapter: '',
        board: '',
        module: '',
        volume: '',
      });
    }
  };
  const handleVolume = (event, value) => {
    setLoading(true);
    setFilterData({
      ...filterData,
      volume: '',
      grade: '',
      subject: '',
      board: '',
      module: '',
    });
    if (value) {
      setFilterData({ ...filterData, volume: value });
      setLoading(false);
    }
  };

  const handleSubject = (event, value) => {
    setFilterData({ ...filterData, subject: '', board: '', module: '' });
    if (value) {
      setFilterData({ ...filterData, subject: value });
    } else {
      // setBoardDropdown([]);
    }
  };

  const getBoard = () => {
    setLoading(true);
    axiosInstance
      .get(`${endpoints.masterManagement.boardMappingList}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setLoading(false);
          setBoardDropdown(result.data.result);
        } else {
          setAlert('error', result.data.message);
          setBoardDropdown([]);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
        setBoardDropdown([]);
      });
  };

  useEffect(() => {
    getBoard();
  }, []);

  // const handleSubject = (event, value) => {
  //   setFilterData({ ...filterData, subject: '' });
  //   if (value) {
  //     setFilterData({ ...filterData, subject: value });
  //     //setFilterData({ ...filterData, chapter: ''});
  //   }
  // };

  const handleBoard = (event = {}, values = []) => {
    setFilterData({ ...filterData, board: '', module: '' });
    if (values?.length > 0) {
      const ids = values.map((el) => el);
      const selectedId = values.map((el) => el?.id);
      setSelectedBoardId(selectedId);
      setFilterData({ ...filterData, board: ids });
      if (filterData?.subject?.id) {
        setLoading(true);
        axiosInstance
          .get(
            `${endpoints.masterManagement.module}?academic_year=${filterData?.year?.id}&volume=${filterData?.volume?.id}&grade_subject=${filterData?.subject?.id}&board=${selectedId}`
          )
          .then((result) => {
            if (result.data.status_code === 200) {
              setLoading(false);
              setModuleDropdown(result.data.result);
            } else {
              setLoading(false);
              setAlert('error', result.data.message);
              setModuleDropdown([]);
            }
          })
          .catch((error) => {
            setLoading(false);
            setAlert('error', error.message);
            setModuleDropdown([]);
          });
      }
    } else {
      setLoading(false);
      setModuleDropdown([]);
    }
  };

  useEffect(() => {
    if (selectedBoardId.length > 0) {
      if (filterData?.subject?.id) {
        axiosInstance
          .get(
            `${endpoints.masterManagement.module}?academic_year=${filterData?.year?.id}&volume=${filterData?.volume?.id}&grade_subject=${filterData?.subject?.id}&board=${selectedBoardId}`
          )
          .then((result) => {
            if (result.data.status_code === 200) {
              setModuleDropdown(result.data.result);
            } else {
              setAlert('error', result.data.message);
              setModuleDropdown([]);
            }
          })
          .catch((error) => {
            setAlert('error', error.message);
            setModuleDropdown([]);
          });
      }
    }
  }, [filterData?.subject?.id]);

  const handleModule = (event = {}, values = []) => {
    setFilterData({ ...filterData, module: '' });
    if (values) {
      // const ids = values.map((el) => el);
      // const selectedId = values.map((el) => el?.id)
      // setSelectedModuleId(selectedId)
      setFilterData({ ...filterData, module: values });
    }
  };

  const handleDeleteSubject = (e) => {
    e.preventDefault();
    setLoading(true);
    axiosInstance
      .delete(
        `${endpoints.masterManagement.updateChapters}${chapterId}${chapterUpdateApi}`
      )
      .then((result) => {
        if (result.data.status_code === 200) {
          setDelFlag(!delFlag);
          setLoading(false);
          setAlert('success', result.data.message);
        } else {
          setLoading(false);
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.message);
      });
    setOpenDeleteModal(false);
  };

  const handleOpenDeleteModal = (id) => {
    // setSubjectId(id)
    setChapterId(id);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 450);
  }, [goBackFlag, page, delFlag, searchGrade, searchSection]);

  useEffect(() => {
    setGoBackFlag(false);
    if (
      filterData.subject.id &&
      filterData.board.length !== 0 &&
      filterData.module.length !== 0
    ) {
      axiosInstance
        .get(
          `${endpoints.masterManagement.chapters}?grade_subject=${filterData.subject.id}&board=${selectedBoardId}&lt_module=${filterData?.module?.id}`
        )
        .then((result) => {
          if (result.data.status_code === 200) {
            let count = result.data.result ? result.data.result.length : 0;
            setTotalCount(result.data.count ? result.data.count : count);
            setSubjects(result.data.result);
            setAlert('success', result.data.message);
          } else {
            setAlert('error', result.data.error_message);
          }
        })
        .catch((error) => {
          setAlert('error', error.message);
        });
    } else if (filterData.subject.id && filterData.board.length !== 0) {
      axiosInstance
        .get(
          `${endpoints.masterManagement.chapters}?grade_subject=${filterData.subject.id}&board=${selectedBoardId}`
        )
        .then((result) => {
          if (result.data.status_code === 200) {
            let count = result.data.result ? result.data.result.length : 0;
            setTotalCount(result.data.count ? result.data.count : count);
            setSubjects(result.data.result);
            setAlert('success', result.data.message);
          } else {
            setAlert('error', result.data.error_message);
          }
        })
        .catch((error) => {
          setAlert('error', error.message);
        });
    } else {
      axiosInstance
        .get(
          `${endpoints.masterManagement.chapters}?all=1&page_size=${limit}&page=${page}`
        )
        .then((result) => {
          if (result.data.status_code === 200) {
            setTotalCount(result.data.count);
            setSubjects(result.data.result);
            setAlert('success', result.data.message);
          } else {
            setAlert('error', result.data.error_message);
          }
        })
        .catch((error) => {
          setAlert('error', error.message);
        });
    }
  }, [goBackFlag, delFlag, page, editFlag]);

  useEffect(() => {
    if (filter) {
      handleFilter();
    }
  }, [page, goBackFlag, delFlag]);

  const handleDelete = (subj) => {
    setSubjectName(subj.chapter_name);
    handleOpenDeleteModal(subj.id);
    console.log(subj, subj.id, subj.chapter_name);
  };

  const handleFilter = () => {
    setFilter(true);
    console.log(filterData);
    let requestUrl = `${endpoints.masterManagement.chapters}?page_size=${limit}&page=${page}`;
    if (filterData?.year) {
      requestUrl += `&academic_year=${filterData?.year?.id}`;
    }
    if (selectedBoardId.length > 0) {
      requestUrl += `&board=${selectedBoardId}`;
    }
    if (filterData?.volume?.id) {
      requestUrl += `&volume=${filterData?.volume?.id}`;
    }
    if (filterData?.subject?.id) {
      requestUrl += `&grade_subject=${filterData?.subject?.id}`;
    }
    if (filterData?.module?.id) {
      requestUrl += `&lt_module=${filterData?.module?.id}`;
    }
    axiosInstance
      .get(requestUrl)
      .then((result) => {
        if (result.data.status_code === 200) {
          setTotalCount(result.data.count);
          setSubjects(result.data.result);
          setAlert('success', result.data.message);
        } else {
          setAlert('error', result.data.error_message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });
  };

  return (
    <>
      {loading ? <Loading message='Loading...' /> : null}
      <Layout>
        <div>
          <div style={{ width: '95%', margin: '20px auto' }}>
            <CommonBreadcrumbs
              componentName='Master Management'
              childComponentName='Chapter List'
              childComponentNameNext={
                addFlag && !tableFlag
                  ? 'Add Chapter'
                  : editFlag && !tableFlag
                  ? 'Edit Chapter'
                  : null
              }
            />
          </div>
        </div>

        {!tableFlag && addFlag && !editFlag && (
          <CreateChapter
            chapterData={filterData}
            setLoading={setLoading}
            handleGoBack={handleGoBack}
          />
        )}
        {!tableFlag && !addFlag && editFlag && (
          <EditChapter
            chapter={chapterEditDetails}
            setLoading={setLoading}
            handleGoBack={handleGoBack}
          />
        )}

        {tableFlag && !addFlag && !editFlag && (
          <>
            <Grid
              container
              spacing={isMobile ? 3 : 5}
              style={{ width: widerWidth, margin: wider }}
            >
              <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  size='small'
                  onChange={handleAcademicYear}
                  style={{ width: '100%' }}
                  id='grade'
                  options={academicYearDropdown}
                  value={filterData.year}
                  getOptionLabel={(option) => option?.session_year}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Academic Year'
                      placeholder='Academic Year'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  size='small'
                  onChange={handleVolume}
                  style={{ width: '100%' }}
                  id='grade'
                  options={volumeDropdown}
                  value={filterData.volume}
                  getOptionLabel={(option) => option?.volume_name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Volume'
                      placeholder='Volume'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  size='small'
                  onChange={handleGrade}
                  style={{ width: '100%' }}
                  id='grade'
                  options={gradeDropdown}
                  value={filterData.grade}
                  getOptionLabel={(option) => option?.grade_name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Grades'
                      placeholder='Grades'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  size='small'
                  onChange={handleSubject}
                  style={{ width: '100%' }}
                  id='grade'
                  options={subjectDropdown}
                  value={filterData.subject}
                  getOptionLabel={(option) => option?.subject?.subject_name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Subject'
                      placeholder='Subject'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  multiple
                  size='small'
                  onChange={handleBoard}
                  style={{ width: '100%' }}
                  id='board'
                  options={boardDropdown || []}
                  value={filterData.board || []}
                  getOptionLabel={(option) => option?.board_name || ''}
                  // filterSelectedOptions
                  getOptionSelected={(option, value) => option?.id == value?.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Board'
                      placeholder='Board'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  // multiple
                  size='small'
                  onChange={handleModule}
                  style={{ width: '100%' }}
                  id='module'
                  options={moduleDropdown || []}
                  value={filterData.module || []}
                  getOptionLabel={(option) => option?.lt_module_name || ''}
                  filterSelectedOptions
                  // getOptionSelected={(option, value) =>
                  //   option?.id == value?.id
                  // }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Module'
                      placeholder='Module1'
                      // required
                    />
                  )}
                />
              </Grid>

              {/* <Grid item xs={12} sm={3} className={isMobile?'':'filterPadding'}>
        <TextField
          style={{ width: '100%'}}
          id='subname'
          label='Chapter Name'
          variant='outlined'
          size='small'
          name='subname'
          autoComplete="off"
          onChange={e=>{setPage(1);setSearchSubject(e.target.value);}}
        />
      </Grid> */}
              {/* <Grid item xs={12} sm={3} className={isMobile?'':'filterPadding'}>
        <Autocomplete
          style={{ width: '100%' }}
          size='small'
          onChange={handleGrade}
          id='grade'
          options={grades}
          getOptionLabel={(option) => option?.grade_name}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              label='Grades'
              placeholder='Grades'
            />
          )}
        />
      </Grid> */}
              {/* <Grid item xs={12} sm={3}>
        <Autocomplete
          style={{ width: '100%' }}
          size='small'
          onChange={handleSection}
          id='section'
          value={sectionDisplay}
          options={sections}
          getOptionLabel={(option) => option?.section__section_name}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              label='Sections'
              placeholder='Sections'
            />
          )}
        />
      </Grid> */}
              <Grid item xs sm={12} className={isMobile ? 'hideGridItem' : ''} />
              <Grid item xs={6} sm={3} className={isMobile ? '' : 'addButtonPadding'}>
                <Button
                  // startIcon={<ClearAllIcon style={{ fontSize: '30px' }} />}
                  variant='contained'
                  color='default'
                  // size='small'
                  className={classes.clrBtn}
                  // style={{ color: 'white' }}
                  title='Clear All'
                  onClick={handleClearFilter}
                >
                  Clear All
                </Button>
              </Grid>
              <Grid item xs={6} sm={3} className={isMobile ? '' : 'addButtonPadding'}>
                <Button
                  // startIcon={<AddOutlinedIcon style={{ fontSize: '30px' }} />}
                  variant='contained'
                  color='primary'
                  // size='small'
                  style={{ color: 'white' }}
                  title='Filter'
                  onClick={handleFilter}
                >
                  Filter
                </Button>
              </Grid>
              <Grid item xs={6} sm={3} className={isMobile ? '' : 'addButtonPadding'}>
                <Button
                  startIcon={<AddOutlinedIcon style={{ fontSize: '30px' }} />}
                  variant='contained'
                  color='primary'
                  size='small'
                  style={{ color: 'white' }}
                  title='Add Chapter'
                  onClick={handleAddSubject}
                >
                  Add Chapter
                </Button>
              </Grid>
            </Grid>
          </>
        )}

        <>
          {!isMobile ? (
            <>
              {tableFlag && !addFlag && !editFlag && (
                <Paper className={`${classes.root} common-table`}>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label='sticky table'>
                      <TableHead className='table-header-row'>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                              className={classes.columnHeader}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {subjects.map((subject, index) => {
                          return (
                            <TableRow hover subject='checkbox' tabIndex={-1} key={index}>
                              <TableCell className={classes.tableCell}>
                                {subject?.board === null ? (
                                  <b style={{ color: 'red' }}> NA </b>
                                ) : (
                                  subject?.board?.board_name
                                )}
                                {/* {subject?.board?.board_name} */}
                              </TableCell>
                              {/* <TableCell className={classes.tableCell}>
                                {subject?.lt_module === null ? (
                                  <b style={{ color: 'red' }}> NA </b>
                                ) : (
                                  subject?.lt_module?.sequence
                                )}
                              </TableCell> */}
                              <TableCell className={classes.tableCell}>
                                {subject?.lt_module === null ? (
                                  <b style={{ color: 'red' }}> NA </b>
                                ) : (
                                  subject?.lt_module?.lt_module_name
                                )}
                                {/* {subject?.board?.board_name} */}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {subject?.sequence}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {subject?.chapter_name}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {subject?.rfrnc ? (
                                  subject?.rfrnc
                                ) : (
                                  <b style={{ color: 'red' }}> NA </b>
                                )}
                              </TableCell>
                              {/* <TableCell className={classes.tableCell}>
                                {subject?.no_of_periods}
                              </TableCell> */}
                              {/* <TableCell className={classes.tableCell}>
                      {subject.subject_description}
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      {(subject.is_optional)?'Yes':'No'}
                    </TableCell> */}
                              <TableCell className={classes.tableCell}>
                                <IconButton
                                  onClick={(e) => {
                                    handleDelete(subject);
                                  }}
                                  title='Delete Chapter'
                                >
                                  <DeleteOutlinedIcon style={{ color: '#fe6b6b' }} />
                                </IconButton>
                                <IconButton
                                  onClick={(e) => handleEditSubject(subject)}
                                  title='Edit Chapter'
                                >
                                  <EditOutlinedIcon style={{ color: '#fe6b6b' }} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className='paginateData'>
                    <TablePagination
                      component='div'
                      count={totalCount}
                      className='customPagination'
                      rowsPerPage={limit}
                      page={page - 1}
                      onChangePage={handleChangePage}
                      rowsPerPageOptions={false}
                    />
                  </div>
                </Paper>
              )}
            </>
          ) : (
            <>
              <>
                {tableFlag && !addFlag && !editFlag && (
                  <>
                    {subjects.map((subject) => (
                      <SubjectCard
                        data={subject}
                        handleDelete={handleDelete}
                        handleEditSubject={handleEditSubject}
                      />
                    ))}
                    <div className='paginateData paginateMobileMargin'>
                      <TablePagination
                        component='div'
                        count={totalCount}
                        rowsPerPage={limit}
                        page={page - 1}
                        onChangePage={handleChangePage}
                        rowsPerPageOptions={false}
                        className='table-pagination'
                      />
                    </div>
                  </>
                )}
              </>
            </>
          )}
        </>
        <Dialog
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          aria-labelledby='draggable-dialog-title'
        >
          <DialogTitle
            style={{ cursor: 'move', color: '#014b7e' }}
            id='draggable-dialog-title'
          >
            Delete Chapter
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`Confirm Delete Chapter ${subjectName}`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteModal} className='labelColor cancelButton'>
              Cancel
            </Button>
            <Button
              color='primary'
              variant='contained'
              style={{ color: 'white' }}
              onClick={handleDeleteSubject}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Layout>
    </>
  );
};

export default ChapterTable;
