import React,{useEffect} from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import useStyles from './useStyles';




const UserInfo = ({onClick}) => {

  const userDetails = JSON.parse(localStorage.getItem('userDetails')) || {}
  const proImage = localStorage.getItem('proImage') || null

  const classes = useStyles();
  return (
    <Box className={classes.root} onClick={onClick}>
      <Grid container direction='column' alignItems='center'>
        <Grid item xs={6}>
          <IconButton>
            {proImage ? (
              <img src={proImage} alt='no img' className={classes.profileImg} />
            ) : (
              <AccountCircle className={classes.profileImg} />
            )}
          </IconButton>
        </Grid>
        <Grid xs={6}>
          <p className={classes.title}>{userDetails?.first_name}</p>
          <p className={classes.subTitle}> {userDetails?.role?.role_name}</p>
        </Grid>
      </Grid>
    </Box>
  );
};
export default UserInfo;
