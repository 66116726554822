import {
  CloseCircleOutlined,
  DeleteOutlined,
  FileImageOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Card, Input } from 'antd';
import React, { useContext, useState } from 'react';
import Loader from '../../../../../components/loader/loader';
import axiosInstance from '../../../../../config/axios';
import endpoints from '../../../../../config/endpoints';
import { AlertNotificationContext } from '../../../../../context-api/alert-context/alert-state';

const FillBlanks = ({ questionData, questionList, parentIndex, setQuestions, comptype, childIndex }) => {
  const { setAlert } = useContext(AlertNotificationContext);
  const [optionsList, setOptionsList] = useState([{ optionValue: '', images: [] }]);
  const handleAddOption = () => {
    setOptionsList([...optionsList, { optionValue: '', images: [] }]);
  };

  const handleDeleteOption = (ind) => {
    let filteredOption = optionsList?.filter((item, index) => ind !== index);
    setOptionsList(filteredOption);
  };

  const [loading, setLoading] = useState(false);

  const handleFileUpload = (list, file, name, index) => {
    setLoading(true)
    if (
      file &&
      file[0] &&
      (file[0]?.name.lastIndexOf('.jpg') > 0 ||
        file[0]?.name.lastIndexOf('.jpeg') > 0 ||
        file[0]?.name.lastIndexOf('.png') > 0)
    ) {
      const formData = new FormData();
      formData.append('file', file[0]);
      formData.append('grade_name', questionData?.grade?.grade_name);
      formData.append('subject_name', questionData?.subject.children);
      formData.append('question_categories', questionData?.question_categories?.value);
      formData.append('question_type', questionData?.question_type?.children);
      axiosInstance
        .post(`${endpoints.questionBank.uploadFile}`, formData)
        .then((result) => {
          if (result?.data?.status_code === 200) {
            list[index][name].push(result?.data?.result);
            setOptionsList(list);
            setLoading(false);
            setAlert('success', result?.data?.message);
          } else {
            setLoading(false);
            setAlert('error', result?.data?.message);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
      // const formData = new FormData();
      // formData.append('file', file[0]);
    } else {
      setAlert('error', 'Only .jpg, .jpeg, .png format is acceptable');
    }
  };

  const handleOptionData = (e, index) => {
    e.preventDefault();
    let name = e.target?.name;
    let value;
    const list = [...optionsList];
    const qlist = [...questionList];
    if (name === 'optionValue') {
      value = e.target?.value;
      list[index][name] = value;
    } else if (name === 'images') {
      if (list[index][name]?.length < 2) {
        const file = e.target?.files;
        handleFileUpload([...optionsList], file, name, index);
      } else {
        setAlert('error', "Can't upload more than 2 images for one option");
      }
    }
    handleOptionUpdate(list, qlist);
    setOptionsList(list);
  };

  const handleDeleteFile = (image, rowIndex, imageIndex) => {
    setLoading(true)
    const list = [...optionsList];
    const qlist = [...questionList];
    axiosInstance
      .post(`${endpoints.questionBank.removeFile}`, {
        file_name: list[rowIndex]['images'][imageIndex],
      })
      .then((result) => {
        if (result?.data?.status_code === 204) {
          list[rowIndex]['images'].splice(imageIndex, 1);
          handleOptionUpdate(list, qlist);
          setOptionsList(list);
          setAlert('success', result?.data?.message);
          setLoading(false);
        } else {
          setAlert('error', result?.data?.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        setAlert('error', error?.message);
        setLoading(false);
      });
  };

  const handleOptionUpdate = (oplist, qlist) => {
    // let qa = questionList[parentIndex]?.question_answer;
    let list = [...qlist];
    // qa.push(oplist)
    let newoplist = oplist.map((item) => item);
    let arr = [];
    newoplist.forEach((item, index) => {
      let obj = {};
      obj[`option${index + 1}`] = item;
      arr.push(obj);
    });
    
    if(comptype == 'comp'){
      let subQuestions = list[parentIndex].subquestions[childIndex]
      subQuestions.question_answer[0]['options'] = arr;
      let answerlist = oplist.map((item) => item.optionValue);
      subQuestions.question_answer[0]['answer'] = answerlist
      // let subQuestions = [...list[parentIndex].subquestions]
      // subQuestions.push(questionTypeProps?.QuestionObj)
      list[parentIndex].subquestions[childIndex] = subQuestions
      setQuestions(list)
    } else{
      list[parentIndex].question_answer[0]['options'] = arr;
      let answerlist = oplist.map((item) => item.optionValue);
      list[parentIndex].question_answer[0]['answer'] = answerlist;
      setQuestions(list);
    }
  };


  return (
    <Card bordered={true} className='options-card' style={{ width: '100%' }}>
       {loading ? <Loader message='Loading...' /> : null}
      <div className='row'>
        <div className='col-md-6 col-12'>
          <label htmlFor='answer' className='text-success' style={{ fontWeight: 500 }}>
            Select Answer
          </label>
        </div>
        <div className='col-md-6 col-12 text-right'></div>
      </div>
      {Array.isArray(optionsList) &&
        optionsList.length > 0 &&
        optionsList.map((item, index) => (
          <div key={index}>
            <div className='row' style={{ alignItems: 'center', alignContent: 'center' }}>
              <div className='col-2 pr-0'>
                Blank {`${String.fromCharCode(index + 65).toUpperCase()}`}
              </div>
              <div className='col-9'>
                <Input
                  placeholder='Type the option here'
                  className='ml-2'
                  onChange={(e) => handleOptionData(e, index)}
                  name='optionValue'
                  value={item.optionValue}
                />
              </div>
              <div className='col-1'>
                {optionsList.length > 1 && (
                  <DeleteOutlined
                    style={{ fontSize: 18, cursor: 'pointer' }}
                    onClick={() => handleDeleteOption(index)}
                  />
                )}
              </div>
            </div>
            <div className='row mt-1'>
              <div className='col-2'></div>
              <div className='col-6 px-0'>
                <p className='ml-3' style={{ fontSize: 14, fontWeight: 500 }}>
                  {Array.isArray(item.images) &&
                    item.images.length > 0 &&
                    item.images?.map((imgitem, i) => (
                      <span key={i}>
                        {/* <FileImageOutlined /> {imgitem?.name} */}
                        <img
                          src={`${endpoints.s3}${imgitem}`}
                          alt='Not found'
                          className='optionImageAttachment'
                          style={{
                            marginLeft: '15px',
                            marginTop: '10px',
                            marginRight: '0',
                          }}
                        />
                        <CloseCircleOutlined
                          className='ml-2'
                          style={{ cursor: 'pointer' }}
                          onClick={() => handleDeleteFile(imgitem, index, i)}
                        />
                      </span>
                    ))}
                </p>
              </div>
              <div className='col-3 px-0 text-right'>
                <label
                  style={{
                    fontWeight: 500,
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  // htmlFor='file-upload'
                >
                  <input
                    type='file'
                    id='file-upload'
                    name='images'
                    onChange={(e) => {
                      handleOptionData(e, index);
                    }}
                    value=''
                    style={{ display: 'none' }}
                  />
                  <FileImageOutlined /> Attach Image
                </label>
              </div>
            </div>
          </div>
        ))}
      <div className='row my-2 justify-content-center text-center'>
        <div className='col-12'>
          {optionsList.length < 6 && (
            <Button
              className='btn add-another'
              style={{ boxShadow: 'none' }}
              onClick={handleAddOption}
            >
              <PlusOutlined /> Add another option
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
};

export default FillBlanks;
