import React, { useEffect, useState, useContext } from 'react';
import {
  Divider,
  Grid,
  withStyles,
  TextField,
  Typography,
  IconButton,
  Button,
  Card,
  CardContent,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import Dropzone from 'react-dropzone';
import endpoints from '../../../config/endpoints';
import axiosInstance from '../../../config/axios';
import Loading from '../../../components/loader/loader';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import DisableDashboardBreadcrumbs from '../../../components/common-breadcrumbs/disableDesktop';
import styles from '../ibookStyle';
import ConfirmDialog from '../../../components/confirm-dialog';

const EditChapter = ({ classes, open, fullDate, handleClose }) => {
  const { setAlert } = useContext(AlertNotificationContext);
  const [chapterId, setChapterId] = useState('');
  const [chapterName, setChapterName] = useState('');
  const [chapterHeader, setChapterHeader] = useState('');
  const [chapterEditorId, setChapterEditorId] = useState('');
  const [files, setFile] = useState('');
  const [loading, setLoading] = useState(false);
  const [conFirmDelete, setConFirmDelete] = useState(false);
  const bookTypeChoices = [
    // { label: 'General', value: '1' },
    // { label: 'Curriculum', value: '2' },
  ];

  const DialogTitle = (props) => {
    const { children, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant='h6'>{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  const getFileNameAndSize = (files) => {
    console.log(files, 'filess');
    if (files?.length) {
      const fileName =
        files &&
        files?.map((file) => (
          <li key={file.name}>{`${file.name} -${file.size} bytes`}</li>
        ));
      return fileName;
    }
    return null;
  };

  useEffect(() => {
    if (open && fullDate) {
      setChapterId(fullDate?.id);
      setChapterName(fullDate?.chapter_name);
      setChapterHeader(fullDate?.chapter_header);
      setChapterEditorId(fullDate?.chapter_editor_id);
      if (fullDate.path === 'prod/ibooks/') {
        setFile(
          // `https://erp-revamp.s3.ap-south-1.amazonaws.com/prod/ibooks/${fullDate?.chapter_image}`
          `${endpoints.ibookCDN}prod/ibooks/${fullDate?.chapter_image}`
        );
      } else {
        setFile(
          // `https://erp-revamp.s3.ap-south-1.amazonaws.com/dev/ibooks/${fullDate?.chapter_image}`
          `${endpoints.ibookCDN}dev/ibooks/${fullDate?.chapter_image}`
        );
      }
    }
  }, [open, fullDate]);

  const isPDF = (files) => {
    // if (files[0].name.match(/.(zip)$/i)) {
    //   return true;
    // }
    // return false;
  };

  const onDrop = (files = []) => {
    // if (!isPDF(files)) {
    //   setAlert('error', 'Please select only PDF format');
    //   return;
    // }
    // if (files.length > 1) {
    //   setAlert('error', 'You can select only a single zip at once');
    //   return;
    // }
    const sampleFile = files;
    setFile(sampleFile);
  };

  function handleSubmit() {
    if (!chapterName) {
      setAlert('error', 'Please Enter chapter Name');
      return;
    }
    if (!chapterHeader) {
      setAlert('error', 'Please Enter chapter Header');
      return;
    }
    if (!chapterEditorId) {
      setAlert('error', 'Please Enter chapter Editor Id');
      return;
    }
    if (!files) {
      setAlert('error', 'Please upload zip file');
      return;
    }
    const formData = new FormData();
    if (typeof files !== 'string') {
      for (let i = 0; i < files.length; i += 1) {
        formData.append('chapter_image', files[i]);
      }
    }

    formData.set('chapter_name', chapterName);
    formData.set('chapter_header', chapterHeader);
    formData.set('chapter_editor_id', chapterEditorId);
    axiosInstance
      .put(`${endpoints.ibook.editChapter}${chapterId}/edit-chapter/`, formData)
      .then((result) => {
        if (result.status === 200) {
          setLoading(false);
          setAlert('success', 'SUCCESSFULLY UPDATED IBOOK');
          handleClose('success');
        } else {
          setLoading(false);
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.message);
      });
  }

  function handleDelete() {
    setAlert('success', 'SUCCESSFULLY DELETED FILE');
    setFile('');
    setTimeout(() => {
      setConFirmDelete(false);
    }, 1000);
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Dialog
          maxWidth='xl'
          open={open}
          disableEnforceFocus
          aria-labelledby='transition-modal-title'
          aria-describedby='transition-modal-description'
          closeAfterTransition
          style={{ zIndex: conFirmDelete ? '' : '10000' }}
        >
          <DialogTitle id='alert-dialog-title' onClose={handleClose}>
            Update Chapter
          </DialogTitle>
          <Divider />
          <DialogContent>
            <Grid container spacing={2} style={{ textAlign: 'center' }}>
              <Grid item md={12} xs={12} style={{ textAlign: 'left' }}>
                {console.log(fullDate, 'fullDate12345')}
                <DisableDashboardBreadcrumbs
                  componentName={[fullDate?.book?.book_name, fullDate?.chapter_name]}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  value={chapterName}
                  variant='outlined'
                  margin='dense'
                  label='Chapter Name'
                  className='dropdownIcon'
                  placeholder='Chapter Name'
                  onChange={(e) => setChapterName(e.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  value={chapterHeader}
                  variant='outlined'
                  margin='dense'
                  label='Chapter Header'
                  className='dropdownIcon'
                  placeholder='Chapter Header'
                  onChange={(e) => setChapterHeader(e.target.value)}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  value={chapterEditorId}
                  variant='outlined'
                  margin='dense'
                  label='Chapter Editor Id'
                  className='dropdownIcon'
                  placeholder='Chapter Editor Id'
                  onChange={(e) => setChapterEditorId(e.target.value)}
                />
              </Grid>
              <Grid item md={12} xs={12} style={{ textAlign: 'left' }}>
                {files && typeof files === 'string' ? (
                  <Typography variant='h6' color='secondary'>
                    View Uploaded Image &nbsp;
                    <a href={files} target='_blank' without rel='noreferrer'>
                      View Image
                    </a>
                    &nbsp;
                    <IconButton
                      size='small'
                      color='primary'
                      onClick={() => setConFirmDelete(true)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Typography>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item md={12} xs={12}>
                      <Typography variant='h6' color='secondary'>
                        Upload Chapter (Accepted jpg,png,jpeg files)
                      </Typography>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Dropzone onDrop={onDrop}>
                        {({
                          getRootProps,
                          getInputProps,
                          isDragActive,
                          isDragAccept,
                          isDragReject,
                        }) => (
                          <Card
                            elevation={0}
                            style={{
                              width: '320px',
                              height: '150px',
                              border: '1px solid #ff6b6b',
                            }}
                            {...getRootProps()}
                            className='dropzone'
                          >
                            <CardContent>
                              <input {...getInputProps()} />
                              <div style={{ textAlign: 'center', marginTop: '40px' }}>
                                {isDragAccept && 'All files will be accepted'}
                                {isDragReject && 'Some files will be rejected'}
                                {!isDragActive && (
                                  <Button
                                    variant='text'
                                    style={{ background: 'none', color: '#014b7e' }}
                                  >
                                    <CloudUploadIcon />
                                    &nbsp;Drop file
                                  </Button>
                                )}
                              </div>
                              {getFileNameAndSize(files)}
                            </CardContent>
                          </Card>
                        )}
                      </Dropzone>
                    </Grid>
                  </Grid>
                )}
              </Grid>
              <Grid item md={12} xs={12}>
                <Button
                  disabled={!chapterName || !chapterHeader || !chapterEditorId || !files}
                  variant='contained'
                  color='primary'
                  onClick={handleSubmit}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
      {conFirmDelete ? (
        <ConfirmDialog
          open={conFirmDelete}
          cancel={setConFirmDelete}
          confirm={handleDelete}
          title='Are You Sure To Delete ?'
        />
      ) : (
        ''
      )}
    </>
  );
};

export default withStyles(styles)(EditChapter);
