export default (theme) => ({
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  paper: {
    overflow: true,
    backgroundColor: 'snow',
    padding: theme.spacing(1, 2, 1),
    marginTop: '10px',
  },
  modalPaper: {
    overflow: true,
    backgroundColor: 'snow',
    padding: theme.spacing(1, 2, 1),
    marginTop: '10px',
  },
  titlestyle: {
    marginTop: '13px',
    color: 'black',
    fontSize: '20px',
    display: 'block',
  },
  titlestylee: {
    marginTop: '15px',
    color: 'black',
    fontSize: '20px',
    display: 'block',
  },
  Descstyle: {
    marginTop: '10px',
    color: 'black',
    fontSize: '20px',
    display: 'block',
  },
  answerPaper: {
    color: 'lightgray',
    padding: theme.spacing(1, 2, 1),
    marginTop: '10px',
    width: '100%',
  },
  button: {
    marginTop: '9px',
  },
  Typography: {
    padding: theme.spacing(1, 2, 1),
    backgroundColor: 'snow',
  },
  TypographyDate: {
    padding: theme.spacing(1, 2, 0),
    color: 'black',
    marginTop: '5px',
  },
  texttitle: {
    color: 'black',
  },
  subtitlestyle: {
    marginTop: '9px',
    color: 'black',
    fontSize: '20px',
    display: 'block',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textField: {
    width: '100%',
  },
});
