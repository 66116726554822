/* eslint-disable react/jsx-no-duplicate-props */
import React, { useContext, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import IconButton from '@material-ui/core/IconButton';
import { Grid, TextField, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TablePagination from '@material-ui/core/TablePagination';
import Layout from '../../Layout';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import CommonBreadcrumbs from '../../../components/common-breadcrumbs/breadcrumbs';
import endpoints from '../../../config/endpoints';
import axiosInstance from '../../../config/axios';
import CreateModule from './createModule';
import EditModule from './editModule';
import '../master-management.css';
import Loading from '../../../components/loader/loader';
// import Loading from '../../../components/loader/loader';
// import BoardCard from './boardCard';
import ModuleCard from './moduleCard';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    boxShadow: 'none',
  },
  container: {
    maxHeight: '70vh',
  },
  buttonContainer: {
    background: theme.palette.background.secondary,
    paddingBottom: theme.spacing(2),
  },
  centerInMobile: {
    width: '100%',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  columnHeader: {
    color: `${theme.palette.secondary.main} !important`,
    fontWeight: 600,
    fontSize: '1rem',
    backgroundColor: `#ffffff !important`,
  },
  tableCell: {
    color: theme.palette.secondary.main,
  },
}));

const columns = [
  { id: 'board_name', label: 'Board', minWidth: 100 },
  // { id: 'module_sequence', label: 'Module Sequence', minWidth: 100 },
  { id: 'module_name', label: 'Module Name', minWidth: 100 },
  // {
  //   id: 'actions',
  //   label: 'Actions',
  //   minWidth: 170,
  //   align: 'right',
  //   labelAlign: 'center',
  // },
];

const ModuleTable = () => {
  const classes = useStyles();
  const { setAlert } = useContext(AlertNotificationContext);
  const [page, setPage] = React.useState(1);
  const [board, setBoard] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [boardId, setBoardId] = useState();
  const [subjectDropdown, setSubjectDropdown] = useState([]);
  const [academicYearDropdown, setAcademicYearDropdown] = useState([]);
  const [volumeDropdown, setVolumeDropdown] = useState([]);
  const [gradeDropdown, setGradeDropdown] = useState([]);
  const [boardDropdown, setBoardDropdown] = useState([]);
  const [selectedBoardId, setSelectedBoardId] = useState([]);
  const [moduleDropdown, setModuleDropdown] = useState([]);
  const [boardName, setBoardName] = useState('');
  const [addFlag, setAddFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [tableFlag, setTableFlag] = useState(true);
  const [delFlag, setDelFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const limit = 15;
  const [goBackFlag, setGoBackFlag] = useState(false);
  const [moduleData, setModuleData] = useState([]);
  const [filterData, setFilterData] = useState({
    year: '',
    volume: '',
    grade: '',
    subject: '',
    chapter: '',
    board: '',
  });

  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const wider = isMobile ? '-10px 0px' : '-10px 0px 20px 8px';
  const widerWidth = isMobile ? '98%' : '95%';

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleAddYear = () => {
    setTableFlag(false);
    setAddFlag(true);
    setEditFlag(false);
  };

  const handleEditYear = (year) => {
    setTableFlag(false);
    setAddFlag(false);
    setEditFlag(true);
    // setBoardId(id);
    // setBoardName(year);
    setModuleData(year);
  };

  const handleGoBack = () => {
    setPage(1);
    setTableFlag(true);
    setAddFlag(false);
    setEditFlag(false);
    setGoBackFlag(!goBackFlag);
    // getList();
  };

  const handleGrade = (event, value) => {
    setFilterData({ ...filterData, grade: '', subject: '', board: '' });
    if (value) {
      setLoading(true);
      setFilterData({ ...filterData, grade: value });
      axiosInstance
        .get(`${endpoints.masterManagement.gradeSubjectMappingList}?grade=${value.id}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setSubjectDropdown(result.data.result.results);
          } else {
            setAlert('error', result.data.message);
            setSubjectDropdown([]);
          }
        })
        .catch((error) => {
          setAlert('error', error.message);
          setSubjectDropdown([]);
        });
    } else {
      setSubjectDropdown([]);
    }
  };

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`${endpoints.masterManagement.academicYear}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setAcademicYearDropdown(result.data.result.results);
          handleAcademicYear('', result.data.current_acad_session_data[0]);
          setLoading(false);
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });
    setLoading(true);
    axiosInstance
      .get(`${endpoints.masterManagement.volumes}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setVolumeDropdown(result.data.result.results);
          setLoading(false);
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });

    axiosInstance
      .get(`${endpoints.masterManagement.grades}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setGradeDropdown(result.data.result.results);
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });
  }, []);

  const handleClearFilter = () => {
    setFilterData({
      year: '',
      volume: '',
      grade: '',
      subject: '',
      board: '',
    });
    // getList()
  };

  const handleDeleteYear = (e) => {
    e.preventDefault();
    setLoading(true);

    axiosInstance
      .delete(`${endpoints.masterManagement.module}${boardId}/`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setLoading(false);
          setDelFlag(true);
          setAlert('success', result.data.message);
        } else {
          setLoading(false);
          // getList()
          setAlert('success', result.data.message);
          setDelFlag(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.message);
      });
    setOpenDeleteModal(false);
  };

  const handleDelete = (year) => {
    setBoardName(year.lt_module_name);
    handleOpenDeleteModal(year.id);
  };

  const handleAcademicYear = (event, value) => {
    setFilterData({
      ...filterData,
      year: '',
      volume: '',
      grade: '',
      subject: '',
      board: '',
    });
    if (value) {
      setFilterData({ ...filterData, year: value });
    }
  };

  const handleVolume = (event, value) => {
    setLoading(true);
    setFilterData({ ...filterData, volume: '', grade: '', subject: '', board: '' });
    if (value) {
      setLoading(false);
      setFilterData({ ...filterData, volume: value });
    }
  };

  const handleSubject = (event, value) => {
    setLoading(true);
    setFilterData({ ...filterData, subject: '', board: '' });
    if (value) {
      setFilterData({ ...filterData, subject: value });
      axiosInstance
        .get(`${endpoints.masterManagement.boardMappingList}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setBoardDropdown(result.data.result);
          } else {
            setAlert('error', result.data.message);
            setBoardDropdown([]);
          }
        })
        .catch((error) => {
          setAlert('error', error.message);
          setBoardDropdown([]);
        });
    } else {
      setBoardDropdown([]);
    }
  };

  const handleBoard = (event = {}, values = []) => {
    setFilterData({ ...filterData, board: '' });
    setLoading(true);
    if (values) {
      const ids = values.map((el) => el);
      const selectedId = values.map((el) => el?.id);
      setSelectedBoardId(selectedId);
      setFilterData({ ...filterData, board: ids });
      axiosInstance
        .get(
          `${endpoints.masterManagement.module}?academic_year=${filterData?.year?.id}&volume=${filterData?.volume?.id}&grade_subject=${filterData?.subject?.id}&board=${selectedId}`
        )
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setModuleDropdown(result.data.result);
            setAlert('success', result.data.message);
          } else {
            setAlert('error', result.data.message);
            setModuleDropdown([]);
            setLoading(false);
          }
        })
        .catch((error) => {
          setAlert('error', error.message);
          setModuleDropdown([]);
          setLoading(false);
        });
    } else {
      setModuleDropdown([]);
    }
  };

  const handleOpenDeleteModal = (id) => {
    setBoardId(id);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    // window.location.reload()
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      // getList();
      setLoading(false);
    }, 450);
  }, [page, delFlag, goBackFlag]);

  const getList = () => {
    axiosInstance
      .get(
        // `${endpoints.masterManagement.module}?academic_year=${filterData.year?.id}&volume=${filterData.volume?.id}&grade_subject=${filterData.grade?.id}&board=${filterData.board?.id}&page=${page}&page_size=${limit}`
        `${endpoints.masterManagement.module}?page=${page}&page_size=${limit}`
      )
      .then((result) => {
        if (result.status === 200) {
          {
            setBoard(result.data.result);
            setTotalCount(result.data.count);
          }
        } else {
          setAlert('error', result.error_message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });
  };

  const handleFilter = () => {
    setLoading(true);
    if (!filterData?.volume) {
      setAlert('error', 'Select Volume!');
      return;
    }
    if (!filterData?.grade) {
      setAlert('error', 'Select Grade!');
      return;
    }
    if (!filterData?.subject) {
      setAlert('error', 'Select Subject!');
      return;
    }
    if (!filterData?.board) {
      setAlert('error', 'Select Board !');
      return;
    }
    // if (!filterData?.module) {
    //   setAlert('error', 'Select Module !');
    //   return;
    // }
    // if (!filterData?.chapter) {
    //   setAlert('error', 'Select Chapter!');
    //   return;

    // }
    else {
      axiosInstance
        .get(
          //topic_name chapter
          // `${endpoints.masterManagement.createTopic}?chapter=${filterData.chapter.id}&page_size=${limit}`
          `${endpoints.masterManagement.module}?academic_year=${
            filterData.year?.id
          }&volume=${filterData.volume?.id}&grade_subject=${
            filterData.subject?.id
          }&board=${selectedBoardId}&page=${1}&page_size=${limit}`
        )
        .then((result) => {
          //console.log(res.data,'topic');
          if (result.data.status_code === 200) {
            // setLoading(true);
            // setTotalCount(res.data.count);
            // setTopicList(res.data.result);
            setLoading(false);
            setBoard(result.data.result);
            setTotalCount(result.data.count);
            setAlert('success', result.data.message);
          } else {
            // setLoading(false);
            setAlert('error', result.error.message);
          }
        })
        .catch((error) => {
          //console.log(error);
          // setLoading(false);
          setAlert('error', error.message);
        });
    }
  };

  // useEffect(() => {
  //   axiosInstance
  //     .get(

  //       `${endpoints.masterManagement.module}?page=${page}&page_size=${limit}`
  //     )
  //     .then((result) => {
  //       if (result.status === 200) {
  //         {
  //           setBoard(result.data.result);
  //           setTotalCount(result.data.count);

  //         }
  //       } else {
  //         setAlert('error', result.error_message);
  //       }
  //     })
  //     .catch((error) => {
  //       setAlert('error', error.message);
  //     });
  // }, [page]);

  useEffect(() => {
    setDelFlag(false);
    if (
      filterData?.year !== 0 &&
      filterData?.volume?.length !== 0 &&
      filterData?.grade?.length !== 0 &&
      filterData?.board?.length !== 0
    ) {
      axiosInstance
        .get(
          // `${endpoints.masterManagement.module}?page=${page}&page_size=${limit}`
          `${endpoints.masterManagement.module}?academic_year=${filterData.year?.id}&volume=${filterData.volume?.id}&grade_subject=${filterData?.subject?.id}&board=${selectedBoardId}&page=${page}&page_size=${limit}`
        )
        .then((result) => {
          if (result.status === 200) {
            {
              setBoard(result.data.result);
              setTotalCount(result.data.count);
              setAlert('success', result.data.message);
            }
          } else {
            setAlert('error', result.error_message);
          }
        })
        .catch((error) => {
          setAlert('error', error.message);
        });
    }
  }, [delFlag, goBackFlag, page, filterData]);
  return (
    <>
      {loading ? <Loading message='Loading...' /> : null}
      <Layout>
        <div>
          <div style={{ width: '95%', margin: '20px auto' }}>
            <CommonBreadcrumbs
              componentName='Master Management'
              childComponentName='Modules'
              childComponentNameNext={
                addFlag && !tableFlag
                  ? 'Add Module'
                  : editFlag && !tableFlag
                  ? 'Edit Module'
                  : null
              }
            />
          </div>
        </div>

        {!tableFlag && addFlag && !editFlag && (
          <CreateModule
            setLoading={setLoading}
            handleGoBack={handleGoBack}
            moduleData={filterData}
          />
        )}
        {!tableFlag && !addFlag && editFlag && (
          <EditModule
            moduleData={moduleData}
            handleGoBack={handleGoBack}
            setLoading={setLoading}
          />
        )}

        {tableFlag && !addFlag && !editFlag && (
          <Grid
            container
            spacing={isMobile ? 3 : 5}
            style={{ width: widerWidth, margin: wider }}
          >
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
              <Autocomplete
                size='small'
                onChange={handleAcademicYear}
                style={{ width: '100%' }}
                id='grade'
                options={academicYearDropdown}
                value={filterData.year}
                getOptionLabel={(option) => option?.session_year}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Academic Year'
                    placeholder='Academic Year'
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
              <Autocomplete
                size='small'
                onChange={handleVolume}
                style={{ width: '100%' }}
                id='grade'
                options={volumeDropdown}
                value={filterData.volume}
                getOptionLabel={(option) => option?.volume_name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Volume'
                    placeholder='Volume'
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
              <Autocomplete
                size='small'
                onChange={handleGrade}
                style={{ width: '100%' }}
                id='grade'
                options={gradeDropdown}
                value={filterData.grade}
                getOptionLabel={(option) => option?.grade_name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Grades'
                    placeholder='Grades'
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
              <Autocomplete
                size='small'
                onChange={handleSubject}
                style={{ width: '100%' }}
                id='grade'
                options={subjectDropdown}
                value={filterData.subject}
                getOptionLabel={(option) => option?.subject?.subject_name}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Subject'
                    placeholder='Subject'
                    required
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
              <Autocomplete
                multiple
                size='small'
                onChange={handleBoard}
                style={{ width: '100%' }}
                id='board'
                options={boardDropdown || []}
                value={filterData.board || []}
                getOptionLabel={(option) => option?.board_name || ''}
                // filterSelectedOptions
                getOptionSelected={(option, value) => option?.id == value?.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Board'
                    placeholder='Board'
                    required
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  multiple
                  size='small'
                  onChange={handleModule}
                  style={{ width: '100%' }}
                  id='module'
                  options={moduleDropdown || []}
                  value={filterData.module || []}
                  getOptionLabel={(option) => option?.lt_module_name || ''}
                  // filterSelectedOptions
                  getOptionSelected={(option, value) =>
                    option?.id == value?.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Module'
                      placeholder='Module'
                      required
                    />
                  )}
                />
              </Grid> */}
            <Grid item sm={12} className={isMobile ? 'hideGrideItem' : ''} />
            <Grid item xs={6} sm={3} className={isMobile ? '' : 'addButtonPadding'}>
              <Button
                // startIcon={<ClearAllIcon style={{ fontSize: '30px' }} />}
                variant='contained'
                color='default'
                // size='small'
                className={classes.clrBtn}
                // style={{ color: 'white' }}
                title='Clear All'
                onClick={handleClearFilter}
              >
                Clear All
              </Button>
            </Grid>

            <Grid
              item
              xs={6}
              sm={3}
              md={3}
              className={isMobile ? '' : 'addButtonPadding'}
            >
              <Button
                // startIcon={<ClearAllIcon style={{ fontSize: '30px' }} />}
                variant='contained'
                color='primary'
                size='medium'
                style={{ color: 'white' }}
                title='Filter'
                onClick={handleFilter}
              >
                Filter
              </Button>
            </Grid>
            {/* <Grid item xs={12} sm={4} className='addButtonPadding'>
              <Button
                startIcon={<AddOutlinedIcon style={{ fontSize: '30px' }} />}
                variant='contained'
                color='primary'
                size='small'
                style={{ color: 'white' }}
                title='Add Module'
                onClick={handleAddYear}
              >
                Add Modules
              </Button>
            </Grid> */}
          </Grid>
        )}

        {!isMobile && tableFlag && !addFlag && !editFlag && (
          <Paper className={`${classes.root} common-table`}>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label='sticky table'>
                <TableHead className='table-header-row'>
                  <TableRow>
                    {/* {console.log(columns,'shivaaa')} */}
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                        className={classes.columnHeader}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {board.map((year, index) => {
                    return (
                      <TableRow hover board='checkbox' tabIndex={-1} key={index}>
                        <TableCell className={classes.tableCell}>
                          {year?.board?.board_name}
                        </TableCell>
                        {/* <TableCell className={classes.tableCell}>
                          {year?.sequence}
                        </TableCell> */}
                        <TableCell className={classes.tableCell}>
                          {year?.lt_module_name}
                        </TableCell>
                        {/* <TableCell className={classes.tableCell}>
                          <IconButton
                            onClick={(e) => {
                              handleDelete(year);
                            }}
                            title='Delete Module'
                          >
                            <DeleteOutlinedIcon style={{ color: '#fe6b6b' }} />
                          </IconButton>

                          <IconButton
                            onClick={(e) => handleEditYear(year)}
                            title='Edit Modules'
                          >
                            <EditOutlinedIcon style={{ color: '#fe6b6b' }} />
                          </IconButton>
                        </TableCell> */}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <div className='paginateData'>
              <TablePagination
                component='div'
                count={totalCount}
                rowsPerPage={limit}
                page={page - 1}
                onChangePage={handleChangePage}
                rowsPerPageOptions={false}
              />
            </div>
          </Paper>
        )}
        {isMobile && !addFlag && !editFlag && (
          <>
            {board.map((year) => (
              <ModuleCard
                year={year}
                handleDelete={handleDelete}
                handleEditYear={handleEditYear}
              />
            ))}
            <div className='paginateData paginateMobileMargin'>
              <TablePagination
                component='div'
                count={totalCount}
                rowsPerPage={limit}
                page={page - 1}
                onChangePage={handleChangePage}
                rowsPerPageOptions={false}
              />
            </div>
          </>
        )}
        <Dialog
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          aria-labelledby='draggable-dialog-title'
        >
          <DialogTitle
            style={{ cursor: 'move', color: '#014b7e' }}
            id='draggable-dialog-title'
          >
            Delete Module
          </DialogTitle>
          <DialogContent>
            <DialogContentText>{`Confirm Delete Module Name ${boardName}`}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteModal} className='labelColor cancelButton'>
              Cancel
            </Button>
            <Button
              color='primary'
              variant='contained'
              style={{ color: 'white' }}
              onClick={handleDeleteYear}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Layout>
    </>
  );
};

export default ModuleTable;
