import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import AlertNotificationProvider from './context-api/alert-context/alert-state';
import './assets/styles/styles.scss';
import Login from './containers/login';
import CentralDashboard from './containers/dashboard/centraldashboard';
import CentralManagement from './containers/central-management';
import AddEditUser from './containers/user-management/create-user/create-user';
import ViewUsers from './containers/user-management/view-user/view-user';
import Profile from './containers/profile/profile';
import LicenseTable from './containers/zoom-license/licensetable';
import AssignLicense from './containers/assign-license/assign-license';
import Mapping from './containers/mapping/mapping';
import Assesment from './containers/assesment';
import SubjectTable from './containers/master-management/subject/subject-table';
import GradeTable from './containers/master-management/grade/grade-table';
import VolumeTable from './containers/master-management/volume/volume-table';
import AcademicYearTable from './containers/master-management/academic-year/academic-year-table';
import BoardTable from './containers/master-management/board/borde';
// import ModuleTable from './containers/master-management/module/module';
import ModuleTable from './containers/master-management/module/module';
import ChapterTable from './containers/master-management/chapter/chapter-table';
import GradeSubjectTable from './containers/master-management/grade-subject-mapping/grade-subject-mapping-table';
// import PeriodTable from './containers/master-management/period/period-table';
import LessonPlan from './containers/lesson-plan/lesson-plan-view';
import CreateLessonPlan from './containers/lesson-plan/create-lesson-plan';

import EditLessonPlan from './containers/lesson-plan/create-lesson-plan/Edit-Lesson-plan';
import QuestionBankList from './containers/question-bank/question-bank-list';
import CreateQuestion from './containers/question-bank/create-question';
import CreateQuestionPaper from './containers/assesment/create-question-paper';
// import Assesmentquestion from './containers/assesment/assesment';
import AssessmentView from './containers/assesment/assesment-view';
import CreateAssesment from './containers/assesment/create-assesment';
import AttachmentPreviewer from './components/attachment-previewer';
import { CreateEbook, ViewEbook } from './containers/ebooks';
import TopicTable from './containers/master-management/topic/TopicTable';
import CurriculumPlan from './containers/master-management/curriculum/CurriculumTable';
// Intelligent books
import CreateIbook from './containers/Ibooks/ibook/CreateIbook';
import CreateIbookChapter from './containers/Ibooks/chapter/CreateIbookChapter';
import ViewiBook from './containers/Ibooks/ViewiBook';
import ViewiChapter from './containers/Ibooks/chapter/ViewChapter';
import LessonPlanFeedback from './containers/lesson-plan/lesson-plan-feedback/feedback-form';
import UserLevelTable from './containers/user-management/userLevel/userlevel';
import Filters from './containers/assesment/assessment-new/create-question-paper-new/filters';
import '../src/assets/styles/common.scss';
import '../src/assets/styles/antdTab.scss';
import '../src/assets/styles/antdTable.scss';
import '../src/assets/styles/antdDrawer.scss';
import '../src/assets/styles/measurement.scss';
import CreatequestionPaperNew from './containers/assesment/assessment-new/create-question-paper-new/createquestionpaper';
import CreateIndices from './containers/createIndices';
// import PPTView from './components/attachment-previewer/attachment-previewer-ui/pptview';
import AutomatedAssessment from './containers/automatedAssessment/QuestionTagging';
import QrcodeUpload from './containers/lesson-plan/qrcode-upload';
import CreateTemplate from './containers/automatedAssessment/CreateTemplate';
import Template from './containers/automatedAssessment/Template';
import QuestionPaper from './containers/automatedAssessment/QuestionPaper';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ff6b6b',
    },
    secondary: {
      main: '#014b7e',
    },
    text: {
      default: '#014b7e',
    },
    background: {
      primary: '#ffffff',
      secondary: '#f9f9f9',
    },
  },
  typography: {
    fontSize: 16,
    color: '#1b4ccb',
  },

  overrides: {
    MuiButton: {
      // Name of the rule
      root: {
        // Some CSS
        color: '#ffffff',
        backgroundColor: '#1b4ccb',
      },
    },
  },
});

function App() {
  const { role_details: roleDetailes } =
    JSON.parse(localStorage.getItem('userDetails')) || {};
  const test = {
    name: 'Test',
    email: 'test@gmail.com',
    contact: '946354123',
    role: { id: 1, role_name: 'Marketing' },
    // profile: roleDetailes.user_profile,
  };

  React.useEffect(() => {
    const {
      repoName = 'Central',
      first_name: firstName,
      user_id: userId,
      is_superuser: isSuperuser,
    } = JSON.parse(localStorage.getItem('userDetails') || '{}') || {};
    if (window.location.hostname.includes('localhost')) {
      document.title = [repoName, firstName, userId, isSuperuser ? 'Spr' : 'Nrml'].join(
        ' - '
      );
    }
  }, []);

  return (
    <div className='App'>
      <Router>
        <AlertNotificationProvider>
          <ThemeProvider theme={theme}>
            <AttachmentPreviewer>
              <Switch>
                {/* <Route path='/assesment'>
                  {({ match }) => <Assesment match={match} />}
                </Route>
                <Route path='/create-assesment'>
                  {({ match }) => <CreateAssesment match={match} />}
                </Route> */}
                <Route path='/lesson-plan/create'>
                  {({ match }) => <CreateLessonPlan match={match} />}
                </Route>
                <Route path='/lesson-plan/edit'>
                  {({ match }) => <EditLessonPlan match={match} />}
                </Route>
                <Route path='/lesson-plan/view'>
                  {({ match }) => <LessonPlan match={match} />}
                </Route>
                <Route path='/profile'>{({ match }) => <Profile match={match} />}</Route>
                <Route path='/dashboard'>
                  {({ match }) => <CentralDashboard match={match} />}
                </Route>
                <Route path='/central-management'>
                  {({ match }) => <CentralManagement match={match} />}
                </Route>
                <Route path='/add-user'>
                  {({ match }) => <AddEditUser match={match} />}
                </Route>
                <Route path='/view-edit-user'>
                  {({ match }) => <ViewUsers match={match} />}
                </Route>
                <Route exact path='/license-table'>
                  {({ match }) => <LicenseTable match={match} />}
                </Route>
                <Route exact path='/assign-license'>
                  {({ match }) => <AssignLicense match={match} />}
                </Route>
                <Route exact path='/standard-mapping'>
                  {({ match }) => <Mapping match={match} />}
                </Route>
                <Route exact path='/'>
                  {({ match, history }) => <Login match={match} history={history} />}
                </Route>
                <Route exact path='/master-management/subject-table'>
                  {({ match }) => <SubjectTable match={match} />}
                </Route>
                <Route exact path='/master-management/grade-table'>
                  {({ match }) => <GradeTable match={match} />}
                </Route>
                <Route exact path='/master-management/academic-year-table'>
                  {({ match }) => <AcademicYearTable match={match} />}
                </Route>
                <Route exact path='/master-management/board'>
                  {({ match }) => <BoardTable match={match} />}
                </Route>
                <Route exact path='/master-management/module'>
                  {({ match }) => <ModuleTable match={match} />}
                </Route>
                <Route exact path='/master-management/volume-table'>
                  {({ match }) => <VolumeTable match={match} />}
                </Route>
                <Route exact path='/master-management/chapter-table'>
                  {({ match }) => <ChapterTable match={match} />}
                </Route>
                <Route exact path='/master-management/topic-table'>
                  {({ match }) => <TopicTable match={match} />}
                </Route>
                <Route exact path='/master-management/curriculum-plan'>
                  {({ match }) => <CurriculumPlan match={match} />}
                </Route>
                <Route exact path='/master-management/grade-subject-mapping-table'>
                  {({ match }) => <GradeSubjectTable match={match} />}
                </Route>
                <Route exact path='/question-bank'>
                  {({ match }) => <QuestionBankList match={match} />}
                </Route>
                <Route exact path='/create-question/:qId?'>
                  {({ match }) => <CreateQuestion match={match} />}
                </Route>
                {/* <Route exact path='/create-question-paper'>
                  {({ match }) => <CreateQuestionPaper match={match} />}
                </Route> */}
                <Route exact path='/create-question-paper/:id?'>
                  {({ match }) => <CreateQuestionPaper match={match} />}
                </Route>
                <Route exact path='/create-questionpaper/'>
                  {({ match }) => <Filters match={match} />}
                </Route>
                <Route exact path='/create-questionspaper/'>
                  {({ match }) => <CreatequestionPaperNew match={match} />}
                </Route>

                <Route exact path='/assessment-question'>
                  {({ match }) => <AssessmentView match={match} />}
                </Route>
                <Route exact path='/ebook/create'>
                  {({ match }) => <CreateEbook match={match} />}
                </Route>
                <Route exact path='/ebook/view'>
                  {({ match }) => <ViewEbook match={match} />}
                </Route>
                <Route exact path='/ibook/view'>
                  {({ match }) => <ViewiBook match={match} />}
                </Route>
                <Route exact path='/ibook/create'>
                  {({ match }) => <CreateIbook match={match} />}
                </Route>
                <Route exact path='/iChapter/view'>
                  {({ match }) => <ViewiChapter match={match} />}
                </Route>
                <Route exact path='/ibook-chapter/create'>
                  {({ match }) => <CreateIbookChapter match={match} />}
                </Route>

                <Route exact path='/lesson-plan/feedback'>
                  {({ match }) => <LessonPlanFeedback match={match} />}
                </Route>
                <Route exact path='/user-level'>
                  {({ match }) => <UserLevelTable match={match} />}
                </Route>
                <Route exact path='/lesson-plan/createindices'>
                  {({ match }) => <CreateIndices match={match} />}
                </Route>
                {/* <Route exact path='/pptview/'>
                  {({ match }) => <PPTView match={match} />}
                </Route> */}
                {/* <Route exact path='/assesment-question'>
                {({ match }) => <Assesmentquestion match={match} />}
              </Route> */}
                <Route exact path='/auto-assessment/question-tagging'>
                  {({ match }) => <AutomatedAssessment match={match} />}
                </Route>
                <Route exact path='/qrcode-upload'>
                  {({ match }) => <QrcodeUpload match={match} />}
                </Route>
                <Route exact path='/auto-assessment/question-paper'>
                  {({ match }) => <QuestionPaper match={match} />}
                </Route>
                <Route exact path='/auto-assessment/template'>
                  {({ match }) => <Template match={match} />}
                </Route>
                <Route exact path='/auto-assessment/create-template'>
                  {({ match }) => <CreateTemplate match={match} />}
                </Route>
              </Switch>
            </AttachmentPreviewer>
          </ThemeProvider>
        </AlertNotificationProvider>
      </Router>
    </div>
  );
}

export default App;
