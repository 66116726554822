import React , { useContext, useState } from 'react';
import { Grid, TextField, Button, useTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import endpoints from '../../../config/endpoints';
import axiosInstance from '../../../config/axios';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';

const EditAcademicYear = ({id,board,handleGoBack,setLoading}) => {

  const { setAlert } = useContext(AlertNotificationContext);
  const [board_name,setBoard_name]=useState(board||'')
  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));

  
  const handleSubmit = (e) => {
    e.preventDefault()

    setLoading(true)
    let request={}
    request['id']=id
    if(board_name!=="" && board_name!==board)
    {
      request['board_name']=board_name
     
      axiosInstance.put(`${endpoints.masterManagement.board}${id}/`,request)
      .then(result=>{
          console.log(result);
      if (result.status === 200) {
          handleGoBack()
          setBoard_name('')
          setLoading(false);
          setAlert('success', 'updated successfully');
      }
      else {
        setLoading(false);
        setAlert('error', result.data.message);
      }
      }).catch((error)=>{
        setLoading(false);
        setAlert('error', error.message);
      })
    }
    else
    {
      setLoading(false);
      setAlert('error','No Fields to Update')
    }
    };


  return (
    <form autoComplete='off' onSubmit={handleSubmit}>
        <div style={{ width: '95%', margin: '20px auto' }}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4} className={isMobile?'':'addEditPadding'}>
            <TextField
              id='board_name'
              label='Board name'
              variant='outlined'
              style={{ width: '100%' }}
              size='small'
            //   inputProps={{maxLength:7,pattern:'^[0-9]{4}-[0-9]{2}'}}
              value={board_name}
              name='board_name'
              onChange={e=>setBoard_name(e.target.value)}
            />
          </Grid>
          </Grid>
        </div>
        <Grid container spacing={isMobile?1:5} style={{ width: '95%', margin: '10px'}} >
          <Grid item xs={6} sm={2} className={isMobile?'':'addEditButtonsPadding'}>
            <Button variant='contained' className="custom_button_master labelColor" size='medium' onClick={handleGoBack}>
              Back
            </Button>
          </Grid>
          <Grid item xs={6} sm={2} className={isMobile?'':'addEditButtonsPadding'}>
            <Button variant='contained' style={{color:'white'}} color ="primary" className="custom_button_master" size='medium' type='submit'>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
  );
};

export default EditAcademicYear;
