/* eslint-disable react/jsx-no-duplicate-props */
import React, { useContext, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import IconButton from '@material-ui/core/IconButton';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { Grid, TextField, Button, useTheme } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Layout from '../../Layout';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import CommonBreadcrumbs from '../../../components/common-breadcrumbs/breadcrumbs';
import endpoints from '../../../config/endpoints';
import axiosInstance from '../../../config/axios';
import CreateTopic from './CreateTopic';
import EditTopic from './EditTopic';
import Loading from '../../../components/loader/loader';
import '../master-management.css';
import SubjectCard from './subjects-card';
import ClearAllIcon from '@material-ui/icons/ClearAll';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '0 auto',
    boxShadow: 'none',
  },
  container: {
    maxHeight: '70vh',
    width: '100%',
  },
  columnHeader: {
    color: `${theme.palette.secondary.main} !important`,
    fontWeight: 600,
    fontSize: '1rem',
    backgroundColor: `#ffffff !important`,
  },
  tableCell: {
    color: theme.palette.secondary.main,
  },
  clrBtn: {
    margin: 3,
    color: 'rgb(140, 140, 140) !important',
  },
  buttonContainer: {
    width: '95%',
    margin: '0 auto',
    background: theme.palette.background.secondary,
    paddingBottom: theme.spacing(2),
  },
}));

const columns = [
  {
    id: '',
    label: 'Key Concept Sequence',
    minWidth: 100,
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'subject_name',
    label: 'Key Concept Name',
    minWidth: 100,
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'created_by',
    label: 'Chapter Sequence',
    minWidth: 100,
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'created_by',
    label: 'Chapter Name',
    minWidth: 100,
    align: 'center',
    labelAlign: 'center',
  },
  // {
  //   id: 'desc',
  //   label: 'Description',
  //   minWidth: 100 ,
  //   align: 'center',
  //   labelAlign: 'center',
  // },
  // {
  //   id: 'optional',
  //   label: 'Optional',
  //   minWidth: 50,
  //   align: 'center',
  //   labelAlign: 'center',
  // },
  {
    id: 'actions',
    label: 'Actions',
    minWidth: 170,
    align: 'center',
    labelAlign: 'center',
  },
];

const TopicTable = () => {
  const classes = useStyles();
  const { setAlert } = useContext(AlertNotificationContext);
  const [page, setPage] = useState(1);
  const [grades, setGrades] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [subjectName, setSubjectName] = useState('');
  const [addFlag, setAddFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [tableFlag, setTableFlag] = useState(true);
  const [delFlag, setDelFlag] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [searchGrade, setSearchGrade] = useState('');
  const [searchSection, setSearchSection] = useState('');
  const [loading, setLoading] = useState(false);
  const [topicList, setTopicList] = useState([]);
  const [gradeDropdown, setGradeDropdown] = useState([]);
  const [subjectDropdown, setSubjectDropdown] = useState([]);
  const [boardDropdown, setBoardDropdown] = useState([]);
  const [chapterDropdown, setChapterDropdown] = useState([]);
  const [selectedBoardId, setSelectedBoardId] = useState([]);
  const [moduleDropdown, setModuleDropdown] = useState([]);
  const [selectedModuleId, setSelectedModuleId] = useState([]);
  const [academicYearDropdown, setAcademicYearDropdown] = useState([]);
  const [volumeDropdown, setVolumeDropdown] = useState([]);

  const [chapterEditDetails, setChapterEditDetails] = useState([]);
  const [filterData, setFilterData] = useState({
    year: '',
    grade: '',
    subject: '',
    chapter: '',
    board: '',
    module: '',
    volume: '',
  });

  const [chapterId, setChapterId] = useState('');
  const topicUpdateApi = '/update-topic/';

  const limit = 10;

  const [goBackFlag, setGoBackFlag] = useState(false);
  const { role_details } = JSON.parse(localStorage.getItem('userDetails'));
  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));

  const wider = isMobile ? '-10px 0px' : '-10px 0px 20px 8px';
  const widerWidth = isMobile ? '98%' : '95%';

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  useEffect(() => {
    axiosInstance
      .get(`${endpoints.masterManagement.academicYear}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setAcademicYearDropdown(result.data.result.results);
          handleAcademicYear('', result.data.current_acad_session_data[0]);
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });

    axiosInstance
      .get(`${endpoints.masterManagement.volumes}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setVolumeDropdown(result.data.result.results);
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });

    axiosInstance
      .get(`${endpoints.masterManagement.grades}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setGradeDropdown(result.data.result.results);
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });
  }, []);

  useEffect(() => {
    if (
      filterData?.subject?.id &&
      filterData?.board.length !== 0 &&
      filterData.module.length !== 0
    ) {
      setLoading(true);
      axiosInstance
        .get(
          `${endpoints.masterManagement.chapters}?academic_year=${filterData?.year?.id}&grade_subject=${filterData.subject.id}&board=${selectedBoardId}&lt_module=${filterData?.module?.id}`
        )
        .then((res) => {
          if (res.data.status_code === 200) {
            setLoading(false);
            setChapterDropdown(res.data.result);
          } else {
            // setAlert('error', res.data.message);
            setLoading(false);
          }
        })
        .catch((error) => setAlert('error', error.message));
    } else if (filterData.subject.id && filterData.board.length !== 0) {
      axiosInstance
        .get(
          `${endpoints.masterManagement.chapters}?academic_year=${filterData?.year?.id}&grade_subject=${filterData.subject.id}&board=${selectedBoardId}`
        )
        .then((res) => {
          if (res.data.status_code === 200) {
            setChapterDropdown(res.data.result);
          } else {
            // setAlert('error', res.data.message);
          }
        })
        .catch((error) => setAlert('error', error.message));
    }
  }, [filterData]);

  const handleGrade = (event, value) => {
    setFilterData({
      ...filterData,
      grade: '',
      subject: '',
      board: '',
      module: '',
      chapter: '',
    });
    if (value) {
      setLoading(true);
      setFilterData({ ...filterData, grade: value });
      axiosInstance
        .get(`${endpoints.masterManagement.gradeSubjectMappingList}?grade=${value.id}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setSubjectDropdown(result.data.result.results);
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
            setSubjectDropdown([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
          setSubjectDropdown([]);
        });
    } else {
      setLoading(false);
      setSubjectDropdown([]);
    }
  };
  const handleSubject = (event, value) => {
    setFilterData({ ...filterData, subject: '', board: '', module: '', chapter: '' });
    if (value) {
      setLoading(true);
      setFilterData({ ...filterData, subject: value });
      axiosInstance
        .get(`${endpoints.masterManagement.boardMappingList}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setBoardDropdown(result.data.result);
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
            setBoardDropdown([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
          setBoardDropdown([]);
        });
    } else {
      setBoardDropdown([]);
    }
  };

  // const handleSubject = (event, value) => {
  //   setFilterData({ ...filterData, subject: '' });
  //   if (value) {
  //     setFilterData({ ...filterData, subject: value });
  //     //setFilterData({ ...filterData, chapter: ''});
  //   }
  // };

  const handleBoard = (event = {}, values = []) => {
    setFilterData({ ...filterData, board: '', module: '', chapter: '' });
    if (values?.length > 0) {
      setLoading(true);
      const ids = values.map((el) => el);
      const selectedId = values.map((el) => el?.id);
      setSelectedBoardId(selectedId);
      setFilterData({ ...filterData, board: ids });

      axiosInstance
        .get(
          `${endpoints.masterManagement.module}?academic_year=${filterData?.year?.id}&volume=${filterData?.volume?.id}&grade_subject=${filterData?.subject?.id}&board=${selectedId}&page_size=100`
        )
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setModuleDropdown(result.data.result);
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
            setModuleDropdown([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
          setModuleDropdown([]);
        });
    }
    if (values?.length == 0) {
      setLoading(false);
      setModuleDropdown([]);
      setChapterDropdown([]);
      setFilterData({ ...filterData, module: '', chapter: '', board: '' });
    }
    if (values?.length == 0) {
      setLoading(false);
      setModuleDropdown([]);
      console.log('hit');
      setFilterData({ ...filterData, module: '', chapter: '', board: '' });
    }
  };

  const handleModule = (event = {}, values = []) => {
    setLoading(true);
    setFilterData({ ...filterData, module: '', chapter: '' });
    if (values) {
      setLoading(false);
      // const ids = values.map((el) => el);
      // const selectedId = values.map((el) => el?.id)
      // setSelectedModuleId(selectedId)
      setFilterData({ ...filterData, module: values });
    } else {
      setLoading(false);
      setFilterData({ ...filterData, module: '', chapter: '' });
    }
  };

  const handleChapter = (event, value) => {
    setFilterData({ ...filterData, chapter: '' });
    if (value) {
      setFilterData({ ...filterData, chapter: value });
    }
  };

  const handleClearFilter = () => {
    setFilterData({
      year: '',
      grade: '',
      subject: '',
      chapter: '',
      board: '',
      module: '',
      volume: '',
    });
    setTopicList([]);
    setTotalCount(0);
  };

  const handleAddSubject = () => {
    setTableFlag(false);
    setAddFlag(true);
    setEditFlag(false);
  };

  const handleEditSubject = (subject) => {
    setTableFlag(false);
    setAddFlag(false);
    setEditFlag(true);
    setChapterEditDetails(subject);
  };
  const handleFilter = () => {
    // setLoading(true);
    if (!filterData?.grade) {
      setAlert('error', 'Select Grade!');
      return;
    }
    if (!filterData?.subject) {
      setAlert('error', 'Select Subject!');
      return;
    }
    if (!filterData?.board) {
      setAlert('error', 'Select Board !');
      return;
    }
    if (!filterData?.module) {
      setAlert('error', 'Select Module !');
      return;
    }
    if (!filterData?.chapter) {
      setAlert('error', 'Select Chapter!');
      return;
    } else {
      setLoading(true);
      axiosInstance
        .get(
          //topic_name chapter
          `${endpoints.masterManagement.createTopic}?chapter=${filterData.chapter.id}&page_size=${limit}`
        )
        .then((res) => {
          if (res.data.status_code === 200) {
            setLoading(false);
            setTotalCount(res.data.count);
            setTopicList(res.data.result);
            setAlert('success', res.data.message);
          } else {
            setLoading(false);
            setAlert('error', res.data.message);
          }
        })
        .catch((error) => {
          //console.log(error);
          setLoading(false);
          setAlert('error', error.message);
        });
    }
  };
  const handleGoBack = () => {
    setPage(1);
    setTableFlag(true);
    setAddFlag(false);
    setEditFlag(false);
    setSearchGrade('');
    setSearchSection('');
    setGoBackFlag(!goBackFlag);
  };

  const handleAcademicYear = (event, value) => {
    setFilterData({
      ...filterData,
      year: '',
      volume: '',
      grade: '',
      subject: '',
      board: '',
      module: '',
      chapter: '',
    });
    if (value) {
      setFilterData({ ...filterData, year: value });
    }
  };

  const handleDeleteTopic = (e) => {
    e.preventDefault();
    setLoading(true);

    axiosInstance
      .delete(`${endpoints.masterManagement.updateTopic}${chapterId}${topicUpdateApi}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setDelFlag(!delFlag);
          setLoading(false);
          setAlert('success', result.data.message);
        } else {
          setLoading(false);
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.message);
      });
    setOpenDeleteModal(false);
  };

  const handleOpenDeleteModal = (id) => {
    // setSubjectId(id)
    setChapterId(id);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 450);
  }, [goBackFlag, page, delFlag, searchGrade, searchSection]);

  useEffect(() => {
    if (filterData?.chapter == '') {
      axiosInstance
        .get(`${endpoints.masterManagement.createTopic}?page=${page}&page_size=10`)
        .then((res) => {
          console.log(res.data, 'topic');
          if (res.data.status_code === 200) {
            setLoading(false);
            let count = res.data.result ? res.data.result.length : 0;
            setTotalCount(res.data.count ? res.data.count : count);
            setTopicList(res.data.result);
            setAlert('success', res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }, [page, goBackFlag, delFlag]);

  useEffect(() => {
    // setLoading(false);
    if (filterData.chapter.id) {
      axiosInstance
        .get(
          `${endpoints.masterManagement.createTopic}?chapter=${filterData.chapter.id}&page=${page}&page_size=10`
        )
        .then((res) => {
          console.log(res.data, 'topic');
          if (res.data.status_code === 200) {
            setLoading(false);
            let count = res.data.result ? res.data.result.length : 0;
            setTotalCount(res.data.count ? res.data.count : count);
            setTopicList(res.data.result);
            setAlert('success', res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
    // else {
    //   axiosInstance
    //     .get(
    //       `${endpoints.masterManagement.createTopic}?all=1&page_size=${limit}&page=${page}`
    //     )
    //     .then((res) => {
    //       //console.log(res.data,'topic');
    //       if (res.data.status_code === 200) {
    //         setLoading(false);
    //         setTotalCount(res.data.count);
    //         setTopicList(res.data.result);
    //       } else {
    //         setLoading(false);
    //         setAlert('error', res.data.message);
    //       }
    //     })
    //     .catch((error) => {
    //       //console.log(error);
    //       setLoading(false);
    //       setAlert('error', error.message);
    //     });
    // }
  }, [page, delFlag, goBackFlag, filterData]);

  const handleDelete = (topic) => {
    setSubjectName(topic.topic_name);
    handleOpenDeleteModal(topic.id);
  };

  const handleVolume = (event, value) => {
    setFilterData({
      ...filterData,
      volume: '',
      grade: '',
      subject: '',
      board: '',
      module: '',
      chapter: '',
    });
    if (value) {
      setFilterData({ ...filterData, volume: value });
    }
  };

  return (
    <>
      {loading ? <Loading message='Loading...' /> : null}
      <Layout>
        <div style={{ width: '95%', margin: '20px auto' }}>
          <CommonBreadcrumbs
            componentName='Master Management'
            childComponentName='Key Concept List'
            childComponentNameNext={
              addFlag && !tableFlag
                ? 'Add Key Concept'
                : editFlag && !tableFlag
                ? 'Edit Key Concept'
                : null
            }
          />
        </div>

        {!tableFlag && addFlag && !editFlag && (
          <CreateTopic
            topicData={filterData}
            setLoading={setLoading}
            handleGoBack={handleGoBack}
          />
        )}
        {!tableFlag && !addFlag && editFlag && (
          <EditTopic
            topicData={chapterEditDetails}
            setLoading={setLoading}
            handleGoBack={handleGoBack}
          />
        )}

        {tableFlag && !addFlag && !editFlag && (
          <>
            <Grid
              container
              spacing={isMobile ? 3 : 5}
              style={{ width: widerWidth, margin: wider }}
            >
              <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  size='small'
                  onChange={handleAcademicYear}
                  style={{ width: '100%' }}
                  id='year'
                  options={academicYearDropdown}
                  value={filterData.year}
                  getOptionLabel={(option) => option?.session_year}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Academic Year'
                      placeholder='Academic Year'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  size='small'
                  onChange={handleVolume}
                  style={{ width: '100%' }}
                  id='grade'
                  options={volumeDropdown}
                  value={filterData.volume}
                  getOptionLabel={(option) => option?.volume_name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Volume'
                      placeholder='Volume'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  size='small'
                  onChange={handleGrade}
                  style={{ width: '100%' }}
                  id='grade'
                  options={gradeDropdown}
                  value={filterData.grade}
                  getOptionLabel={(option) => option?.grade_name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Grades'
                      placeholder='Grades'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  size='small'
                  onChange={handleSubject}
                  style={{ width: '100%' }}
                  id='grade'
                  options={subjectDropdown}
                  value={filterData.subject}
                  getOptionLabel={(option) => option?.subject?.subject_name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Subject'
                      placeholder='Subject'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  multiple
                  size='small'
                  onChange={handleBoard}
                  style={{ width: '100%' }}
                  id='board'
                  options={boardDropdown || []}
                  value={filterData.board || []}
                  getOptionLabel={(option) => option?.board_name || ''}
                  // filterSelectedOptions
                  getOptionSelected={(option, value) => option?.id == value?.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Board'
                      placeholder='Board'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  // multiple
                  size='small'
                  onChange={handleModule}
                  style={{ width: '100%' }}
                  id='module'
                  options={moduleDropdown || []}
                  value={filterData.module || []}
                  getOptionLabel={(option) => option?.lt_module_name || ''}
                  filterSelectedOptions
                  // getOptionSelected={(option, value) =>
                  //   option?.id == value?.id
                  // }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Module'
                      placeholder='Module'
                      // required
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  size='small'
                  onChange={handleChapter}
                  style={{ width: '100%' }}
                  id='grade'
                  options={chapterDropdown}
                  value={filterData.chapter}
                  getOptionLabel={(option) => option?.chapter_name}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Chapter'
                      placeholder='Chapter'
                      required
                    />
                  )}
                />
              </Grid>
              <Grid item sm={12} spacing={1} className={isMobile ? 'hideGridItem' : ''} />
              <Grid
                item
                xs={6}
                sm={3}
                md={2}
                className={isMobile ? '' : 'addButtonPadding'}
              >
                <Button
                  //   startIcon={<ClearAllIcon style={{ fontSize: '30px' }} />}
                  variant='contained'
                  color='default'
                  size='small'
                  style={{ color: '#fff' }}
                  title='Clear All'
                  onClick={handleClearFilter}
                  className={classes.clrBtn}
                >
                  Clear All
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                md={2}
                className={isMobile ? '' : 'addButtonPadding'}
              >
                <Button
                  // startIcon={<ClearAllIcon style={{ fontSize: '30px' }} />}
                  variant='contained'
                  color='primary'
                  size='medium'
                  style={{ color: 'white' }}
                  title='Filter'
                  onClick={handleFilter}
                >
                  Filter
                </Button>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                className={isMobile ? '' : 'addButtonPadding'}
              >
                <Button
                  startIcon={<AddOutlinedIcon style={{ fontSize: '30px' }} />}
                  variant='contained'
                  color='primary'
                  size='small'
                  style={{ color: 'white' }}
                  title='Add Key Concept'
                  onClick={handleAddSubject}
                >
                  Add Key Concept
                </Button>
              </Grid>
            </Grid>
          </>
        )}

        <>
          {!isMobile ? (
            <>
              {tableFlag && !addFlag && !editFlag && (
                <Paper className={`${classes.root} common-table`}>
                  <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label='sticky table'>
                      <TableHead className='table-header-row'>
                        <TableRow>
                          {columns.map((column) => (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              style={{ minWidth: column.minWidth }}
                              className={classes.columnHeader}
                            >
                              {column.label}
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {topicList.map((topic, index) => {
                          return (
                            <TableRow hover subject='checkbox' tabIndex={-1} key={index}>
                              <TableCell className={classes.tableCell}>
                                {topic.sequence}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {topic.topic_name}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {topic.chapter?.sequence}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {topic.chapter?.chapter_name}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                <IconButton
                                  onClick={(e) => {
                                    handleDelete(topic);
                                  }}
                                  title='Delete Key Concept'
                                >
                                  <DeleteOutlinedIcon style={{ color: '#fe6b6b' }} />
                                </IconButton>
                                <IconButton
                                  onClick={(e) => handleEditSubject(topic)}
                                  title='Edit Key Concept'
                                >
                                  <EditOutlinedIcon style={{ color: '#fe6b6b' }} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className='paginateData'>
                    <TablePagination
                      component='div'
                      count={totalCount}
                      className='customPagination'
                      rowsPerPage={limit}
                      page={page - 1}
                      onChangePage={handleChangePage}
                      rowsPerPageOptions={false}
                    />
                  </div>
                </Paper>
              )}
            </>
          ) : (
            <>
              <>
                {tableFlag && !addFlag && !editFlag && (
                  <>
                    {topicList.map((topic) => (
                      <SubjectCard
                        data={topic}
                        handleDelete={handleDelete}
                        handleEditSubject={handleEditSubject}
                      />
                    ))}
                    <div className='paginateData paginateMobileMargin'>
                      <TablePagination
                        component='div'
                        count={totalCount}
                        rowsPerPage={limit}
                        page={page - 1}
                        onChangePage={handleChangePage}
                        rowsPerPageOptions={false}
                        className='table-pagination'
                      />
                    </div>
                  </>
                )}
              </>
            </>
          )}
        </>
        <Dialog
          open={openDeleteModal}
          onClose={handleCloseDeleteModal}
          aria-labelledby='draggable-dialog-title'
        >
          <DialogTitle
            style={{ cursor: 'move', color: '#014b7e' }}
            id='draggable-dialog-title'
          >
            Delete Key Concept
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`Confirm Delete Key Concept : ${subjectName}`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteModal} className='labelColor cancelButton'>
              Cancel
            </Button>
            <Button
              color='primary'
              variant='contained'
              style={{ color: 'white' }}
              onClick={handleDeleteTopic}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Layout>
    </>
  );
};
export default TopicTable;
