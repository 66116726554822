const constantValue = {
  academic_year: [
    {
      id: 1,
      year_name: '2021-2022',
    },
    {
      id: 2,
      year_name: '2022-2023',
    },
    {
      id: 3,
      year_name: '2023-2024',
    },
  ],
  grades: [
    {
      id: 1,
      grade_name: 'Grade 1',
    },
    {
      id: 2,
      grade_name: 'Grade 2',
    },
    {
      id: 3,
      grade_name: 'Grade 3',
    },
    {
      id: 4,
      grade_name: 'Grade 4',
    },
  ],
  subjects: [
    {
      id: 1,
      subject_name: 'Subject 1',
    },
    {
      id: 2,
      subject_name: 'Subject 2',
    },
    {
      id: 3,
      subject_name: 'Subject 3',
    },
    {
      id: 4,
      subject_name: 'Subject 4',
    },
  ],
  volumes: [
    {
      id: 1,
      volume_name: 'Volume 1',
    },
    {
      id: 2,
      volume_name: 'Volume 2',
    },
    {
      id: 3,
      volume_name: 'Volume 3',
    },
    {
      id: 4,
      volume_name: 'Volume 4',
    },
  ],
  chapters: [
    {
      id: 1,
      chapter_name: 'Chapter 1',
    },
    {
      id: 2,
      chapter_name: 'Chapter 2',
    },
    {
      id: 3,
      chapter_name: 'Chapter 3',
    },
    {
      id: 4,
      chapter_name: 'Chapter 4',
    },
  ],
  bloomsLevel: [
    {
      id: 1,
      level: 'Creating',
    },
    {
      id: 2,
      level: 'Evaluating',
    },
    {
      id: 3,
      level: 'Analyzing',
    },
    {
      id: 4,
      level: 'Applying',
    },
    {
      id: 5,
      level: 'Understanding',
    },
    {
      id: 6,
      level: 'Remembering',
    },
  ],
  difficulty: [
    {
      id: 1,
      mode: 'Easy',
    },
    {
      id: 2,
      mode: 'Average',
    },
    {
      id: 3,
      mode: 'Hard',
    },
  ],
  types: [
    {
      id: 1,
      type: 'MCQ',
      color: 'rgb(54, 162, 235)',
      subTypes: [
        {
          id: 1,
          subType: 'Multiple single Choice questions',
          type: 1,
        },
      ],
    },
    {
      id: 2,
      type: 'VSAQ',
      color: '#32CD32',
      subTypes: [
        {
          id: 1,
          subType: 'Fill in the Blanks',
          type: 2,
        },
        {
          id: 2,
          subType: 'True or False',
          type: 2,
        },
        {
          id: 3,
          subType: 'Complete the Sentences',
          type: 2,
        },
        {
          id: 4,
          subType: 'Rewrite the sentences',
          type: 2,
        },
        {
          id: 5,
          subType: 'One word answers',
          type: 2,
        },
        {
          id: 6,
          subType: 'Name the following',
          type: 2,
        },
        {
          id: 7,
          subType: 'Match the Following',
          type: 2,
        },
        {
          id: 8,
          subType: 'Define the following',
          type: 2,
        },
        {
          id: 9,
          subType: 'Complete the analogy',
          type: 2,
        },
        {
          id: 10,
          subType: 'Answer the following',
          type: 2,
        },
      ],
    },
    {
      id: 3,
      type: 'SAQ',
      color: '#FF6347',
      subTypes: [
        {
          id: 1,
          subType: 'Give Reasons / True or false with reason or correct answer',
          type: 3,
        },
        {
          id: 2,
          subType: 'Extract based questions',
          type: 3,
        },
        {
          id: 3,
          subType: 'Differentiate the following',
          type: 3,
        },
        {
          id: 4,
          subType: 'Put the dialogue in the right order',
          type: 3,
        },
        {
          id: 5,
          subType: 'Complete the sequence/table',
          type: 3,
        },
        {
          id: 6,
          subType: 'Short answer around 25 words',
          type: 3,
        },
        {
          id: 7,
          subType: 'Diagram based questions (Draw or Label)',
          type: 3,
        },
        {
          id: 8,
          subType: 'Solve the following (Math)',
          type: 3,
        },
      ],
    },
    {
      id: 4,
      type: 'LAQ',
      color: '#FFA500',
      subTypes: [
        {
          id: 1,
          subType: 'Answer from around 80 - 150 words as per the need of the subject.',
          type: 4,
        },
        {
          id: 2,
          subType: 'Diagram based questions (Draw or Label)',
          type: 4,
        },
        {
          id: 3,
          subType: 'Experiment based question (Science)',
          type: 4,
        },
        {
          id: 4,
          subType: 'Case-based Study',
          type: 4,
        },
      ],
    },
    {
      id: 5,
      type: 'VLAQ',
      color: '#DAA520',
      subTypes: [
        {
          id: 1,
          subType: 'Answer from around 120 - 150 words as per the need of the subject',
          type: 5,
        },
        {
          id: 2,
          subType: 'Diagram based questions (Draw or Label)',
          type: 5,
        },
        {
          id: 3,
          subType: 'Experiment based question (Science)',
          type: 5,
        },
        {
          id: 4,
          subType: 'Case-based Study',
          type: 5,
        },
      ],
    },
  ],
  subTypes: [
    {
      id: 1,
      subType: 'Multiple single Choice questions',
      type: 1,
    },
    {
      id: 2,
      subType: 'Fill in the Blanks',
      type: 2,
    },
    {
      id: 3,
      subType: 'True or False',
      type: 2,
    },
    {
      id: 4,
      subType: 'Complete the Sentences',
      type: 2,
    },
    {
      id: 5,
      subType: 'Rewrite the sentences',
      type: 2,
    },
    {
      id: 6,
      subType: 'One word answers',
      type: 2,
    },
    {
      id: 7,
      subType: 'Name the following',
      type: 2,
    },
    {
      id: 8,
      subType: 'Match the Following',
      type: 2,
    },
    {
      id: 9,
      subType: 'Define the following',
      type: 2,
    },
    {
      id: 10,
      subType: 'Complete the analogy',
      type: 2,
    },
    {
      id: 11,
      subType: 'Answer the following',
      type: 2,
    },
    {
      id: 12,
      subType: 'Give Reasons / True or false with reason or correct answer',
      type: 3,
    },
    {
      id: 13,
      subType: 'Extract based questions',
      type: 3,
    },
    {
      id: 14,
      subType: 'Differentiate the following',
      type: 3,
    },
    {
      id: 15,
      subType: 'Put the dialogue in the right order',
      type: 3,
    },
    {
      id: 16,
      subType: 'Complete the sequence/table',
      type: 3,
    },
    {
      id: 17,
      subType: 'Short answer around 25 words',
      type: 3,
    },
    {
      id: 18,
      subType: 'Diagram based questions (Draw or Label)',
      type: 3,
    },
    {
      id: 19,
      subType: 'Solve the following (Math)',
      type: 3,
    },
    {
      id: 20,
      subType: 'Answer from around 80 - 150 words as per the need of the subject.',
      type: 4,
    },
    {
      id: 21,
      subType: 'Diagram based questions (Draw or Label)',
      type: 4,
    },
    {
      id: 22,
      subType: 'Experiment based question (Science)',
      type: 4,
    },
    {
      id: 23,
      subType: 'Case-based Study',
      type: 4,
    },
    {
      id: 24,
      subType: 'Answer from around 120 - 150 words as per the need of the subject',
      type: 5,
    },
    {
      id: 25,
      subType: 'Diagram based questions (Draw or Label)',
      type: 5,
    },
    {
      id: 26,
      subType: 'Experiment based question (Science)',
      type: 5,
    },
    {
      id: 27,
      subType: 'Case-based Study',
      type: 5,
    },
  ],
  alltemplates: [
    {
      title: 'test template1',
      academic_year: {
        id: 3,
        year_name: '2023-2024',
      },
      grade: {
        id: 3,
        grade_name: 'Grade 3',
      },
      subject: {
        id: 1,
        subject_name: 'Subject 1',
      },
      volume: [
        {
          id: 4,
          volume_name: 'Volume 4',
        },
      ],
      chapter: [
        {
          id: 3,
          chapter_name: 'Chapter 3',
        },
      ],
      total_marks: '20',
      duration: '49',
      instruction: '1. test intruction',
      questionGenerate: false,
      currentPaper: 1,
      exportType: 'Save Template',
      section: [
        {
          header: 'Reading Comprehention',
          description: 'any description',
          totalQuestion: '2',
          question: [
            {
              bloom: {
                id: 2,
                level: 'Evaluating',
              },
              difficult: {
                id: 1,
                mode: 'Easy',
              },
              type: {
                id: 3,
                type: 'SAQ',
                color: '#FF6347',
                subTypes: [
                  {
                    id: 1,
                    subType: 'Give Reasons / True or false with reason or correct answer',
                    type: 3,
                  },
                  {
                    id: 2,
                    subType: 'Extract based questions',
                    type: 3,
                  },
                  {
                    id: 3,
                    subType: 'Differentiate the following',
                    type: 3,
                  },
                  {
                    id: 4,
                    subType: 'Put the dialogue in the right order',
                    type: 3,
                  },
                  {
                    id: 5,
                    subType: 'Complete the sequence/table',
                    type: 3,
                  },
                  {
                    id: 6,
                    subType: 'Short answer around 25 words',
                    type: 3,
                  },
                  {
                    id: 7,
                    subType: 'Diagram based questions (Draw or Label)',
                    type: 3,
                  },
                  {
                    id: 8,
                    subType: 'Solve the following (Math)',
                    type: 3,
                  },
                ],
              },
              subType: {
                id: 12,
                subType: 'Give Reasons / True or false with reason or correct answer',
                type: 3,
              },
              marks: '4',
              question: null,
            },
            {
              bloom: {
                id: 1,
                level: 'Creating',
              },
              difficult: {
                id: 3,
                mode: 'Hard',
              },
              type: {
                id: 5,
                type: 'VLAQ',
                color: '#DAA520',
                subTypes: [
                  {
                    id: 1,
                    subType:
                      'Answer from around 120 - 150 words as per the need of the subject',
                    type: 5,
                  },
                  {
                    id: 2,
                    subType: 'Diagram based questions (Draw or Label)',
                    type: 5,
                  },
                  {
                    id: 3,
                    subType: 'Experiment based question (Science)',
                    type: 5,
                  },
                  {
                    id: 4,
                    subType: 'Case-based Study',
                    type: 5,
                  },
                ],
              },
              subType: {
                id: 24,
                subType:
                  'Answer from around 120 - 150 words as per the need of the subject',
                type: 5,
              },
              marks: '6',
              question: null,
            },
          ],
        },
        {
          header: 'Writing Skills',
          description: 'any description 2',
          totalQuestion: '2',
          question: [
            {
              bloom: {
                id: 6,
                level: 'Remembering',
              },
              difficult: {
                id: 2,
                mode: 'Average',
              },
              type: {
                id: 4,
                type: 'LAQ',
                color: '#FFA500',
                subTypes: [
                  {
                    id: 1,
                    subType:
                      'Answer from around 80 - 150 words as per the need of the subject.',
                    type: 4,
                  },
                  {
                    id: 2,
                    subType: 'Diagram based questions (Draw or Label)',
                    type: 4,
                  },
                  {
                    id: 3,
                    subType: 'Experiment based question (Science)',
                    type: 4,
                  },
                  {
                    id: 4,
                    subType: 'Case-based Study',
                    type: 4,
                  },
                ],
              },
              subType: {
                id: 20,
                subType:
                  'Answer from around 80 - 150 words as per the need of the subject.',
                type: 4,
              },
              marks: '5',
              question: null,
            },
            {
              bloom: {
                id: 3,
                level: 'Analyzing',
              },
              difficult: {
                id: 2,
                mode: 'Average',
              },
              type: {
                id: 2,
                type: 'VSAQ',
                color: '#32CD32',
                subTypes: [
                  {
                    id: 1,
                    subType: 'Fill in the Blanks',
                    type: 2,
                  },
                  {
                    id: 2,
                    subType: 'True or False',
                    type: 2,
                  },
                  {
                    id: 3,
                    subType: 'Complete the Sentences',
                    type: 2,
                  },
                  {
                    id: 4,
                    subType: 'Rewrite the sentences',
                    type: 2,
                  },
                  {
                    id: 5,
                    subType: 'One word answers',
                    type: 2,
                  },
                  {
                    id: 6,
                    subType: 'Name the following',
                    type: 2,
                  },
                  {
                    id: 7,
                    subType: 'Match the Following',
                    type: 2,
                  },
                  {
                    id: 8,
                    subType: 'Define the following',
                    type: 2,
                  },
                  {
                    id: 9,
                    subType: 'Complete the analogy',
                    type: 2,
                  },
                  {
                    id: 10,
                    subType: 'Answer the following',
                    type: 2,
                  },
                ],
              },
              subType: {
                id: 8,
                subType: 'Match the Following',
                type: 2,
              },
              marks: '5',
              question: null,
            },
          ],
        },
        {
          header: 'Writing Skills',
          description: 'any description 2',
          totalQuestion: '2',
          question: [
            {
              bloom: {
                id: 6,
                level: 'Remembering',
              },
              difficult: {
                id: 2,
                mode: 'Average',
              },
              type: {
                id: 4,
                type: 'LAQ',
                color: '#FFA500',
                subTypes: [
                  {
                    id: 1,
                    subType:
                      'Answer from around 80 - 150 words as per the need of the subject.',
                    type: 4,
                  },
                  {
                    id: 2,
                    subType: 'Diagram based questions (Draw or Label)',
                    type: 4,
                  },
                  {
                    id: 3,
                    subType: 'Experiment based question (Science)',
                    type: 4,
                  },
                  {
                    id: 4,
                    subType: 'Case-based Study',
                    type: 4,
                  },
                ],
              },
              subType: {
                id: 20,
                subType:
                  'Answer from around 80 - 150 words as per the need of the subject.',
                type: 4,
              },
              marks: '5',
              question: null,
            },
            {
              bloom: {
                id: 3,
                level: 'Analyzing',
              },
              difficult: {
                id: 2,
                mode: 'Average',
              },
              type: {
                id: 2,
                type: 'VSAQ',
                color: '#32CD32',
                subTypes: [
                  {
                    id: 1,
                    subType: 'Fill in the Blanks',
                    type: 2,
                  },
                  {
                    id: 2,
                    subType: 'True or False',
                    type: 2,
                  },
                  {
                    id: 3,
                    subType: 'Complete the Sentences',
                    type: 2,
                  },
                  {
                    id: 4,
                    subType: 'Rewrite the sentences',
                    type: 2,
                  },
                  {
                    id: 5,
                    subType: 'One word answers',
                    type: 2,
                  },
                  {
                    id: 6,
                    subType: 'Name the following',
                    type: 2,
                  },
                  {
                    id: 7,
                    subType: 'Match the Following',
                    type: 2,
                  },
                  {
                    id: 8,
                    subType: 'Define the following',
                    type: 2,
                  },
                  {
                    id: 9,
                    subType: 'Complete the analogy',
                    type: 2,
                  },
                  {
                    id: 10,
                    subType: 'Answer the following',
                    type: 2,
                  },
                ],
              },
              subType: {
                id: 8,
                subType: 'Match the Following',
                type: 2,
              },
              marks: '5',
              question: null,
            },
          ],
        },
        {
          header: 'Writing Skills',
          description: 'any description 2',
          totalQuestion: '2',
          question: [
            {
              bloom: {
                id: 6,
                level: 'Remembering',
              },
              difficult: {
                id: 2,
                mode: 'Average',
              },
              type: {
                id: 4,
                type: 'LAQ',
                color: '#FFA500',
                subTypes: [
                  {
                    id: 1,
                    subType:
                      'Answer from around 80 - 150 words as per the need of the subject.',
                    type: 4,
                  },
                  {
                    id: 2,
                    subType: 'Diagram based questions (Draw or Label)',
                    type: 4,
                  },
                  {
                    id: 3,
                    subType: 'Experiment based question (Science)',
                    type: 4,
                  },
                  {
                    id: 4,
                    subType: 'Case-based Study',
                    type: 4,
                  },
                ],
              },
              subType: {
                id: 20,
                subType:
                  'Answer from around 80 - 150 words as per the need of the subject.',
                type: 4,
              },
              marks: '5',
              question: null,
            },
            {
              bloom: {
                id: 3,
                level: 'Analyzing',
              },
              difficult: {
                id: 2,
                mode: 'Average',
              },
              type: {
                id: 2,
                type: 'VSAQ',
                color: '#32CD32',
                subTypes: [
                  {
                    id: 1,
                    subType: 'Fill in the Blanks',
                    type: 2,
                  },
                  {
                    id: 2,
                    subType: 'True or False',
                    type: 2,
                  },
                  {
                    id: 3,
                    subType: 'Complete the Sentences',
                    type: 2,
                  },
                  {
                    id: 4,
                    subType: 'Rewrite the sentences',
                    type: 2,
                  },
                  {
                    id: 5,
                    subType: 'One word answers',
                    type: 2,
                  },
                  {
                    id: 6,
                    subType: 'Name the following',
                    type: 2,
                  },
                  {
                    id: 7,
                    subType: 'Match the Following',
                    type: 2,
                  },
                  {
                    id: 8,
                    subType: 'Define the following',
                    type: 2,
                  },
                  {
                    id: 9,
                    subType: 'Complete the analogy',
                    type: 2,
                  },
                  {
                    id: 10,
                    subType: 'Answer the following',
                    type: 2,
                  },
                ],
              },
              subType: {
                id: 8,
                subType: 'Match the Following',
                type: 2,
              },
              marks: '5',
              question: null,
            },
          ],
        },
        {
          header: 'Writing Skills',
          description: 'any description 2',
          totalQuestion: '2',
          question: [
            {
              bloom: {
                id: 6,
                level: 'Remembering',
              },
              difficult: {
                id: 2,
                mode: 'Average',
              },
              type: {
                id: 4,
                type: 'LAQ',
                color: '#FFA500',
                subTypes: [
                  {
                    id: 1,
                    subType:
                      'Answer from around 80 - 150 words as per the need of the subject.',
                    type: 4,
                  },
                  {
                    id: 2,
                    subType: 'Diagram based questions (Draw or Label)',
                    type: 4,
                  },
                  {
                    id: 3,
                    subType: 'Experiment based question (Science)',
                    type: 4,
                  },
                  {
                    id: 4,
                    subType: 'Case-based Study',
                    type: 4,
                  },
                ],
              },
              subType: {
                id: 20,
                subType:
                  'Answer from around 80 - 150 words as per the need of the subject.',
                type: 4,
              },
              marks: '5',
              question: null,
            },
            {
              bloom: {
                id: 3,
                level: 'Analyzing',
              },
              difficult: {
                id: 2,
                mode: 'Average',
              },
              type: {
                id: 2,
                type: 'VSAQ',
                color: '#32CD32',
                subTypes: [
                  {
                    id: 1,
                    subType: 'Fill in the Blanks',
                    type: 2,
                  },
                  {
                    id: 2,
                    subType: 'True or False',
                    type: 2,
                  },
                  {
                    id: 3,
                    subType: 'Complete the Sentences',
                    type: 2,
                  },
                  {
                    id: 4,
                    subType: 'Rewrite the sentences',
                    type: 2,
                  },
                  {
                    id: 5,
                    subType: 'One word answers',
                    type: 2,
                  },
                  {
                    id: 6,
                    subType: 'Name the following',
                    type: 2,
                  },
                  {
                    id: 7,
                    subType: 'Match the Following',
                    type: 2,
                  },
                  {
                    id: 8,
                    subType: 'Define the following',
                    type: 2,
                  },
                  {
                    id: 9,
                    subType: 'Complete the analogy',
                    type: 2,
                  },
                  {
                    id: 10,
                    subType: 'Answer the following',
                    type: 2,
                  },
                ],
              },
              subType: {
                id: 8,
                subType: 'Match the Following',
                type: 2,
              },
              marks: '5',
              question: null,
            },
          ],
        },
      ],
      id: 1,
    },
    {
      title: 'test template2',
      academic_year: {
        id: 3,
        year_name: '2023-2024',
      },
      grade: {
        id: 2,
        grade_name: 'Grade 2',
      },
      subject: {
        id: 2,
        subject_name: 'Subject 2',
      },
      volume: [
        {
          id: 3,
          volume_name: 'Volume 3',
        },
      ],
      chapter: [
        {
          id: 3,
          chapter_name: 'Chapter 3',
        },
      ],
      total_marks: '20',
      duration: '49',
      instruction: '1. test intruction',
      questionGenerate: false,
      currentPaper: 1,
      exportType: 'Save Template',
      section: [
        {
          header: 'Reading Comprehention',
          description: 'any description',
          totalQuestion: '2',
          question: [
            {
              bloom: {
                id: 2,
                level: 'Evaluating',
              },
              difficult: {
                id: 1,
                mode: 'Easy',
              },
              type: {
                id: 3,
                type: 'SAQ',
                color: '#FF6347',
                subTypes: [
                  {
                    id: 1,
                    subType: 'Give Reasons / True or false with reason or correct answer',
                    type: 3,
                  },
                  {
                    id: 2,
                    subType: 'Extract based questions',
                    type: 3,
                  },
                  {
                    id: 3,
                    subType: 'Differentiate the following',
                    type: 3,
                  },
                  {
                    id: 4,
                    subType: 'Put the dialogue in the right order',
                    type: 3,
                  },
                  {
                    id: 5,
                    subType: 'Complete the sequence/table',
                    type: 3,
                  },
                  {
                    id: 6,
                    subType: 'Short answer around 25 words',
                    type: 3,
                  },
                  {
                    id: 7,
                    subType: 'Diagram based questions (Draw or Label)',
                    type: 3,
                  },
                  {
                    id: 8,
                    subType: 'Solve the following (Math)',
                    type: 3,
                  },
                ],
              },
              subType: {
                id: 12,
                subType: 'Give Reasons / True or false with reason or correct answer',
                type: 3,
              },
              marks: '5',
              question: null,
            },
            {
              bloom: {
                id: 1,
                level: 'Creating',
              },
              difficult: {
                id: 3,
                mode: 'Hard',
              },
              type: {
                id: 5,
                type: 'VLAQ',
                color: '#DAA520',
                subTypes: [
                  {
                    id: 1,
                    subType:
                      'Answer from around 120 - 150 words as per the need of the subject',
                    type: 5,
                  },
                  {
                    id: 2,
                    subType: 'Diagram based questions (Draw or Label)',
                    type: 5,
                  },
                  {
                    id: 3,
                    subType: 'Experiment based question (Science)',
                    type: 5,
                  },
                  {
                    id: 4,
                    subType: 'Case-based Study',
                    type: 5,
                  },
                ],
              },
              subType: {
                id: 24,
                subType:
                  'Answer from around 120 - 150 words as per the need of the subject',
                type: 5,
              },
              marks: '5',
              question: null,
            },
          ],
        },
        {
          header: 'Writing Skills',
          description: 'any description 2',
          totalQuestion: '2',
          question: [
            {
              bloom: {
                id: 6,
                level: 'Remembering',
              },
              difficult: {
                id: 2,
                mode: 'Average',
              },
              type: {
                id: 4,
                type: 'LAQ',
                color: '#FFA500',
                subTypes: [
                  {
                    id: 1,
                    subType:
                      'Answer from around 80 - 150 words as per the need of the subject.',
                    type: 4,
                  },
                  {
                    id: 2,
                    subType: 'Diagram based questions (Draw or Label)',
                    type: 4,
                  },
                  {
                    id: 3,
                    subType: 'Experiment based question (Science)',
                    type: 4,
                  },
                  {
                    id: 4,
                    subType: 'Case-based Study',
                    type: 4,
                  },
                ],
              },
              subType: {
                id: 20,
                subType:
                  'Answer from around 80 - 150 words as per the need of the subject.',
                type: 4,
              },
              marks: '5',
              question: null,
            },
            {
              bloom: {
                id: 3,
                level: 'Analyzing',
              },
              difficult: {
                id: 2,
                mode: 'Average',
              },
              type: {
                id: 2,
                type: 'VSAQ',
                color: '#32CD32',
                subTypes: [
                  {
                    id: 1,
                    subType: 'Fill in the Blanks',
                    type: 2,
                  },
                  {
                    id: 2,
                    subType: 'True or False',
                    type: 2,
                  },
                  {
                    id: 3,
                    subType: 'Complete the Sentences',
                    type: 2,
                  },
                  {
                    id: 4,
                    subType: 'Rewrite the sentences',
                    type: 2,
                  },
                  {
                    id: 5,
                    subType: 'One word answers',
                    type: 2,
                  },
                  {
                    id: 6,
                    subType: 'Name the following',
                    type: 2,
                  },
                  {
                    id: 7,
                    subType: 'Match the Following',
                    type: 2,
                  },
                  {
                    id: 8,
                    subType: 'Define the following',
                    type: 2,
                  },
                  {
                    id: 9,
                    subType: 'Complete the analogy',
                    type: 2,
                  },
                  {
                    id: 10,
                    subType: 'Answer the following',
                    type: 2,
                  },
                ],
              },
              subType: {
                id: 8,
                subType: 'Match the Following',
                type: 2,
              },
              marks: '5',
              question: null,
            },
          ],
        },
      ],
      id: 2,
    },
    {
      title: 'test template3',
      academic_year: {
        id: 3,
        year_name: '2023-2024',
      },
      grade: {
        id: 2,
        grade_name: 'Grade 2',
      },
      subject: {
        id: 2,
        subject_name: 'Subject 2',
      },
      volume: [
        {
          id: 3,
          volume_name: 'Volume 3',
        },
      ],
      chapter: [
        {
          id: 3,
          chapter_name: 'Chapter 3',
        },
      ],
      total_marks: '20',
      duration: '49',
      instruction: '1. test intruction',
      questionGenerate: false,
      currentPaper: 1,
      exportType: 'Save Template',
      section: [
        {
          header: 'Reading Comprehention',
          description: 'any description',
          totalQuestion: '2',
          question: [
            {
              bloom: {
                id: 2,
                level: 'Evaluating',
              },
              difficult: {
                id: 1,
                mode: 'Easy',
              },
              type: {
                id: 3,
                type: 'SAQ',
                color: '#FF6347',
                subTypes: [
                  {
                    id: 1,
                    subType: 'Give Reasons / True or false with reason or correct answer',
                    type: 3,
                  },
                  {
                    id: 2,
                    subType: 'Extract based questions',
                    type: 3,
                  },
                  {
                    id: 3,
                    subType: 'Differentiate the following',
                    type: 3,
                  },
                  {
                    id: 4,
                    subType: 'Put the dialogue in the right order',
                    type: 3,
                  },
                  {
                    id: 5,
                    subType: 'Complete the sequence/table',
                    type: 3,
                  },
                  {
                    id: 6,
                    subType: 'Short answer around 25 words',
                    type: 3,
                  },
                  {
                    id: 7,
                    subType: 'Diagram based questions (Draw or Label)',
                    type: 3,
                  },
                  {
                    id: 8,
                    subType: 'Solve the following (Math)',
                    type: 3,
                  },
                ],
              },
              subType: {
                id: 12,
                subType: 'Give Reasons / True or false with reason or correct answer',
                type: 3,
              },
              marks: '5',
              question: null,
            },
            {
              bloom: {
                id: 1,
                level: 'Creating',
              },
              difficult: {
                id: 3,
                mode: 'Hard',
              },
              type: {
                id: 5,
                type: 'VLAQ',
                color: '#DAA520',
                subTypes: [
                  {
                    id: 1,
                    subType:
                      'Answer from around 120 - 150 words as per the need of the subject',
                    type: 5,
                  },
                  {
                    id: 2,
                    subType: 'Diagram based questions (Draw or Label)',
                    type: 5,
                  },
                  {
                    id: 3,
                    subType: 'Experiment based question (Science)',
                    type: 5,
                  },
                  {
                    id: 4,
                    subType: 'Case-based Study',
                    type: 5,
                  },
                ],
              },
              subType: {
                id: 24,
                subType:
                  'Answer from around 120 - 150 words as per the need of the subject',
                type: 5,
              },
              marks: '5',
              question: null,
            },
          ],
        },
        {
          header: 'Writing Skills',
          description: 'any description 2',
          totalQuestion: '2',
          question: [
            {
              bloom: {
                id: 6,
                level: 'Remembering',
              },
              difficult: {
                id: 2,
                mode: 'Average',
              },
              type: {
                id: 4,
                type: 'LAQ',
                color: '#FFA500',
                subTypes: [
                  {
                    id: 1,
                    subType:
                      'Answer from around 80 - 150 words as per the need of the subject.',
                    type: 4,
                  },
                  {
                    id: 2,
                    subType: 'Diagram based questions (Draw or Label)',
                    type: 4,
                  },
                  {
                    id: 3,
                    subType: 'Experiment based question (Science)',
                    type: 4,
                  },
                  {
                    id: 4,
                    subType: 'Case-based Study',
                    type: 4,
                  },
                ],
              },
              subType: {
                id: 20,
                subType:
                  'Answer from around 80 - 150 words as per the need of the subject.',
                type: 4,
              },
              marks: '5',
              question: null,
            },
            {
              bloom: {
                id: 3,
                level: 'Analyzing',
              },
              difficult: {
                id: 2,
                mode: 'Average',
              },
              type: {
                id: 2,
                type: 'VSAQ',
                color: '#32CD32',
                subTypes: [
                  {
                    id: 1,
                    subType: 'Fill in the Blanks',
                    type: 2,
                  },
                  {
                    id: 2,
                    subType: 'True or False',
                    type: 2,
                  },
                  {
                    id: 3,
                    subType: 'Complete the Sentences',
                    type: 2,
                  },
                  {
                    id: 4,
                    subType: 'Rewrite the sentences',
                    type: 2,
                  },
                  {
                    id: 5,
                    subType: 'One word answers',
                    type: 2,
                  },
                  {
                    id: 6,
                    subType: 'Name the following',
                    type: 2,
                  },
                  {
                    id: 7,
                    subType: 'Match the Following',
                    type: 2,
                  },
                  {
                    id: 8,
                    subType: 'Define the following',
                    type: 2,
                  },
                  {
                    id: 9,
                    subType: 'Complete the analogy',
                    type: 2,
                  },
                  {
                    id: 10,
                    subType: 'Answer the following',
                    type: 2,
                  },
                ],
              },
              subType: {
                id: 8,
                subType: 'Match the Following',
                type: 2,
              },
              marks: '5',
              question: null,
            },
          ],
        },
      ],
      id: 3,
    },
    {
      id: 4,
      title: 'test template 4',
      academic_year: {
        id: 38,
        session_year: '2022-23',
        is_delete: false,
        created_by: 11,
        updated_by: null,
        version_name: 'Version 2',
      },
      grade: {
        id: 39,
        grade_name: 'Grade 1',
        is_delete: false,
        created_by: null,
        updated_by: null,
      },
      subject: {
        id: 193,
        grade: {
          id: 39,
          grade_name: 'Grade 1',
        },
        subject: {
          id: 20,
          subject_name: 'Maths',
        },
        is_delete: false,
        created_by: 11,
        updated_by: null,
      },
      volume: [
        {
          id: 38,
          volume_name: 'Volume 1',
        },
      ],
      chapter: [
        {
          id: 923,
          chapter_name: 'Revision',
          volume_id: 38,
        },
      ],
      selectTemplate: 1,
      total_marks: '10',
      duration: '10',
      instruction: 'test instruction',
      questionGenerate: false,
      currentPaper: 1,
      // exportType: 'Publish',
      exportType: 'Draft',
      section: [
        {
          header: 'Section A',
          description: 'Description A',
          totalQuestion: '1',
          question: [
            {
              bloom: {
                id: 2,
                category_name: 'understanding',
              },
              difficult: {
                id: 1,
                level_name: 'easy',
              },
              type: {
                id: 26,
                type_name: 'SAQ',
              },
              subType: {
                id: 17,
                type_name: 'Short answer around 25 words',
                sub_type_id: 26,
              },
              marks: '5',
            },
          ],
        },
        {
          header: 'Section B',
          description: 'Description B',
          totalQuestion: '1',
          question: [
            {
              bloom: {
                id: 4,
                category_name: 'analysis',
              },
              difficult: {
                id: 3,
                level_name: 'difficult',
              },
              type: {
                id: 27,
                type_name: 'LAQ',
              },
              subType: {
                id: 33,
                type_name: 'Comprehension',
                sub_type_id: 27,
              },
              marks: '5',
            },
          ],
        },
      ],
    },
  ],
  allQuestions: [
    {
      id: 271779,
      is_central: true,
      question_status: 3,
      question_answer: [
        {
          answer: ['option1'],
          options: [
            {
              option1: {
                images: [],
                isChecked: true,
                optionValue: 'well',
              },
            },
            {
              option2: {
                images: [],
                isChecked: false,
                optionValue: 'bell',
              },
            },
            {
              option3: {
                images: [],
                isChecked: false,
                optionValue: 'fell',
              },
            },
            {
              option4: {
                images: [],
                isChecked: false,
                optionValue: 'tell',
              },
            },
          ],
          question:
            '<p><span style="font-weight: 400;">Look and choose the correct word.</span></p>\n<p><span style="font-weight: 400;"><img src="https://d2r9gkgplfhsr2.cloudfront.net/prod/question_file/Grade 1/English/Knowledge/MCQ_SINGLE_CHOICE/2023-06-16 04:53:05.237759_14.png" alt="My alt text" width="105" height="100" /></span></p>',
        },
      ],
      question_level: 1,
      question_categories: 1,
      question_type: {
        id: 32,
        type_name: 'Mutiple single choice questions',
        type: {
          id: 24,
          type_name: 'MCQ',
        },
      },
      topic: {
        id: 4987,
        topic_name: 'BLA',
        grade_subject_mapping: {
          id: 33,
          grade: {
            id: 39,
            grade_name: 'Grade 1',
          },
          subject: {
            id: 25,
            subject_name: 'English',
          },
        },
        academic_year: {
          id: 17,
          session_year: '2022-23',
          is_delete: false,
          start_date: null,
          end_date: null,
        },
        volume: {
          id: 20,
          volume_name: 'Volume 1',
          is_delete: false,
        },
      },
      chapter: {
        id: 8247,
        chapter_name: 'BLA',
        module: {
          lt_module_name: 'BLA',
          id: 4856,
        },
      },
      question_mark: 1,
      is_tagged: true,
      sub_questions: [],
      created_at: '2023-06-16T10:23:27.113492+05:30',
      updated_at: '2023-08-22T18:40:52.404907+05:30',
    },
    {
      id: 264219,
      is_central: true,
      question_status: 1,
      question_answer: [
        {
          answer: ['option1'],
          options: [
            {
              option1: {
                title: 'True',
                isChecked: true,
                optionValue: true,
              },
            },
            {
              option2: {
                title: 'False',
                isChecked: false,
                optionValue: false,
              },
            },
          ],
          question: '<p>AS</p>',
        },
      ],
      question_level: 1,
      question_categories: 1,
      question_type: {
        id: 3,
        type_name: 'True or False',
        type: {
          id: 25,
          type_name: 'VSAQ',
        },
      },
      topic: {
        id: 12962,
        topic_name: 'Addition and subtraction together',
        grade_subject_mapping: {
          id: 147,
          grade: {
            id: 39,
            grade_name: 'Grade 1',
          },
          subject: {
            id: 44,
            subject_name: 'Maths',
          },
        },
        academic_year: {
          id: 17,
          session_year: '2022-23',
          is_delete: false,
          start_date: null,
          end_date: null,
        },
        volume: {
          id: 22,
          volume_name: 'Volume 3',
          is_delete: false,
        },
      },
      chapter: {
        id: 12589,
        chapter_name: 'Subtraction up to 999',
        module: {
          lt_module_name: 'Basic Arithmetic Operation',
          id: 7960,
        },
      },
      question_mark: 0,
      is_tagged: false,
      sub_questions: [],
      created_at: '2023-03-16T20:49:01.512742+05:30',
      updated_at: '2023-08-18T05:09:27.588680+05:30',
    },
    {
      id: 7043,
      is_central: true,
      question_status: 3,
      question_answer: [
        {
          answer: '<p>y</p>',
          question: '<p>Cat is is a??? edited to complete the analogy&nbsp;</p>',
        },
      ],
      question_level: 1,
      question_categories: 4,
      question_type: {
        id: 10,
        type_name: 'Complete the analogy',
        type: {
          id: 25,
          type_name: 'VSAQ',
        },
      },
      topic: {
        id: 701,
        topic_name: 'Mul_KC_1',
        grade_subject_mapping: {
          id: 193,
          grade: {
            id: 39,
            grade_name: 'Grade 1',
          },
          subject: {
            id: 20,
            subject_name: 'Maths',
          },
        },
        academic_year: {
          id: 40,
          session_year: '2023-24',
          is_delete: false,
          start_date: null,
          end_date: null,
        },
        volume: {
          id: 38,
          volume_name: 'Volume 1',
          is_delete: false,
        },
      },
      chapter: {
        id: 992,
        chapter_name: 'Mul_Ch_1',
        module: {
          lt_module_name: 'multiplication',
          id: 1221,
        },
      },
      question_mark: 12,
      is_tagged: true,
      sub_questions: [],
      created_at: '2023-03-27T15:08:16.762348+05:30',
      updated_at: '2023-08-17T19:13:03.991330+05:30',
    },
    {
      id: 185,
      is_central: true,
      question_status: 3,
      question_answer: [
        {
          answer: ['option1'],
          options: [
            {
              option1: {
                images: [
                  'dev/question_file/Grade 1/Maths/1/32/2023-08-24 17:10:42.776770_2170482.jpg',
                ],
                isChecked: true,
                optionValue: 'fdg',
              },
            },
            {
              option2: {
                images: [],
                isChecked: false,
                optionValue: 'ftg',
              },
            },
            {
              option3: {
                images: [],
                isChecked: false,
                optionValue: 'der',
              },
            },
          ],
          question: '<p>&nbsp;MCQ single choice</p>',
        },
      ],
      question_level: 1,
      question_categories: 1,
      question_type: {
        id: 32,
        type_name: 'Mutiple single choice questions',
        type: {
          id: 24,
          type_name: 'MCQ',
        },
      },
      topic: {
        id: 787,
        topic_name: 'New Sub_KC',
        grade_subject_mapping: {
          id: 193,
          grade: {
            id: 39,
            grade_name: 'Grade 1',
          },
          subject: {
            id: 20,
            subject_name: 'Maths',
          },
        },
        academic_year: {
          id: 38,
          session_year: '2022-23',
          is_delete: false,
          start_date: null,
          end_date: null,
        },
        volume: {
          id: 38,
          volume_name: 'Volume 1',
          is_delete: false,
        },
      },
      chapter: {
        id: 1037,
        chapter_name: 'New sub_ch',
        module: {
          lt_module_name: 'subtraction',
          id: 1172,
        },
      },
      question_mark: 5,
      is_tagged: true,
      sub_questions: [],
      created_at: '2023-08-16T11:17:17.492621+05:30',
      updated_at: '2023-08-24T17:10:44.669565+05:30',
    },
    {
      id: 244023,
      is_central: true,
      question_status: 3,
      question_answer: [
        {
          answer: ['option3'],
          options: [
            {
              option1: {
                images: [],
                isChecked: false,
                optionValue: 'I',
              },
            },
            {
              option2: {
                images: [],
                isChecked: false,
                optionValue: 'she',
              },
            },
            {
              option3: {
                images: [],
                isChecked: true,
                optionValue: 'you',
              },
            },
            {
              option4: {
                images: [],
                isChecked: false,
                optionValue: 'he',
              },
            },
          ],
          question:
            "<p>We use<strong> '</strong>are'<strong> </strong>with<u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </u>.</p>",
        },
      ],
      question_level: 1,
      question_categories: 5,
      question_type: {
        id: 32,
        type_name: 'Mutiple single choice questions',
        type: {
          id: 24,
          type_name: 'MCQ',
        },
      },
      topic: {
        id: 11716,
        topic_name: 'The Use of Are',
        grade_subject_mapping: {
          id: 33,
          grade: {
            id: 39,
            grade_name: 'Grade 1',
          },
          subject: {
            id: 25,
            subject_name: 'English',
          },
        },
        academic_year: {
          id: 17,
          session_year: '2022-23',
          is_delete: false,
          start_date: null,
          end_date: null,
        },
        volume: {
          id: 22,
          volume_name: 'Volume 3',
          is_delete: false,
        },
      },
      chapter: {
        id: 12059,
        chapter_name: 'The Use of Are',
        module: {
          lt_module_name: 'Parts of Speech',
          id: 7396,
        },
      },
      question_mark: 1,
      is_tagged: true,
      sub_questions: [],
      created_at: '2022-11-16T12:15:01.597244+05:30',
      updated_at: '2023-08-30T17:08:07.573232+05:30',
    },
    {
      id: 242096,
      is_central: true,
      question_status: 3,
      question_answer: [
        {
          answer: ['option4'],
          options: [
            {
              option1: {
                images: [],
                isChecked: false,
                optionValue: 'someone who sails ships',
              },
            },
            {
              option2: {
                images: [],
                isChecked: false,
                optionValue: 'someone who does cleaning jobs in ships',
              },
            },
            {
              option3: {
                images: [],
                isChecked: false,
                optionValue: 'someone who builds ships',
              },
            },
            {
              option4: {
                images: [],
                isChecked: true,
                optionValue: 'someone who attacks and robs ships',
              },
            },
          ],
          question: "<p>What is the meaning of the word 'pirate'?</p>",
        },
      ],
      question_level: 1,
      question_categories: 6,
      question_type: {
        id: 32,
        type_name: 'Mutiple single choice questions',
        type: {
          id: 24,
          type_name: 'MCQ',
        },
      },
      topic: {
        id: 11421,
        topic_name: "Jimmy's Dream",
        grade_subject_mapping: {
          id: 33,
          grade: {
            id: 39,
            grade_name: 'Grade 1',
          },
          subject: {
            id: 25,
            subject_name: 'English',
          },
        },
        academic_year: {
          id: 17,
          session_year: '2022-23',
          is_delete: false,
          start_date: null,
          end_date: null,
        },
        volume: {
          id: 22,
          volume_name: 'Volume 3',
          is_delete: false,
        },
      },
      chapter: {
        id: 11819,
        chapter_name: "Jimmy's Dream",
        module: {
          lt_module_name: 'Reading Comprehension',
          id: 7347,
        },
      },
      question_mark: 1,
      is_tagged: true,
      sub_questions: [],
      created_at: '2022-11-09T11:41:49.995752+05:30',
      updated_at: '2023-08-30T17:05:44.805424+05:30',
    },
    {
      id: 244438,
      is_central: true,
      question_status: 3,
      question_answer: [
        {
          answer:
            "<p>a. Woofy's&nbsp;</p>\n<p>b. gulmohar tree</p>\n<p>&nbsp;</p>\n<p>&nbsp;</p>\n<p>&nbsp;</p>\n<p>&nbsp;</p>",
          question:
            '<p><img src="https://d2r9gkgplfhsr2.cloudfront.net/prod/question_file/Grade 1/English/Understanding/Descriptive/2022-11-17 10:05:44.019384_2022-11-17 15_35_24-WT2_V3_Grade 1_English_QP.pdf.png" alt="My alt text" width="566" height="515" /></p>',
        },
      ],
      question_level: 1,
      question_categories: 6,
      question_type: {
        id: 11,
        type_name: 'Answer the following',
        type: {
          id: 25,
          type_name: 'VSAQ',
        },
      },
      topic: {
        id: 11421,
        topic_name: "Jimmy's Dream",
        grade_subject_mapping: {
          id: 33,
          grade: {
            id: 39,
            grade_name: 'Grade 1',
          },
          subject: {
            id: 25,
            subject_name: 'English',
          },
        },
        academic_year: {
          id: 17,
          session_year: '2022-23',
          is_delete: false,
          start_date: null,
          end_date: null,
        },
        volume: {
          id: 22,
          volume_name: 'Volume 3',
          is_delete: false,
        },
      },
      chapter: {
        id: 11819,
        chapter_name: "Jimmy's Dream",
        module: {
          lt_module_name: 'Reading Comprehension',
          id: 7347,
        },
      },
      question_mark: 2,
      is_tagged: true,
      sub_questions: [],
      created_at: '2022-11-17T15:35:48.568372+05:30',
      updated_at: '2023-08-30T17:09:15.784953+05:30',
    },
    {
      id: 240152,
      is_central: true,
      question_status: 3,
      question_answer: [
        {
          answer: ['option4'],
          options: [
            {
              option1: {
                images: [],
                isChecked: false,
                optionValue: 'The cat is sitting under the box.',
              },
            },
            {
              option2: {
                images: [],
                isChecked: false,
                optionValue: 'The cat is sitting in the box.',
              },
            },
            {
              option3: {
                images: [],
                isChecked: false,
                optionValue: 'The cat is sitting behind the box.',
              },
            },
            {
              option4: {
                images: [],
                isChecked: true,
                optionValue: 'The cat is sitting on the box.',
              },
            },
          ],
          question:
            '<p>Look at the picture, and select the correct sentence.</p>\n<p><img src="https://d2r9gkgplfhsr2.cloudfront.net/prod/question_file/Grade 1/English/Application/MCQ_SINGLE_CHOICE/2022-10-31 07:16:08.825719_WhatsApp Image 2022-10-31 at 12.45.56 PM.jpeg" alt="My alt text" width="173" height="164" /></p>',
        },
      ],
      question_level: 1,
      question_categories: 4,
      question_type: {
        id: 32,
        type_name: 'Mutiple single choice questions',
        type: {
          id: 24,
          type_name: 'MCQ',
        },
      },
      topic: {
        id: 11923,
        topic_name: 'Where Is My Place?',
        grade_subject_mapping: {
          id: 33,
          grade: {
            id: 39,
            grade_name: 'Grade 1',
          },
          subject: {
            id: 25,
            subject_name: 'English',
          },
        },
        academic_year: {
          id: 17,
          session_year: '2022-23',
          is_delete: false,
          start_date: null,
          end_date: null,
        },
        volume: {
          id: 22,
          volume_name: 'Volume 3',
          is_delete: false,
        },
      },
      chapter: {
        id: 12237,
        chapter_name: 'Where Is My Place?',
        module: {
          lt_module_name: 'Parts of Speech',
          id: 7396,
        },
      },
      question_mark: 1,
      is_tagged: true,
      sub_questions: [],
      created_at: '2022-10-31T12:45:22.274324+05:30',
      updated_at: '2023-08-30T17:00:15.870932+05:30',
    },
    {
      id: 244453,
      is_central: true,
      question_status: 3,
      question_answer: [
        {
          answer: '<p>The grasshopper said the words to the ant.&nbsp;</p>',
          question:
            '<p><img src="https://d2r9gkgplfhsr2.cloudfront.net/prod/question_file/Grade 1/English/Understanding/Descriptive/2022-11-17 10:08:07.979271_2022-11-17 15_37_44-WT2_V3_Grade 1_English_QP.pdf.png" alt="My alt text" width="435" height="212" /></p>',
        },
      ],
      question_level: 2,
      question_categories: 6,
      question_type: {
        id: 11,
        type_name: 'Answer the following',
        type: {
          id: 25,
          type_name: 'VSAQ',
        },
      },
      topic: {
        id: 11953,
        topic_name: 'The Ant and the Grasshopper',
        grade_subject_mapping: {
          id: 33,
          grade: {
            id: 39,
            grade_name: 'Grade 1',
          },
          subject: {
            id: 25,
            subject_name: 'English',
          },
        },
        academic_year: {
          id: 17,
          session_year: '2022-23',
          is_delete: false,
          start_date: null,
          end_date: null,
        },
        volume: {
          id: 22,
          volume_name: 'Volume 3',
          is_delete: false,
        },
      },
      chapter: {
        id: 12244,
        chapter_name: 'The Ant and the Grasshopper',
        module: {
          lt_module_name: 'Reading Comprehension',
          id: 7347,
        },
      },
      question_mark: 1,
      is_tagged: true,
      sub_questions: [],
      created_at: '2022-11-17T15:38:11.595298+05:30',
      updated_at: '2023-08-30T17:10:52.639820+05:30',
    },
    {
      id: 244461,
      is_central: true,
      question_status: 3,
      question_answer: [
        {
          answer:
            '<p>&nbsp;</p>\n<p>a. tree- tall</p>\n<p>b. clock- round</p>\n<p>c. train- long</p>\n<p>d. ant- small</p>',
          question:
            '<p><img src="https://d2r9gkgplfhsr2.cloudfront.net/prod/question_file/Grade 1/English/Understanding/Descriptive/2022-11-17 10:12:32.741963_2022-11-17 15_42_20-WT2_V3_Grade 1_English_QP.pdf.png" alt="My alt text" width="517" height="369" /></p>',
        },
      ],
      question_level: 2,
      question_categories: 4,
      question_type: {
        id: 11,
        type_name: 'Answer the following',
        type: {
          id: 25,
          type_name: 'VSAQ',
        },
      },
      topic: {
        id: 11495,
        topic_name: 'Describing Words',
        grade_subject_mapping: {
          id: 33,
          grade: {
            id: 39,
            grade_name: 'Grade 1',
          },
          subject: {
            id: 25,
            subject_name: 'English',
          },
        },
        academic_year: {
          id: 17,
          session_year: '2022-23',
          is_delete: false,
          start_date: null,
          end_date: null,
        },
        volume: {
          id: 22,
          volume_name: 'Volume 3',
          is_delete: false,
        },
      },
      chapter: {
        id: 11876,
        chapter_name: 'Describing Words',
        module: {
          lt_module_name: 'Parts of Speech',
          id: 7396,
        },
      },
      question_mark: 4,
      is_tagged: true,
      sub_questions: [],
      created_at: '2022-11-17T15:42:43.290533+05:30',
      updated_at: '2023-08-30T17:14:22.686370+05:30',
    },
    {
      id: 247222,
      is_central: true,
      question_status: 3,
      question_answer: [
        {
          answer: ['option3'],
          options: [
            {
              option1: {
                images: [],
                isChecked: false,
                optionValue: 'reached',
              },
            },
            {
              option2: {
                images: [],
                isChecked: false,
                optionValue: 'catch',
              },
            },
            {
              option3: {
                images: [],
                isChecked: true,
                optionValue: 'chopped',
              },
            },
            {
              option4: {
                images: [],
                isChecked: false,
                optionValue: 'poor',
              },
            },
          ],
          question:
            '<p>Read the extract given below, and answer the question that follows.<br /><em>As he reached home, Jack chopped down the stalk with an axe. The giant could not catch him. Jack and his mother were never poor again.<br /></em>Choose the correct option from the passage to complete the sentence.<br />Allan ________ some wood for the fire.</p>',
        },
      ],
      question_level: 1,
      question_categories: 4,
      question_type: {
        id: 32,
        type_name: 'Mutiple single choice questions',
        type: {
          id: 24,
          type_name: 'MCQ',
        },
      },
      topic: {
        id: 14676,
        topic_name: 'Jack and the Beanstalk II',
        grade_subject_mapping: {
          id: 33,
          grade: {
            id: 39,
            grade_name: 'Grade 1',
          },
          subject: {
            id: 25,
            subject_name: 'English',
          },
        },
        academic_year: {
          id: 17,
          session_year: '2022-23',
          is_delete: false,
          start_date: null,
          end_date: null,
        },
        volume: {
          id: 23,
          volume_name: 'Volume 4',
          is_delete: false,
        },
      },
      chapter: {
        id: 13812,
        chapter_name: 'Jack and the Beanstalk II',
        module: {
          lt_module_name: 'Reading Comprehension',
          id: 8666,
        },
      },
      question_mark: 1,
      is_tagged: true,
      sub_questions: [],
      created_at: '2022-11-29T12:13:16.076324+05:30',
      updated_at: '2023-08-30T17:18:12.067715+05:30',
    },
    {
      id: 240054,
      is_central: true,
      question_status: 3,
      question_answer: [
        {
          answer: ['option1'],
          options: [
            {
              option1: {
                images: [],
                isChecked: true,
                optionValue: 'a gulmohar tree',
              },
            },
            {
              option2: {
                images: [],
                isChecked: false,
                optionValue: 'a mango tree',
              },
            },
            {
              option3: {
                images: [],
                isChecked: false,
                optionValue: 'a banana tree',
              },
            },
            {
              option4: {
                images: [],
                isChecked: false,
                optionValue: 'a coconut tree',
              },
            },
          ],
          question: "<p>What did Jimmy's mother want to plant in this hole?</p>",
        },
      ],
      question_level: 1,
      question_categories: 6,
      question_type: {
        id: 32,
        type_name: 'Mutiple single choice questions',
        type: {
          id: 24,
          type_name: 'MCQ',
        },
      },
      topic: {
        id: 11421,
        topic_name: "Jimmy's Dream",
        grade_subject_mapping: {
          id: 33,
          grade: {
            id: 39,
            grade_name: 'Grade 1',
          },
          subject: {
            id: 25,
            subject_name: 'English',
          },
        },
        academic_year: {
          id: 17,
          session_year: '2022-23',
          is_delete: false,
          start_date: null,
          end_date: null,
        },
        volume: {
          id: 22,
          volume_name: 'Volume 3',
          is_delete: false,
        },
      },
      chapter: {
        id: 11819,
        chapter_name: "Jimmy's Dream",
        module: {
          lt_module_name: 'Reading Comprehension',
          id: 7347,
        },
      },
      question_mark: 1,
      is_tagged: true,
      sub_questions: [],
      created_at: '2022-10-31T10:07:24.438807+05:30',
      updated_at: '2023-08-30T17:38:26.686009+05:30',
    },
    {
      id: 240054,
      is_central: true,
      question_status: 3,
      question_answer: [
        {
          answer: ['option1'],
          options: [
            {
              option1: {
                images: [],
                isChecked: true,
                optionValue: 'a gulmohar tree',
              },
            },
            {
              option2: {
                images: [],
                isChecked: false,
                optionValue: 'a mango tree',
              },
            },
            {
              option3: {
                images: [],
                isChecked: false,
                optionValue: 'a banana tree',
              },
            },
            {
              option4: {
                images: [],
                isChecked: false,
                optionValue: 'a coconut tree',
              },
            },
          ],
          question: "<p>What did Jimmy's mother want to plant in this hole?</p>",
        },
      ],
      question_level: 1,
      question_categories: 6,
      question_type: {
        id: 32,
        type_name: 'Mutiple single choice questions',
        type: {
          id: 24,
          type_name: 'MCQ',
        },
      },
      topic: {
        id: 11421,
        topic_name: "Jimmy's Dream",
        grade_subject_mapping: {
          id: 33,
          grade: {
            id: 39,
            grade_name: 'Grade 1',
          },
          subject: {
            id: 25,
            subject_name: 'English',
          },
        },
        academic_year: {
          id: 17,
          session_year: '2022-23',
          is_delete: false,
          start_date: null,
          end_date: null,
        },
        volume: {
          id: 22,
          volume_name: 'Volume 3',
          is_delete: false,
        },
      },
      chapter: {
        id: 11819,
        chapter_name: "Jimmy's Dream",
        module: {
          lt_module_name: 'Reading Comprehension',
          id: 7347,
        },
      },
      question_mark: 1,
      is_tagged: true,
      sub_questions: [],
      created_at: '2022-10-31T10:07:24.438807+05:30',
      updated_at: '2023-08-30T17:38:26.686009+05:30',
    },
    {
      id: 247212,
      is_central: true,
      question_status: 3,
      question_answer: [
        {
          answer: ['option2'],
          options: [
            {
              option1: {
                images: [],
                isChecked: false,
                optionValue: 'There are mangoes on the tree.',
              },
            },
            {
              option2: {
                images: [],
                isChecked: true,
                optionValue: 'There are apples on the tree.',
              },
            },
            {
              option3: {
                images: [],
                isChecked: false,
                optionValue: 'There are flowers in the garden.',
              },
            },
            {
              option4: {
                images: [],
                isChecked: false,
                optionValue: 'There are fruits in the basket.',
              },
            },
          ],
          question:
            '<p>Look at the picture and select the correct sentence.</p>\n<p><img src="https://d2r9gkgplfhsr2.cloudfront.net/prod/question_file/Grade 1/English/Understanding/MCQ_SINGLE_CHOICE/2022-11-29 06:17:24.331815_WhatsApp Image 2022-11-29 at 11.47.09 AM.jpeg" alt="My alt text" width="81" height="88" /></p>\n<p>&nbsp;</p>',
        },
      ],
      question_level: 2,
      question_categories: 4,
      question_type: {
        id: 32,
        type_name: 'Mutiple single choice questions',
        type: {
          id: 24,
          type_name: 'MCQ',
        },
      },
      topic: {
        id: 14669,
        topic_name: 'Rebus Reading and Writing II',
        grade_subject_mapping: {
          id: 33,
          grade: {
            id: 39,
            grade_name: 'Grade 1',
          },
          subject: {
            id: 25,
            subject_name: 'English',
          },
        },
        academic_year: {
          id: 17,
          session_year: '2022-23',
          is_delete: false,
          start_date: null,
          end_date: null,
        },
        volume: {
          id: 23,
          volume_name: 'Volume 4',
          is_delete: false,
        },
      },
      chapter: {
        id: 13805,
        chapter_name: 'Rebus Reading and Writing II',
        module: {
          lt_module_name: 'Writing Skills',
          id: 8874,
        },
      },
      question_mark: 1,
      is_tagged: true,
      sub_questions: [],
      created_at: '2022-11-29T11:46:34.351086+05:30',
      updated_at: '2023-08-30T17:18:00.851952+05:30',
    },
  ],
};

export { constantValue };
