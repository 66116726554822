import React, { useState, useEffect, useCallback, useContext } from 'react';
import Layout from '../../Layout';
import CommonBreadcrumbs from '../../../components/common-breadcrumbs/breadcrumbs';
import './styles.scss';
import {
  Typography,
  Grid,
  FormControl,
  TextField,
  FormHelperText,
  Divider,
  Tabs,
  Tab,
  Paper,
  Button,
  InputLabel,
  OutlinedInput,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DeleteIcon from '@material-ui/icons/Delete';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DNDFileUpload from '../../../components/dnd-file-upload';
import TabPanel from './tab-panel';
import PeriodList from './period-list';
import ContentTypeList from './content-type-list';
import AttachmentList from './attachment-list';

import {
  fetchVolumes,
  fetchAcademicYears,
  fetchGrades,
  fetchSubjects,
  fetchBoard,
  fetchModule,
  fetchTopics,
  fetchChapters,
  uploadLessonPlanFile,
  createLessonPlan,
  createLessonOverview,
  deleteLessonPlanFile,
  getAttachments,
  createPeriods,
  periodDetails,
} from './apis';

import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import { fetchModules } from '../../../redux/actions';
const validationSchema = Yup.object({
  academic_year: Yup.object('').required('Required').nullable(),
  volume: Yup.object('').required('Required').nullable(),
  grade: Yup.object('').required('Required').nullable(),
  subject: Yup.object('').required('Required').nullable(),
  chapter: Yup.object('').required('Required').nullable(),
  board: Yup.object('').required('Required').nullable(),
  modules: Yup.object('').required('Required').nullable(),
  // periods: Yup.number()
  //   .required('Required')
  //   .test(
  //     'Is positive?',
  //     'ERROR: The number must be greater than 0!',
  //     (value) => value > 0
  //   ),
});
const contentTypes = [
  'Lesson Plan',
  'Student Reading Material',
  'PPT',
  'Video',
  'Audio',
  'Textbook Answer Scheme',
  'Teacher Reading Material',
  'Activity Sheet',
  'Question Paper',
];

const contentTypesInitialState = contentTypes.map((type) => ({
  currentUploadedFile: null,
  name: type,
  attachments: [],
}));

const validateSynopsisOverviewUpload = (valuesObj) => {
  let errors = [];
  Object.keys(valuesObj).forEach((key) => {
    if (!valuesObj[key]) {
      errors.push(key);
    }
  });
  return errors;
};

const initialState = {
  period: 'Period-1',
  activeTab: 'lesson-plan',
  contentTypeState: contentTypesInitialState,
};

const EditLessonPlan = (ViewData) => {
  const { setAlert } = useContext(AlertNotificationContext);

  const [periodsState, setPeriodsState] = useState([]);
  const [yearlyCurrFile, setYearlyCurrFile] = useState();
  const [portionDocumentFile, setPortionDocumentFile] = useState();
  const [activeTab, setActiveTab] = useState('lesson-plan');
  const [selectedPeriod, setselectedPeriod] = useState(null);
  const [selectedContentType, setSelectedContentType] = useState([]);
  const [totalPeriods, setTotalPeriods] = useState(20);
  const [expandFilter, setExpandFilter] = useState(true);
  const [volumes, setVolumes] = useState([]);
  const [academicYears, setAcademicYears] = useState([]);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [board, setBoard] = useState([]);
  const [modules, setModule] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [showSecondView, setShowSecondView] = useState(false);
  const [contentTypesState, setContentTypesState] = useState([]);
  const [currentFile, setCurrentFile] = useState(null);
  const [activeAttachments, setActiveAttachments] = useState([]);
  const [overviewFile, setOverviewFile] = useState(null);
  const [synopsisFile, setSynopsisFile] = useState(null);
  const [chaptersState, setChaptersState] = useState(null);
  const [uploadingAttachment, setUploadingAttachment] = useState(false);
  const [topics, setTopics] = useState([]);
  const [periods, setPeriods] = useState(0);
  const [periodCount, setPeriodCount] = useState(0);
  const [periodName, setPeriodName] = useState('Period');
  const [questionPaperIds, setQuestionPaperIds] = useState([]);
  const [periodId, setPeriodId] = useState('');
  const [periodStates, setPeriodStates] = useState([]);
  const [ overviewClicked , setOverviewClicked ] = useState(false)
  const [ synClicked , setSynClicked ] = useState(false)
  const formik = useFormik({
    initialValues: {
      academic_year: '',
      volume: '',
      grade: '',
      subject: '',
      chapter: '',
      board: '',
      modules: '',
      topic: '',
    },
    validationSchema,
    onSubmit: (values, extra) => {
      // setExpandFilter(false);
      handleShowSecondView();
    },
    validateOnChange: false,
    validateOnBlur: false,
  });

  const resetToInitialState = () => {
    setShowSecondView(false);
    setselectedPeriod(null);
    setActiveTab(initialState.activeTab);
    // setContentTypesState(initialState.contentTypeState);
    formik.resetForm();
  };
  // const discardChanges = () => {
  //   setShowSecondView(false);
  //   setselectedPeriod(null);
  //   setActiveTab(initialState.activeTab);
  // }

  const getVolumes = async () => {
    try {
      const volumes = await fetchVolumes();
      setVolumes(volumes);
    } catch (e) {
      setVolumes([]);
    }
  };

  const getAcademicYears = async () => {
    try {
      const years = await fetchAcademicYears();
      setAcademicYears(years);
    } catch (e) {
      setAcademicYears([]);
    }
  };

  const getGrades = async () => {
    try {
      const years = await fetchGrades();
      setGrades(years);
    } catch (e) {
      setGrades([]);
    }
  };

  const getSubjects = async (gradeId) => {
    try {
      const subjects = await fetchSubjects(gradeId);
      setSubjects(subjects);
    } catch (e) {
      setSubjects([]);
    }
  };

  const getBoard = async () => {
    try {
      const board = await fetchBoard();
      setBoard(board);
    } catch (e) {
      setBoard([]);
    }
  };

  const getModule = async (yearId, volumeId, gradeId, boardId) => {
    try {
      const modules = await fetchModule(yearId, volumeId, gradeId, boardId);
      setModule(modules);
    } catch (e) {
      setModule([]);
    }
  };
  const getTopic = async (Id) => {
    try {
      const topics = await fetchTopics(Id);
      setTopics(topics);
    } catch (e) {
      setTopics([]);
    }
  };

  const getChapters = async (
    gradeSubjectMappingId,
    volumeId,
    academicYearId,
    board,
    modules
  ) => {
    try {
      const chapters = await fetchChapters(
        gradeSubjectMappingId,
        volumeId,
        academicYearId,
        board,
        modules
      );
      setChapters(chapters);
      // let chaptersInitialState = chapters.length? chapters.map((chapter) => ({
      //   name: chapter.chapter_name,
      //   periods: Array.from({length: chapter.no_of_periods}, (_, index) =>({name: `Period-${index}`, contentTypes: contentTypesInitialState }) ).filter((_,index) => index!==0);
      // })): []
      // setChaptersState(chaptersInitialState)
    } catch (e) {
      setChapters([]);
    }
  };

  const getPeriodDetails = async (topicId, chapterId) => {
    try {
      const topicDetails = await periodDetails(topicId, chapterId);
      if (topicDetails?.total_count > 0) {
        setPeriods(topicDetails?.total_count);
        setPeriodCount(topicDetails?.total_count);
        const periodsState = Array.from(
          { length: topicDetails?.total_count },
          (_, index) => ({
            id: `${topicDetails?.periods[index]?.id}`,
            period: `${topicDetails?.periods[index]?.period}`,
            contentTypes: contentTypes.map((type) => ({
              currentUploadedFile: null,
              name: type,
              attachments: [],
            })),
            topic_id: formik.values.topic.id,
            chapter: formik.values.chapter.id,
          })
        );
        setPeriodsState(periodsState);
        setselectedPeriod(periodsState[0]);
      } else {
        const periodsCreate = Array.from({ length: 1 }, (_, index) => ({
          period: `${periodName}-${index + 1}`,
          contentTypes: contentTypes.map((type) => ({
            currentUploadedFile: null,
            name: type,
            attachments: [],
          })),
          topic_id: formik.values.topic.id,
          chapter: formik.values.chapter.id,
        }));
        handleCreatePeriods(periodsCreate);
      }
    } catch (e) {
      setAlert('error', e);
    }
  };
  useEffect(() => {
    if (formik.values.topic) {
      try {
        getPeriodDetails(formik.values.topic.id, formik.values.chapter.id);
      } catch (e) {
        setAlert('error', e);
      }
    }
  }, [formik.values.topic]);

  useEffect(() => {
    console.log('period count:', periodCount, periods);
    if (periodCount < periods) {
      const periodsCreate = Array.from({ length: 1 }, (_, index) => ({
        period: `${periodName}-${index + 1}`,
        contentTypes: contentTypes.map((type) => ({
          currentUploadedFile: null,
          name: type,
          attachments: [],
        })),
        topic_id: formik.values.topic.id,
        chapter: formik.values.chapter.id,
      }));
      // setPeriodStates(periodsCreate);
      handleCreatePeriods(periodsCreate);
    }
  }, [periods]);

  const handleAddPeriods = () => {
    const periodsCreate = Array.from({ length: 1 }, (_, index) => ({
      period: `${periodName}-${index + 1}`,
      contentTypes: contentTypes.map((type) => ({
        currentUploadedFile: null,
        name: type,
        attachments: [],
      })),
      topic_id: formik.values.topic.id,
      chapter: formik.values.chapter.id,
    }));
    setPeriodStates(periodsCreate);
    handleCreatePeriods(periodsCreate);
  };

  const handleNext = () => {
    if (periodCount === periods) {
      getPeriodDetails(formik.values.topic.id, formik.values.chapter.id);
      formik.handleSubmit();
    } else if (periodCount < periods) {
      // setPeriods(parseInt(periods - periodCount));
      const periodsCreate = Array.from(
        { length: parseInt(periods - periodCount) },
        (_, index) => ({
          period: `${periodName}-${index + 1}`,
          contentTypes: contentTypes.map((type) => ({
            currentUploadedFile: null,
            name: type,
            attachments: [],
          })),
          topic_id: formik.values.topic.id,
          chapter: formik.values.chapter.id,
        })
      );
      setPeriodStates(periodsCreate);
      handleCreatePeriods();
    }
    if(overviewFile != null && overviewClicked == false){
      handleSynopsisOverviewFileUpload(yearlyCurrFile, 'overview');
    }
    if(synopsisFile != null && synClicked == false){
      handleSynopsisOverviewFileUpload(portionDocumentFile, 'synopsis');

    }
  };

  const handleShowSecondView = () => {
    setShowSecondView(true);
  };

  const handleSaveAttachments = async () => {
    const {
      academic_year,
      volume,
      grade,
      subject,
      chapter,
      period,
      board,
    } = formik.values;
    if (
      selectedContentType.currentUploadedFile !== null &&
      selectedContentType.currentUploadedFile !== undefined
    ) {
      try {
        // const temp = contentTypesState;
        // const index = temp.findIndex((obj) => obj.name === selectedContentType.name);
        console.log('periodsState', periodsState);
        const tempState = periodsState;
        const index = tempState.findIndex((obj) => obj.period === selectedPeriod?.period);
        const tempObj = tempState[index];
        const contentTypeIndex = tempObj.contentTypes.findIndex(
          (contentType) => contentType.name === selectedContentType.name
        );
        const tempContentType = tempObj.contentTypes[contentTypeIndex];
        const fd = new FormData();
        fd.set('file', tempContentType.currentUploadedFile);
        // fd.set('academic_year', academic_year.session_year);
        // fd.set('volume', volume.volume_name);
        // fd.set('grade', grade.grade_name);
        // fd.set('subject', subject.subject.subject_name);
        // fd.set('chapter', chapter.chapter_name);
        // fd.set('period', selectedPeriod?.period);

        fd.set('academic_year_id', academic_year.id);
        fd.set('volume_id', volume.id);
        fd.set('chapter_id', chapter.id);
        fd.set('gs_mapping_id', subject.id);
        // fd.set('file_type', selectedContentType.name);
        fd.set('file_type', selectedContentType.name.toLowerCase().replace(/\s/g, '_'));
        setUploadingAttachment(true);
        const result = await uploadLessonPlanFile(fd);

        const tempAttachments = tempContentType.attachments;
        tempAttachments.push(result);
        const resultObject = { name: tempContentType.name, attachments: tempAttachments };
        tempObj.contentTypes = [
          ...tempObj.contentTypes.slice(0, contentTypeIndex),
          resultObject,
          ...tempObj.contentTypes.slice(contentTypeIndex + 1),
        ];
        // setPeriodsState([
        //   ...tempState.slice(0, index),
        //   tempObj,
        //   ...tempState.slice(index + 1),
        // ]);
        const updatedPeriodsState = [
          ...tempState.slice(0, index),
          tempObj,
          ...tempState.slice(index + 1),
        ];
        setPeriodsState(updatedPeriodsState);
        // handleCreateLessonPlan(updatedPeriodsState)
        // temp.splice(index, 1, resultObject);
        // setContentTypesState(temp);
        setSelectedContentType({ ...resultObject, canSubmit: true });
        setUploadingAttachment(false);
      } catch (e) {
        setUploadingAttachment(false);

        console.log('error ', e);
      }
    }
  };

  const handleCreateLessonOverviewSynopsis = async (type) => {
    let reqObj = {
      volume: formik.values.volume.id,
      grade_subject_mapping_id: formik.values.subject.id,
      type: type === 'overview' ? 2 : 1,
      attachment: type === 'overview' ? [overviewFile] : [synopsisFile],
      academic_year_id: formik.values.academic_year.id,
    };

    try {
      const response = await createLessonOverview(reqObj);
      // if (type === 'synopsis') setAlert('success', 'Synopsis Uploaded');
      // else setAlert('success', 'Overview Uploaded');
      if (type === 'synopsis') setAlert('success', 'Portion document Uploaded');
      else setAlert('success', 'Yearly Curriculum Uploaded');
    } catch (e) {
      setAlert('error', 'Failed to upload synopsis');
    }
  };

  // useEffect(() => {
  //    if(formik.values.chapter) {
  //      let periods = formik.values.chapter.no_of_periods;
  //      let periodsInitialState =
  //    }

  // }, [formik.values.chapter]);

  const handleSynopsisOverviewFileUpload = async (file, type) => {
    const { academic_year, volume, grade, subject, chapter, period } = formik.values;
    const errors = validateSynopsisOverviewUpload(formik.values);

    if (type === 'overview' || type === 'synopsis') {
      if (errors.includes['chapter']) {
        if (errors.length > 0) {
          setAlert('error', `Please provide ${errors.join(', ')} from  Periods tab`);
          return;
        }
      }
    } else if (errors.length > 0) {
      setAlert('error', `Please provide ${errors.join(', ')}`);
      return;
    }

    const fd = new FormData();
    fd.set('file', file);
    // fd.set('academic_year', academic_year?.session_year);
    // fd.set('volume', volume?.volume_name);
    // fd.set('grade', grade?.grade_name);
    // fd.set('subject', subject?.subject.subject_name);

    fd.set('academic_year_id', academic_year.id);
    fd.set('volume_id', volume.id);
    fd.set('gs_mapping_id', subject.id);

    if (type === 'overview') {
      fd.set('overview', 1);
      fd.set('file_type', 'pdf');
    } else if (type === 'synopsis') {
      fd.set('synopsis', 1);
      fd.set('file_type', 'pdf');
    } else {
      fd.set('period', period);
      // fd.set('chapter', chapter?.chapter_name);
      fd.set('chapter_id', chapter.id);
      fd.set('file_type', selectedContentType);
    }

    try {
      const result = await uploadLessonPlanFile(fd);
      if (type === 'overview') {
        setOverviewFile(result);
      } else {
        setSynopsisFile(result);
      }
    } catch (e) {
      setAlert('error', 'File upload failed');
    }
  };

  const handleCreateLessonPlan = async () => {
    const arr = selectedPeriod?.contentTypes;
    console.log('arr', arr);
    const isFileRemoved = selectedPeriod?.isFileRemoved;
    const atleaseOneAttachmentPresent = arr.some((obj) => obj.attachments.length > 0);
    if (!isFileRemoved && !atleaseOneAttachmentPresent && !questionPaperIds) {
      setAlert('error', 'Please upload atleast one attachment');
      return;
    }
    var attachments = arr.reduce(function (map, obj) {
      map[obj?.name] = obj?.attachments;
      return map;
    }, {});
    const reqObj = {
      chapter: formik.values.chapter.id,
      period: selectedPeriod?.period,
      lesson_plan: attachments['Lesson Plan'],
      ppt: attachments['PPT'],
      video_file: attachments['Video'],
      audio_file: attachments['Audio'],
      student_reading_material: attachments['Student Reading Material'],
      textbook_Answer_Scheme: attachments['Textbook Answer Scheme'],
      teacher_material: attachments['Teacher Reading Material'],
      activity_sheet: attachments['Activity Sheet'],
      question_paper_ids: questionPaperIds,
      topic_id: formik.values.topic.id,
    };
    try {
      const response = await createLessonPlan(reqObj);
      // resetToInitialState();
      setAlert('success', 'Periods updated');
    } catch (e) {
      setAlert('error', 'Periods creation failed');
    }
  };
  useEffect(() => {
    if (!Array.isArray(selectedContentType)) {
      const { attachments, isFileRemoved, canSubmit } = selectedContentType || {};
      // if((Array.isArray(attachments)&& attachments.length) || isFileRemoved){
      if (canSubmit || isFileRemoved) {
        handleCreateLessonPlan();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContentType]);

  const handleChangeUploadedFile = (file, type, period) => {
    // const type = selectedContentType?.name;
    const tempState = periodsState;
    const index = tempState.findIndex((obj) => obj.period === period);
    const tempObj = tempState[index];
    const contentTypeIndex = tempObj.contentTypes.findIndex(
      (contentType) => contentType.name === type
    );

    const tempContentType = tempObj.contentTypes[contentTypeIndex];
    tempContentType.currentUploadedFile = file;

    tempObj.contentTypes = [
      ...tempObj?.contentTypes.slice(0, contentTypeIndex),
      tempContentType,
      ...tempObj?.contentTypes.slice(contentTypeIndex + 1),
    ];
    setSelectedContentType(tempContentType);
    setPeriodsState([
      ...tempState?.slice(0, index),
      tempObj,
      ...tempState?.slice(index + 1),
    ]);
    // setCurrentFile(file);
  };

  useEffect(() => {
    if (typeof overviewFile == 'string') {
      setOverviewClicked(true)
      handleCreateLessonOverviewSynopsis('overview');
    }
  }, [overviewFile]);
  useEffect(() => {
    if (typeof synopsisFile == 'string') {
      setSynClicked(true)
      handleCreateLessonOverviewSynopsis('synopsis');
    }
  }, [synopsisFile]);

  useEffect(() => {
    const { grade, subject, volume, academic_year, board, modules } = formik.values;
    formik.setFieldValue('chapter', '');
    if (subject && volume && academic_year && board && modules) {
      getChapters(grade.id, volume.id, academic_year.id, board.id, modules.id);
    } else {
      setChapters([]);
    }
  }, [
    formik.values.grade,
    formik.values.subject,
    formik.values.volume,
    formik.values.academic_year,
    formik.values.board,
    formik.values.modules,
  ]);

  useEffect(() => {
    formik.setFieldValue('subject', '');
    if (formik.values.grade) {
      getSubjects(formik.values.grade.id);
    } else {
      setSubjects([]);
    }
  }, [formik.values.grade]);

  useEffect(() => {
    formik.setFieldValue('board', '');
    if (formik.values.subject) {
      getBoard(formik.values.board.id);
    } else {
      setBoard([]);
    }
  }, [formik.values.subject]);

  useEffect(() => {
    formik.setFieldValue('modules', '');
    if (formik.values.board) {
      getModule(
        formik.values.academic_year.id,
        formik.values.volume.id,
        formik.values.grade.id,
        formik.values.board.id
      );
    } else {
      setBoard([]);
    }
  }, [formik.values.board]);

  useEffect(() => {
    getVolumes();
    getAcademicYears();
    getGrades();
    const contentTypesInitialState = contentTypes?.map((type) => ({
      currentUploadedFile: null,
      name: type,
      attachments: [],
    }));
    setContentTypesState(contentTypesInitialState);
    setSelectedContentType(contentTypesInitialState[0]);
  }, []);

  useEffect(() => {
    if (formik.values.chapter) {
      getTopic(formik.values.chapter?.id);
    }
  }, [formik.values.chapter]);

  // useEffect(() => {
  //   if (formik.values.topic) {
  //     const periodsState = Array.from(
  //       { length: periods },
  //       (_, index) => ({
  //         period: `${periodName}-${index + 1}`,
  //         contentTypes: contentTypes.map((type) => ({
  //           currentUploadedFile: null,
  //           name: type,
  //           attachments: [],
  //         })),
  //       })
  //     );
  //     setselectedPeriod(periodsState[0]);
  //   }
  // }, [periods])

  const handleCreatePeriods = async (periodObj) => {
    try {
      const reqObj = {
        peroid_creation: true,
        periods: JSON.stringify(periodObj),
      };
      const periodsCreate = await createPeriods(reqObj);
      // setPeriods(periods + 1);
      getPeriodDetails(formik.values.topic.id, formik.values.chapter.id);
    } catch (e) {
      setAlert('error', e);
      console.log(e);
    }
  };

  const fetchAttachments = async () => {
    try {
      const attachments = await getAttachments(
        formik.values.chapter.id,
        selectedPeriod?.period
      );
      const tempState = periodsState;
      const index = tempState.findIndex((obj) => obj.period === selectedPeriod?.period);
      const tempObj = tempState[index];
      setQuestionPaperIds([]);

      attachments.forEach((attachment) => {
        console.log('quesion_paper_ids1', attachment);
        if (
          attachment.document_type === '' ||
          attachment.document_type === 'Question Paper'
        ) {
          const index = tempObj.contentTypes.findIndex(
            (obj) =>
              obj.name.toLowerCase() ===
              `${'Question Paper'}`.split('_').join(' ').toLowerCase()
          );
          console.log('quesion_paper_ids3', attachment?.quesion_paper_ids);
          setQuestionPaperIds(attachment?.quesion_paper_ids);
        } else {
          console.log('quesion_paper_ids2', attachment);
          const index = tempObj.contentTypes.findIndex(
            (obj) =>
              obj.name.toLowerCase() ===
              attachment.document_type.split('_').join(' ').toLowerCase()
          );
          const tempContentType = tempObj.contentTypes[index];
          attachment.media_file.forEach((file) => {
            if (!tempContentType.attachments.includes(file)) {
              tempContentType.attachments.push(file);
            }
          });
          tempObj.contentTypes = [
            ...tempObj.contentTypes.slice(0, index),
            tempContentType,
            ...tempObj.contentTypes.slice(index + 1),
          ];
        }
      });
      setPeriodsState([
        ...tempState.slice(0, index),
        tempObj,
        ...tempState.slice(index + 1),
      ]);
    } catch (e) {
      setAlert('error', 'Failed to fetch attachments');
      console.log(e);
    }
  };

  useEffect(() => {
    if (formik.values.chapter) {
      fetchAttachments();
    }
  }, [selectedPeriod]);

  // console.log('selectedPeriod? ', selectedPeriod?);
  // console.log(
  //   'selected content type ',
  //   selectedPeriod?.contentTypes.find(
  //     (contentType) => contentType.name === selectedContentType?.name
  //   )
  // );

  const currentUploadedFile = selectedPeriod?.contentTypes.find(
    (contentType) => contentType?.name === selectedContentType?.name
  )?.currentUploadedFile;

  const currentAttachments = selectedPeriod?.contentTypes.find(
    (contentType) => contentType?.name === selectedContentType?.name
  )?.attachments;

  return (
    <>
      <Layout>
        <div className='create-lesson-plan-container'>
          <div className='lesson-plan-breadcrumb-wrapper'>
            <CommonBreadcrumbs componentName='Periods' childComponentName='Create New' />
          </div>
          <div className='content-container'>
            <Accordion
              className='collapsible-section'
              square
              expanded={expandFilter}
              onChange={() => {}}
            >
              {/* <AccordionSummary>
                <div
                  className='header mv-20'
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Typography component='h4' color='primary'>
                    Create New Plan
                  </Typography>
                  {!expandFilter ? (
                    <Typography
                      component='h4'
                      color='secondary'
                      onClick={() => {
                        setExpandFilter(true);
                      }}
                    >
                      Expand Filter
                    </Typography>
                  ) : (
                    <Typography
                      component='h4'
                      color='secondary'
                      onClick={() => {
                        setExpandFilter(false);
                      }}
                    >
                      Collapse Filter
                    </Typography>
                  )}
                </div>
              </AccordionSummary> */}
              <AccordionDetails>
                <div className='form-grid-container mv-20'>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth variant='outlined'>
                        <Autocomplete
                          id='academic_year'
                          name='academic_year'
                          onChange={(e, value) => {
                            formik.setFieldValue('volume', '');
                            formik.setFieldValue('grade', '');
                            formik.setFieldValue('subject', '');
                            formik.setFieldValue('board', '');
                            formik.setFieldValue('modules', '');
                            formik.setFieldValue('chapter', '');
                            if (value) {
                              formik.setFieldValue('academic_year', value);
                            }
                          }}
                          value={formik.values.academic_year}
                          options={academicYears}
                          getOptionLabel={(option) => option.session_year || ''}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              label='Academic year'
                              placeholder='Academic year'
                            />
                          )}
                          size='small'
                        />
                        <FormHelperText style={{ color: 'red' }}>
                          {formik.errors.academic_year ? formik.errors.academic_year : ''}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth variant='outlined'>
                        <Autocomplete
                          id='volume'
                          name='volume'
                          onChange={(e, value) => {
                            formik.setFieldValue('grade', '');
                            formik.setFieldValue('subject', '');
                            formik.setFieldValue('board', '');
                            formik.setFieldValue('modules', '');
                            formik.setFieldValue('chapter', '');
                            if (value) {
                              formik.setFieldValue('volume', value);
                            }
                          }}
                          value={formik.values.volume}
                          options={volumes}
                          getOptionLabel={(option) => option.volume_name || ''}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              label='Volume'
                              placeholder='Volume'
                            />
                          )}
                          size='small'
                        />
                        <FormHelperText style={{ color: 'red' }}>
                          {formik.errors.academic_year ? formik.errors.academic_year : ''}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth variant='outlined'>
                        <Autocomplete
                          id='grade'
                          name='grade'
                          onChange={
                            (e, value) => {
                              formik.setFieldValue('subject', '');
                              formik.setFieldValue('board', '');
                              formik.setFieldValue('modules', '');
                              formik.setFieldValue('chapter', '');
                              if (value) {
                                formik.setFieldValue('grade', value);
                              }
                            }
                            //   formik.setFieldValue('branch', value);
                            //   formik.setFieldValue('grade', []);
                            //   formik.setFieldValue('section', []);
                            //   formik.setFieldValue('subjects', []);
                            //   handleChangeBranch(value ? [value] : null);
                          }
                          value={formik.values.grade}
                          options={grades}
                          getOptionLabel={(option) => option.grade_name || ''}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              label='Grade'
                              placeholder='Grade'
                            />
                          )}
                          size='small'
                        />
                        <FormHelperText style={{ color: 'red' }}>
                          {formik.errors.grade ? formik.errors.grade : ''}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth variant='outlined'>
                        <Autocomplete
                          id='subject'
                          name='subject'
                          onChange={(e, value) => {
                            formik.setFieldValue('board', '');
                            formik.setFieldValue('modules', '');
                            formik.setFieldValue('chapter', '');
                            if (value) {
                              formik.setFieldValue('subject', value);
                            }
                          }}
                          value={formik.values.subject}
                          options={subjects}
                          getOptionLabel={(option) => option.subject?.subject_name || ''}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              label='Subject'
                              placeholder='Subject'
                            />
                          )}
                          size='small'
                        />
                        <FormHelperText style={{ color: 'red' }}>
                          {formik.errors.subject ? formik.errors.subject : ''}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth variant='outlined'>
                        <Autocomplete
                          id='board'
                          name='board'
                          onChange={(e, value) => {
                            formik.setFieldValue('modules', '');
                            formik.setFieldValue('chapter', '');
                            if (value) {
                              formik.setFieldValue('board', value);
                            }
                          }}
                          value={formik.values.board}
                          options={board}
                          getOptionLabel={(option) => option?.board_name || ''}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              label='Board'
                              placeholder='Board'
                            />
                          )}
                          size='small'
                        />
                        {/* <FormHelperText style={{ color: 'red' }}>
                          {formik.errors.subject ? formik.errors.subject : ''}
                        </FormHelperText> */}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth variant='outlined'>
                        <Autocomplete
                          id='modules'
                          name='modules'
                          onChange={(e, value) => {
                            formik.setFieldValue('chapter', '');
                            if (value) {
                              formik.setFieldValue('modules', value);
                            }
                          }}
                          value={formik.values.modules}
                          options={modules}
                          getOptionLabel={(option) => option?.lt_module_name || ''}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              label='Module'
                              placeholder='Module'
                            />
                          )}
                          size='small'
                        />
                        {/* <FormHelperText style={{ color: 'red' }}>
                          {formik.errors.subject ? formik.errors.subject : ''}
                        </FormHelperText> */}
                      </FormControl>
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <FormControl fullWidth variant='outlined'>
                        <Autocomplete
                          id='chapter'
                          name='chapter'
                          onChange={(e, value) => {
                            formik.setFieldValue('chapter', value);
                          }}
                          value={formik.values.chapter}
                          options={chapters}
                          getOptionLabel={(option) => option.chapter_name || ''}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant='outlined'
                              label='Chapter'
                              placeholder='Chapter'
                            />
                          )}
                          size='small'
                        />
                        <FormHelperText style={{ color: 'red' }}>
                          {formik.errors.chapter ? formik.errors.chapter : ''}
                        </FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </div>
              </AccordionDetails>
            </Accordion>
            <div className='divider-container'>
              <Divider />
            </div>
            <div className='tabs-container'>
              <Paper square style={{ boxShadow: 'none' }}>
                <Tabs
                  indicatorColor='primary'
                  textColor='primary'
                  onChange={(event, value) => {
                    setActiveTab(value);
                  }}
                  aria-label='disabled tabs example'
                  style={{ backgroundColor: '#fafafa' }}
                  value={activeTab}
                >
                  {/* <Tab label='Overview' value='overview' />
                  <Tab label='Synopsis' value='synopsis' /> */}

                  <Tab label='Yearly Curriculum' value='overview' />
                  <Tab label='Portion Document' value='synopsis' />

                  <Tab label='Periods' value='lesson-plan' />
                </Tabs>
                <TabPanel name='overview' value={activeTab}>
                  <DNDFileUpload
                    value={overviewFile}
                    handleChange={(files) => {
                      // handleSynopsisOverviewFileUpload(files, 'overview');
                      // console.log('aaa', files);
                      setYearlyCurrFile(files);
                    }}
                    fileType='application/pdf'
                    typeNames='pdf'
                  />
                  <Button
                    style={{ backgroundColor: '#ff6b6b' }}
                    onClick={() => {
                      handleSynopsisOverviewFileUpload(yearlyCurrFile, 'overview');
                    }}
                  >
                    Submit file
                  </Button>
                </TabPanel>
                <TabPanel name='synopsis' value={activeTab}>
                  <DNDFileUpload
                    value={synopsisFile}
                    handleChange={(files) => {
                      // handleSynopsisOverviewFileUpload(files, 'synopsis');
                      setPortionDocumentFile(files);
                    }}
                    fileType='application/pdf'
                    typeNames='pdf'
                  />
                  <Button
                    style={{ backgroundColor: '#ff6b6b' }}
                    onClick={() => {
                      handleSynopsisOverviewFileUpload(portionDocumentFile, 'synopsis');
                    }}
                  >
                    Submit File
                  </Button>
                </TabPanel>
                <TabPanel name='lesson-plan' value={activeTab}>
                  <div className='lesson-plan-tab-content'>
                    <div className='lesson-plan-first-view'>
                      <div className='form-grid-container mb-20 '>
                        <Grid container spacing={2}>
                          <Grid item md={4} xs={12}>
                            <FormControl fullWidth variant='outlined'>
                              <Autocomplete
                                id='topic'
                                name='topic'
                                onChange={(e, value) => {
                                  formik.setFieldValue('topic', value);
                                }}
                                value={formik.values.topic}
                                options={topics}
                                getOptionLabel={(option) => option.topic_name || ''}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    variant='outlined'
                                    label='Key Concept'
                                    placeholder='Select Key Concept'
                                  />
                                )}
                                size='small'
                              />
                              <FormHelperText style={{ color: 'red' }}>
                                {formik.errors.chapter ? formik.errors.chapter : ''}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item md={4} xs={12}>
                            <FormControl variant='outlined' fullWidth size='small'>
                              {/* <InputLabel htmlFor='component-outlined'>
                                Total period
                              </InputLabel> */}
                              <OutlinedInput
                                id='periods'
                                name='periods'
                                onChange={(e) => {
                                  setPeriods(e.target.value);
                                }}
                                value={periods}
                                inputProps={{ maxLength: 150 }}
                                type='number'
                                placeholder='Total period'
                              />
                              <FormHelperText style={{ color: 'red' }}>
                                {/* {formik.errors.periods ? formik.errors.periods : ''} */}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item md={4} xs={12}>
                            {!showSecondView && periods !== 0 && (
                              <div className='btn-container'>
                                <Button
                                  onClick={handleNext}
                                  disabled={periods !== 0 ? false : true}
                                  // style={{ backgroundColor: '#ff6b6b' }}
                                  color='primary'
                                  className={
                                    periods !== 0 ? 'next-button-second' : 'next-button'
                                  }
                                >
                                  Next
                                </Button>
                              </div>
                            )}
                            {showSecondView ||
                              (periods === 0 && (
                                <div className='btn-container'>
                                  <Button
                                    onClick={handleAddPeriods}
                                    // disabled={periods !== 0 ? false : true}
                                    // style={{ backgroundColor: '#ff6b6b' }}
                                    color='primary'
                                    className={
                                      periods !== 0 ? 'next-button-second' : 'next-button'
                                    }
                                  >
                                    Add Periods
                                  </Button>
                                </div>
                              ))}
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                    {showSecondView && (
                      <div className='lesson-plan-second-view'>
                        <Grid container>
                          <Grid xs={12} md={4} item>
                            <PeriodList
                              value={selectedPeriod}
                              totalPeriods={formik?.values?.chapter?.no_of_periods}
                              periods={periodsState}
                              chapterInfo={formik?.values?.chapter?.id}
                              topicInfo={formik?.values?.topic?.id}
                              setPeriodState={setPeriodsState}
                              setPeriodNames={setPeriodName}
                              getPeriodDetails={getPeriodDetails}
                              periodId={selectedPeriod?.id}
                              onClick={(period) => {
                                setselectedPeriod(period);
                              }}
                            />
                          </Grid>

                          {selectedPeriod ? (
                            <>
                              <Grid xs={12} md={3} item>
                                <ContentTypeList
                                  value={selectedContentType}
                                  contentTypes={selectedPeriod?.contentTypes}
                                  onClick={(contentType) => {
                                    setSelectedContentType(contentType);
                                  }}
                                />
                              </Grid>
                              <Grid xs={12} md={4} item>
                                <AttachmentList
                                  loading={uploadingAttachment}
                                  value={currentAttachments}
                                  selectedContentType={selectedContentType?.name}
                                  subjectInfo={formik.values?.subject}
                                  gradeInfo={formik.values?.grade}
                                  chapterInfo={formik.values?.chapter}
                                  setQuestionPaperIds={setQuestionPaperIds}
                                  questionPaperIds={questionPaperIds}
                                  onChange={(file) => {
                                    handleChangeUploadedFile(
                                      file,
                                      selectedContentType?.name,
                                      selectedPeriod?.period
                                    );
                                  }}
                                  currentFile={currentUploadedFile}
                                  onDelete={(file) => {
                                    const type = selectedContentType?.name;
                                    const tempState = periodsState;
                                    const index = tempState.findIndex(
                                      (obj) => obj.period === selectedPeriod?.period
                                    );
                                    const tempObj = tempState[index];
                                    const contentTypeIndex = tempObj.contentTypes.findIndex(
                                      (contentType) => contentType.name === type
                                    );

                                    const tempContentType =
                                      tempObj.contentTypes[contentTypeIndex];

                                    tempContentType.attachments = tempContentType.attachments.filter(
                                      (attachment) => attachment !== file
                                    );

                                    tempObj.contentTypes = [
                                      ...tempObj.contentTypes.slice(0, contentTypeIndex),
                                      tempContentType,
                                      ...tempObj.contentTypes.slice(contentTypeIndex + 1),
                                    ];

                                    setPeriodsState([
                                      ...tempState.slice(0, index),
                                      tempObj,
                                      ...tempState.slice(index + 1),
                                    ]);
                                    setSelectedContentType({
                                      ...tempContentType,
                                      isFileRemoved: true,
                                    });
                                  }}
                                  onSave={handleSaveAttachments}
                                  onSubmit={handleCreateLessonPlan}
                                  onDiscard={resetToInitialState}
                                />
                              </Grid>
                            </>
                          ) : (
                            ''
                          )}
                        </Grid>
                      </div>
                    )}
                  </div>
                </TabPanel>
              </Paper>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default EditLessonPlan;
