import React, { useEffect, useState, useCallback, useContext } from 'react';
import { withStyles, IconButton, Button, CircularProgress } from '@material-ui/core';
import { Typography, Dialog, DialogContent, DialogActions } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Checkbox from '@mui/material/Checkbox';
import {
  Grid,
  FormControl,
  TextField,
  FormHelperText,
  Divider,
  Tabs,
  Tab,
  Paper,
  InputLabel,
  OutlinedInput,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  SvgIcon,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormik } from 'formik';
import { AlertNotificationContext } from '../../../../context-api/alert-context/alert-state';
import DNDFileUpload from '../../../../components/dnd-file-upload';
import './styles.scss';
import endpoints from '../../../../config/endpoints';
import axiosInstance from '../../../../config/axios';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import Slide from '@mui/material/Slide';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ReactHtmlParser from 'react-html-parser';
import QuestionPaperView from './questionPaperView';
import { Drawer, Space, Spin, message } from 'antd';
import { CloseOutlined } from '@material-ui/icons';
import ConfirmPopOver from '../../../assesment/assesment-view/assesment-card-new/ConfirmPopOver';
import { AttachmentPreviewerContext } from '../../../../components/attachment-previewer/attachment-previewer-contexts';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const StyledButton = withStyles({
  root: {
    height: '40px',
    color: '#FFFFFF',
    backgroundColor: '#FF6B6B',
    //borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#FF6B6B',
    },
  },
})(Button);

const StyledCancelButton = withStyles({
  root: {
    backgroundColor: '#E2E2E2',
    color: '#FF6B6B',
    height: '40px',
    marginRight: 'auto',
  },
})(Button);

const contentTypes = [
  'Periods',
  'Student Reading Material',
  'PPT',
  'Video',
  'Audio',
  'Textbook Answer Scheme',
  'Teacher Material',
];

const getAcceptedFileTypes = (contentType) => {
  switch (contentType) {
    case 'Lesson Plan':
      return { fileTypes: 'application/pdf', types: 'pdf' };
    case 'Student Reading Material':
      return { fileTypes: 'application/pdf', types: 'pdf' };
    case 'PPT':
      return {
        fileTypes:
          'application/vnd.openxmlformats-officedocument.presentationml.presentation, application/pdf, application/vnd.ms-powerpoint',
        types: 'ppt, pptx, pdf',
      };
    case 'Video':
      return { fileTypes: 'video/mp4', types: 'mp4' };
    case 'Audio':
      return { fileTypes: 'audio/*', types: 'audio' };
    case 'Textbook Answer Scheme':
      return {
        fileTypes: 'image/png, image/jpg, image/jpeg,  application/pdf',
        types: 'png, jpg, jpeg,  pdf',
      };
    case 'Teacher Reading Material':
      return { fileTypes: 'application/pdf, video/mp4', types: 'pdf,mp4' };
    case 'Homework':
      return {
        fileTypes: 'image/png, image/jpg, image/jpeg, application/pdf,audio/*,video/mp4',
        types: 'png, jpg, jpeg, pdf,audio,mp4',
      };
    case 'Activity Sheet':
      return {
        fileTypes: 'image/png, image/jpg, image/jpeg,  application/pdf',
        types: 'png, jpg, jpeg,  pdf',
      };
    case 'Question Paper':
      return { fileTypes: 'text', types: 'id' };
    default:
      return { fileTypes: 'image/*', types: 'images' };
  }
};
const AttachmentList = ({
  selectedContentType,
  value,
  onChange,
  onDelete,
  currentFile,
  onSave,
  onSubmit,
  onDiscard,
  loading,
  gradeInfo,
  subjectInfo,
  chapterInfo,
  setQuestionPaperIds,
  questionPaperIds,
  qpname,
  setqpname,
  setIstrue,
  istrue,
  academic_year,
  wholeQuestion,
  setWholeQuestion,
  handleCreateLessonPlan,
}) => {
  const acceptedFileTypes = getAcceptedFileTypes(selectedContentType).fileTypes;
  const acceptedTypes = getAcceptedFileTypes(selectedContentType).types;
  const themeContext = useTheme();

  const [deleteDialogeOpen, setDeleteDialogeOpen] = React.useState(false);
  const [questionPapers, setQuestionPapers] = React.useState([]);
  const [questionPaper, setQuestionPaper] = React.useState(questionPaperIds || '');
  const [gradeSubjectMapping, setGradeSubjectMapping] = React.useState([]);
  const [isChecked, setIsChecked] = useState(istrue || '');
  const [openQuestion, setOpenQuestion] = useState(false);
  const [questionId, setQuestionId] = useState('');
  const [questionData, setQuestionData] = useState([]);
  const [questionPaperData, setQuestionPaperData] = useState([]);
  const { setAlert } = useContext(AlertNotificationContext);
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [loadingDrawer, setLoadingDrawer] = useState(false);
  const [deleteFile, setDeleteFile] = useState('');

  const { openPreview } = React.useContext(AttachmentPreviewerContext) || {};
  const getS3DomainURL = (fileSrc, p) => {
    return `${endpoints.erps3}${fileSrc}`;
  };

  const handleDisplayQuestionId = (question) => {
    setQuestionId(question);
    handleClickOpen();
  };

  const handleClickOpen = () => {
    setOpenQuestion(true);
  };

  const handleClose = () => {
    setOpenQuestion(false);
  };
  useEffect(() => {
    if (questionId) {
      axiosInstance
        .get(`${endpoints.questionBank.questionPaper}${questionId}/`)
        .then((result) => {
          if (result.status === 200) {
            {
              setQuestionData(result?.data);
            }
          } else {
            setAlert('error', result.error_message);
          }
        })
        .catch((error) => {
          setAlert('error', error.message);
        });
    }
  }, [questionId]);

  useEffect(() => {
    axiosInstance
      .get(
        `${endpoints.masterManagement.gradeSubjectMappingList}?grade=${gradeInfo?.id}&subject=${subjectInfo?.subject?.subject_name}`
      )
      .then((response) => {
        if (response.data.status_code === 200) {
          setGradeSubjectMapping(response?.data?.result?.results[0]?.id);
        }
      });
  }, [gradeInfo, subjectInfo?.subject]);

  useEffect(() => {
    if (gradeSubjectMapping?.length != 0) {
      axiosInstance
        .get(`${endpoints.periods.questionList}?gs_mapping_id=${subjectInfo.id}`)
        .then((response) => {
          if (response.data.status_code === 200) {
            setQuestionPapers(response.data?.result);
          }
        });
    }
  }, [chapterInfo, gradeSubjectMapping]);

  function handleQuestionPaper(event = {}, value = []) {
    if (value != null) {
      setWholeQuestion(value);
      const ids = [value].map((el) => el);
      const selectedId = [value].map((el) => el?.id);
      // setQuestionPaper(value?.id);
      // setQuestionPaperIds(value?.id);
      // setqpname(value?.paper_name);
    } else {
      setWholeQuestion(value);
    }
  }
  const handleCheck = () => {
    setIsChecked(!isChecked);
    setIstrue(!isChecked);
  };

  const handleClear = () => {
    setqpname('');
    setQuestionData([]);
    setQuestionId();
    setQuestionPaper();
    setQuestionPaperIds();
    setWholeQuestion();
  };
  const extractContent = (s) => {
    const span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  };
  const fetchQuestionData = (paperid) => {
    setLoadingDrawer(true);
    const url = endpoints.questionBank.questionPaperPreview.replace(
      '<question-paper-id>',
      paperid
    );
    axiosInstance
      .get(url)
      .then((result) => {
        if (result.data.status_code === 200) {
          const { sections, questions } = result.data.result;
          const parsedResponse = [];
          sections.forEach((sec) => {
            const sectionObject = { name: '', questions: [] };
            const sectionName = Object.keys(sec)[0];
            sectionObject.name = sectionName;
            sec[sectionName].forEach((qId) => {
              const questionFound = questions.find((q) => q?.id === qId);
              if (questionFound) {
                sectionObject.questions.push(questionFound);
              }
            });
            parsedResponse.push(sectionObject);
          });
          setLoadingDrawer(false);
          setQuestionPaperData(parsedResponse);
        } else {
          setLoadingDrawer(false);
          message.error(result.data.message);
        }
      })
      .catch((error) => {
        setLoadingDrawer(false);
        message.error(error.message);
      })
      .finally(() => {
        setDrawerVisible(true);
        setLoadingDrawer(false);
      });
  };

  const openQpDrawer = (id) => {
    fetchQuestionData(id);
  };

  const closeQpDrawer = () => {
    setDrawerVisible(false);
  };

  const [openModal, setOpenModal] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState();

  const deleteqp = () => {
    setWholeQuestion('');
    setQuestionPaperIds(null);
    deleteQPfunc();
  };
  const deleteQPfunc = async () => {
    await handleCreateLessonPlan();
  };

  return (
    <div className='uploaded-files-container'>
      <div className='file-upload-container'>
        {selectedContentType !== 'Question Paper' ? (
          <DNDFileUpload
            value={currentFile}
            handleChange={onChange}
            fileType={acceptedFileTypes}
            typeNames={acceptedTypes}
            selectedContentType={selectedContentType}
          />
        ) : (
          <div className='form-grid-container mv-20'>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12}>
                <FormControl fullWidth variant='outlined'>
                  <Autocomplete
                    // multiple
                    style={{ width: '100%' }}
                    id='question_paper'
                    name='question_paper'
                    onChange={handleQuestionPaper}
                    className='dropdownIcon'
                    size='small'
                    value={wholeQuestion || []}
                    options={questionPapers || []}
                    getOptionLabel={(option) => option.paper_name || ''}
                    // getOptionSelected={(option, value) => option?.id == value?.id}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='outlined'
                        label='Question Papers'
                        placeholder='Question Papers'
                      />
                    )}
                  />
                  {qpname ? (
                    <div className='bodyContent'>
                      <div className='m-2'>{qpname}</div>
                      <a
                        className='m-2'
                        onClick={() => {
                          // handleDisplayQuestionId(questionPaperIds);
                          openQpDrawer(questionPaperIds);
                        }}
                      >
                        <SvgIcon component={() => <VisibilityIcon />} />
                      </a>
                      <a
                        onClick={() => {
                          setConfirmMessage('delete');
                          setOpenModal(true);
                        }}
                        className='m-2'
                      >
                        <SvgIcon component={() => <DeleteForeverIcon />} />
                      </a>

                      <ConfirmPopOver
                        submit={() => deleteqp()}
                        openModal={openModal}
                        setOpenModal={setOpenModal}
                        operation={confirmMessage}
                        opendelete={true}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  <Drawer
                    title={<span className='th-fw-500'>{'Question Paper'}</span>}
                    placement='right'
                    onClose={closeQpDrawer}
                    zIndex={1400}
                    open={drawerVisible}
                    width={'450px'}
                    closable={false}
                    className='th-resources-drawer'
                    extra={
                      <Space>
                        <CloseOutlined onClick={closeQpDrawer} />
                      </Space>
                    }
                  >
                    {loadingDrawer ? (
                      <div className='text-center mt-5'>
                        <Spin tip='Loading...' />
                        loading
                      </div>
                    ) : (
                      <QuestionPaperView questionData={questionPaperData} />
                    )}
                  </Drawer>
                  <Dialog
                    fullScreen
                    open={openQuestion}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                  >
                    <AppBar
                      sx={{ position: 'relative' }}
                      style={{ marginTop: '80px', background: '#ff6b6b' }}
                    >
                      <Toolbar>
                        <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
                          {questionId?.paper_name}
                        </Typography>
                        <Button autoFocus color='inherit' onClick={handleClose}>
                          Back
                        </Button>
                      </Toolbar>
                    </AppBar>
                    <List>
                      <div style={{ width: '95%', margin: '20px auto' }}>
                        {/* <Button onClick={handleClose}>back</Button> */}
                        {questionData?.question_details?.map((q) =>
                          q.question_answer?.map((qd) => (
                            <div style={{ padding: '10px' }}>
                              <Card sx={{ minWidth: 275 }}>
                                <CardContent>
                                  <Typography
                                    sx={{ fontSize: 14 }}
                                    color='text.secondary'
                                    gutterBottom
                                  >
                                    <div
                                      style={{
                                        color: 'black',
                                        padding: '10px',
                                        fontSize: '15px',
                                      }}
                                      // dangerouslySetInnerHTML={{ __html: qd.question }}
                                    >
                                      {ReactHtmlParser(qd?.question)}
                                    </div>
                                  </Typography>
                                </CardContent>
                                <CardContent>
                                  <Typography
                                    sx={{ fontSize: 14 }}
                                    color='text.secondary'
                                    gutterBottom
                                  >
                                    {qd?.options ? (
                                      <Grid item xs={12} sm={12}>
                                        <Grid container spacing={isMobile ? 3 : 5}>
                                          {Array.isArray(qd?.options) &&
                                            qd?.options.length > 0 &&
                                            qd?.options.map((opitem, opindex) => (
                                              <Grid
                                                item
                                                xs={3}
                                                style={
                                                  isMobile ? { marginLeft: '-8px' } : null
                                                }
                                                sm={3}
                                              >
                                                Option {opindex + 1} .
                                                {/* {qd?.options[0]?.option1?.optionValue} */}
                                                {ReactHtmlParser(
                                                  opitem[`option${opindex + 1}`]
                                                    .optionValue
                                                )}
                                                <br />
                                              </Grid>
                                            ))}

                                          {/* <Grid
                                            item
                                            xs={3}
                                            style={
                                              isMobile ? { marginLeft: '-8px' } : null
                                            }
                                            sm={3}
                                          >
                                            Option 1:{' '}
                                            {qd?.options[0]?.option1?.optionValue}
                                            <br />
                                            
                                          </Grid>
                                          <Grid
                                            item
                                            xs={3}
                                            style={
                                              isMobile ? { marginLeft: '-8px' } : null
                                            }
                                            sm={3}
                                          >
                                            Option 2:{' '}
                                            {qd?.options[1]?.option2?.optionValue}
                                            <br />
                                            <br />
                                            
                                          </Grid>
                                          <Grid
                                            item
                                            xs={3}
                                            style={
                                              isMobile ? { marginLeft: '-8px' } : null
                                            }
                                            sm={3}
                                          >
                                            Option 3:
                                            {qd?.options[2]?.option3?.optionValue}
                                            <br />
                                            <br />
                                            
                                          </Grid>
                                          <Grid
                                            item
                                            xs={3}
                                            style={
                                              isMobile ? { marginLeft: '-8px' } : null
                                            }
                                            sm={3}
                                          >
                                            Option 4:
                                            {qd?.options[3]?.option1?.optionValue}
                                            <br />
                                            <br />
                                            
                                          </Grid> */}
                                        </Grid>
                                      </Grid>
                                    ) : (
                                      ''
                                    )}
                                  </Typography>
                                </CardContent>
                                <CardActions>
                                  <Button size='small'>Answer : {qd.answer} </Button>
                                </CardActions>
                              </Card>

                              {/* {qd.question} */}
                            </div>
                          ))
                        )}
                      </div>
                    </List>
                  </Dialog>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
      <div className='uploaded-files-list-container'>
        {loading ? (
          <div style={{ padding: '1rem', display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </div>
        ) : (
          value?.map((file) => {
            return (
              <div className='uploaded-file'>
                <div className='name' title={file}>
                  {(file || '-/No file').split('/')?.splice(-1)[0]}
                  {/* {file?.document_type == 'Homework'
                    ? file?.url[0]?.split('/')?.splice(-1)[0]
                    : file.split('/')?.splice(-1)[0]} */}
                </div>
                <div className='size'>--</div>
                <div className='actions'>
                  <IconButton size='small'>
                    <VisibilityIcon
                      color='primary'
                      fontSize='small'
                      onClick={() => {
                        openPreview({
                          currentAttachmentIndex: 0,
                          attachmentsArray: [
                            {
                              src: getS3DomainURL(file, selectedContentType?.name),
                              name: `${selectedContentType?.name}`,
                              extension:
                                '.' + file?.split('.')[file?.split('.').length - 1],
                            },
                          ],
                        });
                      }}
                    />
                  </IconButton>
                  <IconButton
                    size='small'
                    onClick={() => {
                      setDeleteFile(file);
                      setDeleteDialogeOpen(true);
                    }}
                  >
                    <DeleteIcon color='primary' fontSize='small' />
                  </IconButton>
                  <Dialog
                    open={deleteDialogeOpen}
                    onClose={() => {
                      setDeleteDialogeOpen(false);
                    }}
                  >
                    <DialogContent>
                      <Typography>{`Are you sure you want to delete ?`}</Typography>
                    </DialogContent>
                    <DialogActions>
                      <StyledCancelButton
                        variant='contained'
                        onClick={() => {
                          setDeleteFile(null);
                          setDeleteDialogeOpen(false);
                        }}
                      >
                        Cancel
                      </StyledCancelButton>
                      <StyledButton
                        variant='contained'
                        onClick={() => {
                          onDelete(deleteFile);
                          setDeleteDialogeOpen(false);
                        }}
                      >
                        Confirm
                      </StyledButton>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            );
          })
        )}
      </div>

      <div className='btn-container'>
        {/* <Button
          className={`outlined-btn ${!currentFile || loading ? 'disabled-btn' : ''}`}
          onClick={onSave}
        >
          Save And Submit
        </Button>
        <Button onClick={onSubmit}>Submit</Button> */}
        {currentFile ? (
          <Button
            className={`outlined-btn ${loading ? 'disabled-btn' : ''}`}
            onClick={onSave}
            variant='contained'
            color='secondary'
          >
            {/* Save And Submit */}
            Save
            {/* On click of save, it submits as well */}
          </Button>
        ) : (
          <Button
            variant='contained'
            color='secondary'
            className={`outlined-btn ${loading ? 'disabled-btn' : ''}`}
            onClick={!loading && onSubmit}
          >
            Save
          </Button>
        )}
        <Button
          className={`outlined-btn ${loading ? 'disabled-btn' : ''}`}
          color='secondary'
          onClick={onDiscard}
        >
          Discard
        </Button>
      </div>
    </div>
  );
};
export default AttachmentList;
