import React , { useContext, useState } from 'react';
import { Grid, TextField, Button, useTheme, Switch, FormControlLabel, InputAdornment,IconButton} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import endpoints from '../../config/endpoints';
import axiosInstance from '../../config/axios';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { AlertNotificationContext } from '../../context-api/alert-context/alert-state';

const CreateLicense = ({setLoading,handleGoBack}) => {

  const { setAlert } = useContext(AlertNotificationContext);
  const [userName,setUsername]=useState('')
  const [password,setPassword]=useState('')
  const [zoomUserId,setZoomUserId]=useState('')
  const [email,setEmail]=useState('')
  const [limit,setLimit]=useState()
  const [optional,setOptional] = useState(false)
  const emailValidator=/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    
  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));

  const handleChange = (event) => {
    setOptional(event.target.checked)
  }

  const [showPassword, setShowPassword] = useState(false)
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  };



  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true);
      axiosInstance.post(endpoints.zoom.createLicense,{
          "username":userName,
          "password":password,
          "zoom_user_id":zoomUserId,
          "email":email,
          "webinar_enabled":optional,
          "limit":limit
      }).then(result=>{
      if (result.data.status_code === 200) {
        setUsername('')
        setPassword('')
        setZoomUserId('')
        setEmail('')
        setLimit('')
        setOptional(false)
        setLoading(false)
        setAlert('success', result.data.message);
      } else {
        setLoading(false);
        setAlert('error',result.data.message)
      }
      }).catch((error)=>{
        setLoading(false);
        setAlert('error', error.response.data.message);
      })
    };

  return (
      <form autoComplete='off' onSubmit={handleSubmit}>
      <div style={{ width: '95%', margin: '20px auto'}}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4} className={isMobile?'':'addEditPadding'}>
            <TextField
              id='username'
              style={{ width: '100%' }}
              label='Username'
              variant='outlined'
              size='small'
              value={userName}
              autoComplete='off'
              inputProps={{maxLength:20}}
              name='username'
              onChange={e=>setUsername(e.target.value)}
              required
            />
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4} className={isMobile?'':'addEditPadding'}>
            <TextField
              id='password'
              style={{ width: '100%' }}
              label='Password'
              variant='outlined'
              size='small'
              value={password}
              autoComplete='off'
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
              }}
              inputProps= {{maxLength:20}}
              name='username'
              onChange={e=>setPassword(e.target.value)}
              required
            />
          </Grid>
        </Grid>

        <Grid container spacing={5}>
          <Grid item xs={12} sm={4} className={isMobile?'':'addEditPadding'}>
            <TextField
              id='email'
              style={{ width: '100%' }}
              label='Email'
              variant='outlined'
              size='small'
              value={email}
              autoComplete='off'
              inputProps={{ type:"email",pattern:"^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$",maxLength:50}}
              title='Ex: john.doe@gmail.com'
              name='email'
              onChange={e=>setEmail(e.target.value)}
              required
            />
          </Grid>
        </Grid>

        <Grid container spacing={5}>
          <Grid item xs={12} sm={4} className={isMobile?'':'addEditPadding'}>
            <TextField
              id='zoomuserid'
              style={{ width: '100%' }}
              label='Zoom User Id'
              variant='outlined'
              size='small'
              value={zoomUserId}
              autoComplete='off'
              name='zoomuserid'
              onChange={e=>setZoomUserId(e.target.value)}
              required
            />
          </Grid>
        </Grid>

        <Grid container spacing={5}>
          <Grid item xs={12} sm={4} className={isMobile?'':'addEditPadding'}>
            <TextField
              id='limit'
              style={{ width: '100%' }}
              label='Limit'
              variant='outlined'
              size='small'
              value={limit}
              autoComplete='off'
              type='number'
              InputProps={{ inputProps: { min: 0}}}
              name='limit'
              onChange={e=>setLimit(e.target.value)}
              required
            />
          </Grid>
        </Grid>
     
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4}>
            <FormControlLabel
              className='switchLabel'
              control={
                <Switch 
                checked={optional} 
                onChange={handleChange} 
                name="optional" 
                color="primary"
                />}
                label={optional?'Webinar-Enabled':'Webinar-Disabled'}
              />
          </Grid>
        </Grid>
       
       
        </div>
        <Grid container spacing={isMobile?1:5} style={{ width: '95%', margin: '10px'}} >
        <Grid item xs={6} sm={2} className={isMobile?'':'addEditButtonsPadding'}>
            <Button variant='contained' className="custom_button_master labelColor" size='medium' onClick={handleGoBack}>
              Back
            </Button>
          </Grid>
          <Grid item xs={6} sm={2} className={isMobile?'':'addEditButtonsPadding'}> 
            <Button variant='contained' style={{color:'white'}} color ="primary" className="custom_button_master" size='medium' type='submit'>
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
  );
};

export default CreateLicense;
