import React, { useState, useEffect, useContext } from 'react';
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  TextField,
  Button,
  SvgIcon,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { connect } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import cuid from 'cuid';
import { useLocation } from 'react-router-dom';
import Layout from '../../Layout';
import CommonBreadcrumbs from '../../../components/common-breadcrumbs/breadcrumbs';
import { fetchGrades, fetchSubjects } from '../../lesson-plan/create-lesson-plan/apis';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import './styles.scss';
import QuestionPaper from './question-paper';
import {
  addSection,
  setIsFetched,
  createQuestionPaper,
  editQuestionPaper,
  setFilter,
  resetState,
  deleteSection,
  deleteQuestionSection,
  addQuestionToSection,
} from '../../../redux/actions';
import endpoints from '../../../config/endpoints'
import axiosInstance from '../../../config/axios';
const levels = [
  { id: 1, name: 'Easy' },
  { id: 2, name: 'Average' },
  { id: 3, name: 'Difficult' },
];

const CreateQuestionPaper = ({
  sections,
  isFetched,
  initAddSection,
  setIsFetched,
  initCreateQuestionPaper,
  initEditQuestionPaper,
  initSetFilter,
  selectedGrade,
  selectedSubject,
  selectedLevel,
  questionPaperName,
  initResetState,
  initDeleteSection,
  deleteQuestionSection,
  initAddQuestionToSection,
  selecteduserLevel,
  questions,
}) => {
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [showQuestionPaper, setShowQuestionPaper] = useState(
    query.get('show-question-paper') || true
  );
  const [expandFilter, setExpandFilter] = useState(true);
  const themeContext = useTheme();
  const [filterData, setFilterData] = useState()
  const { setAlert } = useContext(AlertNotificationContext);

  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const { refresh = false } = history.location?.state || {};
  const validationSchema = Yup.object({
    grade: Yup.object('').required('Required').nullable(),
    subject: Yup.object('').required('Required').nullable(),
  });

  const [userLevelDropdown , setUserLevelDropdown] = useState([])
  const [userLevel , setUserLevel] = useState([])
  console.log(selectedGrade);

  useEffect(() => {
    if (refresh) {
      setIsFetched(false)
    }
  }, [refresh])

  // useEffect(() => {
  //   return () => {
  //     handleResetQuestionPaper();
  //   }
  // }, [])

  const formik = useFormik({
    initialValues: {
      grade: selectedGrade,
      subject: selectedSubject,
      question_paper_level: selectedLevel,
      userLevel : selecteduserLevel
    },
    onSubmit: (values) => {
      setShowQuestionPaper(true);
    },
    validateOnChange: false,
    validateOnBlur: false,
  });
  useEffect(() => {
    if (Number(location.pathname.slice(23))  && !isFetched ) {
      handleResetQuestionPaper()
      handleFetch();
    }
  }, [])

  useEffect(() => {
    if (formik.values.grade) {
      getSubjects(formik.values.grade.id);
    } else {
      setSubjects([]);
    }
  }, [formik.values.grade]);

  useEffect(() => {
    getGrades();
    getUserLevel();
  }, []);

  const getGrades = async () => {
    try {
      const data = await fetchGrades();
      setGrades(data);
    } catch (e) {
      setAlert('error', 'Failed to fetch grades');
    }
  };

  const getSubjects = async (gradeId) => {
    try {
      const data = await fetchSubjects(gradeId);
      setSubjects(data);
    } catch (e) { }
  };

const getUserLevel = () => {
    // setLoading(true)
    axiosInstance.get(`${endpoints.user.getUserLevel}`)
        .then((result) => {
            // setLoading(false)
            setUserLevelDropdown(result.data.result)
        })
        .catch((error) => {
            // setLoading(false)
            setAlert('error', 'failed to fetch');
        })
}

  const handleFetch = () => {
    setIsFetched(true);
    let data = Number(location.pathname.slice(23));
    const url = endpoints.assementQP?.assementViewmore.replace(
      '<question-paper-id>',
      data
    );
    axiosInstance
      .get(url)
      .then((result) => {
        if (result.data.status_code === 200) {
          console.log(result);
          setUserLevel(result?.data?.result?.access_usr_lvl)
          setFilterData(result?.data?.result?.assessment_details)
          initSetFilter('questionPaperName', result?.data?.result?.paper_name);
          const { questions: responseQuestions = [], sections: responseSections = [] } =
            result.data.result || {};
          handleTransformResponse(responseQuestions, responseSections); //for edit question-paper
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });
  };

  useEffect(() => {
    console.log(grades);
    if (filterData?.grade) {
      let gradeVal = [...grades].filter((i) => filterData?.grade == i?.id)
      console.log(gradeVal);
      handleGrade(filterData, gradeVal[0])
    }
  }, [grades, filterData])

  useEffect(() => {
if(userLevel.length && userLevelDropdown.length){
let level = userLevelDropdown?.filter((item) => userLevel.includes(item?.id))
handleuserLevel('',level)
}

  },[userLevel.length , userLevelDropdown])

  useEffect(() => {
    if(filterData?.subject){
      let subjectVal = [...subjects].filter((i) => filterData?.subject.includes(i?.subject?.id))
      console.log(subjectVal);
      handleSubject(filterData ,subjectVal)
    }
    if(filterData?.paper_level){
      let levelVal = [...levels].filter((i) => filterData?.paper_level == i?.id)
      handleLevel(filterData?.paper_level,levelVal[0])
    }
  },[formik?.grade , subjects])

  const handleTransformResponse = (responseQuestions, responseSections) => {
    responseSections.forEach((section, index) => {
      const sectionId = cuid();
      const key = String.fromCharCode(65 + index);
      const questionList = [];
      section[key].forEach((sectionIdentifier) =>
        responseQuestions.forEach((question) => {
          if (sectionIdentifier === question?.id) questionList.push(question);
        })
      );
      const sectionArray = [
        {
          id: cuid(),
          name: key,
          questions: questionList,
        },
      ];
      const sectionObject = { id: sectionId, sections: sectionArray };
      initAddSection(sectionObject);
    });
  };

  const handleuserLevel = (e,value) => {
if(value.length > 0){
let userLevelIds = value?.map((item) => item?.id)
  setUserLevel(userLevelIds)
  formik.setFieldValue('userLevel' ,value )
  initSetFilter('userLevel', value);

}else{
  setUserLevel([])
  formik.setFieldValue('userLevel' ,[] )
  initSetFilter('userLevel', [])
}
  }


  const handleAddSection = () => {
    let len = sections?.length || 0;
    const sectionArray = [
      {
        id: cuid(),
        name: `${String.fromCharCode(65 + len)}`,
        questions: [],
      },
    ];
    const question = { id: cuid(), sections: sectionArray };
    initAddSection(question);
  };

  // const handleAddQuestion = (noOfSections) => {
  //   let len = questions?.length || 0;
  //   const sections = [
  //     {
  //       id: cuid(),
  //       name: `${String.fromCharCode(65 + len)}`,
  //       questions: [],
  //     },
  //   ];
  //   const question = { id: cuid(), sections };
  //   initAddQuestion(question);
  // };

  const handleClearFilters = () => {
    formik.handleReset();
  };
  const handleSubmitPaper = (e) => {
    if (Number(location.pathname.slice(23))) {
      handleEditQuestionPaper(e);
    } else handleCreateQuestionPaper(e);
  };
  const handleResetQuestionPaper = () => {
    handleClearFilters();
    initResetState();
    setFilterData()
  };

  const handleDeleteSection = (questionId, section) => {
    initDeleteSection(questionId, section);
  };
  const handleEditQuestionPaper = async (isDraft) => {
    // try {
    const questionData = [];
    const sectionData = [];
    sections.forEach((q) => {
      q.sections.forEach((sec) => {
        const sectionObj = { [sec.name]: [], discription: sec.name };
        sec.questions.forEach((question) => {
          sectionObj[sec.name].push(question.id);
          if (!questionData.includes(question.id)) {
            questionData.push(question.id, question.child_id);
          }
        });
        sectionData.push(sectionObj);
      });
    });

    let gradeMap = [];
    let sectionMap = [];
    const sectionMapObj = [];

    sections.forEach((q) => {
      q.sections.forEach((sec, i) => {
        sec.questions.forEach((question) => {
          sectionMapObj.push(question.grade_subject_mapping);
          if (!sectionMapObj.includes(question?.grade_subject_mapping)) {
            sectionMapObj.push(question?.grade_subject_mapping);
          }
        });
        sectionMap.push(sectionMapObj);
      });
    });
    console.log(sectionMapObj);

    console.log(isDraft);
    const reqObj = {
      branch: 1,
      paper_name: questionPaperName,
      grade: formik.values.grade?.id,
      grade_name: formik.values.grade?.grade_name,
      grade_subject_mapping: sectionMapObj,
      subject: formik.values.subject.map((obj) => obj.subject.id),
      subject_name: formik.values.subject.map((obj) => obj.subject.subject_name),
      paper_level: formik.values.question_paper_level.id,
      question: questionData.flat(),
      section: sectionData,
      sections: sectionData,
      is_verified: 'False',
      access_usr_lvl : userLevel
    };

    if (isDraft == true) {
      reqObj.is_draft = 'True';
      reqObj.is_review = 'False';
    } else {
      reqObj.is_review = 'True';
      reqObj.is_draft = 'False';
    }

    let sectionFlag = true,
      sectionName = '';

    for (let k = 0; k < sectionData.length; k++) {
      const sectionObj = sectionData[k];
      if (sectionObj[String.fromCharCode(65 + k)].length === 0) {
        sectionName = String.fromCharCode(65 + k);
        sectionFlag = false;
        break;
      }
    }

    let submitArray = {
      Section: sectionFlag,
      'Question Paper Name': questionPaperName,
      'Question Level': formik.values.question_paper_level.id,
      Subject: formik.values.subject.length,
      Grade: formik.values.grade.id,
      userLevel : userLevel.length
    };

    let finalSubmitFlag =
      Object.entries(submitArray).every(([key, value]) => value) && sectionData.length;

    if (finalSubmitFlag) {
      await initEditQuestionPaper(reqObj, location.pathname.slice(23));
      history.push('/assessment-question');
      setAlert('success', 'Question Paper Updated successfully');
      handleResetQuestionPaper();
    } else {
      // const checkSectionLength = isDraft ? true : sectionData.length;
      const checkSectionLength = sectionData.length;
      if (checkSectionLength) {
        for (const [key, value] of Object.entries(submitArray)) {
          if (key === 'Section' && !Boolean(value))
            setAlert('error', `Please add questions for Section-${sectionName}`);
          else if (!Boolean(value)) setAlert('error', `${key} can't be empty!`);
        }
      } else {
        setAlert('error', 'At least one section is compulsory!');
      }
    }
    // } catch (e) {
    //   setAlert('error', 'Failed to Update Question Paper');
    // }
  };
  const handleCreateQuestionPaper = async (isDraft) => {
    try {
      const questionData = [];
      const sectionData = [];
      sections.forEach((q) => {
        q.sections.forEach((sec) => {
          const sectionObj = { [sec.name]: [], discription: sec.name };
          sec.questions.forEach((question) => {
            sectionObj[sec.name].push(question.id);
            if (!questionData.includes(question.id)) {
              questionData.push(question.id, question.child_id);
            }
          });
          sectionData.push(sectionObj);
        });
      });
      const reqObj = {
        branch: 1,
        paper_name: questionPaperName,
        grade: formik.values.grade.id,
        grade_name: formik.values.grade.grade_name,
        grade_subject_mapping: formik.values.subject.map((obj) => obj.id),
        subject_name: formik.values.subject.map((obj) => obj.subject.subject_name),
        paper_level: formik.values.question_paper_level.id,
        question: questionData.flat(),
        subject: formik.values.subject.map((obj) => obj.subject.id),
        access_usr_lvl : formik.values.userLevel?.map((item) => item?.id),

        // section: [
        //   {
        //     section: sectionData,
        //   },
        // ],
        section: sectionData,
        sections: sectionData,
      };

      if (isDraft == false) {
        reqObj.is_draft = 'False';
        reqObj.is_review = 'True';
      } else {
        reqObj.is_draft = 'True';
        reqObj.is_review = 'False';
      }

      let sectionFlag = true,
        sectionName = '';

      for (let k = 0; k < sectionData.length; k++) {
        const sectionObj = sectionData[k];
        if (sectionObj[String.fromCharCode(65 + k)].length === 0) {
          sectionName = String.fromCharCode(65 + k);
          sectionFlag = false;
          break;
        }
      }

      let submitArray = {
        Section: sectionFlag,
        'Question Paper Name': questionPaperName,
        'Question Level': formik.values.question_paper_level.id,
        Subject: formik.values.subject.length,
        Grade: formik.values.grade.id,
        userLevel : userLevel.length
      };

      // if (!isDraft) {
      //   submitArray['Section'] = sectionFlag;
      // }

      // Object.values(submitArray).every(Boolean);

      // let finalSubmitFlag = isDraft
      //   ? Object.entries(submitArray).every(([key, value]) => value)
      //   : Object.entries(submitArray).every(([key, value]) => value) &&
      //     sectionData.length;

      let finalSubmitFlag =
        Object.entries(submitArray).every(([key, value]) => value) && sectionData.length;

      if (finalSubmitFlag) {
        await initCreateQuestionPaper(reqObj);
        history.push('/assessment-question');
        setAlert('success', 'Question Paper created successfully');
        handleResetQuestionPaper();
      } else {
        // const checkSectionLength = isDraft ? true : sectionData.length;
        const checkSectionLength = sectionData.length;
        if (checkSectionLength) {
          for (const [key, value] of Object.entries(submitArray)) {
            if (key === 'Section' && !Boolean(value))
              setAlert('error', `Please add questions for Section-${sectionName}`);
            else if (!Boolean(value)) setAlert('error', `${key} can't be empty!`);
          }
        } else {
          setAlert('error', 'At least one section is compulsory!');
        }
      }
    } catch (e) {
      setAlert('error', 'Failed to create Question Paper');
    }
  };

  const handleGrade = (e, value) => {
    console.log(value);
    formik.setFieldValue('grade', value);
    initSetFilter('selectedGrade', value);
  }

  

  const handleSubject = (e, value) => {
    formik.setFieldValue('subject', value);
    initSetFilter('selectedSubject', value);
  }

  const handleLevel = (e , value) => {
    formik.setFieldValue('question_paper_level', value);
    initSetFilter('selectedLevel', value);
  }

  return (
    <Layout>
      <div className='create-question-paper-container'>
        <div>
          <CommonBreadcrumbs
            componentName='Assesment'
            childComponentName='Question paper'
            childComponentNameNext={Number(location.pathname.slice(23)) ? 'Edit Question Paper' : 'Create new'}
          />
        </div>
        <div className='content-container'>
          <Accordion
            className='collapsible-section'
            square
            expanded={expandFilter}
            onChange={() => { }}
          >
            <AccordionSummary>
              <div className='header mv-20'>
                {!expandFilter ? (
                  <IconButton
                    onClick={() => {
                      setExpandFilter(true);
                    }}
                  >
                    {!isMobile && (
                      <Typography
                        component='h4'
                        color='secondary'
                        style={{ marginRight: '5px' }}
                      >
                        Expand Filter
                      </Typography>
                    )}
                    <FilterListIcon color='secondary' />
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => {
                      setExpandFilter(false);
                    }}
                  >
                    {!isMobile && (
                      <Typography
                        component='h4'
                        color='secondary'
                        style={{ marginRight: '5px' }}
                      >
                        Close Filter
                      </Typography>
                    )}
                    <FilterListIcon color='secondary' />
                  </IconButton>
                )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {/* <div className='form-grid-container mv-20'> */}
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth variant='outlined'>
                    <Autocomplete
                      id='grade'
                      name='grade'
                      className='dropdownIcon'
                      // onChange={(e, value) => {
                      //   console.log(value);

                      // }}
                      onChange={handleGrade}
                      value={formik.values.grade}
                      options={grades}
                      getOptionLabel={(option) => option.grade_name || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='outlined'
                          label='Grade'
                          placeholder='Grade'
                        />
                      )}
                      size='small'
                    />
                    <FormHelperText style={{ color: 'red' }}>
                      {formik.errors.grade ? formik.errors.grade : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth variant='outlined'>
                    <Autocomplete
                      id='subject'
                      name='subject'
                      // onChange={(e, value) => {
                      //   formik.setFieldValue('subject', value);
                      //   initSetFilter('selectedSubject', value);
                      // }}
                      onChange={handleSubject}
                      multiple
                      className='dropdownIcon'
                      value={formik.values.subject}
                      options={subjects}
                      getOptionLabel={(option) => option.subject?.subject_name || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='outlined'
                          label='Subject'
                          placeholder='Subject'
                        />
                      )}
                      size='small'
                    />
                    <FormHelperText style={{ color: 'red' }}>
                      {formik.errors.subject ? formik.errors.subject : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormControl fullWidth variant='outlined'>
                    <Autocomplete
                      id='question_paper_level'
                      name='question_paper_level'
                      // onChange={(e, value) => {
                       
                      // }}
                      onChange={handleLevel}
                      value={formik.values.question_paper_level}
                      options={levels}
                      className='dropdownIcon'
                      getOptionLabel={(option) => option.name || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='outlined'
                          label='Question Paper Level'
                          placeholder='Question Paper Level'
                        />
                      )}
                      size='small'
                    />
                    <FormHelperText style={{ color: 'red' }}>
                      {formik.errors.question_paper_level
                        ? formik.errors.question_paper_level
                        : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl fullWidth variant='outlined'>
                    <Autocomplete
                      multiple
                      id='userLevel'
                      name='userLevel'
                      onChange={handleuserLevel}
                      value={formik.values.userLevel}
                      options={userLevelDropdown}
                      className='dropdownIcon'
                      getOptionLabel={(option) => option.level_name || ''}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='outlined'
                          label='User Level'
                          placeholder='User Level'
                        />
                      )}
                      size='small'
                    />
                    <FormHelperText style={{ color: 'red' }}>
                      {formik.errors.question_paper_level
                        ? formik.errors.question_paper_level
                        : ''}
                    </FormHelperText>
                  </FormControl>
                </Grid>
                
              </Grid>
              {/* </div> */}
            </AccordionDetails>
          </Accordion>
          <div className='divider-container'>
            <Divider />
          </div>
          <div className='form-actions-container mv-20'>
            <div className='btn-container' style={{ marginRight: '20px' }}>
              <Button
                variant='contained'
                className='custom_button_master labelColor modifyDesign'
                style={{ borderRadius: '10px' }}
                onClick={() => { history.push('/assessment-question') 
                handleResetQuestionPaper()
              }}
              // onClick={() => {
              //   formik.handleReset();
              // }}
              >
                Back
              </Button>
            </div>
            <div className='btn-container'>
              <Button
                variant='contained'
                className='custom_button_master labelColor modifyDesign'
                style={{ borderRadius: '10px' }}
                onClick={() => {
                  formik.handleReset();
                  handleResetQuestionPaper();
                }}
              >
                CLEAR ALL
              </Button>
            </div>

            {/* <div className='btn-container '>
              <Button
                variant='contained'
                className=''
                color='primary'
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                FILTER
              </Button>
            </div> */}
          </div>
          {showQuestionPaper && (
            <QuestionPaper
              grade={formik.values.grade?.grade_name}
              subject={formik.values.subject
                .map((sub) => sub.subject.subject_name)
                .join(', ')}
              level={formik.values.question_paper_level?.name}
              sections={sections}
              handleAddSection={handleAddSection}
              onCreateQuestionPaper={handleSubmitPaper}
              // updateQuesionPaper=)}
              onChangePaperName={(e) =>
                initSetFilter('questionPaperName', e.target.value.replace(/\b(\w)/g, (s) => s.toUpperCase()))
              }
              questionPaperName={questionPaperName}
              onDeleteSection={handleDeleteSection}
              onDeleteQuestion={deleteQuestionSection}
            />
          )}
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  sections: state.createQuestionPaper.questions,
  isFetched: state.createQuestionPaper.isFetched,
  selectedGrade: state.createQuestionPaper.selectedGrade,
  selecteduserLevel : state.createQuestionPaper.userLevel,
  selectedSubject: state.createQuestionPaper.selectedSubject,
  selectedLevel: state.createQuestionPaper.selectedLevel,
  questionPaperName: state.createQuestionPaper.questionPaperName,
});

const mapDispatchToProps = (dispatch) => ({
  initAddSection: (section) => {
    dispatch(addSection(section));
  },
  setIsFetched: (isFetched) => {
    dispatch(setIsFetched(isFetched));
  },
  initCreateQuestionPaper: (data) => dispatch(createQuestionPaper(data)),
  initEditQuestionPaper: (data, url) => dispatch(editQuestionPaper(data, url)),
  initSetFilter: (filter, data) => dispatch(setFilter(filter, data)),
  initResetState: () => dispatch(resetState()),
  initDeleteSection: (questionId, sectionId) =>
    dispatch(deleteSection(questionId, sectionId)),
  deleteQuestionSection: (questionId, sectionId) =>
    dispatch(deleteQuestionSection(questionId, sectionId)),
  initAddQuestionToSection: (question, questionId, section) => {
    dispatch(addQuestionToSection(question, questionId, section));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateQuestionPaper);
