const TypeRestricter = (value) => {
  if (['True or False', 8].includes(value)) {
    return [
      {
        id: 25,
        type_name: 'VSAQ',
        type: null,
      },
    ];
  } else if (
    [
      10,
      'Complete the analogy',
      'Rewrite the sentences',
      'Solve the following (Math)',
      'Map Based Questions',
      'Match the following',
      'Complete the Sentences',
      'Case-based Study',
      'Experiment based question (Science)',
      'Diagram based questions (Draw or Label)',
      'Answer from around 120 - 150 words as per the need of the subject',
      'Answer from around 80 - 150 words as per the need of the subject',
      'Solve the following (Math)',
      'Short answer around 25 words',
      'Complete the sequence/table',
      'Put the dialogue in the right order',
      'Differentiate the following',
      'Extract based questions',
      'Give Reasons / True or false with reason or correct answer',
      'Answer the following',
      'Define the following',
      'Name the following',
      'One word answers',
      'Rewrite the sentences',
      'Complete the Sentences',
    ].includes(value)
  ) {
    return [
      {
        id: 28,
        type_name: 'VLAQ',
        type: null,
      },
      {
        id: 27,
        type_name: 'LAQ',
        type: null,
      },
      {
        id: 26,
        type_name: 'SAQ',
        type: null,
      },
      {
        id: 25,
        type_name: 'VSAQ',
        type: null,
      },
    ];
  } else if (
    [
      'Mutiple single choice questions',
      1,
      'Mutiple single choice questions',
      'Single MCQ',
    ].includes(value)
  ) {
    return [
      {
        id: 24,
        type_name: 'MCQ',
        type: null,
      },
    ];
  } else if (['Multiple correct multiple choice question', 2].includes(value)) {
    return [
      {
        id: 24,
        type_name: 'MCQ',
        type: null,
      },
    ];
  } else if ([9, 'Fill in the Blanks'].includes(value)) {
    return [
      {
        id: 25,
        type_name: 'VSAQ',
        type: null,
      },
    ];
  } else if (['Comprehension', 7].includes(value)) {
    return [
      {
        id: 27,
        type_name: 'LAQ',
        type: null,
      },
    ];
  } else {
    return [
      {
        id: 28,
        type_name: 'VLAQ',
        type: null,
      },
      {
        id: 27,
        type_name: 'LAQ',
        type: null,
      },
      {
        id: 26,
        type_name: 'SAQ',
        type: null,
      },
      {
        id: 25,
        type_name: 'VSAQ',
        type: null,
      },
      {
        id: 24,
        type_name: 'MCQ',
        type: null,
      },
    ];
  }
};
export default TypeRestricter;
