import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

const FilterFilledIcon = () => {
  return (
    <SvgIcon width='18.025' height='18.01' viewBox='0 0 18.025 18.01'>
      <g transform='translate(0 -12.908)'>
        <g transform='translate(0 12.908)'>
          <path
            className='a'
            d='M17.267,12.908H0l6.579,6.931v9.392l4.11-2.126V19.839Z'
            transform='translate(0 -12.908)'
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default FilterFilledIcon;
