import React, { useContext } from 'react';
import './styles.scss';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {
  IconButton,
  useTheme,
  Button,
  Checkbox,
  Typography,
  Popover,
  MenuItem,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import endpoints from '../../../../config/endpoints';
import axiosInstance from '../../../../config/axios';
import { AlertNotificationContext } from '../../../../context-api/alert-context/alert-state';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

let periodData;
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
  subTitle: {
    fontSize: '1rem',
    textAlign: 'center',
    position: 'relative',
    '&:before': {
      position: 'absolute',
      top: '51%',
      overflow: 'hidden',
      width: '50%',
      height: '1px',
      content: '""',
      backgroundColor: '#ccc',
      marginLeft: '-51%',
    },
    '&:after': {
      position: 'absolute',
      top: '51%',
      overflow: 'hidden',
      width: '50%',
      height: '1px',
      content: '""',
      backgroundColor: '#ccc',
      marginLeft: '1%',
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  actionButtonContainer: {
    justifyContent: 'center',
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});
const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Period = ({
  period,
  name,
  isSelected,
  onClick,
  topicInfo,
  chapterInfo,
  periodId,
  setPeriodNames,
  getPeriodDetails,
  index,
  len
}) => {
  const { setAlert } = useContext(AlertNotificationContext);
  const [open, setOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const classes = useStyles();
  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const [periodName, setPeriodName] = React.useState(period?.period);
  const [periodSequence, setPeriodSequence] = React.useState(period?.sequence);
  const [openEdit, setOpenEdit] = React.useState(false);

  console.log('periodSequence', periodSequence, period);
  // if(periodIdInfo !== null || periodIdInfo !== undefined || periodIdInfo !== '' ){
  //   setOpenEdit(true);
  // }

  function onDelete(period) {
    // if (period?.period !== 'Period-1') {
    setIsDelete(true);
    // } else {
    //   setAlert('warning', 'Edit "Period-1" name');
    // }
  }

  function openPeriod(period) {
    setIsEdit(true);
    setPeriodName(period?.period);
    setPeriodSequence(period?.sequence);
  }

  function handleDeleteCancel() {
    setIsDelete(false);
  }

  function handleDeleteConfirm() {
    axiosInstance
      .delete(`${endpoints.periods.createUpdatePeriodName}${period.id}/`)
      .then((res) => {
        if (res.data.status_code === 200) {
          setIsDelete(false);
          setAlert('success', res.data.message);
          getPeriodDetails(topicInfo, chapterInfo);
        } else {
          setAlert('error', 'Something went wrong');
        }
      })
      .catch((error) => console.log(error));
  }

  function handleEditCancel() {
    setIsEdit(false);
  }

  function handleChange(value) {
    setPeriodName(value);
  }
  function handleEditConfirm() {
    let req = {
      period_old: period?.period,
      period_new: periodName,
      topic_id: topicInfo,
      chapter: chapterInfo,
      sequence: Number(periodSequence),
    };
    axiosInstance
      .put(`${endpoints.periods.createUpdatePeriodName}${period?.id}/`, req)
      .then((res) => {
        if (res.data.status_code === 200) {
          setIsEdit(false);
          setAlert('success', res.data.message);
          getPeriodDetails(topicInfo, chapterInfo);
          setPeriodName('');
          setPeriodSequence(period?.sequence);
        } else {
          setAlert('error', 'Something went wrong');
        }
      })
      .catch((error) => {
        setAlert('error', error.response.data.message);
      });
  }
  return (
    <>
      <TableRow
        className={`period ${isSelected ? 'active' : ''}`}
        onClick={onClick}
        style={{ cursor: 'pointer' }}
      >
        <TableCell component='th' scope='row'>
          {period?.sequence}
        </TableCell>
        <TableCell align='right'>{period?.period}</TableCell>
        <TableCell align='right'>
          {period?.id ? (
            <>
            {index == 0 && len == 1 ? "" : 
              <IconButton
                onClick={() => {
                  onDelete(period);
                }}
                title='Delete period'
                style={{ float: 'right' }}
              >
                <DeleteOutlinedIcon color='primary' size='small' />
              </IconButton> }
              <IconButton
                onClick={() => {
                  openPeriod(period);
                }}
                title='Edit period'
                style={{ float: 'right' }}
              >
                <EditOutlinedIcon color='primary' size='small' />
              </IconButton>
            </>
          ) : (
            ''
          )}
        </TableCell>
      </TableRow>
      <Dialog open={isEdit} onClose={handleEditCancel}>
        <DialogTitle id='draggable-dialog-title'>Edit Period</DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Are you sure you want to remove this section ?
          </DialogContentText> */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} className={isMobile ? '' : 'addEditPadding'}>
              <TextField
                id='period_name'
                label='Period Name'
                fullwidth
                variant='outlined'
                size='small'
                placeholder='Enter Period name'
                value={periodName}
                name='period_name'
                onChange={(e) => setPeriodName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} className={isMobile ? '' : 'addEditPadding'}>
              <TextField
                id='period_sequence'
                label='Period Sequence'
                fullwidth
                variant='outlined'
                size='small'
                placeholder='Enter Period Sequence'
                value={periodSequence}
                name='period_sequence'
                onChange={(e) => setPeriodSequence(e.target.value)}
                required
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditCancel} className='labelColor cancelButton'>
            Cancel
          </Button>
          <Button
            color='primary'
            variant='contained'
            style={{ color: 'white' }}
            onClick={handleEditConfirm}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isDelete} onClose={handleDeleteCancel}>
        <DialogTitle id='draggable-dialog-title'>Delete Period</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to remove this Period{' '}
            <Typography style={{ color: 'red' }}>{period?.period} ?</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} className='labelColor cancelButton'>
            Cancel
          </Button>
          <Button
            color='primary'
            variant='contained'
            style={{ color: 'white' }}
            onClick={handleDeleteConfirm}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const PeriodList = ({
  value,
  totalPeriods,
  onClick,
  periods,
  setPeriodsState,
  chapterInfo,
  topicInfo,
  periodId,
  setPeriodNames,
  getPeriodDetails,
}) => {
  const [editPeriod, setEditPeriod] = React.useState('');
  const onhandlePeriodName = (periodName) => {
    setEditPeriod(periodName);
  };

  return (
    <div className='periods-list-container'>
      <TableContainer component={Paper}>
        <Table size='small' stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              <TableCell>Sequence</TableCell>
              <TableCell align='left'>Name</TableCell>
              <TableCell align='center'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {periods?.map((period , index) => {
              const isSelected = period?.id === value?.id;
              return (
                <Period
                  period={period}
                  isSelected={isSelected}
                  periodId={periodId}
                  setPeriodNames={setPeriodNames}
                  getPeriodDetails={getPeriodDetails}
                  chapterInfo={chapterInfo}
                  topicInfo={topicInfo}
                  periodData={onhandlePeriodName}
                  onClick={() => onClick(period)}
                  index={index}
                  len={periods?.length}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PeriodList;
