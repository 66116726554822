import React, { useContext, useEffect, useState } from 'react';
import Divider from '@material-ui/core/Divider';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { Grid, TextField, Button, useTheme, SvgIcon } from '@material-ui/core';
import { AttachmentPreviewerContext } from '../../../components/attachment-previewer/attachment-previewer-contexts';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useHistory } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import download from '../../../assets/images/downloadAll.svg';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import endpoints from '../../../config/endpoints';
import axiosInstance from '../../../config/axios';
import './lesson.css';
import Loading from '../../../components/loader/loader';
import CloudUploadSharpIcon from '@material-ui/icons/CloudUploadSharp';
import { event } from 'jquery';

const LessonViewFilters = ({
  handlePeriodList,
  setPeriodData,
  setViewMore,
  setViewMoreData,
  setFilterDataDown,
  setSelectedIndex,
}) => {
  const { openPreview, closePreview } =
    React.useContext(AttachmentPreviewerContext) || {};
  const { setAlert } = useContext(AlertNotificationContext);
  const themeContext = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const wider = isMobile ? '-10px 0px' : '-10px 0px 20px 8px';
  const widerWidth = isMobile ? '98%' : '95%';
  const [overviewSynopsis, setOverviewSynopsis] = useState([]);
  const [academicYearDropdown, setAcademicYearDropdown] = useState([]);
  const [volumeDropdown, setVolumeDropdown] = useState([]);
  const [gradeDropdown, setGradeDropdown] = useState([]);
  const [subjectDropdown, setSubjectDropdown] = useState([]);
  const [boardDropdown, setBoardDropdown] = useState([]);
  const [chapterDropdown, setChapterDropdown] = useState([]);
  const [selectedBoardId, setSelectedBoardId] = useState([]);
  const [selectedModuleId, setSelectedModuleId] = useState([]);
  const [moduleDropdown, setModuleDropdown] = useState([]);
  const [selectedKeyConceptId, setSelectedKeyConceptId] = useState([]);
  const [keyConceptDropdown, setKeyConceptDropdown] = useState([]);
  const [loading, setLoading] = useState(false);

  const [filterData, setFilterData] = useState({
    year: '',
    volume: '',
    grade: '',
    subject: '',
    chapter: '',
    board: '',
    module: '',
    keyconcept: '',
  });

  const handleClear = () => {
    setFilterData({
      year: '',
      volume: '',
      grade: '',
      subject: '',
      chapter: '',
      board: '',
      module: '',
      keyconcept: '',
    });
    setPeriodData([]);
    setSubjectDropdown([]);
    setBoardDropdown([]);
    setChapterDropdown([]);
    setViewMoreData({});
    setViewMore(false);
    setFilterDataDown({});
    setOverviewSynopsis([]);
    setSelectedIndex(-1);
    setKeyConceptDropdown([]);
  };

  const handleAcademicYear = (event, value) => {
    setFilterData({ ...filterData, year: '' });
    if (value) {
      setFilterData({ ...filterData, year: value });
    }
  };

  const handleVolume = (event, value) => {
    setFilterData({
      ...filterData,
      volume: '',
      grade: '',
      subject: '',
      board: '',
      module: '',
      chapter: '',
    });
    if (value) {
      setFilterData({ ...filterData, volume: value });
    }
  };

  const handleGrade = (event, value) => {
    setLoading(true);
    setFilterData({
      ...filterData,
      grade: '',
      subject: '',
      chapter: '',
      board: '',
      module: '',
      chapter: '',
    });
    setOverviewSynopsis([]);
    if (value) {
      setLoading(true);
      setFilterData({ ...filterData, grade: value, subject: '', chapter: '' });
      axiosInstance
        .get(`${endpoints.lessonPlan.gradeSubjectMappingList}?grade=${value.id}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setSubjectDropdown(result.data.result.results);
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
            setSubjectDropdown([]);
            setChapterDropdown([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
          setSubjectDropdown([]);
          setChapterDropdown([]);
        });
    } else {
      setLoading(false);
      setSubjectDropdown([]);
      setChapterDropdown([]);
    }
  };

  const handleSubject = (event, value) => {
    setLoading(true);
    setFilterData({ ...filterData, subject: '', board: '', module: '', chapter: '' });
    setOverviewSynopsis([]);
    if (value) {
      setLoading(true);
      setFilterData({ ...filterData, subject: value });
      axiosInstance
        .get(`${endpoints.masterManagement.boardMappingList}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setBoardDropdown(result.data.result);
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
            setBoardDropdown([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
          setBoardDropdown([]);
        });
    } else {
      setLoading(false);
      setBoardDropdown([]);
    }
  };

  const handleBoard = (event = {}, values = []) => {
    setLoading(true);
    setFilterData({ ...filterData, board: '', module: '', chapter: '' });
    setOverviewSynopsis([]);
    if (values) {
      setLoading(true);
      const ids = values.map((el) => el);
      const selectedId = values.map((el) => el?.id);
      setSelectedBoardId(selectedId);
      setFilterData({ ...filterData, board: ids, chapter: '', module: '', keyconcept: '' });
      setChapterDropdown([])
      setKeyConceptDropdown([])
      axiosInstance
        .get(
          `${endpoints.masterManagement.module}?academic_year=${
            filterData?.year?.id
          }&volume=${filterData?.volume?.id}&grade_subject=${
            filterData?.subject?.id
          }&board=${selectedId}&page_size=${50}`
        )
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setModuleDropdown(result.data.result);
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
            setSelectedBoardId([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
          setSelectedBoardId([]);
        });
    } else {
      setLoading(false);
      setSelectedBoardId([]);
    }
  };

  const handleModule = (event = {}, values = []) => {
    setLoading(true);
    setFilterData({ ...filterData, module: '', chapter: '' });
    setOverviewSynopsis([]);
    if (values) {
      setLoading(true);
      // const ids = values.map((el) => el);
      // const selectedId = values.map((el) => el?.id)
      setSelectedModuleId(values);
      setFilterData({ ...filterData, module: values, chapter: '' });
      if (filterData.year && filterData.volume && values.length !== 0) {
        axiosInstance
          .get(
            `${endpoints.lessonPlan.chapterList}?grade_subject=${filterData.subject.id}&volume=${filterData.volume.id}&academic_year=${filterData.year.id}&board=${selectedBoardId}&lt_module=${values?.id}`
          )
          .then((result) => {
            if (result.data.status_code === 200) {
              setLoading(false);
              setChapterDropdown(result.data.result);
            } else {
              setLoading(false);
              setAlert('error', result.data.message);
              setChapterDropdown([]);
            }
          })
          .catch((error) => {
            setLoading(false);
            setAlert('error', error.message);
            setChapterDropdown([]);
          });
      }
    } else {
      setLoading(false);
      setChapterDropdown([]);
    }
  };

  const handleChapter = (event = {}, values = []) => {
    setLoading(true);
    setFilterData({ ...filterData, chapter: '', keyconcept: '' });
    setOverviewSynopsis([]);
    if (values) {
      setLoading(true);
      setSelectedKeyConceptId(values);
      setFilterData({ ...filterData, chapter: values, keyconcept: '' });
      if (filterData.year && filterData.volume && values.length !== 0) {
        axiosInstance
          .get(
            `${endpoints.masterManagement.createTopic}?chapter=${values?.id}&page_size=50`
          )
          .then((result) => {
            if (result.data.status_code === 200) {
              setLoading(false);
              setKeyConceptDropdown(result.data.result);
            } else {
              setLoading(false);
              setAlert('error', result.data.message);
              setKeyConceptDropdown([]);
            }
          })
          .catch((error) => {
            setLoading(false);
            setAlert('error', error.message);
            setKeyConceptDropdown([]);
          });
      }
    } else {
      setLoading(false);
      setChapterDropdown([]);
    }
  };
  const handleKeyConcept = (event, value) => {
    setFilterData({ ...filterData, keyconcept: '' });
    setOverviewSynopsis([]);
    if (value) {
      setFilterData({ ...filterData, keyconcept: value });
    }
  };

  const handleFilter = () => {
    setLoading(true);
    setSelectedIndex(-1);
    if (filterData.keyconcept) {
      handlePeriodList(filterData.chapter.id, filterData.keyconcept.id);
      setLoading(true);
      setFilterDataDown(filterData);
      axiosInstance
        .get(
          `${endpoints.lessonPlan.overviewSynopsis}?volume=${filterData.volume.id}&grade_subject_mapping_id=${filterData.subject.id}&academic_year_id=${filterData.year.id}`
        )
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setOverviewSynopsis(result.data.result);
          } else {
            setLoading(false);
            setOverviewSynopsis([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
        });
    } else {
      setLoading(false);
      setAlert('warning', 'Please Select All Filters');
      setFilterDataDown({});
    }
  };

  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`${endpoints.lessonPlan.academicYearList}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setLoading(false);
          setAcademicYearDropdown(result.data.result.results);
        } else {
          setLoading(false);
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.message);
      });

    axiosInstance
      .get(`${endpoints.lessonPlan.volumeList}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setLoading(false);
          setVolumeDropdown(result.data.result.results);
        } else {
          setLoading(false);
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.message);
      });

    axiosInstance
      .get(`${endpoints.lessonPlan.gradeList}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setLoading(false);
          setGradeDropdown(result.data.result.results);
        } else {
          setLoading(false);
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.message);
      });
  }, []);

  return (
    <Grid
      container
      spacing={isMobile ? 3 : 5}
      style={{ width: widerWidth, margin: wider }}
    >
      {loading ? <Loading message='Loading...' /> : null}
      <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
        <Autocomplete
          style={{ width: '100%' }}
          size='small'
          onChange={handleAcademicYear}
          id='academic-year'
          className='dropdownIcon'
          value={filterData.year}
          options={academicYearDropdown}
          getOptionLabel={(option) => option?.session_year}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              label='Academic Year'
              placeholder='Academic Year'
              required
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
        <Autocomplete
          style={{ width: '100%' }}
          size='small'
          onChange={handleVolume}
          id='volume'
          className='dropdownIcon'
          value={filterData.volume}
          options={volumeDropdown}
          getOptionLabel={(option) => option?.volume_name}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
            {...params}
            variant='outlined'
            label='Volume'
            placeholder='Volume'
            required
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
        <Autocomplete
          style={{ width: '100%' }}
          size='small'
          onChange={handleGrade}
          id='grade'
          className='dropdownIcon'
          value={filterData.grade}
          options={gradeDropdown}
          getOptionLabel={(option) => option?.grade_name}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField {...params} variant='outlined' label='Grade' placeholder='Grade' required  />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
        <Autocomplete
          style={{ width: '100%' }}
          size='small'
          onChange={handleSubject}
          id='subject'
          className='dropdownIcon'
          value={filterData.subject}
          options={subjectDropdown}
          getOptionLabel={(option) => option?.subject?.subject_name}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              label='Subject'
              placeholder='Subject'
              required 
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
        <Autocomplete
          multiple
          style={{ width: '100%' }}
          size='small'
          onChange={handleBoard}
          id='board'
          className='dropdownIcon'
          value={filterData.board || []}
          options={boardDropdown || []}
          getOptionLabel={(option) => option?.board_name || ''}
          // filterSelectedOptions
          getOptionSelected={(option, value) => option?.id == value?.id}
          renderInput={(params) => (
          <TextField {...params} variant='outlined' label='Board' placeholder='Board' required />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
        <Autocomplete
          // multiple
          style={{ width: '100%' }}
          size='small'
          onChange={handleModule}
          id='module'
          className='dropdownIcon'
          value={filterData.module || []}
          options={moduleDropdown || []}
          getOptionLabel={(option) => option?.lt_module_name || ''}
          filterSelectedOptions
          // getOptionSelected={(option, value) =>
          //     option?.id == value?.id
          //   }
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              label='Module'
              placeholder='Module'
              required
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
        <Autocomplete
          style={{ width: '100%' }}
          size='small'
          onChange={handleChapter}
          id='chapter'
          className='dropdownIcon'
          value={filterData.chapter}
          options={chapterDropdown}
          getOptionLabel={(option) => option?.chapter_name}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              label='Chapter'
              placeholder='Chapter'
              required
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={4} className={isMobile ? '' : 'filterPadding'}>
        <Autocomplete
          style={{ width: '100%' }}
          size='small'
          onChange={handleKeyConcept}
          id='keyConcept'
          className='dropdownIcon'
          value={filterData.keyconcept}
          options={keyConceptDropdown}
          getOptionLabel={(option) => option?.topic_name}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              label='KeyConcept'
              placeholder='KeyConcept'
              required
            />
          )}
        />
      </Grid>
      {overviewSynopsis?.map((obj) => (
        <Grid
          item
          xs={6}
          sm={overviewSynopsis?.length === 1 ? 4 : 2}
          className={isMobile ? '' : 'filterPadding'}
        >
          <a
            className='underlineRemove'
            onClick={() => {
              const fileSrc = `${endpoints.erps3}${obj?.media_file[0]}`;
              openPreview({
                currentAttachmentIndex: 0,
                attachmentsArray: [
                  {
                    src: fileSrc,
                    // name: `${obj.lesson_type === '1'?'Synopsis':'Overview'}`,
                    name: `${
                      obj.lesson_type === '1'
                        ? 'Portion Document'
                        : 'Yearly Curriculum on the ERP (new)'
                    }`,
                    extension: '.' + fileSrc.split('.')[fileSrc.split('.').length - 1],
                  },
                ],
              });
            }}
          >
            <div className='overviewSynopsisContainer'>
              <div className='overviewSynopsisTag'>
                {obj.lesson_type === '1'
                  ? 'Portion Document'
                  : 'Yearly Curriculum on the ERP (new)'}
              </div>
              <div className='overviewSynopsisIcon'>
                <SvgIcon
                  component={() => (
                    // <img
                    //     style={{ height: '25px', width: '25px' }}
                    //     src={download}
                    //     title={`Download ${obj.lesson_type === '1' ? 'Synopsis' : 'Overview'}`}
                    // />
                    <VisibilityIcon color='primary' />
                  )}
                />
              </div>
            </div>
          </a>
        </Grid>
      ))}
      {!isMobile && (
        <Grid item xs={12} sm={12}>
          <Divider />
        </Grid>
      )}
      {isMobile && <Grid item xs={3} sm={0} />}
      <Grid item xs={6} sm={2} className={isMobile ? '' : 'addButtonPadding'}>
        <Button
          variant='contained'
          className='custom_button_master labelColor modifyDesign'
          size='medium'
          onClick={handleClear}
        >
          CLEAR ALL
        </Button>
      </Grid>
      {isMobile && <Grid item xs={3} sm={0} />}
      {isMobile && <Grid item xs={3} sm={0} />}
      <Grid item xs={6} sm={2} className={isMobile ? '' : 'addButtonPadding'}>
        <Button
          variant='contained'
          style={{ color: 'white' }}
          color='primary'
          className='custom_button_master modifyDesign'
          size='medium'
          onClick={handleFilter}
        >
          FILTER
        </Button>
      </Grid>
      {isMobile && <Grid item xs={3} sm={0} />}
      {isMobile && <Grid item xs={3} sm={0} />}
      <Grid
        item
        xs={6}
        sm={2}
        className={isMobile ? 'createButton' : 'createButton addButtonPadding'}
      >
        <Button
          startIcon={<AddOutlinedIcon style={{ fontSize: '30px' }} />}
          variant='contained'
          style={{ color: 'white' }}
          color='primary'
          className='custom_button_master modifyDesign'
          onClick={() =>
            history.push({ pathname: '/lesson-plan/create', state: filterData })
          }
          size='medium'
        >
          CREATE
        </Button>
      </Grid>
      <Grid
        item
        xs={6}
        sm={2}
        className={isMobile ? 'createButton' : 'createButton addButtonPadding'}
      >
        <Button
          startIcon={<CloudUploadSharpIcon style={{ fontSize: '30px' }} />}
          variant='contained'
          style={{ color: 'white' }}
          color='primary'
          className='custom_button_master modifyDesign'
          onClick={() => history.push({ pathname: '/lesson-plan/createindices' })}
          size='medium'
        >
          Upload
        </Button>
      </Grid>
      {isMobile && <Grid item xs={3} sm={0} />}
    </Grid>
  );
};

export default LessonViewFilters;
