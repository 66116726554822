import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import '../assess-attemption.css';

const MatrixQuestion = (props) => {
  const { questionObj: currentQuestionObj } = props || {};

  const {
    question_answer: questionAnswer,
    user_response: { answer: existingAnswerObj = {} } = {},
  } = currentQuestionObj || {};

  const [{ options = [], question, matrixOptions }] = questionAnswer || {};

  return (
    <div>
      <div className='mcq-question-wrapper'>
        <div>{ReactHtmlParser(question)}</div>
        <div className='matrix-table-container'>
          <form>
            <table className='matrix-table'>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  {matrixOptions.map((matrix) => {
                    return <th>{matrix.optionValue}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                {options.map((option, optionIndex) => {
                  return (
                    <tr>
                      <td>{option.optionValue}</td>
                      {matrixOptions.map((matrix, matrixIndex) => {
                        const questionIndex = optionIndex + 1;
                        const name = `${questionIndex}_${option.optionValue}`;
                        const value = matrix.optionValue;
                        const { [`answer${questionIndex}`]: existingAns } =
                          existingAnswerObj || {};
                        return (
                          <td>
                            <input
                              type='radio'
                              checked={existingAns === value}
                              name={name}
                              value={value}
                            />
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </form>
        </div>
      </div>
    </div>
  );
};

export default MatrixQuestion;
