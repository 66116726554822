import React, { useContext, useState, useEffect } from 'react';
import { Grid, TextField, Button, useTheme, Divider } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import endpoints from '../../../config/endpoints';
import axiosInstance from '../../../config/axios';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import Autocomplete from '@material-ui/lab/Autocomplete';

const EditAcademicYear = ({ moduleData, handleGoBack, setLoading }) => {
  console.log({ moduleData, handleGoBack, setLoading }, 'hk9999');

  const { setAlert } = useContext(AlertNotificationContext);
  const [board_name, setBoard_name] = useState(moduleData?.lt_module_name || '');
  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const [academicYearDropdown, setAcademicYearDropdown] = useState([]);
  const [volumeDropdown, setVolumeDropdown] = useState([]);
  const [gradeDropdown, setGradeDropdown] = useState([]);
  const [subjectDropdown, setSubjectDropdown] = useState([]);
  const [boardDropdown, setBoardDropdown] = useState([]);
  const [gradeSubjectMapping, setGradeSubjectMapping] = useState('');
  const [filterData, setFilterData] = useState({
    year: moduleData?.academic_year,
    volume: moduleData?.volume,
    grade: moduleData?.grade_subject_mapping?.grade,
    subject: moduleData?.grade_subject_mapping?.subject,
    board: moduleData?.board,
  });
  const [moduleSequence, setModuleSequence] = useState(moduleData?.sequence);

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    let request = {};
    request['id'] = moduleData?.id;
    // if (board_name !== '' && board_name !== moduleData?.lt_module_name) {
    if (board_name !== '') {
      request['lt_module_name'] = board_name.trim();
      request['board'] = filterData?.board?.id;
      request['grade_subject_mapping'] = moduleData?.grade_subject_mapping?.id;
      request['academic_year'] = filterData?.year?.id;
      request['volume'] = filterData?.volume?.id;
      request['sequence'] = Number(moduleSequence);

      axiosInstance
        .put(
          `${endpoints.masterManagement.createUpdatemodule}${moduleData?.id}/`,
          request
        )
        .then((result) => {
          console.log(result);
          if (result.status === 200) {
            // setBoard_name('')
            setLoading(false);
            setAlert('success', result.data.message);
            handleGoBack();
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
            console.log(result.response);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.response.data.message);
        });
    } else {
      setLoading(false);
      setAlert('error', 'No Fields to Update');
    }
  };

  useEffect(() => {
    axiosInstance
      .get(`${endpoints.masterManagement.academicYear}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setAcademicYearDropdown(result.data.result.results);
          // handleAcademicYear('', result.data.current_acad_session_data[0])
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });

    axiosInstance
      .get(`${endpoints.masterManagement.volumes}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setVolumeDropdown(result.data.result.results);
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });

    axiosInstance
      .get(`${endpoints.masterManagement.grades}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setGradeDropdown(result.data.result.results);
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });
  }, []);

  const handleAcademicYear = (event, value) => {
    console.log(value);
    setFilterData({ ...filterData, year: '' });
    if (value) {
      setFilterData({ ...filterData, year: value });
    }
  };
  const handleVolume = (event, value) => {
    setFilterData({ ...filterData, volume: '' });
    if (value) {
      setFilterData({ ...filterData, volume: value });
    }
  };

  const handleGrade = (event, value) => {
    setFilterData({ ...filterData, grade: '' });
    if (value) {
      setFilterData({ ...filterData, grade: value });
      axiosInstance
        .get(`${endpoints.masterManagement.gradeSubjectMappingList}?grade=${value.id}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            setSubjectDropdown(result.data.result.results);
          } else {
            setAlert('error', result.data.message);
            setSubjectDropdown([]);
          }
        })
        .catch((error) => {
          setAlert('error', error.message);
          setSubjectDropdown([]);
        });
    } else {
      setSubjectDropdown([]);
    }
  };

  const handleSubject = (event, value) => {
    setFilterData({ ...filterData, subject: '' });
    if (value) {
      setFilterData({ ...filterData, subject: value });
      axiosInstance
        .get(
          `${endpoints.masterManagement.gradeSubjectMappingList}?grade=${filterData.grade?.id}&subject=${value?.subject?.subject_name}`
        )
        .then((response) => {
          if (response.data.status_code === 200) {
            console.log('response', response, filterData);
            setGradeSubjectMapping(response?.data?.result?.results[0]?.id);
          }
        });
      axiosInstance
        .get(`${endpoints.masterManagement.boardMappingList}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            setBoardDropdown(result.data.result);
            // console.log(result.data.result,'hargsy77777')
          } else {
            setAlert('error', result.data.message);
            setBoardDropdown([]);
          }
        })
        .catch((error) => {
          setAlert('error', error.message);
          setBoardDropdown([]);
        });
    } else {
      setBoardDropdown([]);
    }
  };

  const handleBoard = (event, value) => {
    setFilterData({ ...filterData, board: '' });
    if (value) {
      setFilterData({ ...filterData, board: value });
      // axiosInstance.get(`${endpoints.masterManagement.module}`)
      // .then(result => {
      //   if(result.data.status_code === 200) {
      //     console.log(result.data.result)
      //     setModuleDropdown(result.data.result)
      //   }
      //   else{
      //     setAlert('error', result.data.message);
      //     setModuleDropdown([]);
      //   }
      // })
      // .catch(error => {
      //   setAlert('error', error.message);
      //   setModuleDropdown([]);
      // })
    }
    // else {
    //   setModuleDropdown([])
    // }
  };
  // const handleModule =(event,value) => {
  //   setFilterData({...filterData, module: ''});
  //   if(value) {
  //     setFilterData({ ...filterData, module: value});
  //   }
  // }

  return (
    <form autoComplete='off' onSubmit={handleSubmit}>
      <div style={{ width: '95%', margin: '20px auto' }}>
        <Grid container spacing={5}>
          <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <Autocomplete
              size='small'
              onChange={handleAcademicYear}
              style={{ width: '100%' }}
              id='grade'
              options={academicYearDropdown}
              value={filterData.year}
              getOptionLabel={(option) => option?.session_year}
              filterSelectedOptions
              disabled
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Academic Year'
                  placeholder='Academic Year'
                  required
                  disabled
                />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <Autocomplete
              size='small'
              onChange={handleVolume}
              style={{ width: '100%' }}
              id='grade'
              options={volumeDropdown}
              value={filterData.volume}
              getOptionLabel={(option) => option?.volume_name}
              filterSelectedOptions
              disabled
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Volume'
                  placeholder='Volume'
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <Autocomplete
              size='small'
              onChange={handleGrade}
              style={{ width: '100%' }}
              id='grade'
              options={gradeDropdown}
              value={filterData.grade}
              getOptionLabel={(option) => option?.grade_name}
              filterSelectedOptions
              disabled
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Grades'
                  placeholder='Grades'
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <Autocomplete
              size='small'
              onChange={handleSubject}
              style={{ width: '100%' }}
              id='grade'
              options={subjectDropdown}
              value={filterData.subject}
              getOptionLabel={(option) => option?.subject_name}
              filterSelectedOptions
              disabled
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Subject'
                  placeholder='Subject'
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <Autocomplete
              size='small'
              onChange={handleBoard}
              style={{ width: '100%' }}
              id='board'
              options={boardDropdown}
              value={filterData.board}
              disabled
              getOptionLabel={(option) => option?.board_name}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Board'
                  placeholder='Board'
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <TextField
              id='sessionyear'
              label='Module Name'
              style={{ width: '100%' }}
              variant='outlined'
              size='small'
              placeholder='Enter Module name'
              value={board_name}
              //   inputProps={{maxLength:7,pattern:'^[0-9]{4}-[0-9]{2}'}}
              name='board_name'
              onChange={(e) => setBoard_name(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <TextField
              id='modulesequencenumber'
              type='number'
              label='Module Sequence Number'
              placeholder='Enter Module Sequence Number'
              value={moduleSequence}
              onChange={(e) => setModuleSequence(e.target.value)}
              style={{ width: '100%' }}
              variant='outlined'
              size='small'
              SelectProps={{
                native: true,
              }}
            />
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={isMobile ? 1 : 5} style={{ width: '95%', margin: '10px' }}>
        <Grid item xs={6} sm={2} className={isMobile ? '' : 'addEditButtonsPadding'}>
          <Button
            variant='contained'
            className='custom_button_master labelColor'
            size='medium'
            onClick={handleGoBack}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={6} sm={2} className={isMobile ? '' : 'addEditButtonsPadding'}>
          <Button
            variant='contained'
            style={{ color: 'white' }}
            color='primary'
            className='custom_button_master'
            size='medium'
            type='submit'
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditAcademicYear;
