/* eslint-disable react/jsx-no-duplicate-props */
import React, { useContext, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import IconButton from '@material-ui/core/IconButton';
import { Grid, TextField, Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TablePagination from '@material-ui/core/TablePagination';
import Layout from '../../Layout';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import CommonBreadcrumbs from '../../../components/common-breadcrumbs/breadcrumbs';
import endpoints from '../../../config/endpoints';
import axiosInstance from '../../../config/axios';
import '../master-management.css';
import Loading from '../../../components/loader/loader';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import './curriculum.css';
import ReadMoreIcon from '@material-ui/icons/More';
import Lock from '@material-ui/icons/LockOutlined';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import LockOpenIcon from '@material-ui/icons/LockOpenRounded';
import { each } from 'jquery';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        boxShadow: 'none'
    },
    container: {
        maxHeight: '70vh',
    },
    buttonContainer: {
        background: theme.palette.background.secondary,
        paddingBottom: theme.spacing(2),
    },
    centerInMobile: {
        width: '100%',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    columnHeader: {
        color: `${theme.palette.secondary.main} !important`,
        fontWeight: 600,
        fontSize: '1rem',
        backgroundColor: `#ffffff !important`,
    },
    tableCell: {
        color: theme.palette.secondary.main,
    },
}));
const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'white',

    // styles we need to apply on draggables
    ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'white' : 'white',
    padding: grid,
    width: 250,
});

// const columns = [
//     // {id:'sequences', label:'sequences', minWidth:100},
//     { id: 'board_name', label: 'Boards' },
//     { id: 'module_name', label: 'Modules' },
//     {
//         id: 'actions',
//         label: 'Actions',
//         // minWidth: 170,
//         align: 'right',
//         labelAlign: 'center',
//     },
// ];

// const columns = [
//     // {id:'sequences', label:'sequences', minWidth:100},
//     { id: 'board_name', label: 'Action' },
//     { id: 'module_name', label: 'Modules' },
//     {
//         id: 'actions',
//         label: 'Edit',
//         // minWidth: 170,
//         align: 'right',
//         // labelAlign: 'center',
//     },
// ];


const CurriculumPlan = () => {
    const classes = useStyles();
    const { setAlert } = useContext(AlertNotificationContext);
    const [page, setPage] = React.useState(1);
    const [board, setBoard] = useState([]);
    const [moduleList, setModuleList] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [boardId, setBoardId] = useState();
    const [subjectDropdown, setSubjectDropdown] = useState([]);
    const [academicYearDropdown, setAcademicYearDropdown] = useState([]);
    const [volumeDropdown, setVolumeDropdown] = useState([]);
    const [gradeDropdown, setGradeDropdown] = useState([]);
    const [boardDropdown, setBoardDropdown] = useState([]);
    const [selectedBoardId, setSelectedBoardId] = useState([]);
    const [moduleDropdown, setModuleDropdown] = useState([])
    const [boardName, setBoardName] = useState('');
    const [addFlag, setAddFlag] = useState(false);
    const [editFlag, setEditFlag] = useState(false);
    const [tableFlag, setTableFlag] = useState(true);
    const [delFlag, setDelFlag] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const limit = 15;
    const [buttonColor, setButtonColor] = useState('BLUE')
    const [goBackFlag, setGoBackFlag] = useState(false)
    const [moduleData, setModuleData] = useState([]);
    const [filterData, setFilterData] = useState({
        year: '',
        volume: '',
        grade: '',
        subject: '',
        chapter: '',
        board: '',

    });

    const [chapterTable, setChapterTable] = useState([]);
    const [periodTable, setPeriodTable] = useState([]);
    const [showChapterTable, setShowChapterTable] = useState(false);
    const [showKeyConceptTable, setShowKeyConceptTable] = useState(false);
    const [keyConceptData, setKeyConceptData] = useState([])
    const [boardList, setBoardList] = useState([])
    const [activeDragChapter, setActiveDragChapter] = useState(true);
    const [activeDragKeyConcept, setActiveDragKeyConcept] = useState(true);
    const [activeDragPeriod, setActiveDragPeriod] = useState(true);
    const themeContext = useTheme();
    const [activeDrag, setActiveDrag] = useState(true);
    const [moduleSequence, setModuleSequence] = useState(null);
    const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
    const wider = isMobile ? '-10px 0px' : '-10px 0px 20px 8px'
    const widerWidth = isMobile ? '98%' : '95%'

    const handleChangePage = (event, newPage) => {
        setPage(newPage + 1)
    }
    // const handleGoBack = () => {
    //     setPage(1)
    //     setTableFlag(true);
    //     setAddFlag(false);
    //     setEditFlag(false);
    //     setGoBackFlag(!goBackFlag)
    //     getList();
    // };
    const handleDeleteYear = (e) => {
        e.preventDefault();
        setLoading(true);

        axiosInstance.delete(`${endpoints.masterManagement.module}${boardId}/`)
            .then((result) => {
                if (result.data.status_code === 200) {
                    setLoading(false);
                    setDelFlag(!delFlag);
                    setAlert('success', result.data.message);
                }
                else {
                    setLoading(false);
                    // getList()
                    setAlert('success', result.data.message);
                }
            })
            .catch((error) => {
                setLoading(false);
                setAlert('error', error.message);
            });
        setOpenDeleteModal(false);
    };


    const handleOpenDeleteModal = (id) => {
        setBoardId(id);
        setOpenDeleteModal(true);
    };

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
        // window.location.reload()
    };

    // useEffect(() => {
    //     getList();
    // }, []);
    // // }, [page, delFlag, goBackFlag]);

    // const getList = () => {
    //     setLoading(true)
    //     axiosInstance
    //         .get(
    //             // `${endpoints.masterManagement.module}?academic_year=${filterData.year?.id}&volume=${filterData.volume?.id}&grade_subject=${filterData.grade?.id}&board=${filterData.board?.id}&page=${page}&page_size=${limit}`
    //             `${endpoints.masterManagement.module}`
    //         )
    //         .then((result) => {
    //             if (result.status === 200) {
    //                 setLoading(false)

    //                 setBoard(result.data.result);
    //                 // setModuleList(results.data.result)
    //                 // setTotalCount(result.data.count);


    //             } else {
    //                 setAlert('error', result.error_message);
    //             }
    //         })
    //         .catch((error) => {
    //             setAlert('error', error.message);
    //         });



    // }

    // const  handleDragEnd = ({ destination, source, draggableId}) => {
    //     console.log({ destination, source, draggableId},'green')
    //     if (!destination) return;
    //     const copyToDos = [...boardList];
    //     copyToDos.splice(source.index, 1);
    //     copyToDos.splice(destination.index, 0, draggableId);
    //     console.log(copyToDos,'89988')
    //     setBoardList(copyToDos)
    // }
    const handleDragEnd = (e) => {
        // console.log(e.destination,'sssssssss=====>')
        if (!e.destination) return;
        let tempData = Array.from(boardList);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        // console.log(tempData,'TEMPDATA==>')
        setBoardList(tempData)
    };
    const handleDragEndChapterList = (e) => {
        if (!e.destination) return;
        let tempData = Array.from(chapterTable);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        setChapterTable(tempData)
    };

    const handleDragEndKeyConcept = (e) => {
        if (!e.destination) return;
        let tempData = Array.from(keyConceptData);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        setKeyConceptData(tempData)
    };

    const handleDragEndPeriod = (e) => {
        if (!e.destination) return;
        let tempData = Array.from(periodTable);
        let [source_data] = tempData.splice(e.source.index, 1);
        tempData.splice(e.destination.index, 0, source_data);
        setPeriodTable(tempData)
    };


    const handleViewMore = (name, id, sequence, index) => {
        setButtonColor('BLACK')
        setLoading(true)
        setChapterTable([])
        setKeyConceptData([])
        setPeriodTable([])
        // console.log(name, id, index)
        setShowChapterTable(true);
        setModuleSequence(sequence);
        axiosInstance
            .get(
                `${endpoints.masterManagement.chapterData}?module_id=${id}&lt_module_seq=${sequence}`
            )
            .then((result) => {
                if (result.data.status_code === 200) {
                    if (result?.data?.data?.length === 0) {
                        setLoading(false)
                        setAlert('error', 'No Data Found')
                    } else {
                        setLoading(false)
                        // console.log(result?.data?.data, 'watch')
                        setChapterTable(result?.data?.data)
                        setAlert('success', 'Successfully Data Fetched')

                    }

                } else {
                    setLoading(false)
                    setAlert('error', result.data.error_message)
                }
            })
            .catch((error) => {
                setLoading(false)
                setAlert('error', error.message);
            })

    }

    const handleViewMoreKeyConcept = (name, id, sequence, index) => {
        setLoading(true)
        setKeyConceptData([])
        setPeriodTable([])
        // console.log(name, id, index)
        setShowKeyConceptTable(true)
        axiosInstance
            .get(
                `${endpoints.masterManagement.keyConceptData}?chapter=${id}&chapter_seq=${sequence}&module_seq=${moduleSequence}`
            )
            .then((result) => {
                // console.log(result.data.data,'water')
                if (result.data.status === 200) {
                    if (result?.data?.data?.length === 0) {
                        setLoading(false)
                        setAlert('error', 'No Data Found !')
                    } else {
                        setLoading(false)
                        setKeyConceptData(result.data?.data)
                        setAlert('success', 'Successfully Fetched')
                        // setChapterTable(result.data.result?.result)
                    }

                } else {
                    setLoading(false)
                    setAlert('error', result.data.error_message)
                }
            })
            .catch((error) => {
                setLoading(false)
                setAlert('error', error.message);
            })

    }

    const handleViewMorePeriod = (name, id, sequence, index) => {
        setLoading(true)
        setPeriodTable([])
        axiosInstance
            .get(
                `${endpoints.masterManagement.periodData}?topic_id=${id}`
            )
            .then((result) => {
                if (result.data.status_code === 200) {
                    if (result?.data?.data?.length === 0) {
                        setLoading(false)
                        setAlert('error', 'No Data Found !')
                    } else {
                        setLoading(false)
                        // console.log(result.data.data, 'Period')
                        setAlert('success', 'Successfully Fetched')
                        setPeriodTable(result.data.data)
                    }
                    // setKeyConceptData(result.data?.result)
                    // setChapterTable(result.data.result?.result)

                } else {
                    setLoading(false)
                    setAlert('error', result.data.error_message)
                }
            })
            .catch((error) => {
                setLoading(false)
                setAlert('error', error.message);
            })

    }

    const handleLock = () => {
        if (activeDrag === false) {
            setLoading(true)
            setActiveDrag(true)
            let a = []
            boardList.map((each, index) => {
                let obj = {
                    id: each.id,
                    sequence: index + 1
                }
                a.push(obj)

            })
            const data = {
                module_ids: JSON.stringify(a)
            }
            axiosInstance
                .put(`${endpoints.masterManagement.moduleListUpdate}`, data)
                // console.log(a,'CHECKING...')
                .then((result) => {
                    setLoading(false)
                    // console.log(result.data.message,'9888')
                    setAlert('success', result.data.message)
                })
                .catch((error) => {
                    setLoading(false)
                    setAlert('error', error.message)
                })

        } else {
            setActiveDrag(false)
            setAlert('success', "Sequence Unlocked")
        }

    }
    // const handleLockChapterList = () => {
    //     setActiveDragChapter(!activeDragChapter)
    // }
    const handleLockChapterList = () => {
        if (activeDragChapter === false) {
            setLoading(true)
            setActiveDragChapter(true)
            let a = []
            chapterTable.map((each, index) => {
                let obj = {
                    id: each.id,
                    sequence: index + 1
                }
                a.push(obj)

            })
            const data = {
                chapter_ids: JSON.stringify(a)
            }
            axiosInstance
                .put(`${endpoints.masterManagement.chapterUpdate}`, data)
                // console.log(a,'CHECKING...')
                .then((result) => {
                    setLoading(false)
                    // console.log(result.data.message,'9888')
                    setAlert('success', result.data.message)
                })
                .catch((error) => {
                    setLoading(false)
                    setAlert('error', error.message)
                })

        } else {
            setActiveDragChapter(false)
            setAlert('success', "Sequence Unlocked")
        }

    }

    // const handleLockChapterList = () => {
    //     setActiveDragChapter(!activeDragChapter)
    //     let a = []
    //     chapterTable.map((each,index) => {
    //         let obj = {
    //             id:each.id,
    //             sequence:index+1
    //         }
    //         a.push(obj)

    //     })
    //     const data ={
    //         chapter_ids: JSON.stringify(a)
    //     }
    //     axiosInstance
    //         .put(`${endpoints.masterManagement.periodUpdate}`,data)
    //     .then((result) => {
    //         if(result.data.status_code === 2002) {
    //             setAlert('success','Updated Successfully')  
    //         }
    //     })
    //     .catch((error) => {
    //         setAlert('error',error.message)
    //     })

    // }



    // const handleLockKeyConceptList = () => {
    //     setActiveDragKeyConcept(!activeDragKeyConcept)
    // }

    const handleLockKeyConceptList = () => {
        // setActiveDragPeriod(!activeDragPeriod)
        if (activeDragKeyConcept === false) {
            setLoading(true)
            setActiveDragKeyConcept(true)
            let a = []
            keyConceptData.map((each, index) => {
                let obj = {
                    id: each.id,
                    sequence: index + 1
                }
                a.push(obj)
            })
            const data = {
                topic_ids: JSON.stringify(a)
            }
            axiosInstance
                .put(`${endpoints.masterManagement.topicUpdate}`, data)
                .then((result) => {
                    setLoading(false)
                    setAlert('success', result.data.message)
                    // if (result.data.status_code === 2002) {
                    //     // setLoading(false)
                    //     setAlert('success', 'Updated Successfully')
                    // }
                })
                .catch((error) => {
                    setLoading(false)
                    setAlert('error', error.message)
                })
        } else {
            setActiveDragKeyConcept(false)
            setAlert('success', "Sequence Unlocked")

        }

    }

    const handleLockPeriod = () => {
        if (activeDragPeriod === false) {
            setLoading(true)
            setActiveDragPeriod(true)
            let a = []
            periodTable.map((each, index) => {
                let obj = {
                    id: each.id,
                    sequence: index + 1
                }
                a.push(obj)

            })
            const data = {
                topic_ids: JSON.stringify(a)
            }
            axiosInstance
                .put(`${endpoints.masterManagement.periodUpdate}`, data)
                .then((result) => {
                    setLoading(false)
                    setAlert('success', result.data.message)
                    // if (result.data.status_code === 2002) {
                    //     setAlert('success', 'Updated Successfully')
                    // }
                })
                .catch((error) => {
                    setLoading(false)
                    setAlert('error', error.message)
                })

        } else {
            setActiveDragPeriod(false)
            setAlert('success', 'Sequence Unlocked')

        }

    }

    const handleGrade = (event, value) => {
        setLoading(true)
        setBoardList([])
        setChapterTable([])
        setKeyConceptData([])
        setPeriodTable([])
        setFilterData({ ...filterData, grade: '', subject: '', board: '' });
        if (value) {
            setLoading(true)
            setFilterData({ ...filterData, grade: value });
            axiosInstance
                .get(`${endpoints.masterManagement.gradeSubjectMappingList}?grade=${value.id}`)
                .then((result) => {
                    if (result.data.status_code === 200) {
                        setLoading(false)
                        setSubjectDropdown(result.data.result.results);
                    } else {
                        setLoading(false)
                        setAlert('error', result.data.message);
                        setSubjectDropdown([]);
                    }
                })
                .catch((error) => {
                    setLoading(false)
                    setAlert('error', error.message);
                    setSubjectDropdown([]);
                });
        } else {
            setLoading(false)
            setSubjectDropdown([]);
        }
    }

    const handleClearFilter = () => {
        setActiveDrag(false)
        setActiveDragKeyConcept(false)
        setActiveDragChapter(false)
        setActiveDrag(false)
        setBoardList([])
        setChapterTable([])
        setKeyConceptData([])
        setPeriodTable([])
        setFilterData({
            year: '',
            volume: '',
            grade: '',
            subject: '',
            board: '',

        });
        // getList()
    };

    // const handleFilter = () => {
    //     // setLoading(true);
    //     if (!filterData?.volume) {
    //         setAlert('error', 'Select Volume!');
    //         return;

    //     }
    //     if (!filterData?.grade) {
    //         setAlert('error', 'Select Grade!');
    //         return;
    //     }
    //     if (!filterData?.subject) {
    //         setAlert('error', 'Select Subject!');
    //         return;
    //     }
    //     if (!filterData?.board) {
    //         setAlert('error', 'Select Board !');
    //         return;
    //     }
    //     // if (!filterData?.module) {
    //     //   setAlert('error', 'Select Module !');
    //     //   return;
    //     // }
    //     // if (!filterData?.chapter) {
    //     //   setAlert('error', 'Select Chapter!');
    //     //   return;

    //     // } 
    //     else {
    //         setLoading(true)
    //         axiosInstance
    //             .get(
    //                 //topic_name chapter
    //                 // `${endpoints.masterManagement.createTopic}?chapter=${filterData.chapter.id}&page_size=${limit}`
    //                 `${endpoints.masterManagement.module}?academic_year=${filterData.year?.id}&volume=${filterData.volume?.id}&grade_subject=${filterData.grade?.id}&board=${selectedBoardId}`
    //             )
    //             .then((result) => {
    //                 console.log(result)
    //                 setLoading(false)
    //                 //console.log(res.data,'topic');
    //                 if (result.data.status_code === 200) {
    //                     console.log(result.data.result,'opppo8888')
    //                     setBoard(result.data.result);
    //                     // setTotalCount(result.data.count);
    //                 } else {
    //                     setLoading(false)
    //                     // setLoading(false);
    //                     setAlert('error', result.error.message);
    //                 }
    //             })
    //             .catch((error) => {
    //                 //console.log(error);
    //                 // setLoading(false);
    //                 setAlert('error', error.message);
    //             });
    //     }
    // };


    const handleAddYear = () => {
        setTableFlag(false);
        setAddFlag(true);
        setEditFlag(false);
    };



    const handleAcademicYear = (event, value) => {
        setBoardList([])
        setChapterTable([])
        setKeyConceptData([])
        setPeriodTable([])
        setFilterData({ ...filterData, year: '', volume: '', grade: '', subject: '', board: '' });
        if (value) {
            setFilterData({ ...filterData, year: value });
        }
    };
    const handleVolume = (event, value) => {
        setBoardList([])
        setChapterTable([])
        setKeyConceptData([])
        setPeriodTable([])
        setFilterData({ ...filterData, volume: '', grade: '', subject: '', board: '' });
        if (value) {
            setFilterData({ ...filterData, volume: value });
        }
    };

    const handleSubject = (event, value) => {
        setBoardList([])
        setLoading(true)
        setChapterTable([])
        setKeyConceptData([])
        setPeriodTable([])
        setFilterData({ ...filterData, subject: '', board: '' });
        if (value) {
            setLoading(true)
            setFilterData({ ...filterData, subject: value });
            axiosInstance
                .get(`${endpoints.masterManagement.boardMappingList}`)
                .then((result) => {
                    if (result.data.status_code === 200) {
                        setLoading(false)
                        setBoardDropdown(result.data.result);
                    } else {
                        setLoading(false)
                        setAlert('error', result.data.message);
                        setBoardDropdown([]);
                    }
                })
                .catch((error) => {
                    setLoading(false)
                    setAlert('error', error.message);
                    setBoardDropdown([]);
                });
        } else {
            setLoading(false)
            setBoardDropdown([]);
        }
    };

    useEffect(() => {
        axiosInstance
            .get(`${endpoints.masterManagement.academicYear}`)
            .then((result) => {
                if (result.data.status_code === 200) {
                    setAcademicYearDropdown(result.data.result.results);
                    handleAcademicYear('', result.data.current_acad_session_data[0])
                } else {
                    setAlert('error', result.data.message);
                }
            })
            .catch((error) => {
                setAlert('error', error.message);
            });

        axiosInstance
            .get(`${endpoints.masterManagement.volumes}`)
            .then((result) => {
                if (result.data.status_code === 200) {
                    setVolumeDropdown(result.data.result.results);
                } else {
                    setAlert('error', result.data.message);
                }
            })
            .catch((error) => {
                setAlert('error', error.message);
            });

        axiosInstance
            .get(`${endpoints.masterManagement.grades}`)
            .then((result) => {
                if (result.data.status_code === 200) {
                    setGradeDropdown(result.data.result.results);
                } else {
                    setAlert('error', result.data.message);
                }
            })
            .catch((error) => {
                setAlert('error', error.message);
            });
    }, []);


    const handleBoard = (event = {}, values = []) => {
        setLoading(true)
        setBoardList([])
        setChapterTable([])
        setKeyConceptData([])
        setPeriodTable([])
        setFilterData({ ...filterData, board: '' });
        // console.log(values,'bkk999')
        if (values) {
            // const ids = values.map((el) => el);
            // const selectedId = values.map((el) => el?.id)
            // setSelectedBoardId(selectedId)
            setFilterData({ ...filterData, board: values });
            axiosInstance
                .get(`${endpoints.masterManagement.moduleList}?academic_year=${filterData?.year?.id}&volume=${filterData?.volume?.id}&grade_subject=${filterData?.subject?.id}&board=${values?.id}`)
                .then((result) => {
                    // console.log(result)
                    if (result.data.status_code === 200) {
                        setLoading(false)
                        // console.log(result)
                        // setBoard(result);
                        setBoardList(result?.data?.data);

                        // setModuleDropdown(result.data.result);
                    } else {
                        setLoading(false)
                        setAlert('error', result.data.message);
                        setModuleDropdown([]);
                    }
                })
                .catch((error) => {
                    setLoading(false)
                    setAlert('error', error.message);
                    setModuleDropdown([]);
                });
        }
        else {
            setModuleDropdown([]);
            setLoading(false)
        }
    }

    return (
        <>
            {loading ? <Loading message='Loading...' /> : null}
            <Layout>
                <div>
                    <div style={{ width: '95%', margin: '20px auto' }}>
                        <CommonBreadcrumbs
                            componentName='Master Management'
                            childComponentName='Curriculum Plan'
                            childComponentNameNext={(addFlag && !tableFlag) ? 'Add Module' : (editFlag && !tableFlag) ? 'Edit Module' : null}
                        />
                    </div>
                </div>

                <Grid container spacing={isMobile ? 3 : 5} style={{ width: widerWidth, margin: wider, marginBottom: '20px' }}>
                    <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                        <Autocomplete
                            size='small'
                            onChange={handleAcademicYear}
                            style={{ width: '100%' }}
                            id='grade'
                            options={academicYearDropdown}
                            value={filterData.year}
                            getOptionLabel={(option) => option?.session_year}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    label='Academic Year'
                                    placeholder='Academic Year'
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                        <Autocomplete
                            size='small'
                            onChange={handleVolume}
                            style={{ width: '100%' }}
                            id='grade'
                            options={volumeDropdown}
                            value={filterData.volume}
                            getOptionLabel={(option) => option?.volume_name}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    label='Volume'
                                    placeholder='Volume'
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                        <Autocomplete
                            size='small'
                            onChange={handleGrade}
                            style={{ width: '100%' }}
                            id='grade'
                            options={gradeDropdown}
                            value={filterData.grade}
                            getOptionLabel={(option) => option?.grade_name}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    label='Grades'
                                    placeholder='Grades'
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                        <Autocomplete
                            size='small'
                            onChange={handleSubject}
                            style={{ width: '100%' }}
                            id='grade'
                            options={subjectDropdown}
                            value={filterData.subject}
                            getOptionLabel={(option) => option?.subject?.subject_name}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    label='Subject'
                                    placeholder='Subject'
                                    required
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                        <Autocomplete
                            // multiple
                            size='small'
                            onChange={handleBoard}
                            style={{ width: '100%' }}
                            id='board'
                            options={boardDropdown || []}
                            value={filterData.board || []}
                            getOptionLabel={(option) => option?.board_name || ''}
                            filterSelectedOptions
                            // getOptionSelected={(option, value) =>
                            //     option?.id == value?.id
                            // }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant='outlined'
                                    label='Board'
                                    placeholder='Board'
                                    required
                                />
                            )}
                        />
                    </Grid>
                    {/* <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
                <Autocomplete
                  multiple
                  size='small'
                  onChange={handleModule}
                  style={{ width: '100%' }}
                  id='module'
                  options={moduleDropdown || []}
                  value={filterData.module || []}
                  getOptionLabel={(option) => option?.lt_module_name || ''}
                  // filterSelectedOptions
                  getOptionSelected={(option, value) =>
                    option?.id == value?.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Module'
                      placeholder='Module'
                      required
                    />
                  )}
                />
              </Grid> */}
                    <Grid item sm={12} className={isMobile ? 'hideGrideItem' : ''} />
                    <Grid item xs={6} sm={3} className={isMobile ? '' : 'addButtonPadding'}>
                        <Button
                            // startIcon={<ClearAllIcon style={{ fontSize: '30px' }} />}
                            variant='contained'
                            color='default'
                            // size='small'
                            className={classes.clrBtn}
                            // style={{ color: 'white' }}
                            title='Clear All'
                            onClick={handleClearFilter}
                        >
                            Clear All
                        </Button>
                    </Grid>

                    {/* <Grid
                        item
                        xs={6}
                        sm={3}
                        md={1}
                        className={isMobile ? '' : 'addButtonPadding'}
                    >
                        <Button
                            // startIcon={<ClearAllIcon style={{ fontSize: '30px' }} />}
                            variant='contained'
                            color='primary'
                            size='medium'
                            style={{ color: 'white' }}
                            title='Filter'
                            onClick={handleFilter}
                        >
                            Filter
                        </Button>
                    </Grid> */}
                    {/* <Grid item xs={12} sm={4} className='addButtonPadding'>
                        <Button
                            startIcon={<AddOutlinedIcon style={{ fontSize: '30px' }} />}
                            variant='contained'
                            color='primary'
                            size="small"
                            style={{ color: 'white' }}
                            title="Add Module"
                            onClick={handleAddYear}>
                            Add Modules
                        </Button>
                    </Grid> */}
                </Grid>

                <Grid container spacing={isMobile ? 3 : 5} style={{ width: widerWidth, margin: wider }}>
                    {boardList?.length !== 0 ? (
                        <Grid item xs={12} sm={3} style={{ height: '500px', maxWidth: '300px' }}>
                            <div
                                style={{ border: '1px solid #ff6b6b', borderRadius: '10px', height: '500px', maxWidth: '300px', overflow: 'auto' }}

                            >
                                <DragDropContext onDragEnd={handleDragEnd}>
                                    <table className='table borderd'>
                                        <thead style={{ color: '#014b7e', fontSize: '17px' }}>
                                            <tr>
                                                <th style={{ minWidth: '50px', padding: '10px' }}>
                                                    <IconButton
                                                        // startIcon={<AddOutlinedIcon style={{ fontSize: '30px' }} />}
                                                        variant='contained'
                                                        color='primary'
                                                        size="small"
                                                        disabled
                                                        // style={{
                                                        //     color: activeDrag !== true ? '#2E7D32' : '#E53935'
                                                        // }}
                                                        title={activeDrag !== true ? 'Sequence' : 'Sequence Locked'}
                                                        onClick={handleLock}>
                                                        {activeDrag !== true ? (
                                                            <LockOpenIcon />

                                                        ) : <Lock />}

                                                    </IconButton>

                                                </th>
                                                <th style={{ minWidth: '100px', padding: '10px' }}>Modules</th>
                                                <th style={{ minWidth: '50px', padding: '10px' }}>Action</th>
                                            </tr>
                                        </thead>
                                        <Droppable droppableId="droppable-1">
                                            {(provider, snapshot) => (
                                                <tbody
                                                    className="text-capitalize"
                                                    ref={provider.innerRef}
                                                    {...provider.droppableProps}
                                                // style={getListStyle(snapshot.isDraggingOver)}
                                                >

                                                    {boardList?.map((year, index) => (
                                                        <Draggable
                                                            key={year.id}
                                                            draggableId={year.name}
                                                            index={index}
                                                            // style={{border:'1px solid black'}}
                                                            isDragDisabled={activeDrag}
                                                        >
                                                            {(provider, snapshot) => (

                                                                // if(snapshot.isDragging){

                                                                //     const offset = {x:100, y: 100}
                                                                //     const x = provider.draggableProps.style.left - offset.x;
                                                                //     const y = provider.draggableProps.style.top - offset.y;
                                                                //     provider.draggableProps.style.left = x;
                                                                //     provider.draggableProps.style.top = y;
                                                                // }
                                                                // return (


                                                                <tr
                                                                    ref={provider.innerRef}
                                                                    {...provider.draggableProps}
                                                                    {...provider.dragHandleProps}
                                                                // style={getItemStyle(
                                                                //     snapshot.isDragging,
                                                                //     provider.draggableProps.style
                                                                //   )}


                                                                >
                                                                    <td {...provider.dragHandleProps}
                                                                        style={{ minWidth: '50px', padding: '10px' }}
                                                                    >
                                                                        <DragIndicatorIcon
                                                                            style={{ color: activeDrag !== true ? '#283593' : '#BDBDBD', cursor: 'pointer' }}
                                                                        // style={{ color: '#BDBDBD', cursor: 'pointer' }}
                                                                        />

                                                                    </td>
                                                                    <td
                                                                        style={{ minWidth: '50px', padding: '10px' }}
                                                                    >{year?.name}</td>
                                                                    <td
                                                                        style={{ minWidth: '50px', padding: '10px' }}
                                                                    >
                                                                        <IconButton
                                                                            onClick={(e) => { handleViewMore(year.lt_module_name, year.id, year.sequence, index) }}
                                                                            title='View more'
                                                                            // size='small'
                                                                            // className='viewMore'
                                                                            style={{ float: 'right' }}
                                                                        >
                                                                            <ReadMoreIcon
                                                                                className='viewMore'
                                                                                // color='primary'
                                                                                // color='#ff6b6b'
                                                                                // className={buttonColor === 'BLUE' ? 'Blue':'Black'}
                                                                                // className='viewMore'
                                                                                size='small' />
                                                                        </IconButton>
                                                                    </td>
                                                                </tr>
                                                                //   )
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provider.placeholder}
                                                </tbody>
                                            )}
                                        </Droppable>
                                    </table>
                                </DragDropContext>
                            </div>

                        </Grid>

                    ) : ''}

                    {showChapterTable !== false && chapterTable?.length !== 0 ? (
                        <Grid item xs={12} sm={3} style={{ maxWidth: '300px', height: '500px' }}>
                            <div className="App mt-4"
                                style={{ border: '1px solid #ff6b6b', borderRadius: '10px', height: '500px', overflow: 'auto', maxWidth: '300px' }}>
                                <DragDropContext onDragEnd={handleDragEndChapterList}>
                                    <table className="table borderd">
                                        <thead style={{ color: '#014b7e', fontSize: '17px' }}>
                                            <tr>
                                                <th style={{ minWidth: '50px', padding: '10px' }}>
                                                    <IconButton
                                                        // startIcon={<AddOutlinedIcon style={{ fontSize: '30px' }} />}
                                                        variant='contained'
                                                        color='primary'
                                                        size="small"
                                                        disabled
                                                        // style={{ color: '#ff6b6b' }}
                                                        // style={{
                                                        //     color: activeDragChapter !== true ? '#2E7D32' : '#E53935'
                                                        // }}
                                                        title={activeDragChapter !== true ? 'Sequence' : 'Sequence Locked'}
                                                        onClick={handleLockChapterList}>
                                                        {activeDragChapter !== true ? (
                                                            <LockOpenIcon />
                                                        ) : <Lock />}
                                                        {/* title={activeDrag !== true ? 'Sequence' : 'Sequence Locked'} */}
                                                        {/* onClick={handleLockChapterList}> */}
                                                        {/* <Lock color='#ff6b6b' /> */}
                                                    </IconButton>
                                                </th>
                                                <th style={{ minWidth: '100px', padding: '10px' }}>Chapter</th>
                                                {/* <th style={{ minWidth: '50px', padding: '10px' }}>Edit</th> */}
                                                <th style={{ minWidth: '50px', paddingLeft: '10px' }}>Action</th>
                                            </tr>
                                        </thead>
                                        <Droppable droppableId="droppable-1"    >
                                            {(provider, snapshot) => (
                                                <tbody
                                                    className="text-capitalize"
                                                    ref={provider.innerRef}
                                                    {...provider.droppableProps}
                                                >
                                                    {chapterTable?.map((year, index) => (
                                                        <Draggable
                                                            key={year.id}
                                                            draggableId={year?.chapter__chapter_name}
                                                            index={index}
                                                            isDragDisabled={activeDragChapter}
                                                        >
                                                            {(provider, snapshot) => (
                                                                <tr
                                                                    ref={provider.innerRef}
                                                                    {...provider.draggableProps}
                                                                    {...provider.dragHandleProps}
                                                                >
                                                                    <td {...provider.dragHandleProps} style={{ minWidth: '50px', padding: '10px' }}>
                                                                        <DragIndicatorIcon
                                                                            style={{ color: activeDragChapter !== true ? '#283593' : '#BDBDBD', cursor: 'pointer' }}
                                                                        />
                                                                    </td>
                                                                    <td style={{ minWidth: '50px', padding: '10px' }}>{year?.chapter__chapter_name}</td>
                                                                    <td style={{ minWidth: '50px', padding: '10px' }}>
                                                                        <IconButton
                                                                            onClick={(e) => { handleViewMoreKeyConcept(year?.chapter__chapter_name, year?.chapter__id, year?.chapter_sequence__sequence, index) }}
                                                                            title='View more'
                                                                            // size='small'
                                                                            style={{ float: 'right' }}
                                                                        >
                                                                            <ReadMoreIcon
                                                                                className='viewMore'
                                                                                size='small' />
                                                                        </IconButton>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provider.placeholder}
                                                </tbody>
                                            )}
                                        </Droppable>
                                    </table>
                                </DragDropContext>
                            </div>
                        </Grid>

                    ) : ''}
                    {/* {console.log(showKeyConceptTable,keyConceptData,'klii999')} */}
                    {showKeyConceptTable && keyConceptData?.length !== 0 ? (
                        <Grid item xs={12} sm={3} style={{ height: '500px', maxWidth: '300px' }}>
                            <div className="App mt-4" style={{ border: '1px solid #ff6b6b', borderRadius: '10px', height: '500px', overflow: 'auto', maxWidth: '300px' }}>
                                <DragDropContext onDragEnd={handleDragEndKeyConcept}>
                                    <table className="table borderd">
                                        <thead style={{ color: '#014b7e', fontSize: '17px' }}>
                                            <tr>
                                                <th style={{ minWidth: '50px', padding: '10px' }}>
                                                    <IconButton
                                                        variant='contained'
                                                        color='primary'
                                                        size="small"
                                                        disabled
                                                        // style={{ color: '#ff6b6b' }}
                                                        // style={{
                                                        //     color: activeDragKeyConcept !== true ? '#2E7D32' : '#E53935'
                                                        // }}
                                                        title={activeDragKeyConcept !== true ? 'Sequence' : 'Sequence Locked'}
                                                        onClick={handleLockKeyConceptList}>
                                                        {activeDragKeyConcept !== true ? (
                                                            <LockOpenIcon />
                                                        ) : <Lock />}
                                                        {/* title={activeDragKeyConcept !== true ? 'Sequence' : 'Sequence Locked'} */}
                                                        {/* onClick={handleLockKeyConceptList}> */}

                                                        {/* <Lock color='#ff6b6b' /> */}
                                                    </IconButton>
                                                </th>
                                                <th style={{ minWidth: '100px', padding: '10px' }}>KeyConcept</th>
                                                {/* <th style={{ minWidth: '50px', padding: '10px' }}>Edit</th> */}
                                                <th style={{ minWidth: '50px', padding: '10px' }}>Action</th>
                                            </tr>
                                        </thead>
                                        <Droppable droppableId="droppable-1">
                                            {(provider) => (
                                                <tbody
                                                    className="text-capitalize"
                                                    ref={provider.innerRef}
                                                    {...provider.droppableProps}
                                                >
                                                    {keyConceptData?.map((year, index) => (
                                                        <Draggable
                                                            key={year.topic__topic_name}
                                                            draggableId={year.topic__topic_name}
                                                            index={index}
                                                            isDragDisabled={activeDragKeyConcept}
                                                        >
                                                            {(provider) => (
                                                                <tr {...provider.draggableProps} ref={provider.innerRef}>
                                                                    <td {...provider.dragHandleProps} style={{ minWidth: '50px', padding: '10px' }}>
                                                                        <DragIndicatorIcon
                                                                            style={{ color: activeDragKeyConcept !== true ? '#283593' : '#BDBDBD', cursor: 'pointer' }}
                                                                        />
                                                                    </td>
                                                                    <td style={{ minWidth: '50px', padding: '10px' }}>{year?.topic__topic_name}</td>
                                                                    <td style={{ minWidth: '50px', padding: '10px' }}>
                                                                        <IconButton
                                                                            onClick={(e) => { handleViewMorePeriod(year.topic__topic_name, year.topic__id, year.topic_sequence__sequence, index) }}
                                                                            title='View more'
                                                                            // className='viewMore'
                                                                            // size='small'
                                                                            style={{ float: 'right' }}
                                                                        >
                                                                            <ReadMoreIcon
                                                                                className='viewMore'
                                                                                size='small' />
                                                                        </IconButton>
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provider.placeholder}
                                                </tbody>
                                            )}
                                        </Droppable>
                                    </table>
                                </DragDropContext>
                            </div>
                        </Grid>

                    ) : ''}
                    {/* {console.log(periodTable,'asseemmmm')} */}

                    {periodTable?.length !== 0 ? (
                        <Grid item xs={12} sm={3} style={{ height: '500px', maxWidth: '380px' }}>
                            <div className="App mt-4" style={{ border: '1px solid #ff6b6b', borderRadius: '10px', height: '500px', overflow: 'auto', maxWidth: '380px' }}>
                                <DragDropContext
                                    onDragEnd={handleDragEndPeriod}>
                                    <table className="table borderd">
                                        <thead style={{ color: '#014b7e', fontSize: '17px' }}>
                                            <tr>
                                                <th style={{ minWidth: '50px', padding: '10px' }}>
                                                    <IconButton
                                                        // startIcon={<AddOutlinedIcon style={{ fontSize: '30px' }} />}
                                                        variant='contained'
                                                        color='primary'
                                                        size="small"
                                                        disabled
                                                        // style={{
                                                        //     color: activeDragPeriod !== true ? '#2E7D32' : '#E53935'
                                                        // }}
                                                        title={activeDragPeriod !== true ? 'Sequence' : 'Sequence Locked'}
                                                        onClick={handleLockPeriod}>
                                                        {activeDragPeriod !== true ? (
                                                            <LockOpenIcon />

                                                        ) : <Lock />}

                                                    </IconButton>

                                                </th>
                                                <th style={{ minWidth: '100px', padding: '10px' }}>Period</th>
                                                {/* <th style={{ minWidth: '100px', padding: '10px' }}>Action</th> */}
                                            </tr>
                                        </thead>

                                        <Droppable droppableId="droppable-1">
                                            {(provider) => (
                                                <tbody
                                                    className="text-capitalize"
                                                    ref={provider.innerRef}
                                                    {...provider.droppableProps}
                                                >
                                                    {periodTable?.map((year, index) => (
                                                        <Draggable
                                                            key={year.period_name}
                                                            draggableId={year.period_name}
                                                            index={index}
                                                            isDragDisabled={activeDragPeriod}
                                                        >
                                                            {(provider) => (
                                                                <tr {...provider.draggableProps} ref={provider.innerRef}>
                                                                    <td {...provider.dragHandleProps} style={{ minWidth: '50px', padding: '10px' }}>
                                                                        <DragIndicatorIcon
                                                                            style={{ color: activeDragPeriod !== true ? '#283593' : '#BDBDBD', cursor: 'pointer' }}
                                                                        />
                                                                    </td>
                                                                    <td style={{ minWidth: '50px', padding: '10px' }}>{year?.period_name}</td>
                                                                    {/* <td style={{ minWidth: '50px', padding: '10px' }}>
                                                                        <IconButton
                                                                            onClick={(e) => { handleViewMorePeriod(year.period_name, year.id, index) }}
                                                                            title='View more'
                                                                            // size='small'
                                                                            style={{ float: 'right' }}
                                                                        >
                                                                            <ReadMoreIcon color='primary' size='small' />
                                                                        </IconButton>
                                                                    </td> */}
                                                                </tr>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provider.placeholder}
                                                </tbody>
                                            )}
                                        </Droppable>
                                    </table>
                                </DragDropContext>
                            </div>
                        </Grid>

                    ) : ''}

                </Grid>
                {/* <Dialog
                    open={openDeleteModal}
                    onClose={handleCloseDeleteModal}
                    aria-labelledby='draggable-dialog-title'
                >
                    <DialogTitle style={{ cursor: 'move', color: '#014b7e' }} id='draggable-dialog-title'>
                        Delete Module
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>{`Confirm Delete Module Name ${boardName}`}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeleteModal} className="labelColor cancelButton">
                            Cancel
                        </Button>
                        <Button color="primary" variant='contained' style={{ color: 'white' }} onClick={handleDeleteYear}>Confirm</Button>
                    </DialogActions>
                </Dialog> */}
            </Layout>
        </>
    );
};

export default CurriculumPlan;
