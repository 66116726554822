function setPrototypes() {
  // eslint-disable-next-line no-extend-native
  Date.prototype.withMonthName = function (spliter = ' ') {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const weekday = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ];

    const nth = function (d) {
      if (d > 3 && d < 21) return 'th';
      switch (d % 10) {
        case 1:
          return 'st';
        case 2:
          return 'nd';
        case 3:
          return 'rd';
        default:
          return 'th';
      }
    };
    const date = this;
    const day = date.getDate();
    const newDate = [
      `${weekday[date.getDay()]},`,
      `${day}${nth(day)}`,
      monthNames[date.getMonth()],
      date.getFullYear(),
    ].join(spliter);
    // Monday, 12th October 2020
    return newDate;
  };
  // eslint-disable-next-line no-extend-native
  Date.prototype.shortDate = function (spliter = '/') {
    const date = this;
    const newDate = [
      String(date.getDay()).padStart(2, '0'),
      String(date.getMonth()).padStart(2, '0'),
      date.getFullYear(),
    ].join(spliter);
    // Monday, 12th October 2020
    return newDate;
  };
}
export default setPrototypes;
