import React from 'react';
import './styles.scss';

const ContentTypeList = ({ value, onClick, contentTypes }) => {
  return (
    <div className='content-type-container' style={{ marginLeft: 30 }}>
      <header className='content-type-header'>Content Type</header>
      <div className='type-list'>
        {contentTypes?.map((type) => {
          const isSelected = type?.name === value?.name;
          return (
            <p
              className={`type ${isSelected ? 'active' : ''}`}
              onClick={() => onClick(type)}
            >
              {type?.name}
            </p>
          );
        })}
      </div>
    </div>
  );
};

export default ContentTypeList;
