import axios from '../../../config/axios';
import qs from 'qs';

export const fetchVolumes = async () => {
  try {
    const response = await axios.get('/lesson_plan/list-volume/');
    return response.data.result.results;
  } catch (e) {
    throw new Error();
  }
};

export const fetchAcademicYears = async () => {
  try {
    const response = await axios.get('/lesson_plan/list-session/');
    return response.data.result.results;
  } catch (e) {
    return [];
  }
};

export const fetchGrades = async () => {
  try {
    const response = await axios.get('/lesson_plan/list-grade/');
    return response.data.result.results;
  } catch (e) {
    return [];
  }
};

export const fetchSubjects = async (gradeId) => {
  try {
    const response = await axios.get(
      `/lesson_plan/list-grade-subject-mapping/?grade=${gradeId}`
    );
    return response.data.result.results;
  } catch (e) {
    return [];
  }
};

export const fetchBoard = async () => {
  try {
    const response = await axios.get('/central-admin/boards/');
    return response.data.result;
  } catch (e) {
    return [];
  }
};

export const fetchModule = async (yearId, volumeId, gradeId, boardId) => {
  try {
    const response = await axios.get(
      `/lesson_plan/lt-module/?academic_year=${yearId}&volume=${volumeId}&grade_subject=${gradeId}&board=${boardId}&page_size=50`
    );
    return response.data.result;
  } catch (e) {
    return [];
  }
};
export const fetchTopics = async (chapterId) => {
  try {
    const response = await axios.get(
      `/assessment/topic/?chapter=${chapterId}&page_size=${50}`
    );
    return response.data.result;
  } catch (e) {
    return [];
  }
};

export const fetchChapters = async (
  gradeSubjectMappingId,
  volume,
  academicYear,
  board,
  module
) => {
  try {
    const response = await axios.get(
      `/lesson_plan/chapter/?grade_subject=${gradeSubjectMappingId}&volume=${volume}&academic_year=${academicYear}&board=${board}&lt_module=${module}`
    );
    return response.data.result;
  } catch (e) {
    return [];
  }
};

export const uploadLessonPlanFile = async (reqData) => {
  try {
    console.log('success in Api');
    const response = await axios.post(`/lesson_plan/files/`, reqData);

    return response.data.result;
  } catch (e) {
    console.log('error in Api');
    throw new Error();
  }
};

export const uploadLessonPlanHomework = async (reqData) => {
  try {
    const response = await axios.post(`/lesson_plan/create-homework/`, reqData);
    if (response.data.status_code === 409) {
      return response?.data;
    } else {
      return response.data.result?.url[0];
    }
  } catch (e) {
    console.log('error in Api');
    throw new Error();
  }
};

export const uploadLessonPlanFileFromDrive = async (reqData) => {
  try {
    const response = await axios.post(`/lesson_plan/drive-files/`, reqData);
    return response.data;
  } catch (e) {
    console.log('error in Api');
    return e.response.data;
    // throw new Error();
  }
};

export const deleteLessonPlanFile = async (reqData) => {
  try {
    const response = await axios.delete(`/lesson_plan/delete-files/`, reqData);

    return response.data.data;
  } catch (e) {
    throw new Error();
  }
};

export const createLessonPlan = async (reqObj) => {
  try {
    const response = await axios.post('/lesson_plan/lesson/', reqObj);

    return response.data;
  } catch (e) {
    throw new Error();
  }
};

export const createLessonOverview = async (reqObj) => {
  try {
    const response = await axios.post('/lesson_plan/create-lesson-overview/', reqObj);

    return response.data;
  } catch (e) {
    throw new Error();
  }
};

export const getAttachments = async (chapter, period, topic) => {
  try {
    const response = await axios.get(
      `/lesson_plan/lesson/?chapter=${chapter}&period=${period}&topic_id=${topic}&config=true`
    );

    return response.data.result;
  } catch (e) {
    throw new Error();
  }
};

export const createPeriods = async (reqObj) => {
  try {
    const response = await axios.post('/lesson_plan/lesson/', reqObj);

    return response.data;
  } catch (e) {
    throw new Error();
  }
};

export const periodDetails = async (topicId, chapterId) => {
  try {
    const response = await axios.get(
      `/assessment/${topicId}/topic-details/?chapter_id=${chapterId}`
    );
    return response.data;
  } catch (e) {
    throw new Error();
  }
};
