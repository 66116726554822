/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable no-debugger */
/* eslint-disable global-require */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useState, Fragment, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import axiosInstance from '../../../config/axios';
import endpoints from '../../../config/endpoints';
import CustomInput from '../../profile/custom-input/customInput';
import CommonBreadcrumbs from '../../../components/common-breadcrumbs/breadcrumbs';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import Layout from '../../Layout';
import Loading from '../../../components/loader/loader';
import './create-user.css';

const AddEditUser = withRouter(({ history, ...props }) => {
  const { userDetails, editClose } = props || {};
  const { setAlert } = useContext(AlertNotificationContext);
  const { role_details: roleDetailes } =
    JSON.parse(localStorage.getItem('userDetails')) || {};
  const { token } = JSON.parse(localStorage.getItem('userDetails')) || {};
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState('');
  const [userImage, setUserImage] = useState(null);
  const [userImageData, setUserImageData] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [defaultInputFields, setDefaultInputFields] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState();
  const [inputDetails, setInputDetails] = useState({
    name: '',
    email: '',
    phone_no: '',
  });
  const [errors, setErrors] = useState({
    role: '',
    name: '',
    email: '',
    phone_no: '',
  });

  const emailValidator=/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

  const getUserDetails = (userDetails) => {
    if (userDetails) {
      setDefaultInputFields([
        {
          name: 'role',
          type: 'text',
          value: userDetails.role.role_name,
          placeholder: 'Role',
        },
        {
          name: 'name',
          type: 'text',
          value: userDetails.name,
          placeholder: 'Name',
          titl: 'Ex: John Doe',
          len: 40,
        },
        {
          name: 'email',
          type: 'email',
          value: userDetails.email,
          placeholder: 'Email Id',
          titl: 'Ex: john.doe@gmail.com',
          len: 50,
        },
        {
          name: 'phone_no',
          type: 'text',
          value: userDetails.contact,
          placeholder: 'Phone Number',
          titl: 'Phone Number should contain 10 digits Ex: 989798xxxx',
          len: 10,
        },
      ]);
      setProfileImage(userDetails.profile);
      setSelectedRoles(userDetails.role);
      setInputDetails({
        name: userDetails.name,
        email: userDetails.email,
        phone_no: userDetails.contact,
      });
      setUserId(userDetails.id);
    } else {
      setDefaultInputFields([
        {
          name: 'role',
          type: 'text',
          value: '',
          placeholder: 'Role',
        },
        {
          name: 'name',
          type: 'text',
          value: '',
          placeholder: 'Name',
          titl: 'Ex: John Doe',
          len: 40,
        },
        {
          name: 'email',
          type: 'text',
          value: '',
          placeholder: 'Email Id',
          titl: 'Ex: john.doe@gmail.com',
          len: 50,
        },
        {
          name: 'phone_no',
          type: 'text',
          value: '',
          placeholder: 'Phone Number',
          titl: 'Phone Number should contain 10 digits Ex: 989798xxxx',
          len: 10,
        },
      ]);
    }
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setUserImage(URL.createObjectURL(event.target.files[0]));
      setUserImageData(event.target.files[0]);
    }
  };

  const handleRoleChange = (event, value) => {
    if (value) {
      setSelectedRoles(value);
    } else {
      setSelectedRoles();
    }
  };

  const getRoles = async () => {
    try {
      const result = await axiosInstance.get(
        `${endpoints.user.getRoles}`
      );
      if (result.status === 200) {
        setRoleList(result.data.result);
      } else {
        setAlert('error', result.data.message);
      }
    } catch (error) {
      setAlert('error', error.message);
    }
  };

  const handleProfileUpdate = async () => {
    const updateUserUrl = `${endpoints.user.centralAdminCommon}${userId}/update-user/`;
    if (!selectedRoles) {
      setErrors({ ...errors, role: 'Please select a role' });
      return;
    }
    if (!inputDetails.name) {
      setErrors({ ...errors, name: 'Please enter the name' });
      return;
    }
  
    if (!inputDetails.email) {
      setErrors({ ...errors, email: 'Please enter the email' });
      return;
    }
    if (!emailValidator.test(inputDetails.email)) {
      setErrors({ ...errors, email: 'Please enter Valid email' });
      return;
    }
    if (
      !Number.isInteger(Number(inputDetails.phone_no)) ||
      Number(inputDetails.phone_no) < 0
    ) {
      setErrors({ ...errors, phone_no: 'Please enter a valid phone no' });
      return;
    }
    if (!inputDetails.phone_no) {
      setErrors({ ...errors, phone_no: 'Please enter the phone no' });
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.set('name', inputDetails.name);
      formData.set('email', inputDetails.email);
      formData.set('phone_number', inputDetails.phone_no);
      if (userImageData) {
        formData.set('photo', userImageData);
      }
      formData.set('role_type', selectedRoles.id);
      const response = await axiosInstance({
        method: 'put',
        url: updateUserUrl,
        data: formData,
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status_code === 200) {
        setAlert('success', 'Profile updated successfully');
        setUserImage(null);
        setUserImageData(null);
        setDefaultInputFields([
          {
            name: 'role',
            type: 'text',
            value: selectedRoles.role_name,
            placeholder: 'Role',
          },
          {
            name: 'name',
            type: 'text',
            value: inputDetails.name,
            placeholder: 'Name',
          },
          {
            name: 'email',
            type: 'text',
            value: inputDetails.email,
            placeholder: 'Email Id',
          },
          {
            name: 'phone_no',
            type: 'text',
            value: inputDetails.phone_no,
            placeholder: 'Phone Number',
          },
        ]);
        setProfileImage(response.data.result.profile);
        setErrors({ role: '', name: '', email: '', phone_no: '' });
        setLoading(false);
        setUpdate(false);
      } else {
        setAlert('error', response.data.message);
        setLoading(false);
      }
    } catch (error) {
      setAlert('error', error.message);
      setLoading(false);
    }
  };
  const handleAddUsers = async () => {
    const adduserurl = endpoints.user.createUsers;
    if (!selectedRoles) {
      setErrors({
        name: '',
        email: '',
        phone_no: '',
        role: 'Please select a role',
      });
      return;
    }
    if (!inputDetails.name) {
      setErrors({ role: '', email: '', phone_no: '', name: 'Please enter the name' });
      return;
    }

    if (!inputDetails.email) {
      setErrors({ role: '', name: '', phone_no: '', email: 'Please enter the email' });
      return;
    }
    if (!emailValidator.test(inputDetails.email)) {
      setErrors({ ...errors, email: 'Please enter Valid email' });
      return;
    }

    if (!inputDetails.phone_no) {
      setErrors({ role: '', name: '', email: '', phone_no: 'Please enter the phone no' });
      return;
    }
    
    if (
      !Number.isInteger(Number(inputDetails.phone_no)) ||
      Number(inputDetails.phone_no) < 0
    ) {
      setErrors({ ...errors, phone_no: 'Please enter a valid phone no' });
      return;
    }
    setLoading(true);
    try {
      const formData = new FormData();
      formData.set('name', inputDetails.name);
      formData.set('phone_number', inputDetails.phone_no);
      formData.set('email', inputDetails.email);
      if (userImageData) {
        formData.set('photo', userImageData);
      }
      formData.set('role_type', selectedRoles.id);
      const response = await axiosInstance({
        method: 'post',
        url: adduserurl,
        data: formData,
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.data.status_code === 201) {
        setAlert('success', 'User added successfully');
        setLoading(false);
        setUserImage(null);
        setUserImageData(null);
        history.push('/view-edit-user');
      } else {
        setAlert('error', response.data.message);
        setLoading(false);
      }
    } catch (error) {
      setAlert('error', error.message);
      setLoading(false);
    }
  };
  const handleUserUpdateCancel = () => {
    setUserImage(null);
    setInputDetails({
      name: '',
      email: '',
      phone_no: '',
    });
    setUpdate(false);
  };
  const handleClose = (e) => {
    setInputDetails({
      name: '',
      email: '',
      phone_no: '',
    });
    editClose(false);
    setDefaultInputFields([]);
    setUserImage(null);
  };
  const handleChange = (e) => {
    const { value, name } = e.target;
    setInputDetails({
      ...inputDetails,
      [name]: value,
    });
  };
  useEffect(() => {
    getUserDetails(userDetails);
    getRoles();
  }, []);
  return (
    <>
      {loading ? <Loading message='Loading...' /> : null}
      <Layout>
        <div className='create_users_breadcrumb_container'>
          <CommonBreadcrumbs componentName={userDetails ? 'Edit user' : 'Create users'} />
        </div>
        
        <div className='add_edit_user'>
          {!update && userDetails ? (
            <span onClick={() => setUpdate(true)} className='add_edit_user_edit_icon'>
              <EditIcon />
            </span>
          ) : null}
          <div className='profile_wrapper'>
            <div className='user_image_wrapper'>
              <img
                src={
                  userImage || profileImage || require('../../../assets/images/Male.svg')
                }
                alt='Not found'
                className='profile_avator'
              />
              {(!update && userDetails) ? null : (
                <Button
                  variant='contained'
                  className='profile_upload_image_button'
                  component='label'
                >
                  Add Image
                  <input
                    type='file'
                    style={{ display: 'none' }}
                    id='raised-button-file'
                    onChange={onImageChange}
                  />
                </Button>
             )}
            </div>
            <form key={update} className='profile_details_wrapper'>
              {defaultInputFields.map((items, index) =>
                (items.name === 'role' && userDetails && update) ||
                (!userDetails && items.name === 'role') ? (
                  <div className='create_user_role_wrapper'>
                    <Autocomplete
                      size='small'
                      onChange={handleRoleChange}
                      value={selectedRoles}
                      id='add_edit_user-role'
                      className='add_edit_user_role'
                      options={roleList}
                      getOptionLabel={(option) => option?.role_name}
                      filterSelectedOptions
                      renderInput={(params) => (
                        <TextField
                          className='add_edit_user-textfield'
                          {...params}
                          variant='outlined'
                          label='Role'
                          placeholder='Role'
                        />
                      )}
                    />
                    <span className='add_edit_user_error'>{errors.role}</span>
                  </div>
                ) : (
                  <Fragment key={`profileInput_${index}`}>
                    <span className='profile_label_tag'>{items.name}</span>
                    <div
                      className={
                        items.name === 'password' ? 'password' : 'textFieldsContainer'
                      }
                    >
                      <CustomInput
                        className={
                          items.type === 'text'
                            ? 'add_edit_user_textFields'
                            : `${'textFields'} ${'passwordWidth'}`
                        }
                        id={items.name}
                        name={items.name}
                        value={items.value}
                        titl={items.titl}
                        len={items.len}
                        patt={items.patt}
                        readonly={
                            // userDetails
                            // ? true
                            // : !update && userDetails
                            !update && userDetails
                        }
                        onChange={handleChange}
                      />
                    </div>
                    {errors[items.name]?
                    <span className='add_edit_user_error'>{errors[items.name]}</span>
                    :null}
                  </Fragment>
                )
              )}
            </form>
          </div>
        </div>
        {(userImage && update && userDetails) || update ? (
          <div className='profile_update_button_wrapper'>
            <input
              className='profile_update_button cancel_button_profile'
              type='button'
              onClick={handleUserUpdateCancel}
              value='cancel'
            />
            <input
              className='profile_update_button'
              type='button'
              onClick={handleProfileUpdate}
              value='Update Profile'
            />
          </div>
        ) : null}
        {!userDetails ? (
          <div className='profile_update_button_wrapper'>
             {/* <Button 
              variant='contained' 
              type="button" 
              title='Go back to User List'
              color='primary'
              style={{color:'white',width:'40%'}}
              onClick={handleAddUsers}
              size='medium'>
              Submit
            </Button> */}
            <input
              className='profile_update_button'
              type='button'
              onClick={handleAddUsers}
              value='Submit'
            />
          </div>
        ) : null}
        {userDetails ? (
          <Button 
          variant='contained' 
          type="button" 
          title='Go back to User List'
          className="labelColor cancelButton custom_button_create_user" 
          onClick={handleClose} 
          size='medium'>
          BACK
        </Button>
        ) : null}
      </Layout>
    </>
  );
});

export default AddEditUser;
