import React, { createRef, useContext, useEffect, useState } from 'react';
import Divider from '@material-ui/core/Divider';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { Grid, TextField, Button, useTheme, SvgIcon } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useSelector } from 'react-redux';
// import download from '../../../assets/images/downloadAll.svg';
import { AlertNotificationContext } from '../../../../context-api/alert-context/alert-state';
import endpoints from '../../../../config/endpoints';
import axiosInstance from '../../../../config/axios';
import { Form, Select } from 'antd';
// import { setFilter } from 'redux/actions';
import { PlusOutlined } from '@ant-design/icons';


const { Option } = Select;

const AssessmentFiltersNew = ({
    handlePeriodList,
    setPeriodData,
    setViewMore,
    setViewMoreData,
    setFilterDataDown,
    setSelectedIndex,
    handleData,
    tabValue,
    setPage,
    setTabGradeId,
    setTabSubjectId

  }) => {
    const { setAlert } = useContext(AlertNotificationContext);
    const themeContext = useTheme();
    const formRef = createRef();
    const history = useHistory();
    const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
    const wider = isMobile ? '-10px 0px' : '-10px 0px 20px 8px';
    const widerWidth = isMobile ? '98%' : '95%';
    const [overviewSynopsis, setOverviewSynopsis] = useState([]);
    const [academicYearDropdown, setAcademicYearDropdown] = useState([]);
    const [volumeDropdown, setVolumeDropdown] = useState([]);
    const [gradeDropdown, setGradeDropdown] = useState([]);
    const [subjectDropdown, setSubjectDropdown] = useState([]);
    const [chapterDropdown, setChapterDropdown] = useState([]);
    const [qpValue, setQpValue] = useState('');
    const [filterData, setFilterData] = useState({
        grade: '',
        subject: '',
        qpValue : ''
      });
    const[gradeSubjectMap ,setGradeSubjectMap ]   = useState('')

      const question_level_options = [
        { value: 1, Question_level: 'Easy' },
        { value: 2, Question_level: 'Average' },
        { value: 3, Question_level: 'Difficult' },
      ];
      const questionLeveloptions = question_level_options?.map((each) => {
        return (
          <Option key={each?.value} value={each.value}>
            {each?.Question_level}
          </Option>
        );
      });

    useEffect(() => {
        axiosInstance
          .get(`${endpoints.lessonPlan.gradeList}`)
          .then((result) => {
            if (result.data.status_code === 200) {
              setGradeDropdown(result.data.result.results);
            } else {
              setAlert('error', result.data.message);
            }
          })
          .catch((error) => {
            setAlert('error', error.message);
          });
      }, []);

      useEffect(() => {
        if(filterData?.grade && filterData?.subject && gradeSubjectMap){
          handleFilter()
        }
      },[filterData?.subject,qpValue, gradeSubjectMap])

      const handleFilter = () => {
        if (!filterData.grade) {
          setAlert('error', 'Select Grade!');
          return;
        }
        if (!filterData.subject) {
          setAlert('error', 'Select subject!');
          return;
        }
        // if (!qpValue) {
        //   setAlert('error', 'Select QP Level!');
        //   return;
        // }
        // console.log('filter ====', qpValue);
        setSelectedIndex(-1);
        handlePeriodList(filterData.grade, filterData.subject, qpValue, gradeSubjectMap);
      };

      const handleSubjectData = (grade, subject) => {
        axiosInstance
          .get(
            `${endpoints.assementQP.assementSubjectMap}?grade=${grade?.value}&subject_id=${subject?.value}`
          )
          .then((result) => {
            if (result.data.status_code === 200) {
              setGradeSubjectMap(result?.data?.result?.results[0]?.id);
            } else {
              setGradeSubjectMap('')
              setAlert('error', result.data.description);
            }
          })
          .catch((error) => {
            setAlert('error', error.message);
          });
      };


      const handleGrade = (event, value) => {
        setPage(1)
        formRef.current.setFieldsValue({
          subject: null,
          // board: null,
        });
        setFilterData({
          ...filterData,
          grade: '',
          subject: '',
        });
        sessionStorage.removeItem('filter')
        setQpValue('');
        setPeriodData([]);
        setSubjectDropdown([]);
        if (value) {
          setFilterData({ ...filterData, grade: value, subject:'' });
          // const acadSessionIds = filterData.branch.map(({ id }) => id) || [];
          axiosInstance
            .get(
              `${endpoints.lessonPlan.gradeSubjectMappingList}?grade=${value?.value}` //?session_year=${acadSessionIds}
            )
            .then((result) => {
              if (result?.data?.status_code === 200) {
                setSubjectDropdown(result.data.result.results);
              } else {
                setAlert('error', result?.data?.message);
              }
            })
            .catch((error) => {
              setAlert('error', error?.message);
            });
        }
      };

      const handleSubject = (event, value) => {
        setPage(1)
        setFilterData({ ...filterData, subject: '' });
        setPeriodData([]);
        if (value) {
          handleSubjectData(filterData.grade, value);
          setFilterData({ ...filterData, subject: value });
        }
      };

      const handleQpLevel = (event, value) => {
        setPage(1)
        setPeriodData([]);
        if (value) {
          setQpValue(value);
        }else{
          setQpValue('')
        }
      };

    const gradeOptions = gradeDropdown?.map((each) => {
        return (
          <Option key={each?.id} value={each.grade_id}>
            {each?.grade_name}
          </Option>
        );
      });
      const subjectOptions = subjectDropdown?.map((each) => {
        return (
          <Option key={each?.id} value={each?.subject?.id}>
            {each?.subject?.subject_name}
          </Option>
        );
      });

      const handleClearSubject = () => {
        setTabSubjectId('')
      }

      const handleClearGrade = () => {
        setTabGradeId('')
        setTabSubjectId('')
      }


  return (
    <div className='th-bg-white'>
          <div className='col-12'>
            <Form id='filterForm' ref={formRef} layout={'horizontal'}>
              <div className='align-items-center' style={{display:'flex'}}>
                {/* {boardFilterArr.includes(window.location.host) && ( */}
                {/* )} */}
                <div className='col-md-2 col-6 px-1'>
                  <div className='mb-2 text-left'>Grade *</div>
                  <Form.Item name='grade'>
                    <Select
                      allowClear
                      placeholder={
                        // filterData?.grade ? filterData?.grade?.children :
                        'Select Grade'
                      }
                      showSearch
                      optionFilterProp='children'
                      getPopupContainer={(trigger) => trigger.parentNode}
                      filterOption={(input, options) => {
                        return (
                          options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                      onChange={(e, value) => {
                        handleGrade(e,value);
                      }}
                      onClear={handleClearGrade}
                      className='w-100 text-left th-black-1 th-bg-grey th-br-6'
                      bordered={false}
                    >
                      {gradeOptions}
                    </Select>
                  </Form.Item>
                </div>
                <div className='col-md-2 col-6 px-2'>
                  <div className='mb-2 text-left'>Subject *</div>
                  <Form.Item name='subject'>
                    <Select
                    allowClear
                      placeholder={
                        filterData?.subject ?  (
                          <span className='th-black-1'>{filterData?.subject?.children}</span>
                        ) :
                        'Select Subject'
                      }
                      // value={filterData?.subject}
                      showSearch
                      getPopupContainer={(trigger) => trigger.parentNode}
                      optionFilterProp='children'
                      // defaultValue={subjectName}
                      filterOption={(input, options) => {
                        return (
                          options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                      onChange={(e, value) => {
                        handleSubject(e,value);
                      }}
                      onClear={handleClearSubject}
                      className='w-100 text-left th-black-1 th-bg-grey th-br-6'
                      bordered={false}
                    >
                      {subjectOptions}
                    </Select>
                  </Form.Item>
                </div>
                <div className='col-md-2 col-6 px-2'>
                    <div className='mb-2 text-left'>Question Level</div>
                    <Form.Item name='questionlevel'>
                      <Select
                        allowClear
                        placeholder='Question Level'
                        getPopupContainer={(trigger) => trigger.parentNode}
                        showSearch
                        optionFilterProp='children'
                        // onClear={handleClearQuestionLevel}
                        filterOption={(input, options) => {
                          return (
                            options.children.toLowerCase().indexOf(input.toLowerCase()) >=
                            0
                          );
                        }}
                        onChange={(e,value) => {
                          handleQpLevel(e,value);
                        }}
                        
                        className='w-100 text-left th-black-1 th-bg-grey th-br-6'
                        bordered={false}
                      >
                        {questionLeveloptions}
                      </Select>
                    </Form.Item>
                  </div>
                  <div className='col-md-2 col-6 px-2'></div>
                <div
                  className='col-md-4 col-6 text-right pr-0'
                >
                  <div className='row justify-content-end pr-1'> <Button
                    type='primary'
                    onClick={() => history.push('/create-questionpaper/')}
                    shape="round"
                    variant='contained'
                    size={'small'}
                    className='th-br-6 th-width-48 th-fw-500 th-button-active'
                  >
                    <PlusOutlined size='small' className='mr-2' />
                    Create New
                  
                  </Button></div>
                 
                </div>
              </div>
            </Form>
          </div>
          </div>
  )
}

export default AssessmentFiltersNew