/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import QuestionHeader from './question-header';
import QuestionBody from './question-body';
import { Box, Grid } from '@material-ui/core';

const QuestionHandler = ({ questionObj, questionsDataObj }) => {
  const questionsList = {};
  if (questionObj['question_type'] === 7) {
    questionsDataObj.map((item) => (questionsList['id'] = item));
    questionObj = { ...questionObj, sub_questions: questionsDataObj };
  }
  const propsObj = {
    questionsDataObj: { ...questionsList },
    // questionsArray,
    questionObj,
  };

  const mainquestionpanel = {
    border: `1px solid #ff6b6b`,
    padding: '10px',
    overflowY: 'scroll',
    height: '42rem',
    borderRadius: '5px',
    fontFamily: 'Andika New Basic, sans- serif',
    color: '#014b7e',
  };

  return (
    <Box style={{ ...mainquestionpanel }}>
      <QuestionHeader {...propsObj} />
      <QuestionBody {...propsObj} />
    </Box>
  );
};
export default QuestionHandler;
