import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton, Button, Grid, Paper, TextField , Box} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import axiosInstance from '../../../../config/axios';
import endpoints from '../../../../config/endpoints';

import Loading from '../../../../components/loader/loader';

import { AlertNotificationContext } from '../../../../context-api/alert-context/alert-state';
import DNDFileUpload from '../../../../components/dnd-file-upload';
import { Autocomplete } from '@material-ui/lab';
import FileSaver from 'file-saver';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    // width: 'fit-content',
    margin: '0 auto',
    width: '95%',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    // backgroundColor: theme.palette.background.paper,
    backgroundColor: 'rgb(249, 250, 251)',
    // color: theme.palette.text.secondary,
    color: 'black',
    alignItems: 'center',
    alignContent: 'center',
    '& svg': {
      margin: theme.spacing(1.5),
    },
    '& hr': {
      margin: theme.spacing(0, 0.5),
    },
  },
  mpZero: {
    margin: 0,
    padding: 5,
  },
}));

const fileConf = {
  fileTypes:
    'application/vnd.ms-excel',
  types: 'xls',
};


const allTypeQuestions = 100;
const templateFiles = {
  [allTypeQuestions]: {
    templateFile:
      'https://omrsheet.s3.ap-south-1.amazonaws.com/prod/assessment_config_files/Bulk+Upload+Questions+Template.xls',
      // 'https://d2r9gkgplfhsr2.cloudfront.net/prod/assessment_config_files/assessment_single_choice.xlsx',
    url: endpoints.createQuestionBulk.AllTypeQuestions,
    label: '',
    fileConf: {
      fileTypes:
        'application/vnd.ms-excel',
      types: 'xls',
    },
    templateFileHeaders: [
      'chapter',
      'keyconcept',
      'question',
      'option1',
      'option2',
      'option3',
      'option4',
      'option5',
      'correct_answer',
      'question_level',
      'question_type',
      'question_categories',
      'comprehension question id',
      'parent comprehension question '
    ],
  },
};

function QuestionBulkCreation(props) {
  const history = useHistory()
  const { attributes = {} } = props || {};
  const {
    category: { id: questionCategoryId = 1, category: categoryLabel } = {},
    level: { id: questionLevelId = 1, level: levelLabel } = {},
    type: { id: questionTypeId = 100, question_type: questionTypeLabel } = {},
    subject: { id: gradeSubjectMappingId = 147 },
    topic: { id: topicId = 6 },
    chapter: { id: chapterId = '' },
  } = attributes || {};
  const classes = useStyles();
  const [selectedAcad, setSelectedAcad] = useState('');
  const [acadList, setAcadList] = useState([]);
  // const [questionTypeId] = useState(questionTypeIdFromProps);
  const [file, setFile] = useState(null);
  const [isUploading, setUploading] = useState(null);
  const { setAlert } = useContext(AlertNotificationContext);

  function uploadFiles(questionTypeId) {
    if (!templateFiles[questionTypeId]) return null;

    const { url: apiURL } = templateFiles[questionTypeId] || {};
    const payLoad = {
      file: {
        value: file,
        label: 'File',
        validationText: 'Please choose file to upload.',
      },
      grade_subject_mapping: {
        value: gradeSubjectMappingId,
        label: 'Grade subject mapping',
        validationText: 'Please choose subject.',
      },
      topic: {
        value: topicId,
        label: 'Topic',
        validationText: 'Please choose key concept.',
      },
      chapter: {
        value: chapterId,
        label: 'Chapter',
        validationText: 'Please choose chapter.',
      },
    };
    const formData = new FormData();
    const isValid = Object.entries(payLoad).every(([key, valueObj]) => {
      formData.set(key, valueObj.value);
      if (!valueObj.value) {
        setAlert('error', valueObj.validationText);
        return false;
      }
      return true;
    });
    if (isValid) {
      setUploading(true);
      axiosInstance
        // .post(apiURL, formData, { responseType: 'blob' })
        .post(apiURL, formData)
        .then((response) => {
          setUploading(false);
          const {
            headers: { 'content-type': contentType },
            status,
          } = response;
          // if (['text/ms-excel', 'type/csv'].includes(contentType)) {
          if (['type/csv'].includes(contentType)) {
            // const blob = new Blob([response.data], {
            //   type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            // });
            const blob = new Blob([response.data]);
            const href = window.URL.createObjectURL(blob);
            downloadSampleFile(href, 'question-creation-report.csv');
          } else {
            console.log('response is not file');
            const {
              data: { status_code: statusCode, message },
            } = response;
            // if (statusCode !== 200) {
            setAlert(statusCode === 200 ? 'success' : 'error', `${message}`);
            // }
          }
        })
        .catch((error) => {
          setAlert('error',error.response.data.message)
          setUploading(false);
        });
    }
    return true;
  }

useEffect(() => {
  handlGetAcadApi()
},[])

  const handlGetAcadApi = () => {
    // setLoading(true);
    axiosInstance
      .get(`${endpoints.lessonPlan.academicYearList}`)
      .then((result) => {
        // setLoading(false);
        if (result.data.status_code === 200) {
            setAcadList(result.data.result.results);
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        // setLoading(false);
        setAlert('error', error.message);
      });
  };
  const downloadchapterkeys = () => {
    if(selectedAcad){
      axiosInstance
      .get(`${endpoints.questionBank.chapterKeyConcept}?session_year=${selectedAcad?.session_year}&grade_subject_mapping=${gradeSubjectMappingId}`,
      {
        responseType: 'arraybuffer',
      }
      )
      .then((result) => {
        // setLoading(false);
        if (result.status === 200) {
          const blob = new Blob([result.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          });
          FileSaver.saveAs(blob, 'records.xls');        }
        else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        // setLoading(false);
        setAlert('error', error.message);
      });
    }else{
      setAlert('error' , 'please select Academic year')
    }
    
  }

  function downloadSampleFile(url, fileNameStr) {
    const fileName = fileNameStr || url.split('/').splice(-1)[0];
    function anchorDwnld(href) {
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      setTimeout(() => {
        URL.revokeObjectURL(url);
      }, 150);
    }
    // axiosInstance.get(url, { responseType: 'arraybuffer' }).then((res) => {
    //   const blob = new Blob([res.data]);
    //   const href = window.URL.createObjectURL(blob);
    //   anchorDwnld(href);
    // });
    anchorDwnld(url);
  }
  function templateFileHeader(questionTypeId) {
    if (!templateFiles[questionTypeId]) return null;

    const { templateFileHeaders, templateFile } = templateFiles[questionTypeId];

    const columnsWithDividers = templateFileHeaders.join(',|,').split(',');
    const columns = columnsWithDividers.map((columnLabel) => {
      return columnLabel === '|' ? (
        <Divider orientation='vertical' flexItem />
      ) : (
        <Typography
          className={classes.mpZero}
          variant='caption'
          display='block'
          gutterBottom
        >
          {columnLabel}
        </Typography>
      );
    });
    return (
      <>
        <Typography
          className={classes.mpZero}
          variant='subtitle2'
          display='block'
          gutterBottom
        >
          File Headers:
        </Typography>
        <Grid
          container
          style={{ margin: 2 }}
          spacing={2}
          alignItems='center'
          alignContent='center'
        >
          <Grid item>
            <Grid container spacing={2} className={classes.root}>
              {columns}
            </Grid>
          </Grid>
          <Grid item>
            <Button
              size='small'
              style={{
                margin: 4,
                textTransform: 'none',
                fontSize: '0.8rem',
                display: 'inline-block',
              }}
              color='primary'
              variant='contained'
              className='modifyDesign'
              onClick={() => {
                history.push('/question-bank');
              }}
            >
              Back
            </Button>
          </Grid>
          <Grid item>
            {/* <Button
              size='small'
              style={{
                margin: 4,
                textTransform: 'none',
                fontSize: '0.8rem',
                display: 'inline-block',
              }}
              color='primary'
              variant='contained'
              className='modifyDesign'
              onClick={() => {
                downloadSampleFile(templateFile);
              }}
            > */}
            <Box display='flex' flexDirection='row' style={{ color: 'gray' }}>
                {/* {`Download Format: `} */}
                <a
                  style={{ cursor: 'pointer' }}
                  href='/assets/download-format/chapterandkeys.xls'
                  download='chapterandkeys.xls'
                >
                  Download template file
                </a>
            </Box>
            {/* </Button> */}
          </Grid>
        </Grid>
      </>
    );
  }

  return (
    <Paper
      style={{
        width: '95%',
        margin: '0 auto',
        padding: 10,
        position: 'relative',
        borderRadius: '10px',
      }}
    >
      {/* <IconButton
        style={{ position: 'absolute', top: 0, right: 0 }}
        onClick={() => {
          props.onJobDone();
        }}
        color='primary'
        aria-label='cancel'
        component='span'
      >
        <CloseIcon />
      </IconButton> */}
      {isUploading ? <Loading message='uploading...' /> : null}

      <Grid container spacing = {2}>
        <Grid container item md={7}>
        </Grid>
        <Grid item container spacing = {2} md={5}>
        <Grid item md={6} xs={8} sm={3}>
              <Autocomplete
                style={{ width: '100%' }}
                size='small'
                className='dropdownIcon'
                onChange={(event, value) => {
                  setSelectedAcad(value);
                }}
                id='Acad_id'
                options={acadList}
                value={selectedAcad}
                getOptionLabel={(option) => option?.session_year}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant='outlined'
                    label='Academic Year'
                    placeholder='Academic Year'
                  />
                )}
              />
        </Grid>
        <Grid item md={6} xs={4} sm={3}>
          <Button variant='contained' color = 'primary' onClick={downloadchapterkeys}>
            Download file
          </Button>
        </Grid>
        <h4>Note :  Please Download Chapter and Key Concept id's from here</h4>
        </Grid>

      </Grid>


      {questionTypeId && !templateFiles[questionTypeId] ? (
        <>
          <h4>{`${questionTypeLabel} is not supported in bulk creation`}</h4>
        </>
      ) : null}
      {templateFileHeader(questionTypeId)}
      <br />

      <Grid container justify='space-evenly' alignContent='center' alignItems='center'>
        <Grid item sm={12} md={5}>
          <DNDFileUpload
            value={file}
            handleChange={(e) => {
              setFile(e);
            }}
            fileType={fileConf.fileTypes}
            typeNames={fileConf.types}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            variant='contained'
            color='primary'
            className='modifyDesign'
            size='small'
            disabled={!file}
            style={{
              margin: 4,
              textTransform: 'none',
              fontSize: '1rem',
              display: 'inline-block',
            }}
            onClick={() => {
              uploadFiles(questionTypeId);
            }}
          >
            Upload
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
export default QuestionBulkCreation;

// var blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
//         var link = document.createElement('a')
//         // eslint-disable-next-line no-debugger
//         // debugger
//         link.href = window.URL.createObjectURL(blob)
//         link.download = 'monthly_Summary_attendence_report.xls'
//         link.click()
