import React, { useContext, useState, useEffect } from 'react';
import { Grid, TextField, Button, useTheme, Divider, MenuItem } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import endpoints from '../../../config/endpoints';
import axiosInstance from '../../../config/axios';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import Autocomplete from '@material-ui/lab/Autocomplete';

const CreateModule = ({ setLoading, handleGoBack, moduleData }) => {
  const { setAlert } = useContext(AlertNotificationContext);
  const [board_name, setBoard_name] = useState('');
  const [academicYearDropdown, setAcademicYearDropdown] = useState([]);
  const [volumeDropdown, setVolumeDropdown] = useState([]);
  const [gradeDropdown, setGradeDropdown] = useState([]);
  const [subjectDropdown, setSubjectDropdown] = useState([]);
  const [boardDropdown, setBoardDropdown] = useState([]);
  const [moduleDropDown, setModuleDropdown] = useState([]);
  const [gradeSubjectMapping, setGradeSubjectMapping] = useState('');
  const [moduleSequence, setModuleSequence] = useState('');

  const [filterData, setFilterData] = useState({
    // year: '',
    // volume: '',
    // grade: '',
    // subject: '',
    // board: '',
    year: moduleData?.year,
    volume: moduleData?.volume,
    grade: moduleData?.grade,
    subject: moduleData?.subject,
    // board: moduleData?.board,
  });

  useEffect(() => {
    axiosInstance
      .get(`${endpoints.masterManagement.academicYear}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setAcademicYearDropdown(result.data.result.results);
          handleAcademicYear('', result.data.current_acad_session_data[0]);
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });

    axiosInstance
      .get(`${endpoints.masterManagement.volumes}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setVolumeDropdown(result.data.result.results);
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });

    axiosInstance
      .get(`${endpoints.masterManagement.grades}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setGradeDropdown(result.data.result.results);
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setAlert('error', error.message);
      });
    axiosInstance
      .get(`${endpoints.masterManagement.boardMappingList}`)
      .then((result) => {
        if (result.data.status_code === 200) {
          setLoading(false);
          setBoardDropdown(result.data.result);
          // console.log(result.data.result,'hargsy77777')
        } else {
          setLoading(false);
          setAlert('error', result.data.message);
          setBoardDropdown([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.message);
        setBoardDropdown([]);
      });
    if (moduleData?.grade) {
      axiosInstance
        .get(
          `${endpoints.masterManagement.gradeSubjectMappingList}?grade=${moduleData?.grade?.id}`
        )
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setSubjectDropdown(result.data.result.results);
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
            setSubjectDropdown([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
          setSubjectDropdown([]);
        });
    }
    if (moduleData?.grade) {
      axiosInstance
        .get(
          `${endpoints.masterManagement.gradeSubjectMappingList}?grade=${moduleData.grade?.id}&subject=${moduleData?.subject?.subject?.subject_name}`
        )
        .then((response) => {
          if (response.data.status_code === 200) {
            let selectedSubject = response.data.result?.results?.filter(
              (item) => item.subject.id === moduleData?.subject?.subject?.id
            );
            setGradeSubjectMapping(selectedSubject[0]?.id);
          }
        });
    }
  }, []);

  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));

  const handleAcademicYear = (event, value) => {
    setLoading(true);
    setFilterData({ ...filterData, year: '' });
    if (value) {
      setLoading(false);
      setFilterData({ ...filterData, year: value });
    } else {
      setLoading(false);
      setFilterData({
        year: '',
        volume: '',
        grade: '',
        subject: '',
        board: '',
      });
    }
  };
  const handleVolume = (event, value) => {
    setLoading(true);
    setFilterData({ ...filterData, volume: '' });
    if (value) {
      setLoading(false);
      setFilterData({ ...filterData, volume: value });
    } else {
      setLoading(false);
      setFilterData({
        year: filterData?.year,
        volume: '',
        grade: '',
        subject: '',
        board: '',
      });
    }
  };

  const handleGrade = (event, value) => {
    setLoading(true);
    setFilterData({ ...filterData, grade: '' });
    if (value) {
      setFilterData({ ...filterData, grade: value });
      axiosInstance
        .get(`${endpoints.masterManagement.gradeSubjectMappingList}?grade=${value.id}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setSubjectDropdown(result.data.result.results);
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
            setSubjectDropdown([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
          setSubjectDropdown([]);
        });
    } else {
      setLoading(false);
      setSubjectDropdown([]);
      setFilterData({
        year: filterData?.year,
        volume: filterData?.volume,
        grade: '',
        subject: '',
        board: '',
      });
    }
  };

  const handleSubject = (event, value) => {
    setLoading(true);
    setFilterData({ ...filterData, subject: '' });
    if (value) {
      setFilterData({ ...filterData, subject: value });
      axiosInstance
        .get(
          `${endpoints.masterManagement.gradeSubjectMappingList}?grade=${filterData.grade?.id}&subject=${value?.subject?.subject_name}`
        )
        .then((response) => {
          if (response.data.status_code === 200) {
            setLoading(false);
            let selectedSubject = response.data.result?.results?.filter(
              (item) => item.subject.id === value?.subject?.id
            );
            setGradeSubjectMapping(selectedSubject[0]?.id);
          }
        });
      axiosInstance
        .get(`${endpoints.masterManagement.boardMappingList}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            setLoading(false);
            setBoardDropdown(result.data.result);
            // console.log(result.data.result,'hargsy77777')
          } else {
            setLoading(false);
            setAlert('error', result.data.message);
            setBoardDropdown([]);
          }
        })
        .catch((error) => {
          setLoading(false);
          setAlert('error', error.message);
          setBoardDropdown([]);
        });
    } else {
      setLoading(false);
      setBoardDropdown([]);
      setFilterData({
        year: filterData?.year,
        volume: filterData?.volume,
        grade: filterData?.grade,
        subject: '',
        board: '',
      });
    }
  };

  const handleBoard = (event, value) => {
    setLoading(true);
    setFilterData({ ...filterData, board: '' });
    if (value) {
      setLoading(false);
      setFilterData({ ...filterData, board: value });
      // axiosInstance.get(`${endpoints.masterManagement.module}`)
      // .then(result => {
      //   if(result.data.status_code === 200) {
      //     console.log(result.data.result)
      //     setModuleDropdown(result.data.result)
      //   }
      //   else{
      //     setAlert('error', result.data.message);
      //     setModuleDropdown([]);
      //   }
      // })
      // .catch(error => {
      //   setAlert('error', error.message);
      //   setModuleDropdown([]);
      // })
    } else {
      setLoading(false);
      setFilterData({
        year: filterData?.year,
        volume: filterData?.volume,
        grade: filterData?.grade,
        subject: filterData?.subject,
        board: '',
      });
    }
    // else {
    //   setModuleDropdown([])
    // }
  };
  // const handleModule =(event,value) => {
  //   setFilterData({...filterData, module: ''});
  //   if(value) {
  //     setFilterData({ ...filterData, module: value});
  //   }
  // }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axiosInstance
      .post(endpoints.masterManagement.createUpdatemodule, {
        lt_module_name: board_name.trim(),
        grade_subject_mapping: gradeSubjectMapping,
        volume: filterData.volume?.id,
        academic_year: filterData.year?.id,
        board: filterData.board?.id,
        sequence: Number(moduleSequence),
      })
      .then((result) => {
        if (result.status === 201) {
          setBoard_name('');
          setGradeSubjectMapping('');
          setModuleSequence('');
          // handleGoBack();
          setFilterData({
            year: '',
            volume: '',
            grade: '',
            subject: '',
            board: '',
          });
          setLoading(false);
          setAlert('success', result?.data?.message);
        } else {
          setLoading(false);
          setAlert('error', result?.data?.message);
        }
      })
      .catch((error) => {
        setAlert('error', error.response.data.message);
        setLoading(false);
      });
  };
  const handleModuleSequenceChange = (e) => {
    setModuleSequence(e.target.value);
  };

  return (
    <form autoComplete='off' onSubmit={handleSubmit}>
      <div style={{ width: '95%', margin: '20px auto' }}>
        <Grid container spacing={5}>
          <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <Autocomplete
              size='small'
              onChange={handleAcademicYear}
              style={{ width: '100%' }}
              id='grade'
              options={academicYearDropdown}
              value={filterData.year}
              getOptionLabel={(option) => option?.session_year}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Academic Year'
                  placeholder='Academic Year'
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <Autocomplete
              size='small'
              onChange={handleVolume}
              style={{ width: '100%' }}
              id='grade'
              options={volumeDropdown}
              value={filterData.volume}
              getOptionLabel={(option) => option?.volume_name}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Volume'
                  placeholder='Volume'
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <Autocomplete
              size='small'
              onChange={handleGrade}
              style={{ width: '100%' }}
              id='grade'
              options={gradeDropdown}
              value={filterData.grade}
              getOptionLabel={(option) => option?.grade_name}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Grades'
                  placeholder='Grades'
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <Autocomplete
              size='small'
              onChange={handleSubject}
              style={{ width: '100%' }}
              id='grade'
              options={subjectDropdown}
              value={filterData.subject}
              getOptionLabel={(option) => option?.subject?.subject_name}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Subject'
                  placeholder='Subject'
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={4} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <Autocomplete
              size='small'
              onChange={handleBoard}
              style={{ width: '100%' }}
              id='board'
              options={boardDropdown}
              value={filterData.board || ''}
              getOptionLabel={(option) => option?.board_name}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Board'
                  placeholder='Board'
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <TextField
              id='sessionyear'
              label='Module Name'
              style={{ width: '100%' }}
              variant='outlined'
              size='small'
              placeholder='Enter Module name'
              value={board_name}
              //   inputProps={{maxLength:7,pattern:'^[0-9]{4}-[0-9]{2}'}}
              name='board_name'
              onChange={(e) => setBoard_name(e.target.value)}
              required
            />
          </Grid>

          <Grid item xs={12} sm={4} className={isMobile ? '' : 'addEditPadding'}>
            <TextField
              id='modulesequencenumber'
              type='number'
              label='Module Sequence Number'
              placeholder='Enter Module Sequence Number'
              value={moduleSequence || ''}
              onChange={handleModuleSequenceChange}
              style={{ width: '100%' }}
              variant='outlined'
              size='small'
              SelectProps={{
                native: true,
              }}
            />
            {/* <Autocomplete
              size='small'
              onChange={handleModuleSequenceChange}
              style={{ width: '100%' }}
              id='modulesequencenumber'
              options={['1', '2', '3']}
              // value={filterData.board}
              getOptionLabel={(option) => option}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='outlined'
                  label='Module Number'
                  placeholder='Enter Module Sequence Number'
                  required
                />
              )}
            /> */}
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={isMobile ? 1 : 5} style={{ width: '95%', margin: '10px' }}>
        <Grid item xs={6} sm={2} className={isMobile ? '' : 'addEditButtonsPadding'}>
          <Button
            variant='contained'
            className='custom_button_master labelColor'
            size='medium'
            onClick={handleGoBack}
          >
            Back
          </Button>
        </Grid>
        <Grid item xs={6} sm={2} className={isMobile ? '' : 'addEditButtonsPadding'}>
          <Button
            variant='contained'
            style={{ color: 'white' }}
            color='primary'
            className='custom_button_master'
            size='medium'
            type='submit'
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default CreateModule;
