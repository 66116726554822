import axiosInstance from '../src/config/axios';
import endpoints from '../src/config/endpoints';

const token = JSON.parse(localStorage.getItem('userDetails'))?.token || null;

const getWords = async () => {
  if (token != null) {
    axiosInstance
      .get(endpoints.assementQP.bloomsDifficultyTypeSubType)
      .then((response) => {
        console.log(response, 'config data');
        if (response?.data?.blooms?.length > 0) {
          configureBloom(response?.data?.blooms);
        }
        if (response?.data?.difficulty?.length > 0) {
          configureDiff(response?.data?.difficulty);
        }
      })
      .catch((error) => {
        console.log('Error fetching config data:', error);
      });
  }
};
getWords();

let newBloom = [];
const configureBloom = async (blooms) => {
  console.log(blooms, 'bloom');
  let transformBloom = blooms?.map((item) => {
    let temp = {
      id: item?.id,
      level: item?.category_name,
    };
    newBloom.push(temp);
  });
  console.log(newBloom, 'newbloom');
};

let newDiff = [];
const configureDiff = async (diff) => {
  console.log(diff, 'bloom');
  let transformDiff = diff?.map((item) => {
    let temp = {
      id: item?.id,
      level: item?.level_name,
    };
    newDiff.push(temp);
  });
  console.log(newDiff, 'newDiff');
};
export const difficultyLevels = newDiff;
export const bloomLevels = newBloom;
// export const difficultyLevels = [
//   { id: '1', level: 'Easy' },
//   { id: '2', level: 'Average' },
//   { id: '3', level: 'Difficult' },
// ];

// export const bloomLevels = [
//   { id: '1', level: 'Creating' },
//   { id: '2', level: 'Evaluating' },
//   { id: '3', level: 'Analyzing' },
//   { id: '4', level: 'Applying' },
//   { id: '5', level: 'Understanding' },
//   { id: '6', level: 'Remembering' },
// ]
