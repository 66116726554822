import React, { useContext, useState, useEffect } from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Autocomplete from '@material-ui/lab/Autocomplete';
import endpoints from '../../../config/endpoints';
import axiosInstance from '../../../config/axios';
import FilterFilledIcon from '../../../components/icon/FilterFilledIcon';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import Loading from '../../../components/loader/loader';

const Filter = ({ handleFilter, clearFilter }) => {
  const { setAlert } = useContext(AlertNotificationContext);
  const [acadList, setAcadList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [selectedAcad, setSelectedAcad] = useState('');
  const [selectedGrade, setSelectedGrade] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState('');
  const [schoolList, setSchoolList] = useState([]);
  const [bookList, setBookList] = useState([]);
  const [selectedBook, setSelectedBook] = useState('');

  useEffect(() => {
    setSelectedAcad('');
    setSubjectList([]);
    setSelectedGrade('');
    setSelectedSubject('');
    setSelectedSchool('');
  }, [clearFilter]);

  function getApiCalls(url, key) {
    setLoading(true);
    axiosInstance
      .get(url)
      .then((result) => {
        console.log({ result });
        setLoading(false);
        if (result.status === 200) {
          if (key === 'book') {
            setBookList(result.data.result.result);
          }
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error.message);
      });
  }

  useEffect(() => {
    getApiCalls(`${endpoints.ibook.bookList}`, 'book');
  }, []);

  function handleClear() {
    handleFilter();
    setSelectedBook('');
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={3} xs={12}>
          {console.log(bookList, 'booookkkkk')}
          <Autocomplete
            style={{ width: '100%' }}
            size='small'
            className='dropdownIcon'
            onChange={(event, value) => {
              setSelectedBook(value);
            }}
            id='Book_id'
            options={bookList}
            value={selectedBook}
            getOptionLabel={(option) => option?.book_name}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField {...params} variant='outlined' label='Book' placeholder='Book' />
            )}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Grid container spacing={2}>
            <Grid item md={2} xs={6}>
              <Button
                style={{ color: 'white' }}
                size='small'
                fullWidth
                onClick={() => handleClear()}
                variant='contained'
              >
                Clear
              </Button>
            </Grid>
            <Grid item md={2} xs={6}>
              <Button
                startIcon={<FilterFilledIcon />}
                style={{ color: 'white' }}
                size='small'
                variant='contained'
                color='primary'
                fullWidth
                onClick={() => handleFilter(selectedBook)}
              >
                Filter
              </Button>
            </Grid>
            <Grid item md={3} xs={6}>
              <Button
                size='small'
                color='primary'
                variant='contained'
                href='/ibook-chapter/create'
                style={{ color: 'white' }}
                fullWidth
              >
                <AddCircleOutlineIcon />
                &nbsp;Create Chapter
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {loading && <Loading />}
    </>
  );
};

export default Filter;
