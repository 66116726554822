import { Card, Checkbox, Radio } from 'antd';
import React, { useState } from 'react';

const TrueFalse = ({ questionData, questionList, parentIndex, setQuestions, comptype, childIndex }) => {
  const [value, setValue] = useState(true);
  const onChange = (e,index) => {
    const list = [...optionsList];
    const qlist = [...questionList];
    let value = e.target.checked;
    let name = e.target.name
    setValue(e.target.value);
    handleOptionUpdate(list, qlist, value,name,index);
  };

  const [optionsList, setOptionsList] = useState([
    { isChecked: false, optionValue: true, title: 'True' },
    { isChecked: false, optionValue: false, title: 'False' }
  ]);

  const handleOptionUpdate = (oplist, qlist, answer,name,index) => {
    // let qa = questionList[parentIndex]?.question_answer;
    let list = [...qlist];
    // qa.push(oplist)
    let newoplist = oplist.map((item) => item);
    let arr = [];
    newoplist.forEach((item, index) => {
      let obj = {};
      obj[`option${index + 1}`] = item;
      arr.push(obj);
    });
    let option = [...optionsList]
    if(name === "False"){
      option[1].isChecked = answer
      option[0].isChecked = false
    }
    if(name === "True"){
      option[0].isChecked = answer
      option[1].isChecked = false

    }

    //   option[1].isChecked = false;
    // if(answer === "True"){
    //   option[index].isChecked = true;
    //   option[1].isChecked = false;
    // }else{
    //   option[1].isChecked = true;
    //   option[0].isChecked = false;
    // }
    setOptionsList(option)
    if(comptype == 'comp'){
      let subQuestions = list[parentIndex].subquestions[childIndex]
      subQuestions.question_answer[0]['options'] = arr;
      let answerarr = [(index === 0 && answer == true) ? "option1" : "option2"]
      subQuestions.question_answer[0]['answer'] = answerarr;
      list[parentIndex].subquestions[childIndex] = subQuestions
      setQuestions(list)
    }else{
      list[parentIndex].question_answer[0]['options'] = arr;
      let answerarr = [(index === 0 && answer == true) ? "option1" : "option2"]
      
      list[parentIndex].question_answer[0]['answer'] = answerarr;
      setQuestions(list);
    }
  };

  // const handleContent = () => {
  //   let list = [...questionList];
  //   if(comptype === 'comp'){
  //     let subQuestions = list[parentIndex].subquestions[childIndex]
  //     let checkAnswer = subQuestions.question_answer[0]['question'] ? subQuestions.question_answer[0]['question'] : ''
  //     return checkAnswer
  //   } else{
  //     return question
  //   }
  // }


  return (
    <Card bordered={true} className='options-card' style={{ width: '100%' }}>
      <div className='row'>
        <div className='col-md-6 col-12'>
          <label htmlFor='answer' className='text-success' style={{ fontWeight: 500 }}>
            Select Answer
          </label>
        </div>
        <div className='col-md-6 col-12 text-right'></div>
      </div>
      <Radio.Group style={{ width: '100%' }}>
        <div className='row'>
          {Array.isArray(optionsList) &&
            optionsList.length > 0 &&
            optionsList.map((item, index) => (
              <div className='col-6' key={index}>
                <Checkbox id='rightanswer' name = {item.title} onChange={(e) => onChange(e,index)} checked={item.isChecked}>{item.title}</Checkbox>
              </div>
            ))}
        </div>
      </Radio.Group>
    </Card>
  );
};

export default TrueFalse;
