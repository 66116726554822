import { combineReducers } from 'redux';
import auth from './authReducer';
import roleManagement from './roleManagementReducer';
import userManagement from './userManagementReducer';
import academicMappingReducer from './academic-mapping-reducer';
import lessonPlanningReducer from './lesson-planning-reducer';
import createQuestionPaper from './create-question-paper-reducer';
import createAssesment from './create-assesment-reducer';

export default combineReducers({
  auth,
  roleManagement,
  userManagement,
  academic: academicMappingReducer,
  createQuestionPaper,
  createAssesment,
  // lessonPlanningReducer,
});
