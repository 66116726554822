import React, { useContext, useState, useEffect } from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import endpoints from '../../../../config/endpoints';
import useMediaQuery from '@material-ui/core/useMediaQuery'
import axiosInstance from './../../../../config/axios';
import { AlertNotificationContext } from './../../../../context-api/alert-context/alert-state';
import { useTheme } from '@material-ui/core/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,

        },
    },
};
const bull = (
    <Box
        component="span"
        sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
    >
        •
    </Box>
);
const CreateEditSchool = ({ periodId, handleGoBack }) => {
    console.log('period', periodId)
    const themeContext = useTheme();
    const { setAlert } = useContext(AlertNotificationContext);
    const [pId, setPId] = useState(periodId?.periodId);
    const [questioList, setQuestionList] = useState();
    const [question, setQuestions] = useState('');
    const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
    const [questionData, setQuestionData] = useState([]);
    useEffect(() => {

        axiosInstance
            .get(
                `${endpoints.questionBank.questionPaper}${periodId}/`
            )
            .then((result) => {
                if (result.status === 200) {
                    {
                        console.log(result);
                        setQuestionList(result.data?.question)


                    }
                } else {
                    setAlert('error', result.error_message);
                }
            })
            .catch((error) => {
                setAlert('error', error.message);
            });
    }, []);

    useEffect(() => {
        let questionItems = questioList?.map((n) => n)
        setQuestions(questionItems)
        console.log('question', questionItems)


    }, [questioList])


    useEffect(() => {

        axiosInstance
            .get(
                `${endpoints.questionBank.questionData}?ids=${question}`
            )
            .then((result) => {
                if (result.status === 200) {
                    {
                        console.log(result);
                        setQuestionData(result.data?.result?.results)

                    }
                } else {
                    setAlert('error', result.error_message);
                }
            })
            .catch((error) => {
                setAlert('error', error.message);
            });
    }, [question]);

    console.log(questionData);


    return (

        <div style={{ width: '95%', margin: '20px auto' }}>

<Button onClick={handleGoBack}>back</Button>

            {questionData?.map((q) => (
                (q.question_answer?.map((qd) => (

                    < div style={{ padding: '10px' }} >
                        <Card sx={{ minWidth: 275 }}>
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                    <div style={{ color: 'black', padding: '10px', fontSize: '15px' }} dangerouslySetInnerHTML={{ __html: qd.question }} />
                                </Typography>

                            </CardContent>
                            <CardContent>
                                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>

                                    {qd?.options ?
                                        <Grid item xs={12} sm={12}>
                                            <Grid container spacing={isMobile ? 3 : 5}>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    style={isMobile ? { marginLeft: '-8px' } : null}
                                                    sm={3}
                                                >
                                                    Option 1: {qd?.options[0]?.option1?.optionValue}<br/>
                                                    {/* <img style={{ height: '100%', width: '100%' }} src={`${endpoints.s3}` + qd?.options[0]?.option1?.images[0]} alt='option1' /> */}

                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    style={isMobile ? { marginLeft: '-8px' } : null}
                                                    sm={3}
                                                >
                                                   Option 2: {qd?.options[1]?.option2?.optionValue}<br/>
                                                   <br/> 
                                                    {/* <img style={{ height: '100%', width: '100%' }} src={`${endpoints.s3}` + qd?.options[1]?.option2?.images[0]} alt='option2' /> */}
                                                     </Grid>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    style={isMobile ? { marginLeft: '-8px' } : null}
                                                    sm={3}
                                                >


                                                   Option 3:{qd?.options[2]?.option3?.optionValue}<br/>
                                                   <br/> 
                                                    {/* <img style={{ height: '100%', width: '100%' }} src={`${endpoints.s3}` + qd?.options[2]?.option3?.images[0]} alt='option3' /> */}
                                                     </Grid>
                                                <Grid
                                                    item
                                                    xs={3}
                                                    style={isMobile ? { marginLeft: '-8px' } : null}
                                                    sm={3}

                                                >
                                                   Option 4:{qd?.options[3]?.option1?.optionValue}<br/>
                                                   <br/> 
                                                    {/* <img style={{ height: '100%', width: '100%' }} src={`${endpoints.s3}` + qd?.options[3]?.option4?.images[0]} alt='option4' /> */}
                                                    </Grid>
                                            </Grid></Grid>
                                        : ''}

                                    
                                </Typography>

                            </CardContent>
                            <CardActions>
                                <Button size="small">Answer :{qd.answer}</Button>
                            </CardActions>
                        </Card>

                        {/* {qd.question} */}



                    </div>

                )))))



            }


         
        </div >
    );
};

export default CreateEditSchool
