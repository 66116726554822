import React,{useContext} from 'react';
import axiosInstance from '../../config/axios';
import endpoints from '../../config/endpoints';
import {
    Grid,
    makeStyles,
    withStyles,
    Switch,
    TextField,
    Typography,
    Table,
    TableContainer,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Dialog,
    DialogTitle ,
    DialogContent,
    Button,
    DialogActions
} from '@material-ui/core';
import { AlertNotificationContext } from '../../context-api/alert-context/alert-state';
import Loading from '../../components/loader/loader';


const StyledButton = withStyles({
    root: {
        height: '40px',
        color: '#FFFFFF',
        backgroundColor: '#FF6B6B',
        //borderRadius: '10px',
        '&:hover': {
            backgroundColor: '#FF6B6B',
        },
    }
})(Button);

const StyledCancelButton = withStyles({
    root: {
        backgroundColor: '#E2E2E2',
        color: '#FF6B6B',
        height: '40px',
        marginRight: 'auto',
    },
})(Button);

const ModuleDataList = ({data, id, schoolDomain}) => {
    //console.log(data);
    const [checked, setChecked] = React.useState(data.is_delete === false ? false : true);
    const [open,setOpen] = React.useState(false);
    const [update, setUpdate] = React.useState(0);
    const [selectedSchool, setSelectedSchool] = React.useState('');
    const [moduleList, setModuleList] = React.useState([]);
    const [moduleData, setModuleData] = React.useState({
        status: '',
        module_name: '',
        mapping_id: '',
        module_id: '',
        school_domain: '',
    });
    const {setAlert} = useContext(AlertNotificationContext);
    const [loading, setLoading] = React.useState(false);

    const handleChange = (data) => {
        handleOpenDialog(data);
        //setModuleList({[event.target.name]: event.target.checked });
        //setChecked(true);
    };

    const handleOpenDialog = (data) => { 
        setModuleData({
            status: data.is_delete === false ? false : true,
            module_name: data.module_name,
            mapping_id: data.id,
            module_id: data.module_id,
            school_domain: schoolDomain,
        });
        setOpen(true);
    }
    const handleCloseDialog = () => {
        setOpen(false);
    }

    const handleComform = () => {
        //alert('Conform');
        setLoading(true);
        const params = {
            mapping_id: moduleData.mapping_id,
            module_id: moduleData.module_id.toString(),
            school_domain: moduleData.school_domain,
        };
        //console.log(moduleData);
        if(checked){
            axiosInstance.put(endpoints.centralManagement.activateModule,params)
            .then((res) => {
                console.log(res.data);
                setLoading(false);
                setAlert('success', res.data.message);
                setChecked(!checked);
                //setUpdate(update + 1);
            })
            .catch((error) => console.log(error))
            handleCloseDialog();
        }
        if(!checked){
            axiosInstance.put(endpoints.centralManagement.removeModule,params)
            .then((res) => {
                setLoading(false);
                console.log(res.data);
                setAlert('success', res.data.message);
                setChecked(!checked);
                //setUpdate(update + 1);
            })
            .catch((error) => console.log(error))
            handleCloseDialog();
        }
    }

    return(
        <>
            {loading ? <Loading message='Loading...' style={{ top: '160px', left: 0}}/> : null}
            <TableRow keys={data.id}>
                <TableCell>{id + 1}</TableCell>
                <TableCell>{data.module_name}</TableCell>
                <TableCell >    
                    {checked === false
                        ? <div style={{ color: 'green',fontWeight:'500'}}>Activated</div>
                        : <div style={{ color: 'red',fontWeight:'500' }}>Deactivated</div>
                    }
                </TableCell>
                <TableCell>
                    <Switch
                        checked={!checked}
                        onChange={(e) => handleChange(data)}
                        name={data.module_name}
                        color="primary"
                    />
                </TableCell>
            </TableRow>
            <Dialog
                open={open}
                onClose={handleCloseDialog}
            >
                <DialogContent>
                    <Typography>{`Are you sure you want to ${checked !== false? 'Activate' : 'Deactivate'} module ${moduleData.module_name}?`}</Typography>
                </DialogContent>
                <DialogActions>
                    <StyledCancelButton
                        variant='contained'
                        onClick={handleCloseDialog}
                    >
                        Cancel
                    </StyledCancelButton>
                    <StyledButton
                        variant='contained'
                        onClick={handleComform}
                    >
                        Confirm
                    </StyledButton >
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ModuleDataList;