import React, { useContext } from 'react';
// import Radio from '@material-ui/core/Radio';
import Checkbox from '@material-ui/core/Checkbox';
import ReactHtmlParser from 'react-html-parser';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core';
import { AttachmentPreviewerContext } from '../../../components/attachment-previewer/attachment-previewer-contexts';
import endpoints from '../../../config/endpoints';
import '../assess-attemption.css';

const useStyles = makeStyles((theme) => ({
  mcqOptions: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
    background: theme.palette.primary.primarylightest,
    padding: '10px',
    width: '100%',
    textAlign: 'justify',
    justifyContent: 'space-between',
    /* max-width: 400px, */
    cursor: 'pointer',
    marginBottom: '10px',
  },
}));

const McqQuestionMultiAnswer = (props) => {
  const classes = useStyles();
  const { questionObj: currentQuestionObj } = props || {};
  const { openPreview } = React.useContext(AttachmentPreviewerContext) || {};

  const {
    id: qId,
    question_answer: questionAnswer,
    user_response: { answer: existingAnswer = [] } = {},
    is_central: isCentral = false,
  } = currentQuestionObj || {};

  const [{ options, question }] = questionAnswer || [];

  const s3Image = endpoints.s3;

  return (
    <div>
      <div className='mcq-question-wrapper'>
        <div style={{ fontSize: '30px' }}>{ReactHtmlParser(question)}</div>
        <FormControl component='fieldset'>
          <RadioGroup aria-label='gender' name='options' value={existingAnswer}>
            <div
              className={classes.mcqOptions}
              style={{ display: 'grid', alignItems: 'center' }}
            >
              <FormControlLabel
                value='option1'
                checked={existingAnswer.includes('option1')}
                control={<Checkbox />}
                label={options[0].option1.optionValue}
              />
              <div className='imageContainer'>
                {options[0]?.option1?.images.length !== 0 ? (
                  <img
                    src={`${s3Image}${options[0]?.option1?.images[0]}`}
                    onClick={() => {
                      const fileSrc = `${s3Image}${options[0]?.option1?.images[0]}`;
                      openPreview({
                        currentAttachmentIndex: 0,
                        attachmentsArray: [
                          {
                            src: fileSrc,
                            name: `demo`,
                            extension: '.png',
                          },
                        ],
                      });
                    }}
                  />
                ) : null}
              </div>
            </div>

            <div
              className={classes.mcqOptions}
              style={{ display: 'grid', alignItems: 'center' }}
            >
              <FormControlLabel
                value='option2'
                checked={existingAnswer.includes('option2')}
                control={<Checkbox />}
                label={options[1].option2.optionValue}
              />
              <div className='imageContainer'>
                {options[1]?.option2?.images.length !== 0 ? (
                  <img
                    src={`${s3Image}${options[1]?.option2?.images[0]}`}
                    onClick={() => {
                      const fileSrc = `${s3Image}${options[1]?.option2?.images[0]}`;
                      openPreview({
                        currentAttachmentIndex: 0,
                        attachmentsArray: [
                          {
                            src: fileSrc,
                            name: `demo`,
                            extension: '.png',
                          },
                        ],
                      });
                    }}
                  />
                ) : null}
              </div>
            </div>
            {options[2]?.option3 ? (
              <div
                className={classes.mcqOptions}
                style={{ display: 'grid', alignItems: 'center' }}
              >
                <FormControlLabel
                  value='option3'
                  checked={existingAnswer.includes('option3')}
                  control={<Checkbox />}
                  label={options[2].option3.optionValue}
                />
                <div className='imageContainer'>
                  {options[2]?.option3?.images.length !== 0 ? (
                    <img
                      src={`${s3Image}${options[2]?.option3?.images[0]}`}
                      onClick={() => {
                        const fileSrc = `${s3Image}${options[2]?.option3?.images[0]}`;
                        openPreview({
                          currentAttachmentIndex: 0,
                          attachmentsArray: [
                            {
                              src: fileSrc,
                              name: `demo`,
                              extension: '.png',
                            },
                          ],
                        });
                      }}
                    />
                  ) : null}
                </div>
              </div>
            ) : null}

            {options[3]?.option4 ? (
              <div
                className={classes.mcqOptions}
                style={{ display: 'grid', alignItems: 'center' }}
              >
                <FormControlLabel
                  value='option4'
                  checked={existingAnswer.includes('option4')}
                  control={<Checkbox />}
                  label={options[3].option4.optionValue}
                />
                <div className='imageContainer'>
                  {options[3]?.option4?.images.length !== 0 ? (
                    <img
                      src={`${s3Image}${options[3]?.option4?.images[0]}`}
                      onClick={() => {
                        const fileSrc = `${s3Image}${options[3]?.option4?.images[0]}`;
                        openPreview({
                          currentAttachmentIndex: 0,
                          attachmentsArray: [
                            {
                              src: fileSrc,
                              name: `demo`,
                              extension: '.png',
                            },
                          ],
                        });
                      }}
                    />
                  ) : null}
                </div>
              </div>
            ) : null}
            {options[4]?.option5 ? (
              <div
                className={classes.mcqOptions}
                style={{ display: 'grid', alignItems: 'center' }}
              >
                <FormControlLabel
                  className={classes.mcqOptions}
                  value='option5'
                  checked={existingAnswer.includes('option5')}
                  control={<Checkbox />}
                  label={options[4].option5.optionValue}
                />
                <div className='imageContainer'>
                  {options[4]?.option5?.images.length !== 0 ? (
                    <img
                      src={`${s3Image}${options[4]?.option5?.images[0]}`}
                      onClick={() => {
                        const fileSrc = `${s3Image}${options[4]?.option5?.images[0]}`;
                        openPreview({
                          currentAttachmentIndex: 0,
                          attachmentsArray: [
                            {
                              src: fileSrc,
                              name: `demo`,
                              extension: '.png',
                            },
                          ],
                        });
                      }}
                    />
                  ) : null}
                </div>
              </div>
            ) : null}
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  );
};
export default McqQuestionMultiAnswer;
