import React, { createRef, useContext, useEffect, useState } from 'react';
import Divider from '@material-ui/core/Divider';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { Grid, TextField, Button, useTheme, SvgIcon } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect, useSelector, useDispatch } from 'react-redux';
// import download from '../../../assets/images/downloadAll.svg';
import { AlertNotificationContext } from '../../../../context-api/alert-context/alert-state';
import endpoints from '../../../../config/endpoints';
import axiosInstance from '../../../../config/axios';
import { Breadcrumb, Form, message, Select } from 'antd';
import { addSection, setFilter } from '../../../../redux/actions';
import { fetchGrades } from '../../../lesson-plan/create-lesson-plan/apis'; 
import { PlusOutlined } from '@ant-design/icons';
import { useFormik } from 'formik';
import cuid from 'cuid';
import Layout from '../../../Layout';
import "./filters.css";

// SECTION IMAGES
import SECTION1 from "./../../../../assets/images/section/section1.png";
import SECTION2 from "./../../../../assets/images/section/section2.png";
import SECTION4 from "./../../../../assets/images/section/section4.png";
import SECTION6 from "./../../../../assets/images/section/section6.png";
import SECTION8 from "./../../../../assets/images/section/section8.png";

// SECTION IMAGES
import SECTIONHOVER1 from "./../../../../assets/images/section/section-hover1.png";
import SECTIONHOVER2 from "./../../../../assets/images/section/section-hover2.png";
import SECTIONHOVER4 from "./../../../../assets/images/section/section-hover4.png";
import SECTIONHOVER6 from "./../../../../assets/images/section/section-hover6.png";
import SECTIONHOVER8 from "./../../../../assets/images/section/section-hover8.png";

const { Option } = Select;

const Filters = () => {
  const { setAlert } = useContext(AlertNotificationContext);
  const themeContext = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const formRef = createRef();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const wider = isMobile ? '-10px 0px' : '-10px 0px 20px 8px';
  const widerWidth = isMobile ? '98%' : '95%';
  const [academicDropdown, setAcademicDropdown] = useState([]);
  const selectedAcademicYear = useSelector(
    (state) => state.commonFilterReducer?.selectedYear
  );
  const sections = useSelector((state) => state.createQuestionPaper.questions);
  const is_ERP_CENTRAL = [
    { id: 1, flag: false, name: 'ERP' },
    { id: 2, flag: true, name: 'CENTRAL' },
  ];
  const [erpCategory, setErpCategory] = useState();
  const [selectedGrade, setSelectedGrade] = useState();
  const [erpCategoryDropdown, setErpGradeDropdown] = useState([]);
  const [gradeDropdown, setGradeDropdown] = useState([]);
  const [subjectDropdown, setSubjectDropdown] = useState([]);

  const selectedBranch = useSelector(
    (state) => state.commonFilterReducer?.selectedBranch
  );

  const filterDataQP = JSON.parse(sessionStorage.getItem('filter')) || [];
  // question level input
  const qpLevel = [
    { id: 1, level: 'Easy' },
    { id: 2, level: 'Average' },
    { id: 3, level: 'Difficult' },
  ];

  const NavData = JSON.parse(localStorage.getItem('navigationData')) || {};
  const [moduleId, setModuleId] = useState('');

  useEffect(() => {
    if (NavData && NavData.length) {
      NavData.forEach((item) => {
        if (
          item.parent_modules === 'Assessment' &&
          item.child_module &&
          item.child_module.length > 0
        ) {
          item.child_module.forEach((item) => {
            if (item.child_name === 'Question Paper') {
              setModuleId(item.child_id);
            }
          });
        }
      });
    }
  }, []);

  const question_level_options = [
    { value: 1, Question_level: 'Easy' },
    { value: 2, Question_level: 'Average' },
    { value: 3, Question_level: 'Difficult' },
  ];
  const questionLeveloptions = question_level_options?.map((each) => {
    return (
      <Option key={each?.value} value={each.value}>
        {each?.Question_level}
      </Option>
    );
  });

  const erpCategories = erpCategoryDropdown?.map((each) => {
    return (
      <Option key={each?.id} value={each.erp_category_id}>
        {each?.erp_category_name}
      </Option>
    );
  });
  useEffect(() => {
   
      getGrades();
      // getErpCategory();
  }, []);


  const handleGrade = (e, value) => {
    setSelectedGrade();
    if (value) {
      setSelectedGrade(value);
    } else {
      setSelectedGrade();
    }
  };

  const handleAddSection = (i) => {
    let len = sections?.length || 0;
    const sectionArray = [
      {
        id: cuid(),
        name: `${String.fromCharCode(65 + i)}`,
        questions: [],
        instruction: '',
        mandatory_questions: 1,
        test_marks: [],
      },
    ];
    const question = { id: cuid(), sections: sectionArray };
    // initAddSection(question);
    dispatch(addSection(question));
  };

  // const handleSubject = (value) => {

  //   if(value){
  //       let subject = subjectDropdown?.filter((item) => item?.subject_id === value?.value)
  //       // formik.setFieldValue('subject',subject[0])
  //   }else{
  //       // formik.setFieldValue('subject','')
  //   }
  // }

  const handleSectionCount = (count) => {
    if (!selectedGrade) {
      return setAlert('error', 'Please Select Grade');
    } else {
      for (let i = 0; i < count; i++) {
        handleAddSection(i);
      }
      history.push({
        pathname: '/create-questionspaper',
        state: {
          sectionCount: count,
          Grade: selectedGrade,
        },
      });
    }
  };

  const getGrades = async () => {
    try {
      const data = await fetchGrades();
      setGradeDropdown(data);
    } catch (e) {
      setAlert('error', 'Failed to fetch grades');
    }
  };

  // const getGrades = () => {
  //   axiosInstance
  //     .get(
  //       `${endpoints.academics.grades}?session_year=${selectedAcademicYear?.id}&branch_id=${selectedBranch?.branch?.id}&module_id=${moduleId}`
  //     )
  //     .then((result) => {
  //       if (result?.data?.status_code === 200) {
  //         setGradeDropdown(result?.data?.data);
  //       } else {
  //         setAlert('error', result?.data?.message);
  //       }
  //     })
  //     .catch((error) => {
  //       setAlert('error', error?.message);
  //     });
  // };

  const gradeOptions = gradeDropdown?.map((each) => {
    return (
      <Option key={each?.id} value={each.grade_id}>
        {each?.grade_name}
      </Option>
    );
  });

  return (
    <Layout>
      <div className='p-3' style={{ zIndex: 2 }}>
        <Breadcrumb separator='>'>
          <Breadcrumb.Item className='th-grey th-16'>Assessment</Breadcrumb.Item>
          <Breadcrumb.Item className='th-black-1 th-16 th-pointer' onClick={() => history.push('/assessment-question')}>
            Question Paper
          </Breadcrumb.Item>
          <Breadcrumb.Item className='th-black-1 th-16'>Create</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className='mx-3'>
        <div className='th-bg-white py-2 px-3'>
          <div className='col-12'>
            <Form id='filterForm' ref={formRef} layout={'horizontal'}>
              <div className='row align-items-center'>
                {/* {boardFilterArr.includes(window.location.host) && ( */}
                {/* )} */}
                <div className='col-md-2 col-6 px-1'>
                  <div className='mb-2 text-left th-fw-600'>Grade</div>
                  <Form.Item name='grade'>
                    <Select
                      allowClear
                      placeholder={
                        // filterData?.grade ? filterData?.grade?.children :
                        'Select Grade'
                      }
                      showSearch
                      optionFilterProp='children'
                      getPopupContainer={(trigger) => trigger.parentNode}
                      filterOption={(input, options) => {
                        return (
                          options.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                      onChange={(e, value) => {
                        handleGrade(e, value);
                      }}
                      //   onClear={handleClearGrade}
                      className='w-100 text-left th-black-1 th-bg-grey th-br-6'
                      bordered={false}
                    >
                      {gradeOptions}
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
          <div className='col-md-12'>
            <hr className='my-2' />
            <div className='row py-2 th-fw-600'>Choose Question Template</div>
            <div className='row p-3 th-bg-grey justify-content-between align-items-center'>
              <div
                className='col-md-2 mb-3 th-pointer'
                onClick={() => handleSectionCount(1)}
                style={{ height: '200px' }}
              >
                <div className='section1'>
                  <img src={SECTION1} alt="section1" className='section_image' />
                  <img src={SECTIONHOVER1} alt="section-hover1" className='section_hover_image section_hover_image1' />
                </div>
              </div>
              <div
                className='col-md-2 mb-3 th-pointer'
                onClick={() => handleSectionCount(2)}
                style={{ height: '200px' }}
              >
                <div className='section2'>
                  <img src={SECTION2} alt="section2" className='section_image' />
                  <img src={SECTIONHOVER2} alt="section-hover2" className='section_hover_image section_hover_image2' />
                </div>

              </div>
              <div
                className='col-md-2 mb-3 th-pointer'
                onClick={() => handleSectionCount(4)}
                style={{ height: '200px' }}
              >
                <div className='section4'>
                  <img src={SECTION4} alt="section4" className='section_image' />
                  <img src={SECTIONHOVER4} alt="section-hover4" className='section_hover_image section_hover_image4' />
                </div>

              </div>
              <div
                className='col-md-2 mb-3 th-pointer'
                onClick={() => handleSectionCount(6)}
                style={{ height: '200px' }}
              >
                <div className='section6'>
                  <img src={SECTION6} alt="section6" className='section_image' />
                  <img src={SECTIONHOVER6} alt="section-hover6" className='section_hover_image section_hover_image6' />
                </div>

              </div>
              <div
                className='col-md-2 mb-3 th-pointer '
                onClick={() => handleSectionCount(8)}
                style={{ height: '200px' }}
              >
                <div className='section8'>
                  <img src={SECTION8} alt="section8" className='section_image' />
                  <img src={SECTIONHOVER8} alt="section-hover8" className='section_hover_image section_hover_image8' />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Filters;
