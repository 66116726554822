/* eslint-disable react/jsx-no-duplicate-props */
import React, { useContext, useEffect, useState, useRef } from 'react';
import Paper from '@material-ui/core/Paper';
import {
  Grid,
  useTheme,
  SvgIcon,
  Button,
  IconButton,
  Slide,
  Dialog,
  DialogContent,
  Box,
  DialogActions,
  Checkbox,
  FormControlLabel,
  FormGroup
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Pagination } from '@material-ui/lab';
// import Pagination from '../../../components/Pagination/index'
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import Layout from '../../Layout';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import CommonBreadcrumbs from '../../../components/common-breadcrumbs/breadcrumbs';
import endpoints from '../../../config/endpoints';
import axiosInstance from '../../../config/axios';
import './question-bank.css';
import Loading from '../../../components/loader/loader';

import QuestionBankCard from './question-bank-card';
import QuestionBankFilters from './question-bank-filter';
import ViewMoreCard from './view-more-card';
import TabPanel from './tab-panel';
import unfiltered from '../../../assets/images/unfiltered.svg';
import selectfilter from '../../../assets/images/selectfilter.svg';
import hidefilter from '../../../assets/images/hidefilter.svg';
import showfilter from '../../../assets/images/showfilter.svg';
import { addQuestionToSection } from '../../../redux/actions';
import QuestionHandler from '../../question-handler/question-handler';
import { Cancel } from '@material-ui/icons';
import DoneAllIcon from '@material-ui/icons/DoneAll';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Andika New Basic, sans-serif',
    width: '100%',
    margin: '-10px auto',
    boxShadow: 'none',
  },
  container: {
    maxHeight: '70vh',
    width: '100%',
  },
}));

const QuestionBankList = ({ sections, initAddQuestionToSection }) => {
  const classes = useStyles();
  const { setAlert } = useContext(AlertNotificationContext);
  const questionBankFilter = JSON.parse(localStorage.getItem('questionBankFilter'));
  let pageValue = 1;
  if (questionBankFilter?.page >= 1) {
    pageValue = questionBankFilter.page;
  }
  const [page, setPage] = useState(pageValue);
  const [periodData, setPeriodData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [viewMore, setViewMore] = useState(false);
  const [viewMoreData, setViewMoreData] = useState('');
  const [periodDataForView, setPeriodDataForView] = useState([]);
  const limit = 20;
  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const [isFilter, setIsFilter] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [tabMapId, setTabMapId] = useState('');
  const [tabQueLevel, setTabQueLevel] = useState('');
  const [tabQueTypeId, setTabQueTypeId] = useState('');
  const [tabQueCatId, setTabQueCatId] = useState('');
  const [tabTopicId, setTabTopicId] = useState('');
  const [tabChapterId, setTabChapterId] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);
  const questionId = query.get('question');
  const section = query.get('section');
  const [clearFlag, setClearFlag] = useState(false);
  const [callFlag, setCallFlag] = useState(false);
  const [val, setVal] = useState('');
  const filterRef = useRef(null);
  const [isPreview, setIsPreview] = useState(false);
  const isEdit = query.get('isedit');
  const [selectedId, setSelectedId] = useState([])
  const [isVisible,setIsVisible] = useState([])
  const [redFlag,setRedflag] =useState([])
  const [isSelectAll, setIsSelectAll] = useState(false)
  const [isSelectAllQuestion, setIsSelectAllQuestion] = useState(false);
  const [selectedIdQuestion,setSelectedIdQuestion] = useState([])
  const [selectedQuestion , setSelectedQuestion ] = useState([])

  const addQuestionToPaper = (question, questionId, section) => {
    initAddQuestionToSection(question, questionId, section);
    setAlert('success', 'Question added successfully!');
    filterRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });
  };
  const handleAddQuestionToQuestionPaper = (question) => {
    const questionIds = [];
    sections.forEach((q) => {
      q.sections[0].questions.forEach(({ id = '' }) => questionIds.push(id) || {});
    });
    if (!questionIds.includes(question?.id)) {
      addQuestionToPaper(question, questionId, section);
      // history.push(`/create-question-paper?show-question-paper=true`);
    } else {
      setAlert('error', 'Question already added!');
    }
  };

  // useEffect(() => {
  //   if (page >= 1) {

  //     handleFetchData()
  //   }
  // }, [page])

  const handleFetchData = (
    tabId = 0,
    subjectMapppingId,
    quesTypeId,
    quesCatId,
    quesLevel,
    topicId,
    chapterId
  ) => {
    setTabValue(tabId);
    let val = subjectMapppingId;
    setVal(val);
    const questionBankFilter = JSON.parse(localStorage.getItem('questionBankFilter'));
    let currentPage = page;
    if (questionBankFilter?.page >= 1) {
      currentPage = questionBankFilter.page;
    }
    let requestUrl = `${endpoints.questionBank.questionData}?mapping_id=${subjectMapppingId}&page=${currentPage}&page_size=${limit}`;
    if (tabId) {
      requestUrl += `&question_status=${tabId}`;
    }
    if (chapterId) {
      requestUrl += `&chapter_id=${chapterId}`;
    }
    if (quesTypeId) {
      requestUrl += `&question_type=${quesTypeId}`;
    }
    if (quesCatId) {
      requestUrl += `&question_categories=${quesCatId}`;
    }
    if (topicId) {
      requestUrl += `&topic=${topicId}`;
    }
    if(quesLevel){
      requestUrl += `&question_level=${quesLevel}`;
    }
    axiosInstance
      .get(requestUrl)
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setTotalCount(result?.data?.result?.count);
          setLoading(false);
          let tempArray = result?.data?.result?.results?.map((items)=>{
            items["checked"] = false;
          })
          // let dummy =[...result?.data?.result?.results,checked:false]
          // console.log(dummy,"CK2")
          setPeriodData(result?.data?.result?.results)
          // setPeriodData(tempArray);
          let isVisible =result?.data?.result?.results.map((question) => question?.question_status === "3").filter((ques)=> ques === true)
          setIsVisible(isVisible)
          setViewMore(false);
          setViewMoreData({});
        } else {
          setLoading(false);
          setAlert('error', result?.data?.description);
        }
      })
      .catch((error) => {
        setLoading(false);
        setAlert('error', error?.message);
      });
  };
  const handlePagination = (event, page) => {
    console.log(page);
    setSelectedIndex(-1);
    setPage(page);
  };
  const handlePeriodList = (
    quesTypeId,
    quesCatId,
    subjMapId,
    quesLevel,
    topicId,
    newValue,
    chapterId
  ) => {
    setLoading(true);
    setTabMapId(subjMapId);
    setPeriodData([]);
    setTabQueTypeId(quesTypeId);
    setTabQueCatId(quesCatId);
    setTabTopicId(topicId);
    setTabQueLevel(quesLevel);
    setTabChapterId(chapterId);
    handleFetchData(
      newValue,
      subjMapId?.id,
      quesTypeId,
      quesCatId?.value,
      quesLevel?.value,
      topicId?.id,
      chapterId
    );
  };
  useEffect(() => {
    console.log(tabMapId , page);
    if (tabMapId && page) {
      setIsSelectAll(false)
      setIsSelectAllQuestion(false)
      setSelectedId([])
      setSelectedIndex(-1);
      handlePeriodList(
        tabQueTypeId,
        tabQueCatId,
        tabMapId,
        tabQueLevel,
        tabTopicId,
        tabValue,
        tabChapterId
      );
    }
  }, [page, tabValue, callFlag]);

  useEffect(() => {
    setTabMapId('');
    setTabQueLevel('');
    setTabQueTypeId('');
    setTabQueCatId('');
    setTabTopicId('');
    setTabChapterId('');
    setSelectedId([])
    setIsSelectAll(false)
    setSelectedIdQuestion([])
    setIsSelectAllQuestion(false)
    setIsVisible([])
  }, [clearFlag]);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction='up' ref={ref} {...props} />;
    // return <Fade direction='in' ref={ref} {...props} />
  });

  const handlePublish = () => {
    if(selectedId.length <=0 ){
      setAlert('error', 'Please Select Question' )
      return
    }else{
      axiosInstance
      .put(`${endpoints.questionBank.multiPublishQuestion}`, {
        question_status: 2,
        question: selectedId.toString(),
      })
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setSelectedIndex(-1);
          setCallFlag((prev) => !prev);
          window.location.reload();
          setAlert('success', result?.data?.message);
          setIsSelectAll(false)
          setSelectedId([])
        } else {
          setAlert('error', 'ERROR!');
        }
      })
      .catch((error) => setAlert('error', error?.message));

    }
  };

  const toggleCompleteQuestion = (e, question, index) => {
    const {name,checked} = e.target;
    console.log(name , checked);
    console.log(question ,'hit');
    if(name === "allSelect"){
      if(checked === true){
        setIsSelectAllQuestion(true)
        setRedflag(true)
        let tempData = [...periodData]
        let tempArr = tempData.map((item)=>{ return {...item, checked}})
        let temQuestionId = tempArr.filter((item) => item?.question_status === "2").map((ques) => ques?.id)
        let tempQues = tempArr.filter((item) => item?.question_status === "2").map((ques) => ques)
        setSelectedIdQuestion(temQuestionId)
        setSelectedQuestion(tempQues)
        setPeriodData(tempArr)
        // setLoading(false)

      } else{
        setIsSelectAllQuestion(false)
        setRedflag(false)
        let tempData = [...periodData]
        let tempArr = tempData.map((item)=>{ return {...item, checked}})
        setSelectedIdQuestion([])
        setSelectedQuestion([])
        setPeriodData(tempArr)
        // setLoading(false)
      }

    }else{

      // for child component ->
      setIsSelectAllQuestion(false)
      let tempAllData =   [...periodData];
      let newData = {...periodData[index], checked}
      console.log("checking12",periodData[index],newData);
      tempAllData.splice(index, 1, newData);
      setPeriodData(tempAllData);
      if(selectedIdQuestion.includes(question?.id) === false){
        setSelectedIdQuestion([...selectedIdQuestion,question?.id])
        setSelectedQuestion([...selectedQuestion,question])
          setLoading(false)
      }else{
        let tempArr=[]
        let tempQues = []
        tempArr=selectedIdQuestion.filter((el) => el !== question?.id)
        tempQues = selectedQuestion.filter((el) => el?.id !== question?.id)
        console.log(tempQues);
        setSelectedIdQuestion(tempArr)
        setSelectedQuestion(tempQues)
        setIsSelectAllQuestion(false)
        console.log(tempArr);
      }

    }
    console.log(selectedQuestion);
  }

  const handleAdd = () => {
    let callRedux = selectedQuestion?.map((item , index) => {
      handleAddQuestionToQuestionPaper(item)
    })
  }


  const handleMultiplePublish = (e, question, index) => {
    // debugger;

    //for parent component ->

    const {name,checked} = e.target;
    if(name === "allSelect"){
      if(checked === true){
        setIsSelectAll(true)
        setRedflag(true)
        let tempData = [...periodData]
        let tempArr = tempData.map((item)=>{ return {...item, checked}})
        let temQuestionId = tempArr.filter((item) => item?.question_status === "3").map((ques) => ques?.id)
        setSelectedId(temQuestionId)
        setPeriodData(tempArr)

      } else{
        setRedflag(false)
        setIsSelectAll(false)
        let tempData = [...periodData]
        let tempArr = tempData.map((item)=>{ return {...item, checked}})
        setSelectedId([])
        setPeriodData(tempArr)
      }

    }else{

      // for child component ->

      let tempAllData =   [...periodData];
      let newData = {...periodData[index], checked}
      tempAllData.splice(index, 1, newData);
      setPeriodData(tempAllData);
      if(selectedId.includes(question?.id) === false){
          setSelectedId([...selectedId,question?.id])
          setLoading(false)
          setIsSelectAll(false)
      }else{
        let tempArr=[]
        tempArr=selectedId.filter((el) => el !== question?.id)
        setSelectedId(tempArr)
        setIsSelectAll(false)
      }

    }
  }
  return (
    <>
      {loading ? <Loading message='Loading...' /> : null}
      <Layout>
        <div className={isMobile ? 'breadCrumbFilterRow' : null}>
          <div
            style={{
              width: '95%',
              margin: '20px auto',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ width: '100%', fontFamily: 'Andika New Basic, sans-serif' }}>
              <CommonBreadcrumbs
                componentName='Assesment'
                childComponentName='Question Bank'
              />
            </div>

            <div className='hideShowFilterIcon'>
              <IconButton onClick={() => setIsFilter(!isFilter)}>
                <div
                  style={{
                    color: '#014b7e',
                    fontSize: '16px',
                    marginRight: '10px',
                    fontWeight: '600',
                    alignSelf: 'center',
                    fontFamily: 'Andika New Basic, sans-serif',
                  }}
                >
                  {isFilter ? 'Close Filter' : 'Expand Filter'}
                </div>
                <SvgIcon
                  component={() => (
                    <img
                      style={{ height: '20px', width: '25px' }}
                      src={isFilter ? hidefilter : showfilter}
                    />
                  )}
                />
              </IconButton>
            </div>
          </div>
        </div>

        <div
          // className={isFilter ? 'showFilters' : 'hideFilters'}
          style={{ fontFamily: 'Andika New Basic, sans-serif' }}
          ref={filterRef}
        >
          <QuestionBankFilters
            questionId={questionId}
            section={section}
            handlePeriodList={handlePeriodList}
            handleFetchData={handleFetchData}
            setPeriodData={setPeriodData}
            setViewMore={setViewMore}
            val={val}
            selectedIndex={selectedIndex}
            setViewMoreData={setViewMoreData}
            setSelectedIndex={setSelectedIndex}
            page={page}
            setPage={setPage}
            setClearFlag={setClearFlag}
            isEdit={isEdit}
            isFilter={isFilter}

          />
        </div>
        <Grid
          container
          style={
            isMobile
              ? { width: '95%', margin: '20px auto' }
              : { width: '100%', margin: '20px auto' }
          }
          spacing={5}
        >
          <Grid item xs={12} sm={12}>
            <TabPanel setTabValue={setTabValue} tabValue={tabValue} setPage={setPage} />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          {isVisible.length !== 0 && window.location.search?.length == 0  ? (
          <Grid container spacing={3}>
            <Grid item xs={3}>
            <Button
              variant="contained"
              color="secondary"
              style={{ margin: '0.5rem', color: 'white', width:'100%'}}
              // className={classes.button}
              checked={isSelectAll}
              startIcon={<DoneAllIcon/>}
              disabled={selectedId.length === 0 ? true : false }
              size='medium'
              onClick={(e) => handlePublish()}
             >
             Bulk Publish
            </Button>
            </Grid>
            <Grid item xs={1}>
            {/* <FormGroup row> */}
            <FormControlLabel
                style={{minWidth:"150px"}}
                control={<Checkbox 
                  // checked={state.checkedA} 
                  checked={isSelectAll}
                  onChange={(e) => handleMultiplePublish(e,periodData)}
                  name="allSelect" />}
                label="Select All"
              />
            {/* </FormGroup> */}
            </Grid>
          </Grid>


           ):" "} 
            {window.location.search?.length > 0 ?(
          <Grid item xs={12} sm={12} 
          >
            <Grid container spacing={3} style={{alignItems:"center"}}>
               <Grid item xs={3}>
            <Button
              style={{ margin: '0.5rem', color: 'white', width:'100%'}}
              onClick={(e) => handleAdd()}
              color='primary'
              disabled={selectedIdQuestion.length === 0 ? true : false }
              variant='contained'
              size='medium'
              startIcon={<DoneAllIcon/>}
            >
              ADD QUESTIONS
            </Button> 

            </Grid>

            <Grid item xs={3}>
            <FormControlLabel
                style={{minWidth:"150px"}}
                control={<Checkbox 
                checked={isSelectAllQuestion}
                onChange={(e) => toggleCompleteQuestion(e,periodData)} 
                name="allSelect" />}
                label="Select All"
              />
            </Grid>
            
            </Grid>
         
          </Grid>

          ): ''}
        </Grid>
        <Grid
          container
          style={
            isMobile
              ? { width: '95%', margin: '20px auto' }
              : { width: '100%', margin: '20px auto' }
          }
          spacing={5}
        ></Grid>
        <Paper className={classes.root}>
          {periodData?.length > 0 ? (
            <Grid
              container
              style={
                isMobile
                  ? { width: '95%', margin: '20px auto' }
                  : { width: '100%', margin: '20px auto' }
              }
              spacing={5}
            >
              <Grid item xs={12} sm={viewMore ? 6 : 12}>
                <Grid container spacing={isMobile ? 3 : 5}>
                  {periodData.map((period, i) => (
                    <Grid
                      item
                      xs={12}
                      style={isMobile ? { marginLeft: '-8px' } : null}
                      sm={viewMore ? 6 : 4}
                    >
                      <QuestionBankCard
                        index={i}
                        period={period}
                        setSelectedIndex={setSelectedIndex}
                        periodColor={selectedIndex === i}
                        viewMore={viewMore}
                        setLoading={setLoading}
                        setViewMore={setViewMore}
                        setViewMoreData={setViewMoreData}
                        setPeriodDataForView={setPeriodDataForView}
                        setCallFlag={setCallFlag}
                        onClick={
                          questionId && section ? handleAddQuestionToQuestionPaper : null
                        }
                        showAddToQuestionPaper={questionId && section}
                        handleMultiplePublish={handleMultiplePublish}
                        redFlag={redFlag}
                        // checkbox={checkbox}
                        periodData={periodData}
                        toggleCompleteQuestion={toggleCompleteQuestion}
                        isSelectAllQuestion={isSelectAllQuestion}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              {viewMore && (
                <Grid item xs={12} sm={5} style={{ width: '100%' }}>
                  <ViewMoreCard
                    setSelectedIndex={setSelectedIndex}
                    viewMoreData={viewMoreData}
                    setViewMore={setViewMore}
                    periodDataForView={periodDataForView}
                    setCallFlag={setCallFlag}
                    isPreview={isPreview}
                    setIsPreview={setIsPreview}
                  />
                </Grid>
              )}
            </Grid>
          ) : (
            <div className='periodDataUnavailable'>
              <SvgIcon
                component={() => (
                  <img
                    style={
                      isMobile
                        ? { height: '100px', width: '200px' }
                        : { height: '160px', width: '290px' }
                    }
                    src={unfiltered}
                  />
                )}
              />
              <SvgIcon
                component={() => (
                  <img
                    style={
                      isMobile
                        ? { height: '20px', width: '250px' }
                        : { height: '50px', width: '400px', marginLeft: '5%' }
                    }
                    src={selectfilter}
                  />
                )}
              />
            </div>
          )}
          {periodData?.length > 0 && (
            <div className='paginateData paginateMobileMargin'>
              <Pagination
                onChange={handlePagination}
                style={{ marginTop: 25 }}
                count={Math.ceil(totalCount / limit)}
                color='primary'
                page={page}
              />
            </div>
          )}
        </Paper>
        {isPreview && (
          <Dialog
            onClose={() => setIsPreview(false)}
            fullScreen
            open={isPreview}
            TransitionComponent={Transition}
          >
            <Box style={{ margin: '100px 20px 50px 20px' }}>
              <DialogActions style={{ margin: '0 15px' }}>
                <Button
                  onClick={() => setIsPreview(false)}
                  aria-label='close'
                  size='small'
                  style={{
                    color: 'white',
                    backgroundColor: '#014b7e',
                    float: 'right',
                    borderRadius: '10px',
                    textTransform: 'none',
                  }}
                >
                  <CloseIcon />
                  Close Preview
                </Button>
              </DialogActions>
              <DialogContent>
                <QuestionHandler
                  questionObj={viewMoreData['parent'] || {}}
                  questionsDataObj={viewMoreData['child'] || {}}
                />
              </DialogContent>
            </Box>
          </Dialog>
        )}
      </Layout>
    </>
  );
};

const mapStateToProps = (state) => ({
  sections: state.createQuestionPaper.questions,
});

const mapDispatchToProps = (dispatch) => ({
  initAddQuestionToSection: (question, questionId, section) => {
    dispatch(addQuestionToSection(question, questionId, section));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionBankList);
