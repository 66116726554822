import React, { useContext, useEffect, useState } from 'react';
import Divider from '@material-ui/core/Divider';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import { Grid, TextField, Button, useTheme, SvgIcon } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import download from '../../../assets/images/downloadAll.svg';
import { AlertNotificationContext } from '../../../context-api/alert-context/alert-state';
import endpoints from '../../../config/endpoints';
import axiosInstance from '../../../config/axios';
import './question-bank.css';
import FileSaver from 'file-saver';

const QuestionBankFilters = ({
  questionId,
  handlePeriodList,
  setPeriodData,
  setViewMore,
  setViewMoreData,
  setSelectedIndex,
  page,
  setPage,
  setClearFlag,
  section,
  isEdit,
  isFilter
}) => {
  const { setAlert } = useContext(AlertNotificationContext);
  const themeContext = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const wider = isMobile ? '-10px 0px' : '-10px 0px 20px 8px';
  const widerWidth = isMobile ? '98%' : '95%';
  const [gradeDropdown, setGradeDropdown] = useState([]);
  const [subjectDropdown, setSubjectDropdown] = useState([]);
  const [chapterDropdown, setChapterDropdown] = useState([]);
  const [topicDropdown, setTopicDropdown] = useState([]);
  const [queTypeDropdown, setQueTypeDropdown] = useState([]);
  const [boardDropdown, setBoardDropdown] = useState([]);
  const [selectedBoardId, setSelectedBoardId] = useState([]);
  const [selectedModuleId, setSelectedModuleId] = useState([]);
  const [moduleDropdown, setModuleDropdown] = useState([]);
  const [academicYearDropdown, setAcademicYearDropdown] = useState([]);
  const [filterData, setFilterData] = useState({
    year: '',
    grade: '',
    subject: '',
    chapter: '',
    quesType: '',
    topicId: '',
    question_level: '',
    question_category: '',
  });

  const question_level_options = [
    { value: 1, Question_level: 'Easy' },
    { value: 2, Question_level: 'Average' },
    { value: 3, Question_level: 'Difficult' },
  ];

  const question_categories_options = [
    { value: 1, q_cat: 'Knowledge' },
    { value: 2, q_cat: 'Understanding' },
    { value: 3, q_cat: 'Application' },
    { value: 4, q_cat: 'Analysis' },
    { value: 5, q_cat: 'Remembering' },
    { value: 6, q_cat: 'Evaluation' },
    { value: 7, q_cat: 'Creating' },
    
  ];


  const handleClear = () => {
    setFilterData({
      year: '',
      grade: '',
      subject: '',
      chapter: '',
      topicId: '',
      quesType: '',
      question_level: '',
      question_category: '',
    });
    setPeriodData([]);
    setSubjectDropdown([]);
    setChapterDropdown([]);
    setViewMoreData({});
    setViewMore(false);
    setSelectedIndex(-1);
    setPage(1);
    setClearFlag((prev) => !prev);
  };

  const handleTopic = (event, value) => {
    setFilterData({
      ...filterData,
      topicId: '',
    });
    setPage(1);
    if (value) {
      setFilterData({ ...filterData, topicId: value });
      setPage(1);
    }
  };

  const handleQuestionCategory = (event, value) => {
    setFilterData({ ...filterData, question_category: '' });
    setPeriodData([]);
    setTopicDropdown([]);
    setPage(1);
    if (value) {
      setFilterData({ ...filterData, question_category: value });
      setPage(1);
    }
  };
  const handleQuestionLevel = (event, value) => {
    setFilterData({
      ...filterData,
      question_level: '',
    });
    setPeriodData([]);
    setPage(1);
    if (value) {
      setFilterData({ ...filterData, question_level: value });
      setPage(1);
    }
  };
  const handleQuestionType = (event, value) => {
    setFilterData({ ...filterData, quesType: '' });
    setPeriodData([]);
    setPage(1);
    if (value) {
      setFilterData({ ...filterData, quesType: value });
      setPage(1);
      setPeriodData([]);
    }
  };

  function callApi(api, key) {
    axiosInstance
      .get(api)
      .then((result) => {
        if (result?.data?.status_code === 200) {
          if (key === 'subject') {
            setSubjectDropdown(result?.data?.result?.results);
          }
          else if (key === 'chapter') {
            setChapterDropdown(result?.data?.result);
          }
          else if (key === 'topic') {
            setTopicDropdown(result?.data?.result);
          }
        }
      })
      .catch((error) => {
        setAlert('error', error?.message);
      });
  }

  const handleGrade = (event, value) => {
    setFilterData({
      ...filterData,
      grade: '',
      subject: '',
      chapter: '',
      topicId: '',
      question_level: '',
      question_category: '',
      board: '',
    });
    setSubjectDropdown([]);
    setChapterDropdown([]);
    setBoardDropdown([]);
    setPeriodData([]);
    setTopicDropdown([]);
    setPage(1);
    if (value) {
      setFilterData({ ...filterData, grade: value, subject: '', chapter: '' });
      setPage(1);
      axiosInstance
        .get(`${endpoints.questionBank.subjects}?grade=${value?.id}`)
        .then((result) => {
          if (result?.data?.status_code === 200) {
            setSubjectDropdown(result?.data?.result?.results);
          } else {
            setAlert('error', result?.data?.message);
            setSubjectDropdown([]);
            setChapterDropdown([]);
          }
        })
        .catch((error) => {
          setAlert('error', error?.message);
          setSubjectDropdown([]);
          setChapterDropdown([]);
        });
    }
  };


  const handleSubject = (event, value) => {
    // setLoading(true)
    // setFilterData({ ...filterData, subject: '', board: '', module: '', chapter: '' });
    setFilterData({
      ...filterData,
      subject: '',
      board: '', module: '',
      chapter: '',
      topicId: '',
      question_level: '',
      question_category: '',
    });
    // setSubjectDropdown([]);
    setChapterDropdown([]);
    setBoardDropdown([]);
    setPeriodData([]);
    setTopicDropdown([]);
    setPage(1);
    // setOverviewSynopsis([]);
    if (value) {
      // setLoading(true)
      setFilterData({ ...filterData, subject: value });
      axiosInstance
        .get(`${endpoints.masterManagement.boardMappingList}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            // setLoading(false)
            setBoardDropdown(result.data.result);
          } else {
            // setLoading(false)
            setAlert('error', result.data.message);
            setBoardDropdown([]);
          }
        })
        .catch((error) => {
          // setLoading(false)
          setAlert('error', error.message);
          setBoardDropdown([]);
        });
    } else {
      // setLoading(false)
      setBoardDropdown([]);
    }

  }

  const handleBoard = (event = {}, values = []) => {
    // setLoading(true)
    setFilterData({ ...filterData, board: '', module: '', chapter: '' });
    // setOverviewSynopsis([]);
    if (values) {
      // setLoading(true)
      const ids = values.map((el) => el);
      const selectedId = values.map((el) => el?.id)
      setSelectedBoardId(selectedId)
      setFilterData({ ...filterData, board: ids, chapter: '' });
      axiosInstance
        .get(`${endpoints.masterManagement.module}?academic_year=${filterData?.year?.id}&grade_subject=${filterData?.subject?.id}&board=${selectedId}&page_size=${50}`)
        .then((result) => {
          if (result.data.status_code === 200) {
            // setLoading(false)
            setModuleDropdown(result.data.result);

          } else {
            // setLoading(false)
            setAlert('error', result.data.message)
            setSelectedBoardId([])
          }
        })
        .catch((error) => {
          // setLoading(false)
          setAlert('error', error.message);
          setSelectedBoardId([]);
        })
    } else {
      // setLoading(false)
      setSelectedBoardId([]);
    }
  }

  const handleChapter = (event, value) => {
    setFilterData({
      ...filterData,
      chapter: '',
      topicId: '',
    });
    setPeriodData([]);
    setTopicDropdown([]);
    setPage(1);
    if (value) {
      setPage(1);
      setFilterData({ ...filterData, chapter: value });
      if (value) {
        axiosInstance
          .get(`${endpoints.questionBank.topics}?chapter=${value?.id}&page_size=50`)
          .then((result) => {
            if (result?.data?.status_code === 200) {
              setTopicDropdown(result?.data?.result);
            } else {
              setAlert('error', result?.data?.message);
              setTopicDropdown([]);
            }
          })
          .catch((error) => {
            setAlert('error', error?.message);
            setTopicDropdown([]);
          });
      }
    }
  };

  // const handleSubject = (event, value) => {
  //   setFilterData({
  //     ...filterData,
  //     subject: '',
  //     chapter: '',
  //     topicId: '',
  //   });
  //   setPage(1);
  //   setPeriodData([]);
  //   if (value) {
  //     setFilterData({ ...filterData, subject: value, chapter: '', topic: '' });
  //     setPage(1);
  //     if (value) {
  //       axiosInstance
  //         .get(`${endpoints.lessonPlan.chapterList}?grade_subject=${value?.id}`)
  //         .then((result) => {
  //           if (result?.data?.status_code === 200) {
  //             setChapterDropdown(result?.data?.result);
  //           } else {
  //             setAlert('error', result?.data?.message);
  //             setChapterDropdown([]);
  //           }
  //         })
  //         .catch((error) => {
  //           setAlert('error', error?.message);
  //           setChapterDropdown([]);
  //         });
  //     }
  //   }
  // };

  const handleModule = (event = {}, values = []) => {
    // setLoading(true)
    setFilterData({ ...filterData, module: '', chapter: '' });
    // setOverviewSynopsis([]);
    if (values) {
      // setLoading(true)
      // const ids = values.map((el) => el);
      // const selectedId = values.map((el) => el?.id)
      setSelectedModuleId(values)
      setFilterData({ ...filterData, module: values, chapter: '' });
      if (filterData.year && values.length !== 0) {
        axiosInstance.get(`${endpoints.lessonPlan.chapterList}?grade_subject=${filterData.subject.id}&academic_year=${filterData.year.id}&board=${selectedBoardId}&lt_module=${values?.id}`)
          .then(result => {
            if (result.data.status_code === 200) {
              // setLoading(false)
              setChapterDropdown(result.data.result);
            }
            else {
              // setLoading(false)
              setAlert('error', result.data.message);
              setChapterDropdown([]);
            }
          })
          .catch(error => {
            // setLoading(false)
            setAlert('error', error.message);
            setChapterDropdown([]);
          })
      }
    }
    else {
      // setLoading(false)
      setChapterDropdown([]);
    }
  };

  const handleAcademicYear = (event, value) => {
    // setFilterData({ ...filterData, year: '' });
    setFilterData({
      grade: '',
      subject: '',
      chapter: '',
      quesType: '',
      topicId: '',
      question_level: '',
      question_category: '',
    })
    setPeriodData([]);
    setSubjectDropdown([]);
    setChapterDropdown([]);
    setBoardDropdown([]);
    setPeriodData([]);
    setTopicDropdown([]);
    setPage(1);

    if (value) {
      setFilterData({ ...filterData, year: value });
    }
  };


  useEffect(() => {
    let questionBankFilter = JSON.parse(localStorage.getItem("questionBankFilter"));
    if (questionBankFilter && questionBankFilter?.filterData?.subject?.id) {
      setFilterData(questionBankFilter.filterData);
      handlePeriodList(
        questionBankFilter.filterData?.quesType?.id,
        questionBankFilter.filterData?.question_category,
        questionBankFilter.filterData?.subject,
        questionBankFilter.filterData?.question_level,
        questionBankFilter.filterData?.topicId,
        0,
        questionBankFilter.filterData?.chapter?.id,
        questionBankFilter.page
      );
      callApi(`${endpoints.questionBank.subjects}?grade=${questionBankFilter.filterData.grade?.id}`, "subject");
      callApi(`${endpoints.lessonPlan.chapterList}?grade_subject=${questionBankFilter.filterData.subject?.id}`, "chapter")
      questionBankFilter.filterData?.chapter?.id && callApi(`${endpoints.questionBank.topics}?chapter=${questionBankFilter.filterData.chapter?.id}`, "topic");
    }
  }, [])

  useEffect(() => {
    let questionBankFilter = JSON.parse(localStorage.getItem("questionBankFilter"));
    if (questionBankFilter) {
      questionBankFilter.page = page;
      localStorage.setItem("questionBankFilter", JSON.stringify(questionBankFilter));
    }
  }, [page])

  const handleFilter = () => {
    if (!filterData?.grade) {
      setAlert('error', 'Select Grade!');
      return;
    }
    if (!filterData?.subject) {
      setAlert('error', 'Select Subject!');
      return;
    }
    // if (!filterData?.chapter) {
    //   setAlert('error', 'Select Chapter!');
    //   return;
    // }
    localStorage.setItem("questionBankFilter", JSON.stringify({ filterData, page }));
    handlePeriodList(
      filterData?.quesType?.id,
      filterData?.question_category,
      filterData?.subject,
      filterData?.question_level,
      filterData?.topicId,
      0,
      filterData?.chapter?.id
    );
    setSelectedIndex(-1);
  };
  function handleDownloadExcel(data, title) {
    let link = document.createElement('a');
    link.setAttribute(
      'href',
      URL.createObjectURL(
        new Blob([data], {
          type: 'application/vnd.ms-excel',
        })
      )
    );
    // link.download = `${title}`;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  const handleDownload = async () => {
    if (filterData.subject) {
      try {
        const { data, headers } = await axiosInstance.get(
          `${endpoints.questionBank.downloadReport
          }?mapping_id=${filterData?.subject?.id}`,
          {
            responseType: 'arraybuffer',
          }
        );
        if (headers['content-type'] === "text/ms-excel") {
          handleDownloadExcel(data)
        }
        else {
          setAlert('error', 'Downloadable report not available')
        }
      } catch (error) {
        setAlert('error', 'Failed to download Count list');
      }
    }
  };
  useEffect(() => {
    axiosInstance.get(`${endpoints.lessonPlan.academicYearList}`)
      .then(result => {
        if (result.data.status_code === 200) {
          setAcademicYearDropdown(result.data.result.results);
        } else {
          setAlert('error', result.data.message);
        }
      }).catch(error => {
        setAlert('error', error.message);
      });
    axiosInstance
      .get(`${endpoints.questionBank.grades}`)
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setGradeDropdown(result?.data?.result?.results);
        } else {
          setAlert('error', result?.data?.message);
        }
      })
      .catch((error) => {
        setAlert('error', error?.message);
      });
    axiosInstance
      .get(`${endpoints.questionBank.examType}`)
      .then((result) => {
        if (result?.data?.status_code === 200) {
          setQueTypeDropdown(result?.data?.result);
        } else {
          setAlert('error', result?.data?.message);
        }
      })
      .catch((error) => {
        setAlert('error', error?.message);
      });
  }, []);

  return (
    <Grid
      container
      spacing={isMobile ? 3 : 5}
      style={{ width: widerWidth, margin: wider, fontFamily: 'Andika New Basic, sans-serif' }}
    >
      <Grid item xs={12} sm={3} className={isMobile ? '' : 'filterPadding'}>
        <Autocomplete
          style={{ width: '100%' }}
          size='small'
          onChange={handleAcademicYear}
          id='academic-year'
          className="dropdownIcon"
          value={filterData.year}
          options={academicYearDropdown}
          getOptionLabel={(option) => option?.session_year}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              label='Academic Year'
              placeholder='Academic Year'
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3} className={isMobile ? '' : 'filterPadding'}>
        <Autocomplete
          style={{ width: '100%', fontFamily: 'Andika New Basic, sans-serif' }}
          size='small'
          onChange={handleGrade}
          id='grade'
          className='dropdownIcon'
          value={filterData?.grade || {}}
          options={gradeDropdown || []}
          getOptionLabel={(option) => option?.grade_name || ''}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField {...params} variant='outlined' label='Grade' placeholder='Grade' />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3} className={isMobile ? '' : 'filterPadding'}>
        <Autocomplete
          style={{ width: '100%' }}
          size='small'
          onChange={handleSubject}
          id='subject'
          className='dropdownIcon'
          value={filterData?.subject || {}}
          options={subjectDropdown || []}
          getOptionLabel={(option) => option?.subject?.subject_name || ''}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              label='Subject'
              placeholder='Subject'
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3} className={isMobile ? '' : 'filterPadding'}>
        <Autocomplete
          multiple
          style={{ width: '100%' }}
          size='small'
          onChange={handleBoard}
          id='board'
          className="dropdownIcon"
          value={filterData.board || []}
          options={boardDropdown || []}
          getOptionLabel={(option) => option?.board_name || ''}
          filterSelectedOptions
          getOptionSelected={(option, value) =>
            option?.id == value?.id
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              label='Board'
              placeholder='Board'
            />
          )}
        />
      </Grid>
      {isFilter && <Grid item xs={12} sm={3} className={isMobile ? '' : 'filterPadding'}>
        <Autocomplete
          // multiple
          style={{ width: '100%' }}
          size='small'
          onChange={handleModule}
          id='module'
          className="dropdownIcon"
          value={filterData.module || []}
          options={moduleDropdown || []}
          getOptionLabel={(option) => option?.lt_module_name || ''}
          filterSelectedOptions
          getOptionSelected={(option, value) =>
            option?.id == value?.id
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              label='Module'
              placeholder='Module'
            />
          )}
        />
      </Grid>}
      {isFilter && <Grid item xs={12} sm={3} className={isMobile ? '' : 'filterPadding'}>
        <Autocomplete
          style={{ width: '100%' }}
          size='small'
          onChange={handleChapter}
          id='chapter'
          className='dropdownIcon'
          value={filterData?.chapter || {}}
          options={chapterDropdown || []}
          getOptionLabel={(option) => option?.chapter_name || ''}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              label='Chapter'
              placeholder='Chapter'
            />
          )}
        />
      </Grid>}
      {isFilter && <Grid item xs={12} sm={3} className={isMobile ? '' : 'filterPadding'}>
        <Autocomplete
          style={{ width: '100%' }}
          size='small'
          onChange={handleTopic}
          id='topic'
          className='dropdownIcon'
          value={filterData?.topicId || {}}
          options={topicDropdown || []}
          getOptionLabel={(option) => option?.topic_name || ''}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField {...params} variant='outlined' label='Key Concept' placeholder='Key Concept' />
          )}
        />
      </Grid>}
      {isFilter && <Grid item xs={12} sm={3} className={isMobile ? '' : 'filterPadding'}>
        <Autocomplete
          style={{ width: '100%' }}
          size='small'
          onChange={handleQuestionLevel}
          id='Question Level'
          className='dropdownIcon'
          value={filterData?.question_level || {}}
          options={question_level_options || []}
          getOptionLabel={(option) => option?.Question_level || ''}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              style={{ fontFamily: "Andika New Basic, sans-serif" }}
              {...params}
              variant='outlined'
              label='Question Level'
              placeholder='Question Level'
            />
          )}
        />
      </Grid>}
      {isFilter && <Grid item xs={12} sm={3} className={isMobile ? '' : 'filterPadding'}>
        <Autocomplete
          style={{ width: '100%' }}
          size='small'
          onChange={handleQuestionCategory}
          id='Category'
          className='dropdownIcon'
          value={filterData?.question_category || {}}
          options={question_categories_options || []}
          getOptionLabel={(option) => option?.q_cat || ''}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant='outlined'
              label='Category'
              placeholder='Category'
            />
          )}
        />
      </Grid>}
      {isFilter && <Grid item xs={12} sm={3} className={isMobile ? '' : 'filterPadding'}>
        <Autocomplete
          style={{ width: '100%', fontFamily: 'Andika New Basic, sans-serif' }}
          size='small'
          onChange={handleQuestionType}
          id='Question Type'
          className='dropdownIcon'
          value={filterData?.quesType || {}}
          options={queTypeDropdown || []}
          getOptionLabel={(option) => option?.question_type || ''}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ fontFamily: 'Andika New Basic, sans-serif' }}
              variant='outlined'
              label='Question Type'
              placeholder='Question Type'
            />
          )}
        />
      </Grid>}

      {!isMobile && (
        <Grid item xs={12} sm={12}>
          <Divider />
        </Grid>
      )}
      {section && questionId && (
        <Grid item xs={6} sm={2} className={isMobile ? '' : 'addButtonPadding'}>
          <Button
            variant='contained'
            className='cancelButton labelColor'
            style={{ width: '100%' }}
            size='medium'
            onClick={
              isEdit
                ? () => history.push(`/create-question-paper/${isEdit}`)
                : () => history.push(`/create-question-paper?show-question-paper=true`)
            }
          >
            Back
          </Button>
        </Grid>
      )}
      {isMobile && <Grid item xs={3} sm={0} />}
      <Grid item xs={6} sm={2} className={isMobile ? '' : 'addButtonPadding'}>
        <Button
          variant='contained'
          style={{ color: 'white', borderRadius: '10px', fontFamily: 'Andika New Basic, sans-serif' }}
          className='custom_button_master labelColor'
          size='medium'
          onClick={handleClear}
        >
          CLEAR ALL
        </Button>
      </Grid>
      {isMobile && <Grid item xs={3} sm={0} />}
      {isMobile && <Grid item xs={3} sm={0} />}
      <Grid item xs={6} sm={2} className={isMobile ? '' : 'addButtonPadding'}>
        <Button
          variant='contained'
          style={{ color: 'white', borderRadius: '10px', fontFamily: 'Andika New Basic, sans-serif' }}
          color='primary'
          className='custom_button_master'
          size='medium'
          onClick={handleFilter}
        >
          FILTER
        </Button>
      </Grid>
      {isMobile && <Grid item xs={3} sm={0} />}
      {isMobile && <Grid item xs={3} sm={0} />}
      {!questionId && (
        <Grid
          item
          xs={6}
          sm={2}
          className={isMobile ? 'createButton' : 'createButton addButtonPadding'}
        >
          <Button
            startIcon={<AddOutlinedIcon style={{ fontSize: '30px' }} />}
            variant='contained'
            style={{ color: 'white', borderRadius: '10px', fontFamily: 'Andika New Basic, sans-serif' }}
            color='primary'
            className='custom_button_master'
            onClick={() => history.push('/create-question')}
            size='medium'
          >
            CREATE
          </Button>
        </Grid>
      )}
      {isMobile && <Grid item xs={3} sm={0} />}
      {isMobile && <Grid item xs={3} sm={0} />}
      <Grid item xs={6} sm={3} className={isMobile ? '' : 'addButtonPadding'}>
        <Button
          variant='contained'
          style={{ color: 'white', borderRadius: '10px', fontFamily: 'Andika New Basic, sans-serif' }}
          color='primary'
          className='custom_button_master'
          size='medium'
          onClick={handleDownload}
        >
          Download Report
        </Button>
      </Grid>
      {isMobile && <Grid item xs={3} sm={0} />}

    </Grid>
  );
};

export default QuestionBankFilters;
