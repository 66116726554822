import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Grid, Divider } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import { withRouter } from 'react-router-dom';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Pagination } from '@material-ui/lab';
import CommonBreadcrumbs from '../../../components/common-breadcrumbs/breadcrumbs';
import Layout from '../../Layout';
import Filter from './filter';
import GridList from './gridList';
import axios from '../../../config/axios';
import endpoints from '../../../config/endpoints';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const styles = (theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  tabRoot: {
    width: '100%',
    flexGrow: 1,
    // backgroundColor: theme.palette.background.paper,
    // margin: '20px',
  },
});

class ViewChapter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      tabValue: 0,
      pageNo: 1,
      pageSize: 6,
      moduleId: 112,
      totalEbooks: 0,
      clearFilter: '',
      acadmicYear: '',
      selectedGrade: '',
      selectedSubject: '',
      // selectedSchool: '',
      totalPages: 0,
    };
  }

  componentDidMount() {
    this.getEbook();
  }

  getDaysAfter = (date, amount) => {
    return date ? date.add(amount, 'days').format('YYYY-MM-DD') : undefined;
  };

  getDaysBefore = (date, amount) => {
    return date ? date.subtract(amount, 'days').format('YYYY-MM-DD') : undefined;
  };

  handleStartDateChange = (date) => {
    const endDate = this.getDaysAfter(date.clone(), 6);
    this.setState({ endDate });
    this.setState({ startDate: date.format('YYYY-MM-DD') });
  };

  handlePagination = (event, page) => {
    const {
      tabValue,
      status,
      acadmicYear,
      selectedGrade,
      selectedSubject,
      // selectedSchool,
    } = this.state;

    if (tabValue === 0) {
      this.setState({ pageNo: page }, () => {
        this.getEbook(acadmicYear, selectedGrade, selectedSubject);
      });
    } else if (tabValue === 1) {
      this.setState({ pageNo: page }, () => {
        this.getEbook(acadmicYear, selectedGrade, selectedSubject);
      });
    } else if (tabValue === 2) {
      this.setState({ pageNo: page }, () => {
        this.getEbook(acadmicYear, selectedGrade, selectedSubject);
      });
    } else if (tabValue === 3) {
      this.setState({ pageNo: page }, () => {
        this.getEbook(acadmicYear, selectedGrade, selectedSubject);
      });
    }
  };

  handleEndDateChange = (date) => {
    const startDate = this.getDaysBefore(date.clone(), 6);
    this.setState({ startDate });
    this.setState({ endDate: date.format('YYYY-MM-DD') });
  };

  handleTabChange = (event, newValue) => {
    const { acadmicYear, selectedGrade, selectedSubject } = this.state;
    this.setState({ tabValue: newValue, pageNo: 1, pageSize: 8 });
    if (newValue === 0) {
      this.setState({ tabValue: newValue, data: [] }, () => {
        this.getEbook(acadmicYear, selectedGrade, selectedSubject);
        // this.state.clearFilter = 1;
        // this.handleClearFilter();
      });
    } else if (newValue === 1) {
      this.setState({ tabValue: newValue, data: [] }, () => {
        this.getEbook(acadmicYear, selectedGrade, selectedSubject);
        // this.state.clearFilter = 2;
        // this.handleClearFilter();
      });
    } else if (newValue === 2) {
      this.setState({ tabValue: newValue, data: [] }, () => {
        this.getEbook(acadmicYear, selectedGrade, selectedSubject);
        // this.state.clearFilter = 3;
        // this.handleClearFilter();
      });
    }
  };

  getEbook = (book, acad, grade, subject, school) => {
    const filterbook = `${book ? `&book_id=${book?.id}` : ''}`;
    const filterAcad = `${acad ? `&academic_year=${acad?.id}` : ''}`;
    const filterGrade = `${grade ? `&grade=${grade?.id}` : ''}`;
    const filterSubject = `${subject ? `&subject=${subject?.subject?.id}` : ''}`;
    // const filteredSchool = `${school ? `&volume=${school.id}` : ''}`;
    const { pageNo, pageSize, tabValue, moduleId, totalPages } = this.state;
    let urlPath = '';
    if (tabValue === 0 || tabValue === 1) {
      urlPath = `${
        endpoints.ibook.allChapterList
      }?page=${pageNo}&page_size=${pageSize}${filterbook}&is_delete=${'False'}`;
    } else if (tabValue === 2) {
      urlPath = `${
        endpoints.ibook.allChapterList
      }?page=${pageNo}&page_size=${pageSize}&is_delete=${'True'}${filterAcad}${filterGrade}${filterSubject}`;
    }
    axios
      .get(urlPath)
      .then((result) => {
        console.log(result.status, 'result');
        if (result.status === 200) {
          console.log(result.data.results, 'result after if');
          this.setState({
            totalPages: Math.ceil(result?.data?.result?.count / pageSize),
          });
          this.setState({
            data: result.data.result.result,
            // totalEbooks: result.data.result.total_ebooks,
          });
        } else {
          console.log(result.data.message);
        }
      })
      .catch((error) => {});
  };

  handleFilter = (book) => {
    this.getEbook(book);
  };

  handleClearFilter = () => {
    this.state.acadmicYear = '';
    this.state.selectedGrade = '';
    this.state.selectedSubject = '';
    // this.state.selectedSchool = '';
  };

  render() {
    const { classes } = this.props;
    const {
      tabValue,
      data,
      totalEbooks,
      pageNo,
      pageSize,
      startDate,
      endDate,
      clearFilter,
    } = this.state;

    return (
      <div className='layout-container-div'>
        <Layout className='layout-container'>
          <div
            className='message_log_wrapper'
            style={{ backgroundColor: '#F9F9F9', padding: '10px' }}
          >
            <div
              className='message_log_breadcrumb_wrapper'
              style={{ backgroundColor: '#F9F9F9' }}
            >
              <CommonBreadcrumbs
                componentName='ibook'
                childComponentName='View Chapter'
              />
              <div className='create_group_filter_container'>
                <Grid container>
                  <Grid
                    item
                    md={12}
                    xs={12}
                    style={{ padding: '10px', marginTop: '20px' }}
                  >
                    <Filter handleFilter={this.handleFilter} clearFilter={clearFilter} />
                  </Grid>
                </Grid>
                <div style={{ margin: '20px' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
                      <div className={classes.tabRoot}>
                        {data && (
                          <GridList
                            data={data}
                            tabValue={tabValue}
                            totalEbooks={totalEbooks}
                            callBack={this.getEbook}
                          />
                        )}
                        {/* 
                        <Tabs
                          indicatorColor='primary'
                          textColor='primary'
                          value={tabValue}
                          onChange={this.handleTabChange}
                          aria-label='simple tabs example'
                        >
                          <Tab label='Activated' {...a11yProps(0)} />
                          <Tab label='Deleted' {...a11yProps(1)} />
                        </Tabs>
                        <TabPanel value={tabValue} index={0}>
                          {data && (
                            <GridList
                              data={data}
                              tabValue={tabValue}
                              totalEbooks={totalEbooks}
                              callBack={this.getEbook}
                            />
                          )}
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                          {data && (
                            <GridList
                              data={data}
                              tabValue={tabValue}
                              totalEbooks={totalEbooks}
                              callBack={this.getEbook}
                            />
                          )}
                        </TabPanel>
                        */}
                      </div>
                    </Grid>
                    <Grid item xs={12} md={12} style={{ textAlign: 'center' }}>
                      <Pagination
                        onChange={this.handlePagination}
                        count={this.state.totalPages}
                        color='primary'
                        page={pageNo}
                        style={{ paddingLeft: '45%' }}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}
export default withRouter(withStyles(styles)(ViewChapter));
