/* eslint-disable react/jsx-no-duplicate-props */
import React, { useContext, useEffect, useState,useCallback } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import FormControl from '@material-ui/core/FormControl';
import SearchOutlined from '@material-ui/icons/SearchOutlined';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';

import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import IconButton from '@material-ui/core/IconButton';
import { Grid, TextField, Button, useTheme } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Layout from '../Layout';
import { AlertNotificationContext } from '../../context-api/alert-context/alert-state';
import endpoints from '../../config/endpoints';
import CommonBreadcrumbs from '../../components/common-breadcrumbs/breadcrumbs';
import axiosInstance from '../../config/axios';
// import CreateLicense from './create-license'
import EditAssignLicense from './edit-assign-license'
import Loading from '../../components/loader/loader';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';



const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '90vw',
    width: '95%',
    margin: '20px auto',
    marginTop: theme.spacing(2),
    boxShadow: 'none',
  },
  container: {
    maxHeight: '70vh',
    maxWidth: '90vw',
  },
  columnHeader: {
    color: `${theme.palette.secondary.main} !important`,
    fontWeight: 600,
    fontSize: '1rem',
  },
  tableCell: {
    color: theme.palette.secondary.main,
  },
}));

const columns = [
  {
    id: '',
    label: '',
    minWidth: 100,
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'school_name',
    label: 'School Name',
    minWidth: 100,
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'school_sub_domain_name',
    label: 'Sub-Domain',
    minWidth: 100,
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'no_of_license',
    label: 'No. of License',
    minWidth: 100,
    align: 'center',
    labelAlign: 'center',
  },
  {
    id: 'action',
    label: 'Actions',
    minWidth: 100,
    align: 'center',
    labelAlign: 'center',
  },
];

// const debounce = (fn, delay) => {
//   let timeoutId;
//   return function(...args) {
//     clearInterval(timeoutId);
//     timeoutId = setTimeout(() => fn.apply(this, args), delay);
//   };
// };


function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [licenseData , setlicenseData] = useState([])

  const allLicenseData = (data)=>{
    setOpen(!open)
    axiosInstance.get(`${endpoints.zoom.assignedLicense}?school_name=${data}`)
    .then(result => {
      debugger
      if (result.status === 200) {
        // setTotalCount(result.data.count)
        setlicenseData(result.data.data)
        console.log("Allll License Data",result.data.data)
      } else {
        // setAlert('error', result.data.message);
      }
    })
    .catch((error) => {
      // setAlert('error', error.response.data.description);
    })
  }
  return (

    <React.Fragment>
      <TableRow >
        <TableCell>
          {row.no_of_license>0?<IconButton aria-label="expand row" size="small" onClick={() => allLicenseData(row.school_name)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>:null}
        </TableCell>
        <TableCell align="right">{row.school_name}</TableCell>
        <TableCell align="right">{row.school_sub_domain_name}</TableCell>
        <TableCell align="right">{row.no_of_license}</TableCell>
        <TableCell
        // className={classes.tableCell}
        >
          {/* <IconButton
                        onClick={e=>{ handleDelete(subject) }}
                        title='Delete Subject'
                      >
                        <DeleteOutlinedIcon style={{color:'#fe6b6b'}} />
                      </IconButton> */}

          <IconButton
            onClick={e => props.handleEdit(row.school_name, row.school_sub_domain_name, row.no_of_license)}
            title='Edit Assigned License'
          >
            <EditOutlinedIcon style={{ color: '#fe6b6b' }} />
          </IconButton>

        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {/* <Typography variant="h6" gutterBottom component="div">
                License Details
              </Typography> */}
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Username</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell align="right">Zoom User_id</TableCell>
                    <TableCell align="right">is_active</TableCell>
                    <TableCell align="right">is_delete</TableCell>
                    <TableCell align="right">is_occupied</TableCell>
                    <TableCell align="right">webinar_enabled</TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {licenseData.length >0  && licenseData.map((data) => (
                    <TableRow key={data.id}>
                      <TableCell component="th" scope="row">
                        {data.username}
                      </TableCell>
                      <TableCell>{data.email}</TableCell>
                      <TableCell align="right">{data.zoom_user_id}</TableCell>
                      <TableCell align="right">
                        {data.is_active?"true":"false"}
                      </TableCell>
                      <TableCell align="right">
                        {data.is_delete?"true":"false"}
                      </TableCell>
                      <TableCell align="right">
                        {data.is_occupied}
                      </TableCell>
                      <TableCell align="right">
                        {data.webinar_enabled?"true":"false"}
                      </TableCell>
                    </TableRow>
                  ))}

                  {/* <TableCell>23</TableCell>
                  <TableCell>danny</TableCell>
                  <TableCell>10000</TableCell>
                  <TableCell>15000</TableCell> */}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>

  );
}


const AssignLicense = (props) => {
  const classes = useStyles();
  const { setAlert } = useContext(AlertNotificationContext);
  const [page, setPage] = useState(1)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [addFlag, setAddFlag] = useState(false)
  const [editFlag, setEditFlag] = useState(false)
  const [tableFlag, setTableFlag] = useState(true)
  const [delFlag, setDelFlag] = useState(false)
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false)
  const [limit, setLimit] = useState(15);
  const [goBackFlag, setGoBackFlag] = useState(false)
  const [assignLicenseList, setAssignLicenseList] = useState([])
  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('sm'));
  const [searchText, setSearchText] = useState('');
  const [isNewSeach, setIsNewSearch] = useState(true);




  const wider = isMobile ? '-10px 0px' : '-10px 0px 20px 8px'
  const widerWidth = isMobile ? '98%' : '95%'
  const [editData, setEditData] = useState({ 'name': '', 'domain': '', 'number': '' })

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1)
  }

  const handleAddLicense = () => {
    // setTableFlag(false)
    // setAddFlag(true)
    // setEditFlag(false)
  }

  const handleEditAssignLicense=(name,domain,number)=>{
    setTableFlag(false)
    setAddFlag(false)
    setEditFlag(true)
    setEditData({name,domain,number})    
  }

  const handleGoBack = () => {
    setPage(1)
    setTableFlag(true)
    setAddFlag(false)
    setEditFlag(false)
    setGoBackFlag(!goBackFlag)
  }

  const handleDeleteSubject = (e) => {
    //   e.preventDefault()
    //   setLoading(true);
    //   axiosInstance.put(endpoints.masterManagement.updateSubject,{
    //     'is_delete': true,
    //     'subject_id': subjectId
    //   }).then(result=>{
    //   if (result.status === 200) {
    //     setDelFlag(!delFlag)
    //     setLoading(false);
    //     setAlert('success', "Subject deleted successfully!");
    //   } else {
    //     setLoading(false);
    //     setAlert('error', "Network Error!");
    //   }
    //   }).catch((error)=>{
    //     setLoading(false);
    //     setAlert('error', "Subject couldn't be deleted!");
    //   })
    // setOpenDeleteModal(false)
  };

  const handleOpenDeleteModal = (id) => {
    // setSubjectId(id)
    // setOpenDeleteModal(true)
  };

  const handleCloseDeleteModal = () => {
    // setOpenDeleteModal(false)
  };

  useEffect(() => {
    setLoading(true)
    setTimeout(() => { setLoading(false) }, 450);
  }, [goBackFlag, page, delFlag])

  

  const getData = () => {
    let url = `${endpoints.zoom.assignLicense}?&page=${page}&page_size=${limit}`
    if (searchText) {
      url += `&school_name=${searchText}`;
    }
    axiosInstance.get(url)
      .then(result => {
        if (result.status === 200) {
          setTotalCount(result.data.count)
          setAssignLicenseList(result.data.result)
          console.log("license data",assignLicenseList)
        } else {
          setAlert('error', result.data.message);
        }
      })
      .catch((error) => {
        setAlert('error', error.response.data.description);
      })
  }

  const handleTextSearch = (e) => {
    let search = e.target.value;
    setSearchText(e.target.value);
  };
  useEffect(() => {
		const fetchData = () => {
      setPage(1);
      getData();
		};

		const timer = setTimeout(() => {
			fetchData();
		}, 500);

		return () => clearTimeout(timer);
	}, [searchText]);

  return (
    <>
      {loading ? <Loading message='Loading...' /> : null}
      <Layout>
        <div>
          <div style={{ width: '95%', margin: '20px auto' }}>
            <CommonBreadcrumbs
              componentName='Assign License List'
              childComponentName={(addFlag && !tableFlag) ? 'Add License' : (editFlag && !tableFlag) ? 'Edit License' : null}
            />
          </div>
        </div>
        <Grid item sm={11} md={11} xs={11}>
                  <FormControl
                    variant='outlined'
                    fullWidth
                    size='small'
                    style={{margin: '35px'}}
                  >
                    <InputLabel>Search</InputLabel>
                    <OutlinedInput
                    style = {{borderRadius : '10px'}}
                      endAdornment={<SearchOutlined color='primary' />}
                      placeholder='Search users ..'
                      label='Search'
                      value={searchText}
                      onChange={handleTextSearch}
                    />
                  </FormControl>
                </Grid>

        {/* {!tableFlag && addFlag && !editFlag && <CreateLicense setLoading={setLoading} handleGoBack={handleGoBack}/> } */}
        {!tableFlag && !addFlag && editFlag && <EditAssignLicense setLoading={setLoading} handleGoBack={handleGoBack} editData={editData} />}

        {/* {tableFlag && !addFlag && !editFlag &&   
      <Grid container spacing={isMobile?3:5} style={{ width: widerWidth, margin: wider}}>
      <Grid item xs={12} sm={3} className={isMobile?'':'addButtonPadding'}>
          <Button 
          startIcon={<AddOutlinedIcon style={{fontSize:'30px'}}/>} 
          variant='contained' 
          color='primary' 
          size="small" 
          style={{color:'white'}} 
          title="Add License" 
          onClick={handleAddLicense}>
            Add License
        </Button>
      </Grid>
      </Grid>
    } */}

        {/* <>
    {!isMobile?
     <> */}
        {tableFlag && !addFlag && !editFlag &&
          <Paper className={`${classes.root} view_user_table_wrapper`}>
            <TableContainer className={`table view_user_table table-shadow ${classes.container}`}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                        className={classes.columnHeader}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {assignLicenseList.map((row) => (
                    <Row key={row.name} row={row} handleEdit= {handleEditAssignLicense} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="paginate paginateMobileMargin">
              <TablePagination
                component='div'
                count={totalCount}
                className='customPagination'
                rowsPerPage={limit}
                page={page - 1}
                onChangePage={handleChangePage}
                rowsPerPageOptions={false}
              />
            </div>
          </Paper>
        }

        {/* </>
      : <>
      <>
      {tableFlag && !addFlag && !editFlag && 
      <>
      {
        subjects.map(subject => (
          <SubjectCard 
          data={subject} 
          handleDelete={handleDelete} 
          handleEditSubject={handleEditSubject} />
        ))
      }
      <div className="paginateData paginateMobileMargin">
      <TablePagination
        component='div'
        count={totalCount}
        rowsPerPage={limit}
        page={page-1}
        onChangePage={handleChangePage}
        rowsPerPageOptions={false}
        className='table-pagination'
      />
      </div>
      </>
      }
      </>
      </>
    }
    </> */}

        {/* <Dialog
      open={openDeleteModal}
      onClose={handleCloseDeleteModal}
      aria-labelledby='draggable-dialog-title'
    >
      <DialogTitle style={{ cursor: 'move',color: '#014b7e' }} id='draggable-dialog-title'>
        Delete Subject
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
        {`Confirm Delete Subject ${subjectName}`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDeleteModal} className="labelColor cancelButton">
          Cancel
        </Button>
        <Button color="primary" onClick={handleDeleteSubject}>Confirm</Button>
      </DialogActions>
    </Dialog> */}

      </Layout>
    </>
  );
};
export default AssignLicense;



